/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */

import history from "Util/History";

const render =  (args, callback, instance) => {
    const {
        option: {
            value_string,
            label,
            url
        },
        base_url
    } = instance.props;

    return (
        <a
            href={ value_string }
            block="ProductAttributeValue"
            mix={ { block: 'CategorySubcategories' } }
            onClick={(e)=>{
                e.preventDefault();
                history.push(url.replace(base_url,'/'))
            }
            }
            className="catalogue-head__link"
        >
                <span
                    title={ label }
                    block="ProductAttributeValue"
                    elem="Text"
                    mix={ {
                        block: 'CategorySubcategories',
                        elem: 'Label'
                    } }
                >
                    { label }
                </span>
        </a>
    );
}

const config = {
    'Component/CategorySubcategories/Component': {
        'member-function': {
            render: [
                {
                    position:100,
                    implementation: render
                }
            ]
        }
    }
}

export default config;
