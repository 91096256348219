import { lazy } from 'react';
import { MULTITAB_SLIDER  } from '../component/MultiTabProductWidget/MultiTabProductWidget.config';
export const MultiTabProductWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-multi-tab-slider" */
    '../component/MultiTabProductWidget'
));

export const renderMap = (props, instance) => {
    return {
        ...props,
        [MULTITAB_SLIDER]: {
            component: MultiTabProductWidget
        }
    }
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': renderMap,
        }
    }
};