import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';


export const openCustomPromoPopup = (args, callback, instance) => {
    const [identifier] = args;
    const { showPopup } = instance.props;

    if (identifier) {
        let popupID = `${identifier}_popup_id`

        instance.setState({ id: popupID, item: popupID });

        if (!BrowserDatabase.getItem(popupID)) {
            setTimeout(function () {
                showPopup(popupID);
            }, 2000);
        }
    }
}


export default {
    'Component/CustomPromoPopup/Container': {
        'member-function': {
            openCustomPromoPopup
        },
    },
}