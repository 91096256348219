import { ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer } from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import getStore from 'SourceUtil/Store';

export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {

    containerFunctions = {
        handleOptionClick: this.handleOptionClick.bind(this),
        getSubHeading: this.getSubHeading.bind(this),
        isSelected: this.isSelected.bind(this),
        getLink: this.getLink.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        getIsConfigurableAttributeAvailableForSwatch: this.getIsConfigurableAttributeAvailableForSwatch.bind(this),
        getIsConfigurableStockStatus: this.getIsConfigurableStockStatus.bind(this),
        getIsConfigurableLowStock: this.getIsConfigurableLowStock.bind(this)
    };

    containerProps() {
        const {
            configurable_options,
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            inStock,
            isProductPage,
            attributeSetId,
            thumbnail,
            variants,
            comments
        } = this.props;

        return {
            navigationName: getStore().getState().NavigationReducer['TOP_NAVIGATION_TYPE']?.navigationState?.name,
            lowStockQty: getStore()?.getState()?.ConfigReducer?.low_stock_qty || 0,
            configurable_options,
            isExpandable,
            isReady,
            mix,
            numberOfPlaceholders,
            parameters,
            showProductAttributeAsLink,
            updateConfigurableVariant,
            inStock,
            isProductPage,
            thumbnail,
            attributeSetId,
            variants,
            comments
        };
    }

    getIsConfigurableAttributeAvailable({ attribute_code, attribute_value }) {
        const { parameters, variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return true;
        }

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        // If value matches current attribute_value, option should be enabled
        if (isAttributeSelected && parameters[attribute_code] === attribute_value) {
            return true;
        }

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected
            // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
            ? parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    // stock_status === 'IN_STOCK'
                    // Variant must have currently checked attribute_code and attribute_value
                    foundValue === attribute_value
                    // Variant must have all currently selected attributes
                    && selectedAttributes.every(([key, value]) => attributes[key].attribute_value === value)
                );
            });
    }

    getIsConfigurableStockStatus({ attribute_code, attribute_value }) {
        const { variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return false;
        }

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    stock_status === 'IN_STOCK'
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                );
            });
    }

    getIsConfigurableLowStock({ attribute_code, attribute_value }) {
        const { variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return false;
        }

         const variant = variants
            .find(({ lowStock, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};
                if(
                    lowStock
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                ) return lowStock;
            });

            return variant?.lowStock || 0
    }


    getIsConfigurableAttributeAvailableForSwatch({ attribute_code, attribute_value }) {
        const { parameters, variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return true;
        }

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        // If value matches current attribute_value, option should be enabled
        // if (isAttributeSelected && parameters[attribute_code] === attribute_value) {
        //     return true;
        // }

        const parameterPairs = Object.entries(parameters);

        const selectedAttributes = isAttributeSelected
            // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
            ? parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    stock_status === 'IN_STOCK'
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                    // Variant must have all currently selected attributes
                    && selectedAttributes.every(([key, value]) => attributes[key].attribute_value === value)
                );
            });
    }
}

export default ProductConfigurableAttributesContainer;
