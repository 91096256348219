import TextPlaceholder from "Component/TextPlaceholder";
import ProductReviewRating from "Component/ProductReviewRating";
import ProductPrice from "Component/ProductPrice";
import { isCrawler, isSSR } from "Util/Browser";
import Link from "SourceComponent/Link";
import CmsBlock from "SourceComponent/CmsBlock";
import Html from "Component/Html";
import Image from "Component/Image";
import ProductAlerts from "Component/ProductAlerts";
import {
    IN_STOCK,
    OUT_OF_STOCK,
} from "SourceComponent/ProductCard/ProductCard.config";
import ProductHelpPopup from "Component/ProductHelpPopup";
import ProductConfigurableAttributes from "Component/ProductConfigurableAttributes";
import ProductLinks from "Component/ProductLinks";
import { RELATED } from "Store/LinkedProducts/LinkedProducts.reducer";
import { DeviceType } from "Type/Device";
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import ClickOutside from "Component/ClickOutside";
import { appendWithStoreCode } from "Util/Url";
import AddToCart from "Component/AddToCart";
import {
    BUNDLE,
    CONFIGURABLE,
    DOWNLOADABLE,
    filterConfigurableOptions,
    GROUPED,
    showNewReviewPopup,
} from "Util/Product";
import {
    FacebookShareButton,
    PinterestShareButton,
    TwitterShareButton,
} from "react-share";
import {
    SAMPLE_CATEGORY,
    BAG_CATEGORY,
    CARDS_CATEGORY,
} from "../../route/ProductPage/ProductPage.config";
import { PriceType, ProductType } from "Type/ProductList";
import { ProductActions as SourceProductActions } from "SourceComponent/ProductActions/ProductActions.component";
import "./GiftCardEmailPreviewProcessor.style.scss";
import "react-datepicker/dist/react-datepicker.css";
import { formatPrice } from 'Util/Price';

export const GIFTPRODUCT = "amgiftcard";
export const GIFTPRODUCTMEDIA = "/amasty/amgcard/image/admin_upload/";
import {
    PRODUCT_DISCOUNT_EXCLUDE,
    BRAND_DIOR,
    BRAND_CHRISTIAN_DIOR,
    BRAND_CHANEL,
    PRODUCT_LABEL_BLACK,
    LUXURY_ITEM,
} from "Route/ProductPage/ProductPage.config";
import {
    PIM_BEAUTY,
    PERFUME_SET,
    PIM_BEAUTY_SIZE,
    FASHION_SET,
    PIM_FASHION_ACCESSORIES,
    PIM_FASHION,
    PIM_FASHION_SIZE,
    PIM_BEAUTY_COLOR,
} from "../ProductAttributeSet/ProductAttributeSet.config";
import PropTypes from "prop-types";
import React, { lazy } from "react";

export const UniversalIcon = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "icons" */
        "Component/UniversalIcon"
    )
);

export const CHANCE_FINDER = "chance_finder";
export const CHANCE_DIOR = "dior_product_page_info";
export const CHANCE_DIOR_HYALUSHOT = "pdp_hyalushot";
export const LIMITATION = "limitation";

/**
 * Product actions
 * @class ProductActions
 * @namespace Component/ProductActions/Component
 */
export class ProductActions extends SourceProductActions {
    static propTypes = {
        product: ProductType.isRequired,
        productOrVariant: ProductType.isRequired,
        minQuantity: PropTypes.number.isRequired,
        maxQuantity: PropTypes.number.isRequired,
        configurableVariantIndex: PropTypes.number,
        showOnlyIfLoaded: PropTypes.func.isRequired,
        quantity: PropTypes.number.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
        showShareButtons: PropTypes.bool.isRequired,
        setShowShareButtons: PropTypes.func.isRequired,
        clickOutsideShareButtons: PropTypes.func.isRequired,
        getLink: PropTypes.func.isRequired,
        setQuantity: PropTypes.func.isRequired,
        updateConfigurableVariant: PropTypes.func.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        groupedProductQuantity: PropTypes.objectOf(PropTypes.number).isRequired,
        clearGroupedProductQuantity: PropTypes.func.isRequired,
        setGroupedProductQuantity: PropTypes.func.isRequired,
        setLinkedDownloadables: PropTypes.func.isRequired,
        setLinkedDownloadablesPrice: PropTypes.func.isRequired,
        onProductValidationError: PropTypes.func.isRequired,
        getSelectedCustomizableOptions: PropTypes.func.isRequired,
        productOptionsData: PropTypes.object.isRequired,
        setBundlePrice: PropTypes.func.isRequired,
        productPrice: PriceType,
        productName: PropTypes.string,
        offerCount: PropTypes.number.isRequired,
        offerType: PropTypes.string.isRequired,
        stockMeta: PropTypes.string.isRequired,
        metaLink: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        isPriceAlertEnabled: PropTypes.bool.isRequired,
        isInStockAlertEnabled: PropTypes.bool.isRequired,
        isWishlistEnabled: PropTypes.bool.isRequired,
        displayProductStockStatus: PropTypes.bool.isRequired,
        setRefs: PropTypes.func.isRequired,
        areReviewsEnabled: PropTypes.bool.isRequired,
        inStock: PropTypes.bool.isRequired,
        cartItems: PropTypes.array.isRequired,
    };

    renderPriceWithSchema() {
        const {
            productPrice,
            offerCount,
            product: {
                attributes = {},
                categories = [],
                variants = {},
                type_id,
            } = {},
            product,
            configurableVariantIndex,
            areDetailsLoaded,
        } = this.props;

        if (!areDetailsLoaded) return null;

        const productOrVariant =
            type_id == CONFIGURABLE && configurableVariantIndex !== -1
                ? variants[configurableVariantIndex]
                : product;

        let {
            attributes: variantAttributes = {},
            is_foucsia_price,
            is_value_label,
            is_reduced_price
        } = productOrVariant;

        let unitLabel = variantAttributes?.unit_label?.attribute_value
            ? variantAttributes.unit_label.attribute_value
            : null;
        let unitCalcModifier = variantAttributes?.unit_product_price_per_unit_calc
            ?.attribute_value
            ? variantAttributes.unit_product_price_per_unit_calc.attribute_value
            : null;
        let isLuxuryItem = variantAttributes?.att_108?.attribute_value
            ? variantAttributes?.att_108?.attribute_value === LUXURY_ITEM
                ? variantAttributes?.att_108?.attribute_value
                : null
            : null;

        const {
            minimum_price: { final_price: { value: minFinalPrice = 0 } = {} } = {},
            maximum_price: { final_price: { value: maxFinalPrice = 0 } = {} } = {},
        } = productPrice;

        const isDisable = categories.filter(({ id }) => id == SAMPLE_CATEGORY);

        if (isDisable.length !== 0) {
            return null;
        }

        let brand = attributes?.brand?.attribute_value
            ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                ?.label
            : null;

        return (
            <div block="ProductActions" elem="PriceWrapper">
                {this.renderSchema()}
                <meta
                    // itemProp="highPrice"
                    content={
                        minFinalPrice === maxFinalPrice ? minFinalPrice : maxFinalPrice
                    }
                />
                <ProductPrice
                    isSchemaRequired
                    variantsCount={offerCount}
                    // showDiscount={true}
                    showDiscount={PRODUCT_DISCOUNT_EXCLUDE.includes(brand) ? false : true}
                    price={productPrice}
                    isReducedPrice={is_reduced_price}
                    isFoucsiaPrice={is_foucsia_price}
                    isValueLabel={is_value_label}
                    mix={{ block: "ProductActions", elem: "Price" }}
                    label={this.getConfigurablePriceBadge()}
                    unitLabel={unitCalcModifier && !isLuxuryItem && unitLabel}
                    unitCalcModifier={
                        unitCalcModifier && !isLuxuryItem && unitCalcModifier
                    }
                />
            </div>
        );
    }

    renderHelpHtml() {
        return (
            <div block="ProductActions" elem="Help">
                <ProductHelpPopup showHelpButton={true} />
            </div>
        );
    }

    renderRelatedProducts() {
        const {
            product: { attribute_set_id, thumbnail, name, id } = {},
            areDetailsLoaded,
        } = this.props;

        if (
            (attribute_set_id !== FASHION_SET &&
                attribute_set_id !== PIM_FASHION_ACCESSORIES &&
                attribute_set_id !== PIM_FASHION &&
                attribute_set_id !== PIM_FASHION_SIZE) ||
            !thumbnail
        )
            return null;

        return (
            <ProductLinks
                linkType={RELATED}
                title={__("Recommended for you")}
                areDetailsLoaded={areDetailsLoaded}
                currentProductThumbnailUrl={thumbnail.url}
                currentProductName={name}
                currentProductId={id}
            />
        );
    }

    renderSizeGuideHtml() {
        const {
            product: { attribute_set_id },
        } = this.props;

        if (
            attribute_set_id !== FASHION_SET &&
            attribute_set_id !== PIM_FASHION_ACCESSORIES &&
            attribute_set_id !== PIM_FASHION &&
            attribute_set_id !== PIM_FASHION_SIZE
        )
            return null;

        return (
            <div block="ProductActions" elem="SizeGuide">
                <ProductHelpPopup showSizeGuideButton={true} />
            </div>
        );
    }

    renderPerfumeHtml() {
        const {
            product: { attribute_set_id, type_id, attributes = {} },
        } = this.props;

        let activeNotes =
            attribute_set_id === PERFUME_SET ||
            attribute_set_id === PIM_BEAUTY_SIZE ||
            (type_id === "simple" && attribute_set_id === PIM_BEAUTY);
        let baseNotes =
            attributes &&
                attributes.att_125 &&
                attributes.att_125.attribute_options.length !== 0 &&
                Object.keys(attributes.att_125.attribute_options).length
                ? attributes.att_125.attribute_options
                : null;
        let topNotes =
            attributes &&
                attributes.att_123 &&
                attributes.att_123.attribute_options.length !== 0 &&
                Object.keys(attributes.att_123.attribute_options).length
                ? attributes.att_123.attribute_options
                : null;
        let heartNotes =
            attributes &&
                attributes.att_124 &&
                attributes.att_124.attribute_options.length !== 0 &&
                Object.keys(attributes.att_124.attribute_options).length
                ? attributes.att_124.attribute_options
                : null;

        let notes = activeNotes && (baseNotes || topNotes || heartNotes);

        if (notes) {
            return (
                <div className="ProductActions_aroma_notes">
                    <div className="label">Νότες Αρωμάτων</div>
                    <div className="ProductActions_aroma_notes_columns">
                        {this.renderTopNotes()}
                        {this.renderHeartNotes()}
                        {this.renderBaseNotes()}
                    </div>
                </div>
            );
        }

        return null;
    }

    renderTopNotes() {
        const { product: { attributes = {} } = {} } = this.props;

        let topNotes =
            attributes &&
                attributes.att_123 &&
                attributes.att_123.attribute_options.length !== 0 &&
                Object.keys(attributes.att_123.attribute_options).length
                ? attributes.att_123.attribute_options
                : null;

        return (
            <>
                {topNotes ? (
                    <div className="ProductActions_aroma_notes_column">
                        <div className="ProductActions_aroma_notes_icon">
                            <UniversalIcon
                                src={media("frame_triangle.svg", WYSIWYG_MEDIA)}
                                alt="frame_triangle"
                            />
                        </div>
                        <div className="ProductActions_aroma_notes_content">
                            <div className="ProductActions_aroma_notes_title">
                                {attributes.att_123.attribute_label}
                            </div>
                            <div className="ProductActions_aroma_notes_subtitle">
                                {topNotes
                                    ? Object.keys(topNotes).map((item) => {
                                        return (
                                            <span className="ProductActions_aroma_notes__item">
                                                {topNotes[item].label}
                                            </span>
                                        );
                                    })
                                    : topNotes}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    }

    renderHeartNotes() {
        const { product: { attributes = {} } = {} } = this.props;

        let heartNotes =
            attributes &&
                attributes.att_124 &&
                attributes.att_124.attribute_options.length !== 0 &&
                Object.keys(attributes.att_124.attribute_options).length
                ? attributes.att_124.attribute_options
                : null;

        return (
            <>
                {heartNotes ? (
                    <div className="ProductActions_aroma_notes_column">
                        <div className="ProductActions_aroma_notes_icon">
                            <UniversalIcon
                                src={media("frame_heart.svg", WYSIWYG_MEDIA)}
                                alt="frame_heart"
                            />
                        </div>
                        <div className="ProductActions_aroma_notes_content">
                            <div className="ProductActions_aroma_notes_title">
                                {attributes.att_124.attribute_label}
                            </div>
                            <div className="ProductActions_aroma_notes_subtitle">
                                {heartNotes
                                    ? Object.keys(heartNotes).map((item) => {
                                        return (
                                            <span className="ProductActions_aroma_notes__item">
                                                {heartNotes[item].label}
                                            </span>
                                        );
                                    })
                                    : heartNotes}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    }

    renderBaseNotes() {
        const { product: { attributes = {} } = {} } = this.props;

        let baseNotes =
            attributes &&
                attributes.att_125 &&
                attributes.att_125.attribute_options.length !== 0 &&
                Object.keys(attributes.att_125.attribute_options).length
                ? attributes.att_125.attribute_options
                : null;

        return (
            <>
                {baseNotes ? (
                    <div className="ProductActions_aroma_notes_column">
                        <div className="ProductActions_aroma_notes_icon">
                            <UniversalIcon
                                src={media("frame_rectangle.svg", WYSIWYG_MEDIA)}
                                alt="frame_rectangle"
                            />
                        </div>
                        <div className="ProductActions_aroma_notes_content">
                            <div className="ProductActions_aroma_notes_title">
                                {attributes.att_125.attribute_label}
                            </div>
                            <div className="ProductActions_aroma_notes_subtitle">
                                {baseNotes
                                    ? Object.keys(baseNotes).map((item) => {
                                        return (
                                            <span className="ProductActions_aroma_notes__item">
                                                {baseNotes[item].label}
                                            </span>
                                        );
                                    })
                                    : baseNotes}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    }

    renderCategoryHtml = (item) => {
        const { name, url } = item;

        if (url) {
            return (
                <Link
                    to={window.location.origin + url}
                    block="ProductActions"
                    elem="CategoriesItem"
                >
                    {name}
                </Link>
            );
        }

        return null;
    };

    renderCategoriesHtml() {
        const { product: { categories, attributes = {} } = {} } = this.props;

        let brandChanel =
            attributes?.brand?.attribute_value &&
                attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label === BRAND_CHANEL
                ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label
                : null;

        if (!categories || categories.length === 0 || brandChanel) return null;

        return (
            <div block="ProductActions" elem="Categories">
                <div block="ProductActions" elem="CategoriesTitle">
                    {__("See more from")}
                </div>
                <div block="ProductActions" elem="CategoriesList">
                    {categories.map(this.renderCategoryHtml)}
                </div>
            </div>
        );
    }

    renderCustomListHtml() {
        const {
            product: { attributes = {} } = {},
            productOrVariant: { mg_custom_lists },
        } = this.props;

        let brandDIOR =
            attributes?.brand?.attribute_value &&
                (attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label === BRAND_DIOR ||
                    attributes.brand.attribute_options[attributes.brand.attribute_value]
                        ?.label === BRAND_CHRISTIAN_DIOR)
                ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label
                : null;

        let offerTypeList = [];
        let giftTypeList = [];

        if (!mg_custom_lists || mg_custom_lists.length === 0) return null;

        mg_custom_lists.map((mg_custom_list) => {
            if (mg_custom_list.product_info.length > 0) {
                Object.values(mg_custom_list.product_info).map((i) =>
                    giftTypeList.push(i)
                );
            } else {
                if (mg_custom_list.name) offerTypeList.push(mg_custom_list);
            }
        });

        return (
            <>
                {offerTypeList.length > 0 ? (
                    <div block="ProductActions" elem="CustomList">
                        <div block="ProductActions" elem="CustomListLabel">
                            {__("Offer")}
                        </div>
                        <div block="ProductActions" elem="CustomListContainer">
                            {offerTypeList.map((i) => this.renderOfferCustomListItemHtml(i))}
                        </div>
                    </div>
                ) : null}
                {giftTypeList.length > 0 ? (
                    <div block="ProductActions" elem="CustomList" className="gift_type">
                        <div block="ProductActions" elem="CustomListLabel">
                            {brandDIOR ? __("YOUR GIFT") : __("Free Gift")}
                        </div>
                        <div block="ProductActions" elem="CustomListContainer">
                            {giftTypeList.map((i) =>
                                this.renderGiftCustomListItemHtml(i, brandDIOR)
                            )}
                        </div>
                    </div>
                ) : null}
            </>
        );
    }

    renderOfferCustomListHtml(item) {
        return (
            <div block="ProductActions" elem="CustomList">
                <div block="ProductActions" elem="CustomListLabel">
                    {__("Offer")}
                </div>
                <div block="ProductActions" elem="CustomListContainer">
                    {item.map(this.renderOfferCustomListItemHtml.bind(this))}
                </div>
            </div>
        );
    }

    renderOfferCustomListItemHtml(item) {
        return (
            <div block="ProductActions" elem="CustomListItem">
                <div block="ProductActions" elem="CustomListContent">
                    {item.type_name ? (
                        <div block="ProductActions" elem="CustomListTitle">
                            {item.type_name}
                        </div>
                    ) : (
                        ""
                    )}
                    <div block="ProductActions" elem="CustomListText">
                        {item.name}
                    </div>
                    {item.description ? (
                        <div block="ProductActions" elem="CustomListText">
                            <Html content={item.description} />
                        </div>
                    ) : (
                        ""
                    )}
                    {item.url ? (
                        <Link to={item.url} block="ProductActions" elem="CustomListLink">
                            {__("Περισσότερα")}
                            <UniversalIcon
                                src={media("icon_direction_right.png", WYSIWYG_MEDIA)}
                                alt="CompareIcon"
                                className="CompareIcon"
                            />
                        </Link>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }

    renderGiftCustomListHtml(item) {
        const {
            product: { attributes },
        } = this.props;

        let brandDIOR =
            attributes?.brand?.attribute_value &&
                (attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label === BRAND_DIOR ||
                    attributes.brand.attribute_options[attributes.brand.attribute_value]
                        ?.label === BRAND_CHRISTIAN_DIOR)
                ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label
                : null;

        return (
            <div block="ProductActions" elem="CustomList" className="gift_type">
                <div block="ProductActions" elem="CustomListLabel">
                    {brandDIOR ? __("YOUR GIFT") : __("Free Gift")}
                </div>
                <div block="ProductActions" elem="CustomListContainer">
                    {this.renderGiftCustomListItemHtml(item, brandDIOR)}
                </div>
            </div>
        );
    }

    renderGiftCustomListItemHtml(item, brandDIOR) {
        const { brand: { slider_image, url_alias } = {} } = this.props;

        return (
            <div block="ProductActions" elem="CustomListItem">
                <Image
                    mix={{ block: "ProductActions", elem: "CustomListImage" }}
                    src={item.thumbnail || ""}
                    ratio="custom"
                    objectFit="cover"
                />
                <div block="ProductActions" elem="CustomListContent">
                    {brandDIOR ? (
                        <>
                            {slider_image ? (
                                <UniversalIcon
                                    src={media(
                                        slider_image,
                                        "amasty/shopby/option_images/slider/"
                                    )}
                                    alt="BrandIcon"
                                    className="ProductActions-CustomListImageDior"
                                />
                            ) : null}
                            <div block="ProductActions" elem="CustomListTitleDior">
                                {__("YOUR GIFT")}
                            </div>
                        </>
                    ) : null}
                    {item.name ? (
                        <div block="ProductActions" elem="CustomListTitle">
                            {item.name}
                        </div>
                    ) : (
                        ""
                    )}
                    {item.description ? (
                        <div block="ProductActions" elem="CustomListText">
                            <Html content={item.description} />
                        </div>
                    ) : (
                        ""
                    )}
                    {item.url ? (
                        <Link to={item.url} block="ProductActions" elem="CustomListLink">
                            {__("Περισσότερα")}
                            <UniversalIcon
                                src={media("icon_direction_right.png", WYSIWYG_MEDIA)}
                                alt="CompareIcon"
                                className="CompareIcon"
                            />
                        </Link>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    }

    renderAddToCart() {
        const {
            configurableVariantIndex,
            product,
            product: { categories = [] },
            quantity,
            groupedProductQuantity,
            onProductValidationError,
            productOptionsData,
            inStock,
            cartItems,
        } = this.props;

        const isDisable =
            categories.filter(({ id }) => id == SAMPLE_CATEGORY)?.length !== 0;

        if (isDisable) {
            return null;
        }

        // count how many items in the cart are of the brand chanel
        const channelItems = cartItems?.filter((cartItem) => {
            return (
                cartItem.product.attributes?.brand?.attribute_value &&
                cartItem.product.attributes.brand.attribute_options[
                    cartItem.product.attributes.brand.attribute_value
                ]?.label === BRAND_CHANEL
            );
        });
        const sumChannelItems = channelItems.reduce((sum, item) => {
            return sum + item.qty;
        }, 0);
        // check if current item is chanel
        const isChanel =
            product.attributes?.brand?.attribute_value &&
            product.attributes.brand.attribute_options[
                product.attributes.brand.attribute_value
            ]?.label === BRAND_CHANEL;

        return (
            <AddToCart
                product={product}
                configurableVariantIndex={configurableVariantIndex}
                mix={{ block: "ProductActions", elem: "AddToCart" }}
                quantity={quantity}
                groupedProductQuantity={groupedProductQuantity}
                onProductValidationError={onProductValidationError}
                productOptionsData={productOptionsData}
                titleUpperCase={true}
                disabled={!inStock || (isChanel && sumChannelItems + quantity > 15)}
                isWithIcon
            />
        );
    }

    renderAddToCartActionBlock() {
        const {
            areDetailsLoaded,
            inStock,
            product: { categories = [] },
        } = this.props;

        const isDisable =
            categories.filter(({ id }) => id == SAMPLE_CATEGORY)?.length !== 0;

        if (!areDetailsLoaded || !inStock || isDisable) {
            return null;
        }

        return (
            <div
                block="ProductActions"
                elem="AddToCartWrapper"
                mods={{ isPrerendered: isSSR() || isCrawler() }}
            >
                {this.renderQuantityInput()}
                {this.renderAddToCart()}
            </div>
        );
    }

    renderProductValueLabel() {
        const {
            areDetailsLoaded,
            productOrVariant = {},
            product,
            attributesRedux: { customAttributeMetadata = {} } = {},
        } = this.props;

        var {
            type_id,
            attribute_set_id,
            attributes: {
                beauty_size: {
                    attribute_value: beautySizeValue,
                    attribute_options: beautySizeOptions = {},
                    attribute_label: SizeTitle,
                } = {},
                beauty_color: {
                    attribute_value: beautyColorValue,
                    attribute_options: beautyColorOptions = {},
                    attribute_label: beautyTitle,
                } = {},
                hex_code: { attribute_value: colorCode } = {},
                fashion_size: {
                    attribute_code,
                    attribute_value: fashionSizeValue,
                    attribute_options: fashionSizeOptions = {},
                    attribute_label: fashionTitle,
                } = {},
            } = {},
            attributes,
        } = product;

        if (Object.values(productOrVariant).length > 0) {
            var {
                attributes: {
                    beauty_size: {
                        attribute_value: beautySizeValue,
                        attribute_options: beautySizeOptions = {},
                        attribute_label: SizeTitle,
                    } = {},
                } = {},
            } = productOrVariant;
        }

        if (type_id === CONFIGURABLE && attribute_set_id !== PIM_BEAUTY_COLOR) {
            return;
        }

        let fashionSizeAttributes = "";

        let label = beautySizeOptions[beautySizeValue]
            ? beautySizeOptions[beautySizeValue].label
            : fashionSizeOptions[fashionSizeValue]?.label || null;
        const swatch =
            beautyColorValue && beautyColorOptions[beautyColorValue]
                ? beautyColorOptions[beautyColorValue]?.swatch_data
                : null;
        const title =
            beautyColorValue && beautyColorOptions[beautyColorValue]
                ? `${beautyTitle}: ${beautyColorOptions[beautyColorValue]?.label}`
                : "";

        const style = {
            "--option-background-color": colorCode || "#fffff",
            "--option-border-color": "#dddddd",
            "--option-check-mark-background": "#000",
            "--option-is-selected": 1,
        };

        if (
            type_id !== CONFIGURABLE &&
            attribute_set_id === PIM_FASHION &&
            !label &&
            customAttributeMetadata &&
            fashionSizeValue
        ) {
            fashionSizeAttributes = customAttributeMetadata.filter(
                (item) => item.attribute_code === attribute_code
            );
            label =
                fashionSizeAttributes &&
                    fashionSizeAttributes[0] &&
                    fashionSizeAttributes[0].attribute_options
                    ? fashionSizeAttributes[0].attribute_options.find(
                        (item) => item.value === fashionSizeValue
                    )?.label
                    : null;
        }

        if ((!label && !colorCode) || !areDetailsLoaded) {
            return null;
        }

        return (
            <div
                block="ProductActions"
                elem="AttributesWrapper"
                mods={{ isSimple: true }}
            >
                <div
                    block="ProductConfigurableAttributes ProductActions"
                    elem="Attributes"
                >
                    <p block="ProductConfigurableAttributes" elem="Title">
                        {title || ""}
                    </p>
                    <div block="ProductConfigurableAttributes" elem="SwatchList">
                        <div>
                            <div block="ProductAttributeValue" elem="ImageWrapper">
                                {swatch && swatch.type === "2" ? (
                                    <>
                                        <img
                                            block="ProductAttributeValue"
                                            elem="Color"
                                            src={swatch.value}
                                            alt={label}
                                        />
                                        <span>{label}</span>
                                    </>
                                ) : colorCode ? (
                                    <>
                                        <span
                                            block="ProductAttributeValue"
                                            elem="Color"
                                            style={{
                                                backgroundColor: colorCode,
                                            }}
                                        />
                                        <span>{label}</span>
                                    </>
                                ) : (
                                    <span
                                        block="ProductAttributeValue"
                                        elem="Value"
                                        style={{
                                            top: "6px",
                                        }}
                                    >
                                        {" "}
                                        {label}{" "}
                                    </span>
                                )}
                            </div>
                            <data
                                block="ProductAttributeValue"
                                elem="Image-Overlay selected"
                                value={label}
                                title={label}
                                style={style}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderConfigurableAttributes() {
        const {
            getLink,
            updateConfigurableVariant,
            parameters,
            areDetailsLoaded,
            inStock,
            product: {
                configurable_options = {},
                type_id,
                variants = {},
                attribute_set_id,
                thumbnail,
                attributes = {},
            } = {},
        } = this.props;

        if (type_id !== CONFIGURABLE && areDetailsLoaded) {
            return this.renderProductValueLabel();
        }

        let comments =
            attributes !== undefined && attributes.comments !== undefined
                ? attributes.comments.attribute_value
                : null;

        return (
            <div
                ref={this.configurableOptionsRef}
                block="ProductActions"
                elem="AttributesWrapper"
            >
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                    numberOfPlaceholders={[2, 4]}
                    mix={{ block: "ProductActions", elem: "Attributes" }}
                    isReady={areDetailsLoaded}
                    getLink={getLink}
                    parameters={parameters}
                    thumbnail={thumbnail}
                    variants={variants}
                    updateConfigurableVariant={updateConfigurableVariant}
                    configurable_options={filterConfigurableOptions(
                        configurable_options,
                        variants
                    )}
                    isContentExpanded
                    inStock={inStock}
                    isProductPage={true}
                    comments={comments}
                    attributeSetId={attribute_set_id}
                />
                {this.renderProductValueLabel()}
            </div>
        );
    }

    renderProductShareButton() {
        const {
            product: { id, image } = {},
            showShareButtons,
            setShowShareButtons,
            clickOutsideShareButtons,
        } = this.props;

        let image_url = "";

        if (!id) {
            return null;
        }

        if (image) {
            image_url = image.url;
        }

        return (
            <ClickOutside onClick={clickOutsideShareButtons}>
                <div block="ProductActionsShare">
                    <button
                        block="ProductActionsShare"
                        elem="Button"
                        onClick={setShowShareButtons}
                    >
                        <UniversalIcon
                            src={media("compare.svg", WYSIWYG_MEDIA)}
                            alt="CompareIcon"
                            className="CompareIcon"
                        />
                    </button>
                    {showShareButtons ? (
                        <div block="ProductActionsShare" elem="container">
                            <FacebookShareButton url={window.location.href}>
                                <UniversalIcon
                                    src={media("facebook.png", WYSIWYG_MEDIA)}
                                    alt="FacebookIcon"
                                    className="FacebookIcon"
                                />
                            </FacebookShareButton>
                            <TwitterShareButton url={window.location.href}>
                                <UniversalIcon
                                    src={media("x-logo-black-smallb.png", WYSIWYG_MEDIA)}
                                    alt="TwitterIcon"
                                    className="TwitterIcon"
                                />
                            </TwitterShareButton>
                            <PinterestShareButton
                                url={window.location.href}
                                media={image_url}
                            >
                                <UniversalIcon
                                    src={media("pinterest.png", WYSIWYG_MEDIA)}
                                    alt="PinterestIcon"
                                    className="PinterestIcon"
                                />
                            </PinterestShareButton>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </ClickOutside>
        );
    }

    renderReviewSection() {
        return (
            <div block="ProductActions" elem="Reviews">
                {this.renderReviews()}
                <div block="ProductActions" elem="ActionButtons">
                    {this.renderProductShareButton()}
                    {this.renderProductWishlistButton()}
                </div>
            </div>
        );
    }

    renderReviews() {
        const {
            product: { review_summary: { rating_summary, review_count } = {} },
            reviewsRef,
        } = this.props;

        // if (!rating_summary) {
        //     return null;
        // }

        return (
            <div
                onClick={() =>
                    window.scrollTo({
                        top: reviewsRef.current.offsetTop,
                        behavior: "smooth",
                    })
                }
            >
                <ProductReviewRating
                    summary={rating_summary || 0}
                    count={review_count}
                />
            </div>
        );
    }

    renderBrand() {
        const {
            product: {
                attributes = {},
                attributes: { brand: { attribute_options = {} } = {} } = {},
                brand: pdpBrand,
            } = {},
            brand: _brand,
            showOnlyIfLoaded,
        } = this.props;

        let url_alias = _brand !== undefined ? _brand.url_alias : false;
        let brand =
            url_alias &&
                attributes !== undefined &&
                attributes.brand !== undefined &&
                attributes.brand.attribute_options[attributes.brand.attribute_value] !==
                undefined &&
                attributes.brand.attribute_options[attributes.brand.attribute_value]
                    .label !== BRAND_DIOR &&
                attributes.brand.attribute_options[attributes.brand.attribute_value]
                    .label !== BRAND_CHRISTIAN_DIOR
                ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                    .label
                : attribute_options[pdpBrand]?.label || null;

        return showOnlyIfLoaded(
            brand,
            <>
                {/* <meta itemProp="brand" content={brand || ''} /> */}
                <Link
                    to={appendWithStoreCode("/" + url_alias)}
                    block="ProductActions"
                    elem="Brand"
                    itemProp="brand"
                >
                    <TextPlaceholder content={brand ? brand + " " : ""} />
                </Link>
            </>
        );
    }

    renderCustomLabel = (attribute) => {
        const { label } = attribute;

        if (PRODUCT_LABEL_BLACK.includes(label.toLowerCase())) {
            // if (label.includes('OFFER')) {
            return (
                <div block="ProductActions" elem="TagBlack">
                    {label}
                </div>
            );
        }

        if (label) {
            return (
                <div block="ProductActions" elem="Tag">
                    {label}
                </div>
            );
        }

        return null;
    };

    renderCustomLabels() {
        const {
            linkTo,
            productOrVariant: {
                mg_custom_lists = [],
                attributes: { mg_custom_lists: { attribute_options = {} } = {} } = {},
            },
        } = this.props;

        if (!mg_custom_lists || mg_custom_lists.length === 0) return null;

        return mg_custom_lists.map(this.renderCustomLabel);
    }

    renderExclusiveLabel() {
        const {
            product: {
                attributes: { badge_exclusive: { attribute_value = 0 } = {} } = {},
            } = {},
        } = this.props;

        if (!attribute_value || attribute_value === "0") {
            return null;
        }

        return (
            <div block="ProductActions" elem="TagBlack">
                {__("EXCLUSIVE")}
            </div>
        );
    }

    renderWebExclusiveLabel() {
        const {
            product: {
                attributes: {
                    att_103: { attribute_options = {}, attribute_value } = {},
                } = {},
            } = {},
        } = this.props;

        if (
            !attribute_value > 0 ||
            attribute_options[attribute_value]?.label !== "15"
        ) {
            return null;
        }

        return (
            <div block="ProductActions" elem="TagBlack">
                {__("WEB EXCLUSIVE")}
            </div>
        );
    }

    renderWebCustomLabels() {
        const {
            product: {
                attributes: { att_105: { attribute_options = {} } = {} } = {},
            } = {},
        } = this.props;

        if (!Object.keys(attribute_options).length > 0) {
            return null;
        }

        return Object.values(attribute_options).map(({ label }) => {
            let tagClass = "Tag";

            if (PRODUCT_LABEL_BLACK.includes(label.toLowerCase())) {
                tagClass = "TagBlack";
            }

            return (
                <div block="ProductActions" elem={tagClass}>
                    {label}
                </div>
            );
        });
    }

    renderHcExclusiveLabel() {
        const {
            product: {
                attributes: {
                    att_103: { attribute_options = {}, attribute_value } = {},
                } = {},
            } = {},
        } = this.props;

        if (
            !attribute_value > 0 ||
            attribute_options[attribute_value]?.label !== "50+"
        ) {
            return null;
        }

        return (
            <div block="ProductActions" elem="TagBlack">
                {__("HC EXCLUSIVE")}
            </div>
        );
    }

    renderOnlyInStoreLabel() {
        const {
            product: {
                attributes: {
                    att_103: { attribute_options = {}, attribute_value } = {},
                } = {},
            } = {},
        } = this.props;

        if (
            !attribute_value > 0 ||
            attribute_options[attribute_value]?.label !== "50"
        ) {
            return null;
        }

        return (
            <div block="ProductActions" elem="TagBlack">
                {__("ONLY IN STORES")}
            </div>
        );
    }

    renderBestsellerLabel() {
        const {
            product: {
                attributes: { badge_bestseller: { attribute_value = 0 } = {} } = {},
            } = {},
        } = this.props;

        if (!attribute_value || attribute_value === "0") {
            return null;
        }

        return (
            <div block="ProductActions" elem="Tag">
                {__("BEST SELLER")}
            </div>
        );
    }

    renderNewinLabel() {
        const {
            product: {
                attributes: { badge_newin: { attribute_value = 0 } = {} } = {},
            } = {},
        } = this.props;

        if (!attribute_value || attribute_value === "0") {
            return null;
        }

        return (
            <div block="ProductActions" elem="Tag">
                {__("NEW IN")}
            </div>
        );
    }

    renderProductTags() {
        const {
            product: { attributes },
        } = this.props;

        if (!attributes) return null;

        return (
            <div className="ProductActions-Tags">
                {this.renderCustomLabels()}
                {this.renderBestsellerLabel()}
                {/*{ this.renderNewinLabel() }*/}
                {/* { this.renderExclusiveLabel() } */}
                {/* { this.renderWebExclusiveLabel() } */}
                {/* { this.renderHcExclusiveLabel() } */}
                {/* { this.renderOnlyInStoreLabel() } */}
                {this.renderWebCustomLabels()}
            </div>
        );
    }

    renderProductAlerts() {
        const {
            areDetailsLoaded,
            configurableVariantIndex,
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            product,
            inStock,
            autoRelatedRef,
            product: { variants, categories = [], url },
            isProductPopup,
        } = this.props;

        const isDisable =
            categories.filter(({ id }) => id == SAMPLE_CATEGORY)?.length !== 0;

        if (
            (!isInStockAlertEnabled && !isPriceAlertEnabled) ||
            !areDetailsLoaded ||
            inStock ||
            isDisable
        ) {
            return null;
        }

        const productOrVariant =
            variants && variants[configurableVariantIndex] !== undefined
                ? variants[configurableVariantIndex]
                : product;

        const { id } = productOrVariant;

        return (
            <section block="ProductActions" elem="Section" mods={{ type: "alerts" }}>
                <ProductAlerts
                    url={url}
                    isProductPopup={isProductPopup}
                    productId={id}
                    stockStatus={inStock ? IN_STOCK : OUT_OF_STOCK}
                    autoRelatedRef={autoRelatedRef}
                />
            </section>
        );
    }

    renderFindStoreHtml() {
        const { areDetailsLoaded, inStock } = this.props;

        if (!areDetailsLoaded || !inStock) {
            return null;
        }

        return (
            <div block="ProductActions" elem="FindStore">
                <Link to={"/hondos-center-stores"} block="Button">
                    <svg
                        width="14"
                        height="20"
                        viewBox="0 0 14 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.5817 10.0108C12.9523 9.13441 13.1281 8.18467 13.0964 7.23023C13.0647 6.27578 12.8263 5.34045 12.3985 4.49179C11.9707 3.64313 11.3641 2.90231 10.6225 2.32286C9.88098 1.7434 9.02296 1.33976 8.11045 1.14109C7.19794 0.942426 6.25372 0.953688 5.34597 1.17407C4.43823 1.39444 3.58963 1.81844 2.86146 2.41542C2.13329 3.01241 1.54372 3.76748 1.13534 4.62612C0.726958 5.48476 0.509953 6.42552 0.5 7.38046C0.500154 8.28757 0.689005 9.18425 1.05399 10.0108V10.0108C1.32509 10.6141 6.79429 19 6.79429 19C6.79429 19 12.2635 10.6202 12.5287 10.0169L12.5817 10.0108Z"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M9.58791 6.87376C9.5879 7.40738 9.43325 7.92901 9.14352 8.37261C8.85378 8.81622 8.442 9.16187 7.96028 9.3658C7.47856 9.56973 6.94857 9.62278 6.43738 9.51824C5.9262 9.41369 5.4568 9.15625 5.08861 8.7785C4.72042 8.40075 4.46998 7.91967 4.369 7.39616C4.26801 6.87264 4.32102 6.33023 4.52132 5.83757C4.72162 5.34491 5.06019 4.92415 5.49419 4.62856C5.92819 4.33296 6.43811 4.17581 6.95939 4.17701C7.65706 4.17861 8.32562 4.46343 8.81839 4.969C9.31116 5.47456 9.58791 6.15958 9.58791 6.87376V6.87376Z"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <span>{__("FIND IN STORE")}</span>
                </Link>
            </div>
        );
    }

    renderAlternativeName() {
        const { product: { attributes = {} } = {} } = this.props;

        let brandChanel =
            attributes?.brand?.attribute_value &&
                attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label === BRAND_CHANEL
                ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label
                : null;

        let alternativeName =
            attributes?.alternative_name?.attribute_value && brandChanel
                ? attributes.alternative_name.attribute_value
                : null;

        if (alternativeName) {
            return (
                <span
                    block="ProductActions"
                    elem="AlternativeName"
                    mods={{ isChanel: brandChanel }}
                >
                    {alternativeName}
                </span>
            );
        }

        return null;
    }

    renderName() {
        const {
            product: { name, attributes = {} },
        } = this.props;

        let brandChanel =
            attributes?.brand?.attribute_value &&
                attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label === BRAND_CHANEL
                ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label
                : null;

        return (
            <>
                <span
                    block="ProductActions"
                    elem="Title"
                    mods={{ isChanel: brandChanel }}
                // itemProp="name"
                >
                    <TextPlaceholder content={name} length="medium" />
                </span>
            </>
        );
    }

    renderSkuNBarcode() {
        const {
            product: { variants = {}, type_id } = {},
            product,
            areDetailsLoaded,
            configurableVariantIndex,
        } = this.props;

        if (!areDetailsLoaded) {
            return (
                <div block="ProductActions" elem="SkuNBarcode">
                    <TextPlaceholder length="medium" />
                </div>
            );
        }

        const productOrVariant =
            type_id == CONFIGURABLE && configurableVariantIndex !== -1
                ? variants[configurableVariantIndex]
                : product;
        const {
            barcode,
            attributes: { barcode: { attribute_label } = {} } = {},
            sku = "",
        } = productOrVariant;

        const barcodeLabel = attribute_label
            ? `${attribute_label}: `
            : __("Barcode") + ": ";
        const skuLable = __("SKU") + ": ";

        return (
            <div block="ProductActions" elem="SkuNBarcode">
                {sku ? (
                    <span
                        block="ProductActions"
                        elem="Sku"
                    // itemProp="sku"
                    >
                        {skuLable} <TextPlaceholder content={sku} length="small" />
                    </span>
                ) : (
                    ""
                )}

                {"|"}

                {barcode ? (
                    <span
                        block="ProductActions"
                        elem="Barcode"
                    // itemProp="barcode"
                    >
                        {barcodeLabel} <TextPlaceholder content={barcode} length="small" />
                    </span>
                ) : (
                    ""
                )}
            </div>
        );
    }

    renderHeading() {
        return (
            <h1 block="ProductActions" elem="Heading">
                {this.renderBrand()}
                {this.renderName()}
            </h1>
        );
    }

    renderChanceFinder() {
        const {
            product: { attributes = {} },
            areDetailsLoaded,
        } = this.props;
        let brandDIOR =
            attributes?.brand?.attribute_value &&
                (attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label === BRAND_DIOR ||
                    attributes.brand.attribute_options[attributes.brand.attribute_value]
                        ?.label === BRAND_CHRISTIAN_DIOR)
                ? attributes.brand.attribute_options[attributes.brand.attribute_value]
                    ?.label
                : null;
        if (areDetailsLoaded) {
            return (
                <>
                    {brandDIOR && (
                        <div block="ProductActions" elem="chance_finder" key={CHANCE_DIOR}>
                            <CmsBlock identifier={CHANCE_DIOR} />
                        </div>
                    )}
                    {attributes.is_hyalushot?.attribute_value === "1" && (
                        <div
                            block="ProductActions"
                            elem="chance_finder"
                            key={CHANCE_DIOR_HYALUSHOT}
                        >
                            <CmsBlock identifier={CHANCE_DIOR_HYALUSHOT} />
                        </div>
                    )}
                    {attributes.is_chance_finder?.attribute_value === "1" && (
                        <div
                            block="ProductActions"
                            elem="chance_finder"
                            key={CHANCE_FINDER}
                        >
                            <CmsBlock identifier={CHANCE_FINDER} />
                        </div>
                    )}
                </>
            );
        }

        return null;
    }

    renderDermoRecommendedBlock() {
        const {
            product: {
                attributes: { dermo_recommended: { attribute_value = 0 } = {} } = {},
            } = {},
            product,
        } = this.props;

        if (!attribute_value || attribute_value === "0") {
            return null;
        }

        return (
            <div block="ProductActions" elem="DermoRecommended">
                <CmsBlock identifier="dermo_recommended_pdp" />
            </div>
        );
    }

    renderLimitationMessage() {
        const { hasLimitation, areDetailsLoaded } = this.props;

        if (hasLimitation && areDetailsLoaded) {
            return (
                <div className="ProductActions_limitation">
                    <div className="label">
                        {__("Eligible for shipping only in Greece")}
                    </div>
                </div>
            );
        }

        return null;
    }

    renderOfferPriceDetails() {
        const { areDetailsLoaded } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        const {
            configurableVariantIndex,
            product,
            product: {
                type_id,
                variants,
            }
        } = this.props;

        let productOrVariant =
            type_id === CONFIGURABLE && configurableVariantIndex !== -1
                ? variants[configurableVariantIndex]
                : product;

        let {
            attributes: variantAttributes = {},
            is_reduced_price: isReducedPrice,
            is_foucsia_price: isFoucsiaPrice,
            price_range: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage = 0
                    } = {},
                    final_price: {
                        value = 0,
                        currency
                    } = {},
                    regular_price: {
                        value: originalValue = 0
                    }
                }
            }
        } = productOrVariant;

        let {
            // promo_valid_until = {},
            last_final_price = {},
            offer_end_date = {}
        } = variantAttributes;

        // const dateObj = new Date(promo_valid_until.attribute_value);
        const dateObj = new Date(offer_end_date.attribute_value);

        if (discountPercentage > 0 && originalValue > 0.05 && !isFoucsiaPrice && !isReducedPrice)
            return (
                <div block="ProductActions" elem="OfferPriceDetails">
                    {offer_end_date && offer_end_date.attribute_value ?
                        <div>
                            <>{__("Η προσφορά ισχύει μέχρι ")}</>
                            <strong>{`${dateObj.getDate()}.${dateObj.getMonth() + 1 < 10 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1}.${dateObj.getFullYear()}`}</strong>
                        </div>
                        : null
                    }
                    {last_final_price && last_final_price.attribute_value && last_final_price.attribute_value > 0 ?
                        <div>
                            <>{__("Η χαμηλότερη τιμή που καταγράφηκε τις προηγούμενες 30 ημέρες: ")}</>
                            <strong>{formatPrice(last_final_price.attribute_value, currency)}</strong>
                        </div>
                        :

                        <div>
                            <>{__("Η χαμηλότερη τιμή που καταγράφηκε τις προηγούμενες 30 ημέρες: ")}</>
                            <strong>{formatPrice(originalValue, currency)}</strong>
                        </div>

                    }
                </div>
            )
        return null;
    }

    renderDesktop() {
        const {
            product: { type_id },
            product,
        } = this.props;

        if (type_id === "amgiftcard") {
            return (
                <>
                    <h1 block="ProductActions" elem="Heading">
                        {this.renderName()}
                    </h1>
                    {this.renderAlternativeName()}
                    {this.renderConfigurableAttributes()}
                    {this.renderCustomizableOptions()}
                    {this.renderBundleItems()}
                    {this.renderGroupedItems()}
                    {this.renderDownloadableProductSample()}
                    {this.renderDownloadableProductLinks()}
                    {this.renderTierPrices()}
                    {this.renderPriceWithGlobalSchema()}
                    {this.renderProductAlerts()}
                    {this.renderAddToCartActionBlock()}
                    {this.renderHelpHtml()}
                    {this.renderSkuNBarcode()}
                </>
            );
        }

        return (
            <>
                {this.renderProductTags()}
                {this.renderHeading()}
                {this.renderAlternativeName()}
                {this.renderReviewSection()}
                {this.renderTierPrices()}
                {this.renderPriceWithGlobalSchema()}
                {this.renderOfferPriceDetails()}
                {this.renderRelatedProducts()}
                {this.renderConfigurableAttributes()}
                {this.renderCustomizableOptions()}
                {this.renderBundleItems()}
                {this.renderGroupedItems()}
                {this.renderDownloadableProductSample()}
                {this.renderDownloadableProductLinks()}
                {this.renderProductAlerts()}
                {this.renderAddToCartActionBlock()}
                {this.renderFindStoreHtml()}
                {this.renderHelpHtml()}
                {this.renderSkuNBarcode()}
                {this.renderLimitationMessage()}
                {this.renderPerfumeHtml()}
                {this.renderCategoriesHtml()}
                {/* {this.renderDermoRecommendedBlock()} */}
                {this.renderChanceFinder()}
                {this.renderCustomListHtml()}
            </>
        );
    }

    renderMobile() {
        const {
            product: { type_id },
        } = this.props;
        const isWithoutPriceTotal = type_id === GROUPED;

        if (type_id === "amgiftcard") {
            return (
                <>
                    {this.renderConfigurableAttributes()}
                    {this.renderCustomizableOptions()}
                    {this.renderBundleItems()}
                    {this.renderGroupedItems()}
                    {this.renderDownloadableProductSample()}
                    {this.renderDownloadableProductLinks()}
                    {this.renderTierPrices()}
                    {this.renderPriceWithGlobalSchema()}
                    {this.renderProductAlerts()}
                    {this.renderAddToCartActionBlock()}
                    {this.renderHelpHtml()}
                    {this.renderSkuNBarcode()}
                </>
            );
        }

        return (
            <>
                {this.renderProductTags()}
                {this.renderHeading()}
                {this.renderAlternativeName()}
                {this.renderReviewSection()}
                {this.renderTierPrices()}
                {this.renderPriceWithGlobalSchema()}
                {this.renderOfferPriceDetails()}
                {this.renderRelatedProducts()}
                {this.renderConfigurableAttributes()}
                {this.renderCustomizableOptions()}
                {this.renderBundleItems()}
                {this.renderGroupedItems()}
                {this.renderDownloadableProductSample()}
                {this.renderDownloadableProductLinks()}
                {this.renderProductAlerts()}
                {this.renderAddToCartActionBlock()}
                {this.renderFindStoreHtml()}
                {this.renderHelpHtml()}
                {this.renderSkuNBarcode()}
                {this.renderLimitationMessage()}
                {this.renderPerfumeHtml()}
                {this.renderCategoriesHtml()}
                {/* {this.renderDermoRecommendedBlock()} */}
                {this.renderChanceFinder()}
                {this.renderCustomListHtml()}
            </>
        );
    }
}

export default ProductActions;
