const around_getUrlResolverFields = (args, callback, instance) => {
    let result = callback(...args);
    result.push('is_hero');
    result.push('option_id');
    result.push('attribute_code');
    return result;
}
export default {
    'Query/UrlRewrites': {
        'member-function': {
            '_getUrlResolverFields': around_getUrlResolverFields
        },
    }
}
