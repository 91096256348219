import Link from 'Component/Link';
import './styles/SearchPage.styles.scss';

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        suggestedTerms: state.ProductListReducer?.currentArgs?.suggestedTerms
        
    }
};

const componentDidUpdate = (args, callback, instance) => {
    const {
        history,
        match: { params: { query }  },
        suggestedTerms,
        isSearchPage
    } = instance.props;
    const [ prevProps  ] = args;

    callback.apply(instance, args);
}

const defaultProps = (_args, _callback, _instance) => { 
    
    return {
        ..._args,
        suggestedTerms: {}
    }
}

const extendConfig = (_args, _callback, _instance) => {
    
      return {
        ..._args,
        pageSize: 24
        };
}

const renderSuggestedTerm = ( {term, url }) => { 
    const { origin } = location;
    url = url.replace(/\%/g, "");
    url = origin + url;

    if(!term && !url) {
        return null;
    }

    return (
        <div block="CategoryFilterOverlay" elem="SuggestedTerm">
            <Link to= { url }>{ term }</Link>
        </div>   
    )
}
 
const renderNoProducts = (noResult) => {

    if(!noResult) {
        return null;
    }

    return (
        <div 
            block="CategoryFilterOverlay"
            elem="SuggestedTerms"
            mods= {{ noResult: true}} 
            >
            <h4>{__("We couldn't find anything relevant. Try one of the below searches.")}</h4>
        </div>
    );
}

const renderSuggestedTerms = (_instance) => {
    const { suggestedTerms = {}, totalItems = 0 } = _instance.props;
    const noResult = !totalItems > 0;
    if (!suggestedTerms || !Object.values(suggestedTerms).length > 0) {
        return null;
    }

    return (
        <>
            { renderNoProducts(noResult) }
            <div
                block="CategoryFilterOverlay"
                elem="SuggestedTerms"
                mods={{ noResult }}
            >
                {Object.values(suggestedTerms).slice(0, 10).map(renderSuggestedTerm)}
            </div>
        </>
    )
}

const renderMiscellaneous =  (_args, _callback, _instance) => {
    const { suggestedTerms } = _instance.props;

    return [
            renderSuggestedTerms(_instance),
            _callback(..._args)
    ];
}

const containerProps = (_args, _callback, _instance) => { 
    const { suggestedTerms } = _instance.props;
    return {
        ..._callback(..._args),
        suggestedTerms
    };
}

export const config = {
    'Route/CategoryPage/Component': {
        'member-function': {
            renderMiscellaneous
        }
    },
    'Route/CategoryPage/Container': {
        'member-property': { 
            config: extendConfig
        },
        'static-member': {
            defaultProps
        },
        'member-function': {
            componentDidUpdate: componentDidUpdate,
            containerProps
        }
    },
    'Route/CategoryPage/Container/mapStateToProps': {
        function: {
            position: 111,
            implementation: mapStateToProps
        }
    },
};

export default config;
