import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import { getProductInStock } from 'Util/Product/Extract';
import { ProductCardContainer as SourceProductCardContainer } from 'SourceComponent/ProductCard/ProductCard.container';
// import { fetchQuery, getErrorMessage } from 'Util/Request';
// import ProductListQuery from 'Query/ProductList.query';
export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    base_link_url: state.ConfigReducer.base_link_url || '',
    product_use_categories: state.ConfigReducer.product_use_categories || false,
    category_url_suffix: state.ConfigReducer.category_url_suffix,
    isWishlistEnabled: state.ConfigReducer.wishlist_general_active
});

/** @namespace Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    state = {
        quantity: 1,
        parameters: {},
        configurableVariantIndex: -1,
        onHover: false,
        // extra_image: {},
        // isMediaRequested: false
    };

    containerFunctions = {
        getAttribute: this.getAttribute.bind(this),
        isConfigurableProductOutOfStock: this.isConfigurableProductOutOfStock.bind(this),
        isBundleProductOutOfStock: this.isBundleProductOutOfStock.bind(this),
        updateConfigurableVariant: this.updateConfigurableVariant.bind(this),
        showSelectOptionsNotification: this.showSelectOptionsNotification.bind(this),
        setQuantity: this.setQuantity.bind(this),
        onHoverToggle: this.onHoverToggle.bind(this)
    };

    _getThumbnail() {
        const { onHover} = this.state;
        const { product: { media_gallery_entries = {} } } = this.props;
        const product = this._getProductOrVariant();
        const { small_image: { url } = {} } = product;

        if(onHover && media_gallery_entries[1]) {
            return media_gallery_entries[1]?.base?.url || media_gallery_entries[1]?.thumbnail?.url;
        }

        if (this._isThumbnailAvailable(url)) {
            return url;
        }

        // If thumbnail is, missing we try to get image from parent
        const { product: { small_image: { url: parentUrl } = {} } } = this.props;
        if (this._isThumbnailAvailable(parentUrl)) {
            return parentUrl;
        }

        return '';
    }

    onHoverToggle() {
        const { onHover } = this.state;
        // if(!isMediaRequested) {
        //     this.requestMediaGallery()
        // } else {
             this.setState({
                onHover: !onHover
            });
        // }
    }

    // requestMediaGallery() {
    //     const product = this._getProductOrVariant();
    //     const { onHover, extra_image } = this.state;
    //     const { sku: productSKU } = product;

    //     if(extra_image?.url) {
    //         return this.setState({
    //             onHover: !onHover
    //         })
    //     }

    //     const options = {
    //         isMediaOnly: true,
    //         isSingleProduct: true,
    //         args: { 
    //             search: productSKU,
    //         }
    //     };

    //     fetchQuery(
    //         ProductListQuery.getQuery(options)
    //     ).then(
    //         (({ products }) => {
    //             const { items } = products
    //             const [media] = items;

    //             this.setState( () =>  {
    //             if(!media?.media_gallery_entries[1]) {
    //                 return { 
    //                     isMediaRequested: true,
    //                 }
    //             }
                
    //             return { 
    //                 isMediaRequested: true,
    //                 onHover: !onHover,
    //                 extra_image: media?.media_gallery_entries[1]?.thumbnail || {}
    //             }
    //         });
    //         })
    //     );
    // }

    containerProps() {
        const {
            children,
            device,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            isWishlistEnabled,
            layout,
            mix,
            product,
            renderContent,
            setSiblingsHaveBrands,
            setSiblingsHavePriceBadge,
            siblingsHaveBrands,
            siblingsHaveConfigurableOptions,
            siblingsHavePriceBadge,
            additionalClass,
            isRelated = false,
            isSampleProducts = false,
            isProductPage = false,
            itemPosition,
            position
        } = this.props;

        const { configurableVariantIndex, parameters, quantity, onHover } = this.state;
        
        return {
            isProductPage,
            itemPosition,
            children,
            device,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            isWishlistEnabled,
            layout,
            mix,
            product,
            renderContent,
            setSiblingsHaveBrands,
            setSiblingsHavePriceBadge,
            siblingsHaveBrands,
            siblingsHaveConfigurableOptions,
            siblingsHavePriceBadge,
            additionalClass,
            configurableVariantIndex,
            quantity,
            parameters,
            isRelated,
            isSampleProducts,
            currentVariantIndex: this._getCurrentVariantIndex(),
            productOrVariant: this._getProductOrVariant(),
            thumbnail: this._getThumbnail(),
            linkTo: this._getLinkTo(),
            inStock: getProductInStock(product, configurableVariantIndex),
            onHover,
            position
        };
    }

    setQuantity(value) {
        this.setState({ quantity: +value });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
