/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MetaContainer as SourceMetaContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/Meta/Meta.container';

import Meta from './Meta.component';

/** @namespace Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    containerProps() {
        const {
            canonical_url,
            default_title,
            title_prefix,
            title_suffix
        } = this.props;

        return {
            metadata: this._getMetadata(),
            canonical_url,
            default_title,
            title: this._getTitle(),
            title_prefix,
            title_suffix
        };
    }

    /**
     * don't fallback to default_description
     * @returns String
     */
    _getDescription() {
        const { description } = this.props;
        return description;
    }

    _getTitle() {
        const { title, default_title, title_prefix, title_suffix } = this.props;

        return `${title_prefix?title_prefix+' | ':''}${title || default_title}${title_suffix?' | '+title_suffix:''}`;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
