import BrowserDatabase from "SourceUtil/BrowserDatabase";
import { SAMPLE_PRODUCTS } from "../route/SampleProductCategoryPage/SampleProductCategoryPage.config";
import { appendWithStoreCode } from 'SourceUtil/Url';
import { getItemIdArray, getSampleQtyLimit } from "../utils";

export const SampleProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/SampleProduct/SampleProduct.dispatcher'
);

const mapStateToProps = (_args, _callback, _instance) => {
    const [state] = _args;

    return {
        ..._callback.apply(_instance, _args),
        sampleProductRule: state.ConfigReducer.sample_product_price_range_count,
        sampleProductList: state.SampleProductsReducer.sampleProducts
        
    }
};

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        requestSampleProducts:(options) => SampleProductDispatcher.then(({ default: dispatcher }) =>
                dispatcher.handleData(dispatch, options))
    };
};

const renderSecureCheckoutButton = (_args, _callback, _instance) => {
    const { 
        onCheckoutButtonClick, 
        hasOutOfStockProductsInCart, 
        history, 
        isSampleDisable 
    } = _instance.props;

    if (hasOutOfStockProductsInCart) {
        return (
            <div block="CartPage" elem="OutOfStockProductsWarning">
                { __('Please, remove out of stock products from cart') }
            </div>
        );
    }

    return (
        <div block="CartPage" elem="CheckoutButtonWrapper">
            <button
                block="CartPage"
                elem="CheckoutButton"
                mix={ { block: 'Button' } }
                onClick={ onCheckoutButtonClick }
            >
                { __('Proceed to checkout') }
            </button>
            <button
                disabled= { isSampleDisable }
                block="CartPage"
                elem="CheckoutButton"
                mods={ { isHollow: true } }
                mix={ { block: 'Button'} }
                onClick={ () => history.push({ pathname: appendWithStoreCode(SAMPLE_PRODUCTS)}) }
            >
                { __('Add Sample Products') }
            </button>
        </div>
    );
}

const containerProps  = (_args, _callback, _instance) =>  {
    const { history, sampleProductRule, sampleProductList } = _instance.props;
    const { isSampleDisable = false } = _instance.state;
    return {
        ..._callback.apply(_instance, _args),
        history,
        sampleProductRule,
        totalSamples: sampleProductList?.items?.length || 0,
        isSampleDisable
    };
};

const onCheckoutButtonClick = (_args, _callback, _instance) => {
    const { history } = _instance.props;
    const isSkip = BrowserDatabase.getItem(SAMPLE_PRODUCTS) || false;

    // if (!isSkip) {
    //     history.push({
    //         pathname: appendWithStoreCode(SAMPLE_PRODUCTS)
    //     });
    //     window.scrollTo({ top: 0 });

    //     return;
    // }

    return [
        _callback.apply(_instance, _args)
    ]
}

const componentDidMount = (args, callback, instance) => {
    const { 
        totals: { items = [] } = {},
        sampleProductList: {
            items: productSample = []
        } = {},
        requestSampleProducts
    } = instance.props;

    if (items && items.length > 0) {
        const options = {
            in: {
                ...getItemIdArray(items),
            },
            activePage: 1
        };
        requestSampleProducts(options)
    }

    if(items.length > 0 || productSample.length > 0 ) {
        instance.setState(() => {
            const totalSamples = productSample?.length || 0;
            return {
                isSampleDisable: getSampleQtyLimit(instance.props) == 0 || totalSamples == 0 ? true : false
            }

        })
    }
    return callback.apply(instance, args);
}

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;   
    const { 
        totals = {}, 
        totals: { items } = {},
        requestSampleProducts,
        sampleProductList: {
            items: productSample = []
        } = {},
    } = instance.props;

    const { 
        totals: prevTotals, 
        sampleProductList: {
            items: prevProductSample = []
        } = {},
    } = prevProps;
    
    if (JSON.stringify(totals) !== JSON.stringify(prevTotals)) {
        const options = {
            in: {
                ...getItemIdArray(items),
            },
            activePage: 1
        };
        requestSampleProducts(options)
    }

    if(JSON.stringify(productSample) !== JSON.stringify(prevProductSample) ||
       JSON.stringify(totals) !== JSON.stringify(prevTotals)) {
        instance.setState(() => {
            const totalSamples = productSample?.length || 0;
            return {
                isSampleDisable: getSampleQtyLimit(instance.props) == 0 || totalSamples == 0 ? true : false
            }

        })
    }

    return callback.apply(instance, args);
}
const aroundState = (_args, context) => ({
    ..._args,
    isSampleDisable: false
});


export default {
    'Route/CartPage/Container/mapStateToProps': {
        function: {
            position: 191,
            implementation: mapStateToProps
        }
    },
    'Route/CartPage/Container/mapDispatchToProps': {
        function: [
            {
                position: 191,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Route/CartPage/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            onCheckoutButtonClick,
            containerProps
        }
    },
    'Route/CartPage/Component': { 
        'member-function': { 
            renderSecureCheckoutButton
        },
        'member-property':{
            state: aroundState
        }
    }
};