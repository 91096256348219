/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { getErrorMessage } from 'Util/Request';

import MyAccountSignIn from './MyAccountSignIn.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isEmailAvailable: state.CheckoutReducer.isEmailAvailable
});

/** @namespace Component/MyAccountSignIn/Container/mapDispatchtoProps */
export const mapDispatchToProps = (dispatch) => ({
    signIn: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.signIn(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

import { MyAccountSignInContainer as SourceMyAccountSignInContainer } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';


/** @namespace Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {

    containerFunctions = {
        onSignInSuccess: this.onSignInSuccess.bind(this),
        onSignInAttempt: this.onSignInAttempt.bind(this)
    };

    containerProps = () => {
        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            cmsBlock
        } = this.props;

        return {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            cmsBlock
        };
    };

    async onSignInSuccess(fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState
        } = this.props;

        try {
            await signIn(fields);
            onSignIn();
        } catch (error) {
            showNotification('error', getErrorMessage(error));
        }

        setLoadingState(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
