import { lazy } from 'react';

import CheckoutQuery from 'Query/Checkout.query';
import { DETAILS_STEP } from 'Route/Checkout/Checkout.config';
import { updateTotals } from 'Store/Cart/Cart.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import getStore from 'Util/Store';

const GUEST_QUOTE_ID = 'guest_quote_id';
export const CartCoupon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CartCoupon'
));
export const ExpandableContent = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/ExpandableContent'
));

class ApplyCouponFixPlugin {
    onShippingEstimationFieldsChange(args, callback, instance) {
        const [shippingAddress] = args;
        instance.setState({ shippingAddress });
        return callback.apply(instance, args);
    }

    onCouponApply(args, callback, instance) {
        const [ isCart ] = args;
        if(!isCart){
            const { selectedShippingMethod, shippingAddress : _shippingAddress } = instance.state;
            const { company, id, save_in_address_book, tax_office, business_activity, vat_id ,...shippingAddress} = _shippingAddress;
            instance.onShippingEstimationFieldsChange(shippingAddress);
            const { method_code, carrier_code } = selectedShippingMethod;
            const { shippingFields } = getStore().getState().CheckoutReducer;
            const addressInformation = {
                billing_address: {},
                shipping_address: {
                    country_id: getStore().getState().ConfigReducer.shipping_origin_country_id
                },
                shipping_carrier_code: carrier_code,
                shipping_method_code: method_code,
            };

            if (shippingFields.country_id) {
                addressInformation.shipping_address = shippingFields;
            }
            fetchMutation(CheckoutQuery.getSaveAddressInformation(addressInformation, BrowserDatabase.getItem(GUEST_QUOTE_ID).token)).then(({ saveAddressInformation: data }) => {
                const { totals, payment_methods } = data;
                const { cartTotals } = getStore().getState().CartReducer;
                const { items } = cartTotals;
                const event = new CustomEvent("updatePaymentMethods", { detail : { payment_methods, resetPayments : false } });
                document.dispatchEvent(event);
                getStore().dispatch(updateTotals({ ...getStore().getState().CartReducer.cartTotals, ...totals, items }));
                BrowserDatabase.setItem(totals, 'PAYMENT_TOTALS', ONE_MONTH_IN_SECONDS);
                if (totals.shipping_amount !== null) {
                    BrowserDatabase.setItem(totals.shipping_amount.toString(), 'shipping_amount', ONE_MONTH_IN_SECONDS);
                } else {
                    BrowserDatabase.deleteItem('shipping_amount');
                }
            }, (e) => {
                console.log(e);
            });
            instance._getPaymentMethods();
        }else {
            const { totals : { shipping_method } } = instance.props;
            if(shipping_method){
                let [ method_code, carrier_code ] = shipping_method.split('_');
                const addressInformation = {
                    billing_address: {},
                    shipping_address: {
                        country_id: getStore().getState().ConfigReducer.shipping_origin_country_id
                    },
                    shipping_carrier_code: method_code,
                    shipping_method_code: carrier_code
                };
                fetchMutation(CheckoutQuery.getSaveAddressInformation(addressInformation, BrowserDatabase.getItem(GUEST_QUOTE_ID).token)).then(({ saveAddressInformation: data }) => {
                    const { totals, payment_methods } = data;
                    const { cartTotals } = getStore().getState().CartReducer;
                    const { items } = cartTotals;
                    const event = new CustomEvent("updatePaymentMethods", { detail : { payment_methods, resetPayments : false } });
                    document.dispatchEvent(event);
                    getStore().dispatch(updateTotals({ ...getStore().getState().CartReducer.cartTotals, ...totals, items }));
                    BrowserDatabase.setItem(totals, 'PAYMENT_TOTALS', ONE_MONTH_IN_SECONDS);
                    if (totals.shipping_amount !== null) {
                        BrowserDatabase.setItem(totals.shipping_amount.toString(), 'shipping_amount', ONE_MONTH_IN_SECONDS);
                    } else {
                        BrowserDatabase.deleteItem('shipping_amount');
                    }
                }, (e) => {
                    console.log(e);
                });
            }
        }
    }

    renderDiscountCode(args, callback, instance) {
        const {
            totals: { coupon_code },
            checkoutStep,
            onCouponApply
        } = instance.props;

        if (checkoutStep === DETAILS_STEP) {
            return;
        }

        return (
            <ExpandableContent
                heading={ __('Have a discount code?') }
                mix={ { block: 'CartPage', elem: 'Discount' } }
            >
                <CartCoupon couponCode={ coupon_code } onCouponApply={ onCouponApply } />
            </ExpandableContent>
        );
    }
    renderDiscountCartCode(args, callback, instance) {
        const {
            totals: { coupon_code },
            checkoutStep,
            onCouponApply
        } = instance.props;

        if (checkoutStep === DETAILS_STEP) {
            return;
        }

        return (
            <ExpandableContent
                heading={ __('Have a discount code?') }
                mix={ { block: 'CartPage', elem: 'Discount' } }
            >
                <CartCoupon couponCode={ coupon_code } onCouponApply={ onCouponApply } isCart />
            </ExpandableContent>
        );
    }

    containerFunctions(prop, instance) {
        return {
            ...prop,
            onCouponApply: instance.onCouponApply.bind(instance)
        };
    }

    handleApplyCouponToCart(args, callback, instance) {
        const [couponCode] = args;
        const { applyCouponToCart, onCouponApply, isCart } = instance.props;

        instance.setState({ isLoading: true });

        applyCouponToCart(couponCode).then(
            /** @namespace Component/CartCoupon/Container/applyCouponToCartThen */
            () => {
                instance.setState({ isLoading: false });
                if (onCouponApply) {
                    onCouponApply(isCart);
                }
            }
        );
    }

    handleRemoveCouponFromCart(args, callback, instance) {
        const { removeCouponFromCart, onCouponApply, isCart } = instance.props;

        instance.setState({ isLoading: true });

        removeCouponFromCart().then(
            /** @namespace Component/CartCoupon/Container/removeCouponFromCartThen */
            () => {
                instance.setState({ isLoading: false });
                if (onCouponApply) {
                    onCouponApply(isCart);
                }
            }
        );
    }
}

const {
    onShippingEstimationFieldsChange, onCouponApply, renderDiscountCode, containerFunctions, handleApplyCouponToCart, handleRemoveCouponFromCart, renderDiscountCartCode
} = new ApplyCouponFixPlugin();

export const config = {
    'Route/Checkout/Container': {
        'member-function': {
            onShippingEstimationFieldsChange,
            onCouponApply
        },
        'member-property': {
            containerFunctions
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDiscountCode
        }
    },
    'Route/CartPage/Component': {
        'member-function': {
            renderDiscountCode : renderDiscountCartCode
        }
    },
    'Route/CartPage/Container': {
        'member-function': {
            onCouponApply
        },
        'member-property': {
            containerFunctions
        }
    },
    'Component/CartCoupon/Container': {
        'member-function': {
            handleApplyCouponToCart,
            handleRemoveCouponFromCart
        }
    }
};

export default config;
