import ProductAdvanceReview from '../component/ProductAdvanceReview';
import ProductReviewItem from 'SourceComponent/ProductReviewItem';
import ProductReviewList from 'SourceComponent/ProductReviewList';

export const EnhacnedRegistrationDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '@mageguide/enhacned-registration/src/store/EnhacnedRegistration/EnhacnedRegistration.dispatcher'
);

const renderNoRating = (props) => {
    const { device: { isMobile } } = props;

    if (isMobile) {
        return (
            <p>
                { __('There are no reviews yet! Click button below to submit one!') }
            </p>
        );
    }

    return (
        <p>
            { __('There are no reviews yet! Click button on the right to submit one!') }
        </p>
    );
}

const mapStateToProps = (args, callback, instance) => {
    const { getCustomData } = args[0];
    return {
        ...callback.apply(instance, args),
        customAttributeMetadata: getCustomData.customAttributeMetadata
    }
};

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        requestCustomerData: () => EnhacnedRegistrationDispatcher.then(
            ({ default: dispatcher }) => dispatcher.getCustomerAttributeFields(dispatch)
        )
    }
}

const componentDidMount = (args, callback, instance) => {
    const { requestCustomerData, customAttributeMetadata } = instance.props;
    requestCustomerData();

    return callback.apply(instance, args);
}

const containerProps = (args, callback, instance) => {
    const { customAttributeMetadata } = instance.props;
    return {
        ...callback.apply(instance, args),
        customAttributeMetadata
    };
}


const renderRatingData = (args, callback, instance) => {
        const {
            product: {
                review_summary: {
                    rating_summary,
                    review_count
                } = {}
            },
            product
        } = instance.props;

        const STARS_COUNT = 5;
        const PERCENT = 100;

        // eslint-disable-next-line no-mixed-operators
        const percent = parseFloat(STARS_COUNT * (rating_summary || 0) / PERCENT).toFixed(2);

        if (!review_count) {
            return renderNoRating(instance.props);
        }

        return <ProductAdvanceReview product= { product }/>
    }

    const renderList = (args, callback, instance) => {
        const { product, customAttributeMetadata } = instance.props;

        return (
            <ProductReviewList
              product={ product }
              customAttributeMetadata= { customAttributeMetadata }
            />
        );
    }    


    const renderReviews = (args, callback, instance) => {
        const { product: { reviews }, customAttributeMetadata } = instance.props;

        return reviews.map((reviewItem, i) => (
            <ProductReviewItem
              customAttributeMetadata= { customAttributeMetadata }
              reviewItem={ reviewItem }
              // eslint-disable-next-line react/no-array-index-key
              key={ i }
            />
        ));
    }

export const config = {
    'Component/ProductReviewList/Component': {
        'member-function': { 
            renderReviews
        }
    },
    'Component/ProductReviews/Container/mapDispatchToProps':{
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/ProductReviews/Container/mapStateToProps': { 
        function: [
             {
                 position: 101,
                 implementation: mapStateToProps
             }
         ]
     },
     'Component/ProductReviews/Container': {
        'member-function': { 
            componentDidMount,
            containerProps 
        }
    },
    'Component/ProductReviews/Component': {
        'member-function': { 
            renderRatingData,
            renderList
        }
    }
};

export default config;