import RewardPointsQuery from '../query/RewardPoints.query';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';


export class ConfigPlugin {
    prepareRequest = (args, callback, instance) => {
        return [
            ...callback(args),
                RewardPointsQuery.getRewardConfig()
               ];
    };

    addRewardToConfigReducerInitialState = (args, callback, instance) => {
        
            return {
                ...callback(...args),
                getRewardConfig: { is_enable: false }
        };
    };

    addRewardPointToConfigUpdate = (args, callback, context) => {
        const [, action] = args;
        const originalUpdatedState = callback.apply(context, args);
        
        if (!action) {
            return originalUpdatedState;
        }
        
        const { config: {
            getRewardConfig = {} ,
            } = {}, type } = action;
            
        if (type !== UPDATE_CONFIG) {
            return originalUpdatedState;
        }
        
        return {
            ...originalUpdatedState,
            getRewardConfig
        };
    };
}

const { 
    prepareRequest, 
    addRewardToConfigReducerInitialState, 
    addRewardPointToConfigUpdate 
} = new ConfigPlugin();

export const config = {
    'Store/Config/Reducer/getInitialState': {
        'function': addRewardToConfigReducerInitialState
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            'prepareRequest': prepareRequest
        }
    },
    'Store/Config/Reducer': {
        'function': addRewardPointToConfigUpdate
    }
};

export default config;