
import { switchWebsite } from './WebSwitch.action'

export class WebsiteSwitch {
    switchWebsite = (isFashion, dispatch) => {
        dispatch(switchWebsite(isFashion));
    }

}

export default new (WebsiteSwitch)();
