import { debounce } from 'SourceUtil/Request';
import { LOADING_TIME } from 'SourceRoute/CmsPage/CmsPage.config';
import { isHomePageUrl } from 'SourceUtil/Url';
import { CMS_PAGE } from 'SourceComponent/Header/Header.config';
import CmsPageQuery from 'Query/CmsPage.query';
import UrlRewritesQuery from 'SourceQuery/UrlRewrites.query';

const updateMetaForPDP = (args, callback, instance) => {
    const { updateMetaFromProduct, redirect_from, redirect_code_previous } = instance.props;
    const product = { ...instance.getDataSource(), redirect_code_previous, redirect_from };

    updateMetaFromProduct(product);
}

const requestPage = (args, callback, instance) => {
    const params = instance.getRequestQueryParams();
    const { id, identifier } = params;

    if (!id && !identifier) {
        return;
    }

    instance.setState({ isLoading: true });

    instance.fetchData(
        isHomePageUrl(location.pathname) ?
            [CmsPageQuery.getQuery(params),
            UrlRewritesQuery.getQuery({ urlParam: location.pathname })
            ] : [CmsPageQuery.getQuery(params)],
        instance.onPageLoad,
        () => instance.setState({ isLoading: false })
    );

}

const onPageLoad = (args, callback, instance) => {

    const [{ cmsPage: page }, urlResolver = {}] = args;

    const {
        location: { pathname },
        updateMeta,
        setHeaderState,
        updateBreadcrumbs,
        redirect_from,
        redirect_code_previous
    } = instance.props;

    const {
        content_heading,
        meta_title,
        title,
        meta_description,
        meta_keywords
    } = page;

    debounce(instance.setOfflineNoticeSize, LOADING_TIME)();

    updateBreadcrumbs(page);

    updateMeta({
        title: meta_title || title,
        description: meta_description,
        keywords: meta_keywords,
        canonical_url: window.location.href,
        redirect_from: isHomePageUrl(pathname) ? window.location.pathname : redirect_from,
        redirect_code_previous: isHomePageUrl(pathname) ? urlResolver?.redirect_info?.redirect_code_previous || redirect_code_previous : redirect_code_previous,
        default_store_description: meta_description
    });

    if (!isHomePageUrl(pathname)) {
        setHeaderState({
            name: CMS_PAGE,
            title: content_heading,
            onBackClick: () => history.goBack()
        });
    }

    instance.setState({ page, isLoading: false, isPageLoaded: true });
};



export default {
    'Route/CmsPage/Container': {
        'member-function': {
            // onPageLoad,
            // requestPage
        }
    },
    'Route/ProductPage/Container': {
        'member-function': {
            updateMeta: updateMetaForPDP
        }
    }
};