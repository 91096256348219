import {Field} from 'Util/Query';

const getSelectedFilterFiled = () => {
    return [
        'filter_code',
        'selected_values'
    ]
}
const around_getUrlResolverFields = (args, callback, instance) => {
    let result = callback(...args);
    result.push('search_query');
    result.push('H1');
    result.push('canonical_url');
    result.push('title');
    result.push('meta_title');
    result.push('meta_description');
    result.push('description');
    result.push('extended_description');
    result.push('banner_link');
    result.push('robotContent');
    result.push('image');
    result.push(new Field('SelectedFilters')
        .setAlias('SelectedFilters')
        .addFieldList(getSelectedFilterFiled())
    );
    return result;
}
export default {
    'Query/UrlRewrites': {
        'member-function': {
            '_getUrlResolverFields': around_getUrlResolverFields
        },
    }
}
