import { cloneElement } from 'react';

const renderResetButton = (args, callback, instance) => {
    const result = callback.apply(instance, args);
    return result ? cloneElement(
        result,
        { category: instance.props.category }
    ) : result;
};

export const config = {
    'Component/CategoryFilterOverlay/Component': {
        'member-function': {
            renderResetButton: [
                {
                    position:100,
                    implementation: renderResetButton
                }
            ]
        }
    }
}

export default config;
