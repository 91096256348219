import {
    EVENT_GTM_PRODUCT_ADD_TO_CART, EVENT_GTM_PRODUCT_REMOVE_WISH_LIST
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-namespace
export const aroundAfterAddToCart = (args, callback, instance) => {
    const {
        product,
        product: {
            type_id, variants,
            wishlist: {
                quantity,
                sku
            }
        },
        event
    } = instance.props;

    const configurableVariantIndex = variants.findIndex((variant) => variant.sku === sku);

    if (type_id === 'grouped') {
        event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: {
                ...product
            },
            isGrouped: true
        });
    } else {
        const productToAdd = variants
            ? { ...product, configurableVariantIndex }
            : product;

        event(EVENT_GTM_PRODUCT_ADD_TO_CART, {
            product: productToAdd,
            quantity,
            configurableVariantIndex
        });
    }

    return callback(...args);
};

export const aroundAftertoggleProductInWishlist = (args, callback, instance) => {
    const {
        product,
        product: {
            type_id, variants,
            wishlist: {
                quantity,
                sku
            }
        },
        event
    } = instance.props;
    const configurableVariantIndex = variants.findIndex((variant) => variant.sku === sku);

    if (type_id === 'grouped') {
        event(EVENT_GTM_PRODUCT_REMOVE_WISH_LIST, {
            product: {
                ...product
            },
            isGrouped: true
        });
    } else {
        const productToAdd = variants
            ? { ...product, configurableVariantIndex }
            : product;

        event(EVENT_GTM_PRODUCT_REMOVE_WISH_LIST, {
            product: productToAdd,
            quantity,
            configurableVariantIndex
        });
    }

    return callback(...args);
};

export default {
    'Component/WishlistItem/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/WishlistItem/Container': {
        'member-function': {
            addItemToCart: aroundAfterAddToCart,
            toggleProductInWishlist: aroundAftertoggleProductInWishlist
        }
    }
};
