const appendTokenToHeaders = (args, callback) => {
    const result = callback(...args);
    if (window.recaptchaValidation) {
        return {
            ...result,
            'X-ReCaptcha': window.recaptchaValidation
        };
    }

    return result;
};

export const config = {
    'Util/Request/appendTokenToHeaders': {
        function: [
            {
                position: 101,
                implementation: appendTokenToHeaders
            }
        ]
    }
};

export default config;
