/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import FieldPassword from '../component/FieldPassword';

export class FieldComponentPlugin {
    renderInputOfType  = (args, callback, instance) => {
        let type = args[0];
        if(type === 'password')
            return <FieldPassword { ...instance.props } />
       return callback.apply(instance,args);
    };
}

const { renderInputOfType } = new FieldComponentPlugin();

export const config = {
    'Component/Field/Component': {
        'member-function': {
            renderInputOfType: renderInputOfType
        }
    }
};

export default config;
