import PropTypes from 'prop-types';
import media from 'SourceUtil/Media';
import { LOGO_MEDIA } from 'SourceUtil/Media/Media';

class extendMeta {

    mapStateToProps(_args, _callback, _instance) {
        const { MetaReducer, ConfigReducer } = _args[0];

        return {
            ..._callback.apply(_instance, _args),
            image: MetaReducer?.image,
            type: MetaReducer?.type,
            default_image: ConfigReducer?.header_logo_src,
            default_store_description: ConfigReducer?.default_description,
            og_image: MetaReducer?.og_image
        }
    };

    defaultProps(_args, _callback, _instance) {

        return {
            ..._args,
            image: '',
            default_image: '',
            default_store_description: '',
            type: ''
        };
    }

    _getOgMetadata(_args, _callback, _instance) {
        const {
            image,
            default_image,
            canonical_url,
            type,
            og_image
        } = _instance.props;

        const logoSrc = default_image ? media(default_image, LOGO_MEDIA) : null;

        const ogmeta = {
            title: _instance._getTitle(),
            description: _instance._getDescription(),
            image: image || logoSrc,
            url: canonical_url || window.location,
            type,
            og_image: og_image || false
        };

        return _instance._generateMetaFromMetadata(ogmeta);
    }


    propTypes(_args, _callback, _instance) {
        return {
            _args,
            image: PropTypes.string,
            default_image: PropTypes.string,
            default_store_description: PropTypes.string,
            type: PropTypes.string
        }
    }

    containerProps(_args, _callback, _instance) {
        const data = {
            ..._callback(..._args),
            ogmeta: _instance._getOgMetadata(),
            default_store_description: _instance.props.default_store_description
        };

        return data;
    }

    renderOgTags(_args, _callback, _instance) {
        const { ogmeta, default_store_description } = _instance.props;
        const ogImgAvailable = ogmeta.find(item => {
            if (item.name === 'og_image' && (item.content !== 'false' || !item.content))
                return item
        })

        return (
            <>
                {ogmeta.map((tag) => {
                    let {
                        name = null,
                        property = null,
                        content = null
                    } = tag;

                    if (name === 'description' && !content) {
                        content = default_store_description;
                    }

                    if (name === 'image' && ogImgAvailable) {
                        content = ogImgAvailable.content;
                    }

                    if (name === 'og_image') {
                        return null;
                    }

                    return (
                        <meta
                            key={name || property}
                            name={`og:${name}`}
                            content={content}
                        />
                    );
                })}
            </>
        )
    }

    renderMeta(_args, _callback, _instance) {

        return (
            <>
                {_callback.apply(_instance, _args)}
                {_instance.renderOgTags(_instance)}
            </>
        )
    }

    // Don't omit but add description with content null if its value is null
    _generateMetaFromMetadata(_args, _callback, _instance) {
        const [metadata, param = 'name'] = _args;
        let results = _callback.apply(_instance, _args);
        if (metadata.hasOwnProperty('description') && !metadata['description']) {
            return [
                ...results,
                { [param]: 'description', content: null }
            ];
        }
        return results;
    }
}

const {
    renderMeta,
    renderOgTags,
    containerProps,
    mapStateToProps,
    propTypes,
    defaultProps,
    _getOgMetadata,
    _generateMetaFromMetadata
} = new extendMeta();

export default {
    'Component/Meta/Component': {
        "member-function": {
            renderMeta,
            renderOgTags
        }
    },
    'Component/Meta/Container/mapStateToProps': {
        function: [
            {
                position: 110,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/Meta/Container': {
        'member-function': {
            containerProps,
            _getOgMetadata,
            _generateMetaFromMetadata
        },
        'static-member': {
            defaultProps,
            propTypes,
        }
    }
};
