import getStore from 'Util/Store';
import { getApiSearchStatus } from '@mageguide/external-search/src/utlis/Request';

const mergeArray = (product, customAttributeMetadata = []) => product?.attributes.reduce((acc, pdA) => {
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();

    if (!customAttributeMetadata?.length > 0 || search_is_enabled && use_in_fulltext && location.pathname.includes('search/')) {
        return acc.concat({
                ...pdA,
            });;
    }

    const pdFA = customAttributeMetadata.find(pdFA => pdFA.attribute_code === pdA.attribute_code );
    let attributeValue = /[^\w\s]/g.test(pdA?.attribute_value) && pdA?.attribute_type !== 'multiselect' ? Number(pdA?.attribute_value) : pdA?.attribute_value;
    let attributeOptions = pdFA?.attribute_options?.filter(({ value }) => value == attributeValue || value == pdA?.attribute_value ) || [];

    if(!attributeOptions.length > 0 && /,|\?/.test(attributeValue)) {
        attributeValue = attributeValue.split(',');
        attributeValue.forEach(element => {
            attributeOptions = attributeOptions.length > 0 ? [ ...attributeOptions,
                ...pdFA?.attribute_options?.filter(({ value }) => value == element || value == element) || []] :
                pdFA?.attribute_options?.filter(({ value }) => value == element || value == element) || [] 
        }, []);
    }

    return pdFA && !pdA?.attribute_options?.length > 0 && attributeOptions?.length > 0 ? 
    acc.concat(
        {
            ...pdA, 
            attribute_value: attributeValue, 
            attribute_options: attributeOptions.length > 0 ? attributeOptions : []
        }) : acc.concat({
            ...pdA,
            attribute_value: pdA?.attribute_value,
            attribute_options: pdA?.attribute_options?.length > 0 ? pdA?.attribute_options : [] 
        });
}, []);

export const extendCartDataProducts = (items = {}, isProductList) => items.map((item) => {
    const store = getStore();
    const {
        ConfigReducer: {
            customAttributeMetadata = []
        } = {}
    } = store.getState();

    if(isProductList && item?.attributes) {
        return {
            ...item,
            attributes: mergeArray(item, customAttributeMetadata)
        }
    }

    const { product } = item;
    const attributes = mergeArray(product, customAttributeMetadata);

    return {
            ...item,
            product: {
                ...product,
                attributes
            }
    };
});

