import ProductAdvanceReview from './ProductAdvanceReview.component';
import { PureComponent } from 'react';
import { ProductType } from 'SourceType/ProductList';

export class ProductAdvanceReviewContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    containerProps() {
        const { product } = this.props;

        return { 
            product,
            reviewData: this.getReviewData(),
            avgProgress: this.getReviewProgress()
         };
    }

    getReviewProgress() {
        const {
            product: {
                reviews = {}
            }
        } = this.props;

        var avgProgress = [];
        var ratingCodeData = [];
        if (reviews !== null && Object.keys(reviews).length > 0) {
            reviews.map(({ average_rating, rating_votes }, i) => {
                if (average_rating <= 20) {
                    avgProgress[0] = { ...avgProgress[0], [i]: average_rating }
                }
                if (average_rating > 20 && average_rating <= 40) {
                    avgProgress[1] = { ...avgProgress[1], [i]: average_rating }
                }
                if (average_rating > 40 && average_rating <= 60) {
                    avgProgress[2] = { ...avgProgress[2], [i]: average_rating }
                }
                if (average_rating > 60 && average_rating <= 80) {
                    avgProgress[3] = { ...avgProgress[3], [i]: average_rating }
                }
                if (average_rating > 80 && average_rating <= 100) {
                    avgProgress[4] = { ...avgProgress[4], [i]: average_rating }
                }
                ratingCodeData[i] = rating_votes;
            });
        }

        return avgProgress;
    }

    getReviewData() {
        const {
            product: {
                reviews
            }
        } = this.props;
        var data = [];

        if(reviews !== null){
            const reviewData = reviews.reduce((i, review) => {
                const { rating_votes } = review;

                return [...i, rating_votes];
            }, []);
            if (Object.keys(reviews).length > 0) {
                reviewData.map((review, i) => {
                    review.map(({ rating_code, percent }, i) => {
                        const { Price = [], Quality = [], Value = [] } = data;
                        switch (rating_code) {
                            case "Price":
                                return data[rating_code] = [...Price, percent];
                            case "Quality":
                                return data[rating_code] = [...Quality, percent];
                            case "Value":
                                return data[rating_code] = [...Value, percent];
                            default:
                                break;
                        }
                    });
                });
            }
        }

        return data;
    }


    render() {
        return (
            <ProductAdvanceReview
            { ...this.containerProps() }
            />
        )
    }

}

export default ProductAdvanceReviewContainer