/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export class FieldContainerPlugin {

    __construct = (args, callback, instance) => {
        callback.apply(instance,args);
        instance.state = {
            ...instance.state,
            areaCode : '1',
            maxlength : 99
        };
    }
}

const { __construct } = new FieldContainerPlugin();

export const config = {
    'Component/Field/Container': {
        'member-function': {
            '__construct': __construct
        }
    }
};

export default config;
