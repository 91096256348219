import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './AlartPoup.style';
import { hideActivePopup } from 'SourceStore/Overlay/Overlay.action';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { hideActiveOverlay } from 'SourceStore/Overlay/Overlay.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';

/** @namespace Component/AlartPoup/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload
});

export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    resetHideActivePopup: () => dispatch(hideActivePopup(false))
});

/** @namespace Component/AlartPoup/Component */
export class AlartPoup extends PureComponent {
  static propTypes = {
    Action: PropTypes.func.isRequired,
  };

  static defaultProps = {
    Action: () => {},
    isConfirm: false
  };

  closeOverly = () => {
    const {
      hideActiveOverlay,
      resetHideActivePopup,
      goToPreviousHeaderState,
    } = this.props;

    hideActiveOverlay();
    goToPreviousHeaderState();
    resetHideActivePopup();
  };

  onClick = () => {
    const { Action, payload } = this.props;

    if (payload.ALART_POPUP_REMOVE) {
      const {
        ALART_POPUP_REMOVE: { Action },
      } = payload;
      this.closeOverly();
      return Action();
    }
    this.closeOverly();
    return Action();
  };

    renderAction() {
      const { isConfirm } = this.props;
      if (!isConfirm) {
        return (
          <div block='AlartPoup'>
            <div
              block='AlartPoup'
              elem='Action'
              onClick={this.onClick}
              className="Button"
            >
              {__("Yes")}
            </div>
            <div
              block='AlartPoup'
              elem='Action'
              onClick={this.closeOverly}
              className="Button Button_isHollow"
            >
              {__("No")}
            </div>
          </div>
        )
      }

      return (
        <div block='AlartPoup'>
          <div
            block='AlartPoup'
            elem='Action'
            onClick={ this.onClick }
            className="Button"
          >
            {__("Confirm")}
          </div>
        </div>
      )
    }

  render() {
    return this.renderAction();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlartPoup);
