import ProductCard from 'SourceComponent/ProductCard';
import { GRID_LAYOUT } from 'SourceRoute/CategoryPage/CategoryPage.config';
import { event as Event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { ROOT, SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH
} from '../component/GoogleTagManager/GoogleTagManager.events';
// due to any override if the event is not passed to the component we are using this to dispatch event directly.
import getStore from 'SourceUtil/Store';

const state = (original) => ({
    ...original,
    lastSearch: ''
});

const renderItems = (args, callback, instance) => {
    const {
        items,
        event,
        location: {
            pathname
        },
        isLoading
    } = instance.props;

    const { fired = false } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (isSearch && !fired && !isLoading) {
        instance.setState({ fired: true });
        const search = pathname.split(ROOT).pop();
        const { dispatch } = getStore();

        event ? 
            event(EVENT_GTM_IMPRESSIONS_SEARCH, {
                products: items, list: SEARCH, search
            }) : 
        dispatch(
            Event(EVENT_GTM_IMPRESSIONS_SEARCH, {
                products: items, list: SEARCH, search
            })
        );
    }

    return callback(...args);
};

const renderPageItems = (args, callback, instance) => {
    const {
        items,
        selectedFilters,
        event,
        mix: {
            mods: {
                layout = GRID_LAYOUT
            } = {},
            block = ''
        },
        location: {
            pathname,
            search
        },
        sliderOptions: {
            additionalClass = ''
        } = {}
    } = instance.props;
    const { fired = false } = instance.state;
    const isSearch = pathname.includes(SEARCH);

    if (!isSearch) {
        if (!(Object.keys(items).length === 0) && !fired) {
            instance.setState({ fired: true });
            const { dispatch, getState } = getStore();

            if(additionalClass.includes("CartPage-GiftBox_choose_bag")) {
                const { PopupReducer: { popupPayload = {} } = {} } = getState();
                if(popupPayload['GiftBox_Popup']){ 
                    event ? 
                    event(EVENT_GTM_IMPRESSIONS_PLP, {
                        products: items, list: block, pathname, search
                    }) :
                    dispatch(Event(EVENT_GTM_IMPRESSIONS_PLP, {
                        products: items, list: block, pathname, search
                    }));
                }
            } else {

            event ? 
            event(EVENT_GTM_IMPRESSIONS_PLP, {
                products: items, list: block, pathname, search
            }) :
            dispatch(Event(EVENT_GTM_IMPRESSIONS_PLP, {
                products: items, list: block, pathname, search
            }));
            }
        }
    }

    return callback(...args);
};

// const componentDidUpdate = (args, callback, instance) => { 
//     const prevProps = args[0];
//     const {
//         items,
//         selectedFilters,
//         event,
//         mix: {
//             mods: {
//                 layout = GRID_LAYOUT
//             } = {},
//             block = ''
//         },
//         location: {
//             pathname,
//             search
//         },
//         isLoading,
//         pageNumber
//     } = instance.props;

//     const { items: prevItems, pageNumber: prevPageNumber } = prevProps;
//     const { fired = false } = instance.state;
//     const isSearch = pathname.includes(SEARCH);

//     if (!isSearch && items !== prevItems) {
//         if (!(Object.keys(items).length === 0) && !fired) {
//             instance.setState({ fired: true });
//             const { dispatch } = getStore();
//             event ? 
//             event(EVENT_GTM_IMPRESSIONS_PLP, {
//                 products: items, list: block, pathname, search
//             }) :
//             dispatch(Event(EVENT_GTM_IMPRESSIONS_PLP, {
//                 products: items, list: block, pathname, search
//             }));
//         }
//     }

//     return callback(...args);
// }

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            renderPageItems,
            renderItems,
            // componentDidUpdate
        },
        'member-property': {
            state
        }
    }
};
