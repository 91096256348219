import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED
} from 'Util/Product';
import TextPlaceholder from 'Component/TextPlaceholder';
import Image from 'Component/Image';
import ProductPrice from 'Component/ProductPrice';
import Field from 'Component/Field';
import Link from 'Component/Link';
import Html from 'Component/Html';
import AddToCart from 'Component/AddToCart';
import { DeviceType } from 'Type/Device';
import { LayoutType } from 'Type/Layout';
import { ProductType } from 'Type/ProductList';
import PropTypes from "prop-types";
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import Loader from 'Component/Loader';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';
import { FASHION_SET, PIM_FASHION_ACCESSORIES, PIM_FASHION, PIM_FASHION_SIZE } from "../ProductAttributeSet/ProductAttributeSet.config";
import { lazy } from "react";
import {
    PRODUCT_DISCOUNT_EXCLUDE,
    BRAND_CHANEL,
    SAMPLE_CATEGORY,
    BAG_CATEGORY,
    CARDS_CATEGORY,
    PRODUCT_LABEL_BLACK,
    LUXURY_ITEM
} from 'Route/ProductPage/ProductPage.config';
import { removeHtmlFromString } from '../../util/String';
import { appendWithStoreCode } from 'Util/Url';

const GIFT = 'amgiftcard';

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
));

/**
 * Product card
 * @class ProductCard
 * @namespace Component/ProductCard/Component
 */
export class ProductCard extends SourceProductCard {
    static propTypes = {
        linkTo: PropTypes.shape({}),
        product: ProductType.isRequired,
        device: DeviceType.isRequired,
        thumbnail: PropTypes.string,
        getAttribute: PropTypes.func.isRequired,
        registerSharedElement: PropTypes.func.isRequired,
        children: PropTypes.element,
        isLoading: PropTypes.bool,
        mix: PropTypes.shape({}),
        renderContent: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        isConfigurableProductOutOfStock: PropTypes.func.isRequired,
        isBundleProductOutOfStock: PropTypes.func.isRequired,
        hideWishlistButton: PropTypes.bool,
        isWishlistEnabled: PropTypes.bool.isRequired,
        hideCompareButton: PropTypes.bool,
        siblingsHaveBrands: PropTypes.bool,
        setSiblingsHaveBrands: PropTypes.func,
        siblingsHavePriceBadge: PropTypes.bool,
        setSiblingsHavePriceBadge: PropTypes.func,
        siblingsHaveConfigurableOptions: PropTypes.bool,
        layout: LayoutType,
        updateConfigurableVariant: PropTypes.func.isRequired,
        configurableVariantIndex: PropTypes.number,
        parameters: PropTypes.shape({}).isRequired,
        showSelectOptionsNotification: PropTypes.func.isRequired,
        productOrVariant: ProductType.isRequired,
        inStock: PropTypes.bool.isRequired,
        quantity: PropTypes.number.isRequired
    };

    shouldComponentUpdate(nextProps) {
        const {
            product,
            device,
            productOrVariant,
            parameters,
            layout,
            quantity,
            maxSampleAdded,
            onHover
        } = this.props;

        const {
            product: nextProduct,
            device: nextDevice,
            productOrVariant: nextProductOrVariant,
            parameters: nextParameters,
            layout: prevLayout,
            quantity: prevQuantity,
            maxSampleAdded: prevMaxSampleAdded,
            onHover: preOnHover
        } = nextProps;

        return product !== nextProduct
            || device !== nextDevice
            || productOrVariant !== nextProductOrVariant
            || parameters !== nextParameters
            || quantity !== prevQuantity
            || layout !== prevLayout
            || maxSampleAdded !== prevMaxSampleAdded
            || onHover !== preOnHover
    }

    renderConfigurableOptions() {
        const {
            product: { configurable_options }
        } = this.props;

        if (configurable_options) {
            const objectSrc = Object.values(configurable_options).reduce((acc, { values }) => {
                acc += values.length;
                return acc;
            }, 0);

            return (
                <div block="ProductCard" elem="ProductConfigurableAttributes">
                    {objectSrc > 0 ? __('%s variations', objectSrc) : ''}
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="ProductConfigurableAttributes"></div>
        );
    }

    renderBrandValue() {
        const {
            linkTo,
            product: {
                attributes = {},
                brandData
            } = {},
        } = this.props;

        const url_alias = brandData?.url_alias ? brandData?.url_alias : null;

        let brand = attributes !== undefined &&
            attributes.brand !== undefined &&
            attributes.brand.attribute_options[attributes.brand.attribute_value] !== undefined ?
            attributes.brand.attribute_options[attributes.brand.attribute_value].label : null;

        if (!brand || !Object.keys(attributes)?.length > 0) {
            return false;
        }

        const url = window.location.origin + appendWithStoreCode('/' + url_alias);

        return (
            <div className={`ProductAttributeValue ProductCard-BrandAttributeValue ${brand}`}>
                {url_alias ?
                    <Link to={url} >
                        <TextPlaceholder content={brand} length="medium" />
                    </Link> :
                    <TextPlaceholder content={brand} length="medium" />
                }
            </div>
        );
    }

    renderCustomLabel = (attribute) => {
        const { label } = attribute;

        if (PRODUCT_LABEL_BLACK.includes(label.toLowerCase())) {
            // if(label.includes('OFFER')) {
            return (
                <div block="ProductCard" elem="TagBlack">{label}</div>
            );
        }

        if (label) {
            return (
                <div block="ProductCard" elem="Tag" className={label.replace(/\s/g, '')}>{label}</div>
            );
        }

        return null;
    }

    renderCustomLabels() {
        const {
            product: {
                attributes: {
                    mg_custom_lists: {
                        attribute_options = {},
                    } = {}
                } = {},
                mg_custom_lists = []
            } = {},
        } = this.props;

        if (!mg_custom_lists || mg_custom_lists.length === 0) {
            return null
        }

        if (!Array.isArray(mg_custom_lists) && mg_custom_lists.length > 0) {
            return (
                <div block="ProductCard" elem="Tag">{mg_custom_lists}</div>
            );
        }

        const uniqueMgCustomLists = mg_custom_lists.filter(
            (mg_custom_list, index, array) => (
                index === array.findIndex((g) => g.label === mg_custom_list.label)
            )
        );

        return (
            <div block="ProductCard" elem="Tags">
                {Array.isArray(mg_custom_lists) ?
                    uniqueMgCustomLists.map(this.renderCustomLabel) :
                    Object.values(mg_custom_lists).map(this.renderCustomLabel)
                }
            </div>
        );
    }


    renderExclusiveLabel() {
        const {
            product: {
                attributes: {
                    badge_exclusive: {
                        attribute_value = 0
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!attribute_value || attribute_value === '0') {
            return null
        }

        return (
            <div block="ProductCard" elem="TagBlack">
                {__('EXCLUSIVE')}
            </div>
        );
    }

    renderWebCustomLabels() {
        const {
            product: {
                attributes: {
                    att_105: {
                        attribute_options = {},
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!Object.keys(attribute_options).length > 0) {
            return null
        }

        return Object.values(attribute_options).map(({ label }) => {
            let tagClass = "Tag";

            if (PRODUCT_LABEL_BLACK.includes(label.toLowerCase())) {
                tagClass = "TagBlack";
            }

            return (
                <div block="ProductActions" elem={tagClass}>
                    {label}
                </div>
            );
        });
    }

    renderBestsellerLabel() {
        const {
            product: {
                attributes: {
                    badge_bestseller: {
                        attribute_value = 0
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!attribute_value || attribute_value === '0') {
            return null
        }

        return (
            <div block="ProductCard" elem="TagGrey">
                {__('BEST SELLER')}
            </div>
        );
    }


    renderNewinLabel() {
        const {
            product: {
                attributes: {
                    badge_newin: {
                        attribute_value = 0
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!attribute_value || attribute_value === '0') {
            return null
        }

        return (
            <div block="ProductCard" elem="TagGrey">
                {__('NEW IN')}
            </div>
        );
    }

    renderVeganLabel() {
        const {
            product: {
                attributes: {
                    att_106: {
                        attribute_value,
                        attribute_options
                    } = {}
                } = {}
            } = {}
        } = this.props;


        if (!attribute_value ||
            attribute_options[attribute_value]?.label !== 'Vegan')
            return null

        return (
            <div block="ProductCard" elem="TagVegan">
                <UniversalIcon
                    src={media('badge_vegan.png', WYSIWYG_MEDIA)}
                    alt="VeganIcon"
                />
            </div>
        );
    }

    renderWebExclusiveLabel() {
        const {
            product: {
                attributes: {
                    att_103: {
                        attribute_options = {},
                        attribute_value
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!attribute_value > 0 || attribute_options[attribute_value]?.label !== '15') {
            return null
        }

        return (
            <div block="ProductCard" elem="TagBlack">
                {__('WEB EXCLUSIVE')}
            </div>
        );
    }



    renderHcExclusiveLabel() {
        const {
            product: {
                attributes: {
                    att_103: {
                        attribute_options = {},
                        attribute_value
                    } = {}
                } = {}
            } = {}
        } = this.props;


        if (!attribute_value > 0 || attribute_options[attribute_value]?.label !== '50+') {
            return null
        }

        return (
            <div block="ProductCard" elem="TagBlack">
                {__('HC EXCLUSIVE')}
            </div>
        );
    }

    renderOnlyInStoreLabel() {
        const {
            product: {
                attributes: {
                    att_103: {
                        attribute_options = {},
                        attribute_value
                    } = {}
                } = {}
            } = {}
        } = this.props;


        if (!attribute_value > 0 || attribute_options[attribute_value]?.label !== '50') {
            return null
        }

        return (
            <div block="ProductCard" elem="TagBlack">
                {__('ONLY IN STORES')}
            </div>
        );
    }

    renderProductPrice() {
        const {
            product: {
                categories = [],
                variants = [],
            },
            product,
            isConfigurableProductOutOfStock,
            isBundleProductOutOfStock
        } = this.props;

        const isDisable = categories.filter(({ id }) => id == BAG_CATEGORY || id == SAMPLE_CATEGORY || id == CARDS_CATEGORY);

        const isWishlist = product?.wishlist;
        const productOrVariant = isWishlist && variants.length ? (variants.find(({ sku }) => sku == isWishlist.sku)) : product;

        const {
            price_range: {
                minimum_price: {
                    final_price
                } = {}
            } = {},
            price_range,
            price_tiers = [],
            type_id,
            is_reduced_price,
            is_foucsia_price,
            is_value_label,
            attributes = {}
        } = productOrVariant;

        let unitLabel = attributes?.unit_label?.attribute_value ? attributes.unit_label.attribute_value : null;
        let unitCalcModifier = attributes?.unit_product_price_per_unit_calc?.attribute_value ? attributes.unit_product_price_per_unit_calc.attribute_value : null;
        let isLuxuryItem = attributes?.att_108?.attribute_value ? attributes?.att_108?.attribute_value === LUXURY_ITEM ? attributes?.att_108?.attribute_value : null : null;

        if (isDisable.length !== 0) {
            return null;
        }

        if (!price_range) {
            return <TextPlaceholder />;
        }

        let brand = attributes?.brand?.attribute_value ? attributes.brand.attribute_options[attributes.brand.attribute_value]?.label : null;

        switch (type_id) {
            // case CONFIGURABLE:
            //     if (isConfigurableProductOutOfStock()) {
            //         return this.renderEmptyProductPrice();
            //     }
            //     break;
            case BUNDLE:
                if (isBundleProductOutOfStock()) {
                    return this.renderEmptyProductPrice();
                }
                break;
            default:
                break;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <ProductPrice
                    price={price_range}
                    price_tiers={price_tiers}
                    isReducedPrice={is_reduced_price}
                    isFoucsiaPrice={is_foucsia_price}
                    isValueLabel={is_value_label}
                    // showDiscount={true}
                    showDiscount={PRODUCT_DISCOUNT_EXCLUDE.includes(brand) ? false : true}
                    mix={{ block: 'ProductCard', elem: 'Price' }}
                    label={!isWishlist && this.renderProductTypePriceBadge()}
                    unitLabel={(unitCalcModifier && !isLuxuryItem) && unitLabel}
                    unitCalcModifier={(unitCalcModifier && !isLuxuryItem) && unitCalcModifier}
                />
            </div>
        );
    }

    renderVisibleOnHover() {
        const {
            linkTo,
            isSampleProducts,
            additionalClass,
            product: {
                type_id
            }
        } = this.props;

        if (additionalClass === 'HeroBrandPage__chanel_product_slider') {
            return (
                <>
                    {this.renderConfigurableLinkWrapper((
                        this.renderConfigurableOptions()
                    ))}
                    <div block="ProductCard" elem="Footer">
                        <Link
                            block="Button AddToCart"
                            to={linkTo}
                            onClick={this.registerSharedElement}
                        >
                            {__('DISCOVER')}
                        </Link>
                    </div>
                </>
            );
        }

        if (isSampleProducts || type_id === GIFT) {
            return (
                <>
                    <div block="ProductCard" elem="Footer">
                        {this.renderAddToCart()}
                    </div>
                </>
            );
        }

        // if (device.isMobile) {
        //     return null;
        // }

        return (
            <>
                {this.renderConfigurableLinkWrapper((
                    this.renderConfigurableOptions()
                ))}
                <div block="ProductCard" elem="Footer">
                    {this.renderAddToCart()}
                </div>
            </>
        );
    }

    renderGiftShortDescription() {
        const { product: { short_description }, isLoading } = this.props;
        const { html } = short_description || {};

        if (!html && !isLoading) {
            return null;
        }

        return (
            <div
                block="ProductCard"
                elem="ShortDesc"
                mods={{ isLoaded: !!name }}
            >
                {html ? <Html content={html} /> : <p><TextPlaceholder length="long" /></p>}
            </div>
        );
    }

    renderQuantityInput() {
        const {
            quantity,
            maxQuantity,
            minQuantity,
            setQuantity,
            product: { type_id }
        } = this.props;

        if (type_id === GROUPED) {
            return null;
        }

        return (
            <Field
                id="item_qty"
                name="item_qty"
                type="number"
                value={quantity}
                max={100}
                min={1}
                mix={{ block: 'ProductActions', elem: 'Qty' }}
                onChange={setQuantity}
            />
        );
    }

    renderAddToCart() {
        const {
            product,
            product: {
                type_id,
                attribute_set_id,
                options = [],
                configurable_options: confOptions = {},
                categories = []
            },
            configurableVariantIndex,
            layout,
            showSelectOptionsNotification,
            inStock,
            additionalClass,
            isLoading
        } = this.props;

        const isDisable = categories.filter(({ id }) => id == BAG_CATEGORY || id == SAMPLE_CATEGORY || id == CARDS_CATEGORY)?.length != 0;

        if (attribute_set_id === FASHION_SET ||
            attribute_set_id === PIM_FASHION_ACCESSORIES ||
            attribute_set_id === PIM_FASHION ||
            attribute_set_id === PIM_FASHION_SIZE ||
            isDisable)
            return null;

        const quantity = 1;
        const groupedProductQuantity = {};

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions
        };

        const redirectOnConfig = type_id === CONFIGURABLE
            && Object.keys(confOptions).length !== Object.keys(this.getAttributesToShow()).length;

        if (additionalClass === 'HeroBrandPage__Single_Product_block') {
            return (
                <AddToCart
                    product={product}
                    configurableVariantIndex={configurableVariantIndex}
                    mix={{ block: 'ProductActions', elem: 'AddToCart' }}
                    quantity={quantity}
                    groupedProductQuantity={groupedProductQuantity}
                    productOptionsData={productOptionsData}
                    titleUpperCase={true}
                    disabled={isLoading ? isLoading : !inStock}
                    layout={layout}
                />
            );
        }

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button
                    rel='nofollow'
                    block="Button AddToCart"
                    mods={{ layout }}
                    onClick={showSelectOptionsNotification}
                >
                    {__('Add To Cart')}
                </button>
            );
        }

        return (
            <AddToCart
                product={product}
                configurableVariantIndex={configurableVariantIndex}
                mix={{ block: 'ProductActions', elem: 'AddToCart' }}
                quantity={quantity}
                groupedProductQuantity={groupedProductQuantity}
                productOptionsData={productOptionsData}
                disabled={!inStock}
                layout={layout}
            />
        );
    }

    renderGiftCardAddToCart() {
        const {
            product,
            product: {
                type_id,
                attribute_set_id,
                options = [],
                configurable_options: confOptions = {}
            },
            configurableVariantIndex,
            layout,
            showSelectOptionsNotification,
            inStock,
            quantity
        } = this.props;

        const groupedProductQuantity = {};

        if (attribute_set_id === FASHION_SET || attribute_set_id === PIM_FASHION_ACCESSORIES || attribute_set_id === PIM_FASHION || attribute_set_id === PIM_FASHION_SIZE)
            return null;

        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const productOptionsData = {
            requiredOptions
        };

        const redirectOnConfig = type_id === CONFIGURABLE
            && Object.keys(confOptions).length !== Object.keys(this.getAttributesToShow()).length;

        if (type_id === BUNDLE || type_id === GROUPED || redirectOnConfig) {
            return (
                <button
                    rel='nofollow'
                    block="Button AddToCart"
                    mods={{ layout }}
                    onClick={showSelectOptionsNotification}
                >
                    {__('Add To Cart')}
                </button>
            );
        }

        return (
            <AddToCart
                product={product}
                configurableVariantIndex={configurableVariantIndex}
                mix={{ block: 'ProductActions', elem: 'AddToCart' }}
                quantity={quantity}
                groupedProductQuantity={groupedProductQuantity}
                productOptionsData={productOptionsData}
                disabled={!inStock}
                layout={layout}
            />
        );
    }

    renderFigureLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (
                <div
                    block="ProductCard"
                    elem="FigureReview"
                >
                    {children}
                </div>
            );
        }

        return (
            <Link
                block="ProductCard"
                elem="FigureReview"
                to={linkTo}
                onClick={this.registerSharedElement}
                mix={mix}
            >
                {children}
            </Link>
        );
    }

    renderContentLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url } } = this.props;

        if (!url) {
            return (
                <div
                    block="ProductCard"
                    elem="Content"
                >
                    {children}
                </div>
            );
        }

        return (
            <Link
                block="ProductCard"
                elem="Content"
                to={linkTo}
                onClick={this.registerSharedElement}
                mix={mix}
            >
                {children}
            </Link>
        );
    }

    renderConfigurableLinkWrapper(children, mix = {}) {
        const { linkTo, product: { url, type_id } } = this.props;

        if (type_id === GIFT) {
            return null;
        }

        if (!url) {
            return (
                <div
                    block="ProductCard"
                    elem="ProductConfigurableAttributes"
                >
                    {children}
                </div>
            );
        }

        return (
            <Link
                block="ProductCard"
                elem="ProductConfigurableAttributes"
                to={linkTo}
                onClick={this.registerSharedElement}
                mix={mix}
            >
                {children}
            </Link>
        );
    }

    renderMainDetails() {
        const { product: { name, attributes } } = this.props;

        let brandChanel = attributes?.brand?.attribute_value
            && attributes?.brand?.attribute_options[attributes.brand.attribute_value]?.label === BRAND_CHANEL ?
            attributes?.brand?.attribute_options[attributes.brand.attribute_value]?.label : null;

        let alternativeName = attributes?.alternative_name?.attribute_value && brandChanel ? attributes.alternative_name.attribute_value : '';


        return (
            <>
                <p
                    block="ProductCard"
                    elem="Name"
                    mods={{ isLoaded: !!name }}
                    className={brandChanel}
                >
                    <TextPlaceholder content={removeHtmlFromString(name)} length="medium" />
                </p>
                {brandChanel ? <p
                    block="ProductCard"
                    elem="AlternativeName"
                >
                    {alternativeName ? alternativeName : null}
                </p> : null
                }
            </>
        );
    }

    renderCardContent() {
        const {
            renderContent,
            additionalClass,
            isRelated,
            isSampleProducts,
            isSearchPage,
            product: {
                type_id
            }
        } = this.props;

        const isGiftP = type_id === GIFT;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (additionalClass === 'CartPage-GiftBox_choose_bag') {
            return (
                <div
                    block="ProductCard"
                    elem="Link"
                >
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                        {this.renderMainDetails()}
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderProductPrice()}
                        {this.renderAddToCart()}
                    </div>
                </div>

            );
        }

        if (additionalClass === 'beauty-cms_block-10__product_slider') {
            return (
                <div
                    block="ProductCard"
                    elem="Link"
                >
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                        {this.renderAdditionalProductDetails()}
                        {this.renderMainDetails()}
                        {this.renderGiftShortDescription()}
                        <div block="ProductCard" elem="VisibleOnHover">
                            {this.renderVisibleOnHover()}
                        </div>
                    </div>
                </div>

            );
        }

        if (additionalClass === 'CartPage-GiftBox_choose_card') {
            return (
                <div
                    block="ProductCard"
                    elem="Link"
                >
                    <div block="ProductCard" elem="FigureReview">
                        {this.renderMainDetails()}
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderProductPrice()}
                        {this.renderQuantityInput()}
                        {this.renderGiftCardAddToCart()}
                    </div>
                </div>
            );
        }

        if (isRelated) {
            return (
                <>
                    {this.renderCardLinkWrapper((
                        <>
                            <div block="ProductCard" elem="FigureReview">
                                <figure block="ProductCard" elem="Figure">
                                    {this.renderPicture()}
                                </figure>
                            </div>
                        </>
                    ))}
                </>
            );
        }

        if (isSampleProducts || isGiftP) {
            return (
                <>
                    <div
                        block="ProductCard"
                        elem="Link"
                    >
                        {isGiftP ?
                            <>
                                {this.renderFigureLinkWrapper((
                                    <figure block="ProductCard" elem="Figure">
                                        {this.renderPicture()}
                                    </figure>
                                ))}
                                {this.renderAdditionalProductDetails()}
                                {this.renderContentLinkWrapper((
                                    <>
                                        {this.renderMainDetails()}
                                        <div block="ProductCard" elem="VisibleOnHover">
                                            {this.renderVisibleOnHover()}
                                        </div>
                                    </>
                                ))} </> : <>
                                <figure block="ProductCard" elem="Figure">
                                    {this.renderPicture()}
                                </figure>
                                {this.renderAdditionalProductDetails()}
                                {this.renderMainDetails()}
                                <div block="ProductCard" elem="VisibleOnHover">
                                    {this.renderVisibleOnHover()}
                                </div>
                            </>
                        }
                    </div>
                </>
            );
        }

        return (
            <>
                <div
                    block="ProductCard"
                    elem="Link"
                >
                    {this.renderFigureLinkWrapper((
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                            {this.renderCustomLabels()}
                            <div block="ProductCard" elem="TagsBottom">
                                {this.renderBestsellerLabel()}
                                {/*{ this.renderNewinLabel() }*/}
                                {this.renderVeganLabel()}
                                {/* { this.renderWebExclusiveLabel() } */}
                                {/* { this.renderHcExclusiveLabel() } */}
                                {/* { this.renderOnlyInStoreLabel() } */}
                                {/* { this.renderExclusiveLabel() } */}
                                {this.renderWebCustomLabels()}
                            </div>
                        </figure>
                    ))}
                    {this.renderContentLinkWrapper((
                        <>
                            {this.renderModifaceIcon()}
                        </>
                    ))}
                    {this.renderAdditionalProductDetails()}
                    {this.renderContentLinkWrapper((
                        <>
                            {this.renderMainDetails()}
                            {this.renderReviews()}
                            {this.renderProductPrice()}
                        </>
                    ))}
                    <div block="ProductCard" elem="VisibleOnHover">
                        {this.renderVisibleOnHover()}
                    </div>
                </div>
                {this.renderProductCardWishlistButton()}
            </>
        );
    }


    renderPicture(mix = {}) {
        const { product: { id, name }, hoverImage, thumbnail, onHoverToggle, onHover } = this.props;

        this.sharedComponent = (
            <Image
                imageRef={this.imageRef}
                src={thumbnail}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
            />
        );

        return (
            <div
                onMouseOver={onHoverToggle}
                onMouseOut={onHoverToggle}
                onTouchStart={onHoverToggle}
            >
                {this.sharedComponent}
                <img src={this.props.product?.product_label} style={{ display: 'none' }} />
                <img
                    style={{ display: 'none' }}
                    alt={name}
                    src={thumbnail}
                />
            </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout,
            product: {
                id,
                sku,
                attributes
            }
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveConfigurableOptions,
            layout
        };

        let brandClass = attributes !== undefined &&
            attributes.brand !== undefined &&
            attributes.brand.attribute_options[attributes.brand.attribute_value] !== undefined ?
            attributes.brand.attribute_options[attributes.brand.attribute_value].label : '';

        if (layout === LIST_LAYOUT) {
            return (
                <li
                    block="ProductCard"
                    mods={mods}
                    mix={mix}
                    id={`product_card_${id}`}
                    data-productid={sku}
                    className={brandClass}
                >
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <li
                block="ProductCard"
                mods={mods}
                mix={mix}
                id={`product_card_${id}`}
                data-productid={sku}
                className={brandClass}
            >
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default ProductCard;
