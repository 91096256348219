import { EVENT_GTM_PRODUCT_DETAIL } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const componentDidUpdate = (args, callback, instance) => {
    const { event, product = {} } = instance.props;
    const areDetailsLoaded = instance.getAreDetailsLoaded();
    const { fired = false } = instance.state;

    if (areDetailsLoaded && !fired) {
        instance.setState({ fired: true });
        event(EVENT_GTM_PRODUCT_DETAIL, { ...product });
    }

    return callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    },
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
