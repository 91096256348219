import { formatCurrency, roundPrice } from 'Util/Price';
import getStore from 'Util/Store';
import Field from 'Component/Field/Field.container';
class CheckoutPaymentComponentPlugin {
    render(args,callback,instance){
        const { method : { code }} = instance.props;
        if('msp_cashondelivery' === code ){
            const {
                isSelected,
                method: { title }
            } = instance.props;
            return  <li block="CheckoutPayment">
                <button
                    block="CheckoutPayment"
                    mods={ { isSelected } }
                    elem="Button"
                    type="button"
                    onClick={ instance.onClick }
                >
                    <Field
                        type="checkbox"
                        id={ `option-${ title }` }
                        name={ `option-${ title }` }
                        checked={ isSelected }
                        disabled
                    />
                    { title }
                    <p style={{
                        "margin-left": "128px",
                        "text-align": "left",
                        "display": "block",
                        "margin-bottom": "-3px",
                    }}>
                        { `${formatCurrency(getStore().getState().CartReducer?.cartTotals?.quote_currency_code)}${roundPrice(getStore().getState().CartReducer?.cartTotals?.msp_fee)}` }
                    </p>
                </button>
            </li>
        }
        return callback.apply(args,instance);
    }

}
const { render } = new CheckoutPaymentComponentPlugin();

export default {
    'Component/CheckoutPayment/Component': {
        'member-function': {
            render
        }
    }
};
