const containerProps = (args, callback, instance) => ({
    ...callback.apply(instance, args),
    extensionAttributes: instance.props.extensionAttributes,
    onOrderAttributeChange: instance.props.onOrderAttributeChange,
    onVatIdBlur: instance.props.onVatIdBlur,
    invoice_type: instance.props.invoice_type,
    getActivities: instance.props.getActivities,
    onInvoiceTypeClick: instance.props.onInvoiceTypeClick,
    shippingFields: instance.props.shippingFields
});

const mapStateToProps = (args, callback, instance) => {
    const { CheckoutReducer } = args[0];
    return {
        ...callback.apply(instance, args),
        shippingFields: CheckoutReducer.shippingFields
    };
};

export const config = {
    'Component/CheckoutAddressBook/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/CheckoutAddressBook/Container/mapStateToProps': {
        function: [
            {
                position: 102,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
