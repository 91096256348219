import {
    CHECKBOX_TYPE
} from './Field.config';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

export class FieldContainer extends SourceFieldContainer {

    containerProps() {
        const {
            autocomplete,
            checked: propsChecked,
            customValidationStatus,
            disabled,
            fileExtensions,
            formRef,
            formRefMap,
            id,
            label,
            placeholder,
            max,
            min,
            mix,
            name,
            selectOptions,
            subLabel,
            type,
            validation,
            ariaLabel,
            isLabelWithArrow,
            step,
            isMobile,
            noLabel,
            isProductPage,
            attributeSetId,
            colorAttribute,
            isDisabled
        } = this.props;

        const {
            checked,
            value,
            validationStatus,
            validationMessage,
            filename
        } = this.state;

        return {
            autocomplete,
            checked: type === CHECKBOX_TYPE ? propsChecked : checked,
            customValidationStatus,
            disabled,
            fileExtensions,
            filename,
            formRef,
            formRefMap,
            id,
            label,
            ariaLabel,
            placeholder,
            max,
            message: validationMessage,
            min,
            mix,
            name,
            selectOptions,
            subLabel,
            type,
            validation,
            validationStatus: customValidationStatus ?? validationStatus,
            value,
            isLabelWithArrow,
            step,
            isMobile,
            noLabel,
            isProductPage,
            attributeSetId,
            isDisabled,
            colorAttribute
        };
    }
}

export default FieldContainer;
