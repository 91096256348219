/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Html from 'Component/Html';
import Popup from 'Component/Popup';
import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import { EIGHT_DAYS_IN_SECONDS } from './CustomPromoPopup.config';

import './CustomPromoPopup.style';
import CmsBlockFetcherWrapper from "../CmsBlockFetcherWrapper";

/** @namespace Component/CustomPromoPopup/Component */
export class CustomPromoPopup extends PureComponent {
    static propTypes = {
        openCustomPromoPopup: PropTypes.func.isRequired
    };

    componentDidMount() {
        const { openCustomPromoPopup, identifier } = this.props;
        openCustomPromoPopup(identifier)
    }

    renderCustomPromoPopup() {
        const { blocks: { items }, identifier, item, id } = this.props;
        return (
            <RenderWhenVisible fallback={() => <div />}>
                <div onClick={() => {
                    BrowserDatabase.setItem(true, id === item ? item : id, EIGHT_DAYS_IN_SECONDS);
                }}>
                    <CmsBlockFetcherWrapper identifiers={[identifier]}>
                        {items[identifier] ? <Html content={items[identifier].content} /> : null}
                    </CmsBlockFetcherWrapper>
                </div>
            </RenderWhenVisible>
        )
    }

    render() {
        const { id } = this.props;
        if (!id) return null;
        return (
            <Popup
                id={id}
                clickOutside={false}
                mix={{ block: "CustomPromoPopup" }}
            >
                {this.renderCustomPromoPopup()}
            </Popup>
        );
    }
}

export default CustomPromoPopup;
