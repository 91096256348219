import {
    SAMPLE_PRODUCTS,
    ADDING_SAMPLE_PRODUCT
} from './SampleProduct.action';
import { getIndexedProducts } from 'SourceUtil/Product';

/** @namespace Store/SampleProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    sampleProducts: {},
    isLoading: false
});

/** @namespace Store/SampleProducts/Reducer/SampleProductsReducer */
export const SampleProductsReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SAMPLE_PRODUCTS:
        const {
            ProductListing,
            ProductListing: { items = [] } = {}
        } = action;

        const IndexedProducts = getIndexedProducts(items);

        return {
            ...state,
            sampleProducts: {
                ...ProductListing,
                items: IndexedProducts
            }
        };

    case ADDING_SAMPLE_PRODUCT:
        const {
            isLoading
        } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default SampleProductsReducer;
