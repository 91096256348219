import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import Loader from 'Component/Loader';
import { ProductCompareButton as SourceProductCompareButton } from 'SourceComponent/ProductCompareButton/ProductCompareButton.component';
import React, {lazy} from "react";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

/** @namespace Component/ProductCompareButton/Component */
export class ProductCompareButton extends SourceProductCompareButton {

    render() {
        const {
            handleClick,
            isLoading,
            isActive,
            mix
        } = this.props;

        let compare_image = 'compare.svg';

        if(isActive) {
            compare_image = 'compare.svg';
        }

        return (
            <div
              block="ProductCompareButton"
              mods={ { isActive } }
              mix={ mix }
            >
                <button
                  block="ProductCompareButton"
                  elem="Button"
                  onClick={ handleClick }
                  mix={ { block: 'Button' } }
                  aria-label={ __('Compare') }
                >
                    <UniversalIcon
                        src={media(compare_image, WYSIWYG_MEDIA)}
                        alt="CompareIcon"
                        className="CompareIcon"
                    />
                    <Loader isLoading={ isLoading } />
                </button>
            </div>
        );
    }
}

export default ProductCompareButton;
