import FreeShippingPromotionsContainer from '../component/FreeShippingPromotions';

const mapStateToProps = (args, callback, instance) => {
    const { ConfigReducer } = args[0];
    return {
            ...callback.apply(instance, args),
            config: ConfigReducer
        }
};


const containerProps  = (args, callback, instance) =>  {
    const { config: { free_shpping_value = {}, is_free_period = {} } } = instance.props;

    return {
        ...callback.apply(instance, args),
        freeShipping: {
            free_shpping_value,
            is_free_period
        }
    };
};

const addFreeShipping = (args, callback, instance) => {
    const { totals, freeShipping } = instance.props

    return (
        <div
            block="CartOverlay"
            elem="Promo">
            <FreeShippingPromotionsContainer
                totals={ totals }
                freeShipping={ freeShipping }
            />
        </div>
    );
}


export default {
    'Component/CartOverlay/Component': {
        'member-function': {
            renderPromo : addFreeShipping
        }
    },
    'Component/CartOverlay/Container/mapStateToProps': {
        function: [
            {
                position: 102,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/CartOverlay/Container' : { 
        'member-function': {
            containerProps
        }
    }
};