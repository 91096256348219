import PropTypes from 'prop-types';
import { PureComponent, lazy } from 'react';
import ClickOutside from 'Component/ClickOutside';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import { formatPrice } from 'Util/Price';
import "./ConfigurationSelect.style.scss"

export const ChevronIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/ChevronIcon'
));

/**
 * ConfigurationSelect
 * @class ConfigurationSelect
 * @namespace Component/ConfigurationSelect/Component
 */
class ConfigurationSelect extends PureComponent {
    static propTypes = {
        isOpened: PropTypes.bool.isRequired,
        handleSelect: PropTypes.func.isRequired,
        product: PropTypes.object.isRequired,
        selectOptions: PropTypes.array.isRequired,
        onSelectOptionsOutsideClick: PropTypes.func.isRequired,
        onSelectOptionsClick: PropTypes.func.isRequired,
        selectedOption: PropTypes.object.isRequired,
    };

    getInfo(item) {
        const { product } = this.props;
        var {
            attributes: {
                beauty_color: {
                    attribute_options: beautyColorOptions = {},
                } = {},
            } = {},
        } = product;
        const variant = product.variants.find(v => v.sku === item.sku)

        const { attributes = {}, price_range: { minimum_price: { final_price: { value, currency } } }, attribute_set_id = "" } = variant || product;
        const itemsConfigurations = Object.keys(product.configurable_options).reduce((acc, key) => {
            return [
                ...acc,
                {
                    ...attributes[key]
                }
            ]
        }, [])

        const swatch = (itemsConfigurations && itemsConfigurations[0] && itemsConfigurations[0].attribute_value && beautyColorOptions[itemsConfigurations[0]?.attribute_value]) ?
            beautyColorOptions[itemsConfigurations[0].attribute_value]?.swatch_data
            : null;

        return {
            value,
            currency,
            itemsConfigurations,
            swatch
        }
    }

    renderSelectedInfo() {
        const { selectedOption } = this.props;
        let variant = this.getInfo(selectedOption)
        return (
            <>
                <span>
                    {variant?.itemsConfigurations.map(configuration => `${configuration.attribute_options[configuration.attribute_value].label}`)}
                </span>
                <span className="Option">
                    {formatPrice(variant.value, variant.currency)}
                </span>
            </>
        )
    }

    render() {
        const {
            selectOptions = [],
            selectedOption = {},
            handleSelect,
            onSelectOptionsOutsideClick,
            onSelectOptionsClick,
            isOpened,
        } = this.props;

        return (
            <div block="ConfigurationSelect">
                <ClickOutside onClick={onSelectOptionsOutsideClick}>
                    <button
                        block="ConfigurationSelect"
                        elem="Title"
                        mods={{ isOpened }}
                        onClick={onSelectOptionsClick}
                    >
                        {selectedOption?.sku ?
                            this.renderSelectedInfo()
                            : <span>
                                {__('Please select')}
                            </span>
                        }
                        <ChevronIcon direction={isOpened ? TOP : BOTTOM} />
                    </button>

                    <div block="ConfigurationSelect" elem="ItemList" mods={{ isOpened }}>
                        {selectOptions.map((item) => {
                            let variant = this.getInfo(item);
                            return (
                                <div key={item.id} block="Item" mods={{ isSelected: item.sku === selectedOption.sku }} onClick={() => {
                                    handleSelect(item, selectedOption);
                                    onSelectOptionsOutsideClick();
                                }}>
                                    <div class="LabelContainer">
                                        {variant?.itemsConfigurations.map(configuration => {
                                            return (
                                                <>
                                                    {variant.swatch ?
                                                        <>
                                                            <div class="Color" style={variant.swatch?.value == '#ffffff' ?
                                                                {
                                                                    'background-color': (variant.swatch?.value.charAt(0) === '#') ?
                                                                        variant.swatch?.value : '#' + variant.swatch?.value, 'box-shadow': 'inset 0 0 0 1px #dddddd'
                                                                }
                                                                : {
                                                                    'background-color': (variant.swatch?.value.charAt(0) === '#') ?
                                                                        variant.swatch?.value :
                                                                        '#' + variant.swatch?.value
                                                                }
                                                            }>

                                                            </div>
                                                            <>
                                                                {`${configuration.attribute_options[configuration.attribute_value].label}`}
                                                            </>
                                                        </>
                                                        :
                                                        <>
                                                            {`${configuration.attribute_options[configuration.attribute_value].label}`}
                                                        </>}
                                                </>
                                            )
                                        })}
                                    </div>
                                    <span class={item.sku === selectedOption.sku ? "Price_isSelected" : "Price"} >
                                        {formatPrice(variant.value, variant.currency)}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </ClickOutside >
            </div >
        );
    }

}

export default ConfigurationSelect;
