import { CheckoutPayments as SourceCheckoutPayments } from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

/** @namespace Component/CheckoutPayments/Component */
export class CheckoutPayments extends SourceCheckoutPayments {
    renderHeading() {
        return (
            <h2 block="Checkout" elem="Heading">
                { __('PAYMENT METHOD') }
            </h2>
        );
    }
}

export default CheckoutPayments;
