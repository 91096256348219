import {
    fetchQuery
} from 'SourceUtil/Request';

import { EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import GtmPurchaseQuery from '../query/GtmPurchase.query';

// const getPurchase = (args, callback, instance) => { 
//     const [ orderID, event ] = args;
//     const query = GtmPurchaseQuery.getGTMPurchase(orderID);
    
//     fetchQuery(query).then(({ purchase }) => {
//         event(EVENT_GTM_PURCHASE, { ...purchase, orderID });
//     });
// };

const componentDidUpdate = (args, callback, instance) => { 
    const [ prevProps ] = args;
    const { orderID: prevOrderID } = prevProps;
    const { isFired = false } = instance.state;
    const { orderID, shippingAddress, totals, event, email, paymentMethod, order = {} } = instance.props;

    const newTotals = { 
        shippingAddress: order?.shippingAddress ? order.shippingAddress : shippingAddress, 
        paymentMethod: order?.paymentMethod ? order.paymentMethod : paymentMethod, 
        ...totals,
        ...order, 
        email: order ? order?.email :email,
    };

    if (orderID && prevOrderID !== orderID && !isFired) {
        event({ orderID });
        // event({ purchase: newTotals , orderID, email: order ? order?.email :email});
        instance.setState({ isFired: true });
    }
}

const componentDidMount = (args, callback, instance) => {
    const { orderID, shippingAddress, totals, event, email, paymentMethod, order = {} } = instance.props;

    const newTotals = { 
        shippingAddress: order?.shippingAddress ? order.shippingAddress : shippingAddress, 
        paymentMethod: order?.paymentMethod ? order.paymentMethod : paymentMethod, 
        ...totals,
        ...order, 
        email: order ? order?.email :email
    };

    const { isFired = false } = instance.state;

    if (orderID && !isFired) {
        // event({ purchase: newTotals , orderID, email: order ? order?.email :email});
        event({ orderID });
        instance.setState({ isFired: true });
    }
};

export default {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            componentDidMount: componentDidMount,
            componentDidUpdate,
            // getPurchase
        }
    }
};