export const UPDATE_BRANDS_LIST = 'UPDATE_BRANDS_LIST';
export const UPDATE_BRAND_TYPE_OPTIONS = 'UPDATE_BRAND_TYPE_OPTIONS';

export const updateBrandsList = (brandList) => ({
    type: UPDATE_BRANDS_LIST,
    brandList
});

export const updateBrandsTypeOptions = (brand_type_options) => ({
    type: UPDATE_BRAND_TYPE_OPTIONS,
    brand_type_options
});
