import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';

class CheckoutOrderSummaryComponentPlugin {
    renderShipping(args, callback, instance){
        return [
            callback.apply(instance,args),
            instance.renderCodAmount()
        ];
    };

    renderCodAmount(args, callback, instance){
        const {
            totals: {
                quote_currency_code,
                msp_cod_amount
            }
        } = instance.props;
        const mods = { divider: true };


        return (
            <CheckoutOrderSummaryPriceLine
                price={ msp_cod_amount }
                currency={ quote_currency_code }
                title={ __('COD charges') }
                mods={ mods }
            />
        );
    }
}

const { renderShipping, renderCodAmount } = new CheckoutOrderSummaryComponentPlugin();

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderShipping: renderShipping,
            renderCodAmount: renderCodAmount
        }
    }
};
