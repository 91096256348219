/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_CURRENT_BRAND = 'UPDATE_CURRENT_BRAND';
export const RESET_BRAND_STATE = 'RESET_BRAND_STATE';

/**
 * Update Current Brand
 * @param {String} brandUrlPath url path Main Brand object
 * @return {void}
 */
export const updateCurrentBrand = (brand) => ({
    type: UPDATE_CURRENT_BRAND,
    brand
});

/**
 * Reset Brand
 * @param {String} brandUrlPath url path Main Brand object
 * @return {void}
 */
export const resetBrandState = (brand) => ({
    type: RESET_BRAND_STATE,
    brand
});
