/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CategorySort from 'Component/CategorySort';

/** @namespace Mageguide/Seosuite/Component/CategorySortWrapper/Container/mapStateToProps */
export const mapStateToProps = (state) => {
    let seo_default_sort_direction = [];
    let seo_available_sort_direction = [];
    if (state.ConfigReducer.seo_default_sort_direction) {
        seo_default_sort_direction = JSON.parse(state.ConfigReducer.seo_default_sort_direction).reduce((collector, option) => {
            collector[option.attribute] = option.direction;
            return collector;
        }, {});
    }

    if (state.ConfigReducer.seo_available_sort_direction) {
        seo_available_sort_direction = JSON.parse(state.ConfigReducer.seo_available_sort_direction).reduce((acc, option) => {
            acc[option.available_attribute] = option.available_direction;
            return acc;
        }, {});
    }

    return {
        seo_default_sort_direction,
        seo_available_sort_direction,
        default_sort_by: state.CategoryReducer.category ? state.CategoryReducer.category.default_sort_by : undefined
    };
};

/** @namespace Mageguide/Seosuite/Component/CategorySortWrapper/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});

/** @namespace Mageguide/Seosuite/Component/CategorySortWrapper/Container/CategorySortWrapperContainer */
export class CategorySortWrapperContainer extends PureComponent {
    render() {
        return (
            <CategorySort
              { ...this.props }
            />
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CategorySortWrapperContainer);
