import PropTypes from 'prop-types';
import {createRef, PureComponent} from 'react';
import Slider from "react-slick";
import "../../assets/slick/slick.css";
import "../../assets/slick/slick-theme.css";

import './SlickSliderHtml.style';

/** @namespace Component/SlickSlider/Component */
export class SlickSliderHtml extends PureComponent {

    sliderRef = createRef();

    static propTypes = {
        settings: PropTypes.object.isRequired,
    };

    static defaultProps = {
        settings : {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 811,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        }
    };

    componentDidMount() {
        let prevElement = document.getElementById(this.props.prevClass);
        let nextElement = document.getElementById(this.props.nextClass);

        if(prevElement) {
            prevElement.addEventListener('click', this.previous.bind(this));
        }

        if(nextElement) {
            nextElement.addEventListener('click', this.next.bind(this))
        }
    }
    next() {
        this.sliderRef.current.slickNext();
    }
    previous() {
        this.sliderRef.current.slickPrev();
    }

    render() {
        return (
            <Slider
                block="Slick"
                elem="Slider"
                { ...this.props.settings }
                ref={ this.sliderRef }
                className={this.props.className}
            >
                { this.props.children }
            </Slider>
        );
    }


}

export default SlickSliderHtml;