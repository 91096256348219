import {PAYPAL_SCRIPT} from "../component/PayPal/PayPal.config";
import PayPal from "../component/PayPal/PayPal.container";
import Link from "Component/Link";
import getStore from 'Util/Store';

class CheckoutComponentPlugin {

    getPayPalScript = (args, callback, instance) => {
        const {
            totals: {base_currency_code}
        } = instance.props;
        const {
            paypal_payment_action,
            paypal_sandbox_flag: isSandboxEnabled,
            paypal_client_id: clientId
        } = getStore().getState().ConfigReducer;

        const params = {
            currency: base_currency_code,
            intent: paypal_payment_action === 'Sale' ? 'capture' : 'authorize',
            'client-id': isSandboxEnabled ? 'sb' : clientId || 'sb'
        };

        const paramsString = (Object.entries(params).map(([key, value]) => `${key}=${value}`)).join('&');
        return `https://www.paypal.com/sdk/js?${paramsString}`;

        //return `<script id="${PAYPAL_SCRIPT}" src="https://www.paypal.com/sdk/js?${paramsString}"></script>`;
    };

    renderActions = (args, callback, instance) => {
        const {
            isOrderButtonEnabled,
            isOrderButtonVisible,
            isTermsAndConditionsAccepted,
            paymentMethod,
            setLoading,
            setDetailsStep,
            isVirtual
        } = instance.props;

        const {termsAreEnabled} = instance.props;

        if (!isOrderButtonVisible) {
            return null;
        }

        // if terms and conditions are enabled, validate for acceptance
        const isDisabled = termsAreEnabled
            ? !isOrderButtonEnabled || !isTermsAndConditionsAccepted
            : !isOrderButtonEnabled;

        if (paymentMethod === 'paypal_express' && !isDisabled) {
            return (
                <div block="Checkout" elem="StickyButtonWrapper">
                    <Link
                        to='/cart'
                        block="Button"
                        disabled={isDisabled && window.shippingFormSubmitted}
                        mix={{block: 'CheckoutBilling', elem: 'Button'}}
                    >
                        {__('Back to cart')}
                    </Link>
                    {
                        isTermsAndConditionsAccepted || !termsAreEnabled ?
                            <PayPal
                                setLoading={setLoading}
                                setDetailsStep={setDetailsStep}
                                selectedPaymentCode={paymentMethod}
                                isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                                isVirtual={isVirtual}
                            /> :
                            null
                    }
                </div>
            );

        } else {
            return callback.apply(instance, args);
        }
    }
    renderBillingStep = (args, callback, instance) => {
        if (!document.getElementById(PAYPAL_SCRIPT)) {
            let script = document.createElement('script');
            script.setAttribute('src', instance.getPayPalScript());
            script.setAttribute('id', PAYPAL_SCRIPT);
            document.head.appendChild(script);
        }
        return callback.apply(instance, args);
    }
}

const {
    renderBillingStep,
    getPayPalScript,
    renderActions
} = new CheckoutComponentPlugin();

export const config = {
    'Route/Checkout/Component': {
        'member-function': {
            renderBillingStep: renderBillingStep,
            getPayPalScript: getPayPalScript,
            renderActions: renderActions
        }
    }
};

export default config;
