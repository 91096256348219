import { UPDATE_CMS_PAGES } from "./CmsPage.action";


export const getInitialState = () => ({
    cmsPages: {}
});

export const CmsPageReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_CMS_PAGES:
        const { cmsPage } = action;

        const newCmsPages = {
            ...state.sliders, 
            [cmsPage.identifier]: cmsPage
        };

        return {
            ...state,
            cmsPages: newCmsPages
        };

    default:
        return state;
    }
};

export default CmsPageReducer;
