/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { FieldForm as SourceFieldForm } from 'SourceComponent/FieldForm/FieldForm.component';

import Form from 'Component/Form';

import './FieldForm.style';

/** @namespace Component/FieldForm/Component */
export class FieldForm extends SourceFieldForm {


    /**
     * Added to fix custom telephone feild issue
     * isSubmitted is needed to pass to check for validation
     */
    onFormError() {
        // @Todo impelentaion
    }

    render() {
        return (
            <Form
              onSubmitSuccess={ this.onFormSuccess }
              onSubmitError={this.onFormError }
              mix={ { block: 'FieldForm' } }
            >
                { this.renderFields() }
                { this.renderActions() }
            </Form>
        );
    }
}

export default FieldForm;
