import { PureComponent } from 'react';
import Cookies from 'universal-cookie';

import { BOXNOW_COOKIE } from '../../plugin/CheckoutDeliveryOption.component.plugin';
import { getPartnerBoxNowUrl } from '../../utils/BoxnowUtils';

import './BoxNowIframe.style.scss';

/** @namespace Boxnow/Component/BoxNowIframe/Component/BoxNowIframeComponent */
export class BoxNowIframeComponent extends PureComponent {
    componentDidMount() {
        const cookies = new Cookies();
        if (cookies.get(BOXNOW_COOKIE)) {
            cookies.set(BOXNOW_COOKIE, null, { path: '/', maxAge: 0 });
        }
    }

    render() {
        const {
            isSelected, partnerId, shippingFields: { country_id }, getBoxNowUrls
        } = this.props;
        let countryMap = Object.values(getBoxNowUrls).find((i) => i.country_id === country_id);
        if (!countryMap) {
            countryMap = Object.values(getBoxNowUrls).find((i) => i.country_id === 'GR');
        }

        if (!isSelected) {
            return null;
        }

        return (
            <div className="boxnowframe">
                <iframe id="boxnowframe" src={ getPartnerBoxNowUrl(countryMap) } width="100%" height="500px" allow="geolocation" />
            </div>
        );
    }
}

export default BoxNowIframeComponent;
