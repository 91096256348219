import CheckoutOrderSummaryPriceLine from 'Component/CheckoutOrderSummaryPriceLine';

class CheckoutOrderSummaryComponentPlugin {
    renderShipping(args, callback, instance){
        return [
            callback.apply(instance,args),
            instance.renderGiftCardAmount()
        ];
    };

    renderGiftCardAmount(args, callback, instance){
        const {
            totals: {
                quote_currency_code,
                gift_card_discount
            }
        } = instance.props;

        const mods = { divider: true };


        return (
            <CheckoutOrderSummaryPriceLine
                price={ gift_card_discount }
                currency={ quote_currency_code }
                title={ __('Gift card discount') }
                mods={ mods }
            />
        );
    }
}

const { renderShipping, renderGiftCardAmount } = new CheckoutOrderSummaryComponentPlugin();

export default {
    'Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderShipping: renderShipping,
            renderGiftCardAmount: renderGiftCardAmount
        }
    }
};
