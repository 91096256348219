export class UrlHandlerPlugin {
    getBypassCacheHosts = (args, callback, instance) => ([
        ...callback.apply(instance, args),
        '(?!^.*tiktoklogin/login/.*)'
    ]);
}

const { getBypassCacheHosts } = new UrlHandlerPlugin();

export const config = {
    'SW/Handler/UrlHandler/getBypassCacheHosts': {
        function: [
            {
                position: 101,
                implementation: getBypassCacheHosts
            }
        ]
    }
};

export default config;
