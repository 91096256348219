import Loader from 'Component/Loader';
import { MyAccountAddressTable as SourceMyAccountAddressTable } from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

/** @namespace Component/MyAccountAddressTable/Component */
export class MyAccountAddressTable extends SourceMyAccountAddressTable {

    get dataPairArray() {
        const { address, getFormatedRegion, showAdditionalFields } = this.props;
        const regionData = getFormatedRegion(address);

        const additionalFields = [
            {
                key: 'country',
                label: __('County'),
                source: regionData
            },
            {
                key: 'region',
                label: __('State/Province'),
                source: regionData
            },
            {
                key: 'city',
                label: __('City'),
                source: address
            },
            // Will be back with B2B update
            // {
            //     key: 'company',
            //     label: __('Company'),
            //     source: address
            // }
        ];

        return [
            {
                key: 'firstname',
                label: __('First name'),
                source: address
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: address
            },
            {
                key: 'street',
                label: __('Street'),
                source: address
            },
            {
                key: 'postcode',
                label: __('Postal code'),
                source: address
            },
            {
                key: 'telephone',
                label: __('Phone number'),
                source: address
            },
            {
                key: 'aaa',
                label: __('Invoice Type'),
                source: address,
                value : __('Invoice Type')
            },
            ...(showAdditionalFields ? additionalFields : [])
        ];
    }
    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address: { default_billing, default_shipping }
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <div className="MyAccountAddressTable-Actions">
                <button
                  block="Button"
                  onClick={ onEditClick }
                  mods={ { isHollow: true, isWithoutBorder: true } }
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.89853 17.0926L3.89853 17.0926L4.73563 13.0927C4.75788 12.9866 4.81241 12.889 4.89294 12.812L4.8584 12.7759L4.89294 12.812L14.9395 3.21182C15.1653 2.99606 15.5324 2.99606 15.7582 3.21182L19.1072 6.41198C19.3317 6.62651 19.3318 6.97352 19.1072 7.18825L9.0607 16.7882C8.98015 16.8652 8.87741 16.9178 8.765 16.9394C8.76499 16.9394 8.76499 16.9394 8.76499 16.9394L4.57905 17.7394C4.171 17.8174 3.81862 17.4738 3.89853 17.0926ZM15.3833 4.41263L15.3488 4.37962L15.3142 4.41263L5.8464 13.4592L5.83518 13.47L5.832 13.4851L5.21662 16.4243L5.20125 16.4977L5.27494 16.4837L8.3508 15.8964L8.3653 15.8937L8.37597 15.8835L17.8442 6.83646L17.882 6.80031L17.8442 6.76416L15.3833 4.41263ZM20.3722 19.85C20.6935 19.85 20.95 20.0984 20.95 20.4001C20.95 20.6762 20.7354 20.9074 20.4525 20.9448L20.3706 20.95H3.62798C3.30669 20.95 3.05 20.7017 3.05 20.4001C3.05 20.124 3.26482 19.8926 3.54766 19.8554L3.6296 19.85L20.3722 19.85Z" fill="black" stroke="white" strokeWidth="0.1"/>
                    </svg>
                    <span>{ __('Επεξεργασία') }</span>
                </button>
                <button
                  block="Button"
                  mods={ { isHollow: true, isWithoutBorder: true } }
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address' }
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 5L5 19" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M5 5L19 19" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>{ __('Διαγραφή διεύθυνσης') }</span>
                </button>
            </div>
        );
    }

    renderCutomerPersonelAddress1() {
        const { address : { street } } = this.props;
        
        if(typeof(street) === 'string' && street.length > 0) {   
            return (
                <><br/>
                { street }
                </>
            );
        }
        
        if((street && street.length === 0) || street === undefined){
            return null
        }

        return (
            <>
                { street[0] ? <><br/>{ street[0] }</> : "" }
            </>
        );
    }

    renderCutomerPersonelAddress2() {
        const { address : { street } } = this.props;
        
        if((street && street.length === 0) || street === undefined || typeof(street) !== 'object'){
            return null
        }

        return (
            <>
                { street[1] ? <><br/>{ street[1] }</> : "" }
            </>
        );
    }

    renderCutomerPersonelAddress3() {
        const { address : { street } } = this.props;

        if((street && street.length === 0) || street === undefined || typeof(street) !== 'object'){
            return null
        }

        return (
            <>
                { street[2] ? <><br/>{ street[2] }</> : "" }
            </>
        );
    }

    renderCustomerInfo() {
        const { address, getFormatedRegion, showAdditionalFields } = this.props;
        const regionData = getFormatedRegion(address);

        if(!address){
            return null
        }

        return (
            <div className="MyAccountAddressTable-CustomerInfo">
                <div className="MyAccountAddressTable-CustomerInfo_content">
                    <div className="MyAccountAddressTable-CustomerInfo_col">
                        { address.firstname && address.lastname ? <>{address.firstname+" "+address.lastname}</> : "" }
                        { this.renderCutomerPersonelAddress1() }
                        { address.city ? " "+address.city : "" }
                        { regionData && regionData.region ? ", "+regionData.region : ", "+ address.region }
                        { address.postcode ? <><br />{ address.postcode }</> : "" }
                        { this.renderCutomerPersonelAddress2() }
                        { this.renderCutomerPersonelAddress3() }
                        { address.telephone ? <><br />{ address.telephone }</> : "" }
                    </div>
                </div>
            </div>
        );
    }

    renderCustomerDefaultShipping() {
        const { address : { default_shipping } } = this.props;

        if(!default_shipping){
            return (
                <div className="MyAccountAddressTable-CustomerInfo_default_address"></div>
            );
        }

        return (
            <div className="MyAccountAddressTable-CustomerInfo_default_address">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 6L9 17L4 12" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{ __('Προεπιλεγμένη διεύθυνση αποστολής') }</span>
            </div>
        );
    }

    renderCustomerDefaultBilling() {
        const { address : { default_billing } } = this.props;

        if(!default_billing){
            return (
                <div className="MyAccountAddressTable-CustomerInfo_default_address"></div>
            );
        }

        return (
            <div className="MyAccountAddressTable-CustomerInfo_default_address">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 6L9 17L4 12" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span>{ __('Προεπιλεγμένη διεύθυνση τιμολόγησης') }</span>
            </div>
        );
    }

    render() {
        const { countries, mix } = this.props;

        return (
            <div block="MyAccountAddressTable" mix={ mix }>
                <Loader isLoading={ !countries.length } />
                { this.renderCustomerInfo() }
                <div className="MyAccountAddressTable-CustomerInfo_default_addresses">
                    { this.renderCustomerDefaultShipping() }
                    { this.renderCustomerDefaultBilling() }
                </div>
                { this.renderActions() }
            </div>
        );
    }
}

export default MyAccountAddressTable;
