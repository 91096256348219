import { BUNDLER_PRODUCTS } from '../component/BundlerSection/BundlerSection.conf';
import BundlerSection from '../component/BundlerSection'

const render = (args, callback, instance) => {
    const { linkType } = instance.props;
    if (linkType === BUNDLER_PRODUCTS) {
        return (
            <BundlerSection
                key={Math.random()}
                {...instance.props}
            />
        );
    }
    return callback.apply(instance, args)
}

const containerProps = (args, callback, instance) => {
    return {
        ...callback(args, instance),
        parameters: instance.props.parameters,
        mainProduct: instance.props.productOrVariant,
        configurableVariantIndex: instance.props.configurableVariantIndex,
        productOptionsData: instance.props.productOptionsData
    };
}
export default {
    'Component/ProductLinks/Component': {
        'member-function': {
            render: render
        }
    },
    'Component/ProductLinks/Container': {
        'member-function': {
            containerProps: containerProps
        }
    }
};
