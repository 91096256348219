/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';

import { DirectionType } from 'Type/Direction';

import { RIGHT } from './ChevronsIcon.config';

import './ChevronsIcon.style';

/** @namespace Component/ChevronsIcon/Component */
export class ChevronsIcon extends PureComponent {
    static propTypes = {
        direction: DirectionType
    };

    static defaultProps = {
        direction: RIGHT
    };

    render() {
        const { direction } = this.props;

        return (
            <>
                <span className="ChevronsIcon-space">
                    <svg
                        block="ChevronsIcon"
                        mods={ { direction } }
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M6 12L10 8L6 4" />
                    </svg>
                    <svg
                        block="ChevronsIcon"
                        mods={ { direction } }
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M6 12L10 8L6 4" />
                    </svg>
                </span>
            </>
        );
    }
}

export default ChevronsIcon;
