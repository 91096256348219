import getStore from 'Util/Store';

export class CheckoutPaymentsPlugin {
    renderBoxNowInstruct() {
        const { box_now_instruction } = getStore().getState().ConfigReducer;
        if (!box_now_instruction) {
            return null;
        }

        return (
            <div className="BankRedirect">
                    <p>{ box_now_instruction }</p>
            </div>
        );
    }

    aroundPaymentRenderMap = (originalMember, instance) => ({
        ...originalMember,
        boxnow: instance.renderBoxNowInstruct.bind(instance)
    });
}

const {
    aroundPaymentRenderMap,
    renderBoxNowInstruct
} = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderBoxNowInstruct
        },
        'member-property': {
            paymentRenderMap: aroundPaymentRenderMap
        }
    }
};

export default config;
