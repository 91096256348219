
import {
    MY_WISHLIST
} from 'SourceType/Account';

class myAccountWishlistPlugin {

    tabMap(args, callback, instance) {
        
        return {
            ...args,
            [MY_WISHLIST]: {
                url: '/my-wishlist',
                name: __('My wishlist')
            }
        }
    }


    componentDidUpdate(args, callback, instance) {
        const {
            wishlistItems: prevWishlistItems
        } = args[0];
        
        const { wishlistItems } = instance.props;

        if(wishlistItems !== prevWishlistItems) {
            instance.getMyWishlistHeaderTitle()
        }

        return callback.apply(instance, args);
    }

}

const { componentDidUpdate } = new myAccountWishlistPlugin();


export default {
    // 'Component/ProductWishlistButton/Container/mapDispatchToProps': {
    //     function: {
    //         position: 101,
    //         implementation: mapDispatchToProps
    //     }
    // },
    'Route/MyAccount/Container': {
        // 'static-member': {
        //     navigateToSelectedTab
        // },
        'member-function': { 
            // showPopup,
            // componentDidUpdate
        }
    },
    // 'Component/ProductWishlistButton/Component': {
    //     'member-function': {
    //         renderWishlistPopup,
    //         renderButton,
    //         renderContent
    //     }
    // }
};