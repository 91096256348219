import { MyAccountForgotPassword as SourceMyAccountForgotPassword } from 'SourceComponent/MyAccountForgotPassword/MyAccountForgotPassword.component';

export class MyAccountForgotPassword extends SourceMyAccountForgotPassword {

    renderAdditionalField() {
        const { state, handleSignIn } = this.props;

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                <section aria-labelledby="forgot-password-label">
                    <button
                      rel='nofollow'
                      block="Button"
                      mods={ { likeLink: true } }
                      mix={ { block: 'MyAccountOverlay', elem: 'SignInButton' } }
                      onClick={ handleSignIn }
                    >
                        { __('SIGN IN') }
                    </button>
                </section>
            </article>
        );
    }

    render() {
        return (
            <>
                <div className="MyAccountOverlay-Info">{ __('Εισάγετε το email σας για να κάνετε ανάκτηση κωδικού') }</div>
                { this.renderForgotPasswordForm() }
                { this.renderAdditionalField() }
            </>
        );
    }
}

export default MyAccountForgotPassword;
