import { Field } from 'SourceUtil/Query';

const _getCustomerFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        _getCustomAttributeField()
    ];
}

const _getCustomAttributeField = () => {
    return new Field('custom_attributes')
        .addFieldList([
            'attribute_code',
            'value'
        ]);
}

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields: _getCustomerFields
        }
    }
    
};