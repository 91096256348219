import Field from 'Component/Field';
import { lazy } from 'react';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import { WishlistItem as SourceWishlistItem } from "SourceComponent/WishlistItem/WishlistItem.component";
import { SAMPLE_CATEGORY, BAG_CATEGORY, CARDS_CATEGORY } from '../../route/ProductPage/ProductPage.config';
export const EditIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/EditIcon'
));

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

/** @namespace Component/WishlistItem/Component */
export class WishlistItem extends SourceWishlistItem {
    renderCommentField() {
        const {
            product: { wishlist: { description } },
            changeDescription
        } = this.props;

        return (
            <Field
              id="description"
              name="description"
              type="text"
              value={ description }
              mix={ { block: 'WishlistItem', elem: 'CommentField' } }
              label={ __('Add a comment') }
              onChange={ changeDescription }
            />
        );
    }

    renderOption({ label, value }){
        
        if(value == 'false') {
            return null;
        }

        return (
            <div block="WishlistItemOptions" elem="item">
                <label>{ label }</label>
                <span>{ value }</span>
            </div>
        );
    }

    renderOptions() {
        const { product: { type_id, wishlist: { options = [] } ={} } = {} } = this.props;

        const renderMethod = this.optionRenderMap[type_id];

        if (renderMethod) {
            return (
                <div block="WishlistItemOptions" elem="List">
                    { options.map(renderMethod) }
                </div>
            );
        }

        if(options?.length === 0)
            return null
        
        return (
            <div block="WishlistItemOptions" elem="List">
                { options.map((option) => this.renderOption(option)) }
            </div>
        );
    }

    renderBrand() {
        const {
            product: {
                attributes: { brand: { attribute_value: brand } = {} } = {},
                attributes
            }
        } = this.props;

        if(!brand)
            return null

        return (
            <div block="WishlistItem" elem="Brand">
                { attributes.brand.attribute_options[attributes.brand.attribute_value]?.label }
            </div>
        );
    }

    renderName() {
        const { product: { name } } = this.props;

        return (
            <div block="WishlistItem" elem="Name">{ name }</div>
        );
    }

    renderSku() {
        const { product: { wishlist: { sku } = {} } = {} } = this.props;

        return (
            <div block="WishlistItem" elem="sku">{ sku }</div>
        );
    }

    renderRemove() {
        const { removeItem } = this.props;

        return (
            <button
                block="WishlistItem"
                elem="Remove"
                onClick={ removeItem }
                aria-label={ __('Remove') }
            >
                <UniversalIcon
                    src={media('wishlist_small_fill.svg', WYSIWYG_MEDIA)}
                    alt="HeartIcon"
                    className="CloseIcon"
                />
            </button>
        );
    }

    renderCardDataMobile({
            content: { productPrice },
            pictureBlock: { picture: renderPicture },
            renderCardLinkWrapper
        }) {

        return (
            <div block="WishlistItem" elem="FigureWrapper">
                { renderCardLinkWrapper((
                    <figure mix={ { block: 'ProductCard', elem: 'Figure' } }>
                        { renderPicture({ block: 'WishlistItem', elem: 'Picture' }) }
                    </figure>
                ), { block: 'WishlistItem', elem: 'ImageWrapper' }) }
                <div block="WishlistItem" elem="InformationWrapper">
                    <div block="WishlistItem" elem="RowWrapper">
                        <div block="WishlistItem" elem="NameAndOptions">
                            { this.renderRating() }
                            { this.renderName() }
                            { this.renderOptions() }
                        </div>
                        { this.renderRemove() }
                    </div>
                    <div block="WishlistItem" elem="RowWrapper">
                        { this.renderQuantityFieldInput() }
                        { this.renderPrice(productPrice) }
                    </div>
                </div>
            </div>
        );
    }

    renderContent = (renderMethods) => {
        const { redirectToProductPage, product: { categories= [] }, isSharedWishlist = false } = this.props;

        const {
            content: { productPrice },
            pictureBlock: { picture: renderPicture },
            renderCardLinkWrapper
        } = renderMethods;

        const isDisable = categories.filter(({ id })=> id == SAMPLE_CATEGORY || id == CARDS_CATEGORY)?.length !== 0;
        
        return (
            <div block="WishlistItem" elem="Main">
                <div block="WishlistItem" elem="Left">
                    <div block="WishlistItem" elem="FigureWrapper">
                        { isDisable ? 
                            <figure mix={ { block: 'ProductCard', elem: 'Figure' } }>
                                { renderPicture({ block: 'WishlistItem', elem: 'Picture' }) }
                            </figure>
                        : renderCardLinkWrapper(
                            <>
                                <figure mix={ { block: 'ProductCard', elem: 'Figure' } }>
                                    { renderPicture({ block: 'WishlistItem', elem: 'Picture' }) }
                                </figure>
                            </>
                        ) }
                    </div>
                    <div block="WishlistItem" elem="LeftContent">
                        { this.renderBrand() }
                        { this.renderName() }
                        { this.renderSku() }
                        { this.renderOptions() }
                    </div>
                </div>
                <div block="WishlistItem" elem="Right">
                { !isSharedWishlist ?
                    <div block="WishlistItem" elem="ActionWrapperTop">
                        <div
                            block="WishlistItem"
                            elem="EditIcon"
                            onClick={ redirectToProductPage }
                        >
                            <EditIcon />
                        </div>
                        { this.renderRemove() }
                    </div>
                    : null }
                    <div block="WishlistItem" elem="Content">
                        <div block="WishlistItem" elem="RowWrapper">
                            { this.renderPrice(productPrice) }
                        </div>
                        {/*{ this.renderCommentField() }*/}
                        <div block="WishlistItem" elem="ActionWrapper">
                            { this.renderQuantityFieldInput() }
                            { this.renderAddToCartButton() }
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default WishlistItem;
