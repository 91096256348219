import { getIndexedAttributeOption } from 'Util/Product'

export const filterConfigurableOptions = (_args, _callback) => {
    const [ options, variants ] = _args;
    return Object.values(options)
    .reduce((acc, option) => {
        const {
            attribute_values = [],
            attribute_code
        } = option;

        // show option if it exist as variant for configurable product
        const filteredOptions = attribute_values.reduce((acc, value) => {
            const isVariantExist = variants.find(({ attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return value === foundValue;
            });

            if (isVariantExist) {
                acc.push(value);
            }

            return acc;
        }, []);

        return {
            ...acc,
            [attribute_code]: {
                ...option,
                attribute_values: filteredOptions
            }
        };
    }, {})

};

export const getIndexedAttributes = (_args, _callback) => {
    const [attributes] = _args;

    return attributes.reduce((indexedAttributes, attribute) => {
        const { attribute_code, attribute_options = [] } = attribute;

        return {
            ...indexedAttributes,
            [attribute_code]: {
                ...attribute,
                attribute_options: Array.isArray(attribute_options) && attribute_options.length > 0 ? attribute_options.reduce((acc, option) => {
                    const { value } = option;
                    return {
                        ...acc,
                        [value]: getIndexedAttributeOption(option)
                    };
                }, {}) : {}
            }
        };
    }, {});
}


export const getIndexedConfigurableOptions = (_args, _callback) => {
    const [ configurableOptions = {}, indexedAttributes ] = _args;

    if(Object.keys(configurableOptions).length > 0) {
        const key = Object.keys(configurableOptions)[0];
            if(!(/^[0-9]+$/.test(key))) {
                return configurableOptions;
            }
        }

    return _callback(..._args);
};

export default {
    'Util/Product/getIndexedConfigurableOptions': {
        'function': getIndexedConfigurableOptions
    },
    'Util/Product/filterConfigurableOptions': {
        'function': filterConfigurableOptions
    },
    'Util/Product/getIndexedAttributes': {
        'function': getIndexedAttributes
    }
}