/* eslint-disable */
import Html from 'Component/Html';
import { CmsBlock as SourceCmsBlock } from 'SourceComponent/CmsBlock/CmsBlock.component';

export class CmsBlock extends SourceCmsBlock {

    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            },
            noWrapper,
            blockType
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        if(noWrapper) {
            return (
                <Html content={ content } />
            );
        }

        return (
            <div
                block="CmsBlock"
                elem="Wrapper"
                mods={ { type: blockType } }
            >
                <Html content={ content } />
            </div>
        );
    }
}

export default CmsBlock;
