import  { CheckoutShipping as SourceCheckoutShipping }  from 'SourceComponent/CheckoutShipping/CheckoutShipping.component';
import CheckoutAddressBook from 'Component/CheckoutAddressBook';

/** @namespace Component/CheckoutShipping/Component */
export class CheckoutShipping extends SourceCheckoutShipping {

    renderActions() {
        const { selectedShippingMethod } = this.props;

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                { this.renderOrderTotal() }
                <button
                    rel='nofollow'
                    style={{display: 'none'}}
                    id='checkoutShippingSubmit'
                    type="submit"
                    block="Button"
                    disabled={ !selectedShippingMethod }
                    mix={ { block: 'CheckoutShipping', elem: 'Button' } }
                >
                </button>
            </div>
        );
    }

    renderAddressBook() {
        const {
            onAddressSelect,
            onShippingEstimationFieldsChange,
            onSaveShippingAddressCheckboxSelect,
            saveShippingAddress,
            isSubmitted
        } = this.props;

        return (
            <CheckoutAddressBook
                onAddressSelect={ onAddressSelect }
                onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                onSaveShippingAddressCheckboxSelect={onSaveShippingAddressCheckboxSelect}
                saveShippingAddress={saveShippingAddress}
                isSubmitted={ isSubmitted }
            />
        );
    }

    renderContent() {
        const { isPickInStoreMethodSelected } = this.props;

        if (isPickInStoreMethodSelected) {
            return this.renderPickInStoreMethod();
        }

        return  (
            <>
                { this.renderAddressBook() }
                { this.renderActions() }
            </>
        );
    }
}

export default CheckoutShipping;
