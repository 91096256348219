import CheckoutQuery from 'Query/Checkout.query';
import { fetchQuery } from 'Util/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
const GUEST_QUOTE_ID = 'guest_quote_id';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import getStore from 'Util/Store';
import { debounce } from 'Util/Request';

class CheckoutContainerPlugin {
    containerFunctions(prop, instance){
        return {
            ...prop,
            onOrderAttributeChange: instance.onOrderAttributeChange.bind(instance),
            onVatIdBlur: instance.onVatIdBlur.bind(instance),
            onInvoiceTypeClick: instance.onInvoiceTypeClick.bind(instance)
        }
    }

    saveAddresses = debounce(
        ()=> {
            document.getElementById('checkoutShippingSubmit').click();
        },
        1500
    );

    onOrderAttributeChange(args, callback, instance){
        let attribute_code = args[0];
        let attribute_value = args[1];
        const newExtensionAttributes = [...instance.state.extensionAttributes];
        if(attribute_code === 'vat_id' ){
            if(attribute_value.length === 9){
                const { business_activity } = instance.state.extensionAttributes;
                const store = getStore();
                const { dispatch } = store;
                fetchQuery(CheckoutQuery.validatedVatId(attribute_value)).then(
                    ({ getActivities : { activities, company, tax_office, vat_office_code } }) => {
                        instance.setState({
                            getActivities : activities
                        });
                        newExtensionAttributes.map(el=>{
                            if (el.attribute_code === 'company'){
                                document.getElementById('invoice_company').value = company;
                                el.value = company;
                            }
                            if (el.attribute_code === 'tax_office'){
                                document.getElementById('invoice_tax_office').value = tax_office;
                                el.value = tax_office;
                            }
                            if (el.attribute_code === 'vat_office_code'){
                                document.getElementById('invoice_vat_office_code').value = vat_office_code;
                                el.value = vat_office_code;
                            }
                        });
                        if(!business_activity && !args[2])
                            dispatch(showNotification('info', __("Please Select the activity") ));
                    },
                    (error) => {
                        dispatch(showNotification('error', error[0].message ));
                    }
                );
            }
        }
        newExtensionAttributes.map(el=>{
            if (el.attribute_code === attribute_code){
                el.value = attribute_value;
            }
        });
        instance.setState({ extensionAttributes: newExtensionAttributes });
        instance.saveAddresses();
    }
    onVatIdBlur(args, callback, instance){
        const [ event ] = args;
        const { getActivities } = instance.state;
        let attribute_value = event.target.value;
        const newExtensionAttributes = [...instance.state.extensionAttributes];
        if(!getActivities && attribute_value.length === 9){
            const { business_activity } = instance.state.extensionAttributes;
            const store = getStore();
            const { dispatch } = store;
            fetchQuery(CheckoutQuery.validatedVatId(attribute_value)).then(
                ({ getActivities : { activities, company, tax_office, vat_office_code } }) => {
                    instance.setState({
                        getActivities : activities
                    });
                    newExtensionAttributes.map(el=>{
                        if (el.attribute_code === 'company'){
                            document.getElementById('invoice_company').value = company;
                            el.value = company;
                        }
                        if (el.attribute_code === 'tax_office'){
                            document.getElementById('invoice_tax_office').value = tax_office;
                            el.value = tax_office;
                        }
                        if (el.attribute_code === 'vat_office_code'){
                            document.getElementById('invoice_vat_office_code').value = vat_office_code;
                            el.value = vat_office_code;
                        }
                    });
                    if(!business_activity && !args[2])
                        dispatch(showNotification('info', __("Please Select the activity") ));
                },
                (error) => {
                    dispatch(showNotification('error', error[0].message ));
                }
            );
        }else if (attribute_value.length !== 9){
            newExtensionAttributes.map(el=>{
                if (el.attribute_code === 'company'){
                    document.getElementById('invoice_company').value = '';
                    el.value = '';
                }
                if (el.attribute_code === 'tax_office'){
                    document.getElementById('invoice_tax_office').value = '';
                    el.value = '';
                }
                if (el.attribute_code === 'vat_office_code'){
                    document.getElementById('invoice_vat_office_code').value = '';
                    el.value = '';
                }
                if (el.attribute_code === 'invoice_business_activity'){
                    document.getElementById('invoice_business_activity').value = '';
                    el.value = '';
                }
            });
            instance.setState({ getActivities: [] });
        }
        instance.setState({ extensionAttributes: newExtensionAttributes });
    }
    onInvoiceTypeClick(args, callback, instance){
        if('Receipt' === args[0]){
            const newExtensionAttributes = [...instance.state.extensionAttributes];
            newExtensionAttributes.map(el=>{
                if (el.attribute_code === 'invoice_type'){
                    el.value = args[0];
                }
                if (!['invoice_type','customer_comment'].includes(el.attribute_code)){
                    el.value = '';
                }
            });
            instance.setState({ invoice_type: args[0], extensionAttributes: newExtensionAttributes });
            instance.onOrderAttributeChange('invoice_type',args[0]);
        }else {
            instance.setState({ invoice_type: args[0] });
            instance.onOrderAttributeChange('invoice_type',args[0]);
        }
    }

    _getExtensionAttributes(args, callback, instance){
        fetchQuery(CheckoutQuery.getCartForCustomer(
            BrowserDatabase.getItem(GUEST_QUOTE_ID).token
        )).then(
            (data) => {
                const {getCartForCustomer} = data;
                let { shippingFields, updateShippingFields} = instance.props;
                let {extension_attributes: extensionAttributes, shipping_address} = getCartForCustomer;
                if(Object.keys(shipping_address).length){
                    shipping_address.country_id = shipping_address.country?.code;
                    shipping_address.region_id = shipping_address.region?.region_id;
                    shipping_address.street0 = shipping_address.street[0];
                    shipping_address.street1 = shipping_address.street[1];
                }
                let invoice_type = '';
                extensionAttributes.map(item => {
                    if(item.attribute_code === 'invoice_type'){
                        if(item.value){
                            invoice_type = item.value;
                        }else {
                            item.value = invoice_type = 'Receipt';
                        }
                    }
                });
                instance.setState({ extensionAttributes, invoice_type });
                updateShippingFields({...shippingFields, ...shipping_address });
            },
            instance._handleError
        );
    }
    __construct(args, callback, instance){
       callback.apply(instance,args);
        if(!window.location.pathname.includes('checkout/success'))
            instance._getExtensionAttributes();
    }

    containerProps(args, callback, instance){
        return {
            ...callback.apply(instance,args),
            extensionAttributes : instance.state.extensionAttributes,
            invoice_type : instance.state.invoice_type,
            getActivities : instance.state.getActivities
        }
    }
}
const  { onVatIdBlur, saveAddresses, onInvoiceTypeClick, containerFunctions, onOrderAttributeChange, _getExtensionAttributes, __construct, containerProps  } = new CheckoutContainerPlugin();

export const config = {
    'Route/Checkout/Container': {
        'member-function': {
            onOrderAttributeChange,
            onInvoiceTypeClick,
            _getExtensionAttributes,
            __construct,
            containerProps,
            saveAddresses,
            onVatIdBlur
        },
        'member-property': {
            'containerFunctions': containerFunctions
        }
    }
};

export default config;
