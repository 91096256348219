const componentDidUpdate = (args, callback, instance) => {
    if (window.location.pathname.includes('klarna') && window.Klarna && !instance.state.isKlarnaOnsiteMessagingTriggerMounted) {
        const intervalID = setInterval(setCmsKlarnaHtml, 1000);
        function setCmsKlarnaHtml() {
            const cmsKlarnaTag = document.getElementsByTagName('klarna-placement');
            console.log('finding')
            if (cmsKlarnaTag[0]) {
                window.Klarna.OnsiteMessaging.refresh();
                clearInterval(intervalID);
                console.log('found')
            }
        }
        console.log('executed')
        instance.setState({ isKlarnaOnsiteMessagingTriggerMounted: true });
    }
    return callback.apply(instance, args);
};
const state = (args) => ({
    ...args,
    isKlarnaOnsiteMessagingTriggerMounted: false
});
export default {
    'Route/CmsPage/Container': {
        'member-function': {
            componentDidUpdate
        },
        'member-property': {
            state
        }
    }
};