/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */
import BrowserDatabase from 'Util/BrowserDatabase';
const BANKING_STEP = 'BANKING_STEP';
import { getGuestQuoteId } from 'Util/Cart';

import {BANK_PAYMENT_CODE} from '../component/BankRedirect/BankRedirect.config';
import {isSignedIn} from 'Util/Auth';
import {fetchMutation, fetchQuery} from 'Util/Request';
import CheckoutQuery from 'Query/Checkout.query';
import {
    BILLING_STEP,
    DETAILS_STEP, PAYMENT_TOTALS, SHIPPING_STEP
} from "Route/Checkout/Checkout.config";

const savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
    const { paymentMethod: { code, additional_data, purchase_order_number } } = args[0];
    if(code.includes(BANK_PAYMENT_CODE)){
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        if (!isCustomerSignedIn && !guest_cart_id) {
            return;
        }

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                guest_cart_id,
                payment_method: {
                    code,
                    [BANK_PAYMENT_CODE]: instance.state.aplhaBankAdditionalData,
                    purchase_order_number
                }
            }));
            const { saveShippingAddress, saveBillingAddress} = instance.state;
            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id,saveShippingAddress,saveBillingAddress));
            const { placeOrder: { action_url,  order  }  } = orderData;
            if(action_url && action_url.includes("success")){
                instance.setDetailsStep(order);
            }else {
                instance.setBankRedirectStep(order.order_id,action_url,order)
            }
        } catch (e) {
            console.log(e)
            instance._handleError(e);
        }
    }else {
        return callback.apply(instance,args);
    }
}

const setBankRedirectStep = (args, callback, Instance) => {
    let order = args[2];
    let ActionUrl = args[1];
    BrowserDatabase.setItem(order.order_id, 'completed_order_id');
    BrowserDatabase.setItem(order, 'completed_order');
    Instance.setState({
        isLoading: false,
        paymentTotals: {},
        checkoutStep: BANKING_STEP,
        orderID:order.order_id,
        ActionUrl,
        order
    });
}
const __construct = (args, callback, instance) => {

    callback.apply(instance,args);

    const {
        totals: { 
            is_virtual
        }
    } = instance.props;

    instance.state = {...instance.state,...{
        aplhaBankAdditionalData: {
            is_installment: false,
            installment_period: 1
        },
        ActionUrl: '',
        checkoutStep:
            is_virtual ?
                window.location.pathname.includes('/success/'+BrowserDatabase.getItem('completed_order_id')) ? DETAILS_STEP : isSignedIn() ? BILLING_STEP : SHIPPING_STEP :
                window.location.pathname.includes('/success/'+BrowserDatabase.getItem('completed_order_id'))? DETAILS_STEP : isSignedIn() ? BILLING_STEP : SHIPPING_STEP,
        completedOrderID: window.location.pathname.includes('/success/'+BrowserDatabase.getItem('completed_order_id'))? BrowserDatabase.getItem('completed_order_id'):null,
        completedOrder: window.location.pathname.includes('/success/'+BrowserDatabase.getItem('completed_order_id'))? BrowserDatabase.getItem('completed_order'):null,
        order: {}
    }};
}

const setBankRedirectAdditionalData = (args, callback, instance) => {
    instance.setState({
        aplhaBankAdditionalData : args[0]
    });
}

const componentDidMount  = (args, callback, instance) => {

    if(instance.state.completedOrderID){
        const {
            updateMeta
        } = instance.props;
        instance.setDetailsStep(instance.state.completedOrder);
        updateMeta({ title: __('Checkout') });
    }else {
        BrowserDatabase.deleteItem('completed_order_id');
        BrowserDatabase.deleteItem('completed_order');
        callback.apply(instance,args);
    }
}

const containerProps =  (args, callback, instance) => {
    return {
        ...callback.apply(instance, args),
        ActionUrl : instance.state.ActionUrl
    };
}

const containerFunctions = (prop, instance) => {
    return {
        ...prop,
        setBankRedirectAdditionalData : instance.setBankRedirectAdditionalData.bind(instance)
    }
}

export default {
    'Route/Checkout/Container': {
        'member-function': {
            savePaymentMethodAndPlaceOrder: savePaymentMethodAndPlaceOrder,
            setBankRedirectStep: setBankRedirectStep,
            __construct: __construct,
            setBankRedirectAdditionalData: setBankRedirectAdditionalData,
            componentDidMount: componentDidMount,
            containerProps: containerProps,
        },
        'member-property': {
            'containerFunctions': containerFunctions
        }
    }
};
