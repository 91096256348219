import CheckoutQuery from 'Query/Checkout.query';
import {
    BILLING_STEP, PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import { updateTotals } from 'Store/Cart/Cart.action';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId } from 'Util/Cart';
import {
    fetchMutation
} from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import getStore from 'Util/Store';
import { updateShippingFields } from 'Store/Checkout/Checkout.action';
import {debounce} from "Util/Request";

export const SHIPPING_REQUIRED_FIELDS = [
    'city',
    'country_id',
    'postcode',
    'telephone',
    'street0',
    'firstname',
    'lastname'
];

class CheckoutContainer {
    updateReduxAndValidateForRequiredShippingFields = debounce((key,value,instance) => {
        const { shippingFields, updateShippingFields } = instance.props;
        let phoneCode = document.getElementById('areaCode').value;
        let maxLength = document.getElementById('telephone').maxLength;
        let newShippingFields = {};
        if(key === 'telephone'){
            newShippingFields = {...shippingFields, [key]: `+${phoneCode}${value}` };
            updateShippingFields(newShippingFields);
        }else {
            newShippingFields = {...shippingFields, [key]: value };
            updateShippingFields(newShippingFields);
        }
        instance.setState(() => ({ [key]: value }));
        window.shippingFormSubmitted = !SHIPPING_REQUIRED_FIELDS.some((requiredKey) => {
            // Check if required fields are empty
            if(!newShippingFields[requiredKey]){
                return true;
            }
            // Check different condition in case of phone number
            if(requiredKey === 'telephone'){
                return isNaN(newShippingFields[requiredKey]) || newShippingFields[requiredKey].length != maxLength
            }
            return false;
        });
    }, 500)
    onChange = (args, callback, instance) => {
        const [key, value] = args;
        /**
         * Update reducer on every field value change
         */
        this.updateReduxAndValidateForRequiredShippingFields(key,value,instance);
        callback.apply(instance, args);
    };

    mapDispatchToProps(args, callback){
        const [dispatch] = args;
        return {
            ...callback(...args),
            updateShippingFields: (shippingFields) => dispatch(updateShippingFields(shippingFields))
        };
    };


    onShippingEstimationFieldsChange = (args, callback, instance) => {
        const { updateShippingFields, shippingFields } = instance.props;
        callback.apply(instance, args);
        if(isSignedIn()){
            let addressForm = document.getElementsByClassName('CheckoutAddressForm');
            if(addressForm.length && addressForm[0].parentNode.parentNode.parentNode.id === 'SHIPPING_STEP')
                updateShippingFields({...shippingFields, ...args[0]});
        }else {
            updateShippingFields({...shippingFields, ...args[0]});
        }
    };

    saveAddressInformationFetchMutationThen = (args, callback, instance) => {
        const { saveAddressInformation: { totals, payment_methods } } = args[0];
        const { cartTotals } = getStore().getState().CartReducer;
        const { items } = cartTotals;
        const event = new CustomEvent("updatePaymentMethods", { detail : { payment_methods, resetPayments : false } });
        document.dispatchEvent(event);
        getStore().dispatch(updateTotals({ ...getStore().getState().CartReducer.cartTotals, ...totals, items }));
        callback.apply(instance, args);
    };

    _getCheckoutTotals = (args, callback, instance) => {
        const { totals: cartTotals } = instance.props;
        const shipping_amount = BrowserDatabase.getItem('shipping_amount');
        callback.apply(instance, args);

        return typeof shipping_amount === 'string' && window.location.pathname.includes('billing')
            ? { ...cartTotals, shipping_amount }
            : cartTotals;
    };

    saveAddressInformation = async (args, callback, instance) => {
        const addressInformation = args[0];
        const { selectedShippingMethod } = instance.state;
        const { shipping_address, shipping_method_code } = addressInformation;
        if (!selectedShippingMethod) {
            instance.setState({
                // isLoading: true,
                shippingAddress: shipping_address,
                selectedShippingMethod: { method_code: shipping_method_code }
            });
        }

        return callback.apply(instance, args);
    };

    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            shippingFields: state.CheckoutReducer.shippingFields
        };
    };
    componentDidMount = (args, callback, instance) => {
        document.addEventListener(
            "updatePaymentMethods",
            (data) => {
                const { detail : { payment_methods: paymentMethods, resetPayments} } = data;
                if(resetPayments){
                    const event = new CustomEvent("resetSelectedPayment");
                    document.dispatchEvent(event);
                }
                instance.setState({ paymentMethods });
            },
            false,
        );
        return callback.apply(instance,args);
    }
}
const {
    _getCheckoutTotals,
    onShippingEstimationFieldsChange,
    saveAddressInformation,
    saveAddressInformationFetchMutationThen,
    mapStateToProps,
    componentDidMount,
    onChange,
    mapDispatchToProps
} = new CheckoutContainer();

export const config = {
    'Route/Checkout/Container': {
        'member-function': {
            onShippingEstimationFieldsChange,
            saveAddressInformation,
            _getCheckoutTotals,
            componentDidMount
        }
    },
    'Route/Checkout/Container/saveAddressInformationFetchMutationThen': {
        function: [
            {
                position: 1,
                implementation: saveAddressInformationFetchMutationThen
            }
        ]
    },
    'Route/Checkout/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            onChange
        }
    },
    'Component/CheckoutAddressForm/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

export default config;
