export const GET_ENHANCED_FORM_DATA = 'GET_ENHANCED_FORM_DATA';
export const GET_ENHANCED_FORM_FIELDS = 'GET_ENHANCED_FORM_FIELDS';
export const UPDATE_CUSTOM_DATA_IS_LOADING = 'UPDATE_CUSTOM_DATA_IS_LOADING';

export const getCustomerAttributeData = (customData) => ({
    type: GET_ENHANCED_FORM_DATA,
    customData
});

export const getCustomerAttributeFields = (customAttributeMetadata) => ({
    type: GET_ENHANCED_FORM_FIELDS,
    customAttributeMetadata
});

export const updateIsLoading = (isLoading) => ({
    type: UPDATE_CUSTOM_DATA_IS_LOADING,
    isLoading
});
