import {
    CATEGORY_PRODUCT_LIST, HOMEPAGE, NOT_APPLICABLE, PRODUCT_LIST_WIDGET, SEARCH, ZERO
} from '../component/GoogleTagManager/GoogleTagManager.config';
import { CONFIGURABLE } from 'SourceUtil/Product/Types';
import { 
    getBrand, 
    getCategories, 
    getCustomeAttribuite, 
    getPrice, 
    getStockStatus, 
    getVariant, 
    getDiscountPercentage 
} from './baseProduct.data';
import { getIndexedProducts } from 'SourceUtil/Product'
import { getPageType } from './general.data';

/** @namespace GtmNew/EventData/Impression/Data/getAction */
export const getAction = (list = '', store) => {

    switch (list) {
    case CATEGORY_PRODUCT_LIST:
        return store.CategoryReducer?.category?.name || '';
    case PRODUCT_LIST_WIDGET:
        return getPageType(store)?.pageType || HOMEPAGE;
    case SEARCH:
        return SEARCH;
    default:
        return list ? list : getPageType(store)?.pageType;
    }
};

/** @namespace GtmNew/EventData/Impression/Data/getItem */
export const getItem = (item, position, list) => {
    const {
        sku,
        name, 
        type_id, 
        price, 
        categories = [], 
        variants ={}
    } = item;

    const data = {
        name,
        title: name,
        id: sku,
        item_price: getPrice(item, type_id, price),
        price: getPrice(item, type_id, price),
        // category: window.location.href,
        category: getCategories(categories),
        brand: getBrand(item),
        google_business_vertical: 'retail',
        // business_unit: getCustomeAttribuite(item, 'att_108'),
        position,
        list,
        // dimension1: NOT_APPLICABLE,
        dimension3: getCustomeAttribuite(item, 'att_108'),
        dimension4: getCustomeAttribuite(item, 'badge_newin'),
        dimension2: getStockStatus(item, type_id, variants),
        dimension5: getDiscountPercentage(item, type_id, price, false),
        dimension6: getCustomeAttribuite(item, 'mg_custom_lists')
        // dimension2: getStockStatus(item, type_id, variants)
    };

    if (type_id === CONFIGURABLE) {
        data.variant = getVariant(item, type_id, ZERO);
    }

    return data;
};

/** @namespace GtmNew/EventData/Impression/Data/getImpressionsData */
export const getImpressionsData = (data = [], store, list = null) => {

    const { products, list: fallbackList = '' } = data;
    const currList = list ? list : fallbackList;
    const actionList = getAction(currList, store);
    const impressions = [];

    products.map((product, i) => (
        impressions.push(getItem(product, i + 1, actionList))
    ));

    return impressions;
};
