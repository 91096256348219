import {
    lazy
} from 'react';
import { CONTACT_PAGE } from 'Component/Router/Router.config';
export const withStoreRegex = (path) => window.storeRegexText.concat(path);
import { Route } from 'react-router-dom';
export const ContactUs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ '../route/ContactUs'));

const isIterable = (value) => {
    return Symbol.iterator in Object(value);
  }
  
// const addTestComponent = (originalMember) => [
//         originalMember,
//         {
//             component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactUs { ...props } /> } />,
//             position: 1
//         }
//     ];

class RouterPlugin {
 
    SWITCH_ITEMS_TYPE = (originalMember, callback) => {
        // const newMember = isIterable(callback.SWITCH_ITEMS_CUSTOM) ? {...callback.SWITCH_ITEMS_CUSTOM} : callback.SWITCH_ITEMS_CUSTOM;
        return [
            {},
            {
                component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactUs { ...props } /> } />,
                position: 120,
                name: CONTACT_PAGE
            }
        ]
    };
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export default {
    'Route/UrlRewrites/Component': {
        'member-property': {
            SWITCH_ITEMS_CUSTOM: SWITCH_ITEMS_TYPE
        }
    }
};
