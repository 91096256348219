const filterResetItems = (args, callback, instance) => {
    const { customFiltersValues } = instance.props;
    if (customFiltersValues.category_ids) {
        if (customFiltersValues.category_id) {
            customFiltersValues.category_id = [...customFiltersValues.category_id, ...customFiltersValues.category_ids];
        } else {
            customFiltersValues.category_id = customFiltersValues.category_ids;
        }
    }

    return Object.entries(customFiltersValues).reduce(
        (prev, [attrCode, attrValues]) => (
            {
                ...prev,
                ...instance.getResetData(attrCode, attrValues)
            }
        ), {}
    );
};

export const config = {
    'Component/ResetAttributes/Container': {
        'member-function': {
            filterResetItems: [
                {
                    position: 1,
                    implementation: filterResetItems
                }
            ]
        }
    }
};

export default config;
