export const TYPE_BRAND_ATTRIBUTE = 'BRAND_ATTRIBUTE';
export const TYPE_BRAND = 'BRAND';
import { lazy } from 'react';
export const BrandPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ '../route/BrandPage'));
export const BrandListPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ '../route/BrandListPage'));
export const HeroBrandPageModerator = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "heroBrand" */ '../route/HeroBrandPageModerator'));

const renderContent = (args, callback, instance) => {
    const { props, type } = instance.props;
    const { urlRewrite } = props;
    switch (type) {
        case TYPE_BRAND_ATTRIBUTE:
            if(urlRewrite && urlRewrite.is_hero)
                return <HeroBrandPageModerator {...urlRewrite }{ ...props } />;
            else if(urlRewrite === undefined){
                return instance.renderDefaultPage();
            }else {
                return <BrandPage {...urlRewrite } {  ...props  } />;
            }
        case TYPE_BRAND:
            return <BrandListPage { ...props } />;
        default:
            return callback.apply(instance, args);
    }
}

export const config = {
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent: [
                {
                    position:100,
                    implementation: renderContent
                }
            ]
        }
    }
};

export default config;
