import { connect } from 'react-redux';
import { WishlistItemContainer as SourceWishlistItemContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/WishlistItem/WishlistItem.container';

import WishlistItem from './WishlistItem.component';

export { mapStateToProps };

export { mapDispatchToProps };


/** @namespace Component/WishlistItem/Container */
export class WishlistItemContainer extends SourceWishlistItemContainer {
    render() {
        const { isLoading } = this.state;

        return (
            <WishlistItem
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WishlistItemContainer);
