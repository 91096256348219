import {lazy, Suspense} from "react";
import  { Checkout as SourceCheckoutComponent }  from 'SourceRoute/Checkout/Checkout.component';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader';
import {BILLING_STEP, DETAILS_STEP, SHIPPING_STEP, CHECKOUT_URL} from "Route/Checkout/Checkout.config";
import Link from "Component/Link";
import Field from 'Component/Field';
import Html from 'Component/Html';
import { appendWithStoreCode } from 'Util/Url';

export const CheckoutGuestForm = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CheckoutGuestForm'
    ));
export const CheckoutDeliveryOptions = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CheckoutDeliveryOptions'
    ));
export const CheckoutPayments = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CheckoutPayments'
    ));
export const CheckoutSuccess = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CheckoutSuccess'
    ));
export const CheckoutOrderSummary = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CheckoutOrderSummary'
    ));

export const CheckoutBilling = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-billing" */
    'Component/CheckoutBilling'
    ));

export const CheckoutShipping = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-shipping" */
    'Component/CheckoutShipping'
    ));


/** @namespace Route/Checkout/Component */
export class Checkout extends SourceCheckoutComponent {

    state = {
        activeClass : '',
        activeHeight : ''
    }
    stepMap = {
        [SHIPPING_STEP]: {
            number: 1,
            title: __('Personal information'),
            url: '/login',
            render: this.renderShippingStep.bind(this),
            areTotalsVisible: true
        },
        [BILLING_STEP]: {
            number: 2,
            title: __('Payment'),
            url: '/billing',
            render: this.renderBillingStep.bind(this),
            areTotalsVisible: true
        },
        [DETAILS_STEP]: {
            title: __('THANKS! YOUR ORDER WAS SUCCESSFULLY COMPLETED'),
            mobileTitle: __('Order details'),
            url: '/success',
            render: this.renderDetailsStep.bind(this),
            areTotalsVisible: false
        }
    };

    componentDidMount() {
        const { checkoutStep, history } = this.props;
        const { url } = this.stepMap[checkoutStep];

        this.updateHeader();

        history.replace(appendWithStoreCode(`${ CHECKOUT_URL }${ url }`));

        window.addEventListener('scroll', () => {
            if(window.innerWidth > 767){
                let activeClass = 'normal';
                let activeHeight = 'auto';
                let FooterHeight = 0;
                let CheckoutRightSectionMaxHeight = '100%';
                const windowHeight = window.innerHeight;
                const CheckoutRightSection = document.getElementById('Checkout-RightSection');
                const Footer = document.getElementById('Footer');
                const documentHeight = document.getElementById('root').clientHeight;
                const HeaderHeight = document.getElementById('Header').clientHeight;

                let footerPos = documentHeight;

                if(Footer){
                    FooterHeight = Footer.clientHeight;
                    footerPos = documentHeight - FooterHeight;
                }

                if(CheckoutRightSection){
                    const CheckoutProgressSectionHeight = document.getElementById('Checkout-ProgressSection').clientHeight;
                    let scrollStartHeight = HeaderHeight + CheckoutProgressSectionHeight;

                    if(window.scrollY > scrollStartHeight && window.scrollY < footerPos - windowHeight){
                        activeClass = 'is-active';
                        activeHeight = window.scrollY - HeaderHeight;
                    } else if(window.scrollY >= footerPos - windowHeight) {
                        activeClass = 'is-active-end';
                    } else {
                        activeClass = '';
                        activeHeight = 'auto';
                    }
                    if(activeClass !== this.state.activeClass){
                        this.setState({
                            activeClass,
                            activeHeight
                        });
                    }

                    if(activeClass){
                        CheckoutRightSection.style.top = activeHeight + 'px';
                        CheckoutRightSection.style.maxHeight = (window.innerHeight - HeaderHeight) + 'px';
                    }
                }
            }
        });
    }

    renderTitle() {
        const { checkoutStep, totals: { is_virtual } } = this.props;
        const { title = '', number } = this.stepMap[checkoutStep];

        if (is_virtual || !number) {
            return (
                <div block="Checkout" elem="ProgressSection" id="Checkout-ProgressSection">
                    <div block="Checkout" elem="Header" className="is_virtual">
                        <div block="Checkout" elem="HeaderStep">
                            <div block="Checkout" elem="Title">{ title }</div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div block="Checkout" elem="ProgressSection" id="Checkout-ProgressSection">
                <div block="Checkout" elem="Header" mods={ { billing: number === 2 } }>
                    <div block="Checkout" elem="HeaderStep">
                        <div block="Checkout" elem="SelectedStep">{ this.stepMap.SHIPPING_STEP.number }</div>
                        <div block="Checkout" elem="Title">{ this.stepMap.SHIPPING_STEP.title }</div>
                    </div>
                    <div block="Checkout" elem="HeaderStep">
                        <div block="Checkout" elem="SelectedStep">{ this.stepMap.BILLING_STEP.number }</div>
                        <div block="Checkout" elem="Title">{ this.stepMap.BILLING_STEP.title }</div>
                    </div>
                </div>
            </div>
        );
    }

    renderDelivery() {
        const {
            shippingMethods,
            onShippingMethodSelect,
            estimateAddress,
            onStoreSelect,
            handleSelectDeliveryMethod,
            selectedShippingMethod
        } = this.props;

        return (
            <CheckoutDeliveryOptions
                shippingMethods={ shippingMethods }
                onShippingMethodSelect={ onShippingMethodSelect }
                estimateAddress={ estimateAddress }
                onStoreSelect={ onStoreSelect }
                handleSelectDeliveryMethod={ handleSelectDeliveryMethod }
                selectedShippingMethod={ selectedShippingMethod }
            />
        );
    }

    renderPayments() {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            setLoading,
            setDetailsStep,
            shippingAddress,
            setOrderButtonVisibility,
            setOrderButtonEnableStatus
        } = this.props;

        if (!paymentMethods.length) {
            return null;
        }

        return (
            <CheckoutPayments
                setLoading={ setLoading }
                setDetailsStep={ setDetailsStep }
                paymentMethods={ paymentMethods }
                onPaymentMethodSelect={ onPaymentMethodSelect }
                setOrderButtonVisibility={ setOrderButtonVisibility }
                billingAddress={ shippingAddress }
                setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            />
        );
    }
    renderDetailsStep() {
        const {
            orderID,
            isEmailAvailable,
            email,
            setLoadingState,
            isMobile,
            billingAddress: {
                firstname,
                lastname
            },
            customer = {}
        } = this.props;
        
        return (
            <Suspense fallback={ <Loader /> }>
                <CheckoutSuccess
                    email={ email || customer?.email } 
                    firstName={ firstname }
                    lastName={ lastname }
                    isEmailAvailable={ isEmailAvailable }
                    orderID={ orderID }
                    isMobile ={ isMobile }
                    setLoadingState={setLoadingState}
                />
            </Suspense>
        );
    }

    validatePlaceOrderSubmission(paymentMethod,showInfo,selectedShippingMethod){

        if(!paymentMethod){
            showInfo(__('Please select the payment method.'));
            return false;
        }
        if(paymentMethod.includes('mg_bank_redirect') && !document.getElementById('payment-select').value){
            showInfo(__('Please select the payment method.'));
            return false;
        }

        return true;
    }

    placeOrder(){
        let {
            paymentMethod,
            showInfo,
            totals: {
                is_virtual
            },
            placingOrder,
            selectedShippingMethod
        } = this.props;

        placingOrder( true);

        const component = this;

        // Double time out in order to fix no validation of shipping form issue in chrome browser
        setTimeout(()=>{
                if(!is_virtual && document.getElementById('checkoutShippingSubmit'))
                    document.getElementById('checkoutShippingSubmit').click();
                setTimeout(()=>{
                        if((window.shippingFormSubmitted || is_virtual) &&
                            component.validatePlaceOrderSubmission(paymentMethod,showInfo,selectedShippingMethod)){
                            return  document.getElementById('checkoutPlaceOrder').click()
                        } else {
                            placingOrder(false);
                        }
                    }
                    ,1000);
            }
            ,1000);
    }

    renderTermsAndConditions() {
        const {
            termsAreEnabled,
            termsAndConditions,
            isTermsAndConditionsAccepted,
            setTACAccepted
        } = this.props;

        const {
            checkbox_text = 'I agree to terms and conditions'
        } = termsAndConditions[0] || {};


        if (!termsAreEnabled) {
            return null;
        }

        return (
            <div
                block="CheckoutBilling"
                elem="TermsAndConditions"
            >
                <div
                    block="CheckoutBilling"
                    elem="TermsAndConditionsContainer"
                >
                    <Field
                        id="termsAndConditions"
                        name="termsAndConditions"
                        type="checkbox"
                        value="termsAndConditions"
                        mix={ { block: 'CheckoutBilling', elem: 'TermsAndConditions-Checkbox' } }
                        checked={ isTermsAndConditionsAccepted }
                        onChange={ setTACAccepted }
                    />
                    <label
                        block="CheckoutBilling"
                        elem="TACLabel"
                        htmlFor="termsAndConditions"
                    >
                        { checkbox_text ? <Html content={ checkbox_text } /> : __('I agree to terms and conditions') }
                    </label>
                </div>
                {
                    !isTermsAndConditionsAccepted
                    &&
                    <div className="Warning" >
                        {__("Please accept the terms and conditions")}
                    </div>
                }
            </div>
        );
    }

    renderActions() {
        const {
            isOrderButtonEnabled,
            isOrderButtonVisible,
            isTermsAndConditionsAccepted,
            isLoading,
            isPlacingOrder,
            isMobile,
            hasLimitation
        } = this.props;

        const { termsAreEnabled } = this.props;

        if (!isOrderButtonVisible) {
            return null;
        }

        // if terms and conditions are enabled, validate for acceptance
        const isDisabled = termsAreEnabled
            ? !isOrderButtonEnabled || !isTermsAndConditionsAccepted || hasLimitation
            : !isOrderButtonEnabled;

        const checkOutButtonState = isPlacingOrder ? isPlacingOrder : isLoading ? isLoading : isDisabled;
        
        return (
            <>
            <div block="Checkout" elem="StickyButtonWrapper">
                <Link
                    to='/cart'
                    block="Button"
                        // disabled={isDisabled && window.shippingFormSubmitted}
                        mix={{ block: 'CheckoutBilling', elem: 'Button' }}
                >
                        {__('BACK TO CART')}
                </Link>
                <button
                    id='place_order_fake'
                        onClick={this.placeOrder.bind(this)}
                    block="Button"
                        disabled={isPlacingOrder ? isPlacingOrder : checkOutButtonState}
                        mods={{ isDisable: checkOutButtonState }}
                        mix={{ block: 'CheckoutBilling', elem: 'Button' }}
                >
                        {__('COMPLETE ORDER')}
                </button>
            </div>
                {!isMobile && this.renderLimitationMessage()}
            </>
        );
    }

    renderSummary(showOnMobile = false) {
        const {
            checkoutTotals,
            checkoutStep,
            paymentTotals,
            isMobile,
            hasLimitation
        } = this.props;
        const { areTotalsVisible } = this.stepMap[checkoutStep];

        if (!areTotalsVisible || (showOnMobile && !isMobile) || (!showOnMobile && isMobile)) {
            return null;
        }

        return (
            <CheckoutOrderSummary
                checkoutStep={checkoutStep}
                totals={checkoutTotals}
                paymentTotals={paymentTotals}
                isExpandable={true}
                // eslint-disable-next-line react/jsx-no-bind
                renderCmsBlock={() => this.renderPromo(true)}
                showItems
                isMobile={isMobile}
                hasLimitation={hasLimitation}
            />
        );
    }

    renderLimitationMessage() {
        const { hasLimitation } = this.props;

        if (hasLimitation) {
        return (
            <div className="Checkout_limitation">
                    <div className="label">{__('You have one or more items that are not eligible for shipping to Cyprus. Please go to cart page and remove them.')}</div>
            </div>
        );
        }

        return null;
    }

    renderBillingStep() {
        const {
            setLoading,
            setDetailsStep,
            shippingAddress,
            paymentMethods = [],
            savePaymentInformation,
            selectedShippingMethod,
            shippingMethods,
            onShippingEstimationFieldsChange,
            saveAddressInformation,
            isDeliveryOptionsLoading,
            onPasswordChange,
            onCreateUserChange,
            onEmailChange,
            isCreateUser,
            estimateAddress,
            isPickInStoreMethodSelected,
            handleSelectDeliveryMethod,
            cartTotalSubPrice,
            onShippingMethodSelect,
            onStoreSelect,
            isOrderButtonVisible,
            isOrderButtonEnabled,
            isTermsAndConditionsAccepted,
            selectedStoreAddress,
            onSaveShippingAddressCheckboxSelect,
            saveShippingAddress,
            onSaveBillingAddressCheckboxSelect,
            saveBillingAddress,
            paymentMethod,
            setAlphaBankAdditionalData,
            totals: {
                is_virtual
            },
            setShippingFormSubmitted,
            placingOrder
        } = this.props;

        return (
            <>
                {
                    is_virtual ?
                        null:
                        <Suspense fallback={ <Loader /> }>
                            <CheckoutShipping
                                isLoading={ isDeliveryOptionsLoading }
                                shippingMethods={ shippingMethods }
                                cartTotalSubPrice={ cartTotalSubPrice }
                                setShippingFormSubmitted={setShippingFormSubmitted}
                                saveAddressInformation={ saveAddressInformation }
                                onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                                onShippingMethodSelect={ onShippingMethodSelect }
                                onPasswordChange={ onPasswordChange }
                                onCreateUserChange={ onCreateUserChange }
                                onEmailChange={ onEmailChange }
                                isCreateUser={ isCreateUser }
                                onSaveShippingAddressCheckboxSelect={onSaveShippingAddressCheckboxSelect}
                                saveShippingAddress={saveShippingAddress}
                                estimateAddress={ estimateAddress }
                                handleSelectDeliveryMethod={ handleSelectDeliveryMethod }
                                isPickInStoreMethodSelected={ isPickInStoreMethodSelected }
                                onStoreSelect={ onStoreSelect }
                                selectedStoreAddress={ selectedStoreAddress }
                                placingOrder={ placingOrder }
                            />
                        </Suspense>
                }
                {this.renderCustomerComments()} 
                <Suspense fallback={ <Loader /> }>
                    <CheckoutBilling
                        setLoading={ setLoading }
                        paymentMethods={ paymentMethods }
                        paymentMethod={ paymentMethod }
                        setDetailsStep={ setDetailsStep }
                        setAlphaBankAdditionalData={setAlphaBankAdditionalData}
                        onShippingEstimationFieldsChange={ is_virtual ? onShippingEstimationFieldsChange : () => {} }
                        isOrderButtonVisible={isOrderButtonVisible}
                        isOrderButtonEnabled={isOrderButtonEnabled}
                        isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                        shippingAddress={ shippingAddress }
                        saveBillingAddress={saveBillingAddress}
                        onSaveBillingAddressCheckboxSelect={onSaveBillingAddressCheckboxSelect}
                        savePaymentInformation={ savePaymentInformation }
                        selectedShippingMethod={ selectedShippingMethod }
                    />
                </Suspense>
                { is_virtual ? null :this.renderDelivery() }
                { this.renderPayments() }
                { this.renderTermsAndConditions() }
                { this.renderActions() }
            </>
        );
    }

    renderShippingStep() {
        const {
            checkoutStep,
            isCreateUser,
            onEmailChange,
            onCreateUserChange,
            onPasswordChange,
            isGuestEmailSaved,
            setLoading,
            setCheckoutStep,
            onSignIn,
            totals
        } = this.props;
        const isBilling = checkoutStep === BILLING_STEP;

        return (
            <>
                <CheckoutGuestForm
                    totals= { totals }
                    onSignIn={onSignIn}
                    setLoadingState={ setLoading }
                    setCheckoutStep={setCheckoutStep}
                    isBilling={ isBilling }
                    isCreateUser={ isCreateUser }
                    onEmailChange={ onEmailChange }
                    onCreateUserChange={ onCreateUserChange }
                    onPasswordChange={ onPasswordChange }
                    isGuestEmailSaved={ isGuestEmailSaved }
                />
                <div className="Checkout_back_to_cart">
                    <Link to="/cart"  block="Button" className="Button_isHollow" >{ __('BACK TO CART') }</Link>
                </div>
            </>
        );
    }

    render() {
        const { checkoutStep,shippingFormSubmitted } = this.props;
        const { number } = this.stepMap[checkoutStep];

        return (
            <main block="Checkout" className={number ? '' : 'Checkout_Success'}>
                <ContentWrapper
                    wrapperMix={ { block: 'Checkout', elem: 'ProgressSectionWrapper' } }
                >
                    { this.renderTitle() }
                </ContentWrapper>
                <ContentWrapper
                    wrapperMix={ { block: 'Checkout', elem: 'Wrapper' } }
                    label={ __('Checkout page') }
                >
                    <div block="Checkout" elem="StepWrapper" mods={ { billing: number === 2 } }>
                        <div block="Checkout" elem="Step">
                            { this.renderStoreInPickUpMethod() }
                            {/*{ this.renderGuestForm() }*/}
                            { this.renderStep() }
                            { this.renderLoader() }
                        </div>
                        <div>
                            <div block="Checkout" elem="RightSection" id="Checkout-RightSection" className={`${this.state.activeClass}`} style={{ 'top' : this.state.activeClass ? this.state.activeHeight : '0'}}>
                                {
                                    checkoutStep === BILLING_STEP ?
                                        <Suspense fallback={ <Loader /> }>
                                            { this.renderSummary() }
                                            { this.renderSummary(true) }
                                            { this.renderPromo() }
                                        </Suspense>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default Checkout;

