import TextPlaceholder from 'Component/TextPlaceholder';

export const renderContent = (args, callback, instance) => {
    const { product: { name } } = instance.props;

    return (
        <figcaption block="SearchItem" elem="Content">
            {instance.renderCustomAttribute()}
            <p block="SearchItem" elem="Title" mods={{ isLoaded: !!name }}>
                <TextPlaceholder content={name} length="long" />
            </p>
        </figcaption>
    );
}

export default {
    'Component/SearchItem/Component': {
        'member-function': {
            renderContent
        }
    },
};