import { appendWithStoreCode } from 'SourceUtil/Url';

class MyAccountMyOrdersComponentPlugin {

    renderOrderHeadingRow = (_args, _callback, _instance) => (
            <tr>
            <th>{ __('Order') }</th>
            <th>{ __('Date') }</th>
            <th>{ __('Status') }</th>
            <th block="hidden-mobile">{ __('Total') }</th>
            <th block="hidden-mobile">{ __('Options') }</th>
            </tr>
    );

    renderReturnLink(_instance) {
        const {
         order: {  
             base_order_info: {
                status,
                id,
                amrma_is_returnable
                } 
            },
            email,
            incrementId
        } = _instance.props;
        
        const isDisable = status !== 'complete';
        const url = incrementId ? 
            `/rma/guest/request?orderId=${incrementId}&email=${email}` 
            : `/my-account/my-returns/new-return&id=${id}`;

        let buttonClass = 'MyAccountOrderPopup-Button Button Button_isHollow';

        if(isDisable) {
            buttonClass = 'MyAccountOrderPopup-Button Button Button_isHollow disabled';
        }



        return (
            <a href={ appendWithStoreCode(url) } className={ buttonClass }>
                { __('Order Return') }
            </a>
        );
    }

    renderActions(_args, _callback, _instance) {
        const { order: { order_products } } = _instance.props;

        if (!order_products) {
            return null;
        }
        
        return [
            _callback.apply(_instance, _args),
            renderReturnLink(_instance)
        ]
    }

}

const { renderOrderHeadingRow, renderActions, renderReturnLink } = new MyAccountMyOrdersComponentPlugin();

export default {
    'Component/MyAccountOrderPopup/Component': {
        'member-function': { 
            renderReturnLink,
            renderActions
        }
    },
    'Component/MyAccountMyOrders/Component': {
        'member-function': {
            // renderOrderHeadingRow
        }
    }
};