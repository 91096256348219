export const UPDATE_BANNERS = 'UPDATE_BANNERS';
export const RESET_BANNERS = 'RESET_BANNERS';

/**
 * Update Banners information
 * @param {Object} banners banners of the page that must be returned
 */
export const updateBanners = banners => ({
    type: UPDATE_BANNERS,
    banners
});

export const resetBanners = () => ({
    type: RESET_BANNERS
})