export class NavigationTabsContainerPlugin {

  mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
      ...callback.apply(instance, args),
      header_bg: state.ConfigReducer.header_bg,
      header_fc: state.ConfigReducer.header_fc,
      header_inverse_icons: state.ConfigReducer.header_inverse_icons
    }
  };

  containerProps = (args, callback, instance) => {
    const { header_bg, header_fc, header_inverse_icons } = instance.props;

    return {
      ...callback.apply(instance, args),
      header_bg,
      header_fc,
      header_inverse_icons
    };
  }

}

export const { mapStateToProps, containerProps } = new NavigationTabsContainerPlugin();

export default {
  'Component/NavigationTabs/Container/mapStateToProps': {
    function: [
      {
        position: 101,
        implementation: mapStateToProps
      }
    ]
  },
  'Component/NavigationTabs/Container': {
    'member-function': {
      containerProps: containerProps
    }
  }
};