import { checkEveryOption } from 'SourceUtil/Product';

export const getVariantsIndexes = (_args, _callback, _instance) => {
    const [ variants = {}, options = {} , inStockOnly ] = _args

    const result = Object.entries(variants)
        .reduce((indexes, [index, variant]) => {
            if (checkEveryOption(variant.attributes, options)) {
                indexes.push(+index);
            }

            return indexes;
        }, []);

    // if (inStockOnly) {
    //     return result.filter((n) => variants[n].stock_status === IN_STOCK);
    // }

    return result;
};


export default {
    'Util/Product/getVariantsIndexes': {
        function: {
            position: 102,
            implementation: getVariantsIndexes
        }
    }
};