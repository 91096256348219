/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/** @namespace Component/FieldPhone/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 3;

/** @namespace Component/FieldPhone/Config/validateUSAPhone */
export const validateUSAPhone = ({ value }) => !isNaN(value) && value.trim().length > 9;

/** @namespace Component/FieldPhone/Config/validateGreecePhone */
export const validateGreecePhone = ({ value }) => {
    if(value === '+30') return true;
    return !isNaN(value) && value.trim().length === 13;
}

/** @namespace Component/FieldPhone/Config/validateCyprusPhone */
export const validateCyprusPhone = ({ value }) => {
    if(value === '+357') return true;
    const allowedValues = ['22', '23', '24', '25', '26', '96', '97', '98', '99'];
    const firstTwoDigits = value.slice(4, 6);
    return !isNaN(value) && value.trim().length === 12 && allowedValues.includes(firstTwoDigits);
}

/** @namespace Component/FieldPhone/Config */
export const formConfig = () => ({
    '1': {
        validate: validateUSAPhone,
        message: __('Invalid phone number format!'),
        maxlength : 10
    },
    '30': {
        validate: validateGreecePhone,
        message: __('Invalid phone number format!'),
        maxlength : 13
    },
    '357': {
        validate: validateCyprusPhone,
        message: __('Invalid phone number format!'),
        maxlength : 12
    },
    isNotEmpty: {
        validate: isNotEmpty,
        message: __('This field is required!'),
        maxCharLimit : null
    }
});

export default formConfig();
