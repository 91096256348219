import Field from 'Component/Field';
import Form from 'Component/Form';

const ALLOWED_COUNTRY_IDS = ['GR'];

class CheckoutAddressBookComponentPlugin {
    componentDidMount(args,callback,instance){
        const {
            invoice_type,
            getActivities,
            extensionAttributes
        } = instance.props;
        if(!extensionAttributes) return null;
        let initialValue = {};

        if (extensionAttributes.length){
            extensionAttributes.map(el => initialValue[el.attribute_code] = el.value);
        }
        if(!getActivities && initialValue['vat_id'] && initialValue['vat_id'].length === 9 && initialValue['business_activity']){
            instance.props.onOrderAttributeChange('vat_id',initialValue['vat_id'],true)
        }
        return callback.apply(instance,args);
    }

    renderContent(args,callback,instance){
        return [
            callback.apply(instance,args),
            // instance.renderDocumentType(),
            instance.renderCustomerComments()
        ];
    }

    renderCustomerComments(args,callback,instance){
        const { extensionAttributes } = instance.props;

        if(!extensionAttributes) return null;

        let customerCommentInitial = '';
        if (extensionAttributes.length){
            customerCommentInitial = extensionAttributes?.find(el => el.attribute_code === 'customer_comment')?.value;
        }

        return (
            <div block="CheckoutAddressBook" elem="CustomerComments">
                <Field type="textarea"
                       id="customer_comments"
                       name="customer_comments"
                       onChange={ (value) => instance.props.onOrderAttributeChange('customer_comment',value) }
                       label={ __('Comments') }
                       rows="7"
                       cols="50"
                       value={customerCommentInitial}
                />
            </div>
        )
    }

    // renderDocumentType(args,callback,instance){
    //     const {
    //         invoice_type,
    //         getActivities,
    //         extensionAttributes,
    //         shippingFields : { country_id }
    //     } = instance.props;

    //     if(!extensionAttributes || !ALLOWED_COUNTRY_IDS.includes(country_id)) return null;


    //     let initialValue = {};

    //     if (extensionAttributes.length){
    //         extensionAttributes.map(el => initialValue[el.attribute_code] = el.value);
    //     }


    //     return(
    //         <div className="Field type_document">
    //             <h2 className="Checkout-Heading">{__('TYPE OF DOCUMENT')}</h2>
    //             <div style={{ display : 'none'}} >
    //                 <Field type={'text'} id='invoice_type' name='invoice_type' value={invoice_type}></Field>
    //             </div>
    //             <div className="Checkout_type_document">
    //                 <div
    //                     onClick={ () => instance.props.onInvoiceTypeClick('Receipt') }
    //                     className="Checkout_type_document_option"
    //                 >
    //                     <span className='label'>{  __('Receipt') }</span>
    //                     <div
    //                         className={`option active ${invoice_type ===  'Receipt' ? 'checked': ''}`}
    //                         data-invoice_type={invoice_type}
    //                     >
    //                     </div>
    //                 </div>
    //                 <div
    //                     onClick={ () => instance.props.onInvoiceTypeClick('Invoice') }
    //                     className="Checkout_type_document_option"
    //                 >
    //                     <span className='label'>{  __('Invoice') }</span>
    //                     <div
    //                         className={`option active ${invoice_type ===  'Invoice' ? 'checked': ''}`}
    //                         data-invoice_type={invoice_type}
    //                     >
    //                     </div>
    //                 </div>
    //             </div>
    //             {
    //                 invoice_type === "Invoice" ?
    //                     <Form
    //                         id="invoice_form"
    //                         key="invoice"
    //                         mix={ { block: 'Invoice' } }
    //                     >
    //                         <div className="FieldForm">
    //                             <div className="FieldForm-Fields">
    //                                 <Field
    //                                     id='invoice_vat_id'
    //                                     key='vat_id'
    //                                     name='vat_id'
    //                                     label={__('VAT ID')}
    //                                     validation={['notEmpty','vat']}
    //                                     value={initialValue.vat_id}
    //                                     placeholder={__('Enter your vat id')}
    //                                     onChange={ (value) => instance.props.onOrderAttributeChange('vat_id',value) }
    //                                     onBlur={ instance.props.onVatIdBlur }
    //                                 />
    //                                 <Field
    //                                     key='company'
    //                                     id='invoice_company'
    //                                     name='company'
    //                                     label={__('Company')}
    //                                     validation={['notEmpty']}
    //                                     value={initialValue.company}
    //                                     placeholder={ __('Enter your company name')}
    //                                     onChange={ (value) => instance.props.onOrderAttributeChange('company',value) }
    //                                     isDisabled={true}
    //                                 />
    //                                 <Field
    //                                     id='invoice_tax_office'
    //                                     key='tax_office'
    //                                     name='tax_office'
    //                                     label={__('Tax Office')}
    //                                     validation={['notEmpty']}
    //                                     value={initialValue.tax_office}
    //                                     placeholder={ __('Enter your tax office')}
    //                                     isDisabled={true}
    //                                     onChange={ (value) => instance.props.onOrderAttributeChange('tax_office',value) }
    //                                 />
    //                                 <div style={{display:"none"}}>
    //                                     <Field
    //                                         id='invoice_vat_office_code'
    //                                         key='vat_office_code'
    //                                         name='vat_office_code'
    //                                         label={__('Tax Office Code')}
    //                                         hidden={true}
    //                                         validation={['notEmpty']}
    //                                         value={initialValue.vat_office_code}
    //                                         isDisabled={true}
    //                                         onChange={ (value) => instance.props.onOrderAttributeChange('vat_office_code',value) }
    //                                     />
    //                                 </div>
    //                                 <Field
    //                                     id='invoice_business_activity'
    //                                     key='business_activity'
    //                                     name='business_activity'
    //                                     type='select'
    //                                     label={__('Occupation')}
    //                                     validation={['notEmpty']}
    //                                     value={initialValue.business_activity}
    //                                     placeholder={ __('Please Select the Occupation')}
    //                                     selectOptions={getActivities}
    //                                     onChange={ (value) => instance.props.onOrderAttributeChange('business_activity',value) }
    //                                 />
    //                             </div>
    //                         </div>
    //                     </Form>
    //                     :null
    //             }
    //         </div>
    //     );
    // }
}


const { componentDidMount, renderCustomerComments, renderContent } = new CheckoutAddressBookComponentPlugin();

export const config = {
    'Component/CheckoutAddressBook/Component': {
        'member-function': {
            // renderDocumentType: renderDocumentType,
            renderCustomerComments: renderCustomerComments,
            componentDidMount,
            render: renderContent
        }
    }
};

export default config;
