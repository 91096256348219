import { BUNDLER_PRODUCTS } from '../component/BundlerSection/BundlerSection.conf';
import ProductLinks from 'Component/ProductLinks';

const renderAdditionalSections = (args, callback, instance) => {
    return (
        <>
            <ProductLinks
                linkType={BUNDLER_PRODUCTS}
                title={__('You might also like')}
                areDetailsLoaded={instance.props.areDetailsLoaded}
                {...instance.props}
            />
            {callback.apply(instance, args)}
        </>
    );
}
export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderAdditionalSections: renderAdditionalSections
        }
    }
};
