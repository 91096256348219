export const ProductAttributes = [
        {
            attribute_code: "color",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "fashion_size",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "size",
            entity_type: "catalog_product"
        },
    // {
    //     attribute_code: "brand",
    //     entity_type: "catalog_product"
    // },
        {
            attribute_code: "mg_custom_lists",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "att_106",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "att_105",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "att_108",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "att_123",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "att_124",
            entity_type: "catalog_product"
        },
        {
            attribute_code: "att_125",
            entity_type: "catalog_product"
        },
        // {
        //     attribute_code: "att_100",
        //     entity_type: "catalog_product"
        // }
];