import { TYPE_CATEGORY, TYPE_CMS_PAGE, TYPE_PRODUCT } from 'SourceRoute/UrlRewrites/UrlRewrites.config';
import { isSignedIn } from 'SourceUtil/Auth';
import { capitalizeFirstLetter } from '@mageguide/admin-customer/src/utils';
import { roundPrice } from 'SourceUtil/Price';
import {
    CHECKOUT,
    HOMEPAGE,
    LOGIN,
    LOGOUT,
    NOT_APPLICABLE,
    NOT_FOUND, ROOT,
    BLOG_URL,
    BLOG_CATEGORY_URL,
    BLOG_POST_URL,
    STORE,
    STORE_LIST
} from '../component/GoogleTagManager/GoogleTagManager.config';
import { baseProductData } from './baseProduct.data';

/** @namespace GtmNew/EventData/General/Data/routes */
export const routes = () => [
    'cart',
    'checkout',
    'account',
    'wishlist',
    'forgot-password',
    'contact',
    'compare',
    'blog',
    'stores',
    'search'
];

/** @namespace GtmNew/EventData/General/Data/getStoreView */
export const getStoreView = (store) => store.ConfigReducer.code || '';

/** @namespace GtmNew/EventData/General/Data/getLocale */
export const getLocale = (store) => store.ConfigReducer.locale || '';

/** @namespace GtmNew/EventData/General/Data/getCustomerID */
export const getCustomerID = (store) => {
    if (isSignedIn()) {
        return { customerId: store.MyAccountReducer.customer.id || 0 };
    }

    return { customerId: NOT_APPLICABLE };
};

/** @namespace GtmNew/EventData/General/Data/getCustomerEmail */
export const getCustomerEmail = (store) => {
    if (isSignedIn()) {
        return { CustomerEmail: store.MyAccountReducer.customer.email || '' };
    }

    return null;
};

/** @namespace GtmNew/EventData/General/Data/getLoginState */
export const getLoginState = () => {
    if (isSignedIn()) {
        return { 
            userLoginState: LOGIN,
            event: 'login',
            method: 'Form'
        };
    }

    return { userLoginState: LOGOUT };
};

/** @namespace GtmNew/EventData/General/Data/getCustomerOrderData */
export const getCustomerOrderData = (store) => {
    const {
        MyAccountReducer: {
            customer: { userExistingCustomer = NOT_APPLICABLE, userLifetimeValue = 0, userLifetimeOrders = 0 } = {}
        } = {}
    } = store;

    return { userExistingCustomer, userLifetimeValue, userLifetimeOrders };
};

/** @namespace GtmNew/EventData/General/Data/getPath */
export const getPath = (pathname = '/', storeView = '') => {
    if (pathname.includes(storeView)) {
        return pathname.replace(`/${storeView}`, '');
    }

    return pathname;
};

/** @namespace GtmNew/EventData/General/Data/getPageType */
export const getPageType = (store, pathname = window.location.pathname) => {
    const storeView = getStoreView(store);
    const path = getPath(pathname, storeView);

    // If user Lands on Homepage
    if (path === ROOT) {
        return { pageType: HOMEPAGE };
    }

    // if one Rewrite
    const { urlRewrite: { type, notFound = false } } = store.UrlRewritesReducer;

    // If user Lands on one of React routes
    const page = routes().find((route) => {
        if (path.includes(route)) {
            return { pageType: route };
        }
    }) || null;
    
        
    if (page && page !== ROOT) {
        return { pageType: capitalizeFirstLetter(page) };
    }
    // if(page?.indexOf('blog') !== -1) {

    // }
    
    if (notFound && !type) {
        return { pageType: '404' };
    }
    if (type === TYPE_CMS_PAGE) {
        return { pageType: `cmspage${path}` };
    }
    if (type === TYPE_CATEGORY) {
        return { pageType: 'Product-Listing' };
        // return { pageType: TYPE_CATEGORY.toLowerCase() };
    }
    if (type === TYPE_PRODUCT) {
        return { pageType: 'Product Page' };
    }

    if (type === (BLOG_CATEGORY_URL || BLOG_CATEGORY_URL || BLOG_POST_URL) ) { 
        return { pageType: 'Blog' };
    }

    if (type === (STORE || STORE_LIST)) { 
        return { pageType: 'Stores' };
    }

    if (type) {
        return { pageType: capitalizeFirstLetter(type) };
    }


    if(page === 'checkout' && pathname?.indexOf('checkout/login') !== -1) {
        return { pageType: 'Login-Subscribe' };
    }

    return { pageType: 'Other' };
};

/** @namespace GtmNew/EventData/General/Data/getProducts */
export const getProducts = (items, event = '') => {
    const fields = [];

    items.map((item) => {
        fields.push(baseProductData(item, false, false, event));
    });

    return fields;
};

/** @namespace GtmNew/EventData/General/Data/getPurchaseProducts */
export const getPurchaseProducts = (items) => {
    return items.map((item) => {
      const { variant, price, name, dimension3, dimension4, dimension5, dimension6 } = item;
  
      const updatedItem = {
        ...item,
        price: +roundPrice(price),
        item_price: +roundPrice(price),
        title: name,
        dimension3: dimension3 !== 'false' ? dimension6 : '',
        dimension4: dimension4 === 1 ? 'new' : '',
        dimension5: +roundPrice(dimension5),
        dimension6: dimension6 !== 'false' ? dimension6 : '',
      };
  
      if (variant === '') {
        delete updatedItem.variant;
      }
  
      return updatedItem;
    });
  };
  

/** @namespace GtmNew/EventData/General/Data/getCart */
export const getCart = (store) => {
    const { cartTotals = {}, cartTotals: { items = [] } } = store.CartReducer;

    if (items.length) {
        return {
            cart: {
                ...cartTotals,
                items: getProducts(items)
            }
        };
    }
};

/** @namespace GtmNew/EventData/General/Data/gaTs */
const gaTs = () => {
    // Get local time as ISO string with offset at the end
    let now = new Date();
    let tzo = -now.getTimezoneOffset();
    let dif = tzo >= 0 ? '+' : '-';
    let pad = function (num) {
        let norm = Math.abs(Math.floor(num));
        return (norm < 10 ? '0' : '') + norm;
    };
    return now.getFullYear()
        + '-' + pad(now.getMonth() + 1)
        + '-' + pad(now.getDate())
        + 'T' + pad(now.getHours())
        + ':' + pad(now.getMinutes())
        + ':' + pad(now.getSeconds())
        + '.' + pad(now.getMilliseconds())
        + dif + pad(tzo / 60)
        + ':' + pad(tzo % 60);
}

// General Event Data
/** @namespace GtmNew/EventData/General/Data/getGeneralEventData */
export const getGeneralEventData = (store) => {
    if (store) {
        return {
            storeView: getStoreView(store),
            language: getLocale(store),
            ...getLoginState(),
            ...getCustomerID(store),
            ...getCustomerEmail(store),
            ...getCustomerOrderData(store),
            ...getPageType(store),
            ...getCart(store)
        };
    }

    return null;
};

// PageView Event Data
/** @namespace GtmNew/EventData/General/Data/getPageViewEventData */
export const getPageViewEventData = (store) => {
    const { switchWebsiteReducer: { isFashion = false } = {} } = store;
    
    if (store) {
        return {
            storeView: getStoreView(store),
            language: getLocale(store),
            IsLoggedIn: getLoginState(),
            IsCustomer: getLoginState(),
            pagePath:  window.location.href,
            pageTitle: store?.MetaReducer?.title?.toString 
            ? store?.MetaReducer?.title.toString() : store?.MetaReducer?.title || document.title,
            ...getPageType(store),
            business_unit: (isFashion || window.location.pathname.indexOf('home_fashion') !== -1) ? 'Fashion' : 'Beauty'
            // dimension2: (new Date).getTime() + "." + Math.random().toString(36).substring(5),
            // dimension4: gaTs(),
            // ...getCustomerID(store),
            // ...getCustomerEmail(store),
            // ...getCustomerOrderData(store),
            // ...getCart(store)
        };
    }

    return null;
};
