import {
    RELATED_VIEWED_PRODUCT,
    UPDATE_LOAD_STATUS
} from '../RelatedProducts/RelatedProducts.action';

/** @namespace Store/RelatedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    relatedProducts: {},
    isLoading: true
});

/** @namespace Store/RelatedProducts/Reducer/RelatedProductsReducer */
export const RelatedProductsReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case RELATED_VIEWED_PRODUCT:
        const {
            items
        } = action;

        return {
            ...state,
            relatedProducts: items
        };

    case UPDATE_LOAD_STATUS:
        const {
            isLoading
        } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default RelatedProductsReducer;
