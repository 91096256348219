// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from "Component/CmsBlock";

import './HeaderSlogan.style';
import Countdown, { zeroPad } from 'react-countdown';

export class HeaderSlogan extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderCountDown() {
        const {
            header_slogan_countdown_end_date
        } = this.props;

        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if(completed){
                return null;
            }

            return (
                <div className="HeaderSlogan__slogan_admin-counter-container">
                    <div className="HeaderSlogan__slogan_admin-counter-column HeaderSlogan__slogan_admin-counter-days">
                        <span className="HeaderSlogan__slogan_admin-counter-label">{ __('Days') }</span>
                        <span className="HeaderSlogan__slogan_admin-counter-count">{zeroPad(days, 2)}</span>
                    </div>
                    <div className="HeaderSlogan__slogan_admin-counter-dots">:</div>
                    <div className="HeaderSlogan__slogan_admin-counter-column HeaderSlogan__slogan_admin-counter-hours">
                        <span className="HeaderSlogan__slogan_admin-counter-label">{ __('Hours') }</span>
                        <span className="HeaderSlogan__slogan_admin-counter-count">{zeroPad(hours, 2)}</span>
                    </div>
                    <div className="HeaderSlogan__slogan_admin-counter-dots">:</div>
                    <div className="HeaderSlogan__slogan_admin-counter-column HeaderSlogan__slogan_admin-counter-minutes">
                        <span className="HeaderSlogan__slogan_admin-counter-label">{ __('Minutes') }</span>
                        <span className="HeaderSlogan__slogan_admin-counter-count">{zeroPad(minutes, 2)}</span>
                    </div>
                    <div className="HeaderSlogan__slogan_admin-counter-dots">:</div>
                    <div className="HeaderSlogan__slogan_admin-counter-column HeaderSlogan__slogan_admin-counter-seconds">
                        <span className="HeaderSlogan__slogan_admin-counter-label">{ __('Seconds') }</span>
                        <span className="HeaderSlogan__slogan_admin-counter-count">{zeroPad(seconds, 2)}</span>
                    </div>
                </div>
            );
        };

        const endDate = header_slogan_countdown_end_date ? new Date(header_slogan_countdown_end_date).getTime()  : new Date('November 11, 2021 00:00:00').getTime();

        if(!renderer){
            return null;
        }

        return (
            <Countdown date={endDate} renderer={renderer} />
        );
    }

    renderSlogan() {
        const {
            header_slogan_enabled,
            header_slogan_show_cd,
            header_slogan_countdown_end_date,
            header_slogan_cms_before_counter,
            header_slogan_cms_after_counter
        } = this.props;

        if(!header_slogan_enabled){
            return null;
        }

        let header_slogan_admin = document.getElementById('HeaderSlogan__slogan_admin');
        let headerArea = document.getElementById('Header-Wrapper');
        let navigationTabs = document.getElementById('NavigationTabs');
        let header = document.getElementById('Header');
        let header_after_slogan = document.getElementById('Header');
        let main_body = document.body;
        let additional_area = 122;

        // if(header_slogan_admin && header_after_slogan && main_body){
        //     let header_slogan_admin_height = header_slogan_admin.clientHeight;
        //
        //     if(window.innerWidth <= 768){
        //         additional_area = 84;
        //     }
        //
        //     header_after_slogan.style.top = header_slogan_admin_height + 'px';
        //     main_body.style.paddingTop = (header_slogan_admin_height + additional_area) + 'px';
        // }

        if(header_slogan_admin && header && headerArea && navigationTabs && window.innerWidth < 811){
            setTimeout(function(){
                let HeaderHeight = header.clientHeight;
                headerArea.style.marginBottom = '0px';
                headerArea.style.paddingTop = HeaderHeight + 'px';
                navigationTabs.style.top = HeaderHeight + 'px';
            }, 1000);
        }

        if(header_slogan_admin && header && headerArea && window.innerWidth < 811){
            setTimeout(function(){
                let HeaderHeight = header.clientHeight;
                headerArea.style.marginBottom = '0px';
                headerArea.style.paddingTop = HeaderHeight + 'px';
                navigationTabs.style.top = HeaderHeight + 'px';
            }, 1000);
        }

        if(header_slogan_admin && header && headerArea && window.innerWidth >= 811){
            setTimeout(function(){
                let HeaderHeight = header.clientHeight;
                headerArea.style.paddingTop = '0px';
                headerArea.style.marginBottom = HeaderHeight + 'px';
            }, 1000);
        }

        return (
            <div className="HeaderSlogan__slogan_admin" id="HeaderSlogan__slogan_admin">
                <div className="container">
                    <div className="HeaderSlogan__slogan_admin-container">
                        <CmsBlock identifier={ header_slogan_cms_before_counter }/>
                        { header_slogan_show_cd && this.renderCountDown() }
                        <CmsBlock identifier={ header_slogan_cms_after_counter }/>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="HeaderSlogan" id="HeaderSlogan">
                { this.renderSlogan() }
            </div>
        );
    }
}

export default HeaderSlogan;
