import Popup from 'SourceComponent/Popup/Popup.container';
import ProductReviewForm from 'SourceComponent/ProductReviewForm/ProductReviewForm.container';
import { REVIEW_POPUP_ID } from 'SourceComponent/ProductReviews/ProductReviews.config';

const renderReviewPopup = (_args, _callback, _instance) => {
    const { dataSource } = _instance.props;

    return (
        <Popup
          id={ REVIEW_POPUP_ID }
          mix={ { block: 'ProductReviews', elem: 'Popup' } }
        >
            <ProductReviewForm product={ dataSource } />
        </Popup>
    );
}


export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderReviewPopup
        }
    }
}; 