const containerProps =  (args, callback, instance) => {
    return {
        ...callback.apply(instance, args),
        paymentMethod : instance.state.paymentMethod
    };
}

const _getPaymentData =  (args, callback, instance) => {
    const [ fields, asyncData ] = args;
    const { paymentMethod: code } = instance.state;
    if(['paypal_express'].includes(code)){
        const [{ token, payer_id }] = asyncData;
        return {
            code,
            additional_data: { token, payer_id }
        };
    }
    return callback.apply(instance,args);
}

export const config = {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            containerProps: containerProps,
            _getPaymentData
        }
    }
};

export default config;
