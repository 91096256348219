import { CarouselScrollItem as SourceCarouselScrollItem } from 'SourceComponent/CarouselScrollItem/CarouselScrollItem.component';

/** @namespace Component/CarouselScrollItem/Component */
export class CarouselScrollItem extends SourceCarouselScrollItem {

    render() {
        const {
            isActive,
            itemRef,
            onClick,
            children: { props : { media: { media_type } } },
            children
        } = this.props;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="CarouselScrollItem"
              mods={ { isActive } }
              ref={ itemRef }
              onClick={ onClick }
              onKeyDown={ onClick }
              className={ media_type }
            >
                { children }
            </div>
        );
    }
}

export default CarouselScrollItem;
