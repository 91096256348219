import { GET_CATEGORY_LIST } from '../../../store/BetterWishlist/BetterWishlist.action';
import { isInitiallySignedIn } from 'SourceUtil/Auth';

const addWishlistCategoriesToInitialState = (args, callback, instance) => {    

        return {
            ...callback(...args),
            wishlistCategories: [],
            isLoading: !isInitiallySignedIn() ? false : true
    };
};


export const WishlistReducer = (args, callback, instance) => {
    const [, action] = args;
    const state = callback.apply(instance, args);

    if (!action) {
        return state;
    }   
    const { categories = [], isAppend = false, type } = action;

    if (type === GET_CATEGORY_LIST) {
        const newCategories = isAppend
                ? [
                    ...state.wishlistCategories, 
                    ...categories
                ]
                : categories;

        return {
            ...state,
            wishlistCategories: newCategories

        };
    }

    return {
        ...state
    };

}



export default {
    'Store/Wishlist/Reducer': {
        function: [
            {
                position: 102,
                implementation: WishlistReducer
            }
        ]
    },
    'Store/Wishlist/Reducer/getInitialState': {
        'function': addWishlistCategoriesToInitialState
    },
}