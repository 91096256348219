import { Field } from 'SourceUtil/Query';

const _getReviewItemsFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        _getCustomAttributeField()
    ]; 
}

const _getCustomAttributeField = () => {
    return new Field('custom_attributes')
        .addFieldList([
            'attribute_code',
            'value'
        ]);
}


export default {
    'Query/ProductList': {
        'member-function': {
            _getReviewItemsFields
        }
    }
}