import Cookies from "universal-cookie";
import { BOX_NOW_CODE, BOXNOW_COOKIE } from './CheckoutDeliveryOption.component.plugin';
import { showNotification } from 'Store/Notification/Notification.action';

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        showInfoNotification: (message) => dispatch(showNotification('info', message)),
    };
};

const onShippingSuccess = (args, callback, instance) => {
    const cookies = new Cookies();
    const { selectedShippingMethod : { carrier_code }} = instance.state;
    const { showInfoNotification } = instance.props;
    if(BOX_NOW_CODE !== carrier_code) {
        if(cookies.get(BOXNOW_COOKIE)){
            cookies.set(BOXNOW_COOKIE, null, { path: '/', maxAge : 0 });
        }
    }else {
        if(!cookies.get(BOXNOW_COOKIE)){
            showInfoNotification(__('Παρακαλώ επιλέξτε BoxNow locker για να προχωρήσετε στο επόμενο βήμα.'));
            return null;
        }
    }
    return callback.apply(instance,args);
}

const componentDidMount = (args, callback, instance) => {
    document.addEventListener(
        "getShippingMethod",
        () => {
            const { selectedShippingMethod } = instance.state;
            window.selectedShippingMethod =  selectedShippingMethod;
        },
        false,
    );
    return callback.apply(instance,args);
}

export const config = {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onShippingSuccess,
            componentDidMount
        }
    },
    'Component/CheckoutShipping/Container/mapDispatchToProps': {
        function: [
            {
                position: 102,
                implementation: mapDispatchToProps
            }
        ]
    },
};

export default config;
