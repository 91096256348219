export const UPDATE_CMS_BLOCKS = 'UPDATE_CMS_BLOCKS';
export const UPDATE_MENU = 'UPDATE_MENU';

/**
 * Update Homepage CMS Block information
 * @param {Object} block URL Key of the page that must be returned
 */
export const updateCmsBlocks = blocks => ({
    type: UPDATE_CMS_BLOCKS,
    blocks
});

/**
 * Update Homepage menu
 * @param {Object} menu Menu object with id and slides
 */
export const updateMenu = menu => ({
    type: UPDATE_MENU,
    menu
});
