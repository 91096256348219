import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { updateProductDetails } from 'Store/Product/Product.action';
import { extendCartDataProducts } from 'Util/Product/PerformaceProduct.js'

import {
    appendPage,
    updateProductListItems
} from 'Store/ProductList/ProductList.action';

export const LinkedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/LinkedProducts/LinkedProducts.dispatcher'
);

const onSuccessProductList = (_args, _callback, _instance) => {
    const [ data, dispatch, options ] = _args;

    const {
        products: {
            items,
            total_count,
            page_info: { total_pages } = {}
        } = {}
    } = data;
    const newProducts = extendCartDataProducts(items, true) || {};

    const { args, isNext } = options;
    const { currentPage } = args;

    if (isNext) {
        return dispatch(
            appendPage(
                newProducts,
                currentPage
            )
        );
    }

    return dispatch(
        updateProductListItems(
            newProducts,
            currentPage,
            total_count,
            total_pages,
            args
        )
    );
}

const onSuccess = (args, callback, instance) => {
    const [ data, dispatch ] = args;
    const { products: { items } } = data;
    
    /**
     * In case there are no items, or item count is
     * smaller then 0 => the product was not found.
     */
    if (!items || items.length <= 0) {
        dispatch(updateNoMatch(true));

        return;
    }
    
    const [extendProduct] = extendCartDataProducts(items, true) || [];

    const product_links = items.reduce((links, product) => {
        const { product_links } = product;

        if (product_links) {
            Object.values(product_links).forEach((item) => {
                links.push(item);
            });
        }

        return links;
    }, []);

    LinkedProductsDispatcher.then(
        ({ default: dispatcher }) => {
            if (product_links.length > 0) {
                dispatcher.handleData(dispatch, product_links);
            } else {
                dispatcher.clearLinkedProducts(dispatch);
            }
        }
    );

    dispatch(updateProductDetails(extendProduct));
}

export default {
    'Store/ProductList/Dispatcher': {
        'member-function': {
            'onSuccess': onSuccessProductList
        }
    },
    'Store/Product/Dispatcher': {
        'member-function': {
            'onSuccess': onSuccess
        }
    }
};
