import  { CheckoutBilling as SourceCheckoutBilling }  from 'SourceComponent/CheckoutBilling/CheckoutBilling.component';
import Form from 'Component/Form';
import Field from 'Component/Field';
import {BILLING_STEP } from "Route/Checkout/Checkout.config";
import CheckoutAddressBook from 'Component/CheckoutAddressBook';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';

/** @namespace Component/CheckoutBilling/Component */
export class CheckoutBilling extends SourceCheckoutBilling {

    state = {
        isOrderButtonVisible: true,
        isOrderButtonEnabled: true,
        isTermsAndConditionsAccepted: false,
        isSubmitted : false
    };
    renderHeading() {
        return (
            <h2 block="Checkout" elem="Heading">
                { __('BILLING ADDRESS') }
            </h2>
        );
    }

    renderAddressBook() {
        const {
            onAddressSelect,
            isSameAsShipping,
            onSaveBillingAddressCheckboxSelect,
            saveBillingAddress,
            onShippingEstimationFieldsChange,
            totals: { is_virtual }
        } = this.props;

        if (isSameAsShipping && !is_virtual) {
            return null;
        }

        return (
            <CheckoutAddressBook
                onAddressSelect={ onAddressSelect }
                isBilling
                is_virtual
                onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                onSaveBillingAddressCheckboxSelect={onSaveBillingAddressCheckboxSelect}
                saveBillingAddress={saveBillingAddress}
                isSubmitted={this.state.isSubmitted}
            />
        );
    }

    renderActions() {
        const {
            isOrderButtonVisible,
            isOrderButtonEnabled,
            isTermsAndConditionsAccepted
        } = this.state;

        const { termsAreEnabled } = this.props;

        if (!isOrderButtonVisible) {
            return null;
        }
        const isDisabled = termsAreEnabled
            ? !isOrderButtonEnabled || !isTermsAndConditionsAccepted
            : !isOrderButtonEnabled;

        return (
            <div block="Checkout" elem="StickyButtonWrapper">
                { this.renderOrderTotal() }
                <button
                    rel='nofollow'
                    style={{display : "none"}}
                    id='checkoutPlaceOrder'
                    type="submit"
                    block="Button"
                    disabled={ false }
                    mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                >
                    { __('COMPLETE ORDER') }
                </button>
            </div>
        );
    }

    // static getDerivedStateFromProps(props) {
    //     const {
    //         isOrderButtonVisible,
    //         isOrderButtonEnabled,
    //         isTermsAndConditionsAccepted
    //     } = props;
    //
    //     return {
    //         isOrderButtonVisible,
    //         isOrderButtonEnabled,
    //         isTermsAndConditionsAccepted
    //     }
    // }

    renderSameAsShippingCheckbox() {
        const {
            isSameAsShipping,
            onSameAsShippingChange,
            totals: { is_virtual },
            selectedShippingMethod
        } = this.props;

        if (is_virtual) {
            return null;
        }

        return (
            <>
            <Field
                id="sameAsShippingAddress"
                name="sameAsShippingAddress"
                type="checkbox"
                label={ __('Same billing address') }
                value="sameAsShippingAddress"
                mix={ { block: 'CheckoutBilling', elem: 'Checkbox' } }
                checked={ isSameAsShipping && selectedShippingMethod !== STORE_IN_PICK_UP_METHOD_CODE }
                onClick={ () => onSameAsShippingChange(true) }
                isDisabled={ selectedShippingMethod === STORE_IN_PICK_UP_METHOD_CODE }
            />
            <Field
                id="sameAsShippingAddressx"
                name="sameAsShippingAddressx"
                type="checkbox"
                label={ __('Different billing address') }
                mix={ { block: 'CheckoutBilling', elem: 'Checkbox' } }
                checked={ !isSameAsShipping }
                onClick={ () => onSameAsShippingChange(false) }
                isDisabled={ selectedShippingMethod === STORE_IN_PICK_UP_METHOD_CODE }
            />
            </>
        );
    }

    render() {
        const { onBillingSuccess, onBillingError, isSameAsShipping, totals: { is_virtual } } = this.props;

        if (isSameAsShipping && !is_virtual) {
            return (
                <Form
                    mix={ { block: 'CheckoutBilling CheckoutBilling_SameAsShipping' } }
                    id={ BILLING_STEP }
                    onSubmitError={ (fields)=> {this.setState({ isSubmitted : true}); onBillingError(fields);} }
                    onSubmitSuccess={ onBillingSuccess }
                >
                    { this.renderAddresses() }
                    {/*{ this.renderPayments() }*/}
                    {/*{ this.renderOrderTotal() }*/}
                    { this.renderActions() }
                    { this.renderPopup() }
                </Form>
            );
        }

        return (
            <Form
              mix={ { block: 'CheckoutBilling' } }
              id={ BILLING_STEP }
              onSubmitError={ (fields)=> {this.setState({ isSubmitted : true}); onBillingError(fields);} }
              onSubmitSuccess={ onBillingSuccess }
            >
                { this.renderAddresses() }
                {/*{ this.renderPayments() }*/}
                {/*{ this.renderOrderTotal() }*/}
                { this.renderActions() }
                { this.renderPopup() }
            </Form>
        );
    }
}

export default CheckoutBilling;
