/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';
import { PureComponent } from 'react';

import FieldPhone from './FieldPhone.component';

/** @namespace ScandiPWA/Blog/Component/FieldPhone/Container/mapStateToProps */
export const mapStateToProps = (state) => ({});

/** @namespace ScandiPWA/Blog/Component/FieldPhone/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

export class FieldPhoneContainer extends PureComponent {
    render() {
       <FieldPhone
           {...this.props }
       />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldPhone);