import {PureComponent} from 'react';

export const HREF_EXCLUDED_URLS_EXACT = [
    'cart'
];

export const HREF_EXCLUDED_URLS_INCLUDES = [
    'my-account/'
];

export class HrefLangComponent extends PureComponent {


    componentDidUpdate(prevProps, prevState, snapshot){
        this.initialize();
    }

    initialize(){
        const { mfAlternateHreflang : { items }, EmptyHref, location, currentCode } = this.props;
        document.querySelectorAll("link[rel=alternate]").forEach(item => item.remove());
        if(items?.length){
            if(
                HREF_EXCLUDED_URLS_EXACT.some(e => location.pathname  === `/${currentCode}/${e}`)
                ||
                HREF_EXCLUDED_URLS_INCLUDES.some(e => location.pathname.includes(e))
            ){
                EmptyHref();
            }else {
                items.map((item)=>{
                    const link = document.createElement('link');
                    link.rel = "alternate";
                    link.href = item.href;
                    link.hreflang = item.hreflang;
                    document.head.insertBefore(link, document.head.childNodes[13]);
                });
            }
        }
    }

    render() {
        return(
            null
        );
    }
}

export default HrefLangComponent