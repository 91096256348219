import Cookies from "universal-cookie";
import {BOXNOW_COOKIE, BOX_NOW_CODE} from "./CheckoutDeliveryOption.component.plugin";
import React from "react";
const renderButtons = (args, callback, instance) => {
    const event = new CustomEvent("getShippingMethod");
    const { isVirtual } = instance.props;
    document.dispatchEvent(event)
    const cookies = new Cookies();
    console.log(isVirtual);
    if ((window.selectedShippingMethod?.carrier_code === BOX_NOW_CODE)
        && !cookies.get(BOXNOW_COOKIE)
        && !isVirtual
    ) {
            return (
                <div className="paypal_terms_message">
                    {__('Please select box now locker.')}
                </div>
            );
        }
    return callback.apply(instance, args);
}

const componentDidMount = (args, callback, instance) => {
    document.addEventListener(
        "paypalComponentSetShippingMethod",
        () => {
            instance.setState({boxNowLockerId : window.boxnowLockerId});
        }
    );
    return callback.apply(instance,args);

}

const state = (args) => ({
    ...args,
    boxNowLockerId : null
});
export const config = {
    'Scandipwa/PayPalGraphQl/Component/PayPal/Component': {
        'member-function': {
            renderButtons,
            componentDidMount
        },
        'member-property': {
            state
        }
    }
};

export default config;
