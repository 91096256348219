import { executeGet, getApiSearchStatus } from '../../utlis/Request';
import {
    updateLoadStatus,
    updatePageLoadingStatus,
} from 'Store/ProductList/ProductList.action';
import {
    UPDATE_PRODUCT_LIST_ITEMS
} from 'Store/ProductList/ProductList.action';
import { getIndexedProducts } from '../../utlis/Product';

const onSuccess = (_args, _callback, _instance) => {
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();
    
    if(search_is_enabled && 
        use_in_fulltext && 
       location.pathname.includes('search')) {
        return ;
    }

    return _callback(..._args);
}

const onError = (_args, _callback, _instance) => {
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();

    if(search_is_enabled && 
        use_in_fulltext && 
        location.pathname.includes('search')) {
        return ;
    }
    
    return _callback(..._args);
}

const prepareRequest = (_args, _callback, _instance) => {
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();
    // if(!search_is_enabled) {
    //     return _callback(..._args);
    // }

    const [ options, dispatch, isNext] = _args;
    const { args: { search, filter} } = options;

    if(search_is_enabled && 
        use_in_fulltext && 
        location.pathname.includes('search')) {
        if (!isNext) {
            dispatch(updateLoadStatus(true));
        } else {
            dispatch(updatePageLoadingStatus());
        }

        return;
    }
    
    return _callback(..._args);

    
    // dispatch(updateInfoLoadStatus(true));

    //    return makeCancelable(executeGet(search, 'fullsearch', ONE_MONTH_IN_SECONDS).then((resp) => {
    //         const data = resp;
    //         const removeHtml = data.replace(/<\/?[^>]+(>|$)/g, "");
    //         const stringToJsonObject =  JSON.parse(removeHtml) || {};
    //         const { products = {} }  = stringToJsonObject;
            
    //          dispatch(updateProductListInfo(products, filter));
    //         dispatch(updateInfoLoadStatus(false));
    //     }));
}

export const ProductListReducer = (_args, _callback, _instance) => {
    const [state , action] = _args;
    const { type } = action;

    if (!action) {
        return _callback.apply(_instance, _args);;
    }

    if (type !== UPDATE_PRODUCT_LIST_ITEMS) {
        return _callback.apply(_instance, _args);;
    }

    if(!location.pathname.includes('search')) { 
        return _callback.apply(_instance, _args);;
    }

    const {
        items: initialItems = [],
        total_pages: totalPages,
        total_count: totalItems,
        currentPage,
        args: currentArgs,
        args: {
            search_is_enabled = false
        } = {}
    } = action;
    
    if(search_is_enabled) {
        return {
            ...state,
            currentArgs,
            isLoading: false,
            totalItems,
            totalPages,
            pages: { [currentPage]: initialItems ? getIndexedProducts(initialItems): [] }
        };
    }
    
    return _callback.apply(_instance, _args);;
};


export default {
    'Store/ProductList/Dispatcher': {
        'member-function': {
            prepareRequest,
            onSuccess,
            onError
        }
    },
    'Store/ProductList/Reducer': {
        'function': ProductListReducer
    }
};
