import TikTokIcon from '../public/assets/images/tiktok.svg'
import {lazy} from "react";
export const TIKTOK_FEED_PROVIDER = `${window.location.origin}/tiktoklogin/login/index`;
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

export class MyAccountOverlayComponentPlugin {

    renderSignIn = (args, callback, instance) => {
        return (
            <>
                { callback.apply(instance,args) }
                <a href={ TIKTOK_FEED_PROVIDER } block="Button MyAccountOverlay-SignInButton" elem="TikTok">
                    <UniversalIcon width="32px" height="32px" style={ { filter: `invert(1)`, width: 'auto' } } src={ TikTokIcon } />
                    { __("Login with TikTok") }
                </a>
            </>
        );
    }
}

const { renderSignIn } = new MyAccountOverlayComponentPlugin();

export const config = {
    'Component/MyAccountOverlay/Component': {
        'member-function': {
            // renderSignIn: [
            //     {
            //         position: 100,
            //         implementation: renderSignIn
            //     }
            // ]
        }
    }
};

export default config;
