import { NOT_FOUND_PDP } from './UrlRewrites.component.plugin'


const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite
    } = instance.props;

    if(instance.getType() === NOT_FOUND_PDP){
        return {
            id: urlRewrite.id,
            image_url: urlRewrite.image_url,
            name: urlRewrite.name,
            sku: urlRewrite.sku,
            categories: urlRewrite.categories

        };
    }

    return callback.apply(args,instance);
}

export const config = {
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps: [
                {
                    position:115,
                    implementation: getTypeSpecificProps
                }
            ]
        }
    }
};

export default config;
