import { DeviceType } from 'Type/Device';
import { createRef, PureComponent } from 'react';
import Slider from "react-slick";
import "../../assets/slick/slick.css";
import "../../assets/slick/slick-theme.css";
import Image from 'Component/Image';
import './SlickSlider.styles';

export class SlickSlider extends PureComponent {
    sliderRef = createRef();

    static propTypes = {
        device: DeviceType.isRequired
    };

    static defaultProps = {
        isLoading: false,
        slides_to_display: 5,
        slides_to_scroll: 2,
        slide_speed: 300,
        animation_speed: '',
        show_navigation: false,
        slider_dots: false,
        auto_play: false,
        infinite_loop: false,
        slides_to_display_tablet: 4,
        slides_to_scroll_tablet: 1,
        slides_to_display_mobile: 2,
        slides_to_scroll_mobile: 1
    };

    componentDidMount() {
        let prevElement = document.getElementById(this.props.slick_slider_arrow_prev_class);
        let nextElement = document.getElementById(this.props.slick_slider_arrow_next_class);

        if(prevElement) {
            prevElement.addEventListener('click', this.previous.bind(this));
        }

        if(nextElement) {
            nextElement.addEventListener('click', this.next.bind(this))
        }
    }
    next() {
        this.sliderRef.current.slickNext();
    }
    previous() {
        this.sliderRef.current.slickPrev();
    }


    sliderConfig() {
        const {
            slides_to_display,
            slides_to_scroll,
            slide_speed,
            auto_play,
            slider_dots,
            infinite_loop,
            animation_speed,
            show_navigation,
            slides_to_display_tablet,
            slides_to_scroll_tablet,
            slides_to_display_mobile,
            slides_to_scroll_mobile,
            slick_slider_class
        } = this.props
        let tabletBreakPoint = 1024;
        let largeMobileBreakPoint = 600;

        if(slick_slider_class === 'beauty-cms_block-18__product_slider') {
            tabletBreakPoint = 1380;
            largeMobileBreakPoint = 1024;
        }

        return {
            dots: slides_to_scroll > 0 ? slider_dots : false,
            infinite: infinite_loop,
            speed: slide_speed,
            autoplay: auto_play,
            arrows: show_navigation,
            accessibility: show_navigation,
            slidesToShow: slides_to_display,
            slidesToScroll: slides_to_scroll,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: tabletBreakPoint,
                    settings: {
                        slidesToShow: slides_to_display_tablet,
                        slidesToScroll: slides_to_scroll_tablet,
                    }
                },
                {
                    breakpoint: largeMobileBreakPoint,
                    settings: {
                        slidesToShow: slides_to_display_mobile,
                        slidesToScroll: slides_to_scroll_mobile,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: slides_to_display_mobile,
                        slidesToScroll: slides_to_scroll_mobile
                    }
                }
            ]
        };
    }

    renderPlaceholders() {
        const { device } = this.props
        const numberOfPlaceholders = device.isMobile ? 2 : 5;
        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                <figure
                block="SlickSlider"
                elem="Figure"
                key={ i }
              >
                  <Image
                    mix={ { block: 'SlickSlider', elem: 'FigureImage' } }
                    ratio="custom"
                    isPlaceholder={ true }
                  />
              </figure>
            )
        );
    }

    renderPlaceholderItems() {
        return (
                <li
                  block="SlickSlider"
                  elem="Placeholder"
                >
                { this.renderPlaceholders() }
            </li>
        );
    }

    render() {
        const { children, isLoading, slick_slider_class} = this.props;
        if(isLoading) {
            return this.renderPlaceholderItems();
        }
            return (
                <Slider
                    block="Slick"
                    elem="Slider"
                    {...this.sliderConfig()}
                    ref={ this.sliderRef }
                    className={ slick_slider_class }
                >
                    { children }
                </Slider>
            );
    }
}

export default SlickSlider;
