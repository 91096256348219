export const WebsiteSwitch = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
    '../store/WebSwitch/WebSwitch.dispatcher'
);


const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        isFashion: state.switchWebsiteReducer.isFashion
    }
};

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        switchWebsite: (options) => WebsiteSwitch.then(
            ({ default: dispatcher }) => dispatcher.switchWebsite(options, dispatch)
            )
    };
};


const onPageLoad = (args, callback, instance) => {
    const [ resp ] = args;
    const {
        isFashion,
        switchWebsite
    } = instance.props;
    const { 
        cmsPage : {
            is_fashion,
            content
        } = {}
    } = resp;

    if(isFashion !== is_fashion && content) {
        switchWebsite(is_fashion);
    }

    return callback.apply(instance, args);
}


export const config = {
    'Route/CmsPage/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Route/CmsPage/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Route/CmsPage/Container': {
        'member-function': {
            onPageLoad: [
                {
                    position:117,
                    implementation: onPageLoad
                }
            ]
        }
    }
};

export default config;
