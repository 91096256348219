/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { formatPrice, roundPrice, formatCurrency } from 'Util/Price';
import { CartItemPrice as SourceCartItemPrice } from 'SourceComponent/CartItemPrice/CartItemPrice.component';

/** @namespace Component/CartItemPrice/Component */
export class CartItemPrice extends SourceCartItemPrice {

    renderUnitValue() {
        const {
            currency_code,
            unitLabel,
            unitCalcModifier,
            actualProductPrice
        } = this.props;

        let unitPrice = actualProductPrice * unitCalcModifier;
        let unitCalcValue = `${roundPrice(unitPrice)} ${formatCurrency(currency_code)} / ${unitLabel}`

        return (
            <span block="CartItem" elem="Price" className="UnitValue" content={unitCalcValue} >
                <span className="value">
                    {unitCalcValue}
                </span>
            </span>
        )
    }

    renderPrice() {
        const {
            price,
            currency_code,
            actualProductPrice,
            unitCalcModifier,
        } = this.props;
        const value = roundPrice(price);

        return (
            <>
                <span aria-label={__('Current product price')}>
                    {
                        actualProductPrice > price ?
                            <del value={actualProductPrice}>{formatPrice(actualProductPrice, currency_code)}</del>
                            :
                            ''
                    }
                    <data value={value}>{formatPrice(price, currency_code)}</data>
                </span>
                {unitCalcModifier > 0 && this.renderUnitValue()}
            </>
        );
    }
}

export default CartItemPrice;
