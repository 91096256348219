import { connect } from 'react-redux';

import { ProductAlertsContainer as SourceProductAlertsContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/ProductAlerts/ProductAlerts.container';

/** @namespace Component/ProductAlerts/Container */
export class ProductAlertsContainer extends SourceProductAlertsContainer {

    containerProps() {
        const {
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            stockStatus,
            autoRelatedRef,
            isProductPopup
        } = this.props;

        return {
            isInStockAlertEnabled,
            isPriceAlertEnabled,
            stockStatus,
            autoRelatedRef,
            isProductPopup
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlertsContainer);
