import getStore from 'Util/Store';

const renderKlarnaPlacement = (configReducer, product) => {
    let {
        klarna_enable, klarna_osm_data_client_id, klarna_osm_product_placement, klarna_osm_product_placement_other, locale, klarna_osm_theme, klarna_osm_enable, klarna_osm_data_custom_local, klarna_osm_product_enable
    } = configReducer;
    if(!klarna_enable || !klarna_osm_data_client_id || !klarna_osm_enable || !klarna_osm_product_enable)
        return null;

    if (klarna_osm_product_placement === 'other') {
        klarna_osm_product_placement = klarna_osm_product_placement_other;
    }
    const productPrice = product?.price_range?.minimum_price?.final_price?.value;
    if (!productPrice) {
        return null;
    }

    return (
        <klarna-placement
          data-key={ klarna_osm_product_placement }
          data-locale={ klarna_osm_data_custom_local || locale.replace('_', '-') }
          data-purchase-amount={ Number.parseInt(productPrice * 100 + 0.0001) }
          data-theme={ klarna_osm_theme }
        />
    );
};
const renderAddToCartAfter = (args, callback, instance) => {
    const configReducer = getStore().getState().ConfigReducer;
    let { product, productOrVariant } = instance.props;
    if (product.type_id === 'configurable') {
        product = productOrVariant;
    }

    return (
        <>
            { callback.apply(instance, args) }
            { renderKlarnaPlacement(configReducer, product) }
        </>
    );
};
const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const { isKlarnaInit = false } = instance.state;
    const { product = {}, areDetailsLoaded, configurableVariantIndex } = instance.props;
    const { configurableVariantIndex: prevConfigurableVariantIndex } = prevProps;

    if (prevConfigurableVariantIndex !== configurableVariantIndex) {
        instance.setState({
            isKlarnaInit: false
        });
    }

    if (
        (product && Object.values(product).length > 0 && areDetailsLoaded)
        && !isKlarnaInit && window.Klarna && window.Klarna.OnsiteMessaging
    ) {
        window.Klarna.OnsiteMessaging.refresh();
        instance.setState({
            isKlarnaInit: true
        });
    }

    return callback.apply(instance, args);
};

const state = (prop, instance) => ({
    ...prop,
    isKlarnaInit: false
});

export const config = {
    'Component/ProductActions/Component': {
        'member-function': {
            renderPriceWithGlobalSchema: renderAddToCartAfter,
            // renderAddToCartActionBlock : renderAddToCartAfter,
            componentDidUpdate
        },
        'member-property': {
            state
        }
    }
};

export default config;
