import {
    ADD_RICH_DATA,
    REMOVE_RICH_DATA
} from './RichData.action';

export const getInitialState = (name) => ({
    isExist: document.getElementById(name) ? true : false
});

export const setInitialState = (dataType) => {
    const richData = document.getElementById(dataType);
    if (richData) {
        richData.remove();
    }

    return false
};


export const addRichDataReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case ADD_RICH_DATA:
            const { isExist } = action;
            return {
                ...state,
                isExist: isExist
            };
 
        case REMOVE_RICH_DATA:
            return {
                ...state,
                isExist: setInitialState(action.dataType)
            };
        default:
            return state;
    }
};

export default addRichDataReducer;
