export const ADD_RICH_DATA = 'ADD_RICH_DATA';
export const REMOVE_RICH_DATA = 'REMOVE_RICH_DATA';


export const addRichData = (dataType ,isExist) => ({
    type: ADD_RICH_DATA,
    dataType,
    isExist
});


export const removeRichData = (dataType, isExist) => ({
    type: REMOVE_RICH_DATA,
    dataType,
    isExist
});