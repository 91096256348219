import { CAROUSEL_ITEM_GAP } from 'Component/CarouselScroll/CarouselScroll.config';
import { isRtl } from 'Util/CSS/CSS';
import { CarouselScroll as SourceCarouselScroll } from 'SourceComponent/CarouselScroll/CarouselScroll.component';

export class CarouselScroll extends SourceCarouselScroll {

    getCarouselWidth(showedItemCount) {
        const { isProductPopup, isProductGallery } = this.props;
        const margin = CAROUSEL_ITEM_GAP;

        if(this.itemRef.current) {
            const { offsetWidth: cardWidth } = this.itemRef.current;
            if(isProductPopup && cardWidth < 72) {
                return `${ (margin + 72) * showedItemCount - margin }px`;
            }
            if(isProductGallery && cardWidth < 96) {
                return `${ (margin + 96) * showedItemCount - margin }px`;
            }
            return `${ (margin + cardWidth) * showedItemCount - margin }px`;
        }
    }

    getNextTranslate(nextId) {
        if(this.itemRef.current) {
            const { offsetWidth } = this.itemRef.current;
            const multiplier = isRtl() ? nextId : -nextId;

            return `${ multiplier * (offsetWidth + CAROUSEL_ITEM_GAP) }px`;
        }
    }

    renderContent() {
        const { children: { length: childrenLength }, children, isImageZoomPopupActive, showArrow, showedItemCount } = this.props;

        return (
            <div block="CarouselScroll" elem="ContentWrapper" mods={ { isImageZoomPopupActive } } className={ !showArrow || childrenLength <= showedItemCount ? '' : 'active' }>
                <div block="CarouselScroll" elem="Content">
                    { children.map(this.renderContentItem) }
                </div>
            </div>
        );
    }
}

export default CarouselScroll;
