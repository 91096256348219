import CheckoutQuery from 'Query/Checkout.query';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchMutation } from 'Util/Request';

const GUEST_QUOTE_ID = 'guest_quote_id';

class CheckoutPaymentsContainer {
    componentDidMount(args, callback, instance) {
        document.addEventListener(
            'resetSelectedPayment',
            (data) => {
                console.log("asdas")
                instance.selectPaymentMethod({});
            },
            false,
        );

        return callback.apply(instance, args);
    }

    selectPaymentMethod = async (args, callback, instance) => {
        const {
            resetCart,
            setLoading
        } = instance.props;
        const { code } = args[0];
        callback.apply(instance, args);
        if(!code){
            resetCart();
            setLoading(false);
            return;
        }
        if (code.includes('klarna')) {
            return;
        }
        setLoading(true);
        const additional_data = [];
        const guest_cart_id = !isSignedIn() ? BrowserDatabase.getItem(GUEST_QUOTE_ID).token : '';
        await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
            guest_cart_id,
            payment_method: {
                code
            }
        })).then(() => {
            resetCart();
            setLoading(false);
        });
    };
}

const {
    selectPaymentMethod,
    componentDidMount
} = new CheckoutPaymentsContainer();

export const config = {
    'Component/CheckoutPayments/Container': {
        'member-function': {
            selectPaymentMethod,
            componentDidMount
        }
    }
};

export default config;
