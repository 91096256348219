import { PureComponent } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import GiftCardDashBoard from "./GiftCardDashBoard.component";

export const GiftCardDashBoardDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/GiftCardDashBoard/GiftCardDashBoard.dispatcher'
    );


/** @namespace Component/GiftCardDashBoardContainer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
        codes : state.GiftCardDashBoardReducer.giftCardsFromAccount
});


/** @namespace Component/GiftCardDashBoardContainer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addCardInAccount: (giftCardCode, quoteId) => GiftCardDashBoardDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addCardInAccountDispatch(dispatch, giftCardCode)
    ),
    removeGiftCardFromAccount: (giftCardCode) => GiftCardDashBoardDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeGiftCardFromAccountDispatch(dispatch,giftCardCode)
    )
    // getCards: () => GiftCardDashBoardDispatcher.then(
    //     ({ default: dispatcher }) => dispatcher.getCodes(dispatch)
    // )
});

/** @namespace Component/GiftCardDashBoardContainer/Container */
export class GiftCardDashBoardContainer extends PureComponent{
    static propTypes = {
        addCardInAccount: PropTypes.func.isRequired,
        removeGiftCardFromAccount: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleFormSuccess: this.handleFormSuccess.bind(this),
        removeGiftCard: this.handleRemoveGiftCardFromAccount.bind(this),
        handleGiftCodeChange: this.handleGiftCodeChange.bind(this)
    }

    state = { isLoading: false };

    handleGiftCodeChange(e){
        this.setState({
            giftCode : e
        });
    }


    handleRemoveGiftCardFromAccount(code) {
        const { removeGiftCardFromAccount } = this.props;
        this.setState({isLoading: true});
        removeGiftCardFromAccount(code).then(
            /** @namespace Component/GiftCardDashBoard/Container/removeGiftCardFromAccount */
            () => this.setState({isLoading: false})
    );
    }

    handleFormSuccess(fields){
        const { couponCode } = fields;
        const { addCardInAccount }  = this.props;
        this.setState({ isLoading: true });
        addCardInAccount(couponCode).then(
            /** @namespace Component/GiftCardDashBoard/Container/addCardInAccount */
            () => this.setState({ isLoading: false, giftCode : "" })
        );
    }


    render() {
        return (
            <GiftCardDashBoard
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardDashBoardContainer);

