/**
 * validate before place order and scroll
 * @param args
 * @param callback
 * @param instance
 * @returns {*}
 */
const placeOrder = (args, callback, instance) => {
    const { showInfo } = instance.props;
    const telephone = document.getElementById('telephone');
    if (telephone) {
        if (telephone.value.length < telephone.maxLength) {
            telephone.parentElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            showInfo(__('Please fill valid telephone.'));
            return;
        }
    }

    return callback.apply(instance, args);
};

export const config = {
    'Route/Checkout/Component': {
        'member-function': {
            placeOrder
        }
    }
};

export default config;
