const isSelected = (args, callback, instance) => {
    const [{ attribute_code, attribute_value }] = args;
    const { parameters = {} } = instance.props;
    if (attribute_code === 'category_id') {
        const parameter1 = parameters.category_id;
        const parameter2 = parameters.category_ids;

        if (parameter1 === undefined && parameter2 === undefined) {
            return false;
        }
        let valuein1 = false;
        let valuein2 = false;
        if (parameter1?.length !== undefined) {
            valuein1 = parameter1.includes(attribute_value);
        }
        if (parameter2?.length !== undefined) {
            valuein2 = parameter2.includes(attribute_value);
        }

        return valuein1 || valuein2;
    }
    const parameter = parameters[attribute_code];

    if (parameter === undefined) {
        return false;
    }
    if (parameter.length !== undefined) {
        return parameter.includes(attribute_value);
    }

    return parameter === attribute_value;
};

export const config = {
    'Component/ProductConfigurableAttributes/Container': {
        'member-function': {
            isSelected: [
                {
                    position: 1,
                    implementation: isSelected
                }
            ]
        }
    }
};

export default config;
