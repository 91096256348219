import getStore from 'SourceUtil/Store';

export class CartQueryPlugin {
    _getCartTotalsFields = (args, callback, instance) => {
        const { 
            getRewardConfig: {
                is_enable = false
            } = {}
        } = getStore().getState() || {};
    
        if(!is_enable) {
            return [
                ...callback.apply(instance, args)
            ]
        }

        return [
        ...callback.apply(instance, args),
        'amrewards_point'
        ]
    }
}

const { _getCartTotalsFields } = new CartQueryPlugin();

export default  {
    'Query/Cart': {
        'member-function': {
            '_getCartTotalsFields': _getCartTotalsFields
        }
    }
};
