import history from 'SourceUtil/History';

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        isFashion: state.switchWebsiteReducer.isFashion
    }
};

const _getMenuOptions = (args, callback, instance) =>{
    const { header_content: { header_menu } = {} } = window.contentConfiguration;
    const { isFashion } = instance.props;
    const { location: { pathname } } = history;

    if((isFashion && '/' !== pathname) || '/home_fashion' === pathname) {
        return {
            identifier: 'fashion-menu'
        }
    };

    return {
        identifier: header_menu || 'new-main-menu'
    };
}


export default {
    // 'Component/Menu/Container/mapStateToProps': {
    //     function: [
    //         {
    //             position: 101,
    //             implementation: mapStateToProps
    //         }
    //     ]
    // },
    // 'Component/Menu/Container': {
    //     'member-function': {
    //         _getMenuOptions: [
    //             {
    //                 position: 101,
    //                 implementation: _getMenuOptions
    //             }
    //         ]
    //     }
    // }
};