const getIndexedProduct = (args, callback, context) => {
    const  {attributes, type_id} =  args[0];
    if(!attributes?.length && (window.location.pathname.includes('checkout') || window.location.pathname.includes('cart'))){
        const {
            options
        } = args[0];

        const updatedProduct = {
            ...args[0],
            options: options ? options : []
        };
        return updatedProduct;
    }
    return {
        ...callback(...args)
    }
};

export default {
    'Util/Product/getIndexedProduct': {
        'function': getIndexedProduct
    }
};
