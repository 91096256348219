import { getGuestQuoteId } from 'Util/Cart';
import CartQuery from 'Query/Cart.query';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';

class CartDispatcherPlugin {

    async applyCouponToCart(args, callback, instance){
        const [ dispatch, couponCode ] = args;
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return;
            }

            const { applyCoupon: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getApplyCouponMutation(couponCode, guestQuoteId)
            );
            instance._updateCartData(cartData, dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }

    }

}

const { applyCouponToCart } = new CartDispatcherPlugin();

export const config = {
    'Store/Cart/Dispatcher': {
        'member-function': {
            applyCouponToCart: applyCouponToCart
        }
    }
};

export default config;
