export const GET_RETURN_LIST = 'GET_RETURN_LIST';
export const GET_RMA_CONFIG = 'GET_RMA_CONFIG';
export const SET_LOADING = 'SET_LOADING';

export const getRmaConfig = (getAmRmaSettings) => ({
    type: GET_RMA_CONFIG,
    getAmRmaSettings
});

/** @namespace Rma/Store/Return/Action/getReturnList */
export const getReturnList = (data) => ({
    type: GET_RETURN_LIST,
    data
});

/** @namespace Rma/Store/Return/Action/setLoading */
export const setLoading = (is) => ({
    type: SET_LOADING,
    is
});
