import { getApiSearchStatus } from '../utlis/Request';

const requestProductList = (_args, _callback, _instance) => {
    const [ options ] = _args;
    const { requestProductList, search } = _instance.props;
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();

    if(use_in_fulltext && 
        search_is_enabled && 
        location.pathname.includes('search') && 
        search) {
            window.scrollTo({ top: 0 , behavior : 'smooth' });
        return requestProductList({ ...options, isPlp: true, isSearchPage: search.length > 0 });
    }
    
    return _callback(..._args);

}

const requestPage = (_args, _callback, _instance) => {
    const [ currentPage = 1, isNext = false ] = _args;
    const {
        sort,
        search,
        filter,
        pageSize,
        requestProductList,
        requestProductListInfo,
        noAttributes,
        noVariants,
        isWidget,
        isBrandPage
    } = _instance.props;

    /**
     * In case the wrong category was passed down to the product list,
     * prevent it from being requested.
     */
    if (filter.categoryIds === -1) {
        return;
    }

    /**
     * Do not request page if there are no filters
     */
    if (!search && !_instance.isEmptyFilter()) {
        return;
    }

    // TODO: product list requests filters alongside the page
    // TODO: sometimes product list is requested more then once
    // TODO: the product list should not request itself, when coming from PDP

    let options = {
        isNext,
        noAttributes,
        noVariants,
        args: {
            sort,
            filter,
            search,
            pageSize,
            currentPage
        }
    };

    if (isBrandPage) {

        options.args.filter = {
            ...options.args.filter,
            excluded_category_id: '623',
            customFilters: {
                ...options.args.filter.customFilters,
                excluded_category_id: ['623']
            }
        }

    }

    const infoOptions = {
        args: {
            filter,
            search,
        }
    };

    requestProductList(options);

    if (!isWidget) {
        requestProductListInfo(options);
    }
};

const renderNoProducts = (_args, _callback, _instance) => {
    const { pathname } = location;

    if (!pathname.includes('all/f/search')) {
        return _callback(..._args);
    }

    return (
        <div block="ProductList">
            <div
                block="ProductList"
                elem="ProductsMissing"
            >
                <h2>{__('We are sorry!')}</h2>
                <h3>{__('There were no products found matching your request.')}</h3>
            </div>
        </div>
    );
}

const _getIsInfiniteLoaderEnabled = (_args, _callback, _instance) => {
    const { isInfiniteLoaderEnabled, device } = _instance.props;
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();

    if (use_in_fulltext && search_is_enabled && location.pathname.includes('search')) {
        return false;
    }

    // Allow scroll and mobile, otherwise disable infinite loader
    return device.isMobile ? isInfiniteLoaderEnabled : false;
};

export default {
    'Component/CategoryProductList/Container': {
        'member-function': {
            requestProductList
        }
    },
    // 'Route/CategoryPage/Container': {
    //     'member-function': {
    //         containerProps
    //     }
    // },
    'Component/ProductList/Component': {
        'member-function': {
            renderNoProducts,
            // renderSearchResults
        }
    },
    'Component/ProductList/Container': {
        'member-function': {
               requestPage,
               _getIsInfiniteLoaderEnabled
            // makeSearchRequest,
            // containerProps
        }
    }
};