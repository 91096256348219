import { QueryDispatcher } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import { HrefLangsResolverQuery } from '../../query';
import { updateHrefLangs } from './HrefLang.action';
import BrowserDatabase from 'Util/BrowserDatabase';

/**
 * HrefLang Dispatcher
 * @extends QueryDispatcher
 * @namespace ScandiPWA/HrefLang/Store/SocialLogins/Dispatcher
 */
export class HrefLangDispatcher extends QueryDispatcher {

    __construct() {
        super.__construct('HrefLangs');
    }

    updateCustom(dispatch){
        let config = BrowserDatabase.getItem('config');
        if(!config) return null;
        const {storeList = [], storeConfig : { code , locale, href_xdefault_store} } = config;
        let mfAlternateHreflang = {};
        mfAlternateHreflang.items = [];
        let pathWithoutStoreCode = window.location.pathname.replace('/'+code+'/','');
        if(pathWithoutStoreCode.charAt(pathWithoutStoreCode.length - 1) === '/'){
            pathWithoutStoreCode = pathWithoutStoreCode.slice(0, -1);
        }
        let defaultHref = window.location.href;
        storeList.map(store => {
            const { code, id } = store;
            if(id == href_xdefault_store){
                defaultHref = store.base_link_url + pathWithoutStoreCode;
            }
            mfAlternateHreflang.items.push({
                hreflang : store.locale.replace('_','-') , href : store.base_link_url + pathWithoutStoreCode
            })
        });
        mfAlternateHreflang.items.push({
            hreflang : 'x-default' , href : defaultHref
        })
        dispatch(updateHrefLangs(mfAlternateHreflang));
    }
    empty(dispatch){
        const mfAlternateHreflang = { items : [] };
        dispatch(updateHrefLangs(mfAlternateHreflang));
    }

    onSuccess(data, dispatch) {
        const { mfAlternateHreflang } = data;
        dispatch(updateHrefLangs(mfAlternateHreflang));
    }

    onError([{ message }], dispatch) {
        dispatch(showNotification('error', 'Error fetching Post!', message));
    }

    prepareRequest(options) {
        return HrefLangsResolverQuery.getQuery(options);
    }
}

export default new HrefLangDispatcher();
