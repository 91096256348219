export const EVENT_SEARCH = 'eventSearch';
export const SEARCH = 'search';
export const SEARCH_CATEGORY = 'site search';
export const RESULTS_LOADED = 'Results loaded';
export const NO_RESULTS_FOUND = 'No Results Found';
export const MY_WISHLIST = 'my-wishlist';
export const WISHLIST = 'wishlist';
export const GROUPED = 'grouped';
export const HOMEPAGE = 'Homepage';
export const LOGIN = 'Logged in';
export const LOGOUT = 'Logged out';
export const NOT_APPLICABLE = 'N/A';
export const CATEGORY_PRODUCT_LIST = 'CategoryProductList';
export const PRODUCT_LIST_WIDGET = 'ProductListWidget';
export const CATEOGRY_LIMIT = 5;
export const NOT_FOUND = 'notFound';
export const CHECKOUT = 'checkout';
export const ZERO = 0;
export const ONE = 1;
export const ROOT = '/';
export const BLOG_URL = 'BLOG_URL';
export const BLOG_POST_URL = 'BLOG_POST_URL';
export const BLOG_CATEGORY_URL = 'BLOG_CATEGORY_URL';
export const STORE_LIST = 'STORE_LIST';
export const STORE = 'STORE';