import { Field } from 'SourceUtil/Query';

export class UrlRewritiesPlugin {
    /**
     * Get base order info fields
     * @returns [Field]
     * @private
     */
    _getUrlResolverFields(args, callback, instance) {
        
        return [
            ...callback(instance, args),
            _getRedirectInfo()
        ];
    }
    _getRedirectInfo() {
        return new Field('redirect_info')
            .addFieldList(_getRedirectCode())
    }

    _getRedirectCode() {
        return [
            'redirect_from',
            'redirect_code_previous'
        ];
    }
}

export const { _getUrlResolverFields, _getRedirectInfo, _getRedirectCode } = new UrlRewritiesPlugin();

export default {
    'Query/UrlRewrites': {
        'member-function': {
            _getUrlResolverFields,
            _getRedirectInfo,
            _getRedirectCode
        }
    },
};
