import getStore from 'Util/Store';
const renderKlarnaPlacement = (configReducer, totals) => {
    let { klarna_enable, klarna_osm_data_client_id, klarna_osm_cart_placement, klarna_osm_cart_placement_other, locale, klarna_osm_theme, klarna_osm_enable, klarna_osm_data_custom_local, klarna_osm_cart_enable  } = configReducer;
    if(!klarna_enable || !klarna_osm_enable ||  !klarna_osm_data_client_id || !klarna_osm_cart_enable)
        return null;
    if(klarna_osm_cart_placement === 'other'){
        klarna_osm_cart_placement = klarna_osm_cart_placement_other;
    }
    const { grand_total } = totals;

    setTimeout(()=>{
        window.Klarna.OnsiteMessaging.refresh();
    },500);

    return (
        <klarna-placement
            data-key={klarna_osm_cart_placement}
            data-locale={klarna_osm_data_custom_local ? klarna_osm_data_custom_local : locale.replace('_','-')}
            data-purchase-amount={Number.parseInt(grand_total * 100 + 0.0001)}
            data-theme={klarna_osm_theme}
        >
        </klarna-placement>
    );
}
const renderKlarnaOnSiteMessage = (args, callback, instance) => {
    const configReducer = getStore().getState().ConfigReducer;
    const { totals } = instance.props;
    return (
        <>
            { callback.apply(instance,args) }
            { renderKlarnaPlacement(configReducer,totals) }
        </>
    );
};

const componentDidMount = (args, callback, instance) => {
    if(window.Klarna){
        setTimeout(()=>{
            window.Klarna.OnsiteMessaging.refresh()
        },1000)
    }
    return callback.apply(instance,args);
}

export default {
    'Route/CartPage/Component': {
        'member-function': {
            renderKlarnaOnSiteMessage,
            componentDidMount
        }
    }
};
