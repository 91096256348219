
import PropTypes from 'prop-types';
import Field from 'SourceComponent/Field';
import { createRef, PureComponent } from 'react';

import './WishlistPopup.style';
import Loader from 'SourceComponent/Loader';
import ExpandableContent from 'SourceComponent/ExpandableContent';

/** @namespace Component/WishlistPopup/Component */
export class WishlistPopup extends PureComponent {

    renderNewCategooryField() {
        const { onChangeField, onSaveCategory, newCategory, isAccountTab, isProductList } = this.props;
        const isDisable = newCategory ? false : true;
        
        if(isAccountTab || isProductList) {
            return null;
        }


        return (
            <ExpandableContent
                heading={__("Add New Category")}
                mix={{ block: 'WishlistPopup', elem: 'Content' }}
            >
                <div className="WishlistPopupNewCategory-container">
                    <Field
                        name="WishlistPopupNewCategory"
                        type="input"
                        value={newCategory}
                        placeholder="Γράψτε το όνομα της λίστας"
                        onChange={onChangeField}
                        maxLength='12'
                    />
                    <button
                        block="WishlistPopup"
                        elem="Button"
                        disabled={isDisable}
                        mix={{ block: 'Button', mods: { isHollow: false } }}
                        onClick={onSaveCategory}
                    >
                        {__("προσθηκη")}
                    </button>
                </div>
            </ExpandableContent>
        )
    }


    isNull = (inputArray) => {
        if (inputArray.length) {
            var currentElement = inputArray[0];
            for (var i = 1, len = inputArray.length; i < len && currentElement === null; i += 1) {
                currentElement = currentElement || inputArray[i];
            }
            if (currentElement !== null) {
                return false;
            }
        }
        return true;
    }

    renderCategoryField = ({ category_id, category_name, is_default }, i) => {
        const { 
            categoryId, 
            isAccountTab, 
            productsInWishlist = {},
            product: {
                id
            } = {}
        } = this.props;
        
        let items = Object.values(productsInWishlist);
        
        if(category_id !== 0) {
            items = Object.values(productsInWishlist).filter(({ wishlist }) => wishlist?.categories?.includes(category_id));
        }

        const itemCounts = items.length;
        const productId = items ? items?.product_id : '';
        
        if(is_default || (productId && productId == id) || isAccountTab && categoryId === category_id){
            return null;
        }
        
        return (
            <li
                block="WishlistPopup"
                elem="Items"
                mix={{ block: 'Item' }}
                key={i}
            >
                <div>
                    <label id={category_id} >
                        {category_name}
                    </label>
                    <span>{ __(`(${itemCounts} Products )`) }</span>
                </div>
                <div>
                    {this.renderAction(category_id, false)}
                </div>
            </li>
        )
    }

    renderCategories() {
        const { addToWishlist, wishlistCategories } = this.props;
        
        const render = wishlistCategories.map(this.renderCategoryField);

            if (this.isNull(render)) {
                return  __(" No Category Available") 
            }

        return render;
    }

    renderContent() {
        const { productsInWishlist, isAccountTab } = this.props;
        
        return (
            <ul
                block="WishlistPopup"
                elem="Items">

                { isAccountTab ? null  
                 : this.renderCategoryField({
                    category_id: 0,
                    category_name: __('All Products'),
                    items: productsInWishlist
                },0)
            }    
                {this.renderCategories()}
            </ul>
        );
    }

    renderAction(category_id, isRemove){
        const { addToWishlist, isMoveCategory } = this.props;
        const title = isRemove ? __("αφαιρώ") : __("αποθηκευση");

        if(!isMoveCategory && isRemove){
            return (
                <div className="WishlistPopup_remove">
                    <div className="WishlistPopup_remove_content">{ __('Είστε βέβαιοι ότι θέλετε να διαγράψετε το προϊόν από τη λίστα;') }</div>
                    <div className="WishlistPopup_remove_action">
                        <button
                            block="WishlistPopup"
                            elem="Button"
                            mix={{ block: 'Button', mods: { isHollow: false } }}
                            onClick={ () => addToWishlist(category_id) }
                        >
                            { title }
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <button
                    block="WishlistPopup"
                    elem="Button"
                    mix={{ block: 'Button', mods: { isHollow: false } }}
                    onClick={ () => addToWishlist(category_id) }
                >
                    {!isMoveCategory ? title : __("Move to this Category") }
                </button>
            </div>
        )
    }

    


    render() {
        const { wishlistCategories, isInWishlist, categoryId, isLoading, productsInWishlist } = this.props;
        const itemCounts = Object.keys(productsInWishlist);
        
        if(isInWishlist && categoryId || categoryId === 0 && itemCounts && itemCounts.length > 0 && isInWishlist) {
            return this.renderAction( categoryId, true )
        }

        if (wishlistCategories && !wishlistCategories.length || isInWishlist) {
            return <Loader  isLoading= { isLoading } />
        }

        return (
            <div
                block="WishlistPopup"
            >
                <Loader isLoading={isLoading} />
                {this.renderNewCategooryField()}
                {this.renderContent()}
            </div>
        );
    }
}