import React, { PureComponent } from 'react';
import ContentWrapper from 'Component/ContentWrapper';
import SlickSlider from 'Component/SlickSlider';
import ProductCard from 'Component/ProductCard';
import Image from "Component/Image";
import {RELATED,UPSELL} from 'Store/LinkedProducts/LinkedProducts.reducer';
import { ProductLinks as SourceProductLinks } from 'SourceComponent/ProductLinks/ProductLinks.component';

/** @namespace Component/ProductLinks/Component */
export class ProductLinks extends SourceProductLinks {
    renderRelatedProductCard = this.renderRelatedProductCard.bind(this);

    renderProductCard(product, i) {
        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions
            },
            isBlogRelated,
            isProductPage
        } = this.props;
        const { id = i } = product;

        return (
            <ProductCard
              isProductPage= { isProductPage }
              position= { isBlogRelated }
              block="ProductLinks"
              elem="Card"
              product={ product }
              key={ id }
              siblingsHaveBrands={ siblingsHaveBrands }
              siblingsHavePriceBadge={ siblingsHavePriceBadge }
              siblingsHaveTierPrice={ siblingsHaveTierPrice }
              siblingsHaveConfigurableOptions={ siblingsHaveConfigurableOptions }
              setSiblingsHaveBrands={ setSiblingsHaveBrands }
              setSiblingsHavePriceBadge={ setSiblingsHavePriceBadge }
              setSiblingsHaveTierPrice={ setSiblingsHaveTierPrice }
              setSiblingsHaveConfigurableOptions={ setSiblingsHaveConfigurableOptions }
            />
        );
    }


    renderRelatedProductCard(product, i) {
        const {
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            productCardFunctions: {
                setSiblingsHaveBrands,
                setSiblingsHavePriceBadge,
                setSiblingsHaveTierPrice,
                setSiblingsHaveConfigurableOptions
            },
            isProductPage
        } = this.props;
        const { id = i } = product;

        return (
            <ProductCard
                block="ProductLinks"
                elem="Card"
                product={ product }
                key={ id }
                isRelated={true}
                isProductPage= { isProductPage }
                siblingsHaveBrands={ siblingsHaveBrands }
                siblingsHavePriceBadge={ siblingsHavePriceBadge }
                siblingsHaveTierPrice={ siblingsHaveTierPrice }
                siblingsHaveConfigurableOptions={ siblingsHaveConfigurableOptions }
                setSiblingsHaveBrands={ setSiblingsHaveBrands }
                setSiblingsHavePriceBadge={ setSiblingsHavePriceBadge }
                setSiblingsHaveTierPrice={ setSiblingsHaveTierPrice }
                setSiblingsHaveConfigurableOptions={ setSiblingsHaveConfigurableOptions }
            />
        );
    }

    renderRoutineProductCard(product, i) {
        const { id = i } = product;

        return (
            <ProductCard
                block="ProductLinks"
                elem="Card"
                isRelated={true}
                product={ product }
                key={ id }
            />
        );
    }
    renderCurrentRelatedPicture() {
        const { currentProductId, currentProductName, currentProductThumbnailUrl } = this.props;

        this.sharedComponent = (
            <Image
                imageRef={ this.imageRef }
                src={ currentProductThumbnailUrl }
                alt={ currentProductName }
                ratio="custom"
                mix={ { block: 'ProductCard', elem: 'Picture' } }
                isPlaceholder={ !currentProductId }
            />
        );

        return (
            <>
                { this.sharedComponent }
            </>
        );
    }

    renderRelatedItems() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            numberOfProductsToDisplay
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderRelatedProductCard({}, i)
            );
        }

        return items.slice(0, numberOfProductsToDisplay).map(this.renderRelatedProductCard);
    }

    renderItems() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            numberOfProductsToDisplay
        } = this.props;

        if (!items) {
            return Array.from(
                { length: numberOfProductsToDisplay },
                (_, i) => this.renderProductCard({}, i)
            );
        }

        return items.slice(0, numberOfProductsToDisplay).map(this.renderProductCard);
    }

    renderHeading() {
        const { title } = this.props;

        return (
            <h2 block="ProductLinks" elem="Title">
                <span>{title}</span>
            </h2>
        );
    }

    render() {
        const { 
            areDetailsLoaded, 
            isBlogRelated, 
            linkType, 
            selectPdpProductTab, 
            pdpProductTab = -1, 
            title, 
            linkedProducts: { 
                [linkType]: { items = [] } 
            } 
        } = this.props;

        let slideToDisplay = 5;

        if(window.innerWidth > 1023 && window.innerWidth < 1280) {
            slideToDisplay = 3;
        }

        if(window.innerWidth > 1279 && window.innerWidth < 1481) {
            slideToDisplay = 4;
        }

        const setting = {
            slides_to_display: slideToDisplay,
            slides_to_scroll: 1,
            slides_to_display_tablet: 3,
            slider_dots: true,
            show_navigation: true
        };

        if (!areDetailsLoaded || !items.length) {
            return null;
        }

        if(linkType === RELATED && !isBlogRelated){
            return (
                <div block="ProductActions" elem="Related">
                    <ul className="ProductActions-Related_layout_grid">
                        <li className="ProductCard ProductCard_layout_grid ">
                            <div className="ProductCard-Link">
                                <div className="ProductCard-FigureReview">
                                    <figure block="ProductCard" elem="Figure">
                                        { this.renderCurrentRelatedPicture() }
                                    </figure>
                                </div>
                            </div>
                        </li>
                        { this.renderRelatedItems() }
                    </ul>
                </div>
            );
        }

        if(linkType === UPSELL){
            return (
                <>
                    <div 
                     className={ pdpProductTab == -1 ? "PdpProductTabs-Button active" : "PdpProductTabs-Button" } 
                     onClick={ ()=> selectPdpProductTab(-1) }>
                        { title }
                    </div>
                    { pdpProductTab === -1 ?
                        <ContentWrapper
                            mix={ { block: 'ProductLinks' } }
                            wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
                            label={ __('Linked products') }
                        >
                            <SlickSlider
                                { ...setting }
                                slick_slider_class="ProductLinks-Slider"
                            >
                                { this.renderItems() }
                            </SlickSlider>
                        </ContentWrapper>
                        : ""
                    }
                </>
            );
        }

        return (
            <ContentWrapper
              mix={ { block: 'ProductLinks' } }
              wrapperMix={ { block: 'ProductLinks', elem: 'Wrapper' } }
              label={ __('Linked products') }
            >
                { this.renderHeading() }
                <SlickSlider
                    { ...setting }
                    slick_slider_class="ProductLinks-Slider"
                >
                    { this.renderItems() }
                </SlickSlider>
            </ContentWrapper>
        );
    }
}

export default ProductLinks;
