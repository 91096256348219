import { getExtensionAttributes } from 'Util/Product';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import CartQuery from 'Query/Cart.query';

const addProductToCart = async (args, callback, instance) => {
    let dispatch = args[0];
    let options = args[1];
    const {
        product,
        bundler_skus,
        quantity,
        productOptionsData,
        buyRequest
    } = options;

    const {
        sku,
        type_id: product_type
    } = product;

    if (bundler_skus) {
        const {
            productOptions,
            productOptionsMulti,
            downloadableLinks
        } = productOptionsData || {};

        const productToAdd = {
            sku,
            product_type,
            quantity,
            product_option: {
                buy_request: buyRequest,
                extension_attributes: getExtensionAttributes(
                    {
                        ...product,
                        productOptions,
                        productOptionsMulti,
                        downloadableLinks
                    }
                )
            }
        };

        productToAdd.bundler_items = bundler_skus;

        if (instance._canBeAdded(options)) {
            try {
                const isCustomerSignedIn = isSignedIn();
                const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

                if (!isCustomerSignedIn && !guestQuoteId) {
                    return Promise.reject();
                }

                const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                    CartQuery.getSaveCartItemMutation(productToAdd, guestQuoteId)
                );

                return instance._updateCartData(cartData, dispatch);
            } catch (error) {
                dispatch(showNotification('error', getErrorMessage(error)));

                return Promise.reject();
            }
        }

        return Promise.reject();
    } else {
        return callback.apply(instance, args);
    }
}
export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart: addProductToCart
        }
    }
};
