/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */
const _getPageFields = (args, callback, instance) => (
    [
        ...callback(...args),
        'pwa_noindex'
    ]
);

export default {
    'Query/CmsPage': {
        'member-function': { _getPageFields: _getPageFields }
    }
};
