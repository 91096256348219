const addMspAmount = (args, callback) => {
    return [
        ...callback(...args),
        'msp_cod_tax_amount',
        'msp_cod_amount',
        'msp_fee'
    ];
};

export default {
    'Query/Cart': {
        'member-function': {
            _getCartTotalsFields: addMspAmount
        }
    }
};
