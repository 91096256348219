import { BUNDLE } from 'SourceUtil/Product';
import Html from 'SourceComponent/Html';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import ExpandableContent from 'SourceComponent/ExpandableContent';
import ProductBundleItems from '@scandipwa/scandipwa/src/component/ProductBundleItems';
import { createRef } from 'react';
import {
    formatPrice,
    roundPrice
} from 'SourceUtil/Price';

const itemRef = () => createRef();
const descriptionRef = () => createRef();

const getSelectedProductDetails = (item, items) => {
    var bundle = [];
    items.map(( { options }) => {
        const { value } = item;
        const [bundleId] = value;
        const filterBundle = Object.values(options).filter(({ id }) => id == bundleId) || [];

        if (!filterBundle.length > 0) {
            return bundle;
        }

        bundle = filterBundle;
    });

    return { 
        bundle: bundle[0], 
        ...item 
    };
}

const scrollToBundle = (_instance) => { 

    return (
        <button
          onClick={() => window.scrollTo({top : _instance.itemRef.current.offsetTop, 'behavior': 'smooth'})}
          block="Button ProductActions"
          elem= 'BundleAction'
        >
            <span>{ __('Buy Now') }</span>
        </button>
    );
}

 const renderDescription = (_instance) => {
    const { product: { description: { html } = {} }, areDetailsLoaded } = _instance.props;
    
    if(areDetailsLoaded && !html) {
        return null;
    }

    if (!html) {
        return (
            <TextPlaceholder length="long" />
        );
    }

    const cleanDescription = html.replace(/<\/?[^>]+(>|$)/g, '');

    return (
        <div block="ProductActions" elem="Description">
            <meta itemProp="description" content={ cleanDescription } />
            <Html content={ html } />
        </div>
    );
}

const renderBundleItems = (_args, _callback, _instance) => {
    const {
        product: { items, type_id, price_range },
        maxQuantity,
        getSelectedCustomizableOptions,
        productOptionsData,
        setBundlePrice
    } = _instance.props;
    
    if (type_id !== BUNDLE) {
        return null;
    }

    return (
        <ProductBundleItems
            items={ items }
            getSelectedCustomizableOptions={ getSelectedCustomizableOptions }
            maxQuantity={ maxQuantity }
            productOptionsData={ productOptionsData }
            setBundlePrice={ setBundlePrice }
            price_range={ price_range }
            type_id={ type_id }
        />
    );
}

const renderContent = (_instance) => {
    const { areDetailsLoaded } = _instance.props;
    const heading = areDetailsLoaded ? __('Description') : '';

    return (
        <ExpandableContent
          heading={ heading }
          mix={ { block: 'ProductActions', elem: 'Description' } }
          isArrow={true}
        >
            { renderDescription(_instance) }
        </ExpandableContent>
    );
}

const renderSelectedBundleProduct = (_args, _callback, _instance) => { 
    const { 
        product: { 
            items
        } 
    } = _instance.props;

    const [ item ] = _args;
    const { 
        bundle: { 
            label, 
            finalOptionPrice,
            product,
            product : {
                price_range: {
                maximum_price: { 
                    final_price: {
                        value,
                        currency
                    } = {}
                } = {}
            } = {}
            } = {}
        },
        quantity 
    } = getSelectedProductDetails(item, items) || {};

    const price = formatPrice(roundPrice(value * quantity), currency);
    

    return (
        <div block="ProductActions" elem="SelectedBundleProduct">
            <span
                block="ProductActions"
                elem="SelectedBundleProductQty">
                {'x ' + quantity}
            </span>
            <label
                block="ProductActions"
                elem="SelectedBundleProductRight">
                <span
                    block="ProductActions"
                    elem="SelectedBundleProductTitle">
                    <strong>
                        {label}
                    </strong>
                </span>
                <span
                    block="ProductActions"
                    elem="SelectedBundleProductPrice">
                    { price }
                </span>
            </label>
        </div>
    )
}

const renderSelectedBundleProducts = (_args, _callback, _instance) => {
    const { productOptionsData: { productOptions = [] } } = _instance.props;
    
    if(!productOptions.length > 0) {
        return null;
    }

    return(
        <div className="ProductActions-SelectedBundleProducts">
            { productOptions.map(_instance.renderSelectedBundleProduct)}
        </div>
    )
}

const renderBundleProduct = (_args, _callback, _instance) => {
    const { product: { type_id } = {} } = _instance.props;

    if(type_id === BUNDLE) {
        return (
            <>
                <article block="ProductActions">
                    { _instance.renderBrand() }
                    { _instance.renderName() }
                    <div block="ProductActions" elem="ActionsWrapper">
                        { _instance.renderReviewSection() }
                    </div>
                    { _instance.renderShortDescription() }
                    <div block="ProductActions" elem="BundleProductDescriptionButton"
                         onClick={() => window.scrollTo({top : _instance.descriptionRef.current.offsetTop, 'behavior': 'smooth'})}
                    >{ __('Description') }</div>
                    { scrollToBundle(_instance) }
                </article>
                <div ref={ _instance.descriptionRef } block="ProductActions" elem="BundleProductDescription">
                    { renderContent(_instance) }
                </div>
                <div ref={ _instance.itemRef } block="ProductActions" elem="BundleProduct" >
                    <h2 className="ProductLinks-Title">
                        <span>
                            { __("Make your own combination") }
                        </span>
                    </h2>
                    <div className="ProductActions-BundleProductCols">
                        { _instance.renderBundleItems() }
                        <div className="ProductActions-BundleProductAction">
                            <div className="ProductBundleItem-Title">{ __('Total: ') }</div>
                            <div className="ProductActions-BundleProductActionWrapper">
                                { _instance.renderSelectedBundleProducts() }
                                <div className="ProductActions-BundleProductActionBottom">
                                    <div className="ProductActions-BundleProductActionPrice">
                                        { __('Total: ') }
                                        { _instance.renderTierPrices() }
                                        { _instance.renderPriceWithGlobalSchema() }
                                    </div>
                                    { _instance.renderAddToCartActionBlock() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return _callback.apply(_instance, _args);
}

export default {
    'Component/ProductActions/Component': {
        'member-property': { 
            itemRef,
            descriptionRef
        },
        'member-function': {
            render: renderBundleProduct,
            renderBundleItems,
            renderSelectedBundleProducts,
            renderSelectedBundleProduct
        }
    }
}; 