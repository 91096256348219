import { showNotification } from 'SourceStore/Notification/Notification.action';
import { isSignedIn } from 'SourceUtil/Auth';
import { getGuestQuoteId } from 'SourceUtil/Cart';
import { getErrorMessage } from 'SourceUtil/Request';

const updateProduct = (args, callback, instance) => {
    const [ dispatch, options ] = args;
    const { item_id, quantity, product, productOptionsData, buyRequest } = options;
    // const {
    //     type_id: product_type,
    //     variants,
    //     configurableVariantIndex
    // } = product;

    // const {
    //     productOptions,
    //     productOptionsMulti,
    //     downloadableLinks
    // } = productOptionsData || {};
    
    try {
        const isCustomerSignedIn = isSignedIn();
        const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

        if (!isCustomerSignedIn && !guestQuoteId) {
            return Promise.reject();
        }

        instance.removeProductFromCart(dispatch, item_id);
        
        return instance.addProductToCart(dispatch, options);
    } catch (error) {
        dispatch(showNotification('error', getErrorMessage(error)));

        return Promise.reject();
    }
}
export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            updateProduct
        }
    }
}