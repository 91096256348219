import { cloneElement, createRef } from 'react';

import {
    RECAPTCHA_TYPE_INVISIBLE,
    RECAPTCHA_TYPE_RECAPTCHA,
    RECAPTCHA_TYPE_RECAPTCHA_V3
} from '../component/Recaptcha/Recaptcha.config';
import Recaptcha from '../component/Recaptcha/Recaptcha.container';
import { getCaptchaKey, getRecaptchaConfigs } from '../utils/recaptchaConfig';

export class MyAccountCreateAccountPlugin {

    recaptchaRef = createRef();
    renderCreateAccountForm = (args, callback, instance) => {
        const originalDetails = callback.apply(instance, args);
        const { recaptcha_for_customer_create } = getRecaptchaConfigs();
        if (recaptcha_for_customer_create) {
            const { props: { children } } = originalDetails;
            return cloneElement(
                originalDetails,
                {},
                [...children, (<Recaptcha
                  recaptchaType={ recaptcha_for_customer_create }
                  recaptcha_public_key={ getCaptchaKey(recaptcha_for_customer_create) }
                  recaptchaRef={ this.recaptchaRef }
                />)]
            );
        }

        return originalDetails;
    };

    onCreateAccountSuccess = async (args, callback, instance) => {
        const { recaptcha_for_customer_create } = getRecaptchaConfigs();
        switch (recaptcha_for_customer_create) {
        case RECAPTCHA_TYPE_INVISIBLE:
            this.recaptchaRef.current.reset();
            return this.recaptchaRef.current.executeAsync().then(() => callback.apply(instance, args).then(async () => {
                window.recaptchaValidation = '';
            }));
        case RECAPTCHA_TYPE_RECAPTCHA:
            return callback.apply(instance, args).then(() => {
                if (window.grecaptcha) {
                    window.grecaptcha.reset();
                }
                window.recaptchaValidation = '';
            });
        case RECAPTCHA_TYPE_RECAPTCHA_V3:
            return callback.apply(instance, args);
        default:
            return callback.apply(instance, args);
        }
    };
}

const { renderCreateAccountForm, onCreateAccountSuccess } = new MyAccountCreateAccountPlugin();
export const config = {
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            renderCreateAccountForm
        }
    },
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            onCreateAccountSuccess
        }
    }
};

export default config;
