import {
    EVENT_GTM_PROMO_VIEW,
    EVENT_GTM_PROMO_CLICK
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { lazy } from 'react';
import Link from 'SourceComponent/Link';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import getStore from 'SourceUtil/Store';
import { event as Event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

const SliderWidget_componentDidUpdate = (args, callback, instance) => { 
    callback(...args);
    const { dispatch } = getStore();
    const [prevProps, prevState] = args;
    const {
        slider,
        isLoading
    } = instance.props;

    const { activeImage } = instance.state

    const {
        prevActiveImage
    } = prevState;

    const {
        prevSlider
    } = prevProps;

    if (!slider
        || slider === prevSlider 
        || activeImage === prevActiveImage
    ) return;

    const { slides = {}, title: creativeTitle, is_slick_slider } = slider;

    if(isLoading || (!slides[activeImage] || is_slick_slider) || !slides[activeImage]?.slide_id) {
        return;
    }
    
    const { title, slide_id } = slides[activeImage];
    
    const promoObj = {
        'promotions': [
            {
              'id': slide_id,      
              'name': title,
              'creative': creativeTitle,
              'position': activeImage
            }
        ]
    };

    // Dispatch GTM event for all slides in viewport
    const slideElements = document.querySelectorAll('.Beauty.Homepage.Slider [data-promoid]');
    slideElements.forEach(slideElement => {
        const slideId = slideElement.getAttribute('data-promoid');
        if (slideId === slide_id) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        // dispatch(Event(
                        //     EVENT_GTM_PROMO_VIEW,
                        //     promoObj
                        // ));                
                    }
                });
            });
            observer.observe(slideElement);
        }
    });
}

const renderSlider = (args, callback, instance) => { 
    const {
        slider: {
            is_slick_slider,
            slides = [],
            title: creativeTitle,
        }
    } = instance.props;
    const { isfired = false } = instance.state;
    const { dispatch } = getStore();

    if(is_slick_slider && slides.length > 0 && !isfired) {
        const promoObj = [];

        slides.map(({ slide_id, title}, i) => {
            promoObj.push({
                'id': slide_id,      
                'name': title,
                'creative': creativeTitle,
                'position': i
            })
        });
        
        dispatch(Event(
            EVENT_GTM_PROMO_VIEW,
            {
                'promotions': {
                    ...promoObj
                }
            }
        ));

        instance.setState({ isfired: true })
    }

    return callback(...args);
}

const SliderWidget_onClick = (args, callback, instance) => { 
    const {
        slider
    } = instance.props;

    const [ slide, position ] = args;
    
    const { dispatch } = getStore();
    if (!slider) return;

    const { slides, title: creativeTitle, is_slick_slider } = slider;
    const { title, slide_id } = slide;

    const promoObj = {
        'promotions': [
            {
              'id': slide_id,      
              'name': title,
              'creative': creativeTitle,
              'position': position
            }
        ]
    };

    dispatch(Event(
        EVENT_GTM_PROMO_CLICK,
        promoObj
    ));
}

const onClick = (event, callback, instance) => {
    const target = event.target;
    const parent = target.closest('.widget');
    const { dispatch } = getStore();
    if (parent) {
        var imgs = [...parent.getElementsByTagName('img')];
        if (imgs && imgs.length > 1) {
            imgs.forEach(img => {
                const src = img.src;

                if(src.indexOf('http') == -1) {
                    return null;
                }
                
                const url = new URL(src); 
                var pathname = url.pathname.split('/');
                if (pathname.length === 6) {
                    const promoObj = {
                        'promotions': [
                            {
                            'name': pathname[4],
                            'creative': pathname[3],
                            'position': pathname[4]
                            }
                        ]
                    };
                    if (pathname[3] && pathname[4]) {
                        dispatch(Event(
                            EVENT_GTM_PROMO_CLICK,
                            promoObj
                        ));
                    }
                }
            });
        }
    }
}

//attach our function to document event listener on scrolling whole doc
const componentDidUpdate = (args, callback, instance) => {
  const { isfired = false } = instance.state
  callback.apply(instance, args)

  if (!isfired) {
    isInViewport(instance)
  }
}

const isInViewport = instance => {
  const { src, isPlaceholder, imageRef } = instance.props
  let { isfired } = instance.state // Initialize isfired from the instance state
  const { dispatch } = getStore()

  // Check if src is provided, imageRef.current exists, and src is a valid URL
  if (
    src &&
    imageRef &&
    imageRef.current &&
    /^https?:\/\//.test(src) &&
    !isPlaceholder &&
    !isfired // Check if the event has not been fired already
  ) {
    const pathname = new URL(src).pathname.split('/')

    // Check if the pathname length is 6
    if (pathname.length === 6) {
      const [_, creative, name, position] = pathname
      const promoObj = {
        promotions: [
          {
            name,
            creative,
            position
          }
        ]
      }

      const imageElement = document.querySelector(`img[src="${src}"]`)

      if (imageElement) {
        // Create the IntersectionObserver
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // Dispatch the event or perform any action here
              instance.setState({ isfired: true }) // Update isfired to true
              dispatch(Event(EVENT_GTM_PROMO_VIEW, promoObj))
              // Stop observing once it's in viewport if needed
              observer.unobserve(entry.target)
            }
          })
        })

        // Start observing the imageElement
        observer.observe(imageElement)
      }
    }
  }
}


const replaceLinks = (args, callback, instance) => {
    const { attribs, children } = args[0]

    const { href, ...attrs } = attribs;

        if (href) {
            const isAbsoluteUrl = (value) => new RegExp('^(?:[a-z]+:)?//', 'i').test(value);
            const isSpecialLink = (value) => new RegExp('^(sms|tel|mailto):', 'i').test(value);

            if (!isAbsoluteUrl(href) && !isSpecialLink(href)) {
                return (
                    <Link onClick={ onClick } { ...attributesToProps({ ...attrs, to: href }) }>
                        { domToReact(children, instance.parserOptions) }
                    </Link>
                );
            }
        }
    }


/** Image */
const renderPlainImage = (args, callback, instance) => {
    const {
        alt,
        src,
        style,
        title,
        className,
        imageRef
    } = instance.props;

    return (
        <UniversalIcon
          ref={ imageRef }
          className={ className }
          src={ src || '' }
          alt={ alt }
          style={ style }
          title={ title }
          onLoad={ instance.onLoad }
          onError={ instance.onError }
          loading="lazy"
        />
    );
};

export default {
    'Component/Image/Component': {
        'member-function': {
            renderPlainImage,
            componentDidUpdate: [
                {
                    position:115,
                    implementation: componentDidUpdate
                }
            ],
            // componentDidUpdate: [
            //     {
                    // position:115,
                    // implementation: componentDidUpdate
            //     }
            // ]
        }
    },
    'Component/SliderWidget/Component': {
        'member-function': {
            'componentDidUpdate': SliderWidget_componentDidUpdate,
            'onClick': SliderWidget_onClick,
            'render': renderSlider
        }
    },
    'Component/Link/Container': {
        'member-function': {
            //  render: Link_render
        }
    },
    'Component/Html/Component': {
        'member-function': {
            replaceLinks
            //  render: Link_render
        }
    }
};