import { isSignedIn } from 'SourceUtil/Auth';
import WishlistQuery from 'SourceQuery/Wishlist.query';
import { getIndexedProduct } from 'SourceUtil/Product';
import { prepareQuery } from 'SourceUtil/Query';
import { executeGet, getErrorMessage } from 'SourceUtil/Request';
import { FIVE_MINUTES_IN_SECONDS } from 'SourceUtil/Request/QueryDispatcher';
import ProductCard from 'Component/ProductCard';
import SharedWishlistItem from 'Component/SharedWishlistItem';
import './css/WishlistSharedPage.plugin.scss';
import { extendCartDataProducts } from 'Util/Product/PerformaceProduct.js'
import Loader from 'Component/Loader';

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);


const _getIsWishlistEmpty = (_args, _callback, _instance) => {
    const { wishlistItems = {} } = _instance.state;
    return wishlistItems && Object.entries(wishlistItems).length <= 0;
};

const componentDidMount = (_args, _callback, _instance) =>  {
    _instance.requestWishlist();
}

const componentDidUpdate = (_args, _callback, _instance) =>  {
    const [ prevProps ] = _args
    const { match: { params: { code } } } = prevProps;

    if (_instance.getCode() !== code) {
        _instance.requestWishlist();
    }
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        removeFromWishlist: (options) => WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
        )
    }
}

export const renderProduct = (_args, _callback, _instance) => {
    const [id, product] = _args;
    const { wishlistItems, loadingItemsMap } = _instance.props;
    const isRemoving = loadingItemsMap ? loadingItemsMap[id] : wishlistItems[id];

    return (
        <SharedWishlistItem
            isSharedWishlist
            isRemoving={ isRemoving }
            handleSelectIdChange={ _instance.handleSelectIdChange }
            key={id}
            product={product}
        />
    );
}

const requestWishlist = (_args, _callback, _instance) =>  {
    const { showError, showNoMatch, updateBreadcrumbs } = _instance.props;

    const code = _instance.getCode();
    const query = prepareQuery([WishlistQuery.getWishlistQuery(code)]);

    updateBreadcrumbs([]);
    _instance.setLoading();

    executeGet(query, 'SharedWishlist', FIVE_MINUTES_IN_SECONDS).then(
        /** @namespace Route/WishlistSharedPage/Container/requestWishlistExecuteGetThen */
        ({ wishlist, wishlist: { items_count, creators_name: creatorsName } = {} }) => {
            if (!items_count) {
                _instance.setLoading(false);

                return;
            }
            if(wishlist.items?.length > 0){
                wishlist.items = extendCartDataProducts(wishlist.items, true) || {};
            }

            const wishlistItems = wishlist.items.reduce((prev, wishlistItem) => {
                const {
                    id,
                    sku,
                    product,
                    description,
                    qty: quantity
                } = wishlistItem;

                const indexedProduct = getIndexedProduct(product);

                return {
                    ...prev,
                    [id]: {
                        quantity,
                        wishlist: {
                            id,
                            sku,
                            quantity,
                            description
                        },
                        ...indexedProduct
                    }
                };
            }, {});

            updateBreadcrumbs([
                { name: creatorsName, url: `/wishlist/shared/${code}` },
                { name: __('Shared Wishlist'), url: '/' }
            ]);

            _instance.setState({
                creatorsName,
                wishlistItems,
                isLoading: false,
                isWishlistLoading: false
            });
        },
        /** @namespace Route/WishlistSharedPage/Container/executeGetCatch */
        (error) => {
            _instance.requestGuestCode()
        }
    );
}

const requestGuestCode = (_args, _callback, _instance) => {
    const { showError, showNoMatch, updateBreadcrumbs } = _instance.props;

    const code = _instance.getCode();
    const query = prepareQuery([WishlistQuery.getWishlistGuestQuery(code)]);

    updateBreadcrumbs([]);
    _instance.setLoading();

    executeGet(query, 'SharedWishlist', FIVE_MINUTES_IN_SECONDS).then(
        ({ wishlist, wishlist: { items_count, creators_name: creatorsName } = {} }) => {
            if (!items_count) {
                _instance.setLoading(false);

                return;
            }

            const wishlistItems = wishlist.items.reduce((prev, wishlistItem) => {
                const {
                    id,
                    sku,
                    product,
                    description,
                    qty: quantity
                } = wishlistItem;

                const indexedProduct = getIndexedProduct(product);

                return {
                    ...prev,
                    [id]: {
                        quantity,
                        wishlist: {
                            id,
                            sku,
                            quantity,
                            description
                        },
                        ...indexedProduct
                    }
                };
            }, {});

            updateBreadcrumbs([
                { name: creatorsName, url: `/wishlist/shared/${code}` },
                { name: __('Shared Wishlist'), url: '/' }
            ]);

            _instance.setState({
                creatorsName,
                wishlistItems,
                isLoading: false,
                isWishlistLoading: false
            });
        },
        (error) => {
            showError(getErrorMessage(error));
            showNoMatch();
        }
    );
}

const render = (_args, _callback, _instance) => {
    const { product, product: { configurable_options = {} }, parameters = [], isLoading } = _instance.props;
    var selectedFilters = {};
    if(parameters.length) {
     const parameterKey = Object.keys(configurable_options);
     const { attribute_options = {} } = configurable_options[parameterKey] || {};
     const attributeValue = Object.values(attribute_options).find((attributes) => attributes.label == parameters[0]) || {};
     selectedFilters = {
        [parameterKey]: attributeValue?.value
        };
    }

    return (
        <>
        <Loader isLoading={ isLoading} />
        <ProductCard
          renderContent={ _instance.renderContent }
          product={ product }
          selectedFilters={ selectedFilters }
          mix={ { block: 'WishlistItem', elem: 'ProductCard' } }
          isLoading={ isLoading }
          hideWishlistButton
          hideCompareButton
        />
        </>
    );
}

const containerProps = (_args, _callback, _instance) => {
    const { sharedWishlist = false } = _instance.props;
    
    return {
        ..._callback(..._args),
        sharedWishlist
    };
}

export default {
    'Component/SharedWishlistItem/Container/sharedWishlistItemContainer': {
        'member-function': { 
            containerProps
        }
    },
    'Component/SharedWishlistItem/Component': {
        'member-function': { 
            render
        }
    },
    'Route/WishlistSharedPage/Container/wishlistSharedContainer': {
        'member-function': { 
            renderProduct
        }
    },
    'Component/SharedWishlistItem/Container/mapDispatchToProps': {
        function: [
            {
                position: 120,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Route/WishlistSharedPage/Container/wishlistSharedContainer': {
        'member-function': { 
            _getIsWishlistEmpty,
            componentDidMount,
            componentDidUpdate,
            requestWishlist,
            requestGuestCode
        }
    }
};