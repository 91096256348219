import {
    UPDATE_CMS_BLOCKS,
    UPDATE_MENU
} from './CmsBlocksAndMenu.action';
import MenuHelper from 'Util/Menu';

export const getInitialState = () => ({
    blocks: {
        items : {}
    },
    menu: {}
});

export const CmsBlocksAndMenuReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        
    case UPDATE_CMS_BLOCKS:
        const { blocks: { items: blockItems } } = action;
        const items = blockItems.reduce((o, item) => {
            if(item){
                const { disabled, identifier } = item;
                return disabled ? o : { ...o, [identifier]: item };
            }
            return  o;
        }, {});

        if (state.blocks.items) {
            return {
                ...state,
                blocks: { items: { 
                    ...state.blocks.items, 
                    ...items 
                } }
            };
        }

        return {
            ...state,
            blocks: {
                items
            }
        };

    case UPDATE_MENU:
        const { menu } = action;

        return {
            ...state,
            menu: MenuHelper.reduce(menu)
        };

    default:
        return state;
    }
};

export default CmsBlocksAndMenuReducer;
