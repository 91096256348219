export const WebsiteSwitch = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
    '../store/WebSwitch/WebSwitch.dispatcher'
);
import history from 'SourceUtil/History';

export class HeaderContainerPlugin {

    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            isFashion: state.switchWebsiteReducer.isFashion
        }
    };

    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            switchWebsite: (options) => WebsiteSwitch.then(
                ({ default: dispatcher }) => dispatcher.switchWebsite(options, dispatch)
                )
        };
    };


    componentDidMount = (args, callback, instance) => {
        const { isFashion, switchWebsite } = instance.props;
        const { location: { pathname } } = history;
        const url = '/';
        const isFashionPathnam = '/home_fashion' === pathname ? true : false
        
        if((isFashion && url === pathname) || isFashionPathnam) {
            switchWebsite(isFashionPathnam)
        }

        return {
            ...callback.apply(instance, args),
        }
    }

    componentDidUpdate = (args, callback, instance) => {
        const [ prevProps ] = args;
        const { isFashion: prevIsFashion } = prevProps;
        const { isFashion, switchWebsite } = instance.props;
        
        if(prevIsFashion !== isFashion) {
            instance.getMenuAndCmsBlock();
        }

        return {
            ...callback.apply(instance, args),
        }
    }


    containerProps = (args, callback, instance) => {
        const { isFashion, switchWebsite } = instance.props;

        return {
            ...callback.apply(instance, args),
            isFashion,
            switchWebsite
        };
    }

}

export const { componentDidUpdate, componentDidMount, mapDispatchToProps, mapStateToProps, containerProps } = new HeaderContainerPlugin();

export default {
    'Component/Header/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/Header/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/Header/Container': {
        'member-function': {
            componentDidMount: [
                {
                    position: 101,
                    implementation: componentDidMount
                }
            ],
            componentDidUpdate: [
                {
                    position: 101,
                    implementation: componentDidUpdate
                }
            ],
            containerProps: containerProps
        }
    }
};