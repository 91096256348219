import getStore from 'Util/Store';
export const GiftCardDashBoardDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/GiftCardDashBoard/GiftCardDashBoard.dispatcher'
    );


const signInAfter = (args, callback, instance)  => {
    return callback.apply(instance, args).then(
        ()=>{
            GiftCardDashBoardDispatcher.then(
                ({ default: dispatcher }) => dispatcher.getCodes(getStore().dispatch),
            )
        }
    );
}

export default {
    'Store/MyAccount/Dispatcher' : {
        'member-function': {
            'signIn': signInAfter
        }
    }
}
