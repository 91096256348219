import getStore from 'Util/Store';

import { HrefLangDispatcher } from '../store/HrefLangs';

export const HREF_LANG_TYPE_MAP = {
    BLOG_URL: 'blog_index',
    BLOG_POST_URL: 'blog_post',
    BLOG_CATEGORY_URL: 'blog_category',
    CATEGORY: 'category',
    PRODUCT: 'product',
    CMS_PAGE: 'cms'
};

export class StoreUrlRewritesDispatcher {
    thisPromisePromiseThen = (args, callback, instance) => {
        const { dispatch } = getStore();
        if (args[0].urlResolver) {
            const { urlResolver: { type, id, SelectedFilters } } = args[0];
            if (type === 'CATEGORY' && id) {
                HrefLangDispatcher.handleData(dispatch, { type: HREF_LANG_TYPE_MAP[type], id, SelectedFilters });
            } else if (HREF_LANG_TYPE_MAP[type] && id) {
                HrefLangDispatcher.handleData(dispatch, { type: HREF_LANG_TYPE_MAP[type], id });
            } else if (type === 'BLOG_URL') {
                HrefLangDispatcher.handleData(dispatch, { type: HREF_LANG_TYPE_MAP[type], id: 'null' });
            } else {
                HrefLangDispatcher.updateCustom(dispatch);
            }
        }

        return callback.apply(instance, args);
    };
}

const { thisPromisePromiseThen } = new StoreUrlRewritesDispatcher();

export const config = {
    'Util/Request/QueryDispatcher/handleData/thisPromisePromiseThen': {
        function: thisPromisePromiseThen
    }
};

export default config;
