import { GIFTPRODUCT } from "./ProductAction.component.plugin";
class AddToCartContainerPlugin {
    addToCartHandlerMap = (prop, instance) => {
        return {
            ...prop,
            [GIFTPRODUCT]: this.addGiftProductToCart.bind(instance)
        }
    }

    validationMap = (prop, instance) => {
        return {
            ...prop,
            [GIFTPRODUCT]: this.validateGiftProduct.bind(instance)
        }
    }

    validateGiftProduct(){
        const  {
            am_display_option_fields,
            giftOptions : {
                am_giftcard_amount_custom,
                am_giftcard_amount,
                am_giftcard_image,
                am_giftcard_sender_name,
                am_giftcard_recipient_name,
                am_giftcard_recipient_email,
                is_date_delivery,
                am_giftcard_date_delivery,
                am_giftcard_date_delivery_timezone,
            },
            showNotification
        } = this.props;
        if(!am_giftcard_amount_custom && !am_giftcard_amount){
            showNotification('info', __('Please specify gift card amount!'));
            return false
        }
        if(!am_giftcard_image){
            showNotification('info', __('Please select gift card image'));
            return false
        }
        if(!am_giftcard_sender_name && am_display_option_fields.includes('am_giftcard_sender_name')){
            showNotification('info', __('Please specify your name'));
            return false
        }
        if(!am_giftcard_recipient_name && am_display_option_fields.includes('am_giftcard_recipient_name')){
            showNotification('info', __('Please specify recipient name'));
            return false
        }
        if(am_display_option_fields.includes('am_giftcard_sender_name') && /[!@#$%^&*(),.?":{}|<>\d]/.test(am_giftcard_sender_name)){
           showNotification('info', __('Digits and special characters are not accepted in name fields!'));
           return false
        }
        if(am_display_option_fields.includes('am_giftcard_recipient_name') && /[!@#$%^&*(),.?":{}|<>\d]/.test(am_giftcard_recipient_name)){
           showNotification('info', __('Digits and special characters are not accepted in name fields!'));
           return false
        }
        if(!am_giftcard_recipient_email){
            showNotification('info', __('Please specify recipient email'));
            return false
        }
        if(is_date_delivery && is_date_delivery === "1" && am_display_option_fields.includes('am_giftcard_date_delivery')){
            if(!am_giftcard_date_delivery){
                showNotification('info', __('Please delivery date'));
                return false
            }
            if(!am_giftcard_date_delivery_timezone){
                showNotification('info', __('Please specify timezone'));
                return false
            }
        }
        return true;
    }

    addGiftProductToCart(){
        const {
            product,
            quantity,
            addProduct,
            giftOptions,
            productOptionsData
        } = this.props;

        addProduct({
            product,
            quantity,
            productOptionsData,
            giftOptions
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }
}

const { addToCartHandlerMap, validationMap } = new AddToCartContainerPlugin();

export default {
    'Component/AddToCart/Container': {
        'member-property': {
            'addToCartHandlerMap': addToCartHandlerMap,
            'validationMap': validationMap,
        }
    }
};
