import React from "react";

function renderContent(args, callback, instance) {

    const {
        children: { length: childrenLength },
        children,
        isImageZoomPopupActive,
        showArrow,
        showedItemCount,
        isGiftCard
    } = instance.props;

    return (
        <div
            block={isGiftCard ? "CarouselScrollGift" : "CarouselScroll"}
            elem="ContentWrapper"
            mods={{ isImageZoomPopupActive }}
            className={!showArrow || childrenLength <= showedItemCount ? '' : 'active'}
        >
            <div block={isGiftCard ? "CarouselScrollGift" : "CarouselScroll"} elem="Content">
                {children.map(instance.renderContentItem)}
            </div>
        </div>
    );
}

function render(args, callback, instance) {
    const { isGiftCard = false } = instance.props
    return (
        <div block={isGiftCard ? "CarouselScrollGift" : "CarouselScroll"} ref={instance.carouselRef}>
            {instance.renderArrow()}
            {instance.renderContent()}
            {instance.renderArrow(true)}
        </div>
    );
}

export const config = {
    "Component/CarouselScroll/Component": {
        "member-function": {
            renderContent,
            render,
        },
    }
};

export default config;