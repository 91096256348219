import React, { lazy } from "react";
import media from "Util/Media";
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
));

import Image from 'Component/Image';
import {
    IMAGE_TYPE, PLACEHOLDER_TYPE, THUMBNAIL_KEY, VIDEO_TYPE, GIFT_TYPE
} from 'Component/ProductGallery/ProductGallery.config';
export const GIFTPRODUCTMEDIA = '/amasty/amgcard/image/admin_upload/';


const renderMediaWithGift = (args, callback, instance) => {
    const { media } = instance.props;

    const { media_type } = media;

    switch (media_type) {
        case VIDEO_TYPE:
            return instance.renderVideo();
        case IMAGE_TYPE:
            return instance.renderImage();
        case GIFT_TYPE:
            return renderGiftImage(args, callback, instance);
        case PLACEHOLDER_TYPE:
            return instance.renderPlaceholder();
        default:
            return null;
    }
}

const renderGiftImage = (args, callback, instance) => {
    const {
        media: {
            label: alt,
            file,
            thumbnail: { url: thumbnailUrl } = {},
            id
        },
        productName
    } = instance.props;

    if (id === THUMBNAIL_KEY) {
        return renderPlaceholder();
    }

    const src = thumbnailUrl || media(file, GIFTPRODUCTMEDIA);

    if (!src) {
        callback.apply(args)
    }
    return (
        <Image
            src={src}
            alt={productName}
            ratio="custom"
            mix={{ block: 'ProductGalleryThumbnailImage' }}
        />
    );
}

export const config = {
    "Component/ProductGalleryThumbnailImage/Component": {
        "member-function": {
            renderGiftImage,
            'renderMedia': renderMediaWithGift,
        },
    }
};

export default config;