/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */
const addActionUrlField = (args, callback, instance) => {
    let mutation = callback(...args);
    mutation.addField('action_url')
    return mutation;
};

export default {
    'Query/Checkout': {
        'member-function': { getPlaceOrderMutation: addActionUrlField }
    }
};
