/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
// import { BrowserDatabase as SourceBrowserDatabase } from 'Util/BrowserDatabase'

// TODO: maybe consider moving to IndexedDB insead of localStorage

/**
 * Set of helpers related to Browser Database
 * @class CSS
 * @namespace Util/BrowserDatabase
 */
export class BrowserDatabase {
    /**
     * Loads data from browser storage
     * @param {String} location Name of the local storage
     * @return {Object} Object stored in a specified path
     * @memberof BrowserDatabase
     */
    getItem(location) {
        try {
            const entryObject = JSON.parse(localStorage.getItem(location));
            const { data, expiration, createdAt } = entryObject;
            const MILLISECONDS_TO_SECONDS = 1000;

            // Setup specific for authorization token so that there is no issue with other items set up to local storage
            if (location === 'auth_token') {
                if (expiration && ((createdAt + expiration) < (Date.now() / MILLISECONDS_TO_SECONDS))) {
                    localStorage.removeItem(location);

                    return null;
                }

                return data;
            }

            if (expiration && Date.now() - createdAt > expiration * MILLISECONDS_TO_SECONDS) {
                localStorage.removeItem(location);

                return null;
            }

            return data;
        } catch {
            return null;
        }
    }

    /**
         * Loads expiration from browser storage
         * @param {String} location Name of the local storage
         * @return {Number} Expiration of item stored in a specified path
         * @memberof BrowserDatabase
         */
    getItemExpiration(location) {
        try {
            const entryObject = JSON.parse(localStorage.getItem(location));
            const { data, expiration, createdAt } = entryObject;
            const MILLISECONDS_TO_SECONDS = 1000;

            if (expiration && ((createdAt + expiration) < (Date.now() / MILLISECONDS_TO_SECONDS))) {
                localStorage.removeItem(location);

                return null;
            }

            return expiration;
        } catch {
            return null;
        }
    }

    /**
         * Loads creation from browser storage
         * @param {String} location Name of the local storage
         * @return {Number} Creation of item stored in a specified path
         * @memberof BrowserDatabase
         */
    getItemCreation(location) {
        try {
            const entryObject = JSON.parse(localStorage.getItem(location));
            const { data, expiration, createdAt } = entryObject;
            const MILLISECONDS_TO_SECONDS = 1000;

            if (expiration && ((createdAt + expiration) < (Date.now() / MILLISECONDS_TO_SECONDS))) {
                localStorage.removeItem(location);

                return null;
            }

            return createdAt;
        } catch {
            return null;
        }
    }

    /**
     * Save data to local storage
     * @param {Any} data The value to save to local storage
     * @param {String} location Name of the local storage
     * @param {Number} expiration Time to store entry (in seconds)
     * @param {Number} createdAt Time of creation
     * @return {Void}
     * @memberof BrowserDatabase
     */
    setItem(data, location, expiration, createdAt = Date.now()) {
        localStorage.setItem(location, JSON.stringify({
            data,
            expiration,
            createdAt
        }));
    }

    /**
     * Delete item from local storage
     * @param {String} location
     * @memberof BrowserDatabase
     */
    deleteItem(location) {
        localStorage.removeItem(location);
    }
}

export default new BrowserDatabase();
