/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {createRef, lazy, PureComponent} from 'react';

import { NotificationType } from 'Type/NotificationList';
import CSS from 'Util/CSS';

import {
    ANIMATION_DURATION, ERROR_NOTIFICATION_LIFETIME, ERROR_TYPE, NOTIFICATION_LIFETIME
} from './Notification.config';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";

import { Notification as SourceNotification } from 'SourceComponent/Notification/Notification.component';
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

/**
 * Notification block
 * @class Notification
 * @namespace Component/Notification/Component
 */
export class Notification extends SourceNotification {
    renderIcon() {
        const { notification } = this.props;
        const { msgType } = notification;

        const mods = {
            type: msgType.toLowerCase()
        };

        if(msgType === 'success') {
            return (
                <UniversalIcon
                    src={media('tick-circle-light.svg', WYSIWYG_MEDIA)}
                    alt="closeIcon"
                    className="CloseIcon"
                />
            );
        }

        if(msgType === 'error') {
            return (
                <UniversalIcon
                    src={media('warning-circle-light.svg', WYSIWYG_MEDIA)}
                    alt="closeIcon"
                    className="CloseIcon"
                />
            );
        }

        return (
            <UniversalIcon
                src={media('warning-circle-light.svg', WYSIWYG_MEDIA)}
                alt="closeIcon"
                className="CloseIcon"
            />
        );
    }

    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification } id={ id }>
                <button block="Notification" elem="Button" onClick={ this.hideNotification }>Close</button>
                { this.renderIcon() }
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default Notification;
