import CmsBlock from "SourceComponent/CmsBlock";

export const renderConfiguredBlock = (args, callback, instance) => {
    const [identifier] = args;
    return (
        <div block="ProductActions" elem="cms_block" key={identifier}>
            <CmsBlock identifier={identifier} />
        </div>
    )

}

export const renderChanceFinder = (args, callback, instance) => {
    const {
        areDetailsLoaded,
        urlRewrite
    } = instance.props;
    if (areDetailsLoaded && urlRewrite && urlRewrite.banners && urlRewrite.banners.banner && urlRewrite.banners.banner.length > 0) {
        const {
            banners: {
                banner
            }
        } = urlRewrite;
        return banner.map(identifier => instance.renderConfiguredBlock(identifier))
    }
    return null;
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderChanceFinder,
            renderConfiguredBlock
        },
    }
}