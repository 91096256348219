const getEndpoint = (version) => {
    let endpoint = 'eu';
    switch (version) {
        case "dach_v3":
        case "uk":
        case "nl":
        case "kp_eu":
            endpoint = 'eu';
            break;
        case "na":
        case "kp_na":
            endpoint = 'na';
            break;
        case "kp_oc":
            endpoint = 'oc';
            break;
        default:
    }
    return endpoint;
}
const componentDidUpdate = (args, callback, instance) => {
    const { klarna_api_test, klarna_enable,  klarna_api_api_version, klarna_osm_data_client_id, klarna_osm_enable } = instance.props;
    const { klarnaMounted }  = instance.state;
    if(!klarnaMounted){
        if(klarna_enable && klarna_osm_enable){
            const script = document.createElement('script');
            script.setAttribute("data-client-id", klarna_osm_data_client_id);
            let endPoint = getEndpoint(klarna_api_api_version);
            script.src = `https://${endPoint}-library.klarnaservices.com/lib.js`;
            if (klarna_api_test) {
                script.src = `https://${endPoint}-library.playground.klarnaservices.com/lib.js`;
            }
            document.head.insertBefore(script, document.head.childNodes[20]);
        }
        if(klarna_enable !== undefined)
            instance.setState({ klarnaMounted : true})
    }
    return callback.apply(instance,args);
}

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback(...args),
        klarna_api_test: state.ConfigReducer.klarna_api_test,
        klarna_enable: state.ConfigReducer.klarna_enable,
        klarna_api_api_version: state.ConfigReducer.klarna_api_api_version,
        klarna_osm_data_client_id: state.ConfigReducer.klarna_osm_data_client_id,
        klarna_osm_enable: state.ConfigReducer.klarna_osm_enable
    };
};
const state = (prop, instance) => {
    return {
        ...prop,
        klarnaMounted : false
    }
}
const containerProps = (args, callback, instance) => {
    const { klarna_api_test, klarna_enable,  klarna_api_api_version, klarna_osm_data_client_id, klarna_osm_enable } = instance.props;
    return {
        ...callback.apply(instance,args),
        klarna_api_test,
        klarna_enable,
        klarna_api_api_version,
        klarna_osm_data_client_id,
        klarna_osm_enable
    }
}
export const config = {
    'Component/Router/Component': {
        'member-function': {
            componentDidUpdate
        },
        'member-property': {
            state
        }
    },
    'Component/Router/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/Router/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
