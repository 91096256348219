const sDk = '../../util/sdk';

class modifaceScripts {
    static initailizeModifaceScript = (category) => {
            const path = category !== 'haircolor' ? '' : '/sdk_hair';
            const addMdifaceToDOM = document.createElement('script');
            addMdifaceToDOM.id = 'sdk';
            addMdifaceToDOM.setAttribute('src', `${window.location.origin}${path}/sdk.js`)
            document.body.appendChild(addMdifaceToDOM);
    }

    static removeScriptFromDom() {
        const sdkScript = document.getElementById('sdk');
        if(sdkScript) {
            sdkScript.remove();
        }
    }
}

export default modifaceScripts;
