import  { CheckoutSuccess as SourceCheckoutSuccess }  from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import CheckoutSuccessCreateAccount from 'Component/CheckoutSuccessCreateAccount'
import Link from 'Component/Link';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import { isSignedIn } from 'Util/Auth';
import React, {lazy} from "react";
import BrowserDatabase from 'Util/BrowserDatabase';

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

export class CheckoutSuccess extends SourceCheckoutSuccess {

    __construct(props) { 
        this.state = {
            
        }
    }

    renderButtons() {
        return (
            <div block="CheckoutSuccess" elem="ButtonWrapper">
                <Link
                    block="Button"
                    mix={ { block: 'CheckoutSuccess', elem: 'ContinueButton' } }
                    to="/"
                    className={'Button_isHollow'}
                >
                    { __('Return to home') }
                </Link>
            </div>
        );
    }

    renderCreateAccountButton() {
        const {
            isEmailAvailable,
            email,
            firstName,
            lastName
        } = this.props;

        const bankOrder = BrowserDatabase.getItem('completed_order');

        if (!isEmailAvailable || isSignedIn() || !bankOrder?.isEmailAvailable) {
            return null;
        }

        return (
            <div className={'CheckoutSuccess_Column'}>
                <div className={'CheckoutSuccess_Subtitle'}>{ __('Save your details for next time') }</div>
                <CheckoutSuccessCreateAccount
                    { ...this.props }
                />
            </div>
        );
    }

    render() {
        const { orderID, isMobile, email: originalEmail } = this.props;
        const bankOrder = BrowserDatabase.getItem('completed_order');

        const email = originalEmail ? originalEmail : bankOrder?.email || '';
        if(isMobile) {
            return (
                <div block="CheckoutSuccess">
                    <div className={'CheckoutSuccess_Columns'}>
                        <div className={'CheckoutSuccess_Column'}>
                            <div className={'CheckoutSuccess_image'}>
                                <UniversalIcon
                                    src={media('checkoutSuccess.png', WYSIWYG_MEDIA)}
                                    alt="CheckoutSuccess"
                                />
                            </div>
                            <div className={'CheckoutSuccess_Subtitle'}>{ __('Order number: %s', orderID) }</div>
                            <p>{ __('You will receive a relevant email with the details of your order at %s', email) }</p>
                        </div>
                        { this.renderCreateAccountButton() }
                        <div className={'CheckoutSuccess_Column'}>
                            <p className={'CheckoutSuccess_text'}>{ __('Return to the home page to continue your shopping.') }</p>
                            { this.renderButtons() }
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div block="CheckoutSuccess">
                <p className={'CheckoutSuccess_text'}>{ __('You will receive a relevant email with the details of your order at %s', email) }</p>
                <div className={'CheckoutSuccess_Columns'}>
                    <div className={'CheckoutSuccess_Column'}>
                        <div className={'CheckoutSuccess_image'}>
                            <UniversalIcon
                                src={media('checkoutSuccess.png', WYSIWYG_MEDIA)}
                                alt="CheckoutSuccess"
                            />
                        </div>
                        <div className={'CheckoutSuccess_Subtitle'}>{ __('Order number: %s', orderID) }</div>
                        <p>{ __('Return to the home page to continue your shopping.') }</p>
                        { this.renderButtons() }
                    </div>
                    { this.renderCreateAccountButton() }
                </div>
            </div>
        );
    }
}

export default CheckoutSuccess;
