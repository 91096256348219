/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// eslint-disable-next-line import/prefer-default-export
export const PRODUCT_INFORMATION = 'information';
export const PRODUCT_ATTRIBUTE_COMPONENTS = 'components';
export const PRODUCT_ATTRIBUTE_COMPOSITION = 'composition';
export const PRODUCT_ATTRIBUTE_INSTRUCTIONS = 'instructions';
export const PRODUCT_LABEL_BLACK = ['offer', 'web exclusive', 'hc exclusive', 'hc loves', 'great gift idea', 'smart', 'smart buy'];
export const PRODUCT_ATTRIBUTES_EXCLUDE = ['barcode', 'description', 'components', 'instructions', 'att_123', 'att_124', 'att_125', 'att_105', 'sorting_date', 'hex_code', 'main_barcode', 'composition', 'comments', 'fashion_size', 'brand', 'beauty_color', 'beauty_size', 'size', 'mg_custom_lists'];
export const PRODUCT_DISCOUNT_EXCLUDE = ['HERMÈS', 'HERMES', 'CHRISTIAN DIOR', 'DIOR', 'DIOR BACKSTAGE', 'GUCCI', 'BVLGARI', 'TOM FORD', 'LA PRAIRIE'];
export const PRODUCT_ATTRIBUTES = 'attributes';
export const PRODUCT_REVIEWS = 'reviews';
export const PRODUCT_SHIPPING_RETURN = 'shipping_return';
export const BRAND_DIOR = 'DIOR';
export const BRAND_CHRISTIAN_DIOR = 'CHRISTIAN DIOR';
export const BRAND_CHANEL = 'CHANEL';
export const SAMPLE_CATEGORY = '622';
export const BAG_CATEGORY = '628';
export const CARDS_CATEGORY = '629';
export const LUXURY_ITEM = '31736';
export const BRAND_MAC = 'M·A·C';
export const BRAND_CLINIQUE = 'CLINIQUE';
export const BRAND_ESTEE_LAUDER = 'ESTÉE LAUDER';
