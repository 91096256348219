/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrandReducer from '../store/Brand/Brand.reducer';
import BrandListReducer from '../store/BrandList/BrandList.reducer';

export class StoreIndexPlugin {
    /**
     * Plugin to add brand list reducers to getStaticReducers method.
     * @param args
     */
    aroundGetStaticReducers = (args, callback) => ({
        ...callback(...args),
        BrandReducer,
        BrandListReducer
    });
}

const { aroundGetStaticReducers } = new StoreIndexPlugin();

export const config = {
    'Store/Index/getReducers': {
        function: aroundGetStaticReducers
    }
};

export default config;
