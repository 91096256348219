export const PRODUCT_BEFORE_RELATED = 'product_before_related';
export const PRODUCT_AFTER_RELATED= 'product_after_related';
export const PRODUCT_INTO_RELATED = 'product_into_related';
export const PRODUCT_BEFORE_UPSELL= 'product_before_upsell';
export const PRODUCT_AFTER_UPSELL= 'product_after_upsell';
export const PRODUCT_INTO_UPSELL= 'product_into_upsell';
export const PRODUCT_CONTENT_TOP= 'product_content_top';
export const PRODUCT_CONTENT_TAB= 'product_content_tab';
export const PRODUCT_BEFORE_TAB= 'product_before_tab';
export const PRODUCT_CONTENT_BOTTOM= 'product_content_bottom';
export const PRODUCT_SIDEBAR_TOP= 'product_sidebar_top';
export const PRODUCT_SIDEBAR_BOTTOM= 'product_sidebar_bottom';
export const CART_CONTENT_TOP= 'cart_content_top';
export const CART_BEFORE_CROSSSEL= 'cart_before_crosssel';
export const CART_AFTER_CROSSSEL= 'cart_after_crosssel';
export const CART_INTO_CROSSSEL= 'cart_into_crosssel';
export const CART_CONTENT_BOTTOM= 'cart_content_bottom';
export const CATEGORY_CONTENT_TOP= 'category_content_top';
export const CATEGORY_CONTENT_BOTTOM= 'category_content_bottom';
export const CATEGORY_SIDEBAR_TOP= 'category_sidebar_top';
export const CATEGORY_SIDEBAR_BOTTOM= 'category_sidebar_bottom';
export const CUSTOM= 'custom'
export const CROSS_SELL = 'crosssell';