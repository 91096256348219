import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { RMA_ROUTE } from '../../route/GuestOrderReturn/GuestOrderReturn.config'
export const withStoreRegex = (path) => window.storeRegexText.concat(path);
import { ReturnDispatcher } from '../../store/Return';

export const GuestOrderReturn = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "rma-guest-order" */ 
            '../../route/GuestOrderReturn')
    );

const mapDispatchToProps =  (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        getAmRmaConfig: () => ReturnDispatcher.getRmaConfiguration(dispatch)
    };
};

const initializeApplication = (args, callback, instance) => {
        const { getAmRmaConfig } = instance.props;
        getAmRmaConfig();

    return callback.apply(instance, args);
}

export class RouterPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex(`/${RMA_ROUTE}`) } render= {  (props) => <GuestOrderReturn { ...props }  /> } />,
            position: 92
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    "Component/Router/Component": {
        "member-property": {
            SWITCH_ITEMS_TYPE,
        },
    },
    // "Component/Router/Container": {
    //     "member-function": {
    //             initializeApplication: initializeApplication,
    //         }
    // },
    // "Component/Router/Container/mapDispatchToProps": {
    //     function: [
    //         {
    //             position: 100,
    //             implementation: mapDispatchToProps,
    //         },
    //     ],
    // },
};

export default config;
