import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { roundPrice, formatCurrency } from 'Util/Price'

export class ProductPrice extends SourceProductPrice {
    renderPriceBadge(label) {
        if (!label) {
            return null;
        }

        return <span mix={ { block: 'ProductPrice', elem: 'PriceBadge' } } className={label.replace(/\s+/g, '')}>{ label }</span>;
    }

    renderOldPrice() {
        const {
            discountPercentage,
            roundedRegularPrice,
            isSchemaRequired,
            variantsCount,
            price_tiers,
            label,
            isReducedPrice
        } = this.props;

        if (isReducedPrice || discountPercentage === 0 || roundedRegularPrice === 0 || price_tiers.length) {
            return null;
        }

        return (
            <del
                block="ProductPrice"
                elem="HighPrice"
                aria-label={ __('Old product price') }
                // itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { roundedRegularPrice }
            </del>
        );
    }

    renderDiscountPercentage() {
        const {
            discountPercentage,
            formattedDiscountAmount,
            isValueLabel,
            roundedRegularPrice,
            price_tiers,
            label,
            isReducedPrice
        } = this.props;

        if (isReducedPrice || discountPercentage === 0 || roundedRegularPrice === 0 || price_tiers.length) {
            return null;
        }

        if(isValueLabel){
            return (
                <span
                    block="ProductPrice"
                    elem="DiscountPercentage"
                    aria-label={ __('Discount Percentage') }
                >
                { __('Benefit %s', formattedDiscountAmount) }
            </span>
            );
        }

        return (
            <span
                block="ProductPrice"
                elem="DiscountPercentage"
                aria-label={ __('Discount Percentage') }
            >
                { __('-%s %', Math.round(discountPercentage)) }
            </span>
        );
    }

    renderCurrentPrice() {
        const {
            discountPercentage,
            formattedFinalPrice
        } = this.props;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (formattedFinalPrice == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                <span
                    // itemProp={ itemProp }
                    content={ content }
                    block="ProductPrice"
                    elem="PriceValue"
                >
                    { formattedFinalPrice }
                </span>
            </PriceSemanticElementName>
        );
    }

    renderUnitCalcValue(value = "") {
        return (
            <span
                content={value}
                block="ProductPrice"
                elem="UnitValue"
            >
                <span className="value">
                    {value}
                </span>
            </span>
        )
    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price
                } = {}
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix,
            showDiscount,
            label,
            isFoucsiaPrice,
            isReducedPrice,
            unitLabel,
            unitCalcModifier,
            priceCurrency,
            minimalPriceValue: finalPrice
        } = this.props;

        let unitPrice = finalPrice * unitCalcModifier;
        let unitCalcValue = `${roundPrice(unitPrice)} ${formatCurrency(priceCurrency)} / ${unitLabel}`;
        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0 } }
              mix={ mix }
              aria-label={ `Product price: ${formattedFinalPrice}` }
              className={ `${isReducedPrice && discountPercentage !== 0 ? 'ProductPrice_offerDiscount' : ''} ${isFoucsiaPrice ? 'ProductPrice_foucsiaPrice' : ''}`}
            >
                {this.renderPriceBadge(label)}
                {this.renderOldPrice()}
                {this.renderCurrentPrice()}
                {this.renderSubPrice()}
                {this.renderSchema()}
                {showDiscount ? this.renderDiscountPercentage() : null}
                {(unitCalcModifier && unitCalcModifier > 0) ? this.renderUnitCalcValue(unitCalcValue) : null}
            </p>
        );
    }
}

export default ProductPrice;
