
import { fetchMutation, fetchQuery } from 'SourceUtil/Request';
import CartDispatcher from "SourceStore/Cart/Cart.dispatcher";
import {updateRewardPointsData, loadCustomerRewardsData, loadCustomerRewardsExpirations} from "./RewardPoints.action";
import RewardPointsQuery  from '../../query/RewardPoints.query';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import BrowserDatabase from "SourceUtil/BrowserDatabase/BrowserDatabase";
export const REWARD_POINTS_DATA = 'reward_points_data';
export const CUSTOMER_REWARD_POINTS_DATA = 'customer_rewards';
export const CUSTOMER_REWARD_EXPIRATIONS_DATA = 'customer_rewards_expirations';

/** @namespace Store/RewardPointsDispatcher */
export class RewardPointsDispatcher{

    applyRewardToCart(dispatch, rewards) {
            return fetchMutation(RewardPointsQuery.getGiftCardApplyMutation(
                rewards
            )).then(
                /** @namespace Store/RewardPoints/Dispatcher/applyGiftCardToCartFetchMutationThen */
                ( { useRewardPoints } ) => {
                CartDispatcher.updateInitialCartData(dispatch);
                dispatch(showNotification('success', useRewardPoints));
                },
                /** @namespace Store/RewardPoints/Dispatcher/applyGiftCardToCartFetchMutationError */
                (error) => dispatch(showNotification('error', error[0].message ))
      )
    }

    fetchRewardPointsData(dispatch){
        return fetchQuery(RewardPointsQuery.getRewardPointsData()).then(
            ({ rewards }) => {
                BrowserDatabase.setItem(rewards,REWARD_POINTS_DATA);
                dispatch(updateRewardPointsData(rewards));
            },
            (error) => {
                console.log(error);
                showNotification('error', error[0].message );
            }
        );
    }

    fetchCustomerRewards(dispatch){
        return fetchQuery(RewardPointsQuery.getCustomerRewards()).then(
            ({ customerRewards }) => {
                BrowserDatabase.setItem(customerRewards, CUSTOMER_REWARD_POINTS_DATA);
                dispatch(loadCustomerRewardsData(customerRewards));
            },
            (error) => {
                console.log(error);
                showNotification('error', error[0].message );
            }
        )
    }

    fetchLoadCustomerRewardsExpirations(dispatch){
        return fetchQuery(RewardPointsQuery.getExpirations()).then(
            ({ customerRewardsExpirations }) => {
                BrowserDatabase.setItem(customerRewardsExpirations, CUSTOMER_REWARD_EXPIRATIONS_DATA);
                dispatch(loadCustomerRewardsExpirations(customerRewardsExpirations));
            },
            (error) => {
                BrowserDatabase.setItem([],CUSTOMER_REWARD_EXPIRATIONS_DATA);
                console.log(error);
                showNotification('error',error[0].message );
            }
        )
    }

}

export default new RewardPointsDispatcher()
