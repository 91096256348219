import getStore from 'SourceUtil/Store';

export const getSkuList = (itemsSkus) => Object.values(itemsSkus).reduce(
    ( skus, { sku }  ) => {
        if(Object.keys(skus).length > 0) {
            return [...skus, sku]
        }
        return [sku]
},{})


export const getItemIdArray = (items, forSample = false) => {
    return Object.values(items).reduce((index, { product, item_id }) => {
        const { id: pId, categories, type_id } = product;
        const isSampleProduct = categories.filter(({ name }) => name == 'ΔΕΙΓΜΑΤΑ');
        const id = forSample ? item_id : pId;

        if (type_id == "amgiftcard" || index.includes(id) ||
            (!forSample && isSampleProduct.length == 1) ||
            (forSample && isSampleProduct.length !== 1)
        ) {
            return index;
        }
        return [...index, id];
    }, []);
}


export const getSampleQtyLimit = (props) => {
    const { totals: { subtotal_incl_tax, discount_amount }, sampleProductRule } = props;
    const grandTotal = discount_amount + subtotal_incl_tax;

    var rules = [];
    var count = 0;

    if(sampleProductRule?.indexOf(',') !== -1) {
        rules = sampleProductRule?.split(",");

    rules?.forEach(rule => {
        const newRule = rule?.split(":");
        if(grandTotal > newRule[0]) {
            count = newRule[1];
        }
       });
    }

    return count
}

export const isSampleProduct = (totals, prevTotals) => {
    const { items = [] } = totals;
    const { items: prevItems = [] } = prevTotals;
    const { ConfigReducer: { excluded_category = '' } } = getStore().getState();
    
    if(prevItems !== items && items?.length > 0 && prevItems?.length != 0) {
        const { product: { categories = [] } = {}} = items[items?.length - 1];
        const isSampleProduct = categories.filter(({ id, name } )=> id == excluded_category || name == 'ΔΕΙΓΜΑΤΑ');
        return isSampleProduct.length > 0;
    }

    return false
}

export const getSampleProductCount = (sampleProduct) => {
    var count = 0;
    Object.values(sampleProduct).filter(
        (item) => item !== null ? count = count + item?.props?.qty : null);

    return count;
}