import { EVENT_GTM_VIEW_CART } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
import { appendWithStoreCode } from 'SourceUtil/Url';
import { CHECKOUT_URL } from 'SourceRoute/Checkout/Checkout.config';
import { isSignedIn } from 'SourceUtil/Auth';


const beforeOnCheckoutButtonClick = (args, callback, instance) => {
    const {
        history,
        guest_checkout
    } = instance.props;

    if (guest_checkout || !isSignedIn()) {
        history.push({
            pathname: appendWithStoreCode(`${CHECKOUT_URL}/login`)
        });
        window.scrollTo({ top: 0 });

        return;
    }

    callback.apply(instance, args);
}

const componentDidUpdate = (args, callback, instance) => {
    const { event, totals } = instance.props;
    const { fired = false } = instance.state;

    if(totals?.items.length > 0 && !fired) {
        instance.setState({ fired: true });
        event(EVENT_GTM_VIEW_CART, { totals });
    }

    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};


export default {
    'Route/CartPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Route/CartPage/Container': {
        'member-function': {
            componentDidUpdate,
            onCheckoutButtonClick: beforeOnCheckoutButtonClick
        }
    }
};
