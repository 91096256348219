import { CheckoutAddressBookContainer as SourceCheckoutAddressBookContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.container';
import { connect } from 'react-redux';

export class CheckoutAddressBookContainer extends SourceCheckoutAddressBookContainer {
    containerProps() {
        const {
            onSaveShippingAddressCheckboxSelect,
            onSaveBillingAddressCheckboxSelect,
            saveBillingAddress,
            saveShippingAddress
        } = this.props;

        return {
            ...super.containerProps(),
            onSaveShippingAddressCheckboxSelect,
            onSaveBillingAddressCheckboxSelect,
            saveBillingAddress,
            saveShippingAddress
        };
    }

    estimateShipping(addressId) {
        const {
            onShippingEstimationFieldsChange,
            customer: { addresses = [] }
        } = this.props;

        const address = addresses.find(({ id }) => id === addressId);

        if (!address) {
            return;
        }

        const {
            city,
            country_id,
            postcode,
            region: {
                region_id,
                region
            } = {}
        } = address;

        if (!country_id) {
            return;
        }

        onShippingEstimationFieldsChange({
            city,
            country_id,
            region_id,
            region,
            postcode
        });
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressBookContainer);
