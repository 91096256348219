/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const POSTS_DETAILS = 'posts-details';
export const POSTS_TYPE_ONE = 1;
export const POSTS_TYPE_TWO = 2;
export const POSTS_TYPE_SPECIAL_OFFER = 3;
