/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import './PayPal.style';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import React from 'react';
import Loader from 'Component/Loader';
import { TotalsType } from 'Type/MiniCart';

/**
 * *Note*
 * This component currently can be rendered only once
 * Please try to have no more than 1 component per page and use isDisabled to hide it.
 * @namespace Scandipwa/PayPalGraphQl/Component/PayPal/Component
 */
export class PayPal extends PureComponent {

    /**
     *
     * @param props
     * @param state
     * @returns {{shippingFields: *}}
     */
    static getDerivedStateFromProps(props, state) {
        /**
         * Rerender paypal button on every shipping field change
         */
        return {
            // added spread oprator so react component should not consider updated
            // state as same because of same variable pointer reference
            shippingFields : { ...props.shippingFields }
        }
    }

    static propTypes = {
        isDisabled: PropTypes.bool,
        paypal: PropTypes.any.isRequired,
        clientId: PropTypes.string.isRequired,
        cartTotals: TotalsType.isRequired,
        onError: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onApprove: PropTypes.func.isRequired,
        createOrder: PropTypes.func.isRequired,
        environment: PropTypes.oneOf([
            'production',
            'sandbox'
        ]).isRequired
    };

    static defaultProps = {
        isDisabled: false
    };

    onApprove(data, actions) {
        const { onApprove, paypal_payment_action } = this.props;
        if(onApprove(data) && paypal_payment_action === "Sale"){
//            return actions.order.capture();
        }
    }

    renderButtons() {
        const {
            paypal,
            onError,
            onCancel,
            createOrder,
            environment,
            isTermsAndConditionsAccepted,
            termsAreEnabled,
            showNotification,
            cartTotals : { is_virtual }
        } = this.props;

        if(!isTermsAndConditionsAccepted && termsAreEnabled){
            return (
                <div className="paypal_terms_message">
                    {__("Please accept the terms and conditions")}
                </div>
            );
        }

        if (!paypal) {
            return <Loader isLoading />;
        }

        const PayPalButton = paypal && paypal.Buttons.driver('react', { React, ReactDOM });

        const processFurther = () => {
            let billingFormData = Object.fromEntries(new FormData(document.getElementById('BILLING_STEP')).entries());
            let isValid = true;
            const skipEntries = ['street1','areaCode'];
            Object.entries(billingFormData).map(([key,value])=>{
                if(!skipEntries.includes(key) && !value)
                    isValid = false;
                else if (key === 'telephone')
                    isValid = value.length > 7;
            });
            if(!isValid)
                showNotification('info', __('Please add complete billing details!'));
            return isValid;
        }

        const scrollToTop = () => {
            let validationError = document.querySelector('#SHIPPING_STEP .Field-Message');
            if(validationError){
                validationError.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
            const telephone = document.getElementById('telephone');
            if (telephone) {
                if (telephone.value.length < telephone.maxLength) {
                    telephone.parentElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                    showNotification('info', __('Please fill valid telephone.'));
                }
            }
            return false;
        }

        let onClick = window.shippingFormSubmitted || is_virtual ? processFurther : scrollToTop;

        const telephone = document.getElementById('telephone');
        if (telephone) {
            if (telephone.value.length < telephone.maxLength) {
                onClick = scrollToTop;
            }
        }

        return (
            <div className={ window.shippingFormSubmitted || is_virtual ? '' : ''}>
                <PayPalButton
                    key={1}
                    disabled={true}
                    env={ environment }
                    onError={ onError }
                    onCancel={ onCancel }
                    onClick={onClick}
                    onApprove={(data, actions) => this.onApprove(data, actions)}
                    createOrder={ createOrder }
                    style={ {
                        layout: 'horizontal',
                        label: 'pay'
                    } }
                />
            </div>
        );
    }

    render() {
        const { isDisabled } = this.props;

        return (
            <div block="Checkout-StickyButtonWrapper" className="PayPalCheckoutShipping-Button">
                <div block="PayPal" mods={ { isDisabled } }>
                    { this.renderButtons() }
                </div>
            </div>
        );
    }
}

export default PayPal;
