import { RMA_ROUTE } from "../route/GuestOrderReturn/GuestOrderReturn.config";

/** @namespace @mageguide/rma/Plugin/Component/Header/Plugin/stateMap */
export class HeaderComponentPlugin {
    aroundStateMap = (originalMember) => ({
        ...originalMember,
        [RMA_ROUTE]: {
            back: true,
            logo: true,
        },
    });
}

const { aroundStateMap } = new HeaderComponentPlugin();

export const config = {
    "Component/Header/Component": {
        "member-property": {
            stateMap: aroundStateMap,
        },
    },
};

export default config;
