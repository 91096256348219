import { Field } from 'Util/Query';

class CartQueryPlugin {
    getCartForCustomer(args, callback, instance){
        const guestCartId = args[0];
        const query = new Field('getCartForCustomer')
            .addFieldList(instance._getCartFields());
        instance._addGuestCartId(guestCartId, query);

        return query;
    }

    _getCartFields(args, callback, instance){
        return [
            instance._getExtensionAttributesField(),
            instance._getBillingField(),
            instance._getShippingField()
        ];
    }

    _getExtensionAttributesField(args, callback, instance){
        return new Field('extension_attributes').addFieldList(instance._getCartForCustomerFields());
    }

    _getBillingField(args, callback, instance){
        return new Field('billing_address').addFieldList(instance._getAddressFields());
    }

    _getShippingField(args, callback, instance){
        return new Field('shipping_address').addFieldList(instance._getAddressFields());
    }

    _getAddressFields(args, callback, instance){
        return [
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'company',
            instance._getRegionField(),
            instance._getCountryField(),
        ];
    }

    _getRegionField(args, callback, instance) {
        return new Field('region')
            .addFieldList(instance._getRegionFields());
    }

    _getCountryField(args, callback, instance) {
        return new Field('country')
            .addFieldList(['code','label']);
    }

    _getRegionFields(args, callback, instance){
        return ['code','label','region_id'];
    }

    _getCartForCustomerFields(args, callback, instance){
        return [
            'attribute_code',
            'label',
            'value'
        ];
    }

    _getCartMessages(){
        return ['type', 'message'];
    }

    validatedVatId(args, callback, instance){
        const [vatId] = args;

        return new Field('getActivities')
            .addArgument('vat_id', 'String', vatId)
            .addFieldList([
                instance.getActivities(),
                'company',
                'vat_office_code',
                'tax_office'
            ])
    }

    getActivities(args, callback, instance){
        return new Field('activities')
            .addFieldList(instance.getActivitiesFields())
    }

    getActivitiesFields(){
        return [
            'label',
            'value'
        ]
    }
}

const { _getCountryField, _getCartFields, _getExtensionAttributesField, _getBillingField, _getShippingField, _getAddressFields, _getRegionField, _getRegionFields, getCartForCustomer,getActivities,  _getCartForCustomerFields, getActivitiesFields, _getCartMessages, validatedVatId } = new CartQueryPlugin();

export const config = {
    'Query/Checkout': {
        'member-function': {
            getActivities,
            getCartForCustomer,
            getActivitiesFields,
            _getCartForCustomerFields,
            validatedVatId,
            _getCartMessages,
            _getCartFields,
            _getExtensionAttributesField,
            _getBillingField,
            _getShippingField,
            _getAddressFields,
            _getRegionField,
            _getRegionFields,
            _getCountryField
        }
    }
};

export default config;
