import React, { lazy } from "react";
import media from "Util/Media/Media";
import CarouselScroll from 'Component/CarouselScroll';
import SlickSlider from 'Component/SlickSlider';
import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GIFT_TYPE } from "Component/ProductGallery/ProductGallery.config";
export const GIFTPRODUCTMEDIA = '/amasty/amgcard/image/admin_upload/';
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
));
export const ProductGalleryThumbnailImage = lazy(() => import(
    'Component/ProductGalleryThumbnailImage'
));
export const Slider = lazy(() => import(
    'Component/Slider'
));
export const Image = lazy(() => import(
    'Component/Image'
));

function render(args, callback, instance) {
    const { am_giftcard_images, giftCardProps, activeGiftCard, onActiveGiftCardChange, isProductPopup, isMobile } = instance.props;
    if (!giftCardProps) {
        return callback.apply(instance, args);
    }
    const setting = {
        slides_to_scroll: 1,
        slides_to_display_tablet: 3,
        slider_dots: false,
        show_navigation: true
    };
    return (
        <>
            {instance.renderGiftCardTitle()}
            <div block="ProductGallery" className="amgiftcard" ref={instance.galleryRef}>
                {instance.renderSlider()}
                {instance.renderGiftCardDescription()}
                <div className="Field ProductActions-GiftOptions_template">
                    <label htmlFor="am_giftcard_images" className="Field-Label">{__('Choose card design')}</label>
                    <div
                        className="ProductActions-GiftOptions_template_Slider slick-initialized"
                    >
                        <CarouselScroll
                            activeItemId={activeGiftCard}
                            onChange={onActiveGiftCardChange}
                            showedItemCount={isMobile ? 2 : 3}
                            isImageZoomPopupActive={false}
                            isProductGallery={isProductPopup ? false : true}
                            isProductPopup={isProductPopup}
                            isGiftCard
                        >
                            {am_giftcard_images ? am_giftcard_images.map((item) => renderCardDesign(item, callback, instance)) : ''}
                        </CarouselScroll>
                    </div>
                </div>
            </div>
        </>
    );
}

function renderGiftCardDescription(args, callback, instance) {
    const { product: { short_description: { html } = {} } } = instance.props.giftCardProps;

    if (!html) {
        return (
            <TextPlaceholder length="long" />
        );
    }

    return (
        <div className="ProductActions-GiftOptions_short_description">
            <div className="ProductActions-GiftOptions_short_description_content">
                <Html content={html} />
            </div>
        </div>
    );
}
function renderSlider(args, callback, instance) {
    const {
        isImageZoomPopupActive,
        am_giftcard_images,
        giftCardProps,
        isMobile,
        activeGiftCard,
        onActiveGiftCardChange,
        isZoomEnabled,
        handleSliderClick,
        sliderRef,
    } = instance.props;

    const mods = {
        isImageZoomPopupActive,
        isZoomInCursor: !isImageZoomPopupActive
    };

    const isMoreThanOnePhoto = (am_giftcard_images && am_giftcard_images.length > 1) || 0;


    if (!giftCardProps) {
        return callback.apply(instance, args);
    }

    const { giftOptions: { am_giftcard_image = [] } } = giftCardProps;

    if (am_giftcard_image && am_giftcard_images && am_giftcard_images[am_giftcard_images.length - 1]) {
        return (
            <Slider
                sliderRef={sliderRef}
                mix={{ block: 'ProductActions', elem: 'GiftOptions_template_Slider', mods }}
                showCrumbs={false}
                showArrows={!isMobile && isMoreThanOnePhoto}
                activeImage={activeGiftCard}
                onActiveImageChange={onActiveGiftCardChange}
                isInteractionDisabled={isZoomEnabled}
                onClick={handleSliderClick}
                sliderHeight={isImageZoomPopupActive ? '100%' : 0}
                isHeightTransitionDisabledOnMount
            >
                {am_giftcard_images.map((giftOption) => {
                    // console.log(giftOption)
                    let src = media(giftOption.image_path, GIFTPRODUCTMEDIA);
                    let style = isImageZoomPopupActive ? { height: 'auto' } : {};
                    return (
                        <Image
                            key={giftOption.image_id}
                            src={src}
                            ratio="custom"
                            alt={giftOption.title}
                            mix={{
                                block: 'ProductGallery',
                                elem: 'SliderImage',
                                mods: { isPlaceholder: !src }
                            }}
                            isPlaceholder={!src}
                            style={style}
                        />
                    )
                })
                }
            </Slider>
        )
    }
    return <></>
}

function renderCardDesign(args, callback, instance) {
    const {
        image_id,
        title,
        image_path,
    } = args;
    // const { activeGiftCard } = instance.props;
    return (
        <ProductGalleryThumbnailImage
            key={image_id}
            media={
                {
                    label: "",
                    file: image_path,
                    id: image_id,
                    media_type: GIFT_TYPE
                }
            }
            productName={title}
        />
    )
}

function renderGiftCardTitle(args, callback, instance) {
    const { giftCardProps, isMobile } = instance.props;

    if (giftCardProps && isMobile) {
        const { product: { name } } = giftCardProps;

        return (
            <h1 block="ProductActions" elem="Title" itemProp="name">
                <TextPlaceholder content={name} length="medium" />
            </h1>
        );
    }

    return null;
}

export const config = {
    "Component/ProductGallery/Component": {
        "member-function": {
            renderSlider,
            render,
            renderGiftCardDescription,
            renderCardDesign,
            renderGiftCardTitle
        },
    }
};

export default config;