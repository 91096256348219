import { 
    EVENT_KEY_PROMO_CLICK, 
    EVENT_KEY_PROMO_VIEW 
} from "../component/GoogleTagManager/GoogleTagManager.events";

export const isEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));

export const validateEvent = (promoObj, event) => {
    var valid = false;
    const dataLayer = window.dataLayer;
    if (dataLayer) {
      Object.values(dataLayer).filter(({ ecommerce }) => {

        if (ecommerce && ecommerce.promoView) {
          const eventType = ecommerce[event];
            valid = isEqual(eventType, promoObj);
        }
      });
    }
    return valid;
  }


export const getPromoEvent = (event, promoObj) => {
    const validation = validateEvent(promoObj, event);
    var eventTile = 'promoClick';

    if(event !== EVENT_KEY_PROMO_CLICK) {
      eventTile = 'promoView';
    }

    if((event === EVENT_KEY_PROMO_CLICK || event === EVENT_KEY_PROMO_VIEW) && !validation) {
       // Chunk data to small parts
       // eslint-disable-next-line fp/no-loops, fp/no-let
       return {
           ecommerce: {
               [eventTile]: {
                   ...promoObj
               }
           }
       };
    }
}