import React from 'react';
import PropTypes from 'prop-types';
import './CircleProgressBar.styles'

export class CircleProgressBar extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        value: PropTypes.number.isRequired,
        radius: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    };

    static defaultProps = {
        radius: 150,
        value: 0
    }

    PartialCircle( radius, value = 1) {
        const pct = (1 - value) * Math.PI * 100;

        return (
            <g>
                <circle 
                    block="CircleProgressBar" elem="Background" r="50" cx="50" cy="50" fill="transparent" />
                <circle block="CircleProgressBar" elem="Bar" r="50" cx="50" cy="50" fill="transparent" strokeDasharray={Math.PI * 100} strokeDashoffset={pct} />
            </g>
        );
    }

    ProgressCircle() {
        const { radius, value, name } = this.props;
        const percentage = value/ 100;
        return (
            <div block="CircleProgressBar">
                <div block="CircleProgress">
                        <span block="CircleProgress" elem="Percentage">{percentage}</span>
                        <svg width={radius * 2} height={radius * 2} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            {this.PartialCircle(radius, percentage)}
                        </svg>
                        <span  block="CircleProgress" elem="Text">{name}</span>
                </div>
            </div>
        );
    }

    render() {
        return this.ProgressCircle();
    }
}

export default CircleProgressBar;