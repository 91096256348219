import { convertQueryStringToKeyValuePairs } from 'SourceUtil/Url';
export const TikTokLoginDispatcher = import(
        /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
        '../store/TikTokLogin/TikTok.dispatcher'
);

export class HeaderContainerPlugin {    

 mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        loginWithTikTok: (options) => TikTokLoginDispatcher.then(
            ({ default: dispatcher }) => dispatcher.prepareTikTokLogin(options, dispatch))
    };
};


validateTikTokLogin = (instance) => {
    const { loginWithTikTok } = instance.props;
        const search = window.location.search;
        const data = convertQueryStringToKeyValuePairs(search);
        if(data && data.code && data.state && data.scopes) { 
            loginWithTikTok(data);
        }
};
     
 componentDidMount =  (args, callback, instance) => {
        callback.apply(instance, args);

        this.validateTikTokLogin(instance);
    }

}

export const { componentDidMount, mapDispatchToProps } = new HeaderContainerPlugin();

export default {
    'Component/Header/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/Header/Container': {
        'member-function': {
            componentDidMount: [
                {
                    position:100,
                    implementation: componentDidMount
                }
            ]
        } 
    }
};
