import { Field } from 'SourceUtil/Query';

const getProductAlertGuestSubscribeMutation = (args, instance) => {
    const [ productId, type, email ] = args;
    return new Field('productAlertGuestSubscribe')
        .addArgument('productId', 'ID!', productId)
        .addArgument('type', 'String!', type)
        .addArgument('email', 'String!', email);
}

export default {
    'Query/ProductAlerts': {
        'member-function': {
            getProductAlertGuestSubscribeMutation
        }
    }
};