import Field from 'Component/Field/Field.container';

import { CheckoutDeliveryOption as SourceCheckoutDeliveryOption } from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';

/** @namespace Component/CheckoutDeliveryOption/Component */
export class CheckoutDeliveryOption extends SourceCheckoutDeliveryOption {
    render() {
        const {
            option: {
                carrier_title,
                available
            } = {},
            onOptionClick,
            isSelected
        } = this.props;

        let CheckoutDeliveryOptionsHeading =  document.getElementById('CheckoutDeliveryOptions-heading');

        let style = {};
        if(CheckoutDeliveryOptionsHeading){
            style = {
                '--checkout-delivery-options-width': CheckoutDeliveryOptionsHeading.clientWidth+'px'
            };
        }

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutDeliveryOption" mods={ { isDisabled: !available } } style={ style }>
                <button
                  block="CheckoutDeliveryOption"
                  mods={ { isDisabled: !available } }
                  elem="Button"
                  type="button"
                  onClick={ onOptionClick }
                  disabled={ !available }
                  className={ isSelected ? 'CheckoutDeliveryOption-Button_isSelected' : '' }
                >
                    <Field
                      type="checkbox"
                      id={ `option-${ carrier_title }` }
                      name={ `option-${ carrier_title }` }
                      checked={ isSelected }
                      disabled
                    />
                    { this.renderRow() }
                </button>
            </li>
        );
    }
}

export default CheckoutDeliveryOption;
