
import { RenderWhenVisible as SourceRenderWhenVisible } from 'SourceComponent/RenderWhenVisible/RenderWhenVisible.component';
import 'intersection-observer';

export class RenderWhenVisible extends SourceRenderWhenVisible {

    static defaultProps = {
        fallback: () => {},
        isVisible: false
        
    };

    __construct(props) {
        super.__construct(props);
        const { isVisible } = this.props;
        // a hack to determine if the element is on screen or not imidiatelly
        if(isVisible) {
        setTimeout(this.checkIsVisible, 0);
        }
    }
}

export default RenderWhenVisible;
