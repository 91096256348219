// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_TRACK_ORDER_CMS_BLOCK = 'track_order_page_block';
export const TRACK_ORDER = 'track-trace';
export const ORDER_PLACED = 'pending';
export const ORDER_PROCESSING_NEW = 'processing_new';
export const ORDER_CONFIRMED = 'confirmed';
export const ORDER_SHIPPED = 'processing';
export const ORDER_PARTIALLY_CONFIRMED = 'partially_confirmed';
export const ORDER_PARTIALLY_SHIPPED = 'partially_shipped';
export const ORDER_DELIVERY = 'complete';
export const ORDER_PARTIALLY_DELIVERY = 'partially_complete';