/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

export class BankRedirectQuery {

    getBankRedirectConfiguration(method) {
        return new Field('getBankRedirectConfigs').setAlias(method)
            .addArgument('method', 'String!', method)
            .addFieldList(this._getBankRedirectConfigurationFields());
    };

    _getBankRedirectConfigurationFields(){
        return [
            'instructions',
            'enable_installment',
            'installments_period'
        ];
    }
}

export default new BankRedirectQuery();
