import { PureComponent } from 'react';
import SlickSliderHtml from './SlickSliderHtml.component';

/** @namespace Component/SlickSlider/Container */
export class SlickSliderHtmlContainer extends PureComponent {

    render() {
        return <SlickSliderHtml {...this.props} />

    }
}

export default SlickSliderHtmlContainer;