import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Recaptcha from './Recaptcha.component';

/** @namespace Recaptcha/Component/Recaptcha/Container/RecaptchaContainer */
export class RecaptchaContainer extends PureComponent {
    static propTypes = {
        recaptchaType: PropTypes.string.isRequired
    };

    containerFunctions = {
        onChange: this.onChange.bind(this)
    };

    containerProps() {
        return { ...this.props };
    }

    onChange(value) {
        window.recaptchaValidation = value;
    }

    render() {
        return (
            <Recaptcha
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default RecaptchaContainer;
