/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_CURRENT_BRAND, RESET_BRAND_STATE } from './Brand.action';


/** @namespace Store/Brand/Reducer/getInitialState */
export const getInitialState = () => ({
    brand: {
        isLoading: true
    }
});

/** @namespace Store/Brand/Reducer */
const BrandReducer = (state = getInitialState(), action) => {
    const { type, brand } = action;
    
    switch (type) {
    case UPDATE_CURRENT_BRAND:
        return {
            ...state,
            brand: {
                isLoading: false,
                ...brand
            }
        };
    case RESET_BRAND_STATE:
        return {
            brand: {
                isLoading: true,
            }
        }; 
    default:
        return state;
    }
};

export default BrandReducer;
