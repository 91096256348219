
import { PureComponent } from 'react';
import './ColorInverter.style';
import PropTypes from 'prop-types';
import { ChildrenType } from 'SourceType/Common';

export default class ColorInverterComponent extends PureComponent {
    static propTypes = {
      isInverted: PropTypes.bool.isRequired,
      children: ChildrenType.isRequired,
    };
  
    render() {
      const { isInverted = false, children } = this.props;
      // we specify a modifier called `isInverted` in the `mods` prop
      // if isInverted is true, the modifier will be added, otherwise not
      return (
        <div block="ColorInverter" mods={{ isInverted: isInverted }}>
          {children}
        </div>
      );
    }
  }
