import getStore from 'Util/Store';
import {debounce} from "Util/Request";
export const UPDATE_STATE_FREQUENCY = 1000;

const reinitializeKlarna = debounce(() => {
    const ignoredFields = ['sameAsShippingAddress', 'termsAndConditions', 'newsLetter' ];
    let form = document.getElementById('BILLING_STEP');
    let allInputs = form.getElementsByTagName("input");
    let allSelects = form.getElementsByTagName("select");
    let items = {};
    for(let i = 0; i < allInputs.length; i++){
        if(!ignoredFields.includes(allInputs[i].name)){
            items[allInputs[i].name] = allInputs[i].value;
        }
    }
    for(let j = 0; j < allSelects.length; j++){
        items[allSelects[j].name] = allSelects[j].value;
    }
    items.street = [
        items.street0, items.street1
    ];
    window.klarnaBillingAddress = items;
    if(window.klarnaReloadCallback)
        window.klarnaReloadCallback(items);
}, UPDATE_STATE_FREQUENCY)

const renderFields = (args, callback, instance) => {
    const { id } = instance.props;
    const configReducer = getStore().getState().ConfigReducer;
    const { klarna_enable } = configReducer;
    if(klarna_enable && 'BILLING_STEP' === id){
        setTimeout(()=>{
            const ignoredFields = ['sameAsShippingAddress', 'termsAndConditions', 'newsLetter' ];
            let form = document.getElementById('BILLING_STEP');
            let allInputs = form.getElementsByTagName("input");
            let allSelects = form.getElementsByTagName("select");
            for(let i = 0; i < allInputs.length; i++){
                if(!ignoredFields.includes(allInputs[i].name)){
                    allInputs[i].addEventListener('change', () => {
                        reinitializeKlarna();
                    });
                }
            }
            for(let j = 0; j < allSelects.length; j++){
                allSelects[j].addEventListener('change', () => {
                    reinitializeKlarna();
                });
            }
        },1000);
    }
    return callback.apply(instance,args);
}

export const config = {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            renderFields
        }
    }
};

export default config;
