import { Field } from 'Util/Query';

/** @namespace Query/GiftCardEmailPreviewProcessorQuery */

export class GiftCardEmailPreviewProcessorQuery {
    getGiftCardPreviewQuery(am_giftcard_fields){
        return  new Field('emailPreviewProcessor')
            .addArgument('am_giftcard_fields','[GiftCardFields!]!',am_giftcard_fields)
            .addField('htmlContent');
    }
}

export default new  GiftCardEmailPreviewProcessorQuery();
