import { getFormFields, trimAddressFields } from 'Util/Address';

const EXTENSION_ATTRIBUTES = [
    'business_activity',
    'company',
    'vat_id',
    'invoice_type'
];
const containerProps = (args, callback, instance) => {
    return {
        ...callback.apply(instance,args),
        extensionAttributes : instance.props.extensionAttributes,
        onOrderAttributeChange : instance.props.onOrderAttributeChange,
        onVatIdBlur : instance.props.onVatIdBlur,
        onInvoiceTypeClick : instance.props.onInvoiceTypeClick,
        getActivities : instance.props.getActivities,
        invoice_type : instance.props.invoice_type
    }
}
const onShippingSuccess = (args, callback, instance) => {
    // Hondosc Customization
    window.shippingFormSubmitted = true;

    const fields = args[0];
    const {
        saveAddressInformation,
        updateShippingFields,
        addressLinesQty,
        selectedStoreAddress,
        extensionAttributes
    } = instance.props;

    const {
        selectedCustomerAddressId,
        selectedShippingMethod
    } = instance.state;

    const formFields = getFormFields(fields, addressLinesQty);

    const shippingAddress = selectedCustomerAddressId
        ? instance._getAddressById(selectedCustomerAddressId)
        : trimAddressFields(formFields);

    const {
        carrier_code: shipping_carrier_code,
        method_code: shipping_method_code
    } = selectedShippingMethod;

    const isInStoreDelivery = Object.keys(selectedStoreAddress).length > 0;

    const data = {
        billing_address: isInStoreDelivery ? instance.getStoreAddress(shippingAddress, true) : shippingAddress,
        shipping_address: isInStoreDelivery ? instance.getStoreAddress(shippingAddress) : shippingAddress,
        shipping_carrier_code,
        shipping_method_code,
        order_extension_atributes: extensionAttributes
    };

    saveAddressInformation(data);
    const shippingMethod = `${shipping_carrier_code}_${shipping_method_code}`;
    let _shippingAddress = { ...fields, shippingMethod };
    if(_shippingAddress.postcode)
        updateShippingFields(_shippingAddress);
}

export const config = {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            containerProps,
            onShippingSuccess
        },
    }
};

export default config;
