/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import ContentShowMore from './ContentShowMore.component';

/** @namespace Component/ContentShowMore/Container */
export class ContentShowMoreContainer extends PureComponent {
    containerFunctions = {
        toggleShowMore: this.toggleShowMore.bind(this)
    };

    state = {
        isShowMore: false
    }

    containerProps = () => {
        const { children } = this.props;
        const { isShowMore } = this.state;

        return {
            children,
            isShowMore
        };
    };

    toggleShowMore(){
        this.setState({
            isShowMore : !this.state.isShowMore
        });
    }

    render() {
        return (
            <ContentShowMore
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        );
    }
}

export default ContentShowMoreContainer;
