
import FieldForm from 'SourceComponent/FieldForm';
import Loader from 'SourceComponent/Loader';
import PropTypes from 'prop-types';
// import './ProductAlertsPopup.style';

/** @namespace Component/ProductAlertsPopup/Component */
export class ProductAlertsPopup extends FieldForm {
    static propTypes = {
        isLoading: PropTypes.bool,
        onFormSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        isLoading: false
    };

    get fieldMap() {
        return {
            email: {
                validation: ['notEmpty', 'email'],
                label: __('Email')
            },
            allow: {
                type: 'checkbox',
                validation: ['notEmpty'],
                label: __('Allow to send email')
            }
        };
    }

    onFormSuccess = this.onFormSuccess.bind(this);

    onFormSuccess(fields) {
        const { onFormSubmit, showNotification } = this.props;
        if(!fields.allow) {
            return showNotification('info', 
                __("Please allow to send email")
            );
        }
        onFormSubmit(fields);
    }

    renderActions() {
        const { isLoading } = this.props;

        return (
            <>
                <Loader isLoading={ isLoading } />
                <button type="submit" block="Button">
                    { __('NOTIFY ME WHEN IN STOCK') }
                </button>
            </>
        );
    }

    render() {
        return (
            <div block="ProductAlertsPopup">
                { super.render() }
            </div>
        );
    }
}

export default ProductAlertsPopup;
