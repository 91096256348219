const containerProps = (_args, _callback, _instance) =>  {
    const { isCategory } = _instance.props;

    return {
        ..._callback.apply(_instance, _args),
        isCategory
    };
};
export default {
    'Component/CategoryPagination/Container': {
        'member-function': {
            containerProps
        }
    }
};