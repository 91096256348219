import { PureComponent } from 'react';
import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from "Component/ProductCard";
import { formatCurrency, roundPrice } from 'Util/Price';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import { CONFIGURABLE } from 'Util/Product';
import {
    FIXED,
    PERCENTAGE
} from "./BundlerSection.conf";
import "./BundlerSection.style.scss"
import BundlerItem from '../BundlerItem';

/**
 * BundlerSection
 * @class BundlerSection
 * @namespace Component/BundlerSection/Component
 */
class BundlerSection extends PureComponent {
    renderTitle() {
        return (
            <h2 className="ProductLinks-Title">
                <span>{__('Combine it with')}</span>
            </h2>
        )
    }



    render() {
        const {
            linkType,
            linkedProducts: { [linkType]: { items } },
            bundlerItems,
            currency,
            mainProductValue,
            buttonClick,
            mainProduct,
            hasBundlerItems,
            updateBundlerItems,
            isLoading
        } = this.props;

        const detailsLoaded = bundlerItems && mainProductValue && currency;
        const shouldRender = hasBundlerItems(detailsLoaded);
        const selectBundlerItems = updateBundlerItems(detailsLoaded);

        return (
            <>
                {shouldRender &&
                    <ContentWrapper
                        mix={{ block: 'BundleSection' }}
                        wrapperMix={{ block: 'BundleSection', elem: 'Wrapper' }}
                        label={__('Combine products')}
                    >
                        <div id="bundle-section" className="BundleSection-container">
                            {this.renderTitle()}
                            {detailsLoaded && selectBundlerItems.items ? selectBundlerItems.items.map(item => {
                                return (
                                    <BundlerItem
                                        item={item}
                                        currency={currency}
                                        mainProduct={mainProduct}
                                        mainProductValue={mainProductValue}
                                        buttonClick={buttonClick}
                                        bundlerItems={selectBundlerItems}
                                        isLoading={isLoading}
                                    />
                                )
                            })
                                : null}
                        </div>
                    </ContentWrapper >
                }
            </>
        );
    }

}

export default BundlerSection;
