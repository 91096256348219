//for AggregateOffer removal
const getOfferType = (_args, _instance, _callback) => {
    return '';
}

export default {
    "Component/ProductActions/Container": {
        "member-function": {
            getOfferType
        },
    },
};