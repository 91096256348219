/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */

import history from "Util/History";
import { appendWithStoreCode } from 'Util/Url';

const renderItemLinkContent =  (args, callback, instance) => {
    const {
        activeMenuItemsStack,
        item,
        itemMods,
        handleCategoryHover
    } = instance.props;

    const {
        url,
        item_id
    } = item;

    let link_path = typeof url === 'object' ? url.pathname : appendWithStoreCode(url);

    const isHovered = activeMenuItemsStack.includes(item_id);

    return (
        <a
            block="Menu"
            elem="Link"
            id={ item_id }
            onMouseEnter={ handleCategoryHover }
            mods={ { isHovered } }
            href={link_path}
            onClick={(e)=>{
                e.preventDefault();
                history.push(link_path)
            }
            }
        >
            { instance.renderItemContent(item, itemMods) }
        </a>
    );
}

const config = {
    'Component/MenuItem/Component': {
        'member-function': {
            // renderItemLinkContent: [
            //     {
            //         position:100,
            //         implementation: renderItemLinkContent
            //     }
            // ]
        }
    }
}

export default config;
