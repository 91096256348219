export const UPDATE_HREF_LANGS = 'UPDATE_HREF_LANGS';

/**
 * Update Social Logins
 * @param post
 * @namespace ScandiPWA/HrefLang/Store/HrefLang/Action/updateHrefLangs
 */
export const updateHrefLangs = mfAlternateHreflang => ({
    type: UPDATE_HREF_LANGS,
    mfAlternateHreflang
});
