import Field from 'Component/Field';
import { ProductConfigurableAttributeDropdown as SourceProductConfigurableAttributeDropdown } from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';
import {
    STRING_ONLY_ATTRIBUTE_CODES
} from "@scandipwa/scandipwa/src/component/ProductAttributeValue/ProductAttributeValue.config";
import { getBooleanLabel } from 'Util/Product';

export class ProductConfigurableAttributeDropdown extends SourceProductConfigurableAttributeDropdown {
    getCustomIsColorLight(hex) {
        const color = (hex.charAt(0) === '#') ? hex.substring(1, 7) : hex;
        const r = parseInt(color.substring(0, 2), 16); // hexToR
        const g = parseInt(color.substring(2, 4), 16); // hexToG
        const b = parseInt(color.substring(4, 6), 16); // hexToB

        return ((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186;
    }

    getColorValue(color, label) {
        const isLight = this.getCustomIsColorLight(color);

        return isLight ? '#ffffff' : color
    }

    getColorOptionLabel(value) {
        const {
            option: {
                attribute_options,
                is_boolean
            },
            isProductCountVisible
        } = this.props;

        if (attribute_options) {
            const optionValues = attribute_options[value];
            if (optionValues) {
                if (!isProductCountVisible) {
                    const { label } = optionValues;

                    return { ...optionValues, labelText: label };
                }

                const { label, count = 0 } = optionValues;

                const adjustedLabel = getBooleanLabel(label, is_boolean);

                return {
                    ...optionValues,
                    label: `${adjustedLabel} (${count})`,
                    labelText: adjustedLabel,
                    count
                };
            }
        }

        return {};
    }

    renderColorStringValue(value, label, count) {
        const { isFormattedAsText, isSelected } = this.props;
        const isSwatch = label;

        if (isFormattedAsText) {
            return label || value || __('N/A');
        }

        if (!isSwatch) {
            return __('N/A');
        }

        return (
            <span
                block="ProductAttributeValue"
                elem="String"
                mods={ { isSelected } }
                title={ label }
            >
                { value }
            </span>
        );
    }

    getColorAttribute() {
        const { option: { attribute_value, attribute_code, has_swatch }, selectValue } = this.props;
        const attributeOption = this.getColorOptionLabel(selectValue);
        const {
            label, labelText, count, swatch_data
        } = attributeOption;

        // skip attributes without valid swatches
        if (!swatch_data && has_swatch) {
            return null;
        }

        if (!swatch_data || STRING_ONLY_ATTRIBUTE_CODES.includes(attribute_code)) {
            return this.renderColorStringValue(labelText || __('N/A'), null, count);
        }

        const { value, type } = swatch_data;

        if(type !== '1')
            return null

        return this.getColorValue(value, label)
    }

    render() {
        const {
            selectOptions,
            selectValue,
            selectName,
            selectLabel,
            onChange,
            isProductPage,
            attributeSetId
        } = this.props;
            
        return (
            <Field
              id={ selectName }
              name={ selectName }
              type="select"
              placeholder={ __('Choose %s', selectLabel) }
              mix={ { block: 'ProductConfigurableAttributeDropdown' } }
              selectOptions={ selectOptions }
              value={ selectValue }
              onChange={ onChange }
              isProductPage={ isProductPage }
              attributeSetId={ attributeSetId }
              colorAttribute={this.getColorAttribute()}
            />
        );
    }
}

export default ProductConfigurableAttributeDropdown;
