/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {UPDATE_POSTS} from './Posts.action';

export const emptyPosts = {
    items: [],
    count: 0,
    total_pages: 0
};

export const initialState = {
    posts: emptyPosts,
    post: {}
};

/** @namespace ScandiPWA/Blog/Store/Posts/Reducer/postsReducer */
export const PostsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_POSTS:
            if (
                !action ||
                !action.posts ||
                !action.posts.items
            ) {
                return state;
            }
        const {
            posts: {
                items,
                total_count: count,
                    total_pages
                },
                isLoaded
            } = action;
            return {
                ...state,
                posts: {
                    items,
                    total_pages,
                    count
                },
                isLoaded
            };
        default:
            return state;
    }
};

export default PostsReducer;
