/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutQuery from 'Query/Checkout.query';
import CartDispatcher from 'Store/Cart/Cart.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { fetchMutation } from 'Util/Request';

import PayPalQuery from '../../query/PayPal.query';
import PayPal from './PayPal.component';
import { PAYPAL_EXPRESS, PAYPAL_SCRIPT } from './PayPal.config';


/** @namespace Scandipwa/PayPalGraphQl/Component/PayPal/Container/mapStateToProps */
export const mapStateToProps = state => ({
    cartTotals: state.CartReducer.cartTotals,
    clientId: state.ConfigReducer.paypal_client_id,
    paypal_payment_action: state.ConfigReducer.paypal_payment_action,
    isSandboxEnabled: state.ConfigReducer.paypal_sandbox_flag,
    shippingFields: state.CheckoutReducer.shippingFields
});

/** @namespace Scandipwa/PayPalGraphQl/Component/PayPal/Container/mapDispatchToProps */
export const mapDispatchToProps = dispatch => ({
    showNotification: (type, message, e) => dispatch(showNotification(type, message, e))
});

/** @namespace Scandipwa/PayPalGraphQl/Component/PayPal/Container */
export class PayPalContainer extends PureComponent {
    static propTypes = {
        clientId: PropTypes.string,
        isSandboxEnabled: PropTypes.bool,
        setLoading: PropTypes.func.isRequired,
        setDetailsStep: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        isVirtual: PropTypes.bool
    };

    static defaultProps = {
        clientId: 'sb',
        isSandboxEnabled: false,
        isVirtual: undefined
    };

    componentDidMount() {
        const script = document.getElementById(PAYPAL_SCRIPT);
        script.onload = () => this.forceUpdate();
    }

    containerProps = () => ({
        paypal: this.getPayPal(),
        environment: this.getEnvironment(),
        isDisabled: this.getIsDisabled()
    });

    getIsDisabled = () => {
        const { selectedPaymentCode } = this.props;
        return selectedPaymentCode !== PAYPAL_EXPRESS;
    };

    containerFunctions = () => ({
        onError: this.onError,
        onCancel: this.onCancel,
        onApprove: this.onApprove,
        createOrder: this.createOrder
    });

    onApprove = async (data) => {
        const { showNotification, setDetailsStep } = this.props;
        const { orderID, payerID } = data;
        window.klarnaAdditionalData = { token : orderID, payer_id : payerID };
        setTimeout(()=>{
            document.getElementById('checkoutPlaceOrder').click();
        },1000);
        return true;

        /**
         * Old code
         */
        // const guest_cart_id = this._getGuestQuoteId();
        //
        // try {
        //     await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
        //         guest_cart_id,
        //         payment_method: {
        //             code: 'paypal_express',
        //             paypal_express: {
        //                 token: orderID,
        //                 payer_id: payerID
        //             }
        //         }
        //     }));
        //
        //     const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
        //     const { placeOrder: { order : { order_id }, order } } = orderData;
        //
        //     setDetailsStep(order);
        // } catch (e) {
        //     showNotification('error', 'Something went wrong');
        //     return false;
        // }
    };

    onCancel = (data) => {
        const { showNotification, setLoading } = this.props;
        setLoading(false);
        showNotification('info', 'Your payment has been canceled', data);
    };

    onError = (err) => {
        const { showNotification, setLoading } = this.props;
        setLoading(false);
        showNotification('error', 'Some error appeared with PayPal', err);
    };

    getPayPal = () => {
        const { paypal } = window;
        return paypal || false;
    };

    getEnvironment = () => {
        const { isSandboxEnabled } = this.props;
        return isSandboxEnabled ? 'sandbox' : 'production';
    };

    createOrder = async () => {
        const { setLoading, selectedPaymentCode, isTermsAndConditionsAccepted, termsAreEnabled } = this.props;
        if(!isTermsAndConditionsAccepted && termsAreEnabled){
            showNotification('error', __('Please accept terms and conditions.'));
            return ;
        }
        const guest_cart_id = this._getGuestQuoteId();

        setLoading(true);

        const {
            paypalExpress: { token }
        } = await fetchMutation(PayPalQuery.getCreatePaypalExpressTokenMutation({
            guest_cart_id,
            express_button: false,
            code: selectedPaymentCode,
            // use_paypal_credit: this.getIsCredit(),
            urls: {
                cancel_url: 'www.paypal.com/checkoutnow/error',
                return_url: 'www.paypal.com/checkoutnow/error'
            }
        }));

        return token;
    };

    _getGuestQuoteId = () => (isSignedIn() ? '' : CartDispatcher._getGuestQuoteId());

    render() {
        return (
            <PayPal
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayPalContainer);
