import ClickOutside from 'Component/ClickOutside';
import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';
import { FASHION_POPUP_ID, FASHION_POPUP_BROWSER_ITEM, EIGHT_DAYS_IN_SECONDS } from "../FashionPopup/FashionPopup.config";
import { NEW_CUSTOMERS_PROMO_POPUP_ID, NEW_CUSTOMERS_PROMO_POPUP_BROWSER_ITEM, EIGHT_DAYS_IN_SECONDS as EIGHT_DAYS_IN_SECONDS_PROMO } from '../PromoPopup/PromoPopup.config';
import { CUSTOM_BRANDS_PROMO_POPUP_ID, CUSTOM_BRANDS_PROMO_POPUP_BROWSER_ITEM } from "Component/CustomPromoPopup/CustomPromoPopup.config";
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { createPortal } from 'react-dom';

/** @namespace Component/Popup/Component */
export class Popup extends SourcePopup {
    // Same with click outside
    handleClickOutside = () => {
        const { clickOutside, isMobile } = this.props;
        if (!clickOutside || isMobile) {
            return;
        }
        this.hidePopupAndGoBack();
    };

    hidePopUp = () => {
        const { hideActiveOverlay, goToPreviousNavigationState, onClose, id } = this.props;
        const isVisible = this.getIsVisible();

        if(id === FASHION_POPUP_ID)
            BrowserDatabase.setItem(true, FASHION_POPUP_BROWSER_ITEM, EIGHT_DAYS_IN_SECONDS);

        if (id === NEW_CUSTOMERS_PROMO_POPUP_ID)
            BrowserDatabase.setItem(true, NEW_CUSTOMERS_PROMO_POPUP_BROWSER_ITEM, EIGHT_DAYS_IN_SECONDS_PROMO);

        if (id === CUSTOM_BRANDS_PROMO_POPUP_ID)
            BrowserDatabase.setItem(true, CUSTOM_BRANDS_PROMO_POPUP_BROWSER_ITEM, EIGHT_DAYS_IN_SECONDS_PROMO);

        if (isVisible) {
            this.unfreezeScroll();
            hideActiveOverlay();
            goToPreviousNavigationState();
            onClose();
        }
    };

    renderContent() {
        const { children, contentMix, id } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        let style = {
            'max-height': window.innerHeight
        };

        if(id === 'ProductGalleryPopup') {
            style = {
                'max-height': window.innerHeight,
                'height': window.innerHeight
            };
        }

        return (
            <ClickOutside onClick={ this.handleClickOutside }>
                <div block="Popup" elem="Content" mix={ contentMix } style={ style }>
                    <header block="Popup" elem="Header">
                        { this.renderTitle() }
                        { this.renderCloseButton() }
                    </header>
                    { this.renderNotifications() }
                    { children }
                </div>
            </ClickOutside>
        );
    }

    render() {
        const { mix, areOtherOverlaysOpen } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
            </div>,
            document.body
        );
    }
}

export default Popup;
