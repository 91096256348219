import { connect } from 'react-redux';
import { PureComponent } from 'react';
import ModiFace from './ModiFace.component';
import { showPopup } from 'SourceStore/Popup/Popup.action';
import { MODIFACE } from '../ModiFacePopup/ModifacePopup.config';
import modifaceScripts from '../Scripts';

export const mapDispatchToProps = (dispatch) => ({
    showPopup: (type, payload) => dispatch(showPopup(type, payload))
});

export const mapStateToProps = (state) => ({})

class ModiFaceContainer extends PureComponent {

    onClickPopup() {
        const { showPopup } = this.props;
        showPopup(
            MODIFACE,
            {
                title: "Modiface"
            }
        );
    }

    componentDidMount(){
        const { category } = this.props
        modifaceScripts.initailizeModifaceScript(category)
    }

    componentWillUnmount() {
        modifaceScripts.removeScriptFromDom();
    }

    containerFunctions = {
        onInitModiface: this.onClickPopup.bind(this),
    };

    containerProps = () => {   
        const { 
            color, 
            category,
            product
        } = this.props;

        return {
            color,
            category,
            product
        }
    }

    render(){
        return(
            <ModiFace 
             { ...this.containerFunctions }
             { ...this.containerProps() }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModiFaceContainer);