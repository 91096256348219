/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// import { createReducer, getStore } from 'Store/index';
// import getStore from 'Util/Store';

export class AppComponentPlugin {
    /**
     * Updates the reducer list
     * The store is created before plugins are called therefore this function is required to get the blog reducers to
     * connect to the redux store.
     * @param args
     * @param callback
     * @returns {*}
     */
    updateReducerList = (args, callback) => {
        getStore().replaceReducer(createReducer());
        return callback(...args);
    };
}

const { updateReducerList } = new AppComponentPlugin();

export const config = {
    // 'Component/App/Component': {
    //     'member-function': {
    //         renderRedux: updateReducerList
    //     }
    // }
};

export default config;
