import Popup from 'SourceComponent/Popup';
import ProductAlertsPopup from '../component/ProductAlertsPopup';
import { PRODUCT_ALERT_POPUP } from '../component/ProductAlertsPopup/ProductAlertsPopup.config';
import { IN_STOCK } from 'SourceComponent/ProductCard/ProductCard.config';

class ProductAlerts {
    handleAlternativeClick = (instance) => {
        const { autoRelatedRef } = instance.props;
        let targetPdpProductTabs = document.getElementById('PdpProductTabs-Button_2000');
        window.scrollTo({top : autoRelatedRef.current.offsetTop, 'behavior': 'smooth'});
        if(targetPdpProductTabs){
            targetPdpProductTabs.click();
        }
    }

    renderInStockSubscribeButton = (args, callback, instance) => {
        const {
            handlePriceDropSubscribeAlertInStock,
            isLoading,
            isSignedIn,
            productAlertPopUp,
            autoRelatedRef,
            isInStockAlertEnabled,
            stockStatus,
            setLoadingState
        } = instance.props;
        
        if(isSignedIn) {
            return callback.apply(instance, args);
        }

        if (!isInStockAlertEnabled) {
            return null;
        }

        if (stockStatus === IN_STOCK || !stockStatus) {
            return null;
        }


        return (
            <>
                <Popup
                    id={PRODUCT_ALERT_POPUP}
                    clickOutside={false}
                    mix={{ block: "ProductAlerts" }}
                >
                    <ProductAlertsPopup
                        isLoading = { isLoading }
                        setLoadingState= { setLoadingState }
                        handlePriceDropSubscribeAlertInStock = { handlePriceDropSubscribeAlertInStock }
                    />
                </Popup>
                <button
                    block="ProductAlerts"
                    elem="InStock"
                    onClick={productAlertPopUp}
                    className={'Button'}
                >
                    {__("NOTIFY ME WHEN IN STOCK")}
                </button>
                <div block="ProductActions" elem="FindStore">
                    <div block="Button" onClick={this.handleAlternativeClick.bind(this, instance)}>
                        <span>{ __('SEE ALTERNATIVES') }</span>
                    </div>
                </div>
            </>
        );
    };
}

const { renderInStockSubscribeButton } = new ProductAlerts();

export default {
    'Component/ProductAlerts/Component': {
        'member-function': {
            renderInStockSubscribeButton
        }
    }
};