/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    UPDATE_REWARD_POINTS_DATA,
    LOAD_CUSTOMER_REWARDS,
    LOAD_CUSTOMER_REWARDS_EXPIRATIONS
} from './RewardPoints.action';
import BrowserDatabase from "SourceUtil/BrowserDatabase/BrowserDatabase";
import {CUSTOMER_REWARD_POINTS_DATA, REWARD_POINTS_DATA, CUSTOMER_REWARD_EXPIRATIONS_DATA} from "./RewardPoints.dispatcher";

/** @namespace Store/RewardPoints/Reducer/getInitialState */
export const getInitialState = () => ({
    rewards: BrowserDatabase.getItem(REWARD_POINTS_DATA) || {},
    customerRewards: BrowserDatabase.getItem(CUSTOMER_REWARD_POINTS_DATA) || {},
    customerRewardsExpirations: BrowserDatabase.getItem(CUSTOMER_REWARD_EXPIRATIONS_DATA) || [],
    rewardHighlight : {}
});

/** @namespace Store/RewardPoints/Reducer/RewardPointsReducer */
export const RewardPointsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_REWARD_POINTS_DATA:
        return {
            ...state,
            rewards : action.data
        };
        case LOAD_CUSTOMER_REWARDS:
            return {
            ...state,
            customerRewards : action.data
            }
        case LOAD_CUSTOMER_REWARDS_EXPIRATIONS:
            return {
                ...state,
            customerRewardsExpirations : action.data
            }
    default:
        return state;
    }
};

export default RewardPointsReducer;
