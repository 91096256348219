import { Field } from 'SourceUtil/Query';
import ProductListQuery from "Query/ProductList.query";

// import ProductReturnQuery from '../../query/ProductReturn.query'
export class OrderQueryPlugin {
    /**
     * Get base order info fields
     * @returns [Field]
     * @private
     */
    _aroundGetBaseOrderInfoFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'total_qty_ordered',
        'state',
        'amrma_is_returnable',
        'status',
        'email',
        this.getArmResolutions()
    ];

    getArmResolutions () {
        return new Field('order_items')
            .addField(this.getProduct());
    }
    getProduct() {
        return new Field('item')
            .addFieldList(this.getProductList())
    }

    getProductList() {
        return [
            'order_item_id',
            'product_id',
            'sku',
            'is_returnable'
        ]
    }
    /**
     * Get order products fields
     * @returns [Field]
     * @private
     */
    _aroundGetOrderProductsFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'order_item_id',
        'qty_available_to_return'
    ];

    _getOrderProductsFields = (args, callback, instance) => {
        return [
            ...callback.apply(instance, args),
            ProductListQuery._getConfigurableProductFragment(),
            'type_id'
        ];
    }
}

export const { _aroundGetBaseOrderInfoFields, _aroundGetOrderProductsFields, _getOrderProductsFields } = new OrderQueryPlugin();

export default {
    'Query/Order': {
        'member-function': {
            _getBaseOrderInfoFields: [
                {
                    position: 100,
                    implementation: _aroundGetBaseOrderInfoFields
                }
            ],
            _getOrderProductsFields: [
                {
                    position: 100,
                    implementation: _getOrderProductsFields
                }
            ]
        }
    },
    // 'Query/ProductList': {
    //     'member-function': {
    //     _getProductInterfaceFields: [
    //             {
    //                 position: 100,
    //                 implementation: _aroundGetOrderProductsFields
    //             }
    //         ]
    //     }
    // }
};
