import { updateIsLoading } from "../store/SampleProduct/SampleProduct.action";

const mapStateToProps = (args, callback, instance) => {
    const { CartReducer } = args[0];
    return {
        ...callback.apply(instance, args),
        totals: CartReducer.cartTotals,
        isAddingSample: CartReducer.isLoading
    }
};

const mapDispatchToProps = (_args, _callback, _instance) => {
    const [dispatch] = _args;
    return {
        ..._callback.apply(_instance, _args),
        updateIsLoading: (state) => dispatch(updateIsLoading(state)
        ),
    }
}

const isSampleProduct = (_args, _callback, _instance) => {
    const { totals: {
        items = []
    } = {} } = _instance.props;

    return Object.values(items).map((item) => {
        const {
            product: { attributes },
        } = item;

        if (!attributes["type_of_product"]) {
            return;
        }

        return item;
    }, [])
}

const buttonClick = (_args, _callback, _instance) => {
    const {
        product: { type_id, attributes = {}, },
        showNotification,
        quantity,
        totals: {
            items = []
        } = {},
        isSampleProducts,
        updateIsLoading
    } = _instance.props;
    
    if(isSampleProducts) {
        updateIsLoading(true);
    }

    return _callback.apply(_instance, _args);
}

const containerProps = (args, callback, instance) => {
    const {
        isSampleProducts = false
    } = instance.props;

    return {
        ...callback(...args),
        isSampleProducts
    };
}



export default {
    'Component/AddToCart/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/AddToCart/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/AddToCart/Container': {
        'member-function': {
            // isSampleProduct,
            buttonClick,
            containerProps
        }
    }
};