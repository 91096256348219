import greekUtils from 'greek-utils';

const matchSearchString = (searchTerm,text) => {
    text = text.replaceAll('·','');
    text = greekUtils.toGreeklish(text);
    return (
        text.normalize("NFD").replace(/[\u0300-\u036f]|’/g, "").indexOf(searchTerm) !== -1
        ||
        text.normalize("NFD").replace(/[\u0300-\u036f]|’/g, "").toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
    )
}

export default matchSearchString;

export const removeHtmlFromString = (string) => {
    const htmlRegex = /<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[^'">\s]+))?)+\s*|\s*)\/?>/gi;
    if(htmlRegex.test(string)) {
        string = string.replace(/(<([^>]+)>)/ig, '');
    }

    return string;
}

export const removeStyleFromString = (content) => {

    if(!content.length > 0) {
        return content;
    }

    const regex = /style="(.*?)"/gm;                
    const result = content.replace(regex, '');

    return result;
}