// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import HeaderSlogan from './HeaderSlogan.component';

/** @namespace Component/HeaderSloganContainer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    header_slogan_enabled : state.ConfigReducer.header_slogan_enabled,
    header_slogan_cms_before_counter : state.ConfigReducer.header_slogan_cms_before_counter,
    header_slogan_cms_after_counter : state.ConfigReducer.header_slogan_cms_after_counter,
    header_slogan_countdown_end_date : state.ConfigReducer.header_slogan_countdown_end_date,
    header_slogan_show_cd : state.ConfigReducer.header_slogan_show_cd
});

/** @namespace Component/HeaderSloganContainer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});


/** @namespace Component/HeaderSloganContainer/Container */
class HeaderSloganContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
    };

    containerProps = () => ({...this.props})

    render() {
        return (
            <HeaderSlogan
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSloganContainer);
