const containerProps =  (args, callback, instance) => {
    return {
        ...callback.apply(instance, args),
        setBankRedirectAdditionalData : instance.props.setBankRedirectAdditionalData
    };
}

export const config = {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            containerProps: containerProps
        }
    }
};

export default config;
