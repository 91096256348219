/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { ChildrenType } from 'Type/Common';

import './ContentShowMore.style';

/** @namespace Component/ContentShowMore/Component */
export class ContentShowMore extends PureComponent {
    static propTypes = {
        toggleShowMore: PropTypes.func.isRequired,
        isShowMore: PropTypes.bool.isRequired
    };

    expandableRef = createRef();
    expandablePlaceholderRef = createRef();

    componentDidUpdate(prevProps) {
        const { isShowMore: prevIsShowMore } = prevProps;
        const { isShowMore: nextIsShowMore } = this.props;

        if (prevIsShowMore !== nextIsShowMore) {
            const ONE_SECOND_IN_MS = 1000;
            const transitionDurationCSStoMS = window
                .getComputedStyle(this.expandableRef.current)
                .getPropertyValue('transition-duration')
                .slice(0, -1) * ONE_SECOND_IN_MS;

            if(nextIsShowMore) {
                setTimeout(() => this.expandableRef.current.style.maxHeight = this.expandablePlaceholderRef.current.clientHeight+'px',
                    transitionDurationCSStoMS);
            } else {
                setTimeout(() => {
                    this.expandableRef.current.style.maxHeight = '300px';
                    this.expandablePlaceholderRef.current.scrollIntoView();
                    }, transitionDurationCSStoMS);
            }
        }
    }

    renderShowAllButton() {
        const { isShowMore, toggleShowMore } = this.props;
        let activeClass = isShowMore ? 'showMore' : '';

        return (
            <div
                block="ContentShowMore"
                elem="ShowAllButtonWrapper"
            >
                <button
                  onClick={ toggleShowMore }
                  mix={ { block: 'Button', mods: { likeLink: true } } }
                  block="ContentShowMore"
                  elem="ShowAllButton"
                  mods={ activeClass }
                >
                    { isShowMore ? __('Show less') : __('Show more') }
                </button>
            </div>
        );
    }

    renderExpandableChildren() {
        const { children, isShowMore } = this.props;
        let activeClass = isShowMore ? 'showMore' : '';

        return (
            <div
                ref={ this.expandableRef }
                block="ContentShowMore"
                elem="ContentShowMoreChildren"
                className={ activeClass }
            >
                { children }
            </div>
        );
    }

    renderExpandablePlaceholder() {
        const { children, isShowMore } = this.props;
        let activeClass = isShowMore ? 'showMore' : '';

        return (
            <div
                ref={ this.expandablePlaceholderRef }
                block="ContentShowMore"
                elem="ContentShowMorePlaceholder"
                className={ activeClass }
            >
                { children }
            </div>
        );
    }

    render() {
        return (
            <div block="ContentShowMore" ref={ this.ref }>
                { this.renderExpandablePlaceholder() }
                { this.renderExpandableChildren() }
                { this.renderShowAllButton() }
            </div>
        );
    }
}
export default ContentShowMore;
