const containerProps =  (args, callback, instance) => {
    return {
        ...callback.apply(instance, args),
        noSort : instance.props.noSort,
        className : instance.props.className
    };
}

export const config = {
    'Component/Field/Container': {
        'member-function': {
            containerProps: [
                {
                    position:100,
                    implementation: containerProps
                }
            ]
        }
    }
}

export default config;
