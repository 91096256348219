/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';

import FieldPassword from './FieldPassword.component';

/** @namespace Component/FieldPassword/Container */
export class FieldPasswordContainer extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        autocomplete: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool
        ]),
        type: PropTypes.string.isRequired,
        skipValue: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool
        ]),
        ariaLabel: PropTypes.string
    };

    static defaultProps = {
        value: '',
        autocomplete: 'off',
        isDisabled: false,
        skipValue: false,
        ariaLabel: ''
    };

    containerFunctions = {
        togglePasswordShow: this.togglePasswordShow.bind(this)
    };

    state = {
        showPassword: false
    }

    getAutocomplete() {
        const { autocomplete, type } = this.props;

        /**
         * Make sure password auto-complete is enabled
         */
        if (type === 'password' && autocomplete === 'off') {
            return 'current-password';
        }

        return autocomplete;
    }

    containerProps = () => {
        const {
            /* eslint-disable react/prop-types */
            dispatch,
            selectOptions,
            isControlled,
            handleChange,
            onChangeCheckbox,
            onKeyEnterDown,
            formRefMap,
            validationStatus,
            subLabel,
            fileExtensions,
            customValidationStatus,
            isLabelWithArrow,

            // Props to be transformed
            isDisabled: disabled,
            autocomplete,
            skipValue,
            ariaLabel,

            // Props that are passed correctly from the beginning
            ...validProps
        } = this.props;

        const { showPassword } = this.state;

        const ariaLabelProp = ariaLabel ? { 'aria-label': ariaLabel } : {};

        return {
            ...validProps,
            ...ariaLabelProp,
            disabled,
            showPassword,
            'data-skip-value': skipValue,
            autoComplete: this.getAutocomplete()
        };
    };

    togglePasswordShow(){
        this.setState({
            showPassword : !this.state.showPassword
        });
    }

    render() {
        return (
            <FieldPassword
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        );
    }
}

export default FieldPasswordContainer;
