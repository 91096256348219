import {updateTotals} from 'Store/Cart/Cart.action';
import {fetchMutation} from 'Util/Request';
import BrowserDatabase from 'Util/BrowserDatabase';
import CheckoutQuery from 'Query/Checkout.query';
import {ONE_MONTH_IN_SECONDS} from 'Util/Request/QueryDispatcher';
import getStore from 'Util/Store';

const GUEST_QUOTE_ID = 'guest_quote_id';

class CheckoutDeliveryOptionsContainerPlugin {

    _getDefaultMethod(props) {
        const {shippingMethods} = props;
        const [{method_code, carrier_code} = [{}]] = shippingMethods;
        return {shipping_carrier_code: carrier_code, shipping_method_code: method_code};
    }

    onShippingMethodSelect = (args, callback, instance) => {
        const {method_code, carrier_code} = args[0];
        const {shippingFields : _shippingFields } = getStore().getState().CheckoutReducer;
        // Omit Fields for fixing saved address error
        let {
            shippingMethod,
            prefix,
            suffix,
            customer_id,
            id,
            default_billing,
            default_shipping,
            middlename,
            country,
            region,
            ...shippingFields
        } = _shippingFields;
        if(typeof region === 'object'){
            shippingFields = {...shippingFields, region_id : region.region_id};
        }
        if(shippingFields.street0){
            shippingFields.street = shippingFields.street0
            if (shippingFields.street1){
                shippingFields.street += shippingFields.street1;
            }
        }
        delete shippingFields.street0;
        delete shippingFields.street1;
        let addressInformation = {
            billing_address: {}, shipping_address: {
                country_id: getStore().getState().ConfigReducer.shipping_origin_country_id
            }, shipping_carrier_code: carrier_code, shipping_method_code: method_code
        }
        if (shippingFields.country_id) addressInformation.shipping_address = shippingFields;
        let paymentSection = document.getElementsByClassName('CheckoutPayments')[0];
        if(paymentSection){
            paymentSection.style.opacity = "0.4";
            paymentSection.style.pointerEvents = "none";
        }

        fetchMutation(CheckoutQuery.getSaveAddressInformation(addressInformation, BrowserDatabase.getItem(GUEST_QUOTE_ID).token)).then(({saveAddressInformation: data}) => {
            const {totals, payment_methods} = data;
            const {cartTotals} = getStore().getState().CartReducer;
            const {items} = cartTotals;
            const event = new CustomEvent("updatePaymentMethods", { detail : { payment_methods, resetPayments : true } });
            document.dispatchEvent(event);
            getStore().dispatch(updateTotals({...getStore().getState().CartReducer.cartTotals, ...totals, items}));
            BrowserDatabase.setItem(totals, 'PAYMENT_TOTALS', ONE_MONTH_IN_SECONDS);
            if(totals.shipping_amount !== null){
                BrowserDatabase.setItem(totals.shipping_amount.toString(), 'shipping_amount', ONE_MONTH_IN_SECONDS);
            }else {
                BrowserDatabase.deleteItem('shipping_amount');
            }
            if(paymentSection){
                paymentSection.style.opacity = "";
                paymentSection.style.pointerEvents = "";
            }
        }, (e) => {
            if(paymentSection){
                paymentSection.style.opacity = "";
                paymentSection.style.pointerEvents = "";
            }
            console.log(e)
        });
        const shippingevent = new CustomEvent("updateSelectedShippingMethod", { detail : args[0] });
        document.dispatchEvent(shippingevent);
        callback.apply(instance, args)
    }

    componentDidUpdate = (args, callback, instance) => {
        const {resetShippingMethod, selectedShippingMethodCode} = instance.state;
        const {shipping_carrier_code, shipping_method_code} = this._getDefaultMethod(instance.props);
        if (!resetShippingMethod && shipping_carrier_code) {
            const {shippingFields : _shippingFields } = getStore().getState().CheckoutReducer;
            // Omit Fields for fixing saved address error
            let {
                shippingMethod,
                prefix,
                suffix,
                customer_id,
                id,
                default_billing,
                default_shipping,
                middlename,
                country,
                region,
                ...shippingFields
            } = _shippingFields;
            if(typeof region === 'object'){
                shippingFields = {...shippingFields, region_id : region.region_id};
            }
            if(shippingFields.street0){
                shippingFields.street = shippingFields.street0
                if (shippingFields.street1){
                    shippingFields.street += shippingFields.street1;
                }
            }
            delete shippingFields.street1;
            delete shippingFields.street0;
            let addressInformation = {
                billing_address: {}, shipping_address: {
                    country_id: getStore().getState().ConfigReducer.shipping_origin_country_id
                }, shipping_carrier_code, shipping_method_code
            }
            if (shippingFields.country_id) addressInformation.shipping_address = shippingFields;
            fetchMutation(CheckoutQuery.getSaveAddressInformation(addressInformation, BrowserDatabase.getItem(GUEST_QUOTE_ID).token)).then(({saveAddressInformation: data}) => {
                const {totals, payment_methods} = data;
                const {cartTotals} = getStore().getState().CartReducer;
                const {items} = cartTotals;
                const event = new CustomEvent("updatePaymentMethods", { detail : { payment_methods, resetPayments : true } });
                document.dispatchEvent(event);
                getStore().dispatch(updateTotals({...getStore().getState().CartReducer.cartTotals, ...totals, items}));
                BrowserDatabase.setItem(totals, 'PAYMENT_TOTALS', ONE_MONTH_IN_SECONDS);
                if(totals.shipping_amount !== null){
                    BrowserDatabase.setItem(totals.shipping_amount.toString(), 'shipping_amount', ONE_MONTH_IN_SECONDS);
                }else {
                    BrowserDatabase.deleteItem('shipping_amount');
                }
            }, (e) => {
                console.log(e)
            });
            instance.setState({resetShippingMethod : true })
        }
        return callback.apply(instance, args);
    }

    __construct = (args, callback, instance) => {
        callback.apply(instance, args);
        instance.state = {
            ...instance.state, resetShippingMethod: false
        };
    }
}

const {
    componentDidUpdate, __construct, onShippingMethodSelect
} = new CheckoutDeliveryOptionsContainerPlugin();

export const config = {
    'Component/CheckoutDeliveryOptions/Container': {
        'member-function': {
            selectShippingMethod: onShippingMethodSelect, componentDidUpdate, __construct
        }
    }
};

export default config;
