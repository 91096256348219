export class ConfigQueryPlugin {
    /**
     * Get config free shipping info fields
     * @returns [Field]
     * @private
     */
_aroundGetConfigQueryInfoFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'is_free_period',
        'free_shpping_value'
    ];
}

export const { _aroundGetConfigQueryInfoFields } = new ConfigQueryPlugin();

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: [
                {
                    position: 100,
                    implementation: _aroundGetConfigQueryInfoFields
                }
            ]
        }
    }
};
