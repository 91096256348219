// eslint-disable-next-line import/prefer-default-export
export const FREE_GIFTS = 'free-gifts';
export const FREE_GIFT_TYPE_ID = '623';
export const FREE_GIFTS_CMS_BLOCK_ID = 'free-gift-top'

export const renderIsGift = (product) => {
    const {
        categories = {}
    } = product;
    
    if (categories && Object.keys(categories).length > 0) {
        const isGift = Object.values(categories).filter(({ id, name }) => id == FREE_GIFT_TYPE_ID || name == 'FREE GIFT').length !== 0;

        return isGift;

    }

    return false;
}