

import { PureComponent } from 'react';
import FreeShippingPromotionsComponent from "./FreeShippingPromotions.component";

export class FreeShippingPromotionsContainer extends PureComponent{
    render() {
        return (
            <FreeShippingPromotionsComponent
                { ...this.props }
            />
        );
    }
}

export default FreeShippingPromotionsContainer;
