export class StoreUrlRewritesDispatcherPlugin {

    processUrlOptions = (args, callback, instance) => {
        const [ originalOptions ] = args;
        let { urlParam, ...other } = originalOptions;
        if(urlParam.includes('all/f/search')){
            urlParam = urlParam.replace(/\./g, "");
        }
        const options = {...originalOptions, urlParam }
        return callback.apply(instance, [ options ]);
    }

}

const { processUrlOptions } = new StoreUrlRewritesDispatcherPlugin();

export const config = {
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            processUrlOptions: [
                {
                    position: 100,
                    implementation: processUrlOptions
                }
            ]
        }
    }
};

export default config;
