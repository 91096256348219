import { Field } from "SourceUtil/Query";

/** @namespace Query/ReOrder */
export class ReOrderQuery {

  getSaveReOrderItemMutation(orderNumber) {
    const mutation = new Field("reorderItems")
      .addArgument("orderNumber", "String!", orderNumber)
      .addField(this.getUserInputFields())
      .addField(this.getCartInputFields());

    return mutation;
  }

  getClearCustomerCart(cartId) {
    const mutation = new Field("clearCustomerCart")
    .addArgument("cartUid", "String!", cartId);

    return mutation;
  }

  getCartInputFields(){
    return new Field('cart')
        .addFieldList(this.getCartInputField());
  }

  getUserInputFields() {
    return new Field('userInputErrors')
        .addFieldList(this.getUserInputField())
  }

  getCartInputField(){
      return [
          'email',
          'total_quantity',

      ]
  }

  getUserInputField() {
    return [
        'code',
        'message',
        'path'
    ]
  }
}

export default new ReOrderQuery();