const OPTIONAL = 'opt';
const REQUIRED = 'req';

import Field from 'Component/Field';
class MyAccountCreateAccountPlugin {

    renderGenderField(instance){
        const { customer_address_gender_show } = instance.props;

        if(!customer_address_gender_show) return null;

        const validation = customer_address_gender_show === REQUIRED ? ['notEmpty'] : [];

        return (
            <Field
                type="select"
                label={ __('Gender') }
                placeholder={ __('Male/Female') }
                id="gender"
                name="gender"
                validation={ validation }
                selectOptions={[
                    {value : 1, label : __('Male')},
                    {value : 2, label : __('Female')}
                ]}
            />
        );
    }

    renderPostCodeField(instance){
        const { customer_address_postcode_show } = instance.props;

        if(!customer_address_postcode_show) return null;

        const validation = customer_address_postcode_show === REQUIRED ? ['notEmpty'] : [];

        return (
            <Field
                label={ __('Post Code') }
                id="postcode"
                name="postcode"
            />
        );
    }

    renderDOBField(instance){
        const { customer_address_dob_show } = instance.props;

        if(!customer_address_dob_show) return null;

        const validation = customer_address_dob_show === REQUIRED ? ['notEmpty'] : [];

        return (
            <Field
                type="date"
                label={ __('Date of Birth') }
                placeholder={ __('Date of Birth') }
                id="date_of_birth"
                name="date_of_birth"
                validation={ validation }
            />
        );

    }

    renderAdditionalFields(instance){

        return (
            <>
                { this.renderGenderField(instance)}
                { this.renderPostCodeField(instance)}
                { this.renderDOBField(instance)}
            </>
        );
    }
    renderVatNumberField = (args, callback, instance) => {
        return (
            <>
                {this.renderAdditionalFields(instance)}
                { callback.apply(instance,args)}
            </>
        );

    }
}

const {
    renderVatNumberField
} = new MyAccountCreateAccountPlugin();



export const config = {
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            renderVatNumberField
        }
    }
};

export default config;
