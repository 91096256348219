/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ShareIcon.style';

/** @namespace Component/ShareIcon/Component */
export class ShareIcon extends PureComponent {
    static propTypes = {
        isPrimary: PropTypes.bool
    };

    static defaultProps = {
        isPrimary: false
    };

    render() {
        const { isPrimary } = this.props;

        return (
            <svg
              block="ShareIcon"
              mods={ { isPrimary } }
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M20.924 3.617C20.8741 3.49665 20.801 3.38725 20.709 3.295L20.705 3.291C20.5179 3.1044 20.2643 2.99973 20 3H14C13.7348 3 13.4804 3.10536 13.2929 3.29289C13.1054 3.48043 13 3.73478 13 4C13 4.26522 13.1054 4.51957 13.2929 4.70711C13.4804 4.89464 13.7348 5 14 5H17.586L10.293 12.293C10.1975 12.3852 10.1213 12.4956 10.0689 12.6176C10.0165 12.7396 9.9889 12.8708 9.98775 13.0036C9.9866 13.1364 10.0119 13.2681 10.0622 13.391C10.1125 13.5139 10.1867 13.6255 10.2806 13.7194C10.3745 13.8133 10.4861 13.8875 10.609 13.9378C10.7319 13.9881 10.8636 14.0134 10.9964 14.0123C11.1292 14.0111 11.2604 13.9835 11.3824 13.9311C11.5044 13.8787 11.6148 13.8025 11.707 13.707L19 6.414V10C19 10.2652 19.1054 10.5196 19.2929 10.7071C19.4804 10.8946 19.7348 11 20 11C20.2652 11 20.5196 10.8946 20.7071 10.7071C20.8946 10.5196 21 10.2652 21 10V4V3.997C21 3.867 20.974 3.739 20.924 3.617ZM3 8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3H9C9.26522 3 9.51957 3.10536 9.70711 3.29289C9.89464 3.48043 10 3.73478 10 4C10 4.26522 9.89464 4.51957 9.70711 4.70711C9.51957 4.89464 9.26522 5 9 5H8C7.20435 5 6.44129 5.31607 5.87868 5.87868C5.31607 6.44129 5 7.20435 5 8V16C5 16.7957 5.31607 17.5587 5.87868 18.1213C6.44129 18.6839 7.20435 19 8 19H16C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7957 19 16V15C19 14.7348 19.1054 14.4804 19.2929 14.2929C19.4804 14.1054 19.7348 14 20 14C20.2652 14 20.5196 14.1054 20.7071 14.2929C20.8946 14.4804 21 14.7348 21 15V16C21 17.3261 20.4732 18.5979 19.5355 19.5355C18.5979 20.4732 17.3261 21 16 21H8C6.67392 21 5.40215 20.4732 4.46447 19.5355C3.52678 18.5979 3 17.3261 3 16V8Z" />
            </svg>
        );
    }
}

export default ShareIcon;
