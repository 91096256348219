import  { CheckoutShippingContainer as SourceCheckoutShippingContainer, mapDispatchToProps, mapStateToProps  }  from 'SourceComponent/CheckoutShipping/CheckoutShipping.container';
import {
    STORE_IN_PICK_UP_ATTRIBUTE_CODE,
    STORE_IN_PICK_UP_METHOD_CODE
} from 'Component/StoreInPickUp/StoreInPickUp.config';
import {connect} from "react-redux";

/** @namespace Component/CheckoutShipping/Container */
export class CheckoutShippingContainer extends SourceCheckoutShippingContainer {

    containerFunctions = {
        onShippingSuccess: this.onShippingSuccess.bind(this),
        onAddressSelect: this.onAddressSelect.bind(this),
        onShippingMethodSelect: this.onShippingMethodSelect.bind(this),
        onShippingError: this.onShippingError.bind(this)
    };

    componentDidUpdate(prevProps, prevState){
        const { isLoading, device } = this.props;
        const { isLoading: prevIsLoading } = prevProps;
        super.componentDidUpdate(prevProps, prevState)

        // if(!isLoading && prevIsLoading !== isLoading) {
        //     this.scrollToTop();
        // }
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    __construct(props) {
        super.__construct(props);

        const { shippingMethods = [], savedShippingMethodCode } = props;

        const previousShippingMethod = shippingMethods.find(
            (method) => `${method.carrier_code}_${method.method_code}` === savedShippingMethodCode
        );

        const [defaultShippingMethod] = shippingMethods.filter((method) => method.available);
        const selectedShippingMethod = previousShippingMethod || defaultShippingMethod || {};
        const { method_code = '' } = selectedShippingMethod;

        this.state = {
            selectedCustomerAddressId: 0,
            isSubmitted: true,
            selectedShippingMethod: method_code && method_code !== STORE_IN_PICK_UP_METHOD_CODE
                ? selectedShippingMethod
                : {}
        };
    }

    containerProps() {
        const { onSaveShippingAddressCheckboxSelect, saveShippingAddress} = this.props;
        return {
            ...super.containerProps(),
            onSaveShippingAddressCheckboxSelect,
            saveShippingAddress,
            isSubmitted : this.state.isSubmitted
        };
    }

    onAddressSelect(id) {
        this.setState({ selectedCustomerAddressId: id });
    }

    onShippingSuccess(fields){
        window.shippingFormSubmitted = true;
        super.onShippingSuccess(fields);
    }

    onShippingError() {
        window.shippingFormSubmitted = false;
        console.log(this.state)
        // super.onShippingError();
        this.setState({ isSubmitted: true });
    }

    getStoreAddress(shippingAddress, isBillingAddress = false) {
        const {
            selectedStoreAddress: {
                region,
                city,                postcode,
                phone,
                street,
                name,
                pickup_location_code,
                country_id
            }
        } = this.props;

        const storeAddress = {
            ...shippingAddress,
            country_id,
            region_id: region,
            city,
            postcode,
            telephone: phone,
            street: [street],
            firstname: name,
            lastname: 'Store'
        };

        if (isBillingAddress) {
            return storeAddress;
        }

        return {
            ...storeAddress,
            extension_attributes: [
                {
                    attribute_code: STORE_IN_PICK_UP_ATTRIBUTE_CODE,
                    value: pickup_location_code
                }
            ]
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutShippingContainer);
