import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideActivePopup } from 'SourceStore/Overlay/Overlay.action';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { hideActiveOverlay } from 'SourceStore/Overlay/Overlay.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';
import ProductAlertsPopup from './ProductAlertsPopup.component';
import { showNotification } from 'SourceStore/Notification/Notification.action';

/** @namespace Component/ProductAlertsPopup/Component/mapStateToProps */
export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload
});

export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    resetHideActivePopup: () => dispatch(hideActivePopup(false)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Component/ProductAlertsPopup/Container */
export class ProductAlertsPopupContainer extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        handlePriceDropSubscribeAlertInStock: PropTypes.func.isRequired
    };

    closeOverly() {
        const {
            hideActiveOverlay,
            resetHideActivePopup,
            goToPreviousHeaderState,
        } = this.props;

        hideActiveOverlay();
        goToPreviousHeaderState();
        resetHideActivePopup();
    }

    containerFunctions = {
        onFormSubmit: this.onFormSubmit.bind(this),
    };

    onFormSubmit(fields) {
        const { handlePriceDropSubscribeAlertInStock, setLoadingState } = this.props;
        setLoadingState();

        handlePriceDropSubscribeAlertInStock(fields).then( 
            () => {
                setTimeout(
                    this.closeOverly(), 
                100)
            });
    }

    containerProps() {
        const { isLoading, showNotification } = this.props;
        return { isLoading, showNotification };
    }

    render() {
        return (
            <ProductAlertsPopup
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAlertsPopupContainer);