import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from "react-redux";
import ConfigurationSelect from './ConfigurationSelect.component';

/** @namespace Component/ConfigurationSelect/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    // bundlerItems: state.LinkedProductsReducer.linkedProducts.bundler
});
/** @namespace Component/ConfigurationSelect/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
})

/** @namespace Component/ConfigurationSelect/Container */
class ConfigurationSelectContainer extends PureComponent {
    static propTypes = {
        handleSelect: PropTypes.func.isRequired,
        product: PropTypes.object.isRequired,
        selectOptions: PropTypes.array.isRequired,
        selectedOption: PropTypes.object.isRequired,
        bundlerItems: PropTypes.array.isRequired
    };

    state = {
        isOpened: false,
    };

    containerFunctions = {
        onSelectOptionsClick: this.onSelectOptionsClick.bind(this),
        onSelectOptionsOutsideClick: this.onSelectOptionsOutsideClick.bind(this)
    };

    onSelectOptionsClick() {
        const { isOpened } = this.state;

        this.setState({ isOpened: !isOpened });
    }

    onSelectOptionsOutsideClick() {
        this.setState({ isOpened: false });
    }


    render() {
        return (
            <ConfigurationSelect
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
            />
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationSelectContainer);
