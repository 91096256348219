import {
    WEBSITE_SWITCH
} from './WebSwitch.action';
import BrowserDatabase from "SourceUtil/BrowserDatabase/BrowserDatabase";

export const getInitialState = () => ({
    isFashion: BrowserDatabase.getItem(WEBSITE_SWITCH) || false,
});


export const switchWebsiteReducer = (
    state = getInitialState(),
    action
) => {
    
    switch (action.type) {
        case WEBSITE_SWITCH:
            const { isFashion } = action;
            BrowserDatabase.setItem(
                isFashion,
                WEBSITE_SWITCH
            );
            return {
                ...state,
                isFashion
            };

        default:
            return state;
    }
};

export default switchWebsiteReducer;