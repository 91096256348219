import { ImageContainer as SourceImageContainer } from 'SourceComponent/Image/Image.container';

/** @namespace Component/Image/Container */
export class ImageContainer extends SourceImageContainer {
    static defaultProps = {
        src: '',
        alt: '',
        ratio: 'square',
        mix: {},
        height: '',
        width: '',
        isPlaceholder: false,
        style: {},
        title: null,
        className: '',
        imageRef: () => {},
        isPlain: false,
        dataPromoid: null
    };

    containerProps() {
        const {
          dataPromoid,
          alt
        } = this.props;

        return {
            ...super.containerProps(),
            dataPromoid: dataPromoid
        };
    }
}

export default ImageContainer;
