import Cookies from "universal-cookie";
import {BOXNOW_COOKIE, BOX_NOW_CODE} from "./CheckoutDeliveryOption.component.plugin";

const validatePlaceOrderSubmission = (args, callback, instance) => {
    const [paymentMethod, showInfo, selectedShippingMethod] = args;
    const { isVirtual } = instance.props;
    const { method_code } = selectedShippingMethod;
    if (method_code === BOX_NOW_CODE) {
        const cookies = new Cookies();
        if (!cookies.get(BOXNOW_COOKIE) && !isVirtual) {
            showInfo(__('Please select box now locker.'));
            document.getElementById('boxnowframe').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            return false;
        }
    }
    return callback.apply(instance, args)
}
export const config = {
    'Route/Checkout/Component': {
        'member-function': {
            validatePlaceOrderSubmission
        }
    }
};

export default config;
