import PropTypes from 'prop-types';
import { removeRichData } from '../store/RichData/RichData.action';

export const RichDataDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
    "../store/RichData/RichData.dispatcher"
);

export class CmsPageContainerPlugin {
    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            addRichData: (options) =>
                RichDataDispatcher.then(({ default: dispatcher }) =>
                    dispatcher.addRichdata(options, dispatch)
                ),
            removeRichdata: (type, isExist) =>
                dispatch(removeRichData(type, isExist)),
        };
    };

    isExist = () => {
        if (document.getElementById("faq")) {
            return true;
        }
        return false;
    };

    mountRichData = (instance, faq) => {
        const { addRichData } = instance.props;

        const options = {
            name: "faq",
            isExist: this.isExist(),
            faq: faq
        };
        addRichData(options);
    };

    unMountRichData = (instance) => {
        const { removeRichdata } = instance.props;
        removeRichdata(
            "faq",
            this.isExist(),
        );
    }

    componentWillUnmount = (args, callback, instance) => {
        this.unMountRichData(instance);
        return callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const { page: { identifier = "" }, isLoading } = instance.state;
        let faq = [];
        if (!isLoading && identifier === "sychnes-erotiseis" || identifier === "frequently-asked-questions") {
            let id = 1;
            while (document.getElementById(`faq-q${id}`) || id < 100) {
                //get all elements with `faq-q${number}` `faq-a${number}` remove internal elements and pair them as question/answer and pass them to mount richdata
                if (document.getElementById(`faq-q${id}`) && document.getElementById(`faq-a${id}`)) {
                    let qaObj = {
                        question: document.getElementById(`faq-q${id}`).innerHTML?.replace(/\n/g, ' ').replace(/&nbsp;/g, ' '),
                        answer: document.getElementById(`faq-a${id}`).innerHTML?.replace(/<[^>]+>/g, '').replace(/\n/g, ' ').replace(/&nbsp;/g, ' ')
                    }
                    faq.push(qaObj);
                }
                id++;
            }
            this.mountRichData(instance, faq)
        }
        return callback(...args);
    }
}
// propTypes, defaultProps,
export const { componentWillUnmount, componentDidUpdate, mapDispatchToProps } = new CmsPageContainerPlugin();

export default {
    "Route/CmsPage/Container/mapDispatchToProps": {
        function: [
            {
                position: 100,
                implementation: mapDispatchToProps,
            },
        ],
    },
    "Route/CmsPage/Container": {
        "member-function": {
            componentWillUnmount: [
                {
                    position: 100,
                    implementation: componentWillUnmount,
                },
            ],
            componentDidUpdate: [
                {
                    position: 100,
                    implementation: componentDidUpdate,
                },
            ]
        }
    },
};