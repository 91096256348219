/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ContactForm from 'Component/ContactForm';
import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';
import Link from 'SourceComponent/Link';

import { PRODUCT_HELP_POPUP_ID } from './ProductHelpPopup.config';

import './ProductHelpPopup.style';

/** @namespace Component/ProductHelpPopup/Component */
export class ProductHelpPopup extends PureComponent {
    state = {};

    static propTypes = {
        openProductHelpPopup: PropTypes.func.isRequired
    };

    renderContent() {
        return (
            <div className="ProductHelpPopup-cols">
                <div className="ProductHelpPopup-col">
                    <CmsBlock identifier={ 'product_help_popup_image_block' } />
                </div>
                <div className="ProductHelpPopup-col">
                    <div className="ProductHelpPopup-content">
                        <CmsBlock identifier={ 'product_help_popup_info_block' } />
                        <ContactForm popupClose={true}/>
                    </div>
                </div>
            </div>
        );
    }

    renderPopupContent() {
        return (
            <Popup
                id={ PRODUCT_HELP_POPUP_ID }
                clickOutside={ false }
                mix={ { block: 'ProductHelpPopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }

    renderPopupButton() {
        const { openProductHelpPopup, showHelpButton } = this.props;

        if(!showHelpButton) {
            return null
        }

        return (
            <Link to={ '/' } block="ProductActions" elem="HelpLink" onClick={ openProductHelpPopup }>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 5H4C2.9 5 2 5.80526 2 6.78947V17.5263C2 18.5105 2.9 19.3158 4 19.3158H13L17 22L18 19.3158H20.5C21.6 19.3158 22 18.5105 22 17.5263V6.78947C22 5.80526 21.1 5 20 5Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    <line x1="6" y1="9.5" x2="18" y2="9.5" stroke="black"/>
                    <line x1="6" y1="12.5" x2="18" y2="12.5" stroke="black"/>
                    <line x1="6" y1="15.5" x2="15" y2="15.5" stroke="black"/>
                </svg>
                <span>{ __('Χρειάζεστε βοήθεια ή συμβουλή;') } </span>
            </Link>
        );
    }

    render() {
        return (
            <>
                { this.renderPopupButton() }
                { this.renderPopupContent() }
            </>
        );
    }
}

export default ProductHelpPopup;
