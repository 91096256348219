import { ProductList as SourceProductList } from 'SourceComponent/ProductList/ProductList.component';
import CategoryPagination from 'Component/CategoryPagination';
import getStore from 'Util/Store';
import './Loader.scss';

/**
 * List of category products
 * @class ProductList
 * @namespace Component/ProductList/Component
 */
export class ProductList extends SourceProductList {

    // renderPage(props = {}) {
    //     const {
    //         isInfiniteLoaderEnabled,
    //         loadPage,
    //         isLoading,
    //         isVisible,
    //         mix,
    //         sliderOptions,
    //         categoryPageCmsBlockListing,
    //         isSampleProducts,
    //         isFreeGifts,
    //         device,
    //         event,
    //         currentPage
    //     } = this.props;

    //     const {
    //         items,
    //         keys,
    //         pageNumber,
    //         selectedFilters,
    //         wrapperRef,
    //         key
    //     } = this._processProps(props);

    //     return (
    //         <ProductListPage
    //           event= { event}
    //           key={ currentPage }
    //           isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
    //           updatePages={ loadPage }
    //           isLoading={ isLoading }
    //           isVisible={ isVisible }
    //           mix={ mix }
    //           items={ items }
    //           keys={ keys }
    //           pageNumber={ pageNumber }
    //           selectedFilters={ selectedFilters }
    //           wrapperRef={ wrapperRef }
    //           sliderOptions = { sliderOptions }
    //           device={device}
    //           categoryPageCmsBlockListing={ categoryPageCmsBlockListing }
    //           isSampleProducts={ isSampleProducts }
    //           isFreeGifts={ isFreeGifts }
    //         />
    //     );
    // }

    componentDidUpdate(prevProps) {
        const { isMobileLoadMore } = this.props;
        super.componentDidUpdate(prevProps)
        if (isMobileLoadMore) {
            this.observePageChange();
        }
    }

    observePageChange() {
        const { updatePage, isLoading, isWidget } = this.props;

        if (isLoading) {
            this.pagesIntersecting = [];
        }

        if (!this.observer && 'IntersectionObserver' in window) {
            const threshold = this._getThreshold();

            this.observer = new IntersectionObserver((entries) => {
                const { currentPage } = this.props;

                entries.forEach(({ target, isIntersecting }) => {
                    const page = +Object.keys(this.nodes).find((node) => this.nodes[node] === target);
                    const index = this.pagesIntersecting.indexOf(page);

                    if (isIntersecting && index === -1 && !isNaN(page)) {
                        this.pagesIntersecting.push(page);
                    }

                    if (!isIntersecting && index > -1) {
                        this.pagesIntersecting.splice(index, 1);
                    }
                });

                const minPage = Math.min(...this.pagesIntersecting);
                if (minPage < Infinity && minPage !== currentPage && !isWidget) {
                    updatePage(minPage);
                }
            }, {
                rootMargin: '0px',
                threshold
            });
        }

        this.updateObserver();
    }

    onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }
    sortBySku(objects, skus) {
        skus = skus.filter(this.onlyUnique).filter((sku) => objects.some((item) => item.sku === sku));
        const skuIndexMap = skus.reduce((map, sku, index) => {
            map[sku] = index;
            return map;
        }, {});
        objects = objects.filter((item) => skus.includes(item.sku));

        objects.sort((a, b) => {
            const skuAIndex = skuIndexMap[a.sku];
            const skuBIndex = skuIndexMap[b.sku];
            return skuAIndex - skuBIndex;
        });

        return objects.filter((item) => skus.includes(item.sku));
    }


    renderPages() {
        const {
            pages,
            isVisible,
            isLoading,
            isInfiniteLoaderEnabled,
            isBrandSteps,
            stepsSkus,
        } = this.props;

        if (isLoading) {
            return this.renderPage();
        }

        if (isBrandSteps && stepsSkus && pages[1]) {
            const page = this.sortBySku(pages[1], stepsSkus.split(','));
            return [[1, page]].map(this.renderProductPage)
        }

        const pageRenders = Object.entries(pages).map(this.renderProductPage);

        if (isVisible && isInfiniteLoaderEnabled) { // add placeholders to the end of pages if needed
            const key = Math.max(Object.keys(pages)) + 1; // the key should match next page key
            pageRenders.push(this.renderPage({ key }));
        }

        return pageRenders;
    }
    renderNoProducts() {
        return (
            <div block="ProductList">
                <div
                    block="ProductList"
                    elem="ProductsMissing"
                >
                    <h2><span>{__('We are sorry!')}</span></h2>
                    <h3>{__('There were no products found matching your request.')}</h3>
                    <p>{__('Please, try removing selected filters and try again!')}</p>
                </div>
            </div>
        );
    }


    renderTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <h2><span>{title}</span></h2>
        );
    }

    renderPagination() {
        const {
            isLoading,
            totalPages,
            requestPage,
            isPaginationEnabled,
            device,
            isMobileLoadMore
        } = this.props;

        if (!isPaginationEnabled) {
            return null;
        }

        if (device.isMobile && isMobileLoadMore)
            // passed empty div so plugins may not break
            return <div></div>;

        return (
            <CategoryPagination
                isLoading={isLoading}
                totalPages={totalPages}
                onPageSelect={requestPage}
            />
        );
    }

    renderButtonLoader() {
        return (
            <span className="loader" ></span>
        );
    }

    renderLoadButton() {
        const {
            isShowLoading,
            isInfiniteLoaderEnabled,
            loadPrevPage,
            device,
            isMobileLoadMore,
            isLoadingMobile,
            isWidget
        } = this.props;

        if (!device.isMobile || isWidget)
            return null;

        if (isMobileLoadMore) {
            if (!isShowLoading) return null;
            if (isLoadingMobile)
                return this.renderButtonLoader();
        } else {
            if (!isShowLoading || !isInfiniteLoaderEnabled) {
                return null;
            }
        }

        return (
            <a
                className='Button Button_isHollow'
                tabIndex="0"
                onKeyUp={loadPrevPage}
                onClick={loadPrevPage}
            >
                {__('Load previous')}
            </a>
        );
    }

    renderLoadMoreButton() {
        const { handleLoadMore, currentPage, totalPages, device, isMobileLoadMore, isLoadingMobile, isWidget } = this.props;
        if (!device.isMobile || isWidget)
            return null;

        if (!isMobileLoadMore)
            return null;

        if (isLoadingMobile)
            return this.renderButtonLoader();

        if (currentPage === totalPages) {
            return (
                <div className="Button Button_isHollow">
                    {__("You have seen all the products")}
                </div>
            );
        }

        return (
            <a className="Button Button_isHollow" onClick={handleLoadMore}>
                {__("Load more products")}
            </a>
        );
    }

    render() {
        const {
            totalPages,
            isLoading,
            currentPage,
            mix,
            additionalClass,
            pages
        } = this.props;

        if (!getStore().getState().ProductListReducer.isLoading && totalPages === 0 || (pages[currentPage] && Object.values(pages[currentPage])?.length == 0)) {
            if (additionalClass === 'CartPage-GiftBox_choose_card')
                return null

            if (mix && mix.block && mix.block === 'ProductListWidget')
                return null
            return this.renderNoProducts();
        }

        return (
            <div
                block="ProductList"
                mods={{ isLoading }}
                mix={mix}
            >
                {this.renderTitle()}
                {this.renderLoadButton()}
                {this.renderPages()}
                {this.renderLoadMoreButton()}
                {this.renderPagination()}
            </div>
        );
    }
}

export default ProductList;
