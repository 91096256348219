/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import { CartItemPriceContainer as SourceCartItemPriceContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/CartItemPrice/CartItemPrice.container';

/** @namespace Component/CartItemPrice/Container */
export class CartItemPriceContainer extends SourceCartItemPriceContainer {

    containerProps = () => {
        const {
            getCartItemPrice,
            getCartItemSubPrice,
            currency_code,
            mix,
            actualProductPrice,
            unitLabel,
            unitCalcModifier,
            ...rest
        } = this.props;

        return {
            currency_code,
            mix,
            actualProductPrice,
            price: getCartItemPrice(rest),
            subPrice: getCartItemSubPrice(rest),
            unitCalcModifier,
            unitLabel
        };
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemPriceContainer);
