/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {lazy, PureComponent} from 'react';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from './StarIcon.config';

import './StarIcon.style';

/** @namespace Component/StarIcon/Component */
export class AddIcon extends PureComponent {
    static propTypes = {
        starFill: PropTypes.oneOf([STAR_HALF_FULL, STAR_EMPTY, STAR_FULL]).isRequired
    };

    getStarPath() {
        const { starFill } = this.props;

        if (starFill === STAR_EMPTY) {
            return (
                <UniversalIcon
                    src={media('star_empty.svg', WYSIWYG_MEDIA)}
                    alt="StarIcon"
                    className="StarIcon"
                />
            );
        }

        if (starFill === STAR_HALF_FULL) {
            return (
                <UniversalIcon
                    src={media('star_half_full.svg', WYSIWYG_MEDIA)}
                    alt="StarIcon"
                    className="StarIcon"
                />
            );
        }

        if (starFill === STAR_FULL) {
            return (
                <UniversalIcon
                    src={media('star_full.svg', WYSIWYG_MEDIA)}
                    alt="StarIcon"
                    className="StarIcon"
                />
            );
        }

        return null;
    }

    render() {
        return this.getStarPath()
    }
}

export default AddIcon;
