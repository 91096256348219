import {
    PIM_FASHION_SIZE
} from 'Component/ProductAttributeSet/ProductAttributeSet.config';

const fashionSizeSet = [
    PIM_FASHION_SIZE
];
import ProductSizeGuidePopup from "../component/ProductSizeGuidePopup";
class ProductActionComponentPlugin {
    renderAddToCartActionBlock = (args,callback,instance) => {
        const { product } = instance.props;
        return [
            callback.apply(instance,args),
            <ProductSizeGuidePopup
                showSizeGuideButton={fashionSizeSet.includes(product.attribute_set_id)}
                product={product}
            />
        ];
    }

}
const { renderAddToCartActionBlock } = new ProductActionComponentPlugin();
export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderAddToCartActionBlock
        }
    }
}
