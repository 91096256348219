import { cloneElement } from 'react';
import { Switch } from 'react-router-dom';
import Loader from 'SourceComponent/Loader';

import {
    TYPE_CUSTOM,
    SWITCH_ITEMS_CUSTOM as SWITCH_ITEMS
} from '../utils/urlRewrite.config';

class UrlRewritiesPlugin {

    // SWITCH_ITEMS_CUSTOM = [
    // ]

    getSortedItems(type) {
        let items = type.sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');
                    return false;
                }

                return true;
            }
        );
        return items;
    }

    renderItemsOfType(type, instance) {
        return getSortedItems(type, instance)
            .map(({ position, component }) => cloneElement(component, { key: position }));
    }

    renderCustomRouteDefault() {
        return (
            <main >
                <Loader isLoading />
            </main>
        );
    }

    renderMainItems(instance) {
        const { SWITCH_ITEMS_CUSTOM: type } = instance;

        return (
            <Switch>
                {renderItemsOfType(type, instance)}
            </Switch>
        );
    }

    renderDefaultPage(args, callback, instance) {
        const { type } = instance.props;

        switch (type) {
            case TYPE_CUSTOM:
                return renderMainItems(instance);
            default:
                return callback(args, instance)
        }
    }

    getProps(args, callback, instance) {
        const {
            urlRewrite: {
                redirect_info: {
                    redirect_from = '',
                    redirect_code_previous = ''
                } = {}
            }
        } = instance.props;

        return {
            ...callback(...args),
            redirect_code_previous,
            redirect_from: window.location.pathname
        };
    }

    requestUrlRewrite(args, callback, instance) {
        const { requestUrlRewrite } = instance.props;
        if(location.pathname !== '/') {
            return requestUrlRewrite(location.pathname);
        }
    }

}

const { 
    getProps, 
    renderDefaultPage, 
    renderMainItems,
    getSortedItems,
    renderCustomRouteDefault,
    renderItemsOfType,
    requestUrlRewrite,
    SWITCH_ITEMS_CUSTOM 
} = new UrlRewritiesPlugin()

export default {
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderCustomRouteDefault,
            renderDefaultPage,
            renderItemsOfType,
            getSortedItems
        },
        // 'member-property': {
        //     SWITCH_ITEMS_CUSTOM: () => SWITCH_ITEMS_CUSTOM
        // }
    },
    'Route/UrlRewrites/Container': {
        'member-function': {
            getProps,
            // requestUrlRewrite
        }
    }
};