import { cloneElement, createRef } from 'react';

import Recaptcha from '../component/Recaptcha';
import { RECAPTCHA_TYPE_INVISIBLE, RECAPTCHA_TYPE_RECAPTCHA, RECAPTCHA_TYPE_RECAPTCHA_V3 } from '../component/Recaptcha/Recaptcha.config';
import { getCaptchaKey, getRecaptchaConfigs } from '../utils/recaptchaConfig';

export class MyAccountSignInPlugin {
    recaptchaRef = createRef();

    renderSignInForm = (args, callback, instance) => {
        const originalDetails = callback.apply(instance, args);
        const { recaptcha_for_customer_login } = getRecaptchaConfigs();
        if (recaptcha_for_customer_login) {
            const { props: { children } } = originalDetails;
            return cloneElement(
                originalDetails,
                {},
                [...children, (<Recaptcha
                  recaptchaType={ recaptcha_for_customer_login }
                  recaptcha_public_key={ getCaptchaKey(recaptcha_for_customer_login) }
                  recaptchaRef={ this.recaptchaRef }
                />)]
            );
        }

        return originalDetails;
    };

    onSignInSuccess = async (args, callback, instance) => {
        const { recaptcha_for_customer_login } = getRecaptchaConfigs();
        switch (recaptcha_for_customer_login) {
        case RECAPTCHA_TYPE_INVISIBLE:
            this.recaptchaRef.current.reset();
            return this.recaptchaRef.current.executeAsync().then(() => callback.apply(instance, args).then(async () => {
                window.recaptchaValidation = '';
            }));
        case RECAPTCHA_TYPE_RECAPTCHA:
            return callback.apply(instance, args).then(() => {
                if (window.grecaptcha) {
                    window.grecaptcha.reset();
                }
                window.recaptchaValidation = '';
            });
        case RECAPTCHA_TYPE_RECAPTCHA_V3:
            return callback.apply(instance, args);
        default:
            return callback.apply(instance, args);
        }
    };
}

const { renderSignInForm, onSignInSuccess } = new MyAccountSignInPlugin();
export const config = {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            renderSignInForm
        }
    },
    'Component/MyAccountSignIn/Container': {
        'member-function': {
            onSignInSuccess
        }
    }
};

export default config;
