import { PureComponent } from 'react';
import ContentWrapper from 'Component/ContentWrapper';
import ProductCard from "Component/ProductCard";
import { formatCurrency, roundPrice } from 'Util/Price';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import {
    FIXED,
    PERCENTAGE
} from "../BundlerSection/BundlerSection.conf";
import { IN_STOCK, OUT_OF_STOCK } from 'Component/ProductCard/ProductCard.config';
import { CONFIGURABLE } from 'Util/Product';
import "./BundlerItem.style.scss"

/**
 * BundlerItem
 * @class BundlerItem
 * @namespace Component/BundlerItem/Component
 */
class BundlerItem extends PureComponent {
    renderMainProduct() {
        const { mainProduct, mainProduct: { id }, configurableProduct, bundlerItems } = this.props;
        return (
            <>
                <ProductCard
                    block="ProductLinks"
                    elem="Card"
                    forBundler={true}
                    product={mainProduct}
                    key={id}
                    mainProduct={configurableProduct}
                    bundlerItems={bundlerItems}
                />
            </>
        );
    }

    renderSelectedProduct(item = null) {
        const { handleSelect, selectedOption, variant, bundlerItems } = this.props;
        if (!item) return null;

        if (item.isConfigurableBundler) {
            return (
                <ProductCard
                    block="ProductLinks"
                    elem="Card"
                    forBundler={true}
                    variations={item.variants}
                    product={item}
                    key={item.id}
                    bundlerOptions={item.applied_products}
                    handleSelect={handleSelect}
                    selectedOption={selectedOption}
                    bundlerItems={bundlerItems}
                />
            )
        }

        return (
            <ProductCard
                block="ProductLinks"
                elem="Card"
                forBundler={true}
                variations={item.variants}
                product={variant ?? item}
                key={item.id}
                bundlerOptions={item.applied_products}
                handleSelect={handleSelect}
                selectedOption={selectedOption}
                bundlerItems={bundlerItems}
            />
        )
    }

    renderTitle() {
        return (
            <h2 className="ProductLinks-Title">
                <span>{__('Combine it with')}</span>
            </h2>
        )
    }

    renderAddToCart() {
        const {
            buttonClick,
            item,
            selectedOption,
            mainProduct,
            isLoading
        } = this.props;
        const isMainDisabled = mainProduct.type_id === CONFIGURABLE
        const isSelectionDisabled = (item.isConfigurableBundler && !Object.keys(selectedOption).length > 0)
        const isDisabled = isMainDisabled || isSelectionDisabled;
        const isMainOutOfStock = mainProduct.type_id !== CONFIGURABLE && mainProduct.stock_status !== IN_STOCK;

        const productOrVariant = item.type_id === CONFIGURABLE ? !item.isConfigurableBundler ? item.variants.find(v => v.sku === item.applied_products[0].sku) : item.variants.find(v => v.sku === selectedOption.sku) : item;
        const isSelectionOutOfStock = ((item.isConfigurableBundler && productOrVariant?.stock_status !== IN_STOCK) && Object.keys(selectedOption).length > 0) ||
            (!item.isConfigurableBundler && productOrVariant.stock_status !== IN_STOCK);

        const isOutOfStockDisabled = isMainOutOfStock || isSelectionOutOfStock;
        return (
            <div className="deal__buttons">
                < button onClick={(e) => isDisabled ? isMainDisabled && window.scrollTo({ top: 0, behavior: "smooth" }) : buttonClick(e, item, selectedOption)
                } className="Button deal__button" disabled={isLoading || isOutOfStockDisabled}>
                    <div>
                        {isDisabled ?
                            <span>{__('Please select product options!')}</span>
                            :
                            <>
                                {isOutOfStockDisabled ?
                                    <span>{__('Out of Stock')}</span>
                                    :
                                    <span>{__('Add to cart')}</span>
                                }
                            </>
                        }
                    </div>
                </button >
            </div >
        )
    }

    renderPricing() {
        const {
            totalPrice,
            currency,
            discountedPrice = 0,
        } = this.props;

        const priceString = formatCurrency(currency);
        const isOfferDisplayed = roundPrice(totalPrice - discountedPrice) <= 0.1 || discountedPrice === totalPrice || discountedPrice === 0;
        let finalPrice = totalPrice - discountedPrice
        return (
            <div className="deal__wrapper">
                {!isOfferDisplayed ? <>
                    <div className="deal__prices" data-label={__('Total:')}>
                        <div className="deal__price deal__price_old">
                            {`${priceString}${roundPrice(totalPrice)}`}
                        </div>
                        <div className="deal__price">
                            {`${priceString}${roundPrice(discountedPrice)}`}
                        </div>
                    </div>
                    <div className="deal__text">
                        <span>{__("You Save:")}</span>
                        <span>
                            {`${priceString}${roundPrice(finalPrice)}`}
                        </span>
                    </div>
                </> :
                    <>
                        <div className="deal__prices" data-label={__('Total:')}>
                            <div className="deal__price">
                                {`${priceString}${roundPrice(discountedPrice)}`}
                            </div>
                        </div>

                    </>}
            </div>
        )
    }

    render() {
        const {
            item,
        } = this.props;

        return (
            <ContentWrapper
                mix={{ block: 'BundleItem' }}
                wrapperMix={{ block: 'BundleItem', elem: 'Wrapper' }}
                label={__('Combine products')}
            >
                <div className="deal" id="section__deal">
                    <div className="deal__products" id="deal__products">
                        <div id="deal__products_top" className="deal__products_top">
                            {this.renderMainProduct()}
                            {this.renderSelectedProduct(item)}

                        </div>
                    </div>
                    <div className="deal__content" id="deal__content">
                        {this.renderPricing()}
                        {this.renderAddToCart()}
                    </div>
                </div>
            </ContentWrapper>
        );
    }

}

export default BundlerItem;
