export const BLOG_URL = 'BLOG_URL';
export const BLOG_POST_URL = 'BLOG_POST_URL';
export const BLOG_CATEGORY_URL = 'BLOG_CATEGORY_URL';
export const BLOG_TAG_URL = 'BLOG_TAG_URL';
import { lazy } from 'react';
export const PostsDetails = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ '../route/PostsDetails'));
export const PostsListing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ '../route/PostsListing'));
export const CategoryListing = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ '../route/CategoryListing'));

const renderContent = (args, callback, instance) => {
    const { props, type } = instance.props;
    switch (type) {
        case BLOG_POST_URL:
            return <PostsDetails { ...props } />;
        case BLOG_URL:
            return <PostsListing { ...props } />;
        case BLOG_CATEGORY_URL:
            return <CategoryListing { ...props } />;
        default:
            return callback.apply(instance, args);
    }
}

export const config = {
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent: [
                {
                    position:100,
                    implementation: renderContent
                }
            ]
        }
    }
};

export default config;
