export const BUNDLER_PRODUCTS = 'bundler';
import { UPDATE_LINKED_PRODUCTS } from 'Store/LinkedProducts/LinkedProducts.action';

const getInitialState = (args, callback, instance) => {
    let result = callback.apply(instance,args);
    return {
        linkedProducts :{
            ...result.linkedProducts,
            bundler: {}
        }
    };
}


export const LinkedProductsReducer = (args, callback, instance) => {
    let result = callback.apply(instance,args);
    const action  = args[1];
    const { type } = action;
    if (type !== UPDATE_LINKED_PRODUCTS) {
        return result;
    }
    const {
        linkedProducts: {
            [BUNDLER_PRODUCTS]: bundler_products,
        }
    } = action;
    result.linkedProducts[BUNDLER_PRODUCTS] = bundler_products;
    
    return result;
}



export default {
    'Store/LinkedProducts/Reducer': {
        function: [
            {
                position: 102,
                implementation: LinkedProductsReducer
            }
        ]
    },
    'Store/LinkedProducts/Reducer/getInitialState': {
        'function': getInitialState
    },
}
