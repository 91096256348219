/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_CATEGORIES, UPDATE_CATEGORY_FILTERS } from './Categories.action';

export const emptyCategories = {
    items: [],
    count: 0
};

export const emptyFilter = {
    items: []
};

export const emptyCategoryFilterOptionsArrays = {

};

/** @namespace ScandiPWA/Blog/Store/Categories/Reducer/getInitialState */
export const getInitialState = () => ({
    categories: emptyCategories,
    categoryFilter: emptyFilter,
    categoryFilterOptionsArrays : emptyCategoryFilterOptionsArrays
});

/** @namespace ScandiPWA/Blog/Store/Categories/Reducer/categoriesReducer */
export const CategoriesReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_CATEGORIES:
        const { categories: { items, total_count: count }, isLoaded } = action;

        return {
            ...state,
            categories: {
                items,
                count
            },
            isLoaded
        };
    case UPDATE_CATEGORY_FILTERS:
        const { categoryFilter: { items : i }, isLoaded : l } = action;
        let categoryFilterOptionsArrays = i.reduce(
            (aggregation, attribute) => {
                const { options, fieldCode } = attribute;
                aggregation[fieldCode] = {};
                options.map(({value, label})=> { aggregation[fieldCode][String(value)] = label});
                return aggregation;
            } ,{});

        return {
            ...state,
            categoryFilter: {
                items : i
            },
            categoryFilterOptionsArrays,
            isLoaded : l
        };
    default:
        return state;
    }
};

export default CategoriesReducer;
