export const EnhacnedRegistrationDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/EnhacnedRegistration/EnhacnedRegistration.dispatcher'
);
import { filterLabel } from '../utils/config/index';
import Loader from 'SourceComponent/Loader';
import ExpandableContent from 'SourceComponent/ExpandableContent';
import CmsBlock from 'SourceComponent/CmsBlock';
import { appendWithStoreCode } from 'SourceUtil/Url';
import {
    ENCHANCE_REGISTRATION,
    PERSONAL_INFORMATION,
    ADDITIONAL_INFORMATION
} from '../utils/config';

class MyAccountDashboardPlugin {

    state () {
        return {
            fields: {}
        }
    }

    componentDidMount(args, callback, instance) {
        const { requestCustomerData, customAttributeMetadata } = instance.props;
        requestCustomerData();
    }

    componentDidUpdate(args, callback, instance) { 
        const { customer, customAttributeMetadata } = instance.props;
        const { fields: originalFields } = instance.state;
        if(customer && customer.custom_attributes) {
            const { custom_attributes } = customer;
            const fields = getFieldsData(custom_attributes)
            
            if(Object.keys(fields).length > 0 
            && !Object.keys(originalFields).length > 0 
            && customAttributeMetadata 
            && customAttributeMetadata.items) {
            instance.setState({ 
                fields
            });
         }
        }
    }

     mapStateToProps(args, callback, instance) {
        const { getCustomData } = args[0];
        return {
            ...callback.apply(instance, args),
            customAttributeMetadata: getCustomData.customAttributeMetadata
        }
    };

    getFieldsData(custom_attributes){
        var customData = {}
        if (custom_attributes && Object.values(custom_attributes).length > 0) {
            custom_attributes.map(({ attribute_code, value }) => {
                if (attribute_code === 'skin_concerns'
                    || attribute_code === 'favorite_stores'
                    || attribute_code === 'favorite_brands') {
                    const isArray = value.indexOf(',');
                    if (isArray !== -1) {
                        const toArray = value.split(',').map((val) => val);
                        customData[attribute_code] = {
                            value: toArray
                        };
                    } else {
                        customData[attribute_code] = {
                            value: [value]
                        };
                    }
                } else {
                    customData[attribute_code] = {
                        value
                    };
                }
            });
        }
        
        return customData;
    }
    
    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            requestCustomerData: () => EnhacnedRegistrationDispatcher.then(
                ({ default: dispatcher }) => dispatcher.getCustomerAttributeFields(dispatch)
            )
        }
    }
    
    containerProps(args, callback, instance) {
        const { customer, customAttributeMetadata } = instance.props;
        const { fields } = instance.state;

        return {
            ...callback.apply(instance, args),
            customer, 
            fields,
            customAttributeMetadata
        };
    }

    renderAddtionalDetails(instance) {
        const { customAttributeMetadata, fields, device } = instance.props
        // if(Object.keys(fields).length === 0)
        //     return null;

        if(!Object.keys(fields).length > 1) {
            return (
                <div className="MyAccount-EnhanchedRegistration_dashboard">
                    <CmsBlock identifier={ 'account_enhanced_registration_welcome' } />
                </div>
            );
        }

        if(device.isMobile){
            return (
                <div className="MyAccount-EnhanchedRegistration_dashboard">
                    <ExpandableContent
                        mix={ { block: 'scandi_accordian', elem: 'Content' } }
                        heading="Επιπλέον χαρακτηριστικά<span>Επεξεργαστείτε τα επιπλέον προσωπικά χαρακτηριστικά σας</span>"
                    >
                        <ul block="MyAccount-EnhanchedRegistration_dashboard" elem="Details">
                            {(customAttributeMetadata && customAttributeMetadata.items) ?
                                customAttributeMetadata.items.map(({ attribute_code, attribute_label, attribute_options }, _) => {
                                    const attri = fields[attribute_code];
                                    if (attri) {
                                        const { value } = attri;
                                        return (
                                            <li block="MyAccount-EnhanchedRegistration_dashboard" elem="List">
                                                <label> { attribute_label } </label>
                                                <span> {filterLabel(value, attribute_options)} </span>
                                            </li>
                                        )
                                    }
                                })
                                : null}
                        </ul>
                        <div>
                            <a href={appendWithStoreCode(`/${ENCHANCE_REGISTRATION}/${PERSONAL_INFORMATION}`)} className="MyAccount-EnhanchedRegistration_dashboard-Button Button Button_isHollow">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.89853 17.0926L3.89853 17.0926L4.73563 13.0927C4.75788 12.9866 4.81241 12.889 4.89294 12.812L4.8584 12.7759L4.89294 12.812L14.9395 3.21182C15.1653 2.99606 15.5324 2.99606 15.7582 3.21182L19.1072 6.41198C19.3317 6.62651 19.3318 6.97352 19.1072 7.18825L9.0607 16.7882C8.98015 16.8652 8.87741 16.9178 8.765 16.9394C8.76499 16.9394 8.76499 16.9394 8.76499 16.9394L4.57905 17.7394C4.171 17.8174 3.81862 17.4738 3.89853 17.0926ZM15.3833 4.41263L15.3488 4.37962L15.3142 4.41263L5.8464 13.4592L5.83518 13.47L5.832 13.4851L5.21662 16.4243L5.20125 16.4977L5.27494 16.4837L8.3508 15.8964L8.3653 15.8937L8.37597 15.8835L17.8442 6.83646L17.882 6.80031L17.8442 6.76416L15.3833 4.41263ZM20.3722 19.85C20.6935 19.85 20.95 20.0984 20.95 20.4001C20.95 20.6762 20.7354 20.9074 20.4525 20.9448L20.3706 20.95H3.62798C3.30669 20.95 3.05 20.7017 3.05 20.4001C3.05 20.124 3.26482 19.8926 3.54766 19.8554L3.6296 19.85L20.3722 19.85Z" fill="black" stroke="black" strokeWidth="0.1"/>
                                </svg>
                                { __('επεξεργασια χαρακτηριστικων') }
                            </a>
                        </div>
                    </ExpandableContent>
                </div>
            );
        }

        return (
            <div className="MyAccount-EnhanchedRegistration_dashboard">
                <div className="MyAccount-EnhanchedRegistration_welcome_page_title">Επιπλέον χαρακτηριστικά</div>
                <ul block="MyAccount-EnhanchedRegistration_dashboard" elem="Details">
                    {(customAttributeMetadata && customAttributeMetadata.items) ?
                        customAttributeMetadata.items.map(({ attribute_code, attribute_label, attribute_options }, _) => {
                            const attri = fields[attribute_code];
                            if (attri) {
                                const { value } = attri;
                                return (
                                    <li block="MyAccount-EnhanchedRegistration_dashboard" elem="List">
                                        <label> {attribute_label} </label>
                                        <span> {filterLabel(value, attribute_options)} </span>
                                    </li>
                                )
                            }
                        })
                        : null}
                </ul>
                <div>
                    <a href={appendWithStoreCode(`/${ENCHANCE_REGISTRATION}/${PERSONAL_INFORMATION}`)} className="MyAccount-EnhanchedRegistration_dashboard-Button Button Button_isHollow">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.89853 17.0926L3.89853 17.0926L4.73563 13.0927C4.75788 12.9866 4.81241 12.889 4.89294 12.812L4.8584 12.7759L4.89294 12.812L14.9395 3.21182C15.1653 2.99606 15.5324 2.99606 15.7582 3.21182L19.1072 6.41198C19.3317 6.62651 19.3318 6.97352 19.1072 7.18825L9.0607 16.7882C8.98015 16.8652 8.87741 16.9178 8.765 16.9394C8.76499 16.9394 8.76499 16.9394 8.76499 16.9394L4.57905 17.7394C4.171 17.8174 3.81862 17.4738 3.89853 17.0926ZM15.3833 4.41263L15.3488 4.37962L15.3142 4.41263L5.8464 13.4592L5.83518 13.47L5.832 13.4851L5.21662 16.4243L5.20125 16.4977L5.27494 16.4837L8.3508 15.8964L8.3653 15.8937L8.37597 15.8835L17.8442 6.83646L17.882 6.80031L17.8442 6.76416L15.3833 4.41263ZM20.3722 19.85C20.6935 19.85 20.95 20.0984 20.95 20.4001C20.95 20.6762 20.7354 20.9074 20.4525 20.9448L20.3706 20.95H3.62798C3.30669 20.95 3.05 20.7017 3.05 20.4001C3.05 20.124 3.26482 19.8926 3.54766 19.8554L3.6296 19.85L20.3722 19.85Z" fill="black" stroke="black" strokeWidth="0.1"/>
                        </svg>
                        { __('επεξεργασια χαρακτηριστικων') }
                    </a>
                </div>
            </div>
        );
    }

    render(args, callback, instance) {
        const { customer } =  instance.props;
        return [
        <div block="MyAccountDashboard">
            <Loader isLoading={ !Object.keys(customer).length } />
            { instance.renderCustomerTable(instance) }
            { instance.renderCustomerPopup(instance) }
            { renderAddtionalDetails(instance) }
        </div>
        ]
    };

}
const { render, getFieldsData, containerProps, componentDidMount, componentDidUpdate, mapDispatchToProps, mapStateToProps, state, renderAddtionalDetails } = new MyAccountDashboardPlugin();

export default {
    'Component/MyAccountDashboard/Component': {
        'member-function': {
            render,
        }
    },
    'Component/MyAccountDashboard/Container': {
        'member-property': {
            state
        },
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            getFieldsData,
            containerProps
        }
    },
    'Component/MyAccountDashboard/Container/mapStateToProps': { 
       function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/MyAccountDashboard/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    }
};

