const _getStoreConfigFields = (args, callback,instance) => {

    return [
        'is_guestwishlist_enable',
        ...callback.apply(instance,args)
    ]
}
export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
}