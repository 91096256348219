import { Field } from 'SourceUtil/Query';

/** @namespace GtmNew/Query/GoogleTagManager/Query/GoogleTagManagerQuery */
export class GoogleTagManagerQuery {
    _getGTMConfigurationFields = () => ([
        'enabled',
        'gtm_id',
        this.getEventsField()
    ]);

    getEventsField = () => new Field('events').addFieldList(this.getEvents());

    getEvents = () => ([
        'gtm_general_init',
        'gtm_impressions',
        'gtm_impressions_plp',
        'gtm_impressions_home',
        'gtm_impressions_search',
        'gtm_site_search',
        'gtm_product_click',
        'gtm_product_detail',
        'gtm_product_add_to_cart',
        'gtm_product_remove_from_cart',
        'gtm_purchase',
        'gtm_checkout',
        'gtm_checkout_option',
        'gtm_user_login',
        'gtm_user_register',
        'gtm_not_found',
        'gtm_category_filters',
        'gtm_additional',
        'gtm_site_search_started'
    ]);

    getGTMConfiguration = () => new Field('getGtm')
        .setAlias('gtm')
        .addFieldList(this._getGTMConfigurationFields());


    getEventLogger(data) {
        if(!data) {
            return;
        }

        return new Field('eventLogger')
            .addArgument('data', 'String!', data)
            .addField('status')
    }        
}

export default new GoogleTagManagerQuery();
