/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, {lazy, PureComponent} from 'react';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";

import './FieldPassword.style';

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

/** @namespace Component/FieldPassword/Component */
export class FieldPassword extends PureComponent {
    static propTypes = {
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) })
        ]),
        validateSeparately: PropTypes.bool,
        isSubmitted: PropTypes.bool,
        fileExtensions: PropTypes.string,
        filename: PropTypes.string,
        togglePasswordShow: PropTypes.func.isRequired,
        showPassword: PropTypes.bool.isRequired
    };

    static defaultProps = {
        formRef: () => {},
        validateSeparately: false,
        isSubmitted: false,
        fileExtensions: '',
        filename: ''
    };

    renderOnlyLabel() {
        const {
            id,
            label,
            validation
        } = this.props;
        const isRequired = validation.includes('notEmpty');

        if (!label) {
            return null;
        }

        return (
            <label
                block="Field"
                elem="Label"
                mods={ { isRequired } }
                htmlFor={ id }
            >
                { label }
            </label>
        );
    }

    render() {
        const {
            formRef,
            validateSeparately,
            isSubmitted,
            fileExtensions,
            filename,
            togglePasswordShow,
            showPassword,
            ...validProps
        } = this.props;

        return (
            <div className="has-float-label">
                <UniversalIcon
                    src={ showPassword ? media('hidepassword.png', WYSIWYG_MEDIA) : media('showpassword.png', WYSIWYG_MEDIA)}
                    alt="eyeIcon"
                    className="password_eye"
                    onClick={togglePasswordShow}
                />
                <input
                    ref={ formRef }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    { ...validProps }
                    type={showPassword ? "text" : "password"}
                />
                { this.renderOnlyLabel() }
            </div>
        );
    }
}

export default FieldPassword;
