import {Field} from 'Util/Query';
import {isSignedIn} from "Util/Auth";

/** @namespace Query/CartGiftCardQuery */

export class CartGiftCardQuery {
    getGiftCardApplyMutation(code, quoteId) {

        const mutation = new Field('giftCardCodeApply')
            .addArgument('giftcard_code', 'String!', code)
            .addField('gift_card_code_apply');

            if (!isSignedIn()) {
                mutation.addArgument('guest_cart_id', 'String', quoteId);
            }

            return mutation;

    }

    getGiftCardRemoveMutation(code, quoteId) {

        const mutation = new Field('giftCardCodeRemove')
            .addArgument('giftcard_code', 'String!', code)
            .addField('gift_card_code_remove');

        if (!isSignedIn()) {
            mutation.addArgument('guest_cart_id', 'String', quoteId);
        }

        return mutation;

    }

    getGiftCardCodeStatus(code){
        return new Field('amGiftCardAccount')
            .addArgument('input','AmGiftCardAccountInput!',code)
            .addFieldList([
                'code',
                'expiration_date',
                'status',
                this._getCurrentBalanceField()
            ]);
    }

    _getCurrentBalanceField(){
        return new Field('current_balance')
            .addField('value');
    }

}

export default new CartGiftCardQuery();
