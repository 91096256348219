import ClickOutside from 'Component/ClickOutside';
import React, { lazy } from 'react';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));
export const CloseIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/CloseIcon'
));

export const ChevronIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/ChevronIcon'
));


import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';
import {
    BEAUTY_COLOR_SET,
    PIM_BEAUTY_COLOR
} from "../ProductAttributeSet/ProductAttributeSet.config";

export class FieldSelect extends SourceFieldSelect {
    renderOption = (option) => {
        const {
            id,
            label,
            subLabel,
            swatch_data,
            outOfStock,
            isLowStock = 0,
            lowStockQty
        } = option;

        const { isProductPage, name } = this.props;

        const {
            formRef,
            value,
            skipValue,
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick,
            handleUnselectListOptionClick,
            attributeSetId,
            id: field_id,
        } = this.props;

        let selectedAdditionalClass = "";
        let genderSelectedAdditionalClass = "";

        if (formRef.current && formRef.current.value == id) {
            selectedAdditionalClass = "FieldSelect-Option_isSelceted";
        }

        if (formRef.current === undefined && value == id) {
            selectedAdditionalClass = "FieldSelect-Option_isSelceted";
        }
        const isDisabled = outOfStock ? outOfStock : false;

        if(label && label.value === "Male" && value === '1' || label && label.value === "Female" && value === '2'){
            genderSelectedAdditionalClass = 'active';
        }

        return (
            <>
            <li
                block="FieldSelect"
                elem="Option"
                mods={ { isExpanded, isDisabled } }
                key={ id }
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={ `o${id}` }
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => handleSelectListOptionClick(option) }
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={ () => handleSelectListOptionClick(option) }
                tabIndex={ isExpanded ? '0' : '-1' }
                className={selectedAdditionalClass+" "+genderSelectedAdditionalClass}
            >
                <span>
                    { ( attributeSetId === BEAUTY_COLOR_SET || attributeSetId === PIM_BEAUTY_COLOR ) && isProductPage &&  swatch_data?.type === '1' && (
                        <div class="test" style={  swatch_data?.value == '#ffffff' ? {'background-color':( swatch_data?.value.charAt(0) === '#') ?  swatch_data?.value : '#'+ swatch_data?.value, 'box-shadow':'inset 0 0 0 1px #dddddd'} : {'background-color':( swatch_data?.value.charAt(0) === '#') ?  swatch_data?.value : '#'+ swatch_data?.value}}></div>
                    ) }
                    { ( attributeSetId === BEAUTY_COLOR_SET || attributeSetId === PIM_BEAUTY_COLOR ) && isProductPage &&  swatch_data?.type === '2' && (
                        <img src={ swatch_data?.value} alt="@@"/>
                    ) }
                    { label }
                    { label && label.value === "Female" ?
                        <UniversalIcon
                            src={media('female.png', WYSIWYG_MEDIA)}
                            alt="accountBanner"
                        />
                        : null }
                    { label && label.value === "Male" ?
                        <UniversalIcon
                            src={media('male.png', WYSIWYG_MEDIA)}
                            alt="accountBanner"
                        />
                        : null }
                </span>
                { !isDisabled && isLowStock > 0 && lowStockQty > 0 ? <span block="FieldSelect" elem="lowStock">
                    { __('Only %s left!', isLowStock) }
                    </span> 
                : '' }
                { isDisabled ? <span className="isDisabled">{ __('Not available') }</span> : '' }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
            { field_id === "gender" && label && label.value === "Male" ?
                <li id="oundefined" role="menuitem" tabIndex="-1" className="FieldSelect-Option " onClick={ () => handleUnselectListOptionClick() }>
                    <span className="None">
                        { __('None') }
                        <UniversalIcon
                            src={media('male_female.png', WYSIWYG_MEDIA)}
                            alt="accountBanner"
                        />
                    </span>
                </li>
                : null }
            </>
        );
    };

    renderSelectedOptionOnly = (option) => {
        const {
            id,
            label,
            subLabel,
            swatch_data
        } = option;

        const { isProductPage, name, colorAttribute, attributeSetId } = this.props;

        const {
            formRef,
            value,
            skipValue,
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick
        } = this.props;

        let selectedAdditionalClass = "";

        if (formRef.current && formRef.current.value == id) {
            selectedAdditionalClass = "FieldSelect-Option_isSelceted";
        }

        if (formRef.current === undefined && value == id) {
            selectedAdditionalClass = "FieldSelect-Option_isSelceted";
        }

        if(!selectedAdditionalClass)
            return null;

        return (
            <span className="FieldSelect-SelectStatic">
                { (attributeSetId === BEAUTY_COLOR_SET || attributeSetId === PIM_BEAUTY_COLOR) && isProductPage &&  swatch_data?.type === '1' && (
                    <div 
                    style={  swatch_data?.value == '#ffffff' ? 
                    {'background-color':( swatch_data?.value.charAt(0) === '#') ?  
                        swatch_data?.value : '#'+ swatch_data?.value, 'box-shadow':'inset 0 0 0 1px #dddddd'} : {'background-color':
                        ( swatch_data?.value.charAt(0) === '#') ?  swatch_data?.value : '#'+ swatch_data?.value}}
                    >

                    </div>
                ) }
                { (attributeSetId === BEAUTY_COLOR_SET || attributeSetId === PIM_BEAUTY_COLOR) && isProductPage &&  swatch_data?.type === '2' && (
                    <img src={ swatch_data?.value} alt="@@"/>
                    ) }
                { label }
            </span>
        );
    };

    renderNativeSelect() {
        const {
            name,
            id,
            onChange,
            selectOptions,
            formRef,
            value,
            isDisabled,
            isSelectExpanded: isExpanded,
            autocomplete,
            skipValue,
            label,
            isProductPage,
            attributeSetId
        } = this.props;

        return (
            <>
                { (attributeSetId === BEAUTY_COLOR_SET || attributeSetId === PIM_BEAUTY_COLOR) && isProductPage && selectOptions.map(this.renderSelectedOptionOnly) }
                <select
                    block="FieldSelect"
                    elem="Select"
                    autoComplete={ autocomplete }
                    mods={ { isExpanded } }
                    ref={ formRef }
                    name={ name }
                    id={ id }
                    disabled={ isDisabled }
                    tabIndex="0"
                    value={ value || '' }
                    onChange={ onChange }
                    data-skip-value={ skipValue }
                    aria-label={ __('Select drop-down') }
                >
                    { this.renderPlaceholder() }
                    { selectOptions.map(this.renderNativeOption) }
                </select>
                { label ?
                    <label className="Field-Label">{ label }</label>
                    : "" }
            </>
        );
    }

    renderOptions() {
        const {
            label,
            selectOptions,
            isSelectExpanded: isExpanded
        } = this.props;

        return (
            <div
                block="FieldSelect"
                elem="OptionsWrapper"
                mods={ { isExpanded } }>
                <div
                    block="FieldSelect"
                    elem="FieldLabelMobile"
                    mods={ { isExpanded } }>
                    <div className="FieldSelect-FieldLabel">{ label ? label : "" }</div>
                    <CloseIcon />
                </div>
                <ul
                  block="FieldSelect"
                  elem="Options"
                  role="menu"
                  mods={ { isExpanded } }
                >
                    { selectOptions.map(this.renderOption) }
                </ul>
            </div>
        );
    }

    render() {
        const {
            name,
            isProductPage,
            attributeSetId,
            isSelectExpanded: isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            id,
            isMobile
        } = this.props;
        
        if(isMobile && id === 'category-sort') {
            return (
                <ClickOutside onClick={ handleSelectExpandedExpand }>
                    <div
                        id={ `${id}_wrapper` }
                        block="FieldSelect"
                        mods={ { isExpanded } }
                        onClick={ handleSelectExpand }
                        onKeyPress={ handleSelectListKeyPress }
                        role="button"
                        tabIndex="0"
                        aria-label="Select dropdown"
                        aria-expanded={ isExpanded }
                    >
                        <div block="FieldSelect" elem="Clickable">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 2L1.6906 6L6.3094 6L4 2ZM4.4 13L4.4 5.6L3.6 5.6L3.6 13L4.4 13Z" fill="black"/>
                                <path d="M11 13L13.3094 9L8.6906 9L11 13ZM10.6 2L10.6 9.4L11.4 9.4L11.4 2L10.6 2Z" fill="black"/>
                            </svg>
                            <span>{ __('Sort') }</span>
                            { this.renderNativeSelect() }
                        </div>
                        { this.renderOptions() }
                    </div>
                </ClickOutside>
            );
        }

        let productColorClass = '';

        if((attributeSetId === BEAUTY_COLOR_SET || attributeSetId === PIM_BEAUTY_COLOR) && isProductPage) {
            productColorClass = 'productColor';
        }

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                    id={ `${id}_wrapper` }
                    block="FieldSelect"
                    mods={ { isExpanded } }
                    onClick={ handleSelectExpand }
                    onKeyPress={ handleSelectListKeyPress }
                    role="button"
                    tabIndex="0"
                    aria-label="Select dropdown"
                    aria-expanded={ isExpanded }
                    className={productColorClass}
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? TOP : BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelect;
