import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import BoxNowConfig from '../query/BoxNowConfig';

export class ConfigQueryPlugin {
    _aroundGetStoreConfigFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'box_now_partner_id',
        'box_now_instruction',
        'is_box_now_module_active',
        'is_box_now_active'
    ];

    addBoxNowConfigQuery(args, callback) {
        const original = callback(...args);
        return [
            ...(Array.isArray(original) ? original : [original]),
            BoxNowConfig.getBoxNowConfiguration()
        ];
    }

    BoxNowToConfigReducerInitialState(args, callback) {
        const { getBoxNowUrls } = BrowserDatabase.getItem('config') || { getBoxNowUrls: {} };
        return {
            ...callback(...args),
            getBoxNowUrls
        };
    }

    addBoxNowToConfigUpdate(args, callback, context) {
        const [, action] = args;
        const originalUpdatedState = callback.apply(context, args);
        if (!action) {
            return originalUpdatedState;
        }
        const { config: { getBoxNowUrls } = {}, type } = action;

        if (type !== UPDATE_CONFIG) {
            return originalUpdatedState;
        }

        return {
            ...originalUpdatedState,
            getBoxNowUrls
        };
    }
}

export const {
    _aroundGetStoreConfigFields, addBoxNowConfigQuery, BoxNowToConfigReducerInitialState, addBoxNowToConfigUpdate
} = new ConfigQueryPlugin();

export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _aroundGetStoreConfigFields
        }
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: addBoxNowConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        function: BoxNowToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        function: addBoxNowToConfigUpdate
    }
};

export default config;
