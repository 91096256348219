/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import LinkedProductsReducer from 'Store/LinkedProducts/LinkedProducts.reducer';
import { ProductType } from 'Type/ProductList';
import { withReducers } from 'Util/DynamicReducer';
import {UPSELL} from 'Store/LinkedProducts/LinkedProducts.reducer';

import ProductLinks from './ProductLinks.component';

import { ProductLinksContainer as SourceProductLinksContainer, mapDispatchToProps  } from 'SourceComponent/ProductLinks/ProductLinks.container';

export const mapStateToProps = (state) => ({
    linkedProducts: state.LinkedProductsReducer?.linkedProducts
});


export { mapDispatchToProps };

/** @namespace Component/ProductLinks/Container */
export class ProductLinksContainer extends SourceProductLinksContainer {

    containerProps() {
        const {
            areDetailsLoaded,
            linkType,
            linkedProducts,
            numberOfProductsToDisplay,
            title,
            currentProductThumbnailUrl,
            currentProductId,
            currentProductName,
            isBlogRelated,
            selectPdpProductTab,
            pdpProductTab,
            isProductPage = false
        } = this.props;

        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;

        return {
            isProductPage,
            areDetailsLoaded,
            linkType,
            linkedProducts,
            numberOfProductsToDisplay,
            title,
            currentProductThumbnailUrl,
            currentProductId,
            currentProductName,
            isBlogRelated,
            selectPdpProductTab,
            pdpProductTab,
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true })
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            }
        };
    }
}

export default withReducers({
    LinkedProductsReducer
})(connect(mapStateToProps, mapDispatchToProps)(ProductLinksContainer));
