import {
    GET_CUSTOMERS_LIST,
    UPDATE_CUSTOM_IS_LOADING,
    GET_ORDERS_LIST,
    UPDATE_ADMIN_WIDGET_STATE
} from './AdminCustomer.action';
import BrowserDatabase from "SourceUtil/BrowserDatabase/BrowserDatabase";

export const getInitialState = () => ({
    customsList: {},
    customerOrderList: {},
    isLoading: false,
    isAdminCollapsed: false
});

export const AdminCustomerReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case GET_CUSTOMERS_LIST:
            return {
                ...state,
                customersList: action.customersList
            };
        case GET_ORDERS_LIST:
            return {
                ...state,
                ordersList: action.ordersList
            };
        case UPDATE_CUSTOM_IS_LOADING:
            const { isLoading } = action;
            
            return {
                ...state,
                isLoading
            };
        case UPDATE_ADMIN_WIDGET_STATE:
            const { isAdminCollapsed } = action;

            return {
                ...state,
                isAdminCollapsed
            };            
        default:
            return state;
    }
};

export default AdminCustomerReducer;
