/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Html from 'Component/Html';

import Field from 'Component/Field/Field.container';
import media, { WYSIWYG_MEDIA } from "SourceUtil/Media/Media";

import './CheckoutGroupPayment.style';

const groupPaymentsInfo = {
    mg_bank_redirect : { title : __('Credit / Debit') , instructions : __('If you are a Member of the go4more Rewards Program of the National Bank, you earn 4 go4more points for every € 1 worth of purchases.') }
};

/** @namespace Component/CheckoutGroupPayment/Component */
export class CheckoutGroupPayment extends PureComponent {
    static propTypes = {
        groupedPayments: PropTypes.arrayOf(Object).isRequired,
        selectPaymentMethod: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        paymentKey: PropTypes.string.isRequired,
        isSelected: PropTypes.bool.isRequired
    };

    state = {
        isCheckboxSelected : false
    };

    onClick = (value) => {
        const {
            selectPaymentMethod,
        } = this.props;
        if(value){
            selectPaymentMethod({code : value});
            this.setState({isCheckboxSelected : false})
        }else {
            this.setState({isCheckboxSelected : true})
        }
    };

    selectCheckBox(){
        const { groupedPayments, selectPaymentMethod } = this.props;
        const { code } = groupedPayments[0];
        selectPaymentMethod({ code });
        this.setState({isCheckboxSelected : true});
    }

    renderDetails(){
        let {
            selectedPaymentCode
        } = this.props;

        let instructions = this.props?.[selectedPaymentCode]?.instructions;
        if(!instructions)return null;
        return(
            <Html
                content={instructions || ''}
            />
        );
    }

    render() {
        let {
            selectedPaymentCode,
            groupedPayments,
            paymentKey,
            isSelected
        } = this.props;
        let { isCheckboxSelected } = this.state;
        let paymentSelectElement = document.getElementById('payment-select');
        let bankRedirectElement = document.getElementById('BankRedirect_group');

        if(!isCheckboxSelected && isSelected && paymentSelectElement && bankRedirectElement){
            bankRedirectElement.classList.add('active');
        }

        if(isCheckboxSelected && isSelected && paymentSelectElement && bankRedirectElement){
            bankRedirectElement.classList.remove('active');
        }

        if(isCheckboxSelected)
            selectedPaymentCode = '';

        let title = groupPaymentsInfo[paymentKey].title;

        // isSelected = selectedPaymentCode && selectedPaymentCode.includes(paymentKey);

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutGroupPayment">
                <button
                    block="CheckoutPayment"
                    mods={ { isSelected : isSelected || isCheckboxSelected } }
                    elem="Button"
                    type="button"
                    onClick={ this.selectCheckBox.bind(this) }
                >
                    <Field
                        type="checkbox"
                        id={ `option-${ title }` }
                        name={ `option-${ title }` }
                        checked={ isSelected }
                        disabled
                    />
                    { title }
                </button>
                {
                    isSelected ?
                        <div className="CheckoutGroupPayment_ContentWrapper">
                            <Field
                                type="select"
                                id='payment-select'
                                name='payment-select'
                                value={ selectedPaymentCode }
                                noSort={ true }
                                selectOptions={
                                    [
                                        { value : '' , label : __('Please Select Bank') },
                                        ...groupedPayments.map(item=> ({ value : item.code , label : item.title }))
                                    ]

                                }
                                onChange={this.onClick}
                            />
                            { this.renderDetails()}
                        </div>
                        :
                        null
                }
            </li>
        );
    }
}

export default CheckoutGroupPayment;
