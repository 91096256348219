import { Field } from 'SourceUtil/Query';

const _getUrlResolverFields = (args, callback, instance) => {

    return [
        ...callback(instance, args),
        instance._addBannersFields()
    ];
}

const _addBannersFields = (args, callback, instance) => {
    return new Field('banners').addFieldList([
        'banner',
        'popup'
    ])
}

export default {
    'Query/UrlRewrites': {
        'member-function': {
            '_getUrlResolverFields': _getUrlResolverFields,
            '_addBannersFields': _addBannersFields
        },
    }
}
