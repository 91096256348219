import { CATEGORY } from "SourceComponent/Header/Header.config";
import { removeRichData } from '../store/RichData/RichData.action';

export const RichDataDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
    "../store/RichData/RichData.dispatcher"
);

export class CategoryPageContainerPlugin {
    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            addRichData: (options) =>
                Object.keys(options).length
                    ? RichDataDispatcher.then(({ default: dispatcher }) =>
                          dispatcher.addRichdata(options, dispatch)
                      )
                    : RichDataDispatcher.then(({ default: dispatcher }) =>
                          dispatcher.addRichdata({}, dispatch)
                      ),
            removeRichdata: (type, isExist) =>
                dispatch(removeRichData(type, isExist)),
        };
    };

    isExist = () => {
        if (document.getElementById(CATEGORY)) {
            return true;
        }
        return false;
    };

    mountRichData = (instance, category) => {
        const { addRichData } = instance.props;
        const options = {
            name: CATEGORY,
            isExist: this.isExist(),
            category
        };
        addRichData(options);
    };

    unMountRichData = (instance) => {
        const { removeRichdata } = instance.props;
        removeRichdata(
            CATEGORY,
            this.isExist()
        );
    }

    componentWillUnmount = (args, callback, instance) => {
        this.unMountRichData(instance);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [prevProps] = args;
        const { category: prevCategory } = prevProps;
        const { category } = instance.props;
        const areLoaded = category ? true : false;
        
        if(areLoaded && category != prevCategory) {
            this.mountRichData(instance, category)
        }
        
        return callback(...args);
    }
}

export const { componentWillUnmount, mapDispatchToProps, componentDidUpdate } =
    new CategoryPageContainerPlugin();

export default {
    "Route/CategoryPage/Container/mapDispatchToProps": {
        function: [
            {
                position: 100,
                implementation: mapDispatchToProps,
            },
        ],
    },
    "Route/CategoryPage/Container": {
        "member-function": {
            componentWillUnmount: [
                {
                    position: 100,
                    implementation: componentWillUnmount,
                },
            ],
            componentDidUpdate: [
                {
                    position: 100,
                    implementation: componentDidUpdate,
                },
            ]
        },
    },
};
