import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { WishlistPopup } from './WishlistPopup.component';
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Wishlist/Wishlist.dispatcher'
);
import { hideActivePopup } from 'SourceStore/Overlay/Overlay.action';
import { hideActiveOverlay } from 'SourceStore/Overlay/Overlay.action';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';
import { ERROR_CONFIGURABLE_NOT_PROVIDED } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.config';
import { SAMPLE_CATEGORY, CARDS_CATEGORY } from 'Route/ProductPage/ProductPage.config';
import { showNotification } from 'SourceStore/Notification/Notification.action';

import { event } from '@mageguide/gtm-new/src/store/GoogleTagManager/GoogleTagManager.action';
import { 
    EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST,
    EVENT_GTM_PRODUCT_REMOVE_WISH_LIST 
} from '@mageguide/gtm-new/src/component/GoogleTagManager/GoogleTagManager.events';

/** @namespace Component/WishlistPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    wishlistCategories: state.WishlistReducer.wishlistCategories,
    isLoading: state.WishlistReducer.isLoading,
    payload: state.PopupReducer.popupPayload,
    productsInWishlist: state.WishlistReducer.productsInWishlist,
});

/** @namespace Component/WishlistPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // getWishlistCategories: () => WishlistDispatcher.then(
    //     ({ default: dispatcher }) => dispatcher.getWishlistCategories(dispatch),
    // ),
    addNewCategory: (newCategory) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addNewWishlistCategory(dispatch, newCategory),
    ),
    addItemToCategory: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addItemToCategory(dispatch, options),
    ),
    removeItemFromCategory: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromCategory(dispatch, options),
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    resetHideActivePopup: () => dispatch(hideActivePopup(false)),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
    updateInitialWishlistData: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch),
    ),
    getRemoveItemFromCategory: () => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getRemoveItemFromCategory(dispatch),
    ),
    removeProductFromWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    ),
    moveOrCopyItem: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.moveOrCopyItem(dispatch, options)
    ),
    moveAllItemsToWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.moveAllItemsToWishlist(dispatch, options)
    ),
    event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});


/** @namespace Component/WishlistPopup/Container */
export class WishlistPopupContainer extends PureComponent {

    __construct(props) {
        super.__construct(props);

        this.state = {
            isMoveCategory: this.getIsMoveState()
        };
    }
    
    static defaultProps = {
        setLoading: () => {},
        isAccountTab: false,
        product: {},
        isProductList: false,
        isCart: false
    }

    state = {
        categoryId: null,
        isLoading: false,
        wishlistCategories: {},
        newCategory: null
    }

    getIsMoveState(){
        const { payload } = this.props;
        const payloadData = Object.values(payload).find( ({ isMoveCategory }) => isMoveCategory);
        
        if(payloadData && 
           payloadData.isMoveCategory && 
           typeof(payloadData.isMoveCategory) === 'boolean') {
            return true;
        }

        return false;
    }

    componentDidMount() {
        const { wishlistCategories, updateInitialWishlistData } = this.props;
        this.getCategoryId();
    }
    
    componentDidUpdate(prevProps) {
        const { wishlistCategories: prevWishlistCategories } = prevProps;
        const { wishlistCategories } = this.props;
        
        
        if(wishlistCategories !== prevWishlistCategories) {
            this.getCategoryId();
        }
    }

    setLoading(isLoading){
        const { setLoading } = this.props;
        setLoading(this, isLoading);
    }

    onNewCateogrySave(e) {
        e.preventDefault()
        const { addNewCategory } = this.props;
        const { newCategory } = this.state;
         addNewCategory({ category_name: newCategory }).then(
             () => this.onCategoryField(null)
        );
            
    }

    onCategoryField(newCategory){
        this.setState({ newCategory })
    }

    onClick = (categoryId) => {
        const {
            product: productFromComponent,
            quantity,
            id,
            isInWishlist: orgIsInWishlist,
            addToWishlist: orgnalAddwishList,
            removeFromWishlist: orgRemoveFromWishlist,
            moveOrCopyItem,
            addItemToCategory,
            removeItemFromCategory,
            resetHideActivePopup,
            goToPreviousHeaderState,
            hideActiveOverlay,
            payload,
            isAccountTab,
            updateInitialWishlistData,
            isCart,
            removeProductFromWishlist,
            productsInWishlist,
            moveAllItemsToWishlist,
            event,
            showNotification
        } = this.props;

        const { isMoveCategory, categoryId: activeCategory } = this.state;
        
        if(payload?.WISHLIST_POPUP?.moveAllItems) {
            return moveAllItemsToWishlist({ category_id: categoryId }).then( () => 
                resetHideActivePopup(),
                goToPreviousHeaderState(),
                hideActiveOverlay(),
            )    
        }

        const isInWishlist = orgIsInWishlist ? orgIsInWishlist : this.isInWishlist();
        const product = Object.entries(productFromComponent).length > 0 ? productFromComponent : payload.WISHLIST_POPUP.product; 
        const addToWishlist = !orgnalAddwishList ? payload.WISHLIST_POPUP.addToWishlist : orgnalAddwishList;
        const removeFromWishlist = !orgRemoveFromWishlist ? payload.WISHLIST_POPUP.removeFromWishlist : orgRemoveFromWishlist;

        const { 
            id: productId,
            categories= [], 
            price_range: {
                minimum_price: {
                    final_price: {
                        value: minimalPriceValue
                    } = {},
                } = {}
            } = {},
         }= product;
        
        // const getPrdouctVarient =  payload.WISHLIST_POPUP.product && payload.WISHLIST_POPUP.product.product_option 
        //     ? payload.WISHLIST_POPUP.product : this.getProductVariant();

        // const { product_option = {} } = getPrdouctVarient;

        const isDisable = categories.filter(({ id })=> id == SAMPLE_CATEGORY || id == CARDS_CATEGORY)?.length !== 0;

        if(isDisable || minimalPriceValue < 0.5) {
            return showNotification('info', __("You can't add this product to wishlist"));
        }
        
        const options = {
            categoryId,
            productId
        };
        
        if (!isInWishlist && isCart) {

            return addToWishlist(options).then(() => {
                    event(EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST, {
                        product: product,
                        quantity,
                        configurableVariantIndex: false
                    });
                    resetHideActivePopup();
                    goToPreviousHeaderState();
                    hideActiveOverlay();
                });
        }

        if (isAccountTab) {
            const { wishlist: { id: item_id, quantity: itemQty } } = Object.values(productsInWishlist).find(
                ({ wishlist: { sku }, sku: psku }) => psku === product.sku || sku === product.sku
            ) || {};
            
            if(isMoveCategory) {
            return moveOrCopyItem({ 
                    item_id, 
                    from_category_id: activeCategory,
                    category_id: categoryId,
                    is_Move: isMoveCategory

                }).then(() => {
                    resetHideActivePopup(),
                    goToPreviousHeaderState(),
                    updateInitialWishlistData(),
                    setTimeout(hideActiveOverlay, 300)
            });
        } else { 

             return moveOrCopyItem({ 
                    item_id, 
                    from_category_id: activeCategory,
                    category_id: categoryId,
                    is_Move: false

            }).then(() => {
                updateInitialWishlistData(),
                resetHideActivePopup(),
                goToPreviousHeaderState(),
                setTimeout(hideActiveOverlay, 300)
            });
            }
        }

        if (categoryId === 0) {
            resetHideActivePopup();
            goToPreviousHeaderState();
            setTimeout(hideActiveOverlay, 500);
            if (isInWishlist) {
                return removeFromWishlist(product, quantity);
            }
            return addToWishlist(product, quantity, options);
        }
        
        if (!isInWishlist && categoryId !== 0) {

            return addToWishlist(options).then(
                        () => {
                        event(EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST, {
                            product: product,
                            quantity,
                            configurableVariantIndex: false
                        });  
                        resetHideActivePopup();
                        goToPreviousHeaderState();
                        hideActiveOverlay();
                        setTimeout(hideActiveOverlay, 500);
                        }
                    );
        }

        if (categoryId !== 0) {
            removeItemFromCategory({
                product_id: productId,
                category_id: categoryId,
            });
        }

        event(EVENT_GTM_PRODUCT_REMOVE_WISH_LIST, {
            product: instance.props.product,
            quantity,
            configurableVariantIndex
        });

        return removeFromWishlist(product, quantity);
    };

    getProductVariant() {
        const { 
            product: productFromComponent,
            payload
        } = this.props

        const product = Object.entries(productFromComponent).length > 0 ? productFromComponent : payload.WISHLIST_POPUP.product; 
        const { type_id, wishlist = {}  } = product;
        
        if(type_id === 'simple' || !wishlist) {
            return { };
        }
        
        const { super_attribute } = JSON.parse(wishlist.buy_request);
  
        const extension_attributes = {
            configurable_item_options: Object.entries(super_attribute).map((option) => ({
                option_id: Number(option[0]),
                option_value: option[1].toString()
            }))
        }

        return { product_option: { extension_attributes } };
    }

    _getVariantIndex() {
        const { 
            product: productFromComponent,
            payload
        } = this.props
        const product = Object.entries(productFromComponent).length > 0 ? productFromComponent : payload.WISHLIST_POPUP.product; 
        
        const {
                variants = [], 
                wishlist: { sku: itemSku }
        } = product;
        
        return variants.findIndex(({ sku }) => sku === itemSku || itemSku.includes(sku));
    }

    getCategoryId() {
        const { 
            wishlistCategories, 
            payload, 
            product, 
            isInWishlist, 
            activeCategory, 
            productsInWishlist 
        } = this.props;
        
        let categoryId = 0
        const category = wishlistCategories.find(({ category_id }) => category_id === activeCategory)

        if(!category?.is_default) {
        // Update the state with the categoryId
            categoryId = activeCategory;
        }

        this.setState({ categoryId });
    }
    
    containerFunctions = {
        handleCategorySelect: this._handleCategorySelect.bind(this),
        onChangeField: this.onCategoryField.bind(this),
        onSaveCategory: this.onNewCateogrySave.bind(this),
        addToWishlist: this.onClick.bind(this)
    };

    containerProps = () => {
        const  { categoryId, newCategory, isMoveCategory } = this.state;
        const { payload, product, isInWishlist, wishlistCategories, productsInWishlist, isAccountTab, isProductList } = this.props;
        
        const [key] = Object.keys(payload);
        
        return {
            product: payload[key] ? payload[key]?.product : product,
            wishlistCategories,
            isInWishlist:  isInWishlist ? isInWishlist : this.isInWishlist(),
            categoryId,
            productsInWishlist,
            isAccountTab,
            isMoveCategory,
            newCategory,
            isProductList
        }
    }

    isInWishlist = () => {
            const { 
            product: productFromComponent,
            payload,
            productsInWishlist,
            isAccountTab
        } = this.props

        if(payload?.WISHLIST_POPUP?.moveAllItems) {
            return false    
        }

        const product = 
            Object.entries(productFromComponent).length > 0 
                ? productFromComponent : payload.WISHLIST_POPUP?.product; 

        const {
            sku,
            wishlist
        } = product;

        const productSku = wishlist && wishlist.sku ? wishlist.sku : sku 

        if (product === ERROR_CONFIGURABLE_NOT_PROVIDED || isAccountTab) {
            return false;
        }

        return Object.values(productsInWishlist).findIndex(({ wishlist: { sku } }) => sku === productSku) >= 0;
    };

    _handleCategorySelect(categoryId) {
        this.setState({ categoryId })
    }

    render() {
        const { isLoading } = this.props
        
        return (
            <WishlistPopup
                isLoading = { isLoading }
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPopupContainer);