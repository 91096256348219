import ProductListPage from 'SourceComponent/ProductListPage';

import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};


const renderPage = (args, callback, instance) => {
    const [props = {}] = args;
    const {
        isInfiniteLoaderEnabled,
        loadPage,
        isLoading,
        isVisible,
        currentPage,
        mix,
        event,
        sliderOptions,
        categoryPageCmsBlockListing,
        isSampleProducts,
        isFreeGifts,
        device,
        isSearchPage,
        isBrandPage,
        title,
        widgetId
    } = instance.props;

    const newProps = instance._processProps(props);

    return (
        <ProductListPage
          widgetId= { widgetId }
          title= { title }
          key={ currentPage }
          event={ event }
          isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
          updatePages={ loadPage }
          isLoading={ isLoading }
          isVisible={ isVisible }
          mix={ mix }
          categoryPageCmsBlockListing= { categoryPageCmsBlockListing }
          sliderOptions={ sliderOptions }
          device= { device }
          isSampleProducts= { isSampleProducts }
          isFreeGifts = { isFreeGifts }
          isSearchPage = { isSearchPage }
          isBrandPage = { isBrandPage }
          { ...newProps }
        />
    );
};

const containerProps = (args, callback, instance) => {
    const {
        event
    } = instance.props;

    return {
        ...callback(...args),
        event
    };
}

export default {
    'Component/ProductList/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductList/Container': {
        'member-function': { 
            containerProps
        }
    },
    'Component/ProductList/Component': {
        'member-function': {
            renderPage
        }
    }
};
