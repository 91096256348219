import { AMOUNT_OF_PLACEHOLDERS } from 'Component/SearchOverlay/SearchOverlay.config';
import {  getApiSearchStatus, emptySearchRequest } from '../utlis/Request';

class ExternalSearch {

    onSearchBarFocus(_args, _callback, _instance) {
        const {
            searchCriteria
        } = _instance.state;

        if(searchCriteria == '') {
            emptySearchRequest();
        }
        return _callback(..._args);
    }

    makeSearchRequest(_args, _callback, _instance){
        const {
            makeSearchRequest,
            clearSearchResults,
            searchCriteria
        } = _instance.props;

        if (searchCriteria) {
            clearSearchResults();
            makeSearchRequest({
                args: {
                    search: searchCriteria,
                    pageSize: 24,
                    currentPage: 1
                }
            });
        } else {
            if(searchCriteria == '') {
                emptySearchRequest();
            }
        }
    }

    renderSearchResults(_args, _callback, _instance) {
        const { searchResults, isLoading, search_is_enabled } = _instance.props;

        if(!search_is_enabled) {
            return _callback.apply(_instance, _args);
        }
        
        if ((!searchResults.length && !isLoading && !_instance.timeout) || 
            (!_instance.timeout && !isLoading && search_is_enabled && searchResults[0] && searchResults[0].indexOf('<li') == -1 )) {
            return _instance.renderNoResults();
        }

        const resultsToRender = ((isLoading || _instance.timeout) && (typeof(searchResults[0]) !== 'string')) ? Array(AMOUNT_OF_PLACEHOLDERS).fill({}) : searchResults;

        if(searchResults[0] && searchResults[0].indexOf('<li') !== -1 && search_is_enabled && typeof(searchResults[0]) == 'string') {
            return <div dangerouslySetInnerHTML={{__html: resultsToRender[0] }} />
        }

        return (
            <ul>
                { resultsToRender.map((item, i) => _instance.renderSearchItem(item, i)) }
            </ul>
        );
    }

}

const containerProps = (args, callback, instance) => {
    const { search_is_enabled = false, use_in_autocomplete = false } = getApiSearchStatus();

    return {
        ...callback(...args),
        search_is_enabled: use_in_autocomplete && search_is_enabled
    };
}



const { makeSearchRequest, renderSearchResults, onSearchBarFocus } = new ExternalSearch();

export default {
    'Component/Header/Container': {
        'member-function': {
            onSearchBarFocus
        }
    },
    'Component/SearchOverlay/Component': {
        'member-function': {
            renderSearchResults
        }
    },
    'Component/SearchOverlay/Container': {
        'member-function': {
            makeSearchRequest,
            containerProps
        }
    }
};