const   _getStoreListFields = (args, callback, instance) => (
    [
        ...callback.apply(instance, args),
        'id',
        'locale'
    ]
);
export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreListFields: [
                {
                    position: 100,
                    implementation: _getStoreListFields
                }
            ]
        }
    }
};

export default config;
