import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { EIGHT_DAYS_IN_SECONDS } from 'Component/CustomPromoPopup/CustomPromoPopup.config';

export const hidePopUp = (args, callback, instance) => {
    const { hideActiveOverlay, goToPreviousNavigationState, onClose, id } = instance.props;
    const isVisible = instance.getIsVisible();

    if (id.includes('_popup_id')) {
        BrowserDatabase.setItem(true, id, EIGHT_DAYS_IN_SECONDS);
    }
    if (isVisible) {
        instance.unfreezeScroll();
        hideActiveOverlay();
        goToPreviousNavigationState();
        onClose();
    }
};

export default {
    'Component/Popup/Component': {
        'member-function': {
            hidePopUp
        },
    }
}