import { Field } from 'Util/Query';
export class SwitchStoreQuery {
    getQuery(options) {
        const { guestCartId , storeId } = options;
         let field = new Field('switchStore');
        field.addArgument('storeId', 'String!', storeId);
        if(guestCartId)
            field.addArgument('guestCartId', 'String', guestCartId);
        return field;
    }
}

export default new SwitchStoreQuery();