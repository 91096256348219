import PayPal from '../component/PayPal';
import { appendWithStoreCode } from 'Util/Url';
import media, {WYSIWYG_MEDIA} from 'Util/Media/Media';
import {lazy} from "react";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

export class CheckoutBillingComponentPlugin {
    renderActions = (args, callback, instance) => {
        const { paymentMethod, setLoading, setDetailsStep, termsAreEnabled, isVirtual } = instance.props;
        const { isTermsAndConditionsAccepted } = instance.state;
        let result = callback.apply(instance, args);
        if(paymentMethod === "paypal_express"){
            return (
                <>
                    <div block="Checkout" elem="StickyButtonWrapper">
                        <a href={appendWithStoreCode("/cart")} className="Button CheckoutShipping-Button CheckoutShipping-Button_Back">
                            { __('Back to Cart') }
                        </a>
                        {
                            window.shippingFormSubmitted && (isTermsAndConditionsAccepted || !termsAreEnabled)?
                            <PayPal
                                    setLoading={setLoading}
                                    setDetailsStep={setDetailsStep}
                                    selectedPaymentCode={paymentMethod}
                                isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                                    isVirtual={isVirtual}
                                /> :
                            <button
                                rel='nofollow'
                                type="submit"
                                block="Button"
                                disabled={ true }
                                mix={ { block: 'CheckoutBilling', elem: 'Button' } }
                            >
                                { __('Complete order Paypal ') }
                                <UniversalIcon src={media('admin_icons/arrow-right-long-light.png',WYSIWYG_MEDIA)} alt="@@" className="icon_arrow-right-long" />
                            </button>
                        }
                    </div>
                </>
            );
        }
        return result;
    }

    renderCheckoutActions = (args, callback, instance) => {
        const { paymentMethod, setLoading, setDetailsStep, termsAreEnabled, isTermsAndConditionsAccepted, hasLimitation, isMobile, isVirtual } = instance.props;
        if (paymentMethod === "paypal_express") {
            return (
                <>
                    <div block="Checkout" elem="StickyButtonWrapper">
                        <a href={appendWithStoreCode("/cart")} className="Button CheckoutBilling-Button">
                            {__('Back to Cart')}
                        </a>
                        {
                            !hasLimitation && (isTermsAndConditionsAccepted || !termsAreEnabled) ?
                                <PayPal
                                    setLoading={setLoading}
                                    setDetailsStep={setDetailsStep}
                                    selectedPaymentCode={paymentMethod}
                                    isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                                    isVirtual={isVirtual}
                                /> :
                                <>
                                <button
                                    rel='nofollow'
                                    type="submit"
                                    block="Button"
                                        disabled={true}
                                        mix={{ block: 'CheckoutBilling', elem: 'Button' }}
                                >
                                        {__('Complete order Paypal ')}
                                </button>
                                </>
                        }
                    </div>
                    {!isMobile && instance.renderLimitationMessage()}
                </>
            );
        }
        return callback.apply(instance, args);
    }
}

const {
    renderActions,
    renderCheckoutActions
} = new CheckoutBillingComponentPlugin();

export const config = {
    // 'Component/CheckoutBilling/Component': {
    //     'member-function': {
    //         // renderActions: renderActions
    //     }
    // },
    'Route/Checkout/Component': {
        'member-function': {
            renderActions: renderCheckoutActions
        }
    }
};

export default config;
