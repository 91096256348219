/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import {
    isMobile,
    isMobileClientHints,
    isUsingClientHints
} from 'Util/Mobile';
import { RouterContainer as SourceRouterContainer, mapStateToProps as SourceMapStateToProps } from 'SourceComponent/Router/Router.container';
import { isSignedIn } from 'Util/Auth';
import { updateConfigDevice } from 'Store/Config/Config.action';
import { updateMeta } from 'Store/Meta/Meta.action';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const ConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);
export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    canonical_url: state.MetaReducer.canonical_url
});


/** @namespace Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateConfigDevice: (device) => dispatch(updateConfigDevice(device)),
    init: () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch)
        );
        isSignedIn() ?
         WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
         ) : null,
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
        );
    }
});

/** @namespace Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);
        this.isPageLoaded(prevProps);
    }
    componentDidMount() {
        if(isMobile.any()){
            this.updateDeviceConfigOnMobile();
        }else {
            window.addEventListener('resize', this.handleResize);
        }
    }

    isPageLoaded(prevProps) {
        const { isPageLoaded = false } = this.state;
        const { canonical_url, meta_title } = this.props;
        const { canonical_url: prevCanonical_url, prevMeta_title } = prevProps;

        if (!isPageLoaded &&
            ((canonical_url && prevCanonical_url !== canonical_url) &&
                (meta_title && meta_title !== prevMeta_title))
        ) {
            // Condition not met, set a timeout to set isPageLoaded to true after 1 seconds when all conditions matches
            setTimeout(() => {
                this.setPageLoadingState(true);
            }, 1000);
        } else if (!isPageLoaded) {
            window.addEventListener('load', (event) => {
                this.setPageLoadingState(true);
            });
        }
    }
    

    setPageLoadingState = (state) => {
        this.setState({
            isPageLoaded: state
        })
    }

    containerProps() {
        const { isBigOffline } = this.props;
        const { isPageLoaded = false } = this.state;

        return { 
            isBigOffline, 
            isPageLoaded,
            setPageLoadingState: (state) => this.setPageLoadingState(state)
        };
    }

    async updateDeviceConfigOnMobile(){
        const { updateConfigDevice } = this.props;
        if (isUsingClientHints) {
            const { platform, model } = await isMobileClientHints.getDeviceData();
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(platform),
                ios: isMobile.iOS(platform),
                blackberry: isMobile.blackBerry(model),
                opera: isMobile.opera(model),
                safari: isMobile.safari(model),
                windows: isMobile.windows(model)
            });
        } else {
            updateConfigDevice({
                isMobile: isMobile.any(),
                android: isMobile.android(),
                ios: isMobile.iOS(),
                blackberry: isMobile.blackBerry(),
                opera: isMobile.opera(),
                safari: isMobile.safari(),
                windows: isMobile.windows()
            });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
