import { lazy, Suspense } from 'react';
export const RelatedProducts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "related-products" */ '../component/RelatedProducts'));
import Loader from 'SourceComponent/Loader';
import ProductLinks from 'SourceComponent/ProductLinks';
import { CART_INTO_CROSSSEL, CROSS_SELL } from '../component/RelatedProducts/RelatedProducts.config';

const renderRelatedProducts = (relatedProducts) => {

    if(relatedProducts && Object.keys(relatedProducts).length > 0) {
        return relatedProducts.map(renderRelatedProductComponent)
    }

        return null;
}


const renderRelatedProductComponent = ({ block_title, items, position }, _) => {

    return(
        <Suspense fallback={<Loader isLoading />}>
            <RelatedProducts
                type="cart"
                position={position}
                block_title={block_title}
                items={items}
            />
        </Suspense>
    )
}

const renderAdditionalSections = (args, callback, instance) => {
    const { relatedProducts = {} } = instance.props;
    var isMerged = false;

    if(Object.values(relatedProducts)?.length >0){
        const isCrossSellMerged = relatedProducts.filter(({position}) => position == CART_INTO_CROSSSEL);
        isMerged = isCrossSellMerged.length > 0;
    }
    
    if(isMerged) {
        return(
            <div block="CartPage" elem="CrossSell">
            { renderRelatedProducts(relatedProducts) }
        </div>
        )
    }

    return (
        <div block="CartPage" elem="CrossSell">
            <ProductLinks
              isMerged= { isMerged }
              linkType={ CROSS_SELL }
              title={ __('We think you would like it') }
              numberOfProductsToDisplay={ 10 }
            />
            { renderRelatedProducts(relatedProducts) }
        </div>
    );
} 


 
export default {
    'Route/CartPage/Component' : { 
        'member-function': {
            renderCrossSellProducts: renderAdditionalSections
        }
    }
} 
