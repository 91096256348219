const sortSelectOptions =  (args, callback, instance) => {
    const { selectOptions, noSort } = instance.props;
    if(noSort)
        return selectOptions;
    return callback.apply(instance, args);
}
export const config = {
    'Component/FieldSelect/Container': {
        'member-function': {
            'sortSelectOptions': sortSelectOptions
        }
    }
};

export default config;
