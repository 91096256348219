import { ADDING_SAMPLE_PRODUCT } from '../../../store/SampleProduct/SampleProduct.action' ;

const addisLoadingToInitialState = (args, callback, instance) => {   
        return {
            ...callback(...args),
            isLoading: false
    };
};


export const CartReducer = (args, callback, instance) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(instance, args);
    
    if (!action) {
        return originalUpdatedState;
    }   
    const { isLoading = false, type } = action;

    if (type === ADDING_SAMPLE_PRODUCT) {
        return {
            ...originalUpdatedState,
            isLoading

        };
    }

    return {
        ...originalUpdatedState
    };

}



export default {
    'Store/Cart/Reducer': {
        function: [
            {
                position: 102,
                implementation: CartReducer
            }
        ]
    },
    'Store/Cart/Reducer/getInitialState': {
        'function': addisLoadingToInitialState
    },
}