/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import {CUSTOM_VALIDATED_FIELDS_FUTURE_DATE, CUSTOM_VALIDATED_FIELDS_NO_NUMBER} from "./Field.container.plugin";
import moreValidationConfig from "../component/Field/Field.config";
import defaultValidationConfig from 'Component/Form/Form.config';

export class FormComponentPlugin {

    validateField = (args, callback) =>  {
        const [ field, refMap ] = args;
        const { props }= field;
        const { validation : oldValidation, id, name } = props;
        let isNoNumberValidate = CUSTOM_VALIDATED_FIELDS_NO_NUMBER.includes(name);
        let isFutureDateValidate = CUSTOM_VALIDATED_FIELDS_FUTURE_DATE.includes(name);
        if(isNoNumberValidate || isFutureDateValidate){
            if (oldValidation && id && refMap[name] && refMap[name].current) {
                const { current: inputNode } = refMap[name];
                const validationConfig = {
                    ...defaultValidationConfig,
                    ...moreValidationConfig
                };
                let validation = [];
                if(isNoNumberValidate){
                    validation = [...oldValidation, 'noNumber'];
                }
                if(isFutureDateValidate){
                    validation = [...oldValidation, 'noFutureDate'];
                }

                const rule = validation.find((rule) => {
                    if (!validationConfig[rule]) {
                        return false;
                    }
                    const validationRules = validationConfig[rule];
                    const isValid = validationRules.validate(inputNode, refMap);

                    return !isValid;
                });

                if (rule) {
                    return validationConfig[rule];
                }
            }
        }
        return callback(...args);
    }
}

const {  validateField } = new FormComponentPlugin();

export const config = {
    'Component/Form/Component': {
        'static-member': {
            validateField : validateField
        }
    }
};

export default config;
