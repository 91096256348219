import OrderQuery from 'SourceQuery/Order.query';
import { Field } from 'SourceUtil/Query';

/** @namespace Rma/Query/ProductReturn/Query/ProductReturnQuery */
export class ProductReturnQuery {
    /**
     * Get return list fields
     * @returns {*}
     */
    getReturnList() {
        return new Field('customer')
            .addField(this.getReturnListItems())
    }

    getGuestOrderReturns(options) {
        return new Field('getGuestOrderReturns')
            .addArgument('pageSize', 'Int', 10)
            .addArgument('currentPage', 'Int', 1)
            .addArgument('increment_id', 'String!', options.increment_id)
            .addArgument('email', 'String!', options.email)
            .addField('total_count')
            .addField(this.getPageInfo())
            .addField(this.getItems());
    }


    getGuestOrderReturn(options) {
        const { email, order_id } = options;
        return new Field('getGuestOrderReturn')
            .addArgument('order_id', 'String!', order_id)
            .addArgument('email', 'String!', email)
            .addField('secretKey');
    }


    getReturnListItems() {
        return new Field('amrma_returns')
            .addArgument('pageSize', 'Int', 10)
            .addArgument('currentPage', 'Int', 1)
            .addField('total_count')
            .addField(this.getPageInfo())
            .addField(this.getItems());
    }

    getPageInfo() {
        return new Field('page_info')
            .addField('current_page')
            .addField('page_size')
            .addField('total_pages')
    }

    getItems() {
        return new Field('items')
            .addFieldList(this.getItemsList());
    }


    getGuestOrder(options) {
        const { email, secretKey } = options;
        return new Field('getGuestOrderByScrentKey')
            .addArgument('secretKey', 'String!', secretKey)
            .addArgument('email', 'String!', email)
            .addFieldList(OrderQuery._getOrderItemsFields(false))
    }


    getCustomerOrder() {
        return new Field('customerOrders')
            .addField('total_count')
            .addField(this.getPageInfo())
            .addField(this.getCustomerOrders());
    }

    getCustomerOrders() {
        return new Field('items')
            .addFieldList(this.getCustomerOrdersItems())
    }

    getCustomerOrdersItems() {
        return [
            'id',
            'increment_id',
            'status',
            // 'amrma_is_returnable',
            // comments
            'created_at',
            // credit_memos
            // gift_message
            'grand_total',
            // invoices
            // 'number',
            // 'order_date',
            'order_number',
            // payment_methods
            // shipments
            // shipping_address
            'shipping_method',
            // this.getCustomerOrderTotal(),
            this.getCustomerOrderItem()
        ]
    }

    getCustomerOrderItem() {
        return [
            'id',
            'product_name',
            'product_sku',
            'product_type',
            'product_url_key',
            'quantity_canceled',
            'quantity_invoiced',
            'quantity_ordered',
            'quantity_refunded',
            'quantity_returned',
            'quantity_shipped',
            'status',
            this.getMoneyValues('product_sale_price'),
            this.getCustomerOrderItemOptions('entered_options'),
            this.getCustomerOrderItemOptions('selected_options')
        ]
    }

    getCustomerOrderItemOptions(type) {
        return new Field(type)
            .addField('label')
            .addField('value')
    }

    getMoneyValues(type) {
        return new Field(type)
            .addField('currency')
            .addField('value')
    }

    getCustomerOrderTotal() {
        return new Field('total')
            .addField(this.getMoneyValues('base_grand_total'))
            .addField(this.getMoneyValues('grand_total'))
            .addField(this.getMoneyValues('total_shipping'))
            .addField(this.getMoneyValues('subtotal'))
    }

    getItemsList() {
        return [
            'shipping_label',
            'note',
            'store_id',
            'customer_id',
            'customer_name',
            'url_hash',
            'manager_id',
            'custom_fields',
            'rating',
            'rating_comment',
            'request_id',
            'order_id',
            'store_id',
            'created_at',
            'modified_at',
            this.getTrackingDeatails(),
            this.getRequestItems(),
            this.getStatus()
        ]
    }

    getTrackingDeatails() {
        return new Field('tracking_numbers')
            .addField('tracking_code')
            .addField('tracking_id')
            .addField('is_customer')
            .addField('request_id')
            .addField('tracking_number')
    }

    getRequestItems() {
        return new Field('request_items')
            .addField('item_status_label_color')
            .addField('item_status_label')
            .addField('item_status')
            .addField('order_item_id')
            .addField('qty')
            .addField('condition_id')
            .addField('reason_id')
            .addField('request_id')
            .addField('request_item_id')
            .addField('request_qty')
            .addField('resolution_id')
            // .addField(OrderQuery._getOrderProductsField())
    }

    getProductData() {
        return new Field('order_products')
            .addField('data')
    }
    getStatus() {
        return new Field('status')
            .addField('color')
            .addField('label')
            .addField('state')
            .addField('status_id')
            .addField('title')
    }


    /**
     * Get RMA configuration fields
     */
    getRmaConfiguration() {
        return new Field('getAmRmaSettings')
            .addField('isEnabled')
            .addField('isChatEnabled')
            .addField('isReturnPolicyEnabled')
            .addField('isShowRmaInfoProductPage')
            .addField('isShowAdministratorContact')
            .addField('urlPrefix')
            .addField('policyUrl')
            .addField('isGuestRmaAllowed')
            .addField('administratorPhoneNumber')
            .addField('administratorEmail')
            .addField(this._getReturnReasonsFields())
            .addField(this._getItemConditionsFields())
            .addField(this._getItemResolutionsFields());
    }

    /**
     * Get return reasons fields
     * @returns {*}
     * @private
     */

    _getReturnReasonsFields() {
        return new Field('allReasons')
            .addField('reason_id')
            .addField('payer')
            .addField('label')
            .addField('position');
    }

    /**
     * Get item conditions fields
     * @returns {*}
     * @private
     */
    _getItemConditionsFields() {
        return new Field('allConditions')
            .addField('condition_id')
            .addField('label')
            .addField('payer')
            .addField('position');
    }

    /**
     * Get item resolutions fields
     * @returns {*}
     * @private
     */
    _getItemResolutionsFields() {
        return new Field('allResolutions')
            .addField('resolution_id')
            .addField('label')
            .addField('status')
            .addField('position');
    }

    /**
     * Get new return for guest mutation
     * @param options
     * @returns {*}
     */
    placeRmaGuestReturnRequest(options) {
        return new Field('placeRmaGuestReturnRequest')
            .addArgument('input', 'PlaceGuestRmaReturnRequestInput!', options)
            .addFieldList(this.getItemsList());
    }

    /**
     * Get new return mutation
     * @param options
     * @returns {*}
     */
     placeAmRmaCustomerReturnRequest(options) {
        return new Field('placeAmRmaCustomerReturnRequest')
            .addArgument('input', 'PlaceAmRmaReturnRequestInput!', options)
            .addFieldList(this.getItemsList());
    }
}

export default new ProductReturnQuery();
