/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */
import BrowserDatabase from 'Util/BrowserDatabase';
import Loader from 'Component/Loader';
import { lazy, PureComponent, Suspense } from 'react';
export const CheckoutBilling = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-billing" */
    'Component/CheckoutBilling'
    ));
const BANKING_STEP = 'BANKING_STEP';


const setBankingStep = (prop, instance) => {
    return {
        ...prop,
        [BANKING_STEP]: {
            title: __('Redirecting'),
            url: '/redirect',
            render: instance.renderBankStep.bind(instance),
            areTotalsVisible: false
        }
    }
}

const renderBankStep = (args, callback, instance) => {
    const {orderID, ActionUrl} = instance.props;
    window.location = ActionUrl;
}

const renderShippingStep = (args, callback, Instance) => {
    if(BrowserDatabase.getItem('completed_order_id')){
        BrowserDatabase.deleteItem('completed_order_id');
        BrowserDatabase.deleteItem('completed_order');
        window.location = '/cart';
        return ;
    }
    return callback.apply(Instance,args);
}

const renderBillingStep = (args, callback, instance) => {

    const {
        setLoading,
        setBankRedirectAdditionalData,
        setDetailsStep,
        shippingAddress,
        paymentMethods = [],
        savePaymentInformation,
        selectedShippingMethod,
        onShippingEstimationFieldsChange
    } = instance.props;

    return (
        <Suspense fallback={ <Loader /> }>
            <CheckoutBilling
                setLoading={ setLoading }
                paymentMethods={ paymentMethods }
                setDetailsStep={ setDetailsStep }
                onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                setBankRedirectAdditionalData={ setBankRedirectAdditionalData }
                shippingAddress={ shippingAddress }
                savePaymentInformation={ savePaymentInformation }
                selectedShippingMethod={ selectedShippingMethod }
            />
        </Suspense>
    );
}

export default {
    'Route/Checkout/Component': {
        'member-property': {
            'stepMap': setBankingStep
        },
        'member-function': {
            'renderBankStep': renderBankStep
            // 'renderShippingStep': renderShippingStep,
            // 'renderBillingStep': renderBillingStep
        }
    }
};
