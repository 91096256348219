import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';
import { isSignedIn } from 'Util/Auth';
import {  getGuestQuoteId } from 'Util/Cart';
import SwitchStoreQuery from '../query/SwitchStore';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);

export class StoreSwitcherContainer {


    
    _handleStoreSelect(args, callback, instance) {
        const [ storeCode ] = args;
        const { createGuestEmptyCart } = instance.props;

        if(!isSignedIn() && !getGuestQuoteId() ) {
          return createGuestEmptyCart().then(
                () => instance._switchStoreMutation(storeCode, instance)
            );
        }

        return instance._switchStoreMutation(storeCode, instance);
    }

    _switchStoreMutation(args) {
        const [ storeCode, instance ] = args;
        const { storeList } = instance.state;
        const { 
            currentCode,
            showErrorNotification,
            mfAlternateHreflang: { 
                items = [] 
            },
        } = instance.props;
        const { pathname } = location;
        const store = storeList.find(
            ({ value }) => value === storeCode
        );

        if (!store) {
            showErrorNotification(__('instance store can not be opened!'));
            return;
        }
        let hrefLangUrl = false;

        items.map(i => {
            if (i.hreflang.split('-')[0] === storeCode) {
                hrefLangUrl = i.href;
            }
        });

        BrowserDatabase.deleteItem(STORE_CONFIG_KEY);
        let options = {
            storeId: store.storeId,
            guestCartId: !isSignedIn() ? getGuestQuoteId() : null 
        };

        fetchMutation(SwitchStoreQuery.getQuery(options)).then(
            /** @namespace Component/StoreSwitcher/Container/onStoreFieldChangeFetchMutationThen */
            (status) => {
                if (hrefLangUrl && !pathname.includes('all/f/search')) {
                    window.location = hrefLangUrl;
                    return;
                }
                window.location = window.location.href.replace(`/${currentCode}/`, `/${storeCode}/`);
            },
            (error) => {
                const message = getErrorMessage(error);
                    showErrorNotification(message);
            }
        );
    }

    _getStoreList(args, callback, instance){
        const { storeList } = instance.props;
        const { storeList : stateStoreList  } = instance.state;
        if(storeList?.length && !stateStoreList.length ){
            instance.setState({
                storeList: instance._formatStoreList(storeList)
            })
        }
    }
    _formatStoreList(args, callback, instance){
        let storeList = args[0];
        return storeList.reduce((acc, {
            name, code, is_active, base_url, base_link_url, id
        }) => {
            if (!is_active) {
                return acc;
            }

            return [
                ...acc,
                {
                    id: `store_${ code }`,
                    storeId: id,
                    value: code,
                    storeUrl: base_url,
                    storeLinkUrl: base_link_url,
                    label: name
                }
            ];
        }, []);
    }

    mapStateToProps(args, callback, instance){
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            currentCode: state.ConfigReducer.code,
            storeList: state.ConfigReducer.storeList,
            mfAlternateHreflang : state.HrefLangReducer.mfAlternateHreflang
        };
    };

     mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            createGuestEmptyCart: () => CartDispatcher.then(
                ({ default: dispatcher }) => {
                    dispatcher.createGuestEmptyCart(dispatch);
                }
            ),
        }
    }
}

const { 
    _handleStoreSelect, 
    _getStoreList, 
    mapStateToProps, 
    _formatStoreList, 
    mapDispatchToProps,
    _switchStoreMutation
} = new StoreSwitcherContainer();

export const config = {
    'Component/StoreSwitcher/Container': {
        'member-function': {
            _switchStoreMutation: _switchStoreMutation,
            _handleStoreSelect: [
                {
                    position: 100,
                    implementation: _handleStoreSelect
                }
            ],
            _getStoreList: [
                {
                    position: 100,
                    implementation: _getStoreList
                }
            ],
            _formatStoreList: [
                {
                    position: 100,
                    implementation: _formatStoreList
                }
            ]
        }
    },
    'Component/StoreSwitcher/Container/mapDispatchToProps': {
        function: [
            {
                position: 100,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/StoreSwitcher/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
