export const RELATED_VIEWED_PRODUCT = 'RELATED_VIEWED_PRODUCT';
export const UPDATE_LOAD_STATUS = 'UPDATE_LOAD_STATUS';

/**
 * Add RelatedProducts product into list.
 * @param  {Object} product Product returned from fetch
 * @param  {String} store code
 * @return {void}
 * @namespace Store/RelatedProducts/Action/addRelatedProducts
 */
export const addRelatedProducts = (items) => ({
    type: RELATED_VIEWED_PRODUCT,
    items,
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/RelatedProducts/Action/updateLoadStatus
 */
export const updateLoadStatus = (status) => ({
    type: UPDATE_LOAD_STATUS,
    isLoading: status
});
