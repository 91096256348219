import CategoryPagination from 'SourceComponent/CategoryPagination';

const renderWidgetPagination = (args, callback, instance) => {
    const {
        isLoading,
        totalPages,
        requestPage,
        isPaginationEnabled,
        isWidget,
        visiblePage
    } = instance.props;

    if (isWidget && isPaginationEnabled) {
        const { updateOptions, updateVisiblePage } = instance.props;

        return (
            <CategoryPagination
                totalPages={totalPages}
                visiblePage={visiblePage}
                requestPage={requestPage}
                isLoading={isLoading}
                updateOptions={updateOptions}
                updateVisiblePage={updateVisiblePage}
                onPageSelect={(pageNumber) => requestPage(pageNumber)
                }
            />
        )
    }

    return callback(args, instance)
}

const requestPage = (_args, _callback, _instance) => {
    const [currentPage] = _args

    _instance.setState(({
        visiblePage: currentPage
    }))

    return _callback(_args, _instance)
}

const containerProps = (_args, _callback, _instance) => {
    const { visiblePage } = _instance.state;

    return {
        ..._callback.apply(_instance, _args),
        visiblePage
    };
};

export const config = {
    'Component/ProductList/Component': {
        'member-function': {
            renderPagination: renderWidgetPagination
        },
    },
    "Component/ProductList/Container": {
        'member-function': {
            requestPage: [
                {
                    position: 101,
                    implementation: requestPage
                }
            ],
            containerProps: [
                {
                    position: 101,
                    implementation: containerProps
                }
            ]
        }
    }
};

export default config;
