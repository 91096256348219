
import PropTypes from 'prop-types';
import Field from 'SourceComponent/Field';
import { PureComponent } from 'react';
import './MyAccountWishlistPopupNewCategroy.style';
import Loader from 'SourceComponent/Loader';
import ExpandableContent from 'SourceComponent/ExpandableContent';

/** @namespace Component/MyAccountWishlistPopupNewCategroy/Component */
export class MyAccountWishlistPopupNewCategroy extends PureComponent {

    renderNewCategooryField() {
        const { onChangeField, onSaveCategory, newCategory } = this.props;
        const isDisable = newCategory ? false : true;
        return (
            <div block="MyAccountWishlistPopupNewCategroy" elem="Field">
                <Field
                    name="MyAccountWishlistPopupNewCategroy"
                    type="input"
                    value={newCategory}
                    placeholder="Add New Category"
                    onChange={onChangeField}
                    maxLength='12'
                />
                <button
                    block="MyAccountWishlistPopupNewCategroy"
                    elem="Button"
                    disabled={isDisable}
                    mix={{ block: 'Button', mods: { isHollow: false } }}
                    onClick={onSaveCategory}
                >
                    {__("Add New Category")}
                </button>
                </div>
        )
    }

    render() {
        const {  isLoading } = this.props;

        return (
            <div
                block="MyAccountWishlistPopupNewCategroy"
            >
                <Loader isLoading={isLoading} />
                {this.renderNewCategooryField()}
            </div>
        );
    }
}