export const ENCHANCE_REGISTRATION = 'enhanced-registration';
export const WELCOME = 'getting-start';
export const PERSONAL_INFORMATION = 'personal-information';
export const ADDITIONAL_INFORMATION = 'additional-information';
export const THANK_YOU = 'thank-you';
export const IS_REDIRECT = 'IS_REDIRECT';


export const CUSTOM_ATTRIBUTES = [
    {
        attribute_code: "age_group",
        entity_type: "1"
    },
    {
        attribute_code: "hair_colour",
        entity_type: "1"
    },
    {
        attribute_code: "skin_type",
        entity_type: "1"
    },
    {
        attribute_code: "skin_concerns",
        entity_type: "1"
    },
    {
        attribute_code: "favorite_stores",
        entity_type: "1"
    },
    {
        attribute_code: "favorite_brands",
        entity_type: "1"
    },
    {
        attribute_code: "styling_type",
        entity_type: "1"
    }
];


export const beautifyLabel = (label) => {
    return label.replace(/_/g, ' ').split(' ')
    .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
    .join(' ');;
}

export const capitalizeFirstLetter = (string) => {
    const filterLable = string.indexOf('brand') !== -1 ? string.split("/")[1] : string;
    return filterLable.charAt(0).toUpperCase() + filterLable.slice(1);
  }
  

export const filterLabel = (v, options) => {
    let labelName = '';
    if(typeof(v) === 'object') {
        options.filter(({ value, label }) => {
            v.filter(( val ) =>  {
                if (value === val) {
                    labelName = [ ...labelName, <label> { capitalizeFirstLetter(label) } </label>];
                    }
                });
        });

        return [labelName];
    }

    options.filter(({ value, label }) => {
        if (v === value) {
            labelName = label;
        }
    });

    return labelName;
}

export const filterOptions = (options, search) => Object.values(options)?.filter(({ label }) =>
        label?.toLowerCase().includes(search?.toLowerCase())
    );

