import BankRedirectQuery from "../query/BankRedirect.query";
import BrowserDatabase from 'Util/BrowserDatabase';
import { UPDATE_CONFIG } from 'Store/Config/Config.action';

const addBankRedirectConfigQuery = (args, callback, instance) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        BankRedirectQuery.getBankRedirectConfiguration("mg_bank_redirect_winbank").setAlias('mg_bank_redirect_winbank'),
        BankRedirectQuery.getBankRedirectConfiguration("mg_bank_redirect_alphabank").setAlias('mg_bank_redirect_alphabank'),
        BankRedirectQuery.getBankRedirectConfiguration("mg_bank_redirect_eurobank").setAlias('mg_bank_redirect_eurobank'),
        BankRedirectQuery.getBankRedirectConfiguration("mg_bank_redirect_nbg_enterprise").setAlias('mg_bank_redirect_nbg_enterprise'),
        BankRedirectQuery.getBankRedirectConfiguration("banktransfer").setAlias('banktransfer')
    ];
};

const BankRedirectToConfigReducerInitialState = (args, callback, instance) => {
    const { mg_bank_redirect_winbank, mg_bank_redirect_alphabank, mg_bank_redirect_eurobank, mg_bank_redirect_nbg_enterprise } = BrowserDatabase.getItem('config') || { mg_bank_redirect_winbank: {}, mg_bank_redirect_alphabank : {}, mg_bank_redirect_eurobank : {}, mg_bank_redirect_nbg_enterprise : {} };
    return {
        ...callback(...args),
        mg_bank_redirect_nbg_enterprise,
        mg_bank_redirect_winbank,
        mg_bank_redirect_alphabank,
        mg_bank_redirect_eurobank
    };
};

const addBankRedirectToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);
    if (!action) {
        return originalUpdatedState;
    }
    const { config: { mg_bank_redirect_nbg_enterprise, mg_bank_redirect_winbank, mg_bank_redirect_alphabank, mg_bank_redirect_eurobank} = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        mg_bank_redirect_nbg_enterprise,
        mg_bank_redirect_winbank,
        mg_bank_redirect_alphabank,
        mg_bank_redirect_eurobank
    };
};


export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            'prepareRequest': addBankRedirectConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        'function': BankRedirectToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        'function': addBankRedirectToConfigUpdate
    },
};
