/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Popup from 'Component/Popup';
import Link from 'Component/Link';
import Image from 'Component/Image';

import {PRODUCT_SIZE_GUIDE_POPUP_ID} from './ProductSizeGuidePopup.config';

import './ProductSizeGuidePopup.style';

/** @namespace Component/ProductSizeGuidePopup/Component */
export class ProductSizeGuidePopup extends PureComponent {
    state = {};

    static propTypes = {
        openProductSizeGuidePopup: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    renderContent() {
        const { product : { brandSizeGuide }, isMobile } = this.props;

        if(!brandSizeGuide) return (
            <div className="ProductSizeGuidePopup-content">{__("No size guide found")}</div>
        );

        let {filePathMobile , filePath, fileType, fileTypeMobile} = brandSizeGuide;

        if(isMobile){
            filePathMobile = filePathMobile || filePath;
            fileTypeMobile = fileTypeMobile || fileType;
            return (
                <div className="ProductSizeGuidePopup-content">
                    {
                        fileType === 'pdf' ?
                            <div>
                                <iframe src={filePath} loading="lazy"
                                        style={{
                                            width:"600px",
                                            height:"500px"
                                        }}
                                        frameBorder="0"
                                >
                                </iframe>

                            </div>
                            :
                            <Image
                                src={ filePath }
                                alt={ filePath }
                            />
                    }
                </div>
            );
        }

        return (
            <div className="ProductSizeGuidePopup-content">
                {
                    fileType === 'pdf' ?
                        <div>
                            <iframe src={filePath} loading="lazy"
                                style={{
                                    width:"600px",
                                    height:"500px"
                                }}
                                frameBorder="0"
                            >
                            </iframe>

                        </div>
                        :
                        <Image
                            src={ filePath }
                            alt={ filePath }
                        />
                }
            </div>
        );
    }

    renderPopupContent() {
        return (
            <Popup
                id={ PRODUCT_SIZE_GUIDE_POPUP_ID }
                clickOutside={ false }
                mix={ { block: 'ProductSizeGuidePopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }

    renderPopupButton() {
        const { openProductSizeGuidePopup, showSizeGuideButton } = this.props;

        if(!showSizeGuideButton) {
            return null
        }

        return (
            <Link to={ '/' } block="ProductActions" elem="SizeGuideLink" onClick={ openProductSizeGuidePopup }>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.5" y="6.5" width="21" height="11" stroke="black"/>
                    <line x1="4.5" y1="12" x2="4.5" y2="17" stroke="black"/>
                    <line x1="8.5" y1="14" x2="8.5" y2="17" stroke="black"/>
                    <line x1="12.5" y1="12" x2="12.5" y2="17" stroke="black"/>
                    <line x1="15.5" y1="14" x2="15.5" y2="17" stroke="black"/>
                    <line x1="19.5" y1="12" x2="19.5" y2="17" stroke="black"/>
                </svg>
                <span>{ __('Size Guide') } </span>
            </Link>
        );
    }

    render() {
        return (
            <>
                { this.renderPopupButton() }
                { this.renderPopupContent() }
            </>
        );
    }
}

export default ProductSizeGuidePopup;
