import { lazy } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';

const BrandsPageWidget = 'BrandsPageWidget';

export const BrandsPageWidgetComponent = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-video" */
    '../component/BrandsPageWidget'
    ));

const renderContent = (args, callback, instance) => {
    const {
        type
    } = instance.props;

    const {
        component: Widget, fallback
    } = instance.renderMap[type] || {};

    if (Widget !== undefined && type === BrandsPageWidget) {
        return (
            <RenderWhenVisible fallback={ fallback }>
                <Widget
                  { ...instance.props }
                />
            </RenderWhenVisible>
        );
    }

    return callback.apply(instance, args);
};

export const renderMap = (props, instance) => ({
    ...props,
    [BrandsPageWidget]: {
        component: BrandsPageWidgetComponent
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderContent
        }
    }
};
