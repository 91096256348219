import Field from 'Component/Field';
import { CategorySort as SourceCategorySort } from 'SourceComponent/CategorySort/CategorySort.component';

/** @namespace Component/CategorySort/Component */
export class CategorySort extends SourceCategorySort {

    renderSortField() {
        const {
            sortKey,
            sortDirection,
            selectOptions,
            isMatchingInfoFilter,
            isMobile
        } = this.props;

        if (!isMatchingInfoFilter) {
            return this.renderPlaceholder();
        }

        return (
            <Field
              id="category-sort"
              name="category-sort"
              type="select"
              label={ __('Sort') }
              mix={ { block: 'CategorySort', elem: 'Select' } }
              selectOptions={ selectOptions }
              value={ `${sortDirection} ${sortKey}` }
              onChange={ this.onChange }
              isMobile={ isMobile }
              isLabelWithArrow
            />
        );
    }
}

export default CategorySort;
