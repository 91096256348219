import GiftCardDashBoard from "../component/GiftCardDashBoard";
import {GIFTCARDS} from "../type/Account";

export class MyAccountComponentPlugin {

 renderMap = (props, instance) => {
   return {
       ...props,
       [GIFTCARDS]: GiftCardDashBoard,
   }
 }
}
const { renderMap } = new MyAccountComponentPlugin();

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            'renderMap': renderMap,
        }
    }
};

