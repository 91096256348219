import { lazy } from "react";
import ModiFace from "../component/ModiFace";
import {
    CONFIGURABLE,
    filterConfigurableOptions
} from 'SourceUtil/Product';
import ModiFaceIcon from '../../util/assets/images/modiface-tag-gr.png';
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
));
import { isModifaceType } from '../../util/index';

import './ProductActions.style.plugin.scss';


const addModiFaceToProduct = (_args, _callback, _instance) => {
    const {
        product: {
            configurable_options = {},
            type_id,
            variants = {},
            attributes: { beauty_color: { 
                attribute_value: beautycolor = '',
                attribute_options: beautycolorOptions = {}
            } = {}, 
            // modiface_type: { attribute_options: category, attribute_value = '' } = {} 
        } = {},
        },
        areDetailsLoaded,
        product,
        updateConfigurableVariant,
        configurableVariantIndex,
        inStock
    } = _instance.props;

    const productOrVariant = areDetailsLoaded &&
        configurableVariantIndex !== -1 && 
        variants[configurableVariantIndex] ? variants[configurableVariantIndex] : 
        type_id !== CONFIGURABLE ? product : isModifaceType(variants)[0] || product;
    
    const { 
        modifaceData
    } = productOrVariant;
    
    const beautyColorOption = beautycolorOptions[beautycolor] ?
        beautycolorOptions[beautycolor] : {};

    if (!modifaceData || !areDetailsLoaded || modifaceData == 'true') {
        return _callback.apply(_instance, _args);
    }

    const category = JSON.parse(modifaceData)['shadeArray'][0]['category'];
    const { beauty_color } = filterConfigurableOptions(configurable_options, variants);
    const color = beauty_color !== undefined ? beauty_color : beautyColorOption;
 
    return (
        <>
            <ModiFace
                product={product}
                productType={type_id}
                color={ color }
                category={ category }
            />
            {_callback.apply(_instance, _args)}
        </>
    );
}

const renderProductAlerts = (_args, _callback, _instance) => {
    const {
        areDetailsLoaded,
        isInStockAlertEnabled,
        isPriceAlertEnabled,
        inStock
    } = _instance.props;
    
    if (
        (!isInStockAlertEnabled && !isPriceAlertEnabled)
        || !areDetailsLoaded || inStock
    ) {
        return _callback.apply(_instance, _args);
    }

    return (
        <div block="ProductActions" elem="AddToCartWrapper"> 
            { addModiFaceToProduct(_args, _callback, _instance) } 
        </div>
        );
}

const roundRenderHeading = (_args, _callback, _instance) => {
    const {
        product: {
            type_id,
            variants = {} 
        } = {},
        product,
        configurableVariantIndex,
        areDetailsLoaded
    } = _instance.props;
    
    const productOrVariant = areDetailsLoaded &&
        configurableVariantIndex !== -1 && 
        variants[configurableVariantIndex] ? variants[configurableVariantIndex] : 
        type_id !== CONFIGURABLE ? product : isModifaceType(variants)[0] || product;

    const { 
        modifaceData
    } = productOrVariant;

    if (!modifaceData || !areDetailsLoaded) {
        return _callback.apply(_instance, _args);
    }



    return (
        <div block="ProductActions" elem="Top">
            { _callback(..._args) }
            <div block="ProductActions" elem="Modiface">
                <UniversalIcon
                    src={ModiFaceIcon}
                    alt="Modiface-Type"
                    className="ProductActions-ModifaceIcon"
                />
            </div>
        </div>
    );
}

export const config = {
    'Component/ProductActions/Component': {
        'member-function': {
            'renderAddToCart': addModiFaceToProduct,
            'renderProductAlerts': renderProductAlerts,
            'renderHeading' : roundRenderHeading
        }
    }
};

export default config;