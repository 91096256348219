import { PureComponent } from 'react';
import { connect } from "react-redux";
import BundlerItem from './BundlerItem.component';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED
} from 'Util/Product';

import {
    FIXED,
    PERCENTAGE
} from "../BundlerSection/BundlerSection.conf";

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Component/BundlerItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    configurableProduct: state.ProductReducer.product,
    // bundlerItems: state.LinkedProductsReducer.linkedProducts.bundler
});
/** @namespace Component/BundlerItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
})

/** @namespace Component/BundlerItem/Container */
class BundlerItemContainer extends PureComponent {
    state = {
        totalPrice: null,
        discountedPrice: null,
        currency: "EUR",
        variant: {},
        selectedOption: {},
    }

    componentDidMount() {
        const {
            currency,
            mainProductValue,
            item,
        } = this.props;

        if (item.type_id === CONFIGURABLE && item.isConfigurableBundler) {
            return this.setState({
                totalPrice: mainProductValue,
                currency,
                discountedPrice: mainProductValue
            })
        }
        let productOrVariant = { ...item };
        if (item.type_id === CONFIGURABLE && !item.isConfigurableBundler) {
            let productVariant = item.variants?.filter((v) => {
                return v.sku === item.linked_product_sku;
            });
            productOrVariant.price_range = productVariant[0].price_range;
            productOrVariant.stock_status = productVariant[0].stock_status;
            productOrVariant.image = productVariant[0].image;
            productOrVariant.thumbnail = productVariant[0].thumbnail;
            productOrVariant.small_image = productVariant[0].small_image;
        }

        this.setState({ variant: productOrVariant });
        const { price_range: { minimum_price: { final_price: { value } } } } = productOrVariant;
        let discountedPrice = 0;

        switch (item.discount_type) {
            case FIXED:
                discountedPrice = mainProductValue + (value - item.discount);
                break;
            case PERCENTAGE:
                discountedPrice = mainProductValue + (value - item.discount / 100 * value);
                break;
        }

        let totalPrice = mainProductValue + value;

        return this.setState({
            totalPrice,
            currency,
            discountedPrice
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { item, currency, mainProductValue } = this.props
        const { mainProductValue: prevValue } = prevProps

        const { selectedOption } = this.state;
        const { selectedOption: prevSelectedOption } = prevState
        const { sku } = selectedOption;
        const {
            sku: prevSKU
        } = prevSelectedOption

        if (prevSKU !== sku || mainProductValue !== prevValue) {
            const productOrVariant = item.variants.find(variant => variant.sku === sku)
            if (!productOrVariant) {
                return this.setState({
                    totalPrice: mainProductValue,
                    currency,
                    discountedPrice: mainProductValue,
                })
            }

            const { price_range: { minimum_price: { final_price: { value: value = 0 } = {} } = {} } = {} } = productOrVariant;

            let discountedPrice = value;
            let totalPrice = mainProductValue + value;

            switch (item.discount_type) {
                case FIXED:
                    discountedPrice = mainProductValue + (value - item.discount);
                    break;
                case PERCENTAGE:
                    discountedPrice = mainProductValue + (value - item.discount / 100 * value);
                    break;
            }
            return this.setState({
                totalPrice,
                currency,
                discountedPrice,
            })
        }
    }

    containerFunctions = {
        handleSelect: this.handleSelect.bind(this)
    };

    handleSelect(item) {
        this.setState({ selectedOption: item });
    }

    render() {
        return (
            <BundlerItem
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
            />
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(BundlerItemContainer);
