import { connect } from 'react-redux';
import { CartItemContainer as SourceCartItemContainer, mapStateToProps as SourceMapStateToProps, mapDispatchToProps } from 'SourceComponent/CartItem/CartItem.container';
import { BRAND_CHANEL } from 'Route/ProductPage/ProductPage.config';
import CartItem from './CartItem.component';


/** @namespace Component/CartItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...SourceMapStateToProps(state),
    totals: state.CartReducer.cartTotals,
});

/** @namespace Component/CartItem/Container */
export class CartItemContainer extends SourceCartItemContainer {
    state = { isLoading: false, isChanel: false, hasLimitation: false };

    containerProps = () => {
        const {
            isSampleProducts,
            item,
            currency_code,
            isEditing,
            isCartOverlay,
            isMobile,
            cartPage,
            showLoader,
            isCheckout
        } = this.props;
        const { isLoading, hasLimitation } = this.state;

        return {
            item,
            isSampleProducts: isSampleProducts ? isSampleProducts : false,
            currency_code,
            isEditing,
            isCartOverlay,
            isMobile,
            cartPage,
            isLoading,
            showLoader,
            linkTo: this._getProductLinkTo(),
            thumbnail: this._getProductThumbnail(),
            minSaleQuantity: this.getMinQuantity(),
            maxSaleQuantity: this.getMaxQuantity(),
            isProductInStock: this.productIsInStock(),
            optionsLabels: this.getConfigurableOptionsLabels(),
            isMobileLayout: this.getIsMobileLayout(),
            isMaxSaleQtyExceed: this.isMaxSaleQtyExceed(),
            hasLimitation,
            isCheckout
        };
    };

    componentDidMount() {
        const { item } = this.props;

        const isCurrentChanel = item?.product?.attributes?.brand?.attribute_value && item?.product?.attributes?.brand?.attribute_options[item.product.attributes.brand.attribute_value]?.label === BRAND_CHANEL;
        const isCurrentLimited = item?.product?.attributes?.limitation?.attribute_value && item?.product?.attributes?.limitation?.attribute_value !== "0";

        if (isCurrentChanel)
            this.setState({ isChanel: isCurrentChanel })
        if (isCurrentLimited)
            this.setState({ hasLimitation: isCurrentLimited })
    }

    isMaxSaleQtyExceed() {
        const {
            item: { discount_amount, row_total_incl_tax, sku, qty: mainProductQty },
            totals: { items = [] } = {},
        } = this.props;

        const freeGiftItem = items.find((item) => item.sku === sku && item.discount_amount === item.row_total_incl_tax);
        const totalQuantity = freeGiftItem ? mainProductQty + freeGiftItem.qty : mainProductQty;

        return this.getMaxQuantity() < totalQuantity && discount_amount !== row_total_incl_tax;
    }

    getMaxQuantity() {
        const {
            item,
            totals: { items = [] },
            item: { qty: mainProductQty },
        } = this.props;
        const { isChanel } = this.state


        // count how many items in the cart are of the brand chanel
        const channelItems = items?.filter((cartItem) => {
            return cartItem.product.attributes?.brand?.attribute_value
                && cartItem.product.attributes.brand.attribute_options[cartItem.product.attributes.brand.attribute_value]?.label === BRAND_CHANEL
        })
        const sumChannelItems = channelItems?.reduce((sum, item) => { return sum + item.qty }, 0)

        if (isChanel && sumChannelItems >= 15) {
            return mainProductQty;
        }

        return item?.product?.stock_item?.max_sale_qty;
    }

    getConfigurationOptionLabel = ([key, attribute]) => {
        const {
            item: {
                product: {
                    configurable_options = {}
                }
            }
        } = this.props;

        const { attribute_code, attribute_value } = attribute;
        let attribute_with_label = {};

        if (!Object.keys(configurable_options).includes(key) || attribute_value === null) {
            return null;
        }

        const {
            [attribute_code]: { // configurable option attribute
                attribute_label,
                attribute_options: {
                    [attribute_value]: { // attribute option value label
                        label
                    } = {}
                } = {}
            } = {}
        } = configurable_options;

        attribute_with_label.label = attribute_label;
        attribute_with_label.value = label;

        return attribute_with_label;
    };

    getConfigurableOptionsLabels() {
        const {
            item: {
                product: {
                    configurable_options,
                    variants
                }
            }
        } = this.props;

        if (!variants || !configurable_options) {
            return [];
        }

        const { attributes = [] } = this.getCurrentProduct() || {};

        return Object.entries(attributes).map(this.getConfigurationOptionLabel).filter((label) => label);
    }

    render() {
        const { isLoading } = this.state;

        return (
            <CartItem
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemContainer);
