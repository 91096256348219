import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { SAMPLE_PRODUCTS } from '../route/SampleProductCategoryPage/SampleProductCategoryPage.config'
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const SampleProductCategoryPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "sample-products" */ 
    '../route/SampleProductCategoryPage')
    );

export class RouterPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex(`/${ SAMPLE_PRODUCTS }`) } exact component={ SampleProductCategoryPage } />,
            position: 101
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    'Route/UrlRewrites/Component': {
        'member-property': {
            SWITCH_ITEMS_CUSTOM: SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
