export const WebsiteSwitch = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
    '../store/WebSwitch/WebSwitch.dispatcher'
);


const mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
        ...callback.apply(instance, args),
        isFashion: state.switchWebsiteReducer.isFashion
    }
};

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        switchWebsite: (options) => WebsiteSwitch.then(
            ({ default: dispatcher }) => dispatcher.switchWebsite(options, dispatch)
            )
    };
};


const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite : {
            is_fashion = false,
            type
        },
        urlRewrite,
        isFashion,
        switchWebsite
    } = instance.props;

    if(isFashion !== is_fashion && (type !== "CMS_PAGE" && type !== undefined)) {
        if(type !== 'BRAND') {
            switchWebsite(is_fashion);
        }
    }

    return callback.apply(args,instance);
}


export const config = {
    'Route/UrlRewrites/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Route/UrlRewrites/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps: [
                {
                    position:117,
                    implementation: getTypeSpecificProps
                }
            ]
        }
    }
};

export default config;
