import { UPDATE_HREF_LANGS } from './HrefLang.action';

export const initialState = {
    mfAlternateHreflang: {}
};

/** @namespace ScandiPWA/HrefLang/Store/HrefLang/Reducer/HrefLangReducer */
export const HrefLangReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
    case UPDATE_HREF_LANGS:
        const { mfAlternateHreflang } = action;

        return {
            ...state,
            mfAlternateHreflang
        };
    default:
        return state;
    }
};

export default HrefLangReducer;
