/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import validationConfig from '../component/FieldPhone/FieldPhone.config';
import {CHECKBOX_TYPE, FILE_TYPE} from "@scandipwa/scandipwa/src/component/Field/Field.config";

export class FieldContainerPlugin {

    validateField(instance) {
        const {
            id,
            formRef: refMap,
            formRefMap
        } = instance.props;
        const { areaCode } = instance.state;

        if (!areaCode || !id || !refMap || !refMap.current) {
            return {};
        }

        const { current: inputNode } = refMap || {};

        if (!inputNode) {
            return {};
        }

        // we are looking for validation and executing it
        const rule = ['isNotEmpty',areaCode].find((rule) => {
            if (!validationConfig[rule]) {
                return false;
            }

            const validationRules = validationConfig[rule];
            const isValid = validationRules.validate(inputNode, formRefMap);

            return !isValid;
        });

        return validationConfig[rule] || {maxlength : validationConfig[areaCode].maxlength };
    }

    setValidationMessage = (args, callback, instance) => {
        if(instance.props.type === 'telephone'){
            return ;
        }
        return callback.apply(instance,args);
    }

    updateValidationStatus = (args, callback, instance) => {
        if(instance.props.type === 'telephone'){
            const validationRule = this.validateField(instance);
            instance.setState({
                validationStatus: !validationRule.validate,
                validationMessage: validationRule.message,
                maxlength: validationRule.maxlength
            });
            return ;
        }
        return callback.apply(instance,args);
    };

    setAreaCode = (args, callback, instance) => {
        instance.setState({ areaCode: args[0] });
    }

    containerFunctions = (prop, instance) => {
        return {
            ...prop,
            setAreaCode : instance.setAreaCode.bind(instance)
        }
    }
    containerProps (args, callback, instance) {
        return {
            ...callback(args, instance),
            maxlength : instance.state.maxlength
        };
    }

    componentDidUpdate (args, callback, instance) {
        const { type, formRefMap, formRef } = instance.props;
        const prevProps = args[0];
        if(type === 'telephone'){
            const { value: prevValue, checked: prevChecked, isSubmitted: prevSubmitted } = prevProps;
            const {
                value: currentValue,
                checked: currChecked,
                type,
                id,
                validateSeparately,
                /**
                 * @Todo this should be monitored and submited by parent form
                 *
                  */
                isSubmitted
            } = instance.props;
            const { eventId } = instance.state;

            if (prevValue !== currentValue) {
                // eslint-disable-next-line react/no-did-update-set-state
                instance.setState({ value: currentValue });
            }
            if (type === CHECKBOX_TYPE && currChecked !== prevChecked) {
                // eslint-disable-next-line react/no-did-update-set-state
                instance.setState({ checked: currChecked });
            }
            let validate = false;
            Object.values(formRefMap).map(element => {
                if(element.current?.value && element.current.type === 'text' && element.current.type === 'text' && element.current.id !== 'telephone')
                    validate = true;
            })
            if(!Object.keys(formRefMap).length)
                validate = true;

            // prevents validating all fields when entering data in only one of them
            if (isSubmitted || formRef.current.value.length > 3) {
                instance.updateValidationStatus();
                instance.setValidationMessage(prevProps);
            }
        }else {
            return callback.apply(instance,args);
        }
    }
}

const {  containerProps, setAreaCode, containerFunctions, updateValidationStatus, setValidationMessage, componentDidUpdate } = new FieldContainerPlugin();

export const config = {
    'Component/Field/Container': {
        'member-function': {
            updateValidationStatus : updateValidationStatus,
            setAreaCode : setAreaCode,
            componentDidUpdate : componentDidUpdate,
            containerProps : containerProps
        },
        'member-property': {
            'containerFunctions': containerFunctions
        }
    }
};

export default config;
