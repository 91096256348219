/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-bind */
import { formatPrice } from 'SourceUtil/Price';
import { appendWithStoreCode } from 'SourceUtil/Url';
import {ORDER_PLACED, TRACK_ORDER} from '../route/TrackOrder/TrackOrder.config';

class MyAccountOrderTableRowComponentPlugin {
    // adds `return` link to each order row
    renderTrackLink(_instance) {
        const {
            order: {
                base_order_info: {
                    increment_id,
                    state
                }
            }
        } = _instance.props;

        if(state === 'canceled')
            return null;

        let buttonClass = 'MyAccountOrderTableRow-Button Button';
        const url = `/${TRACK_ORDER}?orderId=${increment_id}`;

        return (
            <a href={ appendWithStoreCode(url) } className={ buttonClass }>
                { __('Track Order') }
            </a>
        );
    }

    render = (_args, _callback, instance) => {
        const {
            base_order_info: {
                created_at,
                status_label,
                increment_id,
                grand_total,
                id,
                amrma_is_returnable
            },
            onViewClick,
            currency_code
        } = instance.props;

        let statusLabelClass = status_label;

        if (statusLabelClass === 'ΚΑΤΑΧΩΡΗΘΗΚΕ') {
            statusLabelClass = 'Pending';
        }

        if (statusLabelClass === 'ΑΚΥΡΩΘΗΚΕ') {
            statusLabelClass = 'Canceled';
        }

        if (statusLabelClass === 'ΟΛΟΚΛΗΡΩΘΗΚΕ') {
            statusLabelClass = 'Complete';
        }

        return (
                <div block="MyAccountOrderTableRows">
                    <div block="MyAccountOrderTableRow order_no">
                        <label>{ __('Αριθμός παραγγελίας:') }</label>
                        <span>{ increment_id ? `#${increment_id}` : '' }</span>
                    </div>
                    <div className="MyAccountOrderTableRow_seperator"></div>
                    <div block="MyAccountOrderTableRow">
                        <label>{ __('Ημερομηνία παραγγελίας') }</label>
                        <span>{ instance.getFormattedDate(created_at) }</span>
                    </div>
                    <div block="MyAccountOrderTableRow">
                        <label className={ statusLabelClass }>{ status_label }</label>
                    </div>
                    <div className="MyAccountOrderTableRow_seperator"></div>
                    <div block="hidden-mobile" className="MyAccountOrderTableRow total">
                        <label>{ __('Σύνολο παραγγελίας:') }</label>
                        <span>{ grand_total ? formatPrice(grand_total, currency_code) : '' }</span>
                    </div>
                    <div block="MyAccountOrderTableRow Actions">
                        { this.renderTrackLink(instance) }
                        { instance.renderViewLink(onViewClick) }
                    </div>
                </div>
        );
    };
}

const { render } = new MyAccountOrderTableRowComponentPlugin();

export default {
    'Component/MyAccountOrderTableRow/Component': {
        'member-function': {
            render
        }
    }
};
