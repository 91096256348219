import CategoryPriceRange from '../component/CategoryPriceRange';
const renderPriceRange = (args, callback, instance) => {
    return (
        <CategoryPriceRange
            key="price"
            options={args[0]}
        />
    );
}


export const config = {
    'Component/CategoryConfigurableAttributes/Component': {
        'member-function': {
            renderPriceSwatch: [
                {
                    position:100,
                    implementation: renderPriceRange
                }
            ]
        }
    }
};

export default config;
