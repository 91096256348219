/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ContactForm as SourceContactForm } from 'SourceComponent/ContactForm/ContactForm.component.js';

/** @namespace Component/ContactForm/Component */
export class ContactForm extends SourceContactForm {

    state = {
        isSubmitted : false
    };

    onFormError = this.onFormError.bind(this);

    // necessary implementation for custom telephone field
    onFormError(){
        this.setState({ isSubmitted : true});
    }

    get fieldMap() {
        const { isSubmitted } = this.state;
        return {
            name: {
                validation: ['notEmpty'],
                label: __('Name')
            },
            email: {
                validation: ['notEmpty', 'email'],
                label: __('Email')
            },
            telephone: {
                validation: ['notEmpty','telephone'],
                type : 'telephone',
                label: __('Phone number'),
                isSubmitted : isSubmitted
            },
            message: {
                type: 'textarea',
                validation: ['notEmpty'],
                label: __('What\'s on your mind?')
            }
        };
    }
}

export default ContactForm;
