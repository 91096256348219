import { showPopup as PopupSource } from 'SourceStore/Popup/Popup.action';
import media, { WYSIWYG_MEDIA } from "SourceUtil/Media/Media";
import Popup from 'SourceComponent/Popup';
import WishlistPopup from '../component/WishlistPopup';
import { WISHLIST_POPUP, PRODUCTLIST_POPUP, MULTITAB_POPUP } from '../utils/wishlist.config'
import { isSignedIn } from 'SourceUtil/Auth';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { ERROR_CONFIGURABLE_NOT_PROVIDED } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.config';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { history } from 'SourceUtil/History';
import { SAMPLE_CATEGORY, BAG_CATEGORY, CARDS_CATEGORY } from 'Route/ProductPage/ProductPage.config';

import {
    EVENT_GTM_PRODUCT_CLICK,
    EVENT_GTM_PRODUCT_REMOVE_WISH_LIST,
    EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST
} from '@mageguide/gtm-new/src/component/GoogleTagManager/GoogleTagManager.events';
import { event } from '@mageguide/gtm-new/src/store/GoogleTagManager/GoogleTagManager.action';
import getStore from 'SourceUtil/Store';


import {
    BUNDLE,
    CONFIGURABLE,
    DOWNLOADABLE,
    getExtensionAttributes,
    GROUPED
} from 'SourceUtil/Product';
import React, {lazy} from "react";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

const afterToggleProductInWishlist = (args, instance) => {
        let isAdded = args;
        const { dispatch } = getStore();

        if(!isAdded){
            const {
                product,
                product: { type_id, variants },
                quantity,
                configurableVariantIndex,
                groupedProductQuantity
            } = instance.props;
    
            if (type_id === 'grouped') {
                dispatch(event(EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST, {
                    product: {
                        ...product,
                        quantities: groupedProductQuantity
                    },
                    isGrouped: true
                }));
            } else {
                const productToAdd = variants
                    ? { ...product, configurableVariantIndex }
                    : product;
    
                dispatch(event(EVENT_GTM_PRODUCT_ADD_TO_WISH_LIST, {
                    product: productToAdd,
                    quantity,
                    configurableVariantIndex
                }));
            }
        }
};    

class ProductWishlistButtonPlugin {
    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
        showPopup: (type, payload) => dispatch(PopupSource(type, payload))
        }
    };

    defaultProps(args) {
        return {
            ...args,
            isProductPopup: false,
            isProductList: false,
            isCartItem: false
        }
    }



    isDisabled = (args, callback, instance) => {
        const { isAddingWishlistItem, isLoading } = instance.props;
        const product = instance._getProductVariant();

        // if (product === ERROR_CONFIGURABLE_NOT_PROVIDED) {
        //     return true;
        // }

        return isAddingWishlistItem || isLoading;
    };

    containerFunctions(args, instance){
        return {
            ...args,
        showPopup: showPopup.bind(args, instance),
        setLoading: setLoading.bind(args, instance)
        }
    };

    containerProps(args, callback, instance) {
        const { productsInWishlist, isProductList, isCartItem } = instance.props;

        return {
            ...callback.apply(instance, args),
            productsInWishlist,
            isProductList,
            isCartItem
        };
    }

    toggleProductInWishlist = (args = true, callback, instance) =>{
        const [ add, options = {} ] = args;
        const { categoryId = 0 } = options;
 
        const {
            product: { 
                categories= [], 
                sku, 
                type_id, 
                id,
                price_range: {
                    minimum_price: {
                        final_price: {
                            value: minimalPriceValue
                        } = {},
                    } = {}
                } = {},
            } = {},
            quantity,
            isAddingWishlistItem,
            showNotification,
            productsInWishlist,
            addProductToWishlist,
            onProductValidationError,
            removeProductFromWishlist
        } = instance.props;

        const isDisable = categories.filter(({ id })=> id == SAMPLE_CATEGORY || id == CARDS_CATEGORY)?.length !== 0;

        if(isDisable || minimalPriceValue < 0.5) {
            return showNotification('info', __("You can't add this product to wishlist"));
        }

        // if (!isSignedIn()) {
        //     return showNotification('info', __('You must login or register to add items to your wishlist.'));
        // }

        if (isAddingWishlistItem) {
            return null;
        }

        const product = instance._getProductVariant();
        if (product === ERROR_CONFIGURABLE_NOT_PROVIDED) {
            onProductValidationError(type_id);

            return showNotification('info', __('Please, select desirable option first!'));
        }

        instance.setWishlistButtonLoading(true);

        const { sku: variantSku, product_option } = product;
        if (add) {
            const item = !isSignedIn() ?  { 
                sku, product_option, quantity 
            } : { 
                sku, 
                product_option, 
                quantity, 
                id, 
                options,
                categoryId
            }

            return addProductToWishlist(item);
        }

        const { wishlist: { id: item_id } } = Object.values(productsInWishlist).find(
            ({ wishlist: { sku } }) => sku === variantSku
        );

        return removeProductFromWishlist({ item_id, sku: variantSku });
    }

    _getProductVariant(args, callback, instance) {
        const {
            product,
            product: { type_id },
            configurableVariantIndex
        } = instance.props;

        if (type_id === CONFIGURABLE) {
            if (configurableVariantIndex < 0) {
                return ERROR_CONFIGURABLE_NOT_PROVIDED;
            }

            const extension_attributes = getExtensionAttributes({ ...product, configurableVariantIndex });
            const variant = product.variants[configurableVariantIndex];

            return { ...variant, product_option: { extension_attributes } };
        }

        if (type_id === GROUPED) {
            const {
                groupedProductQuantity = {}
            } = this.props;

            const grouped_product_options = Object.entries(groupedProductQuantity).map((option) => ({
                option_id: option[0],
                option_value: option[1]
            }));

            return { ...product, product_option: { extension_attributes: { grouped_product_options } } };
        }

        if (type_id === BUNDLE) {
            const {
                productOptionsData: {
                    productOptions
                }
            } = this.props;

            const extension_attributes = getExtensionAttributes({ ...product, productOptions });

            return { ...product, product_option: { extension_attributes } };
        }

        if (type_id === DOWNLOADABLE) {
            const {
                productOptionsData: {
                    downloadableLinks
                }
            } = this.props;

            const extension_attributes = getExtensionAttributes({ ...product, downloadableLinks });

            return { ...product, product_option: { extension_attributes } };
        }

        return product;
    }
    
    showPopup(instance, args) {
        const { 
            showPopup, 
            isProductList, 
            isCartItem,
            product: { name, sku, id, type_id, url }, 
            quantity,
            onProductValidationError, 
            showNotification,
            isProductPopup,
            productsInWishlist,
            removeProductFromWishlist,
            isMultiTab,
            tabId,
            configurableVariantIndex,
            position,
            type,
            widgetId,
            title: heading
        } = instance.props;
        const { dispatch } = getStore();

        const {   
        containerFunctions: {
            addToWishlist,
            removeFromWishlist
            }
        } = instance

        const { storeConfig: { is_guestwishlist_enable: isGuestWishlistEnable = {} } } = BrowserDatabase.getItem('config') || {};

        if(instance.isInWishlist()) {
            const item = instance.getProductInWishListProductListing(args, instance);
            const { wishlist: { id, sku: variantSku, quantity }, variants } = item[0];
            const configurableVariantIndex = variants.findIndex((variant) => variant.sku === variantSku);
            
            dispatch(event(EVENT_GTM_PRODUCT_REMOVE_WISH_LIST, {
                product: instance.props.product,
                quantity,
                configurableVariantIndex
            } ));
            return removeProductFromWishlist({ item_id: id, sku: variantSku });
        }

        if(type_id === CONFIGURABLE && position) {
            showNotification('info', __('Please, select desirable option first!'));
            
            return history.push(url);
        }

        var title = isProductList ? name : __('ADD PRODUCT TO WISHLIST');
        const popupType = !isProductPopup && isProductList && !isCartItem && type_id === CONFIGURABLE ? 
            heading ? `${PRODUCTLIST_POPUP}-${heading}` : `${PRODUCTLIST_POPUP}${widgetId ? `-${widgetId}` : ''}` : `${WISHLIST_POPUP}${widgetId ? `-${widgetId}` : ''}`;

        const product = instance._getProductVariant();
        const tab = isMultiTab ? `${MULTITAB_POPUP}-${tabId}` : popupType;

        if (product === ERROR_CONFIGURABLE_NOT_PROVIDED && (!isProductList || isProductPopup)) {
            onProductValidationError(type_id);

            return showNotification('info', __('Please, select desirable option first!'));
        }

        if (!isSignedIn()) {
            if(isGuestWishlistEnable != 1) {
                return showNotification(
                "info",
                __("You must login or register to add items to your wishlist.")
                );
            }
            
            if (product === ERROR_CONFIGURABLE_NOT_PROVIDED && (isProductList || popupType !== WISHLIST_POPUP)) {
                return showPopup(
                    tab,
                    { 
                      addToWishlist: (product, quantity, options ) => addToWishlist(options),
                      removeFromWishlist: () => removeFromWishlist(),
                      title,
                      product,
                      sku,
                      id 
                    }
                    );
            }
            if (instance.isInWishlist()) {
                // dispatch(event(EVENT_GTM_PRODUCT_REMOVE_WISH_LIST, ...instance.props.product ));
                return removeFromWishlist();
            } else {
                afterToggleProductInWishlist(instance.isInWishlist(), instance);
                return addToWishlist();
            }

        }

        if(instance.isInWishlist()) {
            title = __('REMOVE PRODUCT FROM WISHLIST');
        }

        setLoading(true, instance);
        dispatch(event(EVENT_GTM_PRODUCT_CLICK, { ...instance.props.product }));

        return showPopup(
            tab,
            { 
              addToWishlist,
              removeFromWishlist: () => removeFromWishlist(),
              title,
              product,
              sku,
              id 
            }
            );
    }

    getProductInWishListProductListing(args, callback, instance) {
        const {
            product: { sku, type_id, variants },
            productsInWishlist,
        } = instance.props;

        return Object.values(productsInWishlist).filter(({ sku: ParentSku, wishlist, wishlist: { sku: wishlistSku, item_id } }) => {
            if (ParentSku === sku) {
                if (type_id === CONFIGURABLE) {
                    return Object.values(variants).filter((sku) => {
                        if (sku === wishlistSku) {
                            {
                                wishlistSku,
                                    item_id
                            }
                        }
                    }
                    );
                }
                return wishlist;
            }
        });
    }

    isInWishlist = (args, callback, instance) => {
        const { productsInWishlist, isProductList, product, configurableVariantIndex } = instance.props;

        if (product) {
            if (product === ERROR_CONFIGURABLE_NOT_PROVIDED) {
                return false;
            }

            const { sku: productSku, variants = [] } = product;
            return Object.values(productsInWishlist).findIndex(({ sku: ParentSku, wishlist: { sku: wishlistSku } }) => {
                if (ParentSku === productSku && variants.length > 0) {

                    if (configurableVariantIndex > 0) {
                        return variants[configurableVariantIndex]?.sku === wishlistSku;
                    }
                    return Object.values(variants).findIndex((sku) => sku === wishlistSku);
                }

                return wishlistSku === productSku || productSku === ParentSku;
            }) >= 0;
        }

        return callback.apply(instance, args);
    };

    setLoading(isLoading, instance){
        instance.setState({ isWishlistButtonLoading: !isLoading });
    }

    renderWishlistPopup(args, callback, instance){
        const { product: { id }, isProductList, isCartItem } = instance.props;

        if(isProductList || isCartItem) {
            return;
        }
        
        return (
            <Popup
            id={ WISHLIST_POPUP }
            clickOutside={ false }
            mix={ { block: 'ProductActionWishlistPopup' } }
          >
              <WishlistPopup { ...instance.props }/>
          </Popup>
        )
    }

    renderButton(args, callback, instance) {
        const { 
            isInWishlist, 
            isDisabled, 
            mix, 
            showPopup, 
            product: {
                price_range: {
                    minimum_price: {
                        final_price: {
                            value: minimalPriceValue
                        } = {},
                    } = {}
                } = {},
            } = {},
            isCart = false, 
            isProductList = false, 
            isProductPopup = false, 
            } = instance.props;

        let wishlist_image = 'wishlist_small.svg';

        if(isInWishlist) {
            wishlist_image = 'wishlist_small_fill.svg';
        }

        if(!minimalPriceValue > 0.5) {
            return null;
        }

        if(isProductPopup) {
            return (
                <button
                    rel='nofollow'
                    block="ProductWishlistButton"
                    elem="Button"
                    mods={ { isInWishlist, isDisabled } }
                    mix={ { block: 'Button', mix } }
                    title={ __('Save to favorites') }
                    onClick={ showPopup }
                    className={'ProductWishlistButton-Button_Popup'}
                >
                    <UniversalIcon
                        src={media(wishlist_image, WYSIWYG_MEDIA)}
                        alt="HeartIcon"
                        className="HeartIcon"
                    />
                    <span >{ __('Add To Wishlist') }</span>
                </button>
            );
        }

        if(isCart) {
            return (
                <button
                    block="ProductWishlistButton"
                    elem="Button"
                    mods={ { isInWishlist, isDisabled } }
                    mix={ { block: 'Button', mix } }
                    title={ __('Save to favorites') }
                    onClick={ showPopup }
                >
                    <UniversalIcon
                        src={media(wishlist_image, WYSIWYG_MEDIA)}
                        alt="HeartIcon"
                        className="HeartIcon"
                    />
                    <span>{ __('Save to favorites') }</span>
                </button>
            );
        }

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mix } }
              title={ __('Save to favorites') }
              onClick={ showPopup }
            >
                <UniversalIcon
                    src={media(wishlist_image, WYSIWYG_MEDIA)}
                    alt="HeartIcon"
                    className="HeartIcon"
                />
            </button>
        );
    }

    renderContent(args, callback, instance) {

        return (
            <div block="ProductWishlistButton">
                { instance.renderButton() }
                { instance.renderWishlistPopup() }
                { instance.renderLoader() }
            </div>
        );
    }
}

const {
    renderButton,
    renderWishlistPopup,
    showPopup,
    containerFunctions,
    mapDispatchToProps,
    setLoading,
    containerProps,
    defaultProps,
    toggleProductInWishlist,
    getProductInWishListProductListing,
    renderContent,
    isInWishlist,
    isDisabled
 } = new ProductWishlistButtonPlugin();

export default {
    'Component/ProductWishlistButton/Container/mapDispatchToProps': {
        function: {
            position: 101,
            implementation: mapDispatchToProps
        }
    },
    'Component/ProductWishlistButton/Container': {
        'member-property': {
            defaultProps
        },
        'member-function': { 
            // afterToggleProductInWishlist,
            isDisabled,
            showPopup,
            setLoading,
            toggleProductInWishlist,
            isInWishlist,
            getProductInWishListProductListing,
            containerFunctions,
            containerProps
        }
    },
    'Component/ProductWishlistButton/Component': {
        'member-function': {
            renderWishlistPopup,
            renderButton,
            renderContent
        }
    }
};