export class ProductPrice {

  render(args, callback, instance) {
    const { price } = instance.props;

    if(!Object.keys(price).length){
        return null;
    }

    return callback.apply(instance, args);
  }
}

const { render } =
  new ProductPrice();

export const config = {
  "Component/ProductPrice/Component": {
    "member-function": {
      render,
    },
  }
};

export default config;
