import { Field } from 'SourceUtil/Query';

/** @namespace Query/RewardPointsQuery */

export class RewardPointsQuery {
    getGiftCardApplyMutation(rewards) {
        return new Field('useRewardPoints')
            .addArgument('points', 'Float!', rewards);
    }

    getRewardConfig(){
        return new Field('getRewardConfig')
            .addField('is_enable');
    }

    getRewardPointsData(){
        return new Field('rewards')
            .addFieldList(this._getFields());;
    }

    getCustomerRewards(){
        return new  Field('customerRewards')
            .addFieldList(this._getCustomerRewardsFields());
    }

    getExpirations(){
        return new Field('customerRewardsExpirations')
            .addFieldList([this._getExpirationsFields()]);

    }

    _getExpirationsFields(){
        return new Field('expirations_list')
            .addFieldList([
                'entity_id',
                'amount',
                'date'
            ]);
    }
    _getCustomerRewardsFields(){
        return [
            'redeemed',
            'balance',
            'rewarded',
            'expired',
        ]
    }
    _getFields(){
        return [
            'balance',
            this._getHistoryFields()
        ];
    }

    _getHistoryFields(){
        return new Field('history')
            .addFieldList([
                'total_count',
                this._getHistoryItems()
            ]);
    }

    _getHistoryItems(){
        return new Field('items')
            .addFieldList([
                'action',
                'action_date',
                'amount',
                'expiration_date',
                'points_left'
        ]);
    }
}

export default new RewardPointsQuery();
