/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */
import { appendWithStoreCode } from 'SourceUtil/Url';

const resetFilters = (args, callback, instance) => {
    const { location: { pathname }, history, category } = instance.props;
    if (pathname.includes('search')) {
        let searchParam = pathname.match(/search\/(.*?)\//)[0];
        history.push(appendWithStoreCode(`/all/f/${searchParam}`));
    } else {
        history.push(category && category.url ? category.url : "#");
    }
}

export const config = {
    'Component/ResetButton/Container': {
        'member-function': {
            resetFilters: [
                {
                    position: 1,
                    implementation: resetFilters
                }
            ]
        }
    }
};

export default config;
