import {lazy, PureComponent} from 'react';
import PropTypes from 'prop-types';
import Field from 'Component/Field';
import media, { WYSIWYG_MEDIA } from 'Util/Media';
import BrowserDatabase from 'Util/BrowserDatabase';
import Html from 'Component/Html';
import {PAYMENT_TOTALS} from "Route/Checkout/Checkout.config";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

export default class BankRedirectComponent extends PureComponent{
    static propTypes = {
        isDisabled : PropTypes.bool.isRequired,
        instructions : PropTypes.string,
        enable_installment : PropTypes.string,
        installments_period : PropTypes.string
    };

    range(start, end){
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    }

    render() {
        const {
            onCheckBoxChange,
            onFieldUpdate,
            isDisabled,
            is_installment,
            selectedPaymentCode,
            mg_bank_redirect_winbank,
            mg_bank_redirect_alphabank,
            mg_bank_redirect_eurobank,
            mg_bank_redirect_nbg_enterprise
        } = this.props;
        const { grand_total } = BrowserDatabase.getItem('cart_totals');

        let instructions = '';
        let installments_period = '';
        let enable_installment = '';
        if(selectedPaymentCode === "mg_bank_redirect_winbank"){
            instructions = mg_bank_redirect_winbank ? mg_bank_redirect_winbank.instructions : "";
            installments_period = mg_bank_redirect_winbank ? mg_bank_redirect_winbank.installments_period : "";
            enable_installment = mg_bank_redirect_winbank ? mg_bank_redirect_winbank.enable_installment: "";
        }
        else if(selectedPaymentCode === "mg_bank_redirect_alphabank"){
            instructions = mg_bank_redirect_alphabank ? mg_bank_redirect_alphabank.instructions : "";
            installments_period = mg_bank_redirect_alphabank ? mg_bank_redirect_alphabank.installments_period : "";
            enable_installment = mg_bank_redirect_alphabank ? mg_bank_redirect_alphabank.enable_installment : "";
        }
        else if(selectedPaymentCode === "mg_bank_redirect_nbg_enterprise"){
            instructions = mg_bank_redirect_nbg_enterprise ? mg_bank_redirect_nbg_enterprise.instructions : "";
            installments_period = mg_bank_redirect_nbg_enterprise ? mg_bank_redirect_nbg_enterprise.installments_period : "";
            enable_installment = mg_bank_redirect_nbg_enterprise ? mg_bank_redirect_nbg_enterprise.enable_installment : "";
        }
        else if(selectedPaymentCode === "mg_bank_redirect_eurobank"){
            instructions = mg_bank_redirect_eurobank ? mg_bank_redirect_eurobank.instructions: "";
            installments_period = mg_bank_redirect_eurobank ? mg_bank_redirect_eurobank.installments_period: "";
            enable_installment = mg_bank_redirect_eurobank ? mg_bank_redirect_eurobank.enable_installment: "";
        }
        let installmentPeriodConditions = installments_period ? installments_period.split(',').map((item)=>{
            let splitedItem = item.split(':');
            return { months : splitedItem[0], grand_total : splitedItem[1]  };
        }): [];
        let installmentPeriod = [];
        let found = false;
        installmentPeriodConditions.map(item => {
            let itemGrandTotal = parseFloat(item.grand_total);
            if(grand_total < itemGrandTotal){
                if(found)
                    return;
                found = true;
                this.range(2,parseInt(item.months)).map(month => {
                    installmentPeriod.push({ id : month , label : month + ' μηνιαίες δόσεις', value : month });
                });
            }
        });

        let min_grand_total = null;

        if(enable_installment){
            min_grand_total = installmentPeriodConditions?.[0]?.grand_total;
        }


        return(
            <div block="BankRedirect" mods={ { isDisabled } } id="BankRedirect_group" className="BankRedirect_group">
                <h4>{__('Accepted Cards')}</h4>
                <UniversalIcon src={media('hondsc_icons/cards.png',WYSIWYG_MEDIA)}/>
                {
                    enable_installment === "1" &&  min_grand_total && grand_total >= min_grand_total && installmentPeriod.length ?
                        <>
                            <div className="contact-form">
                                <fieldset block="ContactUs" elem="Legend">
                                    <div className="inputbox">
                                        <Field
                                            type="checkbox"
                                            id="installment"
                                            name="installment"
                                            onChange={ onCheckBoxChange }
                                            checked={is_installment}
                                            label={ __('Installment') }
                                        />
                                    </div>
                                    {
                                        is_installment ?
                                            <div className="inputbox">
                                                <Field
                                                    type="select"
                                                    value="sas"
                                                    id="period"
                                                    selectOptions = {installmentPeriod}
                                                    name="period"
                                                    noSort={true}
                                                    onChange={onFieldUpdate}
                                                    validation={ ['notEmpty'] }
                                                    label={ __('Installment Period') }
                                                />
                                            </div>:
                                            ""
                                    }
                                </fieldset>
                            </div>
                            <Html content={ instructions || '' } />
                        </>
                        :
                        ''

                }
            </div>
        );
    }
}
