/**
 * MageGuide - Progressive Web App for Magento
 *
 * Copyright © MageGuide, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 */

import { lazy, Suspense } from 'react';
export const RelatedProducts = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "related-products" */ '../component/RelatedProducts'));
import Loader from 'SourceComponent/Loader';

const renderRelatedProducts = (relatedProducts, selectPdpProductTab, isUpSellActive) => {

    if(relatedProducts && Object.keys(relatedProducts).length > 0) {
        return relatedProducts.map( ( { block_title, items, position }, _) =>
            position === "product_into_upsell" ?
            <Suspense fallback={ <Loader  isLoading />}>
                <RelatedProducts
                    index= { _ }
                    type= "product"
                    position = { position }
                    block_title= { block_title }
                    items= { items }
                    selectPdpProductTab={ selectPdpProductTab }
                    pdpProductTab={ isUpSellActive }
                    isAutoRelatedTab={true}
                />
            </Suspense>
                : null
        )
    }
}

const renderNonTabRelatedProducts = (relatedProducts) => {
    if(relatedProducts && Object.keys(relatedProducts).length > 0) {
        return relatedProducts.map( ( { block_title, items, position }, _) =>
            position !== "product_into_upsell" ?
            <Suspense fallback={ <Loader  isLoading />}>
                <RelatedProducts
                    type= "product"
                    position = { position }
                    block_title= { block_title }
                    items= { items }
                />
            </Suspense>
                : null
        )
    }
}

const renderPdpProductTabs = (args, callback, instance) => {
    const { relatedProducts, selectPdpProductTab, isUpSellActive } = instance.props;

    return (
        <>
            {renderRelatedProducts(relatedProducts, selectPdpProductTab, isUpSellActive)}
            {callback.apply(instance, args)}

        </>
    );
}

const renderNonTabAutoRelatedProducts = (args, callback, instance) => {
    const { relatedProducts, selectPdpProductTab, isUpSellActive } = instance.props;

    return (
        <>
            {callback.apply(instance, args)}
            {renderNonTabRelatedProducts(relatedProducts, selectPdpProductTab, isUpSellActive)}
        </>
    );
}

export default {
    'Route/ProductPage/Component' : { 
        'member-function': {
            renderPdpProductTabs: renderPdpProductTabs,
            renderNonTabAutoRelatedProducts: renderNonTabAutoRelatedProducts
        }
    }
} 
