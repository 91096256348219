import { getStaticReducers as sourceGetStaticReducers/*, default as injectStaticReducers*/ } from 'SourceStore'
import CmsBlocksAndSliderReducer from './CmsBlocksAndSlider/CmsBlocksAndSlider.reducer';
import CmsBlocksAndMenuReducer from './CmsBlocksAndMenu/CmsBlocksAndMenu.reducer';
import CmsPageReducer from './CmsPage/CmsPage.reducer';

/** @namespace Store/Index/getReducers */
export const getStaticReducers = () => ({
        ...sourceGetStaticReducers(),
        CmsBlocksAndSliderReducer,
        CmsBlocksAndMenuReducer,
        CmsPageReducer
})

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(
        ([name, reducer]) => {
            // eslint-disable-next-line no-param-reassign
            store.asyncReducers[name] = reducer;
            store.injectReducer(name, reducer);
        }
    );

    return store;
}
