const HrefLangDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/HrefLangs/HrefLang.dispatcher'
    );

const componentDidMount = (args, callback, instance) => {
    const { updateCustomHref } = instance.props;
    updateCustomHref();
    return callback.apply(instance, args);
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        updateCustomHref: () => HrefLangDispatcher.then(
            ({default: dispatcher}) => dispatcher.updateCustom(dispatch)
        )
    };
};


export const config = {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidMount: [
                {
                    position: 100,
                    implementation: componentDidMount
                }
            ]
        }
    },
    'Route/HomePage/Container/mapDispatchToProps': {
        function: [
            {
                position: 100,
                implementation: mapDispatchToProps
            }
        ]
    }
};

export default config;
