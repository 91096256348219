import { PureComponent } from 'react';
import { ProductType } from 'SourceType/ProductList';
import ProductReviewRating from 'SourceComponent/ProductReviewRating';
import CircleProgressBar from '../CircleProgressBar';

import './ProductAdvanceReview.styles';

/**
 * @class ProductAdvanceReview
 * @namespace Component/ProductAdvanceReview/Component
 */
export class ProductAdvanceReview extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    getPercentage = (value) => {
        var sum = 0 , i ;
        value && value.forEach( (i) => {
            sum += i;
          });

        return sum;
    }

    renderRatingSchema(percent, reviewCount) {
        return (
            <>
                <meta itemProp="ratingValue" content={ percent } />
                <meta itemProp="worstRating" content={ 0 } />
                <meta itemProp="bestRating" content={ 100 } />
                <meta itemProp="reviewCount" content={ reviewCount } />
            </>
        );
    }

    renderReviewList() {
        const {
            avgProgress,
            reviewData
        } = this.props;

        return (
            <>
        {Array.from(
            { length: avgProgress.length },
            (_, i) => {
                const reviewCount = avgProgress[i] ? (Object.keys(avgProgress[i]).length) : 0;

                return (
                    <li
                    block='ProductAdvanceReview'
                    elem='Summary-RatingList' >
                        <span className="count"> {i + 1} </span>
                        <span
                            key={i}
                            block='ProductAdvanceReview'
                            elem='Summary-RatingList-Item' >
                            <div
                                block='ProductAdvanceReview'
                                elem='Summary-RatingList-Item-Line'
                                style={{ width: `${ reviewCount/  avgProgress.length * 100 }%` }}
                            >
                            </div>
                        </span>
                        <span className="count">{ reviewCount }</span>
                    </li>
                )
            })}
            </>
        )
    }

    renderReviewData() {
        const { reviewData } = this.props;
        return this.renderRatingSummary(reviewData)
    }


    renderRatingSummary = (data) => {
        const { Price, Quality, Value } = data;

        return (
            <div
            block="ProductAdvanceReview"
            elem="SummaryDetails">
                <CircleProgressBar
                    name={__('Price')}
                    value={this.getPercentage(Price)}
                />
                <CircleProgressBar
                    name={__('Quality')}
                    value={this.getPercentage(Quality)}
                />
                <CircleProgressBar
                    name={__('Value')}
                    value={this.getPercentage(Value)}
                />
            </div>
        )
    }

    renderRatingData() {
        const {
            product: {
                review_summary: {
                    rating_summary,
                    review_count
                } = {},
                reviews
            }
        } = this.props;
        const STARS_COUNT = 5;
        const PERCENT = 100;

        // eslint-disable-next-line no-mixed-operators
        const percent = parseFloat(STARS_COUNT * (rating_summary || 0) / PERCENT).toFixed(2);

        if (!review_count) {
            return this.renderNoRating();
        }

        return (
            <div block="ProductAdvanceReview" elem="Summary">
                {/* { this.renderRatingSchema(rating_summary, review_count) } */}
                <div block="ProductAdvanceReview" elem="aggregatePercent"> { percent } </div>
                <ProductReviewRating
                  mix={ { block: 'ProductReviews', elem: 'SummaryRating' } }
                  summary={ rating_summary }
                />
                <div block="ProductAdvanceReview" elem="aggregateCount">
                    { __('Reviews (%s)', review_count || 0) }
                </div>
            </div>
        );
    }

    render() {
        const { product } = this.props;
        const hasReviews = product.reviews && Object.keys(product.reviews).length > 0;
        if (!hasReviews) {
            return null;
        }

        return (
            <>
                <div block="ProductAdvanceReview">
                    { this.renderRatingData() }
                    <ul block="ProductAdvanceReview" elem="Summary-RatingsList">
                        { this.renderReviewList() }
                    </ul>
                </div>
                {/* { this.renderReviewData() } */}
            </>
        );
    }
}

export default ProductAdvanceReview;
