import { lazy } from 'react';
import { COUNTDOWN_TIMER  } from '../component/CountdownTimer/CountdownTimer.config';
import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';

export const CountdownTimer = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-video" */
    '../component/CountdownTimer'
));

const renderContent = (args, callback, instance) => {

    const {
        type,
    } = instance.props;

    const {
        component: Widget,
        fallback
    } = instance.renderMap[type] || {};

    if (Widget !== undefined && type === COUNTDOWN_TIMER) {
        return(
        <RenderWhenVisible fallback={ fallback }>
            <Widget
             { ...instance.props }
            />
        </RenderWhenVisible>
        )
    }

    return callback.apply(instance, args);
}

export const renderMap = (props, instance) => {
    return {
        ...props,
        [COUNTDOWN_TIMER]: {
            component: CountdownTimer
        }
    }
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': renderMap
        },
        'member-function': { 
            renderContent
        }
    }
};