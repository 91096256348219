/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { STORE_PAGE_DATA } from './StorePage.action';

export const getInitialState = () => ({
    storePageData : {}
});

export const StorePageReducer = (state= getInitialState(), action) => {
    switch (action.type) {
    case STORE_PAGE_DATA:
        const { storePageData } = action;

        return {
            ...state,
            storePageData: storePageData
        };

    default:
        return state;
    }
};

export default StorePageReducer;
