const addArgumentList = (arg, callback, instance) => {
    const options = arg[0];
    const { args } = instance;
    Object.values(options).map(({ name, type, value }) => {
        args.push({
            name,
            type,
            value
        });
    });
    
    return instance;
}


export default {
    'Util/Query/Field': {
        'member-function': {
            addArgumentList,
        }
    }
};