import { PureComponent } from "react";
import {connect} from "react-redux";
import CartGiftCard from "./CartGiftCard.component";
import PropTypes from "prop-types";
import BrowserDatabase from "Util/BrowserDatabase";
export const GUEST_QUOTE_ID = 'guest_quote_id';
import { isSignedIn } from 'Util/Auth';
import CartGiftCardQuery from '../../query/CartGiftCard.query'
import { fetchQuery } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';
export const CartGiftCardDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/CartGiftCard/CartGiftCard.dispatcher'
    );

export const mapStateToProps = (state) => ({
    currency_code: state.ConfigReducer.currencyData.current_currency_code
});

/** @namespace Component/CartGiftCardContainer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    applyGiftCardToCart: (giftCardCode, quoteId) => CartGiftCardDispatcher.then(
        ({ default: dispatcher }) => dispatcher.applyGiftCardToCart(dispatch, giftCardCode,  !isSignedIn() && quoteId)
    ),
    removeGiftCardFromCart: (giftCardCode, quoteId) => CartGiftCardDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeGiftCardFromCart(dispatch,giftCardCode,  !isSignedIn() && quoteId)
    ),
    showNotification: (type,message) => dispatch(showNotification(type, message))
});

/** @namespace Component/CartGiftCardContainer/Container */
export class CartGiftCardContainer extends PureComponent{
    static propTypes = {
        applyGiftCardToCart: PropTypes.func.isRequired,
        removeGiftCardFromCart: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleRemoveGiftCardFromCart: this.handleRemoveGiftCardFromCart.bind(this),
        handleFormSuccess: this.handleFormSuccess.bind(this),
        removeGiftCard: this.handleRemoveGiftCardFromCart.bind(this),
        handleGiftCodeChange: this.handleGiftCodeChange.bind(this),
        handleGiftCodeStatus: this.handleGiftCodeStatus.bind(this)
    }

    state = { isLoading: false, giftCode : "" , codeStatus: {}, emptyField: true};

    handleGiftCodeChange(e){
        this.setState({
            giftCode : e
        });
    }

    handleGiftCodeStatus(){
        const { giftCode } = this.state;
        const { showNotification } = this.props;
        const CodeArgs = {
            am_gift_card_code: giftCode
        }

        if(giftCode === ""){
            return ;
        }
        this.setState({ isLoading: true });

        fetchQuery(CartGiftCardQuery.getGiftCardCodeStatus(CodeArgs)).then(
            ({ amGiftCardAccount: amGiftCardAccount }) => {
                this.setState({ emptyField: false, codeStatus: { ...amGiftCardAccount }, isLoading: false });
            },
            (errors) => {
                errors.map(error => showNotification('error', error.message));
                this.setState({ isLoading: false });
            }
        );
    }


    handleRemoveGiftCardFromCart(giftCardCode) {
      const { removeGiftCardFromCart } = this.props;
        this.setState({ isLoading: true });
        removeGiftCardFromCart(giftCardCode, !isSignedIn() && this._getGuestQuoteId()).then(
            /** @namespace Component/CartGiftCard/Container/removeGiftCardFromCart */
            () => this.setState({ isLoading: false })
        );
    }

    handleFormSuccess(fields){
        const { showNotification } = this.props;
        const { couponCode } = fields;
        if(!couponCode) {
            showNotification('info', __('Please enter code!'));
            return;
        }
        const { applyGiftCardToCart }  = this.props;
        this.setState({ isLoading: true });
        applyGiftCardToCart(couponCode, !isSignedIn() && this._getGuestQuoteId()).then(
            /** @namespace Component/CartGiftCard/Container/applyGiftCardToCart */
            () => this.setState({ isLoading: false, giftCode : "" })
        );
    }

    _getGuestQuoteId() {
        return BrowserDatabase.getItem(GUEST_QUOTE_ID).token;
    }
    render() {
        return (
            <CartGiftCard
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartGiftCardContainer);

