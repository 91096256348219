import {UPDATE_BRANDS_LIST, UPDATE_BRAND_TYPE_OPTIONS } from "./BrandList.action";

/** @namespace Store/BrandList/Reducer/getInitialState */
export const getInitialState = () => ({
    brandList : [],
    brand_type_options :  []
});

const BrandListReducer = (state = getInitialState(),action ) => {
    switch (action.type) {
        case UPDATE_BRANDS_LIST:
            const { brandList  } = action;

            return {
                ...state,
                brandList: brandList
            };
        case UPDATE_BRAND_TYPE_OPTIONS:
            const {  brand_type_options  } = action;

            return {
                ...state,
                brand_type_options : brand_type_options
            };


        default:
            return state;
    }
}
export default BrandListReducer;
