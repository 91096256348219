/** @namespace Mageguidepwahondosc/Extrafieldvalidations/Component/Form/Config/validateNoNumber */
export const validateNoNumber = ({ value }) => !/[!@#$%^&*(),.?":{}|<>\d]/.test(value);

/** @namespace Mageguidepwahondosc/Extrafieldvalidations/Component/Form/Config/validateNoFutureDate */
export const validateNoFutureDate = ({value}) => {
    if(!value) return true;
    let today = new Date();
    let past = new Date(value);
    return past.setHours(0, 0, 0, 0) < today.setHours(0, 0, 0, 0);
};

/** @namespace Mageguidepwahondosc/Extrafieldvalidations/Component/Form/Config */
export const formConfig = () => ({
    noNumber : {
        validate: validateNoNumber,
        message: __('Digits and special characters are not accepted!')
    },
    noFutureDate : {
        validate: validateNoFutureDate,
        message: __("Date can't be future date!" )
    }
});

export default formConfig();
