import HeaderSlogan from "../component/HeaderSlogan";
class HeaderComponentPlugin {
    renderTopMenu = (args, callback, instance) => {
        return (
            <>
                <HeaderSlogan/>
                { callback.apply(instance,args) }
            </>
        )
    }

}

const { renderTopMenu } = new HeaderComponentPlugin();

export default {
    'Component/Header/Component': {
        'member-function': {
            'renderTopMenu': renderTopMenu
        }
    }
};
