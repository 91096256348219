import { cloneElement, createRef } from 'react';

import {
    RECAPTCHA_TYPE_INVISIBLE,
    RECAPTCHA_TYPE_RECAPTCHA
} from '../component/Recaptcha/Recaptcha.config';
import Recaptcha from '../component/Recaptcha/Recaptcha.container';
import { getCaptchaKey, getRecaptchaConfigs } from '../utils/recaptchaConfig';

export class ContactFormPlugin {
    recaptchaRef = createRef();

    renderFields = (args, callback, instance) => {
        const originalDetails = callback.apply(instance, args);
        const { recaptcha_for_contact } = getRecaptchaConfigs();
        if (recaptcha_for_contact) {
            const { props: { children } } = originalDetails;
            return cloneElement(
                originalDetails,
                {},
                [...children, (<Recaptcha
                  recaptchaType={ recaptcha_for_contact }
                  recaptcha_public_key={ getCaptchaKey(recaptcha_for_contact) }
                  recaptchaRef={ this.recaptchaRef }
                />)]
            );
        }

        return originalDetails;
    };

    onFormSubmit = async (args, callback, instance) => {
        const { recaptcha_for_contact } = getRecaptchaConfigs();
        switch (recaptcha_for_contact) {
        case RECAPTCHA_TYPE_INVISIBLE:
            this.recaptchaRef.current.reset();
            return this.recaptchaRef.current.executeAsync().then(() => {
                callback.apply(instance, args);
            });
        default:
            callback.apply(instance, args);
            return null;
        }
    };

    fetchMutationThen = (args, callback) => {
        const { recaptcha_for_contact } = getRecaptchaConfigs();
        switch (recaptcha_for_contact) {
        case RECAPTCHA_TYPE_RECAPTCHA:
            if (window.grecaptcha) {
                window.grecaptcha.reset();
            }
            window.recaptchaValidation = '';
            return callback(...args);
        default:
            window.recaptchaValidation = '';
            return callback(...args);
        }
    };
}

const { renderFields, onFormSubmit, fetchMutationThen } = new ContactFormPlugin();

export const config = {
    'Component/ContactForm/Component': {
        'member-function': {
            renderFields
        }
    },
    'Mageguidepwahondosc/Contactus/Route/ContactUs/Component/ContactUsComponent': {
        'member-function': {
            renderFields
        }
    },
    'Component/ContactForm/Container': {
        'member-function': {
            onFormSubmit
        }
    },
    'Mageguidepwahondosc/Contactus/Route/ContactUs/Container/ContactUsContainer': {
        'member-function': {
            onFormSubmitSuccess: onFormSubmit
        }
    },
    'Store/ContactForm/Dispatcher/fetchMutationThen': {
        function: [
            {
                position: 1,
                implementation: fetchMutationThen
            }
        ]
    },
    'Store/ContactForm/Dispatcher/fetchMutationError': {
        function: [
            {
                position: 1,
                implementation: fetchMutationThen
            }
        ]
    },
    'Mageguidepwahondosc/Contactus/Route/ContactUs/Container/fetchMutation/then': {
        function: [
            {
                position: 1,
                implementation: fetchMutationThen
            }
        ]
    }
};

export default config;
