/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Field } from 'Util/Query';

/** @namespace Boxnow/Query/BoxNowConfig/BoxNowConfig */
export class BoxNowConfig {
    getBoxNowConfiguration() {
        return new Field('getBoxNowUrls')
            .addFieldList(this._getBoxNowConfigurationFields());
    }

    _getBoxNowConfigurationFields() {
        return [
            'country_id',
            'partner_id',
            'url'
        ];
    }
}

export default new BoxNowConfig();
