const render = (_args, _callback, _instance) => {
    const { visiblePage } = _instance.props;

    if (visiblePage) {
        const {
            label,
            isCurrent,
            children
        } = _instance.props;

        return (
            <button
                style={{ cursor: "pointer" }}
                aria-label={label}
                block="CategoryPaginationLink"
                mods={{ isCurrent, isArrow: typeof children !== 'string' }}
                aria-current={isCurrent ? 'page' : 'false'}
                onClick={_instance.getPage}
            >
                {children}
            </button>
        );
    }

    return _callback.apply(_instance, _args);
}

export default {
    'Component/CategoryPaginationLink/Component': {
        'member-function': {
            render: [
                {
                    position: 101,
                    implementation: render
                }
            ]
        }
    }
};