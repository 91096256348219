import getStore from 'Util/Store';
const  onAddressSelect = (args, callback, instance) => {
    const [ id ] = args;
    const { customer : { addresses } } = instance.props;
    const configReducer = getStore().getState().ConfigReducer;
    const { klarna_enable } = configReducer;
    let selectedAddress = addresses.filter((address)=> address.id == id)?.[0];
    if(klarna_enable && selectedAddress){
        window.klarnaBillingAddress = selectedAddress;
        if(window.klarnaReloadCallback)
            window.klarnaReloadCallback(selectedAddress);
    }
    return callback.apply(instance,args);
}
const  _getPaymentData = (args, callback, instance) => {
    const [ fields, asyncData ] = args;
    const { paymentMethod: code } = instance.state;
    if(['klarna_pay_now','klarna_pay_later','klarna_pay_over_time', 'klarna_direct_debit','klarna_direct_bank_transfer'].includes(code)){
        const [{ authorization_token }] = asyncData;
        return {
            code,
            additional_data: {
                authorization_token
            }
        };
    }
    return callback.apply(instance,args);
}
export const config = {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            onAddressSelect,
            _getPaymentData
        }
    }
};

export default config;
