import { lazy } from "react";

import { EDIT_PRODUCT_POPUP, WISHLIST_POPUP } from '../utils/wishlist.config'
import { showPopup } from 'SourceStore/Popup/Popup.action';
import Loader from 'SourceComponent/Loader';
import ProductPopup from '../component/ProductPopup';
import Popup from 'SourceComponent/Popup';
import CartItem from 'SourceComponent/CartItem';
import WishlistPopup from '../component/WishlistPopup';
import { isSignedIn } from 'SourceUtil/Auth';
import media, { WYSIWYG_MEDIA } from "SourceUtil/Media/Media";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
));

class CartPagePlugin {
    mapStateToProps(args, callback, instance) {
        const [state] = args;
        return {
            ...callback.apply(instance, args),
            productsInWishlist: state.WishlistReducer.productsInWishlist,
            isWishlistEnabled: state.ConfigReducer.wishlist_general_active
            
        }
    };

    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            showPopup: (type, payload) => dispatch(showPopup(type, payload)),
        }
    }

    state(args) {
        return {
            ...args,
            item: {},
            getLink: ''
        }
    }


    containerFunctions(args, callback, instance){
        return {
            ...args,
            productEditPopup: productEditPopup.bind(args, callback, instance),
            moveAllItemPopup: moveAllItemPopup.bind(args, callback, instance)
        }
    };

    containerProps(args, callback, instance) {
        const { item, getLink } = instance.state;
        const { productsInWishlist, isWishlistEnabled } = instance.props;
        
        return {
            ...callback.apply(instance, args),
            productsInWishlist,
            item,
            getLink,
            isWishlistEnabled
        };
    }

    moveAllItemPopup(instance, callback, args){
        const { showPopup } = instance.props;

        return showPopup(
            WISHLIST_POPUP,
            {
                moveAllItems: true,
                title: __('Move all cart items to wishlist')
        });
    }


    productEditPopup(instance, callback, args){
        const { item, getLink } = args;
        const { product:{ name, id } } = item;
        const { showPopup } = instance.props;
        instance.setState({ item, getLink });

        return showPopup(
            EDIT_PRODUCT_POPUP,
            {
                item,
                title: __('EDIT PRODUCT')
        });
    }


    renderCartItems(args, callback, instance) {
        const {
            totals: {
                items,
                quote_currency_code
            },
            isCartItemLoading,
            onCartItemLoading,
            productEditPopup,
            isWishlistEnabled
        } = instance.props;
        
        if (!items || items.length < 1) {
            return (
                <p block="CartPage" elem="Empty">{ __('There are no products in cart.') }</p>
            );
        }

        return (
            <>
                <div block="CartPage" elem="Items" aria-label="List of items in cart">
                    <Loader isLoading={ isCartItemLoading } />
                    { items.map((item) => (
                        <CartItem
                          isWishlistEnabled= { isWishlistEnabled }
                          productEditPopup= { productEditPopup }
                          key={ item.item_id }
                          item={ item }
                          currency_code={ quote_currency_code }
                          onCartItemLoading={ onCartItemLoading }
                          showLoader={ false }
                          cartPage={ true }
                          isEditing
                          updateCrossSellsOnRemove
                        />
                    )) }
                </div>
            </>
        );
    }

    getProductPopup(instance){
        const { item: { item_id, product = {}, qty }, location, history, getLink, item } = instance.props;
        const { sku: productSKU = '', id: productID = '' } = product;
        const { parameters, configurableVariantIndex } = getLink;
        
        return (
            <ProductPopup
                configurableVariantIndex= { configurableVariantIndex }
                isCart
                item_id = { item_id }
                qty = { qty }
                item={item}
                history={history}
                location={location}
                parameters={ parameters }
                // match={match}
                productSKU={productSKU}
                productID={productID}
                key={productID}
            />
        );
    }

    renderWishlistPopup(instance){
        
        return (
            <Popup
            id={ WISHLIST_POPUP }
            clickOutside={ false }
            mix={ { block: 'CartPageWishlistPopup' } }
          >
              <WishlistPopup isCart { ...instance.props }/>
          </Popup>
        )
    }    

    getEditProductItemPopup(instance) {
        const { item } = instance.props;

        const isItemLoaded = Object.keys(item).length > 0;

        return (
            <Popup
                id={ EDIT_PRODUCT_POPUP }
                clickOutside={false}
                mix={{ block: 'CartItem-Edit' }}
            >
                { getProductPopup(instance)}
            </Popup>
        );
    }

    render(args, callback, instance) {

        return[
            callback.apply(instance,args),
            getEditProductItemPopup(instance),
            renderWishlistPopup(instance)
        ]
    }

    renderGiftBoxSection = (args, callback, instance) => {
        const { 
            moveAllItemPopup, 
            productsInWishlist,
            totals: {
                items,
                quote_currency_code
            }
         } = instance.props;
        
        if(!isSignedIn()) {
            return callback.apply(instance, args) ;
        }

        const wishlistSKUs = Object.values(productsInWishlist).map(({ sku }) => sku);

        // Check if all items' SKUs match with SKUs in productsInWishlist
        const allItemsMatchInWishlist = items.every(item => wishlistSKUs.includes(item.sku));

        return (
            <div block="CartPage" elem="GiftBox"
            >
                { callback.apply(instance, args) }
                <div 
                 block="CartPage" 
                 elem="WishlistAction" 
                 mix= { { block:"CartPage", elem: "GiftBox"}  }
                >
                    <button
                        block="ProductWishlistButton"
                        elem="Button"
                        mix={ { block: 'CartPage', elem:'GiftBoxButton Button' }}
                        mods={ { isDisabled: allItemsMatchInWishlist } }
                        onClick={ !allItemsMatchInWishlist && moveAllItemPopup }
                        title={ __('Add all to wishlist') }
                        style={{
                            color: '#000'
                        }}
                    >
                    <UniversalIcon
                        src={ media( allItemsMatchInWishlist ?'wishlist_small_fill.svg': 'wishlist_small.svg', WYSIWYG_MEDIA) }
                        alt="HeartIcon"
                        className="HeartIcon"
                        style={{
                            width: 'auto'
                        }}
                        
                    />
                        <span> { __('Add all to wishlist') } </span>
                    </button>
                </div>
            </div>
        );
    }

}

const { 
    state,
    mapDispatchToProps,
    mapStateToProps,
    getEditProductItemPopup,
    getProductPopup, 
    containerFunctions,
    containerProps,
    productEditPopup,
    moveAllItemPopup,
    renderCartItems, 
    render, 
    renderWishlistPopup,
    renderGiftBoxSection
} = new CartPagePlugin();

export default {
    'Route/CartPage/Component': {
        'member-function': {
            getEditProductItemPopup,
            renderCartItems,
            getProductPopup,
            renderWishlistPopup,
            render,
            renderGiftBoxSection,
        }
    },
    'Route/CartPage/Container': { 
        'member-property': {
            state
        },
        'member-function': { 
            mapDispatchToProps,
            productEditPopup,
            moveAllItemPopup,
            containerFunctions,
            containerProps
        }
    },
    'Route/CartPage/Container/mapDispatchToProps': {
        function: {
            position: 101,
            implementation: mapDispatchToProps
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: {
            position: 101,
            implementation: mapStateToProps
        }
    },
}