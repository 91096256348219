import CartQuery from 'Query/Cart.query';
import { GIFTPRODUCT } from './ProductAction.component.plugin';
import { getExtensionAttributes } from 'Util/Product';
import { fetchMutation } from 'Util/Request';
import { isSignedIn } from 'Util/Auth';
import { showNotification } from 'Store/Notification/Notification.action';

class CartDispatcherPlugin {
    addProductToCart = (args, callback, instance) => {
        const {
            product
        } = args[1];
        const dispatch = args[0];
        const {
            sku,
            type_id: product_type
        } = product;
        if(product_type === GIFTPRODUCT){
            const {
                quantity,
                giftOptions,
                productOptionsData
            } = args[1];
            const {
                productOptions,
                productOptionsMulti
            } = productOptionsData || {};

            const productToAdd = {
                sku,
                ...giftOptions,
                product_type,
                quantity,
                product_option: {
                    extension_attributes: getExtensionAttributes(
                        {
                            ...product,
                            productOptions,
                            productOptionsMulti
                        }
                    )
                }
            };

            if (instance._canBeAdded(args[1])) {
                return fetchMutation(CartQuery.getSaveCartItemMutation(
                    productToAdd, !isSignedIn() && instance._getGuestQuoteId()
                )).then(
                    /** @namespace Store/Cart/Dispatcher/addProductToCartFetchMutationThen */
                    ({ saveCartItem: { cartData } }) => instance._updateCartData(cartData, dispatch),
                    /** @namespace Store/Cart/Dispatcher/addProductToCartFetchMutationCatch */
                    ([{ message }]) => {
                        dispatch(showNotification('error', message));
                        return Promise.reject();
                    }
                );
            }

            return Promise.reject();
        }
        return callback.apply(instance, args);
    }
}
const { addProductToCart } = new CartDispatcherPlugin();
export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            'addProductToCart': addProductToCart
        }
    }
};
