import { PureComponent } from 'react';
import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

/** @namespace Component/Breadcrumb/Component */
export class Breadcrumb extends SourceBreadcrumb {
    render() {
        const { index } = this.props;

        return (
            <li
              block="Breadcrumb"
              key={ index }
            //   itemProp="itemListElement"
            //   itemScope
            //   itemType="http://schema.org/ListItem"
            >
                { this.renderLink() }
            </li>
        );
    }
}

export default Breadcrumb;
