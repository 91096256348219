import { lazy } from 'react';

export const CareerForm = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-slider" */
    '../component/CareerForm'
    ));
const CAREER_FORM = 'CareerForm';

const renderMap = (args) => ({
    ...args,
    [CAREER_FORM]: {
        component: CareerForm
    },
})

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap : renderMap
        }
    }
};
