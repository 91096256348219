import { appendWithStoreCode } from 'SourceUtil/Url';
import { TRACK_ORDER, ORDER_PLACED } from '../route/TrackOrder/TrackOrder.config';

class MyAccountMyOrdersComponentPlugin {

    renderTrackLink(_instance) {
        const {
         order: {  
             base_order_info: {
                increment_id,
                 state
                } 
            },
        email,
        incrementId
        } = _instance.props;

        if(state === 'canceled')
            return null;

        let buttonClass = 'MyAccountTrackOrderPopup-Button Button';
        const url = `/${TRACK_ORDER}?orderId=${incrementId ?? increment_id}${email ? `&email=${email}` : ``}`;

        return (
            <a href={ appendWithStoreCode(url) } className={ buttonClass }>
                { __('Track Order') }
            </a>
        );
    }

    renderActions(_args, _callback, _instance) {
        const { order: { order_products } } = _instance.props;

        if (!order_products) {
            return null;
        }
        
        return [
             _callback.apply(_instance, _args),
            renderTrackLink(_instance)
        ]
    }

}

const { renderActions, renderTrackLink } = new MyAccountMyOrdersComponentPlugin();

export default {
    'Component/MyAccountOrderPopup/Component': {
        'member-function': { 
            renderTrackLink,
            renderActions
        }
    }
};