const onPageLoad = (args, callback, instance) => {
    const { cmsPage : { pwa_noindex } } = args[0];
    const existingMeta = document.querySelector('meta[name="robots"][content="NOINDEX,NOFOLLOW"]');
    
    if(pwa_noindex){
        const meta = document.createElement('meta');
        meta.name = "robots";
        meta.content = "NOINDEX,NOFOLLOW";
        document.head.insertBefore(meta, document.head.childNodes[10]);
    } else if(existingMeta) {
        existingMeta.remove();
    }

    return callback.apply(instance,args);
}

const isPageLoaded = (args, callback, instance) => {
    const { pathname } = location;
    const existingMeta = document.querySelector('meta[name="robots"][content="NOINDEX,NOFOLLOW"]');
    const headerWrapper = document.querySelector('#Header-Wrapper');
    const isCmsPage = headerWrapper ? headerWrapper.className.includes('cms-page') : false;
    
    if ((pathname.includes('login') || pathname.includes('rma/guest') || pathname.includes('track-trace') && !isCmsPage)) {
      if (!existingMeta) {
        const meta = document.createElement('meta');
        meta.name = "robots";
        meta.content = "NOINDEX,NOFOLLOW";
        document.head.insertBefore(meta, document.head.childNodes[10]);
      }
    } else {
      if (existingMeta) {
        existingMeta.remove();
      }
    }

    return callback.apply(instance,args);
}

export default { 
    'Route/CmsPage/Container': {
        'member-function': { onPageLoad: onPageLoad }
    },
    'Component/Router/Container': {
        'member-function': { 
            isPageLoaded 
        }
    }
};
