import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import ProductReturnQuery from '../../query/ProductReturn.query';

const addAmRmaConfigToConfigQuery = (args, callback, instance) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        ProductReturnQuery.getRmaConfiguration()

    ];
};

const addAmRmaConfigToConfigReducerInitialState = (args, callback, instance) => {
    const { getAmRmaConfig } = BrowserDatabase.getItem('config') || { getAmRmaConfig: {},
    };
    return {
        ...callback(...args),
        getAmRmaConfig
    };
};

const addAmRmaConfigToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { getAmRmaSettings } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        getAmRmaConfig: getAmRmaSettings
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            'prepareRequest': addAmRmaConfigToConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        'function': addAmRmaConfigToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        'function': addAmRmaConfigToConfigUpdate
    },
};
