const _getCategoryBreadcrumbs = (args, callback, instance) => {
    let { canonical_url , url, name, breadcrumbs } = args[0];
    const breadcrumbsList = [];

    if (breadcrumbs) {
        breadcrumbs
            .sort((a, b) => a.category_level - b.category_level)
            .forEach((crumb) => {
                const { category_url, category_name, category_is_active } = crumb;

                // do not add link to inactive categories
                if (category_is_active) {
                    breadcrumbsList.push({
                        name: category_name,
                        url: {
                            pathname: category_url,
                            state: { category: true }
                        }
                    });
                } else {
                    breadcrumbsList.push({
                        url: '',
                        name: category_name
                    });
                }
            });
    }
    if(!canonical_url)
        canonical_url = url;

    return [
        { url : canonical_url, name },
        ...breadcrumbsList.reverse()
    ];
}

export const config = {
    'Store/Breadcrumbs/Dispatcher': {
        'member-function': {
            _getCategoryBreadcrumbs: [
                {
                    position:100,
                    implementation: _getCategoryBreadcrumbs
                }
            ]
        }
    }
};

export default config;
