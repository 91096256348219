/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BannersReducer from '../store/Banners/Banners.reducer';

export class StoreIndexPlugin {
    /**
     * Plugin to add banners reducer to getStaticReducers method.
     * @param args
     */
    aroundGetStaticReducers = (args, callback) => ({
        ...callback(...args),
        BannersReducer
    });
}

const { aroundGetStaticReducers } = new StoreIndexPlugin();

export const config = {
    'Store/Index/getReducers': {
        function: aroundGetStaticReducers
    }
};

export default config;
