import { connect } from 'react-redux';
import {
    formatPrice,
    roundPrice
} from 'Util/Price';

import { ProductPriceContainer as SourceProductPriceContainer } from 'SourceComponent/ProductPrice/ProductPrice.container';

/** @namespace Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    displayTaxInPrice: state.ConfigReducer.priceTaxDisplay?.product_price_display_type
});

/** @namespace Component/ProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/**
 * Product price
 * @class ProductPrice
 * @namespace Component/ProductPrice/Container
 */
export class ProductPriceContainer extends SourceProductPriceContainer {
    containerProps() {
        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage = 0
                    } = {},
                    final_price: {
                        value: minimalPriceValue = 0,
                        currency: priceCurrency = ''
                    } = {},
                    regular_price: {
                        value: regularPriceValue = 0
                    } = {},
                    default_price: {
                        value: defaultPriceValue = 0
                    } = {},
                    default_final_price_excl_tax: {
                        value: defaultFinalPriceExclTax = 0
                    } = {}
                } = {}
            } = {},
            isSchemaRequired,
            showDiscount,
            label,
            mix,
            price,
            price_tiers,
            variantsCount,
            isFoucsiaPrice,
            isValueLabel,
            isReducedPrice,
            unitCalcModifier,
            unitLabel
        } = this.props;

        if ((!minimalPriceValue || !regularPriceValue) && !defaultPriceValue) {
            return {};
        }

        const roundedRegularPrice = this.getRoundedRegularPrice();
        const formattedFinalPrice = this.getFormattedFinalPrice();
        const formattedSubPrice = this.getFormattedSubPrice();
        const formattedDiscountAmount = this.getFormatedDiscountAmount();

        return {
            roundedRegularPrice,
            priceCurrency,
            defaultFinalPriceExclTax,
            discountPercentage,
            formattedFinalPrice,
            formattedSubPrice,
            isSchemaRequired,
            showDiscount,
            label,
            mix,
            price,
            price_tiers,
            variantsCount,
            formattedDiscountAmount,
            isFoucsiaPrice,
            isValueLabel,
            isReducedPrice,
            unitCalcModifier,
            unitLabel,
            minimalPriceValue,
            priceCurrency
        };
    }

    getFormatedDiscountAmount() {
        const {
            price: {
                minimum_price: {
                    regular_price: {
                        currency: priceCurrency
                    } = {},
                    discount: {
                        amount_off: discountAmount = 0
                    } = {}
                } = {}
            } = {}
        } = this.props;


        return formatPrice(roundPrice(discountAmount), priceCurrency);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
