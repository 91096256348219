export class ConfigQueryPlugin {
    _aroundGetStoreConfigFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'default_description'
    ];
}

export const { _aroundGetStoreConfigFields } = new ConfigQueryPlugin();

export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: _aroundGetStoreConfigFields
        }
    }
};

export default config;
