/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_REWARD_POINTS_DATA = 'UPDATE_REWARD_POINTS_DATA';
export const LOAD_CUSTOMER_REWARDS = 'LOAD_CUSTOMER_REWARDS';
export const LOAD_CUSTOMER_REWARDS_EXPIRATIONS = 'LOAD_CUSTOMER_REWARDS_EXPIRATIONS';

/**
 * Remove coupon from cart
 * @return {void}
 * @namespace Store/RewardPoints/Action/updateRewardPointsData
 */
export const updateRewardPointsData = (data) => ({
    type: UPDATE_REWARD_POINTS_DATA,
    data
});

export const loadCustomerRewardsData = (data) => ({
   type: LOAD_CUSTOMER_REWARDS,
   data
});

export const loadCustomerRewardsExpirations = (data) => ({
    type: LOAD_CUSTOMER_REWARDS_EXPIRATIONS,
    data
})

