import { appendWithStoreCode } from 'SourceUtil/Url';
import { stringify } from 'rebem-classname';

const getTo = (_args, _callback, _instance) => {
    const { to: toProp } = _instance.props;
    // fix null, undefined and empty links
    const to = toProp || '/';

    if (typeof to === 'string') {
        // in case this URL is absolute, do not append store
        if (/^https?:\/\//.test(to)) {
            return to;
        }

        return appendWithStoreCode(to);
    }

    const pathname = to.pathname || '/';

    return {
        ...to,
        pathname: /^https?:\/\//.test(pathname) ? pathname : appendWithStoreCode(pathname)
    };
}


const renderAbsolutePathLink  = (_args, _callback, _instance) => {
    const [ classNameConverted ] = _args;

    const {
        isOpenInNewTab,
        children,
        to,
        bemProps,
        ...props
    } = _instance.props;

    const href = to?.pathname && /^https?:\/\//.test(to?.pathname) ? to.pathname : to;

    if (isOpenInNewTab) {
        return (
            <a
              { ...props }
              href={ href }
                // eslint-disable-next-line react/forbid-dom-props
              className={ classNameConverted }
              rel="noopener noreferrer"
              target="_blank"
            >
                { children }
            </a>
        );
    }

    return (
        <a
          { ...props }
          href={ href }
            // eslint-disable-next-line react/forbid-dom-props
          className={ classNameConverted }
        >
            { children }
        </a>
    );
};

const render = (_args, _callback, _instance) => {
    const {
        className,
        bemProps,
        children,
        to,
        isOpenInNewTab,
        ...props
    } = _instance.props;

        if (!to) {
        return (
            <div { ...props } { ...bemProps }>
                { children }
            </div>
        );
        }

    
    const classNameConverted = `${ className } ${ stringify(bemProps)}`;

    if(to?.pathname && /^https?:\/\//.test(to?.pathname)) {
        return _instance.renderAbsolutePathLink(classNameConverted);
    }

    return _callback.apply(_instance, _args);
}

export default {
    'Component/Link/Component': {
        'member-function': {
            renderAbsolutePathLink,
            render
        }
    },
    'Component/Link/Container': {
        'member-function': {
            getTo
        }
    }
};