/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const CUSTOM_BRANDS_PROMO_POPUP_ID = 'CustomBrandsPopup'
export const CUSTOM_BRANDS_PROMO_POPUP_BROWSER_ITEM = 'CUSTOM_BRANDS_POPUP';
export const CUSTOM_BRANDS_POPUP_IDENTIFIER = 'custom_brands_promo_popup'
export const EIGHT_DAYS_IN_SECONDS = 28800;
