import { MyAccountConfirmEmail as SourceMyAccountConfirmEmail } from 'SourceComponent/MyAccountConfirmEmail/MyAccountConfirmEmail.component';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import Link from "Component/Link";

import './MyAccountConfirmEmail.style';

export class MyAccountConfirmEmail extends SourceMyAccountConfirmEmail {
    componentDidMount() {
        const { isMobileConfirmPage } = this.props;

        if(isMobileConfirmPage)
            window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    render() {
        const { state, handleSignIn, isMobileConfirmPage } = this.props;

        return (
            <article
                aria-labelledby="confirm-email-notice"
                block="MyAccountOverlay"
                elem="Additional"
                mods={ { state } }
            >
                <div className="MyAccountConfirmEmail-content">
                    <p id="confirm-email-notice">
                        { /* eslint-disable-next-line max-len */ }
                        { __('Έχετε σχεδόν ολοκληρώσει την εγγραφή σας! Απομένει ένα ακόμη βήμα, στείλαμε έναν σύνδεσμο επιβεβαίωσης στη διεύθυνση email σας.') }
                    </p>
                    <p>
                        { /* eslint-disable-next-line max-len */ }
                        {__('Αν αντιμετωπίσετε κάποιο πρόβλημα παρακαλούμε να επικοινωνήσετε με την ') }
                        <Link to={'contact'} >{__('εξυπηρέτηση πελατών.')}</Link>
                    </p>
                    { isMobileConfirmPage ?
                        <Link to={ '/account/login' } className="Button" >
                            { __('Καταστήματα') }
                        </Link>
                        :
                        <button
                            block="Button"
                            onClick={ handleSignIn }
                        >
                            { __('Got it') }
                        </button>
                    }
                </div>
            </article>
        );
    }
}

export default MyAccountConfirmEmail;
