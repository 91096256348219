/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CartQuery from 'Query/Cart.query';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { getExtensionAttributes } from 'Util/Product';
import { fetchMutation, getErrorMessage } from 'Util/Request';
import { CartDispatcher as SourceCartDispatcher } from 'SourceStore/Cart/Cart.dispatcher'
import { updateIsLoading } from '@mageguide/sample-products/src/store/SampleProduct/SampleProduct.action';

const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
    );

/**
 * Product Cart Dispatcher
 * @class CartDispatcher
 * @namespace Store/Cart/Dispatcher
 */
export class CartDispatcher  extends SourceCartDispatcher{

    async changeItemQty(dispatch, options) {
        const { item_id, quantity, sku } = options;

        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return Promise.reject();
            }

            const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getSaveCartItemMutation(
                    { sku, item_id, quantity },
                    guestQuoteId
                )
            );

            return this._updateCartData(cartData, dispatch);
        } catch (error) {
            const {debugMessage, message } = error[0];
            if ((debugMessage && debugMessage.match('cartId')) || (debugMessage && debugMessage.match('guestCartId'))|| (message && message.match(__('Internal server error')))) {
                dispatch(showNotification('error', debugMessage));
                this.createGuestEmptyCart(dispatch);
            }
            dispatch(showNotification('error', getErrorMessage(error)));

            return Promise.reject();
        }
    }

    async createGuestEmptyCart(dispatch) {
        try {
            const {
                createEmptyCart: quoteId = ''
            } = await fetchMutation(CartQuery.getCreateEmptyCartMutation());
            setGuestQuoteId(quoteId);
            this._updateCartData({}, dispatch);

            return quoteId;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }

    async removeProductFromCart(dispatch, item_id) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return null;
            }

            const { removeCartItem: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getRemoveCartItemMutation(item_id, guestQuoteId)
            );

            this._updateCartData({...cartData, type: 'removeCartItem' }, dispatch);

            return cartData;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }

    async addProductToCart(dispatch, options) {
        const {
            product,
            quantity,
            productOptionsData,
            buyRequest
        } = options;

        const {
            sku,
            type_id: product_type
        } = product;

        const {
            productOptions,
            productOptionsMulti,
            downloadableLinks
        } = productOptionsData || {};

        const productToAdd = {
            sku,
            product_type,
            quantity,
            product_option: {
                buy_request: buyRequest,
                extension_attributes: getExtensionAttributes(
                    {
                        ...product,
                        productOptions,
                        productOptionsMulti,
                        downloadableLinks
                    }
                )
            }
        };

        if (this._canBeAdded(options)) {
            try {
                const isCustomerSignedIn = isSignedIn();
                const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

                if (!isCustomerSignedIn && !guestQuoteId) {
                    return this.createGuestEmptyCart().then(() => this.addProductToCart(dispatch, options));
                    // return Promise.reject();
                }

                const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                    CartQuery.getSaveCartItemMutation(productToAdd, guestQuoteId)
                );

                if (cartData) {
                    dispatch(updateIsLoading(false));
                }
                return this._updateCartData(cartData, dispatch);
            } catch (error) {
                const {debugMessage, message } = error[0];
                if ((debugMessage && debugMessage.match('cartId')) || (debugMessage && debugMessage.match('guestCartId'))|| (message && message.match(__('Internal server error')))) {
                    dispatch(showNotification('error', debugMessage));
                    this.createGuestEmptyCart(dispatch);
                }
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));

                return Promise.reject();
            }
        }

        return Promise.reject();
    }


        /**
     * Check if it is allowed to add product to cart
     * @param {Object} options Cart options
     * @return {Boolean} Indicates is allowed or not
     * @memberof CartDispatcher
     */
        _canBeAdded(options) {
            if (options.product && options.quantity && (options.product.quantity + options.quantity) < 1) {
                return false;
            }
            const { 
                product: {
                    type_id,
                    categories = [],
                    price_range: {
                        minimum_price: {
                            final_price: {
                                value: minimalPriceValue
                            } = {},
                        } = {}
                    } = {},
                } = {}
            } = options

            if ((options.quantity === 0 || minimalPriceValue < 0.2) && type_id !== 'amgiftcard') {
                return false;
            }
    
            return true;
        }
}

export default new CartDispatcher();
