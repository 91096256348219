import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';


/** @namespace ThemeConfig/Component/CustomScriptContainer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    storeConfig: state.ConfigReducer
});

/** @namespace ThemeConfig/Component/CustomScriptContainer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
});

/** @namespace ThemeConfig/Component/CustomScriptContainer/Container/CustomScriptContainer */
export class CustomScriptContainer extends PureComponent {
    static propTypes = {
        storeConfig: PropTypes.object.isRequired,
    };

    static defaultProps = {
        storeConfig: {}
    };

    state = {
        isInitialized: false
    };
    componentDidMount() { 
        const {
            storeConfig: {
                code
            }
        } = this.props;
        if(code) {
            this.loadZEScript(code)
        }
    }
    
    componentDidUpdate(prevProps) {
        const {
            storeConfig: {
                code: storeCode
            }
        } = this.props;

        const {
            storeConfig: {
                code: prevStoreCode
            }
        } = prevProps;
        const {
            isInitialized
        } = this.state;

        if(storeCode !== prevStoreCode && !isInitialized) {
            this.loadZEScript(storeCode)
        }
    }  

    loadZEScript(storeCode) {
        let setZEAttemptCount = 0;
        let self = this;

        if(window.zE && typeof(window.zE) === 'function'){
        // Set the onload callback to execute the zE function with the desired arguments
            function setZE(){
                let zEId = document.getElementById('launcher');
                if(zEId){
                    zE.setLocale(storeCode);
                    setZEAttemptCount = 21;
                    self.setState({isInitialized: true});
                }
                if(setZEAttemptCount <= 20){
                    setZEAttemptCount++;
                    setTimeout(setZE,2000);
                }
            }
            setZE();
        }
      }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomScriptContainer));
