const _getStoreConfigFields = (args, callback, instance) => [
    ...callback(...args),
    'recaptcha_for_customer_login',
    'recaptcha_for_customer_forgot_password',
    'recaptcha_for_customer_create',
    'recaptcha_for_customer_edit',
    'recaptcha_for_contact',
    'recaptcha_for_product_review',
    'recaptcha_for_newsletter',
    'recaptcha_for_sendfriend',
    'recaptcha_recaptcha_public_key',
    'recaptcha_invisible_public_key',
    'recaptcha_recaptcha_v3_public_key'
];

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
