export const GET_CUSTOMERS_LIST = 'GET_CUSTOMERS_LIST';
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const UPDATE_CUSTOM_IS_LOADING = 'UPDATE_CUSTOM_IS_LOADING';
export const UPDATE_ADMIN_WIDGET_STATE = 'UPDATE_ADMIN_WIDGET_STATE';

export const getCustomersList = (customersList) => ({
    type: GET_CUSTOMERS_LIST,
    customersList
});

export const getOrdersList = (ordersList) => ({
    type: GET_ORDERS_LIST,
    ordersList
});

export const updateIsLoading = (isLoading) => ({
    type: UPDATE_CUSTOM_IS_LOADING,
    isLoading
});

export const updateAdminWidgetState = (isAdminCollapsed) => ({
    type: UPDATE_ADMIN_WIDGET_STATE,
    isAdminCollapsed
});
