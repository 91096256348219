import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { history } from 'SourceUtil/History';
import { ENCHANCE_REGISTRATION, IS_REDIRECT } from  '../utils/config';
export const ONE_MONTH_IN_SECONDS = 2628000;

const requestCustomerData = (args, callback, instance) => {
    const { customer: { custom_attributes } } = args[0]
    if(custom_attributes && Object.keys(custom_attributes).length > 0){
        const options = [
            "age_group",
            "hair_colour",
            "skin_type",
            "skin_concerns",
            "favorite_stores",
            "favorite_brands",
            "styling_type"
        ];
        
        const isReirect = BrowserDatabase.getItem(IS_REDIRECT) || false;
        const isCustomDataExist = Object.values(custom_attributes).filter(({ attribute_code }) => options.indexOf(attribute_code) !== -1);

        if(!Object.keys(isCustomDataExist).length > 0 && !isReirect) {
            BrowserDatabase.setItem(true, IS_REDIRECT, ONE_MONTH_IN_SECONDS);
            history.push({ pathname: `/${ENCHANCE_REGISTRATION}`})
        }
    }

    return callback.apply(instance, args);
}

const onCreateCustomer = (args, callback, instance) => {
    let result = callback.apply(instance, args);
    if( result !== 2) {
        history.push({ pathname: `/${ENCHANCE_REGISTRATION}`});
    }
    
    return result;

}

export default {
    'Store/MyAccount/Dispatcher/requestCustomerDataExecutePostThen' : {
        function: [
            {
                position: 101,
                implementation: requestCustomerData
            }
        ]
    },
    'Store/MyAccount/Dispatcher/createAccountFetchMutationThen' : {
        function: [
            {
                position: 101,
                implementation: onCreateCustomer
            }
        ]
    }
}
