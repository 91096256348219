import ProductAlertsQuery from 'SourceQuery/ProductAlerts.query';
import { fetchMutation } from 'SourceUtil/Request';
import { showPopup } from 'SourceStore/Popup/Popup.action';
import { PRODUCT_ALERT_POPUP } from '../component/ProductAlertsPopup/ProductAlertsPopup.config';
import { isSignedIn } from 'SourceUtil/Auth';
import history from 'SourceUtil/History';

const state = (args) => {

    return {
        ...args,
        isLoading: false
    }
}

const setLoadingState = (args, callback, instance) => {
  const { isLoading } = instance.state;
  instance.setState({ isLoading: !isLoading });
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        showPopup: (type, payload) => dispatch(showPopup(type, payload)),
    }
}

const productAlertPopUp = (instance, callback, args) => {
    const { showPopup, isProductPopup, url } = instance.props;
  
    if (isProductPopup) {
      return history.push({ pathname: url });
    }
  
    if (window.scrollY === 0) {
      return showPopup(PRODUCT_ALERT_POPUP, {
        title: __("Product Subscription"),
      });
    }
  
    scrollToTop().then(() => {
      return showPopup(PRODUCT_ALERT_POPUP, {
        title: __("Product Subscription"),
      });
    });
  };
  
  const scrollToTop = () => {
    return new Promise((resolve) => {
      if (window.scrollY === 0) {
        // No need to scroll, resolve immediately
        resolve();
      } else {
        const onScroll = () => {
          if (window.scrollY === 0) {
            // Scrolling is complete
            window.removeEventListener('scroll', onScroll);
            resolve();
          }
        };
  
        window.addEventListener('scroll', onScroll);
  
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    });
  };
  

const containerFunctions = (args, callback, instance) =>{
  
    return {
        ...args,
        productAlertPopUp: productAlertPopUp.bind(args, callback, instance),
        setLoadingState: callback.setLoadingState.bind(callback)
    }
};

const containerProps = (args, callback, instance) => { 
    const { isLoading } = instance.state;
    return {
        isLoading,
        isSignedIn: isSignedIn(),
        ...callback.apply(instance, args)
    }
}

const handlePriceDropSubscribe = (args, callback, instance) => {
    const type = args[0];
        const {
            productId,
            showErrorNotification,
            showNotification,
            // isSignedIn
        } = instance.props;
        const { email, allow } = args[1];

        if (!isSignedIn() && email) {

            const query = ProductAlertsQuery.getProductAlertGuestSubscribeMutation(productId, type, email);

            if(!allow) {
                return showNotification('info', __('Please accept T&C'));
            }

            return fetchMutation(query).then(
                /** @namespace Component/ProductAlertsGuest/Container/fetchMutation/then */
                (ProductAlertGuestSubscribe) => {
                    if (!ProductAlertGuestSubscribe) {
                        return null;
                    }
                    instance.setLoadingState();
                    return showNotification('success', __('You saved the alert subscription'));
                }
            ).catch(
                /** @namespace Component/ProductAlertsGuest/Container/fetchMutation/catch */
                (error) => {
                    instance.setLoadingState();
                    showErrorNotification('error', error[0].message);
                }
            );
    
        }

        return callback.apply(instance, args);
}

export default {
    'Component/ProductAlerts/Container/mapDispatchToProps':{
        function: {
            position: 102,
            implementation: mapDispatchToProps
        }
    },
    'Component/ProductAlerts/Container': {
        'member-property': {
            state
        },
        'member-function': {
            handlePriceDropSubscribe,
            setLoadingState,
            containerProps,
            containerFunctions
        }
    }
};