import { EVENT_GTM_CHECKOUT, EVENT_GTM_CHECKOUT_OPTION, EVENT_GTM_GUEST_STEP } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';
const filterShippingMethod = (shippingMethods, method_code) => Object.values(shippingMethods).filter(({ carrier_code }) => carrier_code == method_code);

const onShippingMethodSelect = (args, callback, instance) => {
    const { event, totals: { items } } = instance.props;
    const { selectedShippingMethod = {}, shippingMethods } = instance.state;

    const { method_code = {} } = selectedShippingMethod;
    const method = filterShippingMethod(shippingMethods, method_code);

    const option = method[0] ? method[0].carrier_code : ''

    const data = {
        products: items,
        option: option,
        step: 2,
        isShipping: true
    };

    event(EVENT_GTM_CHECKOUT_OPTION, data);
};

const componentDidUpdate  = (args, callback, instance) => {
    const [ prevProps, prevState ] = args;
    const { selectedShippingMethod: prevSelectedShippingMethod } = prevState;
    
    const { selectedShippingMethod } = instance.state;
    if(selectedShippingMethod?.method_code && 
       selectedShippingMethod?.method_code !== prevSelectedShippingMethod?.method_code ){
        onShippingMethodSelect(args, callback, instance);
       }
    callback.apply(instance, args);
}

const componentDidMount = (args, callback, instance) => {
    const { event, totals: { items = [] } = {} } = instance.props;

    const data = {
        products: items,
        option: '',
        step: 1
    };
    event(EVENT_GTM_GUEST_STEP, data);

    callback.apply(instance, args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            // onShippingMethodSelect: onShippingMethodSelect,
            componentDidUpdate
        }
    },
    'Component/CheckoutGuestForm/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Component/CheckoutGuestForm/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
