import { cloneElement, createRef } from 'react';

import {
    RECAPTCHA_TYPE_INVISIBLE,
    RECAPTCHA_TYPE_RECAPTCHA,
    RECAPTCHA_TYPE_RECAPTCHA_V3
} from '../component/Recaptcha/Recaptcha.config';
import Recaptcha from '../component/Recaptcha/Recaptcha.container';
import { getCaptchaKey, getRecaptchaConfigs } from '../utils/recaptchaConfig';

export class MyAccountForgotPasswordPlugin {
    recaptchaRef = createRef();

    renderForgotPasswordForm = (args, callback, instance) => {
        const originalDetails = callback.apply(instance, args);
        const { recaptcha_for_customer_forgot_password } = getRecaptchaConfigs();
        if (recaptcha_for_customer_forgot_password) {
            const { props: { children } } = originalDetails;
            return cloneElement(
                originalDetails,
                {},
                [...children, (<Recaptcha
                  recaptchaType={ recaptcha_for_customer_forgot_password }
                  recaptcha_public_key={ getCaptchaKey(recaptcha_for_customer_forgot_password) }
                  recaptchaRef={ this.recaptchaRef }
                />)]
            );
        }

        return originalDetails;
    };

    onForgotPasswordSuccess = (args, callback, instance) => {
        const { setLoadingState } = instance.props;
        const { recaptcha_for_customer_forgot_password } = getRecaptchaConfigs();
        switch (recaptcha_for_customer_forgot_password) {
        case RECAPTCHA_TYPE_INVISIBLE:
            this.recaptchaRef.current.reset();
            return this.recaptchaRef.current.executeAsync().then(() => {
                callback.apply(instance, args);
                setLoadingState(false);
            });
        default:
            callback.apply(instance, args)
            setLoadingState(false);
            return null;
        }
    };

    forgotPasswordThen = (args, callback) => {
        const [{ type }] = args;
        if (type === 'SHOW_NOTIFICATION') {
            window.recaptchaValidation = '';
            return;
        }
        const { recaptcha_for_customer_forgot_password } = getRecaptchaConfigs();
        switch (recaptcha_for_customer_forgot_password) {
        case RECAPTCHA_TYPE_RECAPTCHA:
            if (window.grecaptcha) {
                window.grecaptcha.reset();
            }
            window.recaptchaValidation = '';
            return callback(...args);
        default:
            window.recaptchaValidation = '';
            return callback(...args);
        }
    };
}

const { renderForgotPasswordForm, onForgotPasswordSuccess, forgotPasswordThen } = new MyAccountForgotPasswordPlugin();
export const config = {
    'Component/MyAccountForgotPassword/Component': {
        'member-function': {
            renderForgotPasswordForm
        }
    },
    'Component/MyAccountForgotPassword/Container': {
        'member-function': {
            onForgotPasswordSuccess
        }
    },
    'Component/MyAccountOverlay/Container/forgotPasswordThen': {
        function: [
            {
                position: 1,
                implementation: forgotPasswordThen
            }
        ]
    }
};

export default config;
