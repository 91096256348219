import {
    updateIsLoading,
    updateAllProductsInWishlist,
    removeItemFromWishlist,
    updateItemOptions,
    clearWishlist
} from 'SourceStore/Wishlist/Wishlist.action';
import { getCategoriesList } from '../../../store/BetterWishlist/BetterWishlist.action';
import BetterWishlist from '../../../query/BetterWishlist.query';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { isSignedIn } from 'SourceUtil/Auth';
import { fetchMutation, fetchQuery, getErrorMessage } from 'SourceUtil/Request';
import WishlistQuery from 'SourceQuery/Wishlist.query';
import getStore from 'SourceUtil/Store';
import { getPriceRange } from 'SourceUtil/Wishlist';
import { getGuestQuoteId } from 'SourceUtil/Cart';
import { extendCartDataProducts } from 'Util/Product/PerformaceProduct.js'

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);

// const getWishlistCategories = (args, callback, instance) => {
//     const [ dispatch ] = args;
//         if (!isSignedIn()) {
//             return Promise.reject();
//         }
//     dispatch(updateIsLoading(true));

//     fetchQuery(BetterWishlist.getQuery()).then(
//         ({ MpBetterWishlistGetCategories }) => {
//             dispatch(getCategoriesList( MpBetterWishlistGetCategories )),
//             dispatch(updateIsLoading(false));
//         },
//         (error) => {
//             dispatch(showNotification('error', __('Error fetching wish list categries!')));
//         }
//     );
    
// }

const addNewWishlistCategory = (args, callback, instance) => {
    const [dispatch, options] = args;
    if (!isSignedIn()) {
        return Promise.reject();
    }
    dispatch(updateIsLoading(true));

    return fetchMutation(BetterWishlist.getCreateCategroy(options)).then(
        ({ MpBetterWishlistCreateCategory }, error) => {
            const categories = [
                MpBetterWishlistCreateCategory
            ];

            if (!error) {
                dispatch(showNotification('success', __('New wishlist category added!')));
                dispatch(getCategoriesList(categories, true));
                dispatch(updateIsLoading(false));
            } else {
                dispatch(showNotification('error', __(error ? error[0].message : 'Error unable to add new category!')));
                dispatch(updateIsLoading(false));
            }
        },
        (error) => {
            dispatch(showNotification('error', __(error ? error[0].message : 'Error unable to add new category!')));
            dispatch(updateIsLoading(false));
        }
    );
}


const editWishlistCategoryName = (args, callback, instance) => {
    const [dispatch, options] = args;
    
    if (!isSignedIn()) {
        return Promise.reject();
    }
    dispatch(updateIsLoading(true));
    // dispatch(showNotification('success', __('Product added to wish-list!')));

   return  fetchMutation(BetterWishlist.getEditCategroy(options)).then(
        ({ MpBetterWishlistEditCategory: {
            category_name
        } }, error) =>
            !error ? dispatch(showNotification('success', __(`Wishlist category updated!`)))
                : null,
                dispatch(updateIsLoading(false)),
        (error) => {
            dispatch(showNotification('error', __(error ? error[0].message : 'Error unable to update category name!')));
        }
    );
}

const deleteCategory = (args, callback, instance) => {
    const [dispatch, options] = args;
    
    if (!isSignedIn()) {
        return Promise.reject();
    }
    dispatch(updateIsLoading(true));
    // dispatch(showNotification('success', __('Product added to wish-list!')));

    return fetchMutation(BetterWishlist.getDeleteCategory(options)).then(
        ({ MpBetterWishlistDeleteCategory: {
            category_name
        } }) =>
            instance.updateInitialWishlistData(dispatch),
            dispatch(showNotification('success', __('Wishlist Category deleted!'))),
        (error) => {
            dispatch(showNotification('error', __(error ? error[0].message : 'Error unable to delete category!')));
        }
    );
}

const addItemToCategory = (args, callback, instance) => {

    const [ dispatch, options ] = args;
        if (!isSignedIn()) {
            return Promise.reject();
        }

    dispatch(updateIsLoading(true));

      fetchMutation(BetterWishlist.getAddItemToCategory(options)).then(
        ({ MpBetterWishlistAddItem }, error) => 
            dispatch(showNotification('success', __(`Product Added Successfully`))),
            dispatch(updateIsLoading(false)),
            // instance.getWishlistCategories(dispatch),
            instance.updateInitialWishlistData(dispatch),
        (error) => {
            dispatch(showNotification('error', __(error ? error[0].message : 'unable to add item to wishlist!')));
        }
    );

    // return instance.updateInitialWishlistData(dispatch);
}

const moveAllItemsToWishlist = (args, callback, instance) => {

    const [ dispatch, options ] = args;
        if (!isSignedIn()) {
            return Promise.reject();
        }

        const successMessage = __('All item moved to wishlist');
        const infoMessage = __('unable to add all item to wishlist');

     fetchMutation(WishlistQuery.moveAllItemsToWishlist(options)).then(
        ({ moveAllItemsToWishlist: status }) => 
            dispatch(showNotification(
                status ? 'success' : 'info', 
                status ? successMessage : infoMessage
            )),
            dispatch(updateIsLoading(false)),
            instance.updateInitialWishlistData(dispatch),
        (error) => {
            dispatch(showNotification('error', __(error ? error[0].message : 'unable to all item from wishlist!')));
        }
    );

    // return instance.updateInitialWishlistData(dispatch);
}

const removeItemFromCategory = (args, callback, instance) => {

    const [ dispatch, options ] = args;
        if (!isSignedIn()) {
            return Promise.reject();
        }

     fetchMutation(BetterWishlist.getRemoveItemFromCategory(options)).then(
        ({ MpBetterWishlistRemoveItem }, error) => 
            // !error ? dispatch(showNotification('success', __(`Product Removed Successfully`)))
            // : null,
            dispatch(updateIsLoading(false)),
            instance.updateInitialWishlistData(dispatch),
        (error) => {
            dispatch(showNotification('error', __(error ? error[0].message : 'unable to remove item from wishlist!')));
        }
    );

    // return instance.updateInitialWishlistData(dispatch);
}

const removeItemFromGuestWishlist = (args, callback, instance) => {
    const [ dispatch ] = args;
    const { item_id, noMessages } = args[1]
    dispatch(updateIsLoading(true));
    if (item_id && !isSignedIn()) {
        dispatch(showNotification('info', __('Product has been removed from your Wish List!')));

        return fetchMutation(WishlistQuery.getRemoveProductFromGuestWishlistMutation(item_id)).then(
            /** @namespace Store/Wishlist/Dispatcher/removeItemFromGuestWishlistFetchMutationThen */
            () => dispatch(removeItemFromWishlist(item_id)),
            /** @namespace Store/Wishlist/Dispatcher/removeItemFromWishlistGuestFetchMutationError */
            () => {
                dispatch(showNotification('error', __('Error updating wish list!')));
            }
        );
    }

    if (!item_id || !isSignedIn()) {
        return Promise.reject();
    }
    dispatch(updateIsLoading(true));

    if (noMessages) {
        return fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id)).then(
            () => instance.updateInitialWishlistData(dispatch),
            /** @namespace Store/Wishlist/Dispatcher/removeItemFromWishlistNoMessagesFetchMutationThen */
            () => dispatch(removeItemFromWishlist(item_id))
        );
    }

    dispatch(showNotification('info', __('Product has been removed from your Wish List!')));

    return fetchMutation(WishlistQuery.getRemoveProductFromWishlistMutation(item_id)).then(
        () => instance.updateInitialWishlistData(dispatch),
        /** @namespace Store/Wishlist/Dispatcher/removeItemFromWishlistFetchMutationThen */
        () => dispatch(removeItemFromWishlist(item_id)),
        /** @namespace Store/Wishlist/Dispatcher/removeItemFromWishlistFetchMutationError */
        () => {
            dispatch(showNotification('error', __('Error updating wish list!')));
        }
    );
    // return callback.apply(instance, args);
}

const _syncWishlistWithBE = (args, callback, instance) => {
    const [ dispatch ] = args;
    if (!isSignedIn()) { 
        return fetchQuery(WishlistQuery.getWishlistGuestQuery()).then(
            /** @namespace Store/Wishlist/Dispatcher/_syncWishlistWithBEGuestFetchQueryThen */
            (data) => {
                if (data && data.wishlist) {
                    let { wishlist } = data;
                    
                    if(wishlist.items?.length > 0){
                        wishlist.items = extendCartDataProducts(wishlist.items, true) || {};
                    }

                    let productsToAdd = wishlist.items.reduce((prev, wishlistItem) => {
                        const {
                            id,
                            sku,
                            product,
                            description,
                            price,
                            price_without_tax,
                            buy_request,
                            options,
                            qty: quantity,
                            price_range
                        } = wishlistItem;

                        const priceRange = !isSignedIn() ? price_range : getPriceRange(product, price, price_without_tax);

                        return {
                            ...prev,
                            [id]: {
                                ...product,
                                ...priceRange,
                                quantity,
                                wishlist: {
                                    id,
                                    sku,
                                    quantity,
                                    description,
                                    buy_request,
                                    options
                                }
                            }
                        };
                    }, {});

                    dispatch(updateAllProductsInWishlist(productsToAdd));
                } else {
                    dispatch(updateIsLoading(false));
                }
            },
            /** @namespace Store/Wishlist/Dispatcher/_syncWishlistWithBEFetchQueryError */
            () => {
                dispatch(updateIsLoading(false));
            }
        );
    }

    return callback.apply(instance, args);
}

const moveOrCopyItem = (args, callback, instance) => {
    const [ dispatch, options ] = args;

    if (!isSignedIn()) { 
        dispatch(showNotification('error', __('Please Sign in to move or copy wishlist items!')));
    }
        dispatch(updateIsLoading(true));

        return fetchMutation(BetterWishlist.getMoveOrCopyItem(options)).then(
            dispatch(showNotification('success', __(`Product Move/Copy Successfully`))),
            () => dispatch(showNotification('error', __('Error updating wish list!')))
        );

}


const addItemToWishlist = (args, callback, instance) => {
    const [ dispatch, wishlistItem ] = args;
    if (!isSignedIn()) {

        dispatch(updateIsLoading(true));
        dispatch(showNotification('success', __('Product added to wish-list!')));
        return fetchMutation(WishlistQuery.getSaveWishlistItemGuestMutation(wishlistItem)).then(
            /** @namespace Store/Wishlist/Dispatcher/addItemToWishlistFetchMutationThen */
            () => instance._syncWishlistWithBE(dispatch),
            /** @namespace Store/Wishlist/Dispatcher/addItemToWishlistFetchMutationError */
            () => {
                dispatch(showNotification('error', __('Error updating wish list!')));
            }
        );

    }

    delete wishlistItem.id;
    delete wishlistItem.options;

    return callback.apply(instance, args);
}

const updateWishlistItem = (args, callback, instance) => {
    const [ dispatch, options ] = args;
    
    if (!isSignedIn()) {
        return fetchMutation(WishlistQuery.getSaveWishlistItemGuestMutation(options)).then(
            /** @namespace Store/Wishlist/Dispatcher/updateGuestWishlistItemFetchMutationThen */
            () => dispatch(updateItemOptions(options))
        );
    }

    return callback.apply(instance, args);

}

const updateInitialWishlistData = (args, callback, instance) => {
    const [ dispatch ] = args;
    if (!isSignedIn()) {
        instance._syncWishlistWithBE(dispatch);
    } else {
        return callback.apply(instance, args);
    }
}


const clearGuestWishlist = (args, callback, instance) => {
    const [dispatch, options] = args;

    if (!isSignedIn()) {
        return fetchMutation(WishlistQuery.getClearGuestWishlist())
            .then(
                /** @namespace Store/Wishlist/Dispatcher/clearGuestWishlistFetchMutationThen */
                () => dispatch(clearWishlist())
            )
            .catch(
                /** @namespace Store/Wishlist/Dispatcher/clearGuestWishlistFetchMutationThenCatch */
                () =>
                    dispatch(
                        showNotification(
                            "error",
                            __("Error clearing wish list!")
                        )
                    )
            );
    }

    return fetchMutation(WishlistQuery.getClearWishlist(options))
    .then(
        /** @namespace Store/Wishlist/Dispatcher/clearWishlistFetchMutationThen */
        () => dispatch(clearWishlist())
    )
    .catch(
        /** @namespace Store/Wishlist/Dispatcher/clearWishlistFetchMutationThenCatch */
        () => dispatch(showNotification('error', __('Error clearing wish list!')))
    );

};

const moveWishlistToCart = (args, callback, instance) => {
    const [dispatch, ] = args;
    const guestId = getGuestQuoteId();
    if (!isSignedIn()) {
        return fetchMutation(WishlistQuery.getMoveGuestWishlistToCart(guestId))
        .then(
            /** @namespace Store/Wishlist/Dispatcher/moveGuestWishlistToCartFetchMutationThen */
            () => {
                instance._syncWishlistWithBE(dispatch);
                CartDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                );
            }
        );

    }
        return callback.apply(instance, args);
}

const _syncWishlistWithBEFetchQueryThen = (args, callback, instance) => { 
    let [ data ] = args;
    const { dispatch } = getStore();
    
    if(data?.wishlist?.items?.length > 0){
        data.wishlist.items = extendCartDataProducts(data.wishlist.items, true) || {};
    }
    
        if (data && data.wishlist) {
            const { wishlist } = data;

            const productsToAdd = wishlist.items.reduce((prev, wishlistItem) => {
                const {
                    id,
                    sku,
                    product,
                    description,
                    price,
                    price_without_tax,
                    buy_request,
                    options,
                    categories,
                    qty: quantity,
                    product: {
                        price_range
                    }
                } = wishlistItem;
                // const priceRange = getPriceRange(product, price, price_without_tax);

                const categoryIdsArray = categories && categories.split(',') || [];

                return {
                    ...prev,
                    [id]: {
                        ...product,
                        ...price_range,
                        quantity,
                        wishlist: {
                            id,
                            sku,
                            quantity,
                            description,
                            buy_request,
                            options,
                            categories: categoryIdsArray
                        }
                    }
                };
            }, {});

            dispatch(updateAllProductsInWishlist(productsToAdd));
            dispatch(getCategoriesList( wishlist?.wishlist_categories ));
        } else {
            dispatch(updateIsLoading(false));
        }
    
    // return callback( ...[ wishlist ]);
}

export default {
    'Store/Wishlist/Dispatcher': {
        'member-function': {
            moveOrCopyItem,
            // getWishlistCategories,
            addNewWishlistCategory,
            addItemToCategory,
            removeItemFromCategory,
            editWishlistCategoryName,
            addItemToWishlist,
            _syncWishlistWithBE,
            updateInitialWishlistData,
            removeItemFromWishlist: removeItemFromGuestWishlist,
            deleteCategory,
            updateWishlistItem,
            clearWishlist: clearGuestWishlist,
            moveWishlistToCart,
            moveAllItemsToWishlist
        }
    },
    'Store/Wishlist/Dispatcher/_syncWishlistWithBEFetchQueryThen': {
        'function': _syncWishlistWithBEFetchQueryThen
    }
}