/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React, { PureComponent } from 'react';
import ReactCustomFlagSelect from 'react-custom-flag-select';

import cnImage from '../../public/asset/images/flag/cn.svg';
import cyprusImage from '../../public/asset/images/flag/cyprus_icon.svg';
import greeceImage from '../../public/asset/images/flag/greece.svg';
import usImage from '../../public/asset/images/flag/us.svg';

import 'react-custom-flag-select/lib/react-custom-flag-select.min.css';
import './FieldPhone.style';

/** @namespace Mageguidepwahondosc/Flaggedphone/Component/FieldPhone/Component/FieldPhoneComponent */
export class FieldPhoneComponent extends PureComponent {
    FLAG_SELECTOR_OPTION_LIST = [
        // { id: '1', name: 'US', displayText: 'US(1)', locale: 'en-US', englishName: 'United States', flag: usImage },
        // { id: '86', name: '中国', displayText: '中国(86)', locale: 'zh-CN', englishName: 'China', flag: cnImage },
        {
            id: '30', name: 'GR', displayText: 'GR(30)', locale: 'el-GR', englishName: 'Greece', flag: greeceImage
        },
        {
            id: '357', name: 'Cyprus', displayText: 'CY(357)', locale: 'en-CY', englishName: 'Cyprus', flag: cyprusImage
        }
    ];
    __construct() {
        super.__construct();
        const { value, setAreaCode } = this.props;
        let areaCode = value.charAt(0) === '+' ? value.substring(1, 3) : '30';
        if (!this.FLAG_SELECTOR_OPTION_LIST.some((item) => {
            item.id === areaCode;
        })) {
            // fallback to default greece when no area code match
            areaCode = '30';
        }
        this.state = {
            areaCode
        };
        setAreaCode(areaCode);
    }

    handlePhoneChange(phone) {
        const { handleChange } = this.props;
        handleChange('');
    }

    render() {
        const find = (arr, obj) => {
            const res = [];
            arr.forEach((o) => {
                let match = false;
                Object.keys(obj).forEach((i) => {
                    if (obj[i] == o[i]) {
                        match = true;
                    }
                });
                if (match) {
                    res.push(o);
                }
            });

            return res;
        };

        const { areaCode, phone, validate } = this.state;
        let {
            validation, label, max, min, formRef,
            type,
            name,
            id,
            value,
            validateSeparately,
            isSubmitted,
            fileExtensions,
            formrefmap,
            filename, ...props
        } = this.props;

        const currentItem = find(this.FLAG_SELECTOR_OPTION_LIST, { id: areaCode })?.[0] || null;
        // forcefully replace + character
        value = value ? value.replace(`+${areaCode}`, '').replace('+', '') : '';

        if (!currentItem) {
            return (
                <div block="FieldPhone">
                    <div>
                        <input
                          name={ name }
                          id={ id }
                          ref={ formRef }
                          { ...props }
                          value={ `+${areaCode + value}` }
                        />
                    </div>
                    <label className="Field-Label">{ __('Phone') }</label>
                </div>
            );
        }

        return (
            <div block="FieldPhone">
                <ReactCustomFlagSelect
                  attributesWrapper={ { id: 'areaCodeWrapper', tabIndex: '1' } } // Optional.[Object].Modify wrapper general attributes.
                  attributesButton={ { id: 'areaCodeButton' } } // Optional.[Object].Modify button general attributes.
                  attributesInput={ { id: 'areaCode', name: 'areaCode' } } // Optional.[Object].Modify hidden input general attributes.
                  value={ currentItem.id } // Optional.[String].Default: "".
                  disabled={ false } // Optional.[Bool].Default: false.
                  showSearch={ false } // Optional.[Bool].Default: false. Show a search box in order to find option quickly.
                  fields={ ['name', 'locale', 'displayText', 'englishName'] } // Optional.[array].Default: ['name']. Fields for search filtering.
                    // keyword={''} // Optional.[String].Default: ''. Show a keyword for search box.
                  showArrow // Optional.[Bool].Default: true.
                  animate // Optional.[Bool].Default: false.
                  optionList={ this.FLAG_SELECTOR_OPTION_LIST } // Required.[Array of Object(s)].Default: [].
                  customStyleOptionListContainer={ {
                      maxHeight: '100px', overflow: 'auto', width: '120px', marginTop: '11px'
                  } } // Optional.[Object].Default: {}.
                  onChange={ (areaCode) => {
                      props.setAreaCode(areaCode);
                      this.setState({ areaCode }, () => {
                          // this.handlePhoneChange(areaCode);
                      });
                      this.handlePhoneChange(areaCode);
                  } }
                />
                <input
                  value={ value }
                  { ...props }
                />
                { /* added hide class instead of hidden prop because of no scroll during validation issue */ }
                <div className="hide">
                    <input
                      name={ name }
                      id={ id }
                      ref={ formRef }
                      { ...props }
                      value={ `+${areaCode + value}` }
                    />
                </div>
                <label className="Field-Label">{ __('Phone') }</label>
            </div>
        );
    }
}

export default FieldPhoneComponent;
