import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { TRACK_ORDER } from '../route/TrackOrder/TrackOrder.config'
export const withStoreRegex = (path) => window.storeRegexText.concat(path);
export const TrackOrder = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "track-order" */ '../route/TrackOrder'));

const removeFirstEmptyKey = (originalMember) => {
    if(originalMember[0] && Object.keys(originalMember[0]).length == 0) {
        originalMember.shift();
    }
}

export class RouterPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => {
        removeFirstEmptyKey(originalMember);

        return [
            ...originalMember,
            {
                component: <Route path={withStoreRegex(`/${TRACK_ORDER}`)} component={TrackOrder} />,
                position: 94
            }
        ]
    };
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    'Route/UrlRewrites/Component': {
        'member-property': {
            SWITCH_ITEMS_CUSTOM: SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
