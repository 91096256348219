import {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import BankRedirectComponent from "./BankRedirect.component";
import BrowserDatabase from 'Util/BrowserDatabase';
import {BANK_PAYMENT_CODE} from './BankRedirect.config';

export const mapStateToProps = (state) => ({
    mg_bank_redirect_winbank: state.ConfigReducer.mg_bank_redirect_winbank,
    mg_bank_redirect_alphabank: state.ConfigReducer.mg_bank_redirect_alphabank,
    mg_bank_redirect_nbg_enterprise: state.ConfigReducer.mg_bank_redirect_nbg_enterprise,
    mg_bank_redirect_eurobank: state.ConfigReducer.mg_bank_redirect_eurobank
});

export const mapDispatchToProps = (dispatch) => ({});

export class BankRedirect extends PureComponent {

    static propTypes = {
        setLoading: PropTypes.func,
        setDetailsStep: PropTypes.func,
        selectedPaymentCode: PropTypes.string.isRequired,
        instructions: PropTypes.string
    };

    state = {
        is_installment: false,
        installment_period: 1,
        mg_bank_redirect_winbank: {},
        mg_bank_redirect_alphabank: {},
        mg_bank_redirect_eurobank : {}
    }

    static getDerivedStateFromProps(props, state) {
        const {mg_bank_redirect_winbank, mg_bank_redirect_alphabank, mg_bank_redirect_eurobank, mg_bank_redirect_nbg_enterprise} = props;
        return {
            mg_bank_redirect_winbank,
            mg_bank_redirect_alphabank,
            mg_bank_redirect_nbg_enterprise,
            mg_bank_redirect_eurobank
        };
    }

    containerFunctions = {
        isDisabled: this.checkDisabled(),
        onCheckBoxChange: this.onCheckBoxChange.bind(this),
        onFieldUpdate: this.onFieldUpdate.bind(this)
    };

    onCheckBoxChange(e, status) {
        const {
            setBankRedirectAdditionalData,
            selectedPaymentCode,
            mg_bank_redirect_winbank,
            mg_bank_redirect_alphabank,
            mg_bank_redirect_eurobank,
        } = this.props;
        this.setState({
            is_installment: status
        });
        const { grand_total } = BrowserDatabase.getItem('cart_totals');
        let instructions = '';
        let installments_period = '';
        let enable_installment = '';
        if(selectedPaymentCode === "mg_bank_redirect_winbank"){
            instructions = mg_bank_redirect_winbank ? mg_bank_redirect_winbank.instructions : "";
            installments_period = mg_bank_redirect_winbank ? mg_bank_redirect_winbank.installments_period : "";
            enable_installment = mg_bank_redirect_winbank ? mg_bank_redirect_winbank.enable_installment: "";
        }
        else if(selectedPaymentCode === "mg_bank_redirect_alphabank"){
            instructions = mg_bank_redirect_alphabank ? mg_bank_redirect_alphabank.instructions : "";
            installments_period = mg_bank_redirect_alphabank ? mg_bank_redirect_alphabank.installments_period : "";
            enable_installment = mg_bank_redirect_alphabank ? mg_bank_redirect_alphabank.enable_installment : "";
        }
        else if(selectedPaymentCode === "mg_bank_redirect_eurobank"){
            instructions = mg_bank_redirect_eurobank ? mg_bank_redirect_eurobank.instructions: "";
            installments_period = mg_bank_redirect_eurobank ? mg_bank_redirect_eurobank.installments_period: "";
            enable_installment = mg_bank_redirect_eurobank ? mg_bank_redirect_eurobank.enable_installment: "";
        }
        let installmentPeriodConditions = installments_period ? installments_period.split(',').map((item)=>{
            let splitedItem = item.split(':');
            return { months : splitedItem[0], grand_total : splitedItem[1]  };
        }): [];
        let installmentPeriod = [];
        let found = false;
        installmentPeriodConditions.map(item => {
            let itemGrandTotal = parseFloat(item.grand_total);
            if(grand_total < itemGrandTotal){
                if(found)
                    return;
                found = true;
                this.range(2,parseInt(item.months)).map(month => {
                    installmentPeriod.push({ id : month , label : month + ' μηνιαίες δόσεις', value : month });
                });
            }
        });
        const a = installmentPeriod?.[0]?.value;
        if(a){
            setBankRedirectAdditionalData({ is_installment: status, installment_period: a});
        }else {
            setBankRedirectAdditionalData({ is_installment: status});
        }
    }

    range(start, end){
        const length = end - start + 1;
        return Array.from({ length }, (_, i) => start + i);
    }

    onFieldUpdate(a) {
        const {setBankRedirectAdditionalData} = this.props;
        this.setState({
            installment_period: a
        });
        setBankRedirectAdditionalData({ installment_period: a, is_installment : this.state.is_installment});
    }

    checkDisabled() {
        const {selectedPaymentCode} = this.props;
        return selectedPaymentCode.indexOf(BANK_PAYMENT_CODE) < 0
    };

    render() {
        return (
            <BankRedirectComponent
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BankRedirect)



