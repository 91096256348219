import { Field } from 'Util/Query';
import getStore from 'Util/Store';

const brandSizeGuideFields = () => {
    return new Field('brandSizeGuide').addFieldList([
        'option_id',
        'fileType',
        'fileTypeMobile',
        'filePath',
        'filePathMobile'
    ])
}
export const aroundGetProductInterfaceFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);
    const { isSingleProduct } = instance.options;
    if(isSingleProduct){
        let brand_attributes = getStore().getState().ConfigReducer.brand_attributes;
        if(brand_attributes){
            fields.push(brandSizeGuideFields());
            brand_attributes?.split(',').map((i) =>  fields.push(i) )
        }
    }
    return fields;
};

export default {
    'Query/ProductList': {
        'member-function': {
            _getProductInterfaceFields: aroundGetProductInterfaceFields
        }
    }
};
