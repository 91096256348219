import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import { ProductWishlistButton as SourceProductWishlistButton } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';
import React, {lazy} from "react";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

/** @namespace Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends SourceProductWishlistButton {

    renderButton() {
        const { isInWishlist, isDisabled, mix, isCart } = this.props;
        let wishlist_image = 'wishlist_small.svg';

        if(isInWishlist) {
            wishlist_image = 'wishlist_small_fill.svg';
        }

        if(isCart) {
            return (
                <button
                    block="ProductWishlistButton"
                    elem="Button"
                    mods={ { isInWishlist, isDisabled } }
                    mix={ { block: 'Button', mix } }
                    title={ this.getTitle() }
                    onClick={ this.onClick }
                >
                    <UniversalIcon
                        src={media(wishlist_image, WYSIWYG_MEDIA)}
                        alt="HeartIcon"
                        className="HeartIcon"
                    />
                    <span>{ __('Save to favorites') }</span>
                </button>
            );
        }

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <UniversalIcon
                    src={media(wishlist_image, WYSIWYG_MEDIA)}
                    alt="HeartIcon"
                    className="HeartIcon"
                />
            </button>
        );
    }
}

export default ProductWishlistButton;
