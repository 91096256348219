import {isSignedIn} from 'SourceUtil/Auth';
import {appendWithStoreCode, convertQueryStringToKeyValuePairs} from 'SourceUtil/Url';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

const getData = (name) => JSON.parse(localStorage.getItem(name));

const state = (args) => {
    return {
        ...args,
        isTokenExpirationChecked: false
    }
}
let checkSignInFlag = true;

const checkExpiration = (_instance) => {
    if(!checkSignInFlag){
        return;
    }
    const {
        logout,
        signIn
    } = _instance.props;
    const entryObject = JSON.parse(localStorage.getItem('auth_token'));
    if (
        !entryObject ||
        !isSignedIn()
    ) {
        return false;
    }
    const {
        expiration,
        createdAt
    } = entryObject;
    const expiry = parseInt(expiration) - (60 * 30);
    if (!expiry) {
        return false;
    }
    const now = parseInt(Date.now() / 1000);
    const deadline = parseInt(
        Math.min(
            Math.max(
                createdAt + expiry - now,
                30
            ),
            Math.max(
                3600 - (now - createdAt),
                30
            )
        )
    );
    console.log(
        'times',
        entryObject,
        {
            createdAt: createdAt,
            deadline: deadline,
            expiry: expiry,
            expiration: expiration,
            now: now
        }
    );
    if(!createdAt){
        console.log('no created at');
        return;
    }
    _instance.setState({isTokenExpirationChecked: true})
    return setTimeout(
        async () => {
            try {
                _instance.setState({isTokenExpirationChecked: false});
                // if ((now - createdAt) > expiration) {
                //     console.log('no time to renew');
                //     return;
                // }
                checkSignInFlag = false;
                console.log('renewing');
                await signIn({
                    email: '',
                    password: '',
                    noCaptcha: true
                });
            } catch (e) {
                _instance.setState({isTokenExpirationChecked: true});
                console.log(e);
            }

        },
        deadline * 1000   //in milliseconds
    );
}

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        logout: () => MyAccountDispatcher.then(
            ({default: dispatcher}) => dispatcher.logout(true, dispatch)
        ),
        signIn: (args) => MyAccountDispatcher.then(
            ({default: dispatcher}) => dispatcher.signIn(args, dispatch)
        )
    };
};

const componentDidUpdate = (_args, _callback, _instance) => {
    const {isTokenExpirationChecked} = _instance.state;
    if (!isTokenExpirationChecked) {
        checkExpiration(_instance);
    }

    return _callback.apply(_instance, _args);
}

const componentDidMount = (_args, _callback, _instance) => {
    const {isTokenExpirationChecked} = _instance.state;
    const {history} = _instance.props;
    const {pathname, search} = location;

    const {token} = convertQueryStringToKeyValuePairs(search);
    if (pathname.indexOf('customer/account') !== -1 &&
        !pathname.includes('createPassword') && !token) {
        history.push({
            pathname: appendWithStoreCode('account/login')
        })
    }

    if (!isTokenExpirationChecked) {
        checkExpiration(_instance);
    }

    return _callback.apply(_instance, _args);
}


export default {
    'Component/Header/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/Header/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        },
        'member-property': {
            state
        },
    }
};