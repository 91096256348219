import {
    EVENT_GTM_PRODUCT_CLICK
} from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

/** ProductCard */
const registerSharedElement = (args, callback, instance) => {
    const { event, position, itemPosition } = instance.props;
    const { pathname } = window.location;
    const { linkTo: { state: { prevCategoryId } } } = instance.props;
    
    const {
        currentVariantIndex: configurableVariantIndex,
        selectedFilters,
        product,
        list
    } = instance.props;

    const productToPass = Object.keys(selectedFilters).length
        ? {
            ...product, 
            configurableVariantIndex, 
            prevCategoryId, 
            position: itemPosition || position, 
            pathname,
            list
        }
        : {
            ...product, 
            prevCategoryId, 
            position: itemPosition || position , 
            pathname,
            list
        };

    event(EVENT_GTM_PRODUCT_CLICK, productToPass);

    callback(...args);
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};

export const containerProps = (args, callback, instance) => {
    const {
        event,
        list,
        selectedFilters,
    } = instance.props;

    return {
        ...callback(...args),
        selectedFilters,
        event,
        list
    };
}

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            registerSharedElement
        }
    },
    'Component/ProductCard/Container/mapDispatchToProps': {
            function: mapDispatchToProps
    },
    'Component/ProductCard/Container': {
        'member-function': { 
            containerProps
        }
    }
};
