/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ProductAttributeValue from 'Component/ProductAttributeValue';
import Html from 'Component/Html';
import { removeStyleFromString } from '../../util/String';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';
import {
    PERFUME_SET,
    PIM_BEAUTY_SIZE,
    BEAUTY_COLOR_SET,
    PIM_BEAUTY_COLOR,
    FASHION_SET,
    PIM_FASHION_ACCESSORIES,
    DEFAULT_SET,
    PIM_BEAUTY,
    PIM_FASHION,
    PIM_FASHION_SIZE
} from '../ProductAttributeSet/ProductAttributeSet.config'
import { getVariantIndex } from 'Util/Product';
import './ProductConfigurableAttributes.style';
import { ProductConfigurableAttributes as SourceProductConfigurableAttributes } from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import { sortAlphabetically, sortBySortOrder } from 'Util/Product';

/** @namespace Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {

    renderSwatch(option) {
        const { attribute_values, attribute_code, attribute_options } = option;
        
        const options = Object.values(attribute_options).reduce((acc, a) => (a.label ? [...acc, a] : acc), []);
        const sortedOptions = options.every((option) => option.sort_order)
            ? sortBySortOrder(options)
            : sortAlphabetically(options, 'label');


        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
              key={ attribute_code }
            >
                { sortedOptions.map(({ value }) => (
                    this.renderConfigurableAttributeValue({ ...option, attribute_value: value })
                )) }
            </div>
        );
    }

    renderDropdown(option) {
        const {
            updateConfigurableVariant,
            getIsConfigurableAttributeAvailable,
            parameters,
            isProductPage,
            attributeSetId,
            getIsConfigurableStockStatus,
            getIsConfigurableLowStock,
            lowStockQty
        } = this.props;

        return (
            <ProductConfigurableAttributeDropdown
              option={ option }
              updateConfigurableVariant={ updateConfigurableVariant }
              getIsConfigurableAttributeAvailable={ getIsConfigurableAttributeAvailable }
              parameters={ parameters }
              isProductPage={isProductPage}
              attributeSetId={attributeSetId}
              getIsConfigurableStockStatus = { getIsConfigurableStockStatus }
              getIsConfigurableLowStock = { getIsConfigurableLowStock }
              lowStockQty ={ lowStockQty }
            />
        );
    }

    renderImageSwatch(option) {
        const { attribute_values, attribute_code } = option;
        const sortedOptions = sortBySortOrder(attribute_values).reverse();
        return (
            <div
                block="ProductConfigurableAttributes"
                elem="SwatchListWrapper"
            >
                <div
                    block="ProductConfigurableAttributes"
                    elem="SwatchList"
                    key={ attribute_code }
                >
                    { sortedOptions.map((attribute_value, index) => (
                        this.renderConfigurableAttributeValue({ ...option, attribute_value, index })
                    )) }
                </div>
            </div>
        );
    }

    renderAttributeSetBasedConfigurableLayout(type, option, attribute_label,attribute_id, isSwatch) {
        const { comments } = this.props;
        switch (type) {
            case BEAUTY_COLOR_SET:
            case PIM_BEAUTY_COLOR:
                return (
                    <>
                        { this.renderDropdown(option) }
                        { isSwatch ? this.renderSwatch(option) : null }
                    </>
                );
            case PERFUME_SET:
            case PIM_BEAUTY_SIZE:
                return (
                    <>
                        <p block="ProductConfigurableAttributes" elem="Title">{ __('Choose %s',attribute_label) }</p>
                        { this.renderImageSwatch(option) }
                    </>
                );
            case FASHION_SET:
            case PIM_FASHION_ACCESSORIES:
            case PIM_FASHION:
            case PIM_FASHION_SIZE:
                return (
                    <>
                        { this.renderDropdown(option) }
                        { comments ? <p block="ProductConfigurableAttributes" elem="Hint"><Html content={ removeStyleFromString(comments) } /></p> : '' }
                    </>
                );
            case PIM_BEAUTY:
            case DEFAULT_SET:
                return (
                    <div key={ attribute_id }>
                        <p block="ProductConfigurableAttributes" elem="Title">{ attribute_label }</p>
                        { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
                    </div>
                );
            default:
                return (
                    <div key={ attribute_id }>
                        <p block="ProductConfigurableAttributes" elem="Title">{ attribute_label }</p>
                        { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
                    </div>
                );
        }
    }

    getStockStatus = ({ attribute_code, attribute_value }) => {
        const { variants, parameters } = this.props;
        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return false;
        }

        // If value matches current attribute_value, option should be enabled
        var status = true;
         variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};
                if(attribute_value === foundValue) {
                    return  stock_status === 'IN_STOCK' ? false : true;
                }
            });
            
        return status;
    }

    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailableForSwatch,
            handleOptionClick,
            getLink,
            isSelected,
            showProductAttributeAsLink,
            inStock,
            thumbnail,
            attributeSetId,
            variants,
            navigationName
        } = this.props;

        const { attribute_value, attribute_code } = attribute;

        const parameters = {
            [attribute_code]: attribute_value
        };
        
        const index = getVariantIndex(variants, parameters, true);

        return (
            <ProductAttributeValue
                navigationName = { navigationName }
                key={ attribute_value }
                attribute={ attribute }
                attributeSetId={attributeSetId}
                isSelected={ isSelected(attribute) }
                isAvailable={ this.getStockStatus(attribute) && getIsConfigurableAttributeAvailableForSwatch(attribute) }
                onClick={ handleOptionClick }
                getLink={ getLink }
                thumbnail={ variants && variants[index] && variants[index].thumbnail ? variants[index].thumbnail : thumbnail }
                showProductAttributeAsLink={ showProductAttributeAsLink }
            />
        );
    }

    renderConfigurableAttributes() {
        const {
            configurable_options,
            isExpandable,
            inStock,
            isProductPage,
            attributeSetId
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const {
                attribute_label,
                attribute_options,
                attribute_id
            } = option;

            const [{ swatch_data }] = attribute_options && Object.values(attribute_options).length ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatch(option) : this.renderDropdown(option);
            }

            // if (!inStock && !isSwatch) {
            //     return null;
            // }

            if(isProductPage) {
                return this.renderAttributeSetBasedConfigurableLayout(attributeSetId, option, attribute_label,attribute_id, isSwatch);
            }

            return (
                <div key={ attribute_id }>
                    <p block="ProductConfigurableAttributes" elem="Title">{ attribute_label }</p>
                    { isSwatch ? this.renderSwatch(option) : this.renderDropdown(option) }
                </div>
            );
        });
    }
}

export default ProductConfigurableAttributes;
