import { FieldSelectContainer as SourceFieldSelectContainer } from 'SourceComponent/FieldSelect/FieldSelect.container';

export class FieldSelectContainer extends SourceFieldSelectContainer {
    containerFunctions = {
        handleSelectExpand: this.handleSelectExpand.bind(this),
        handleSelectExpandedExpand: this.handleSelectExpandedExpand.bind(this),
        handleSelectListOptionClick: this.handleSelectListOptionClick.bind(this),
        handleSelectListKeyPress: this.handleSelectListKeyPress.bind(this),
        handleUnselectListOptionClick: this.handleUnselectListOptionClick.bind(this)
    };

    containerProps() {
        const {
            onChange,
            id,
            name,
            label,
            formRef,
            placeholder,
            value,
            autocomplete,
            skipValue,
            isMobile,
            isProductPage,
            attributeSetId,
            colorAttribute
        } = this.props;
        const {
            valueIndex,
            searchString,
            isSelectExpanded
        } = this.state;

        return {
            selectOptions: this.sortSelectOptions(),
            isDisabled: this.isSelectDisabled(),
            valueIndex,
            searchString,
            isSelectExpanded,
            onChange,
            id,
            name,
            label,
            formRef,
            placeholder,
            value,
            autocomplete,
            skipValue,
            isMobile,
            isProductPage,
            attributeSetId,
            colorAttribute
        };
    }

    isSelectDisabled() {
        const { selectOptions, isDisabled } = this.props;

        return isDisabled || selectOptions.length === 0;
    }

    handleUnselectListOptionClick() {
        const { formRef, onChange } = this.props;

        if (typeof formRef !== 'function') {
            formRef.current.value = null;

            // TODO: investigate why this is required
            const event = new Event('change', { bubbles: true });
            formRef.current.dispatchEvent(event);
        } else {
            onChange(value);
        }
    }
}

export default FieldSelectContainer;
