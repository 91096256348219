import { lazy } from 'react';
import { VIDEO_WIDGET  } from '../component/VideoWidget/VideoWidget.config';
import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';

export const VideoWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-video" */
    '../component/VideoWidget'
));

const renderContent = (args, callback, instance) => {

    const {
        type,
    } = instance.props;

    const {
        component: Widget,
        fallback
    } = instance.renderMap[type] || {};

    if (Widget !== undefined && type === VIDEO_WIDGET) { 
        return(
        <RenderWhenVisible fallback={ fallback }>
            <Widget
             { ...instance.props }
            />
        </RenderWhenVisible>
        )
    }

    return callback.apply(instance, args);
}

export const renderMap = (props, instance) => {
    return {
        ...props,
        [VIDEO_WIDGET]: {
            component: VideoWidget
        }
    }
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': renderMap
        },
        'member-function': { 
            renderContent
        }
    }
};