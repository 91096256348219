/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-bind */
import Html from 'SourceComponent/Html';
import { formatPrice } from 'SourceUtil/Price';

class MyAccountOrderTableRowComponentPlugin {
    // adds `return` link to each order row
    render = (_args, _callback, instance) => {
        const {
            base_order_info: {
                created_at,
                status,
                status_label,
                increment_id,
                grand_total,
                id,
                amrma_is_returnable
            },
            onViewClick,
            currency_code
        } = instance.props;
        
        return (
                <div block="MyAccountOrderTableRows">
                    <div block="MyAccountOrderTableRow order_no">
                        <label>{ __('Αριθμός παραγγελίας:') }</label>
                        <span>{ increment_id ? `#${increment_id}` : '' }</span>
                    </div>
                    <div className="MyAccountOrderTableRow_seperator"></div>
                    <div block="MyAccountOrderTableRow">
                        <label>{ __('Ημερομηνία παραγγελίας') }</label>
                        <span>{ instance.getFormattedDate(created_at) }</span>
                    </div>
                    <div block="MyAccountOrderTableRow">
                        <label className={status}>{ status_label }</label>
                    </div>
                    <div className="MyAccountOrderTableRow_seperator"></div>
                    <div block="hidden-mobile" className="MyAccountOrderTableRow total">
                        <label>{ __('Σύνολο παραγγελίας:') }</label>
                        <span>{ grand_total ? formatPrice(grand_total, currency_code) : '' }</span>
                    </div>
                    { status === 'complete' && amrma_is_returnable
                        ?
                        <div block="MyAccountOrderTableRow Actions">
                            { this.renderReturnLink(id) }
                            { instance.renderViewLink(onViewClick) }
                        </div>
                        : (
                            <div block="MyAccountOrderTableRow Actions">
                                { instance.renderViewLink(onViewClick) }
                            </div>
                        ) }
                </div>
        );
    };

    renderReturnLink(id) {
        return (
            <Html content={ `<a class="MyAccountOrderTableRow-Button Button"href=/my-account/my-returns/new-return&id=${id}>Return</a>` } />
        );
    }
}

const { render } = new MyAccountOrderTableRowComponentPlugin();

export default {
    'Component/MyAccountOrderTableRow/Component': {
        'member-function': {
            // render
        }
    }
};
