import { Field } from 'Util/Query';

export class CartQueryPlugin {
    _getCartTotalsFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'gift_card_code',
        'gift_card_discount',
    ];

    _getCartItemFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        this._getAmGiftcardOptions()
    ];

    _getAmGiftcardOptions() {
        return new Field('am_giftcard_options')
            .addFieldList(this._getGiftcardOptionsFields());
    }

    _getGiftcardOptionsFields() {
        return [
            'code',
            'label',
            'value'
    ]
    }
}
const { _getCartTotalsFields, _getCartItemFields } = new CartQueryPlugin();

export default {
    'Query/Cart': {
        'member-function': {
            '_getCartTotalsFields': _getCartTotalsFields,
            '_getCartItemFields': _getCartItemFields,
        }
    }
};
