import { setAuthorizationToken, getAuthorizationToken } from 'Util/Auth';
import { updateCustomerSignInStatus } from 'Store/MyAccount/MyAccount.action';
import { convertQueryStringToKeyValuePairs } from 'Util/Url';

const updateInitialCartData = (args, callback, instance) => {
    const { search, pathname } = location;
    const { token } = convertQueryStringToKeyValuePairs(search);
    let dispatch = args[0];
    
    if(token && !getAuthorizationToken() && pathname.indexOf('createPassword') == -1){
        setAuthorizationToken(token.replace(/\/$/, ""));
        dispatch(updateCustomerSignInStatus(true))
    }
    return callback.apply(instance, args);

};

export const config = {
    'Store/Cart/Dispatcher': {
        'member-function': {
            updateInitialCartData: [
                {
                    position: 100,
                    implementation: updateInitialCartData
                }
            ]
        }
    }
};

export default config;
