/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { ContactFormContainer as SourceContactFormContainer, mapStateToProps  as sourceStateToProps, mapDispatchToProps as sourceMapDispatchToProps } from 'SourceComponent/ContactForm/ContactForm.container.js';
import PropTypes from "prop-types";

/** @namespace Component/ContactForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceStateToProps(state),
    productSku: state.ProductReducer.product?.sku ? state.ProductReducer.product.sku : null
});

/** @namespace Component/ContactForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch)=> ({
    ...sourceMapDispatchToProps(dispatch),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousNavigationState: (state) => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE, state))
})

/** @namespace Component/ContactForm/Container */
export class ContactFormContainer extends SourceContactFormContainer {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        sendMessage: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired
    };

    hidePopUp () {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        hideActiveOverlay();
        goToPreviousNavigationState();
    };

    onFormSubmit(fields) {
        const { sendMessage, popupClose, productSku } = this.props;
        if(productSku)
            fields.sku = productSku;
        sendMessage(fields).then(()=>{
            Object.keys(fields).map(
                (key) => {
                    if(key !== 'message'){
                        document.querySelector(`input[name="${key}"]`).value = '';
                    }else {
                        document.querySelector(`textarea[name="${key}"]`).value = '';
                    }
                }
            );
            if(popupClose)
                this.hidePopUp();
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer);
