const mapStateToProps = (args, callback, instance) => {
    const { ConfigReducer, CheckoutReducer } = args[0];
    return {
        ...callback.apply(instance, args),
        box_now_cart_title_before_condition: ConfigReducer.box_now_cart_title_before_condition,
        box_now_cart_title_after_condition: ConfigReducer.box_now_cart_title_after_condition,
        boxnow_before_hours: ConfigReducer.boxnow_before_hours,
        boxnow_conditional_days: ConfigReducer.boxnow_conditional_days,
        is_box_now_module_active: ConfigReducer.is_box_now_module_active
    }
};

export const config = {
    'Route/CartPage/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
