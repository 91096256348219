/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';
import { PureComponent } from 'react';
import RewardPointsForm from './RewardPointsForm.component';
import { showNotification } from 'SourceStore/Notification/Notification.action';

export const RewardPointsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    "../../store/RewardPoints/RewardPoints.dispatcher"
    );

/** @namespace Component/RewardPointsForm/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({
    balance : state.RewardPointsReducer.rewards.balance
});

/** @namespace Component/RewardPointsForm/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    applyRewardToCart: (rewards) => RewardPointsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.applyRewardToCart(dispatch, rewards)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

class RewardPointsFormContainer extends PureComponent {

    static propTypes = {
    };

    state = {
        isLoading: false,
        isApplied : false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            isApplied : !!parseInt(nextProps.totals.amrewards_point)
        }
    }

    containerFunctions = {
        handleFormSuccess: this.handleFormSuccess.bind(this)
    }

    handleFormSuccess(field){
        const { applyRewardToCart, showNotification } = this.props;
        const { reward_field, reward_cancel } = field;
        let value = 0;
        if(reward_field){
            value = parseInt(reward_field) ? parseInt(reward_field) : 0;
            if(value){
                this.setState({ isLoading: true });
                applyRewardToCart(value).then(
                    /** @namespace Component/RewardPoints/Container/handleFormSuccess */
                    () => this.setState({ isLoading: false, rewardAmount : "" })
                );
                return;
            }else {
                showNotification('info', __('Reward amount should be a non zero number!'));
            }
            this.setState({  isLoading: false, rewardAmount : "" });
            return;
        }
        if(reward_cancel){
            this.setState({ isLoading: true });
            applyRewardToCart(value).then(
                /** @namespace Component/RewardPoints/Container/handleFormSuccess */
                () => this.setState({  isLoading: false, rewardAmount : "" })
            );
        }
    }

    render() {
        return (
            <RewardPointsForm
                { ...this.props }
                { ...this.state }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardPointsFormContainer);
