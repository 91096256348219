import {UPDATE_SHIPPING_FIELDS} from "@scandipwa/scandipwa/src/store/Checkout/Checkout.action";

export const UPDATE_BILLING_FIELDS = 'UPDATE_BILLING_FIELDS';

export const updateBillingFields = (billingFields) => ({
    type: UPDATE_BILLING_FIELDS,
    billingFields
});
export const checkoutReducer = (args,callback) => {
    const [ state, action ] = args;
    switch (action.type) {
        case UPDATE_BILLING_FIELDS:
            const { billingFields } = action;

            return {
                ...state,
                billingFields
            };

        default:
            return callback(...args);
    }
};

export const getInitialState = (args) => ({
    ...args,
    billingFields: {},
});

export default {
    'Store/Meta/Reducer/getInitialState': {
        function: getInitialState
    },
    'Store/Checkout/Reducer/checkoutReducer': {
        function: checkoutReducer
    },
};