import {PureComponent} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";


const CmsBlocksAndSliderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/CmsBlocksAndSlider/CmsBlocksAndSlider.dispatcher'
);


export const mapDispatchToProps = (dispatch) => ({
    requestCmsBlocks: (options) => CmsBlocksAndSliderDispatcher.then(({default: dispatcher}) => dispatcher.handleData(dispatch, options))
});

export const mapStateToProps = () => ({});

export class CmsBlockFetcherWrapper extends PureComponent {
    static propTypes = {
        identifiers: PropTypes.array.isRequired, requestCmsBlocks: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {identifiers, requestCmsBlocks} = this.props;
        requestCmsBlocks({identifiers: identifiers})
    }

    render() {
        return (
            this.props.children
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsBlockFetcherWrapper);
