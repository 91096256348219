import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { formatCurrency } from 'Util/Price';
import Field from 'Component/Field';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import './CartGiftCard.style.scss';

/** @namespace Component/CartGiftCardComponent/Component */
export class CartGiftCardComponent extends PureComponent {

    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        giftCardCode: PropTypes.string,
        handleApplyGiftCardToCart: PropTypes.func.isRequired,
        handleRemoveGiftCardFromCart: PropTypes.func.isRequired
    };

    renderErrorOnEmptyCode(){
        const { emptyField } = this.props;

        return null;

    }

    renderCodeStatus(){
        const { codeStatus, emptyField, giftCode, currency_code } = this.props;

        if (emptyField) {
            return ;
        }

        return (
            <div mix={{block: 'GiftCartCoupon', elem: 'InfoGrid'}}>
                <ul>
                    <li>
                        <span>{__('Code ')}</span>
                        <span>{codeStatus.code}</span>
                    </li>
                    <li>
                        <span>{__('Validity ')}</span>
                        <span>{codeStatus.expiration_date}</span>
                    </li>
                    <li>
                        <span>{__('Status ')}</span>
                        <span>{codeStatus.status}</span>
                    </li>
                    <li>
                        <span>{__('Balance ')}</span>
                        <span>{codeStatus.current_balance.value}{formatCurrency(currency_code)}</span>
                    </li>
                </ul>
            </div>
        )
    }

    renderApplyGiftCard() {
        const {giftCode, handleGiftCodeChange, handleGiftCodeStatus, isWidget} = this.props;
        return (
            <>
                <Field
                    type="text"
                    id="giftCardCode-2"
                    name="couponCode"
                    placeholder={__('Gift Card Code')}
                    value={giftCode}
                    onChange={handleGiftCodeChange}
                    mix={{block: 'GiftCartCoupon', elem: 'Input'}}
                />
            <div
                block="GiftCartCoupon"
                elem="Action"
            >
                {
                    isWidget ? null :
                        <button
                            block="GiftCartCoupon"
                            elem="Button"
                            type="submit"
                            className="Button"
                        >
                            {__('Apply')}
                        </button>
                }
                <button
                    block="GiftCartCoupon"
                    elem="Button"
                    type="button"
                    onClick={handleGiftCodeStatus}
                    className="Button"
                >
                    {__('Check Status')}
                </button>
            </div>
            </>
        );
    }

    renderRemoveGiftCard() {
        const {totals: {gift_card_code}, removeGiftCard} = this.props;

        if (!gift_card_code)
            return null;

        return (
            <div mix={{block: 'GiftCartCoupon', elem: 'AppliedCoupons'}}>
                {
                    gift_card_code.split(',').map(
                        (item) => {
                            return (
                                <div className="Item">
                                    <div><p>{item}</p></div>
                                    <div className="remove" onClick={() => removeGiftCard(item)}>remove</div>
                                </div>
                            )
                        }
                    )
                }
            </div>
        );
    }

    renderGiftCardSection() {
        const {isLoading, handleFormSuccess} = this.props;

        return (
            <div className="GiftCartCoupon">
                <Loader isLoading={ isLoading }/>
                {this.renderRemoveGiftCard()}
                <Form
                 block="GiftCartCoupon"
                 onSubmitSuccess={handleFormSuccess}
                >
                    {this.renderApplyGiftCard()}
                </Form>
            </div>
        );
    }

    render() {
        return (
            <>
                { this.renderErrorOnEmptyCode() }
                { this.renderGiftCardSection() }
                { this.renderCodeStatus() }
            </>
        );
    }
}
export default CartGiftCardComponent;
