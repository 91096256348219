import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MyAccountWishlistPopupNewCategroy } from './MyAccountWishlistPopupNewCategroy.component';
import { updateIsLoading } from 'SourceStore/Wishlist/Wishlist.action';
import { hideActiveOverlay } from 'SourceStore/Overlay/Overlay.action';
import { goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Wishlist/Wishlist.dispatcher'
);
import { hideActivePopup } from 'SourceStore/Overlay/Overlay.action';

/** @namespace Component/MyAccountWishlistPopupNewCategroy/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.WishlistReducer.isLoading
});

/** @namespace Component/MyAccountWishlistPopupNewCategroy/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addNewCategory: (newCategory) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addNewWishlistCategory(dispatch, newCategory),
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE)),
});

/** @namespace Component/MyAccountWishlistPopupNewCategroy/Container */
export class MyAccountWishlistPopupNewCategroyContainer extends PureComponent {

    state = {
        newCategory: null,
        isLoading: false
    }

    onNewCateogrySave(e) {
        e.preventDefault()
        this.setState({isLoading: true});
        const { addNewCategory, hideActiveOverlay, goToPreviousHeaderState, getWishlistCategories } = this.props;
        const { newCategory } = this.state;
         addNewCategory({ category_name: newCategory }).then(
             () =>  
                this.onCategoryField(null),
                // getWishlistCategories(),
                setTimeout(() => { 
                    hideActiveOverlay() 
                    goToPreviousHeaderState()
                }, 1000)
        );
    }

    onCategoryField(newCategory){
        this.setState({ newCategory })
    }

    containerFunctions = {
        onChangeField: this.onCategoryField.bind(this),
        onSaveCategory: this.onNewCateogrySave.bind(this)
    };

    containerProps = () => {
        const  { newCategory, isLoading } = this.state;
        
        return {
            newCategory,    
            isLoading
        }
    }

    render() {
        
        return (
            <MyAccountWishlistPopupNewCategroy
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountWishlistPopupNewCategroyContainer);