import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import CustomAttributeQuery from '@mageguide/enhacned-registration/src/query/CustomAttribute.query';
import { ProductAttributes } from '../utils/ProductAttributes';

const addProductAttributesToConfigQuery = (args, callback, instance) => {
    const original = callback(...args);
    const query = CustomAttributeQuery.getCustomerAttribute(ProductAttributes);

    return [
        ...(Array.isArray(original) ? original : [original]),
        query

    ];
};

const addProductAttributesToConfigReducerInitialState = (args, callback, instance) => {
    const { customAttributeMetadata } = BrowserDatabase.getItem('config') || { customAttributeMetadata: {
        items: {}
    },
    };
    return {
        ...callback(...args),
        customAttributeMetadata
    };
};

const addProductAttributesToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { customAttributeMetadata: { items } = {} } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        customAttributeMetadata: items
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            'prepareRequest': addProductAttributesToConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        'function': addProductAttributesToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        'function': addProductAttributesToConfigUpdate
    },
};
