import PropTypes from 'prop-types';
import { lazy, PureComponent, Suspense } from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import ProductActions from 'Component/ProductActions';
import { ProductType } from 'Type/ProductList';

import './ProductPopup.style';

export const ProductGallery = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "product-gallery" */
    'SourceComponent/ProductGallery'
));

/** @namespace Route/ProductPopup/Component */
export class ProductPopup extends PureComponent {
    static propTypes = {
        configurableVariantIndex: PropTypes.number.isRequired,
        productOrVariant: ProductType.isRequired,
        getLink: PropTypes.func.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        updateConfigurableVariant: PropTypes.func.isRequired,
        dataSource: ProductType.isRequired,
        areDetailsLoaded: PropTypes.bool.isRequired,
        getSelectedCustomizableOptions: PropTypes.func.isRequired,
        setLinkedDownloadables: PropTypes.func.isRequired,
        setLinkedDownloadablesPrice: PropTypes.func.isRequired,
        productOptionsData: PropTypes.object.isRequired,
        setBundlePrice: PropTypes.func.isRequired,
        selectedLinkPrice: PropTypes.number.isRequired,
        selectedBundlePrice: PropTypes.number.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isInformationTabEmpty: PropTypes.bool.isRequired,
        isAttributesTabEmpty: PropTypes.bool.isRequired,
        selectedBundlePriceExclTax: PropTypes.number.isRequired,
        selectedInitialBundlePrice: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired
    };


    renderProductPageContent() {
        const {
            configurableVariantIndex: index,
            parameters,
            getLink,
            dataSource,
            dataSource: { type_id },
            updateConfigurableVariant,
            productOrVariant,
            areDetailsLoaded,
            getSelectedCustomizableOptions,
            productOptionsData,
            setBundlePrice,
            selectedBundlePrice,
            selectedInitialBundlePrice,
            selectedBundlePriceExclTax,
            setLinkedDownloadables,
            setLinkedDownloadablesPrice,
            selectedLinkPrice,
            quantity,
            getQuantity,
            handleChangeProduct,
            isLoading,
            isCart,
            isDisable,
            isEditWishlistItem,
            isProductList,
            toggleProductInWishlist,
            isAddtoCart,
            brand
        } = this.props;

        const configurableVariantIndex = index === undefined ? -1 : index;

        return (
            <>
                {
                    type_id !== 'amgiftcard' ?
                        <Suspense fallback={<Loader />}>
                            <ProductGallery
                                product={productOrVariant}
                                areDetailsLoaded={areDetailsLoaded}
                                isProductPopup
                            />
                        </Suspense> : ''
                }
                <Loader isLoading={isLoading} />
                <ProductActions
                    isProductPopup
                    brand={brand}
                    isAddtoCart={isAddtoCart}
                    isProductList={isProductList}
                    toggleProductInWishlist={toggleProductInWishlist}
                    isEditWishlistItem={isEditWishlistItem}
                    isDisable={isDisable}
                    isCart={isCart}
                    handleChangeProduct={handleChangeProduct}
                    getQuantity={getQuantity}
                    quantity={quantity}
                    getLink={getLink}
                    updateConfigurableVariant={updateConfigurableVariant}
                    product={dataSource}
                    productOrVariant={productOrVariant}
                    parameters={parameters}
                    areDetailsLoaded={areDetailsLoaded}
                    configurableVariantIndex={configurableVariantIndex}
                    getSelectedCustomizableOptions={getSelectedCustomizableOptions}
                    productOptionsData={productOptionsData}
                    setBundlePrice={setBundlePrice}
                    selectedBundlePrice={selectedBundlePrice}
                    selectedInitialBundlePrice={selectedInitialBundlePrice}
                    selectedBundlePriceExclTax={selectedBundlePriceExclTax}
                    setLinkedDownloadables={setLinkedDownloadables}
                    setLinkedDownloadablesPrice={setLinkedDownloadablesPrice}
                    selectedLinkPrice={selectedLinkPrice}
                />
            </>
        );
    }

    render() {
        return (
            <main
                block="ProductPage"
                aria-label="Product page"
                itemScope
                // itemType="http://schema.org/Product"
                className={'ProductPopup'}
            >
                <ContentWrapper
                    wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                    label={__('Main product details')}
                >
                    {this.renderProductPageContent()}
                </ContentWrapper>
            </main>
        );
    }
}

export default ProductPopup;
