const containerProps = (_args, _callback, _instance) => {
    const { visiblePage } = _instance.props;

    return {
        ..._callback.apply(_instance, _args),
        visiblePage
    };
};

const _getCurrentPage = (_args, _callback, _instance) => {
    const { visiblePage } = _instance.props;
    if (visiblePage) {
        return Number(visiblePage) || 1;
    }

    return _callback.apply(_instance, _args);
}

export default {
    'Component/CategoryPagination/Container': {
        'member-function': {
            containerProps,
            _getCurrentPage
        }
    }
};