import { lazy } from 'react';
export const AdminCustomerGridList = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "admin-customer", webpackPreload: true */ '../component/AdminCustomerGridList'));

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 11;

const BEFORE_ITEMS_TYPE = (originalMember) => {
    const maxPosition = Math.max(
        originalMember.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...originalMember,
        {
            component: <AdminCustomerGridList />,
            position: maxPosition + INCREASED_POSITION,
            name: 'ADMIN-WIDGHT'
        }
    ];
};

export const config = {
    'Component/Router/Component': {
        'member-property': {
            BEFORE_ITEMS_TYPE
        }
    }
};

export default config;
