import {
    FETCH_CODES
} from './GiftCardDashBoard.action';
import BrowserDatabase from "Util/BrowserDatabase/BrowserDatabase";
import {LOAD_CODES} from "./GiftCardDashBoard.dispatcher";


/** @namespace Store/GiftCardDashBoard/Reducer/getInitialState */
export const getInitialState = () => ({
    giftCardsFromAccount:  BrowserDatabase.getItem(LOAD_CODES) || [],
});

/** @namespace Store/GiftCardDashBoard/Reducer/GiftCardDashBoardReducer */
export  const GiftCardDashBoardReducer = (state= getInitialState(), action) => {
    switch (action.type) {
        case FETCH_CODES:
            return {
                ...state,
                giftCardsFromAccount: action.codes
            };
        default:
            return state;
    }
}
export default GiftCardDashBoardReducer;
