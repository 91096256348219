import { appendWithStoreCode } from 'SourceUtil/Url';
import ContentWrapper from 'SourceComponent/ContentWrapper';
import Breadcrumb from '../component/Breadcrumb/Breadcrumb.component.plugin'
import Link from "SourceComponent/Link/Link.container";
import TextPlaceholder from "SourceComponent/TextPlaceholder";

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;
    return {
        ...callback(...args),
        isFashion: state.switchWebsiteReducer.isFashion
    }
};

const containerProps = (_args, _callback, _instance) => {
    const { isFashion } = _instance.props;
    console.log(isFashion);
    return {
        ..._callback(...args),
      isFashion
    };
  };

export const renderBreadcrumbHome =( instance ) => {
    const { isFashion } = instance.props;
    const url = !isFashion ? '/' : '/home_fashion';

    return (
        <li
            block="Breadcrumbs"
            elem="Crumb"
            itemProp="itemListElement"
            className="Breadcrumb breadcrumbs__item"
        >
            <Link
                block="Breadcrumb"
                elem="Link breadcrumbs__link"
                to={ url }
                tabIndex="-1"
            >
                <meta itemProp="item" content={ url } />
                <span itemProp="name">
                        <TextPlaceholder content="Home" />
                    </span>
                <meta itemProp="position" content="-1" />
            </Link>
        </li>
    );
}

export const renderBreadcrumb = (args, callback, instance) => {
    const [breadcrumbs, i ] = args
    const { url, name } = breadcrumbs;
    const isDisabled = !url || breadcrumbs.length - 1 === i;

    return (
        <Breadcrumb
          name={ name }
          url={ url }
          index={ i }
          key={ i }
          isDisabled={ isDisabled }
        />
    );
}

export const renderBreadcrumbList = (instance) => {
    return instance.props.breadcrumbs.map((_, i) => instance.renderBreadcrumb( breadcrumbs[breadcrumbs.length - 1 - i ], i));
}

export const render = (args, callback, instance) => {
    const { breadcrumbs, areBreadcrumbsVisible, urlRewrite } = instance.props;

    if (
        !areBreadcrumbsVisible
        || location.pathname === appendWithStoreCode('/')
        || location.pathname === appendWithStoreCode()
        || location.pathname === appendWithStoreCode('home_fashion')
        || location.pathname === '/'
        || !breadcrumbs
    ) {
        return null;
    }

    return (
        <div className="Breadcrumbs breadcrumbs catalogue__breadcrumbs hide-lg" >
            <ContentWrapper  label={ __('Breadcrumbs (current location)...') }>
                  <nav aria-label="Breadcrumbs navigation">
                      <ul
                          block="Breadcrumbs"
                          id={ urlRewrite.type == "PRODUCT" ? "Breadcrumbs-List" : "" }
                          style={ urlRewrite.type != "PRODUCT" ? { 'font-family': 'inherit' } : {}}
                      >
                          { renderBreadcrumbHome(instance) }
                          { (
                              breadcrumbs.length
                                  ? breadcrumbs.map((_, i) => instance.renderBreadcrumb(
                                      breadcrumbs[breadcrumbs.length - 1 - i], i
                                  ))
                                  : instance.renderBreadcrumb(breadcrumbs, 0)
                          ) }
                      </ul>
                  </nav>
            </ContentWrapper>
        </div>

    );
}

export default {
    'Component/Breadcrumbs/Container': {
        'member-function': {
            containerProps
        }
    },
    'Component/Breadcrumbs/Container/mapStateToProps': {
        function: {
            position: 101,
            implementation: mapStateToProps
        }
     },
    'Component/Breadcrumbs/Component': {
        'member-function': {
            'renderBreadcrumbList': renderBreadcrumbList,
            'renderBreadcrumb': renderBreadcrumb,
            'render': render
        }
    }
};
