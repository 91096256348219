import EditIcon from 'SourceComponent/EditIcon';
import media, { WYSIWYG_MEDIA } from "SourceUtil/Media/Media";
import CopyIcon from '../component/CopyIcon';
import MoveIcon from '../component/MoveIcon';
import { WISHLIST_POPUP, ALART_POPUP_REMOVE } from '../utils/wishlist.config'
import { showPopup } from 'SourceStore/Popup/Popup.action';
import { isSignedIn } from 'SourceUtil/Auth';
import {lazy} from "react";
import { CONFIGURABLE } from 'SourceUtil/Product';
import { appendWithStoreCode } from 'SourceUtil/Url';
import { SAMPLE_CATEGORY, BAG_CATEGORY, CARDS_CATEGORY } from 'Route/ProductPage/ProductPage.config';
import { IN_STOCK } from 'SourceComponent/ProductCard/ProductCard.config';

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Wishlist/Wishlist.dispatcher'
);
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

class WishlistItemPlugin {

    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            removeItemFromCategory: (options) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.removeItemFromCategory(dispatch, options),
            ),
            getRemoveItemFromCategory: (options) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.getRemoveItemFromCategory(dispatch, options),
            ),
            showPopup: (type, payload) => dispatch(showPopup(type, payload)),
        }
    }

    containerFunctions(args, callback, instance){
        return {
            ...args,
            copyProductToCategory: copyProductToCategory.bind(args, callback, instance),
            removeItemFromCategory: removeItemFromCategory.bind(args, callback, instance),
            showAlartBox: showAlartBox.bind(args, callback, instance)
        }
    };

    containerProps(args, callback, instance) {
        const { 
            productsInWishlist, 
            activeCategory, 
            isSharedWishlist = false 
        } = instance.props;

        return {
            ...callback.apply(instance, args),
            isSharedWishlist,
            productsInWishlist,
            activeCategory
        };
    }

    getCategoryId(activeCategory, instance) {
        const { wishlistCategories, product: { id, wishlist }, isInWishlist } = instance.props;
        var categoryId = 0;
        
        if (wishlistCategories.lenght === 0) {
            return categoryId;
        }
        
        if(wishlist?.categories.includes(activeCategory)) {
            categoryId = activeCategory;
        }
      
        return categoryId;
    }

    copyProductToCategory(instance, callback, isMoveCategory) {
        const { showPopup, product: { id }, product, activeCategory, removeFromWishlist } = instance.props;
        const title = isMoveCategory === true ? __('Move Product to other Wishlist Category') : __('Copy Product to other Wishlist Category');

        showPopup(
             WISHLIST_POPUP,
            { 
                product,  
                id,
                activeCategory,
                title,
                isMoveCategory,
                removeFromWishlist
            }
        );
    }

    showAlartBox(instance, callback, args) {
        const { showPopup, product: { id } } = instance.props;
        
        showPopup(
            ALART_POPUP_REMOVE,
            { 
                Action: () => instance.removeItem(),
                title: __('Are You Sure') 
            }
        );
    }

    removeItemFromCategory(args, callback, instance) {

        if(!isSignedIn()){
            return callback.apply(instance, args);
        }

        const { getRemoveItemFromCategory, activeCategory, product: { id } } = instance.props
        const isFoundInCategory = getCategoryId(activeCategory, instance);
        
        if(activeCategory && isFoundInCategory !== 0) {
            getRemoveItemFromCategory({ product_id: id, category_id: isFoundInCategory })
        }

        return [
            instance.setState({ isLoading: false })
        ]
    }

    removeItem(args, callback, instance) {

        if(!isSignedIn()){
            return callback.apply(instance, args);
        }

        const { removeItemFromCategory, activeCategory, product: { id } } = instance.props
        const isFoundInCategory = getCategoryId(activeCategory, instance);
        
        if(activeCategory && isFoundInCategory !== 0) {
            removeItemFromCategory({ product_id: id, category_id: isFoundInCategory })
        }

        return [
            callback.apply(instance, args),
            instance.setState({ isLoading: false })
        ]
    }

    redirectToProductPage(args, callback, instance){

        if(!isSignedIn()){
            return callback.apply(instance, args);
        }

        const { productEditPopup, product } = instance.props;
        productEditPopup(product)
    }

    renderRemove(args, callback, instance) {

        if(!isSignedIn()){
            return callback.apply(instance, args);
        }

        const { showAlartBox } = instance.props;

        return (
            <button
              block="WishlistItem-Remove"
              elem="Remove"
              onClick={ showAlartBox }
              aria-label={ __('Remove') }
            >
                <UniversalIcon src={media('wishlist_small_fill.svg', WYSIWYG_MEDIA)} alt="HeartIcon" className="CloseIcon"/>
            </button>
        );
    }

    renderAddToCartButton(args, callback, instance) {
        const {
            addToCart,
            isEditingActive,
            isMobile,
            product: {
                wishlist: {
                    sku: AddedSku
                } = {},
                variants = []
            } = {},
            product
        } = instance.props;
 
        const productOrVarient = variants.length > 0 ? variants.find( ({ sku }) => sku == AddedSku) : product;

        if(productOrVarient?.stock_status !== IN_STOCK) {
            return (
                <span
                  block="Button"
                  mods={ { isHollow: isMobile } }
                  mix={ { block: 'WishlistItem', elem: 'AddToCart' } }
                >
                    { __('Out of stock') }
                </span>
            );
        }

        const mods = isMobile ? { isEditingActive } : {};

        return (
            <button
              rel='nofollow'
              block="Button"
              mods={ { isHollow: isMobile } }
              mix={ { block: 'WishlistItem', elem: 'AddToCart', mods } }
              onClick={ addToCart }
            >
                { __('Add to cart') }
            </button>
        );
    }

    renderContent(args, callback, instance) {
        
        if(!isSignedIn()){
            return callback.apply(instance, args);
        }

        const { redirectToProductPage, copyProductToCategory, activeCategory, product: { type_id } } = instance.props;
        const renderMethods = args[0]
        const {
            content: { productPrice },
            pictureBlock: { picture: renderPicture },
            renderCardLinkWrapper
        } = renderMethods;
        const isDefault = activeCategory === 0;
        return (
            <>
                <div block="WishlistItem" elem="Main">
                    <div block="WishlistItem" elem="Left">
                        <div block="WishlistItem" elem="FigureWrapper">
                            { renderCardLinkWrapper(
                                <>
                                    <figure mix={ { block: 'ProductCard', elem: 'Figure' } }>
                                        { renderPicture({ block: 'WishlistItem', elem: 'Picture' }) }
                                    </figure>
                                </>
                            ) }
                        </div>
                        <div block="WishlistItem" elem="LeftContent">
                            { instance.renderBrand() }
                            { instance.renderName() }
                            { instance.renderSku() }
                            { instance.renderOptions() }
                        </div>
                    </div>
                    <div block="WishlistItem" elem="Right">
                        <div block="WishlistItem" elem="ActionWrapperTop">
                           { type_id === 'simple' ? null : <div
                                block="WishlistItem"
                                elem="EditIcon"
                                onClick={ redirectToProductPage }
                            >
                                <EditIcon />
                            </div>
                            }
                            { renderRemove(args, callback, instance) }
                        </div>
                        <div block="WishlistItem" elem="Content">
                            <div block="WishlistItem" elem="RowWrapper">
                                { instance.renderPrice(productPrice) }
                                { instance.renderQuantityFieldInput() }
                            </div>
                            { instance.renderCommentField() }
                            <div block="WishlistItem" elem="ActionWrapper">
                                { instance.renderAddToCartButton() }
                            </div>
                        </div>
                    </div>
                </div>
                <div block="WishlistItem" elem="ActionWrapperBottom">
                    {/*<div*/}
                    {/*    block="WishlistItem"*/}
                    {/*    elem="EditIcon"*/}
                    {/*    onClick={ redirectToProductPage }*/}
                    {/*>*/}
                    {/*    <EditIcon />*/}
                    {/*</div>*/}
                    { isDefault ?
                        <div
                            block="WishlistItem"
                            elem="CopyIcon"
                            onClick={ copyProductToCategory }
                        >
                            <CopyIcon />
                            { __('Αντιγραφή σε λίστα') }
                        </div>
                        :
                        <>
                            <div
                                block="WishlistItem"
                                elem="CopyIcon"
                                onClick={ copyProductToCategory }
                            >
                                <CopyIcon />
                                { __('Αντιγραφή σε λίστα') }
                            </div>
                            <div
                                block="WishlistItem"
                                elem="MoveIcon"
                                onClick={ () => copyProductToCategory(true) }
                            >
                                <MoveIcon />
                                { __('Μεταφορά σε λίστα') }
                            </div>
                        </>
                    }
                </div>
                {/*<div block="WishlistItem" elem="FigureWrapper">*/}
                {/*    { renderCardLinkWrapper(*/}
                {/*        <>*/}
                {/*            <figure mix={ { block: 'ProductCard', elem: 'Figure' } }>*/}
                {/*                { renderPicture({ block: 'WishlistItem', elem: 'Picture' }) }*/}
                {/*            </figure>*/}
                {/*            { instance.renderRating() }*/}
                {/*            { instance.renderBrand() }*/}
                {/*            { instance.renderName() }*/}
                {/*        </>*/}
                {/*    ) }*/}
                {/*    { renderRemove(instance) }*/}
                {/*</div>*/}
                {/*{ instance.renderOptions() }*/}
                {/*<div block="WishlistItem" elem="Content">*/}
                {/*    <div block="WishlistItem" elem="RowWrapper">*/}
                {/*        { instance.renderPrice(productPrice) }*/}
                {/*        { instance.renderQuantityFieldInput() }*/}
                {/*    </div>*/}
                {/*    { instance.renderCommentField() }*/}
                {/*    <div block="WishlistItem" elem="ActionWrapper">*/}
                {/*        { instance.renderAddToCartButton() }*/}
                {/*        <div*/}
                {/*          block="WishlistItem"*/}
                {/*          elem="EditIcon"*/}
                {/*          onClick={ redirectToProductPage }*/}
                {/*        >*/}
                {/*            <EditIcon />*/}
                {/*        </div>*/}
                {/*        { !isDefault ? */}
                {/*        <div*/}
                {/*          block="WishlistItem"*/}
                {/*          elem="MoveIcon"*/}
                {/*          onClick={ () => copyProductToCategory(true) }*/}
                {/*        >*/}
                {/*            <MoveIcon />*/}
                {/*        </div>*/}
                {/*         : */}
                {/*        <div*/}
                {/*          block="WishlistItem"*/}
                {/*          elem="CopyIcon"*/}
                {/*          onClick={ copyProductToCategory }*/}
                {/*        >*/}
                {/*            <CopyIcon />*/}
                {/*        </div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    };

    render(args, callback, instance) {
        return callback.apply(instance, args)
    }

    addItemToCart(args, callback, instance) {
        const { product: item, addProductToCart, showNotification } = instance.props;
        const {
            categories,
            type_id,
            variants,
            wishlist: {
                id, sku, quantity, buy_request
            }
        } = item;

        const isDisable = categories.filter(({ id })=> id == SAMPLE_CATEGORY || id == CARDS_CATEGORY)?.length !== 0;

        if(isDisable) {
            return showNotification('info', __("You can't add this product to cart"));
        }

        if (type_id === CONFIGURABLE) {
            const configurableVariantIndex = instance.getConfigurableVariantIndex(sku, variants);

            if (!configurableVariantIndex) {
                history.push({ pathname: appendWithStoreCode(item.url) });
                showNotification('info', __('Please, select product options!'));

                return Promise.resolve();
            }

            item.configurableVariantIndex = configurableVariantIndex;
        }

        instance.setState({ isLoading: true });

        return addProductToCart({ product: item, quantity, buyRequest: buy_request })
            .then(
                /** @namespace Component/WishlistItem/Container/addItemToCartAddProductToCartThen */
                () => {
                    instance.removeItem(id);
                    showNotification('success', __('Product Added To Cart'));
                },
                /** @namespace Component/WishlistItem/Container/addItemToCartAddProductToCartCatch */
                () => instance.showNotification('error', __('Error Adding Product To Cart'))
            )
            .catch(
                /** @namespace Component/WishlistItem/Container/addItemToCartAddProductToCartThenThenCatch */
                () => instance.showNotification('error', __('Error cleaning wishlist'))
            );
    }


}

const { 
    removeItem, 
    render, 
    getCategoryId, 
    mapDispatchToProps, 
    copyProductToCategory, 
    containerFunctions,
    containerProps,
    showAlartBox,
    renderRemove,
    redirectToProductPage,
    renderContent,
    addItemToCart,
    removeItemFromCategory,
    renderAddToCartButton
} = new WishlistItemPlugin();

export default {
    'Component/WishlistItem/Container/mapDispatchToProps': {
        function: {
            position: 101,
            implementation: mapDispatchToProps
        }
    },
    'Component/WishlistItem/Container': {
        'member-function': { 
            removeItem,
            getCategoryId,
            copyProductToCategory,
            showAlartBox,
            containerFunctions,
            containerProps,
            redirectToProductPage,
            addItemToCart,
            removeItemFromCategory
        }
    },
    'Component/WishlistItem/Component': {
        'member-function': {
            renderAddToCartButton,
            renderContent,
            renderRemove,
            render
        }
    }
};