import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FREE_GIFTS } from '../route/FreeGiftCategoryPage/FreeGiftCategoryPage.config'
export const withStoreRegex = (path) => window.storeRegexText.concat(path);

export const FreeGiftCategoryPage = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "free-gift" */ 
    '../route/FreeGiftCategoryPage')
    );

export class RouterPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex(`/${ FREE_GIFTS }`) } exact component={ FreeGiftCategoryPage } />,
            position: 101
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
