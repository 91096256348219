import MyAccountQuery from 'SourceQuery/MyAccount.query';
import {
    updateCustomerDetails
} from 'SourceStore/MyAccount/MyAccount.action';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import { prepareQuery } from 'SourceUtil/Query';
import { executePost, getErrorMessage } from 'SourceUtil/Request';
import { isSignedIn } from 'SourceUtil/Auth';
import { deleteGuestQuoteId } from 'SourceUtil/Cart';
import getStore from 'SourceUtil/Store';
import {
    PAYMENT_TOTALS
} from 'SourceRoute/Checkout/Checkout.config';
export const CUSTOMER = 'customer';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);


export const ONE_MONTH_IN_SECONDS = 2628000;
const extendCustomerData = (args, callback, instance) => {
    const [ dispatch ] = args;
    const query = MyAccountQuery.getCustomerQuery();

    const customer = BrowserDatabase.getItem(CUSTOMER) || {};
    if (customer.id) {
        dispatch(updateCustomerDetails(customer));
    }

    return executePost(prepareQuery([query])).then(
        /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostThen */
        ({ customer }) => {
            dispatch(updateCustomerDetails(customer));
            BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
        },
        /** @namespace Store/MyAccount/Dispatcher/requestCustomerDataExecutePostError */
        (error) => {
            dispatch(showNotification('error', getErrorMessage(error)))
            if(error[0] && error[0]?.message === "The current customer isn't authorized." || 
              error[0] && error[0]?.message.indexOf('είναι εξουσιοδοτημένος') !== -1){
                instance.logout(false, dispatch);
            }
        }
    );
}

const extendGetErrorMessage = (args, callback, instance) => { 
    const [ error ] = args;
    if(error && error[0]?.debugMessage) {
        const {
            debugMessage
        } = error[0];

        if(debugMessage.includes('Καμία τέτοια οντότητα με cartId = ') || 
          debugMessage.includes("The current customer isn't authorized.") || 
          debugMessage.includes('είναι εξουσιοδοτημένος') || 
          debugMessage.includes('No such entity with')) {
            deleteGuestQuoteId();
            BrowserDatabase.deleteItem(PAYMENT_TOTALS);
            const store = getStore();
            const { dispatch } = store;

            if (isSignedIn()) {
                CartDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
                );
            } else {
                CartDispatcher.then(
                    ({ default: dispatcher }) => {
                        dispatcher.resetGuestCart(dispatch);
                        dispatcher.createGuestEmptyCart(dispatch);
                    }
                );
            }
        }
    }

    return callback(...args)
}

// const extendCustomerData = (args, callback, instance) => {
//     const [error] = args[0]
//     if (error && error.message === "The current customer isn't authorized.") {
        
//         // instance.logout(false, dispatch);
//     }
//     return callback.apply(instance, args);
// }

export default {
    'Util/Request/getErrorMessage': {
        'function': extendGetErrorMessage
    },
    'Store/MyAccount/Dispatcher': {
    'member-function': {
        requestCustomerData: extendCustomerData
    }
        // function: [
        //     {
        //         position: 101,
        //         implementation: extendCustomerData
        //     }
        // ]
    }
};