/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import defaultValidationConfig from 'Component/Form/Form.config';
import moreValidationConfig from '../component/Field/Field.config';

export const CUSTOM_VALIDATED_FIELDS_NO_NUMBER = [
    'city',
    'firstname',
    'name',
    'lastname',
];

export const CUSTOM_VALIDATED_FIELDS_FUTURE_DATE = [
    'date_of_birth'
];

export class FieldContainerPlugin {

    validateField = (args, callback, instance) =>  {
        let {
            validation : oldValidation,
            id,
            name,
            formRef: refMap,
            formRefMap
        } = instance.props;
        let isNoNumberValidate = CUSTOM_VALIDATED_FIELDS_NO_NUMBER.includes(name);
        let isFutureDateValidate = CUSTOM_VALIDATED_FIELDS_FUTURE_DATE.includes(name);
        if(isNoNumberValidate || isFutureDateValidate){
            if (!oldValidation || !id || !refMap || !refMap.current) {
                return {};
            }

            const { current: inputNode } = refMap || {};

            if (!inputNode) {
                return {};
            }
            let validation = [];
            if(isNoNumberValidate){
                validation = [...oldValidation, 'noNumber'];
            }
            if(isFutureDateValidate){
                validation = [...oldValidation, 'noFutureDate'];
            }
            const validationConfig = {
                ...defaultValidationConfig,
                ...moreValidationConfig
            };

            // we are looking for validation and executing it
            const rule = validation.find((rule) => {
                if (!validationConfig[rule]) {
                    return false;
                }

                const validationRules = validationConfig[rule];
                const isValid = validationRules.validate(inputNode, formRefMap);

                return !isValid;
            });

            return validationConfig[rule] || {};
        }
        return callback.apply(instance,args);
    }
}

const {  validateField } = new FieldContainerPlugin();

export const config = {
    'Component/Field/Container': {
        'member-function': {
            validateField : validateField
        }
    }
};

export default config;
