import getCustomData from '../store/EnhacnedRegistration/EnhacnedRegistration.reducer';

export class StoreIndexPlugin {
    aroundGetStaticReducers = (args, callback) => ({
        ...callback(...args),
        getCustomData
    });
}

const { aroundGetStaticReducers } = new StoreIndexPlugin();

export const config = {
    'Store/Index/getReducers': {
        function: aroundGetStaticReducers
    }
};

export default config;
