import RequestAccountDeleteButton from "../component/RequestAccountDeleteButton";
const renderAdditional = (args, callback, instance) => {
    return (
        <>
            {callback.apply(instance,args)}
            <RequestAccountDeleteButton/>
        </>
    );
}
export default {
    'Component/MyAccountDashboard/Component': {
        'member-function': {
            renderAdditional
        }
    }
};

