export const validateUSAPhone = ( value ) => !isNaN(value) && value.trim().length > 9;

export const validateGreecePhone = ( value ) => {
    return !isNaN(value) && value.trim().length === 13;
}

export const validateCyprusPhone = ({ value }) => {
    const allowedValues = ['22', '23', '24', '25', '26', '96', '97', '98', '99'];
    const firstTwoDigits = value.slice(0, 2);
    return !isNaN(value) && value.trim().length === 12 && allowedValues.includes(firstTwoDigits);
}

const CountryCodeMapRule = {
    '1': {
        validate: validateUSAPhone,
        message: __('Invalid phone number format!'),
        maxlength : 10
    },
    '30': {
        validate: validateGreecePhone,
        message: __('Invalid phone number format!'),
        maxlength : 13
    },
    '357': {
        validate: validateCyprusPhone,
        message: __('Invalid phone number format!'),
        maxlength : 13
    },
}

const collectFieldsInformation = (args, callback, instance) => {
    let { inputValues, invalidFields } = callback.apply(instance,args);
    if(inputValues.telephone){
        let isValid = true;
        const countryCode  = inputValues.telephone.slice(1,3);
        const rule = CountryCodeMapRule[countryCode];
        if(rule){
            isValid = rule.validate(inputValues.telephone)
            if(!isValid){
                let inputs = document.querySelectorAll("input[name='telephone']");
                let current = null;
                for (let i = 0; i < inputs.length; i++) {
                    if(inputs[i].getAttribute("message")){
                        current = inputs[i];
                    }
                }
                if(!invalidFields.includes('telephone')){
                    invalidFields.push('telephone')
                }
                if(invalidFields.length === 1 && invalidFields[0] === 'telephone' && current){
                    current.hidden = false;
                    current.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
                    current.hidden = true;
                }
            }
        }
    }
    return { inputValues, invalidFields };
}
export const config = {
    'Component/Form/Component': {
        'member-function': {
            collectFieldsInformation
        }
    }
};

export default config;
