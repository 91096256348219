 import PropTypes from 'prop-types';
 import { PureComponent } from 'react';
 
 import Link from 'SourceComponent/Link';
 import TextPlaceholder from 'SourceComponent/TextPlaceholder';
 
//  import './Breadcrumb.style';
 
 /** @namespace Component/Breadcrumb/Component */
 export class Breadcrumb extends PureComponent {
     static propTypes = {
         index: PropTypes.number.isRequired,
         isDisabled: PropTypes.bool.isRequired,
         url: PropTypes.oneOfType([
             PropTypes.string,
             PropTypes.shape({})
         ]), 
         name: PropTypes.string
     };
 
     static defaultProps = {
         url: '',
         name: ''
     };
 
     renderLink() {
         const {
             url,
             index,
             isDisabled
         } = this.props;
 
         return (
             <Link
               block="Breadcrumb"
               elem="Link"
               to={ url || '' }
             >
                 <span>
                     { this.renderName() }
                 </span>
             </Link>
         );
     }
 
     renderName() {
         const { name } = this.props;
 
         return (
             <TextPlaceholder content={ name } />
         );
     }
 
     render() {
         const { index } = this.props;
 
         return (
             <li
               block="Breadcrumb"
             >
                 { this.renderLink() }
             </li>
         );
     }
 }
 
 export default Breadcrumb;
 