import { cloneElement } from 'react';
const renderPagination = (args, callback, instance) => {
    const {
        isPaginationEnabled
    } = instance.props;

    if (!isPaginationEnabled) {
        return null;
    }
    const originalComponent = callback.apply(instance, args);
    return cloneElement(
        originalComponent,
        {isCategory : true}
    );

}
export default {
    'Component/ProductList/Component': {
        'member-function': {
            renderPagination
        }
    }
};