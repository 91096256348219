/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ProductReviewRating from 'Component/ProductReviewRating';

import './ProductReviewItem.style';

/**
 * @class ProductReviewItem
 * @namespace Component/ProductReviewItem/Component
 */
export class ProductReviewItem extends PureComponent {
    static propTypes = {
        reviewItem: PropTypes.object.isRequired
    };

    getFormattedDate(created_at) {
        // Safari bug
        const fixedDate = created_at.replace(/-/g, '/');
        const date = new Date(fixedDate);

        return date ? date.toDateString() : created_at;
    }

    renderReviewListItemRating = (ratingVoteItem, i) => {
        const {
            rating_code,
            percent
        } = ratingVoteItem;

        return (
            <div
              key={ i }
              block="ProductReviewItem"
              elem="RatingSummaryItem"
            >
                <p block="ProductReviewItem" elem="RatingItem">{ rating_code }</p>
                <meta content={ percent } />
                <meta content={ 0 } />
                <meta content={ 100 } />
                <ProductReviewRating
                  summary={ percent }
                  code={ rating_code }
                />
            </div>
        );
    };

    timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    renderAuthor(reviewItem) {
        const { nickname, created_at } = reviewItem;

        let createDate = this.getFormattedDate(created_at);

        let date1 = new Date(created_at);
        let date2 = new Date();

        let Difference_In_Time = date2.getTime() - date1.getTime();

        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        let Difference_In_Months = Difference_In_Days / 30;

        if(Difference_In_Days > 30) {
            createDate = __('%s μήνα πριν', Math.round(Difference_In_Months));
        }

        return (
            <p block="ProductReviewItem" elem="ReviewAuthor">
                <strong >{ nickname }</strong>
                <meta  content={ this.getFormattedDate(created_at) } />
                { createDate }
            </p>
        );
    }

    render() {
        const {
            reviewItem,
            reviewItem: {
                title,
                detail,
                average_rating
            }
        } = this.props;

        return (
            <li
              block="ProductReviewItem"
            //   itemType="http://schema.org/Review"
            //   itemProp="review"
            //   itemScope
            >
                <div block="ProductReviewItem" elem="RatingSummary">
                    <ProductReviewRating summary={ average_rating || 0 } />
                    { this.renderAuthor(reviewItem) }
                </div>
                <div block="ProductReviewItem" elem="Content">
                    <p block="ProductReviewItem" elem="ReviewDetails" 
                    // itemProp="reviewBody"
                    >
                        { detail }
                    </p>
                </div>
            </li>
        );
    }
}

export default ProductReviewItem;
