import { PureComponent } from 'react';
import CmsBlock from 'SourceComponent/CmsBlock';

import './CmsTabs.style';

/** @namespace Component/CmsTabs/Component */
export class CmsTabs extends PureComponent {
    state= {
        activeTab : 0
    }

    setActiveTab(value){
        this.setState({ activeTab : value});
    }

    renderCmsTabButton(){
        const {
            tabsTitle
        } = this.props;

        return (
            <div className="cms_scandi_tabs_buttons">
                {
                    tabsTitle.map((item, index) => {
                        return (
                            <div className={`cms_scandi_tabs_button ${index === this.state.activeTab ? 'active' : ''}`} onClick={ () => this.setActiveTab(index) }>
                                {item}
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderCmsTabContent() {
        const {
            tabsContent
        } = this.props;

        return (
            <div className="cms_scandi_tabs_contents">
                {
                    tabsContent.map((item, index) => {
                        return (
                            <div className={`cms_scandi_tabs_content ${index === this.state.activeTab ? 'active' : ''}`}>
                                <CmsBlock isVisible={true} noWrapper={true} identifier={item} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        return (
            <div className="cms_scandi_tabs">
                { this.renderCmsTabButton() }
                { this.renderCmsTabContent() }
            </div>
        );
    }
}

export default CmsTabs;
