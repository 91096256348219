import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { ProductWishlistButtonContainer as SourceProductWishlistButtonContainer } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/** @namespace Component/ProductWishlistButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    productsInWishlist: state.WishlistReducer.productsInWishlist,
    isAddingWishlistItem: state.WishlistReducer.isLoading
});

/** @namespace Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProductToWishlist: (wishlistItem) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addItemToWishlist(dispatch, wishlistItem)
    ),
    removeProductFromWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    containerProps() {
        const { quantity, product, mix, isCart, isProductPopup } = this.props;

        return {
            quantity,
            mix,
            product,
            isCart,
            isProductPopup,
            isDisabled: this.isDisabled(),
            isInWishlist: this.isInWishlist(),
            isReady: this._getIsProductReady(),
            isSignedIn: isSignedIn()
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
