import { PureComponent } from 'react';

import './MoveIcon.style';

/** @namespace Component/MoveIcon/Component */
export class MoveIcon extends PureComponent {
    render() {
        return (
            <svg
                block="MoveIcon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M21.2913 4.60999C20.7805 4.099 20.1741 3.69364 19.5066 3.41708C18.8392 3.14052 18.1238 2.99817 17.4013 2.99817C16.6788 2.99817 15.9634 3.14052 15.2959 3.41708C14.6285 3.69364 14.022 4.099 13.5113 4.60999L12.4513 5.66999L11.3913 4.60999C10.3596 3.5783 8.96032 2.9987 7.50129 2.9987C6.04226 2.9987 4.64298 3.5783 3.61129 4.60999C2.5796 5.64169 2 7.04096 2 8.49999C2 9.95903 2.5796 11.3583 3.61129 12.39L4.67129 13.45L12.4513 21.23L20.2313 13.45L21.2913 12.39C21.8023 11.8792 22.2076 11.2728 22.4842 10.6053C22.7608 9.93789 22.9031 9.22248 22.9031 8.49999C22.9031 7.77751 22.7608 7.0621 22.4842 6.39464C22.2076 5.72718 21.8023 5.12075 21.2913 4.60999V4.60999Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                <rect x="9" y="9" width="14" height="14" rx="7" fill="black" />
                <path d="M16.4903 18.32H15.5203V16.57H13.7603V15.54H15.5203V13.79H16.4903V15.54H18.2403V16.57H16.4903V18.32Z" fill="white" />
            </svg>
        );
    }
}

export default MoveIcon;
