/**
 * MageGuide - Progressive Web App for Magento
 *
 * Copyright © MageGuide, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 */

 import { Field } from 'SourceUtil/Query';
 import ProductListQuery from 'Query/ProductList.query.js';
 import { isSignedIn } from 'SourceUtil/Auth';
 
 /** @namespace Query/SampleProducts */
 export class SampleProductsQuery {

    getRemoveSampleItemsMutation(item_ids, quoteId) {
        const mutation = new Field('removeSampleItems')
            .addArgument('item_ids', 'String!', item_ids)
            .addField('success');

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', quoteId);
        }

        return mutation;
    }

    getSampleProductQuery({ activePage, in: productArray }) {

        if (!productArray) {
            throw new Error('Missing argument `options`');
        }

        return new Field('getSampleProductCollection')
            .addArgument('ids', 'FilterTypeInput!', { in: productArray})
            .addArgument('activePage', 'Int', activePage)
            .addArgument('position', 'String', 'sample_product_page')
            .addField('total_count')
            .addField('total_pages')
            .addField('current_page')
            .addField('page_size')
            .addField(this._getProducts())
    }

    getProductData(){
        ProductListQuery.options.isForLinkedProducts = true;
        // ProductListQuery.options.noAttributes = true;
        ProductListQuery.options.noVariantAttributes = true;
        ProductListQuery.options.isPlp = true;
        ProductListQuery.options.isCart = true;
        ProductListQuery.options.isSlider = true;
        ProductListQuery.options.isSample = true;
    
        const productQuery = ProductListQuery._getProductInterfaceFieldsOptz(false, true);

        ProductListQuery.options.isSample = false;
        ProductListQuery.options.isSlider = false;
        ProductListQuery.options.isCart = false;
        ProductListQuery.options.isPlp = false;
        ProductListQuery.options.isForLinkedProducts = false;
        // ProductListQuery.options.noAttributes = false;
        ProductListQuery.options.noVariantAttributes = false;    
        return [
            ...productQuery,
            // ProductListQuery._getCategoriesField()
        ];
    }
    
    _getProducts() {
        return new Field('items')
             .addFieldList(this.getProductData());
    }
 }
 
 export default new SampleProductsQuery();