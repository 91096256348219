
import { PureComponent } from 'react';
import { formatPrice } from 'SourceUtil/Price';
import './FreeShippingPromotions.style';

export class FreeShippingPromotionsComponent extends  PureComponent{
    render() {
        const { freeShipping: {
                free_shpping_value,
                is_free_period
            },
            totals: {
                subtotal_incl_tax,
                quote_currency_code,
                discount_amount,
                estimated_shipping_amount
            },
            totals
         } = this.props;
         
         const grandTotal = discount_amount + subtotal_incl_tax;

         const freeShipMsg = __("Congratulations! Your shipping will be free");

         if(is_free_period) {
            return (
                <div
                    block="FreeShippingPromotions"
                    elem="Promo">
                    { freeShipMsg }
                </div>
            );
         }

         const diffLeft = ( grandTotal > free_shpping_value ||
            grandTotal == 0 || estimated_shipping_amount == 0) ? 0 : (free_shpping_value - grandTotal);

         const message = diffLeft > 0 ?
         __(`There are %s left for free shipping`, formatPrice(diffLeft, quote_currency_code)):
        freeShipMsg;

         const defaultMessage =  __(`Free shipping on order of %s or above.`, formatPrice( free_shpping_value, quote_currency_code ));
            
        return (
            <div
                block="FreeShippingPromotions"
                elem="Promo">
                {grandTotal > 0 ?
                    message : defaultMessage}
                <div block="FreeShippingPromotions" elem="ProgressBar">
                    <div block="FreeShippingPromotions" elem="ProgressBarLine"
                    style={{width: `${100 - diffLeft}%`}}
                    ></div>
                </div>
            </div>
        );
    }
}

export default FreeShippingPromotionsComponent;
