import './RewardPointsForm.style.scss';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'SourceComponent/Field';
import Form from 'SourceComponent/Form';
import Loader from 'SourceComponent/Loader';

export class RewardPointsFormComponent extends PureComponent {

    static propTypes = {
        isLoading: PropTypes.bool.isRequired
    };


    render() {
        const { isLoading, handleFormSuccess , isApplied, totals : { amrewards_point }, balance} = this.props;
        return(
            <Form block="RewardPoints"
                  onSubmitSuccess={ handleFormSuccess }
            >
                <Loader isLoading={ isLoading } />
                {
                    isApplied ?
                        <>
                            <div className="reward-left">
                                <strong>
                                    {__(`You Have ${balance - amrewards_point} points left `)}
                                </strong>
                            </div>
                            <Field
                                type="text"
                                id="reward_field"
                                name="reward_cancel"
                                isDisabled={true}
                                value={amrewards_point}
                                mix={ { block: 'RewardPoints', elem: 'Input' } }
                            />
                        </>
                        :
                        <>
                            <div className="reward-left">
                                <strong>
                                {__(`You Have ${balance} points left `)}
                                </strong>
                            </div>
                            <Field
                                type="text"
                                id="reward_field"
                                name="reward_field"
                                value={amrewards_point}
                                validation={['notEmpty']}
                                mix={ { block: 'RewardPoints', elem: 'Input' } }
                            />
                        </>
                }
                <button
                    block="RewardPoints"
                    elem="Button"
                    type="submit"
                >
                    { isApplied ? __('Cancel Reward') : __('Apply')}
                </button>
            </Form>
        );
    }
}

export default RewardPointsFormComponent;
