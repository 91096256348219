import { UPDATE_BANNERS, RESET_BANNERS } from "./Banners.action";


export const getInitialState = () => ({
    banners: {}
});

export const BannersReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case UPDATE_BANNERS:
            const { banners } = action;

            return {
                ...state,
                banners: banners
            };
        case RESET_BANNERS:
            return {
                ...state,
                banners: getInitialState()
            };
        default:
            return state;
    }
};

export default BannersReducer;
