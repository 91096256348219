import {cloneElement} from "react";

const renderAddressBook = (args, callback, instance) =>  {
    return cloneElement(
        callback.apply(instance, args),
        {
            extensionAttributes: instance.props.extensionAttributes,
            onOrderAttributeChange: instance.props.onOrderAttributeChange,
            onVatIdBlur: instance.props.onVatIdBlur,
            onInvoiceTypeClick: instance.props.onInvoiceTypeClick,
            getActivities: instance.props.getActivities,
            invoice_type: instance.props.invoice_type
        }
    );
};
export const config = {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderAddressBook
        }
    }
};

export default config;
