import { PureComponent } from 'react';
import ReOrderQuery from '../../query/reOrder.query';
import MyAccountOrderPopup from 'Component/MyAccountOrderPopup/MyAccountOrderPopup.component';
import { fetchMutation } from 'SourceUtil/Request';

export const CartDispatcher = import(
  /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
  'SourceStore/Cart/Cart.dispatcher'
);


class MyAccountMyOrdersComponentPlugin extends PureComponent {

  mapDispatchToProps(args, callback, instance) {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
     updateCartData: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    )
      }
  }

  renderReOrderLink(_instance) {
    const { 
        onReOrderSubmit, 
        order: { base_order_info: {
            increment_id
        }
      }, 
    isAdminOrder 
  } = _instance.props;

    return (
      <button 
        block="MyAccountTrackOrderPopup" 
        elem="Button Button"
        mods= {{ isDisable: isAdminOrder }}
        onClick={ () => onReOrderSubmit(increment_id, _instance) }
      >
        {__("Re Order")}
      </button>
    );
  }

  renderActions(_args, _callback, _instance) {
    const {
      order: { order_products },
    } = _instance.props;

    if (!order_products) {
      return null;
    }

    return [_callback.apply(_instance, _args), renderReOrderLink(_instance)];
  }

  async onReOrderSubmit(_instance, _args) {
    const { showNotification, updateCartData } = _instance.props;
    _instance.setState({ isLoading: true });

    const mutation = ReOrderQuery.getSaveReOrderItemMutation(_args);

    return fetchMutation(mutation).then(
        /** @namespace ReOrder/Component/MyAccountOrderPopup/Container/fetchMutation */
        ({ reorderItems: { cart, userInputErrors } }) => {
            updateCartData(),
            _instance.setState({ isLoading: false }, () => {
              const [data] = userInputErrors;
              
              if(data && data.message) {
                showNotification('info', data.message );
              } else {
                showNotification('success',__( "Product's are added to cart."));
              }
            });
        },
        (e) => _instance.setState({ isLoading: false }, () => { 
            showNotification('info',__("Their is some error while adding reorder products to cart"))
          })
    );
  }

  containerFunctions = (_args, callback, _instance) => {
    return {
      ..._args,
      onReOrderSubmit: this.onReOrderSubmit.bind(_args, _instance),
    };
  };

  render(_args, _callback, _instance) {
    return (
      <MyAccountOrderPopup
        {...containerFunctions(_args, _callback, _instance)}
        {..._instance.containerProps()}
      />
    );
  }
}

const { renderActions, renderReOrderLink, onReOrderSubmit, containerFunctions, render, mapDispatchToProps } = new MyAccountMyOrdersComponentPlugin();

export default {
  'Component/MyAccountOrderPopup/Container/mapDispatchToProps': {
    function: [
        {
            position: 101,
            implementation: mapDispatchToProps
        }
    ]
  },
    'Component/MyAccountOrderPopup/Component': {
        'member-function': { 
            renderReOrderLink,
            renderActions
        }
    },
    'Component/MyAccountOrderPopup/Container': {
        'member-function': { 
            onReOrderSubmit,
            containerFunctions,
            render
        }
    },
};