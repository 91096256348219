import Html from 'Component/Html';
import {showNotification} from 'Store/Notification/Notification.action';
import getStore from 'SourceUtil/Store';

const _updateCartData = (args, callback, context) => {
    let [{ messages = [], type = '' }] = args;
    
    if (messages && messages.length > 0 && type !== 'removeCartItem') {
        messages = messages.reduce((accumulator, currentMessage) => {

            if (currentMessage?.message.includes("is not available at the moment") && 
                currentMessage?.message.includes("We apologize") &&
                currentMessage?.message.includes("free gift") &&
                currentMessage.type === 'notice') {
                currentMessage.message = "Some of the free gifts were not available";
            }
            
            const existingMessage = accumulator.find(
              message => message?.message === currentMessage?.message && currentMessage?.type === message?.type
            );
          
            if (!existingMessage) {
              accumulator.push(currentMessage);
            }
          
            return accumulator;
          }, []);


        const { dispatch } = getStore();
        messages.map(({ message, type }) => {
            dispatch(showNotification(type, <Html content={message} />))
        });
    }

    return callback(...args);
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData
        }
    }
};
