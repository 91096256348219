import { Field } from 'SourceUtil/Query';

/** @namespace Query/BetterWishlist */
export class BetterWishlist {

    getShareWishlistGuestMutation(input) {
        return new Field('s_shareGuestWishlist')
            .setAlias('shareGuestWishlist')
            .addArgument('input', 'ShareGuestWishlistInput!', input);
    }

    getGuestWishListShareCodeQuery() {
        return new Field('s_GetWishlistGuest')
            .addField('sharing_code');
    }

    getQuery(options) {
        return new Field('MpBetterWishlistGetCategories')
            // .addArgument('category_id', 'String', '')
            .addArgument('is_items', 'Boolean', true)
            .addFieldList(this._getWishlistCategory(true));
    }

    getMoveOrCopyItem(options) {
        const mutation = new Field('WishlistMoveOrCopy')
        .addArgument('input', 'WishlistMoveOrCopyInput', options)
        .addField('message');

        return mutation;
    }

    getRemoveItemFromCategoryOnly(options) {
        const mutation = new Field('WishlistMoveOrCopyInput')
        .addArgument('input', 'WishlistMoveOrCopyInput', options)

        return mutation;
    }

    getCreateCategroy(options) {
        const mutation = new Field('MpBetterWishlistCreateCategory')
            .addArgument('input', 'MpBetterWishlistCategoryInput', options)
            .addFieldList(this._getWishlistCategory(true));

        return mutation;
    }

    getEditCategroy(options) {
        const mutation = new Field('MpBetterWishlistEditCategory')
            .addArgument('input', 'MpBetterWishlistCategoryInput', options)
            .addFieldList(this._getWishlistCategory());

        return mutation;
    }

    getDeleteCategory(options){
        const mutation = new Field('MpBetterWishlistDeleteCategory')
            .addArgument('input', 'MpBetterWishlistCategoryInput', options)

        return mutation;
    }

    getAddItemToCategory(options){
        const mutation = new Field('MpBetterWishlistAddItem')
             .addArgument('input', 'MpBetterWishlistItemInput', options)

        return mutation;
    }

    getRemoveItemFromCategory(options) {
        const mutation = new Field('MpBetterWishlistRemoveItem')
            .addArgument('input', 'MpBetterWishlistItemInput', options)

        return mutation;
    }

    _getWishlistCategory(isCreate) {
        return [
            'category_id',
            'category_name',
            'is_default',
            isCreate ? 
                this._getItems() :
            null
        ];
    }

    _getItems() {
        return new Field('items')
            .addFieldList(this._getItem())
    }

    _getItem() {
        return [
            'added_at',
            'description',
            'product_id',
            'qty',
            'store_id',
            'wishlist_item_id',
        ];
    }
}

export default new BetterWishlist();