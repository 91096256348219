import getStore from 'SourceUtil/Store';

export const RewardPointsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/RewardPoints/RewardPoints.dispatcher'
    );


const signInAfter = (args, callback, instance)  => {
    const { 
        getRewardConfig: {
            is_enable = false
        } = {},
    } = getStore().getState() || {};

    if(!is_enable) {
        return callback.apply(instance, args);
    }

    return callback.apply(instance, args).then(
        ()=>{
            RewardPointsDispatcher.then(
                ({ default: dispatcher }) => dispatcher.fetchRewardPointsData(getStore().dispatch),
            )
        }
    ).then(
        ()=>{
            RewardPointsDispatcher.then(
                ({ default: dispatcher }) => dispatcher.fetchCustomerRewards(getStore().dispatch)
            )
        }
    ).then(
        ()=> {
            RewardPointsDispatcher.then(
                ({ default: dispatcher }) => dispatcher.fetchLoadCustomerRewardsExpirations(getStore().dispatch)
            )
        }
    );
}

export default {
    'Store/MyAccount/Dispatcher' : {
        'member-function': {
            'signIn': signInAfter
        }
    }
}
