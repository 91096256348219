const _updateCartData = async (args, callback, instance) => {
    const cartData = args[0];
    const dispatch = args[1];
    if (cartData.hasOwnProperty('is_active') && !cartData.is_active) {
        return instance.createGuestEmptyCart(cartData, dispatch);
    }

    return callback.apply(instance, args);
};

export const config = {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData
        }
    }
};

export default config;
