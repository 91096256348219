const getSelectedFilterFiled = () => {
    return [
        'filter_code',
        'selected_values'
    ]
}
const aroung_getAggregationsOptionsFields = (args, callback, instance) => {
    let result = callback(...args);
    result.push('url');
    result.push('level');
    result.push('parent_id');
    return result;
}
export default {
    'Query/ProductList': {
        'member-function': {
            '_getAggregationsOptionsFields': aroung_getAggregationsOptionsFields
        },
    }
}
