import { lazy } from 'react';
import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));


/**
 * Button for adding product to Cart
 * @class AddToCart
 * @namespace Component/AddToCart/Component
 */
export class AddToCart extends SourceAddToCart {

    render() {
        const {
            mix,
            product: { type_id },
            isLoading,
            buttonClick,
            layout,
            disabled,
            titleUpperCase,
            isAddingSample,
            isModiface,
            src,
            isSampleProducts
        } = this.props;

        const isDisable = isAddingSample ? true : isLoading || disabled;
        const CTA = isSampleProducts ? __('Add Sample') :__('Add to cart')

        if (!type_id) {
            this.renderPlaceholder();
        }

        if(titleUpperCase) {
            return (
                <button
                    rel='nofollow'
                    onClick={ buttonClick }
                    block="Button AddToCart"
                    mix={ mix }
                    mods={ { isLoading, layout } }
                    disabled={ isDisable }
                >
                    { this.renderCartIcon() }
                    <span>{ isLoading ? __('ADDING...') : __('ADD TO CART') }</span>
                </button>
            );
        }

        return (
            <button
              rel='nofollow'
              onClick={ buttonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { isLoading, layout } }
              disabled={ isDisable }
            >
                {!isModiface ?
                    <>
                        {this.renderCartIcon()}
                        <span>{isLoading ? __('Adding...') : CTA }</span>
                    </> :
                    <UniversalIcon
                        src={src}
                        alt="Add to cart"
                    />
                }
            </button>
        );
    }
}

export default AddToCart;
