import { MyAccountAddressForm as SourceMyAccountAddressForm } from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.component';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import {
    getAvailableRegions
} from 'Util/Address';
import './RadioDiv.style.scss';
import { fetchQuery, getErrorMessage } from 'SourceUtil/Request';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import MyAccountQuery from 'SourceQuery/MyAccount.query';
import Field from 'SourceComponent/Field';
import getStore from 'Util/Store';
import { BILLING_STEP } from "Route/Checkout/Checkout.config";
import { CART_TOTALS } from "Store/Cart/Cart.reducer";
import {getCountryPostCodeValidationClass} from "Util/Validation";

export class MyAccountAddressForm extends SourceMyAccountAddressForm {

    __construct(props) {
        super.__construct(props);

        const {
            countries,
            shippingFields,
            address,
            default_country,
            id
        } = props;

        let {
            country_id,
            region_id,
            city = '',
            vat_id,
            tax_office,
            business_activity,
            company
        } = shippingFields || address;

        if(id === "BILLING_STEP"){
            country_id = region_id = city = vat_id = tax_office = business_activity = company = '';
        }

        const countryId = country_id || default_country;
        const country = countries.find(({ id }) => id === countryId) || {};

        const { is_state_required = false } = country;

        const availableRegions = getAvailableRegions(countryId, countries);
        // const defaultRegionId = availableRegions.length ? availableRegions[0].id : '';
        const defaultRegionId = '';
        const regionId = region_id || defaultRegionId;

        this.state = {
            countryId,
            availableRegions,
            regionId,
            vat_id,
            company,
            isStateRequired: is_state_required,
            city,
            tax_office,
            defaultShipping: false,
            defaultBilling:  false,
            getActivities: [],
            business_activity: business_activity
        };
    }

    setDefaultValues(){
        const {
            address: {
                default_billing,
                default_shipping
            }
         } = this.props;

        if(default_shipping !== undefined){
            this.setState({
                defaultShipping: default_shipping,
                defaultBilling:  default_billing,
            })
        }
    }

    componentDidMount(){
        this.setDefaultValues();
    }


    // returns the first address field in quantity equal to BE
    getAddress1Field() {
        const { addressLinesQty } = this.props;

        if (addressLinesQty === 1) {
            return {
                street: this.getStreetFields(
                    __('Street address'),
                    __('Your street address'),
                    0
                )
            };
        }

        const streets = {};

        // eslint-disable-next-line fp/no-loops, fp/no-let
        for (let i = 0; i < 1; i++) {
            streets[`street${i}`] = this.getStreetFields(
                __('Street address', i + 1),
                __('Your street address line %s', i + 1),
                i
            );
        }

        return streets;
    }

    // returns other than first address fields in quantity equal to BE
    getAddressFields() {
        const { addressLinesQty } = this.props;

        if (addressLinesQty === 1) {
            return {
                street: this.getStreetFields(
                    __('Street address'),
                    __('Your street address'),
                    0
                )
            };
        }

        const streets = {};

        // eslint-disable-next-line fp/no-loops, fp/no-let
        for (let i = 1; i < addressLinesQty; i++) {
            streets[`street${i}`] = this.getStreetFields(
                __('Additional information (optional)', i + 1),
                __('Your street address line %s', i + 1),
                i
            );
        }

        return streets;
    }

    getRegionFields() {
        const { address: { region: { region, region_id } = {} }, regionDisplayAll } = this.props;
        const { availableRegions, regionId, isStateRequired } = this.state;

        if (!regionDisplayAll && !isStateRequired) {
            return null;
        }

        if (!availableRegions || !availableRegions.length) {
            return {
                region_string: {
                    label: __('State / Province'),
                    value: region,
                    validation: isStateRequired ? ['notEmpty'] : []
                }
            };
        }

        return {
            region_id: {
                placeholder: __('Please select your state/province'),
                label: __('State / Province'),
                type: 'select',
                selectOptions: availableRegions.map(({ id, name }) => ({ id, label: name, value: id })),
                onChange: (regionId) => this.setState({ regionId }),
                value: !region_id ? regionId : region_id,
                validation: isStateRequired ? ['notEmpty'] : []
            }
        };

    }

    onDefaultAddressChange(){
        const { defaultShipping } = this.state
        this.setState({ defaultShipping: !defaultShipping })
    }

    onDefaultBillingChange(){
        const { defaultBilling } = this.state
        this.setState({ defaultBilling: !defaultBilling })
    }

    get fieldMap() {
        const { countryId, city, defaultBilling, defaultShipping } = this.state;
        const { countries: sourceCountries, address, isSubmitted, id } = this.props;
        const { default_billing, default_shipping } = address;

        /*
        * Map and push empty field to show in case
        * if no country selected instead of default for myaccount
        */
        const countries = sourceCountries.map(({ id, label }) => ({ id, label, value: id }));
        countries.push({ id: ' ', label: ' ', value: ' ' });
        let postCodeValidation = ['notEmpty'];
        const countryPostCodeValidationClass = getCountryPostCodeValidationClass(countryId);
        if(countryPostCodeValidationClass){
            postCodeValidation.push(countryPostCodeValidationClass)
        }


        const fields = {
            firstname: {
                label: __('First name'),
                validation: ['notEmpty'],
                validateSeparately: false,
                isSubmitted,
                placeholder: __('Your first name')
            },
            lastname: {
                label: __('Last name'),
                validation: ['notEmpty'],
                validateSeparately: false,
                isSubmitted,
                placeholder: __('Your last name')
            },
            ...this.getAddress1Field(),
            telephone: {
                type: 'telephone',
                label: __('Phone'),
                validation: ['notEmpty', 'telephone'],
                validateSeparately: false,
                isSubmitted
            },
            postcode: {
                label: __('Τ.Κ.'),
                validation: postCodeValidation,
                validateSeparately: false,
                isSubmitted,
                onBlur: this.onZipcodeChange,
                placeholder: __('Your zip / postal code')
            },
            ...this.getRegionFields(),
            city: {
                label: __('City'),
                validation: ['notEmpty'],
                validateSeparately: false,
                isSubmitted,
                value: city,
                placeholder: __('Your city')
            },
            country_id: {
                type: 'select',
                label: __('Country'),
                validation: ['notEmpty'],
                validateSeparately: false,
                isSubmitted,
                value: countryId,
                selectOptions: countries,
                onChange: this.onCountryChange
            },
            ...this.getAddressFields(),
            ...this.getVatField(),
            default_billing: {
                type: 'checkbox',
                label: __('This is default Billing Address'),
                value: defaultBilling,
                checked: defaultBilling,
                onChange: () => this.onDefaultBillingChange()
            },
            default_shipping: {
                type: 'checkbox',
                label: __('This is default Shipping Address'),
                value: defaultShipping,
                checked: defaultShipping,
                onChange: () => this.onDefaultAddressChange()
            }
        };

        return fields;
    }

    onCountryChange = (countryId) => {
        const { countries } = this.props;
        const { countryId: prevCountryId } = this.state;
        const country = countries.find(({ id }) => id === countryId);
        const { available_regions = [], is_state_required } = country;

        this.setState({
            countryId,
            availableRegions: available_regions || [],
            isStateRequired: is_state_required
        });

        // avoid region reset when coming back to shipping step
        // HONDOSC-1640 We don't want any pre-selection of region on country change
        // if (prevCountryId && prevCountryId !== countryId) {
        //     this.setState({
        //         regionId: available_regions?.length ? available_regions[0].id : null
        //     });
        // }
    };
}

export default MyAccountAddressForm;
