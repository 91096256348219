import { toggleOverlayByKey } from 'SourceStore/Overlay/Overlay.action';
import { CART_OVERLAY } from 'SourceComponent/Header/Header.config';
import { changeNavigationState, goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';

class MiniCartPopup {
    mapStateToProps(args, callback, instance) {
        const [state] = args;
        return {
            ...callback.apply(instance, args),
            device: state.ConfigReducer.device,
        }
    };

    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
            setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName))
        }
    }

    aroundAfterAddToCart(args, callback, instance) {
        callback(...args);
        const {
            showOverlay,
            device,
            setNavigationState
        } = instance.props;
        
        if (!device.isMobile) {
            showOverlay(CART_OVERLAY);

            setNavigationState({
                name: CART_OVERLAY
            });
        }
    }

}

const { 
    mapDispatchToProps,
    mapStateToProps,
    aroundAfterAddToCart
} = new MiniCartPopup();

export default {
    'Component/AddToCart/Container': {
        'member-function': {
            afterAddToCart: aroundAfterAddToCart
        }
    },
    'Component/AddToCart/Container/mapDispatchToProps': {
        function: {
            position: 102,
            implementation: mapDispatchToProps
        }
    },
    'Component/AddToCart/Container/mapStateToProps': {
        function: {
            position: 102,
            implementation: mapStateToProps
        }
    },
}