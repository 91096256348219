import BrowserDatabase from "SourceUtil/BrowserDatabase";
import { SAMPLE_PRODUCTS } from "../route/SampleProductCategoryPage/SampleProductCategoryPage.config";
import { appendWithStoreCode } from 'SourceUtil/Url';
import history from 'SourceUtil/History';
import { getSampleQtyLimit, getItemIdArray } from '../utils';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);

const mapStateToProps = (_args, _callback, _instance) => {
    const [state] = _args;
    return {
        ..._callback.apply(_instance, _args),
        sampleProductRule: state.ConfigReducer.sample_product_price_range_count,
        
    }
};

const mapDispatchToProps = (_args, _callback, _instance) => {
    const [dispatch] = _args;
    return {
        ..._callback.apply(_instance, _args),
        removeSampleProductFromCart: (itemIds) => CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.removeSampleProductFromCart(dispatch, itemIds)
        ),
    }
}

const __construct = (_args, _callback, _instance) =>{
    const props = _args[0];
    _callback(props);

    _instance.state = {
        maxSample: getSampleQtyLimit(props)
    };
}

const handleCheckoutClick = (_args, _callback, _instance) => {
    const isSkip = BrowserDatabase.getItem(SAMPLE_PRODUCTS) || false;

    // if (!isSkip) {
    //     history.push({
    //         pathname: appendWithStoreCode(SAMPLE_PRODUCTS)
    //     });
    //     window.scrollTo({ top: 0 });

    //     return;
    // }

    return [
        _callback.apply(_instance, _args)
    ]
}

const componentDidUpdate = (_args, _callback, _instance) => {
    const [ prevProps, prevState ] = _args;
    const { maxSample } = prevState;
    const { totals: prevTotals } = prevProps;
    const { totals, totals: { items = [] }, removeSampleProductFromCart } = _instance.props;

    if(totals !== prevTotals && totals?.items.length > 0) {
        _instance.setState(() => {
           if(maxSample !==  getSampleQtyLimit(_instance.props)) {
            removeSampleProductFromCart(getItemIdArray(items, true));
            return {
                maxSample: getSampleQtyLimit(_instance.props)
            }
           }
        })
     }
        _callback.apply(_instance, _args)
}


export default {
    'Component/CartOverlay/Container/mapDispatchToProps': {
        function: {
            position: 101,
            implementation: mapDispatchToProps
        }
    },
    'Component/CartOverlay/Container/mapStateToProps': {
        function: {
            position: 101,
            implementation: mapStateToProps
        }
    },
    'Component/CartOverlay/Container': {
        'member-function': {
            __construct: __construct, 
            handleCheckoutClick,
            componentDidUpdate
        },
    }
};