/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';

import ProductHelpPopup from './ProductHelpPopup.component';
import {PRODUCT_HELP_POPUP_ID} from "./ProductHelpPopup.config";

/** @namespace Component/ProductHelpPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (message) => dispatch(showNotification('success', message)),
    showError: (message) => dispatch(showNotification('error', message)),
    showPopup: (payload) => dispatch(showPopup(PRODUCT_HELP_POPUP_ID, payload)),
    hidePopup: () => dispatch(showPopup('', {}))
});

/** @namespace Component/ProductHelpPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Component/ProductHelpPopup/Container/ProductHelpPopupContainer */
export class ProductHelpPopupContainer extends PureComponent {
    static propTypes = {
        showError: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        hidePopup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        showHelpButton: false
    };

    containerFunctions = {
        openProductHelpPopup: this.openProductHelpPopup.bind(this)
    };

    containerProps() {
        const { showHelpButton } = this.props;

        return { showHelpButton };
    }

    openProductHelpPopup(e) {
        e.preventDefault();

        const { showPopup } = this.props;
        showPopup({ title: __('ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ') });
    }

    render() {
        return (
            <ProductHelpPopup
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductHelpPopupContainer);
