// import PropTypes from 'prop-types';

import DataContainer from 'Util/Request/DataContainer';
import { fetchMutation } from 'Util/Request';
import RequestAccountDeleteQuery from "../../query/RequestAccountDelete.query";
import RequestAccountDeleteButton from './RequestAccountDeleteButton.component';
import {connect} from "react-redux";
import { showNotification } from 'Store/Notification/Notification.action';

export const mapStateToProps = state => ({
});

export const mapDispatchToProps = dispatch => ({
    showNotification: (type, message, e) => dispatch(showNotification(type, message, e))
});

class RequestAccountDeleteButtonContainer extends DataContainer {
    state = {
        isLoading : false,
        isOverlayVisible : false
    }

    static propTypes = {
    };

    containerFunctions = {
        onButtonClick: this.onButtonClick.bind(this),
        showOverlay: this.showOverlay.bind(this)
    };

    showOverlay(state){
        this.setState({isOverlayVisible : state});
    }

    onButtonClick(){
        const { showNotification } = this.props;
        const mutation = RequestAccountDeleteQuery.getQuery();
        this.setState({
            isLoading: true
        });
        return fetchMutation(mutation).then(
            (data) => {
                showNotification('success', __("Request made successfully"));
                this.setState({
                    isLoading: false,
                    isOverlayVisible : false
                });
            },
            (error) => {
                if (error[0].debugMessage) {
                    showNotification('error', error[0].debugMessage);
                } else {
                    showNotification('error', error[0].message);
                }

                this.setState({ isLoading: false, isOverlayVisible : false});
            }
        );

    }

    containerProps = () => {
        return { ...this.state }
    };

    render() {
        return (
            <RequestAccountDeleteButton
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestAccountDeleteButtonContainer);
