
import { Field } from 'Util/Query';

/** @namespace ScandiPWA/HrefLangsResolver/Query */
export class HrefLangsResolverQuery {
    getQuery({type,id, SelectedFilters}) {
        return new Field('mfAlternateHreflang')
            .addArgument('type', 'String', type)
            .addArgument('id', 'String', id)
            .addArgument('selectedFilters', '[GetSelectedCategoryFilters]', !!SelectedFilters ? SelectedFilters : {})
            .addFieldList([this._getItemFields()]);
    }

    _getItemFields() {
        return new Field('items')
            .addFieldList([
                'href',
                'hreflang'
            ]);
    }
}
export default new HrefLangsResolverQuery();
