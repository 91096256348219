import { CheckoutGuestForm as SourceCheckoutGuestForm } from "SourceComponent/CheckoutGuestForm/CheckoutGuestForm.component";
import FormPortal from 'Component/FormPortal';
import MyAccountSignIn from 'Component/MyAccountSignIn';
import {
    BILLING_STEP
} from 'Route/Checkout/Checkout.config';

/** @namespace Component/CheckoutGuestForm/Component */
export class CheckoutGuestForm extends SourceCheckoutGuestForm {

    state = {
        emailValue : ''
    }

    guestFieldMap() {
        const {
            formId
        } = this.props;

        return {
            guest_email: {
                form: formId,
                label: __('Email'),
                type: 'email',
                validation: ['notEmpty', 'email'],
                onChange: (value)=>{this.setState({emailValue : value})},
                skipValue: true,
                validateSeparately: true
            }
        };
    }

    renderGuestFields() {
        return (
            <div
                block="FieldForm"
                elem="Fields"
            >
                { Object.entries(this.guestFieldMap()).map(this.renderField) }
                <input type={'password'} name={'password'} hidden={true}></input>
            </div>
        );
    }

    renderGuestForm() {
        const { formId } = this.props;

        return (
            <FormPortal
                id={ formId }
                name="CheckoutGuestForm"
            >
                { this.renderFields() }
                <span>{ __('No customer with such email.') }</span>
            </FormPortal>
        );
    }

    proceedToBilling(){
        const { emailValue } = this.state;
        const { handleEmailInput } = this.props;
        if(!emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            return;
        }
        handleEmailInput(emailValue);
        this.props.setCheckoutStep(BILLING_STEP);
        setTimeout(()=>{
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },500);
    }


    renderGuestCheckoutForm(){
        const { formId } = this.props;
        const { emailValue } = this.state;

        return(
            <article block="MyAccountOverlay" elem="Additional" >
                <h2 block="CheckoutGuestForm" elem="Heading">
                    { __('Complete as a guest') }
                </h2>
                <FormPortal
                    id={ formId }
                    name="CheckoutGuestForm"
                >
                    { this.renderGuestFields() }
                    <div className='CheckoutGuestForm_email'>{__('Guest email: ')}{ emailValue}</div>
                    <div className='CheckoutGuestForm_text'>{ __('You can create an account after checkout') }</div>
                </FormPortal>
                <section>
                    <div block="MyAccountOverlay" elem="SignInButton" onClick={ this.proceedToBilling.bind(this) }>
                        <button disabled={!emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)} block="Button">{ __('Checkout as guest') }</button>
                    </div>
                </section>
            </article>
        );
    }

    render() {
        const { login_option, switchLoginOption } = this.props;

        return (
            <div
                block="CheckoutGuestForm"
                mix={ { block: 'FieldForm' } }
            >
                <div className="CheckoutGuestForm-title">{ __('ΕΠΙΛΕΞΤΕ ΤΟΝ ΤΡΟΠΟ ΣΥΝΔΕΣΗΣ') }</div>
                <div className="CheckoutGuestForm__Options">
                    <div className={"CheckoutGuestForm__Option " + (login_option === 0 ?"is-active":"") } onClick={() => switchLoginOption(0)}>{ __('Συνδεθείτε στο λογαριασμό σας') }</div>
                    <div className={"CheckoutGuestForm__Option " + (login_option === 1 ?"is-active":"") } onClick={() => switchLoginOption(1)}>{ __('Ολοκλήρωση ως επισκέπτης') }</div>
                </div>
                <div className="CheckoutGuestForm-cols">
                    <div className={"CheckoutGuestForm-col " + (login_option === 0 ?"is-active":"") }>{ this.renderForm() }</div>
                    <div className={"CheckoutGuestForm-col " + (login_option === 1 ?"is-active":"") }>{ this.renderGuestCheckoutForm() }</div>
                </div>
            </div>
        );
    }

}

export default CheckoutGuestForm;
