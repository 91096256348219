const containerProps = (args, callback, instance) => {
    return {
        ...callback.apply(instance, args),
        forBundler: instance.props.forBundler,
        bundlerOptions: instance.props.bundlerOptions,
        handleSelect: instance.props.handleSelect,
        selectedOption: instance.props.selectedOption,
        mainProduct: instance.props.mainProduct,
        bundlerItems: instance.props.bundlerItems
    };
}

export const config = {
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps: containerProps
        }
    }
};

export default config;
