/**
 * MageGuide - Progressive Web App for Magento
 *
 * Copyright © MageGuide, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 */

import SampleProductsReducer from '../store/SampleProduct/SampleProduct.reducer';

export class StoreIndexPlugin {
    /**
     * Plugin to add blog reducers to getStaticReducers method.
     * @param args
     */
     getStaticReducers = (args, callback) => ({
        ...callback(...args),
        SampleProductsReducer
    });
}

const { getStaticReducers } = new StoreIndexPlugin();
 
export const config = {
    'Store/Index/getReducers': {
        function: getStaticReducers
    }
};

export default config;
