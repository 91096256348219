import ProductListQuery from 'Query/ProductList.query';
import { Field } from 'SourceUtil/Query';

export class CartQueryPlugin {
    _getProductField() {

        ProductListQuery.options.isCart = true;
        ProductListQuery.options.isForLinkedProducts = true;
        // ProductListQuery.options.isPlp = true;
        // ProductListQuery.options.noAttributes = true;
        // ProductListQuery.options.noVariantAttributes = true;
        
        const productQuery = new Field('product') 
            .addFieldList([
                ...ProductListQuery._getProductInterfaceFieldsOptz(false, false),
                ProductListQuery._getCategoriesField()
            ]); 

        // ProductListQuery.options.isPlp = false;
        // ProductListQuery.options.noAttributes = false;
        // ProductListQuery.options.noVariantAttributes = false;
        ProductListQuery.options.isForLinkedProducts = false;
        ProductListQuery.options.isCart = false;
    
        return productQuery;
    }
}

const { _getProductField } = new CartQueryPlugin();

export default  {
    'Query/Cart': {
        'member-function': {
            '_getProductField': _getProductField
        }
    }
};
