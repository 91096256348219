
export class CmsBlockQueryPlugin {
    /**
     * Get cms block theem config fields
     * @returns [Field]
     * @private
     */
     _aroundGetItemFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'cms_bg_image',
        'bg_color',
        'fc_color'
    ];
}

export const { _aroundGetItemFields } = new CmsBlockQueryPlugin();

export default {
    'Query/CmsBlock': {
        'member-function': {
            _getItemFields: [
                {
                    position: 100,
                    implementation: _aroundGetItemFields
                }
            ]
        }
    }
};
