import { lazy } from 'react';
import { CartItem as SourceCartItem } from 'SourceComponent/CartItem/CartItem.component';
import CartItemPrice from 'Component/CartItemPrice';
import { formatCurrency, roundPrice } from 'Util/Price';
import Field from 'Component/Field';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import { 
    SAMPLE_CATEGORY, 
    BAG_CATEGORY, 
    CARDS_CATEGORY,
    BRAND_DIOR,
    BRAND_CHRISTIAN_DIOR,
    LUXURY_ITEM
} from 'Route/ProductPage/ProductPage.config';


export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
));

/**
 * Cart and CartOverlay item
 * @class CartItem
 * @namespace Component/CartItem/Component
 */
export class CartItem extends SourceCartItem {

    renderDeleteButton() {
        const {
            item: {
                discount_amount,
                row_total,
                product: {
                    categories
                }
        },
        handleRemoveItem,
        isMobileLayout,
        isSampleProducts
    } = this.props;

        if(!(discount_amount - row_total) && !categories?.length > 0){
            return null;
        }

        return (
            <button
                block="CartItem"
                id="RemoveItem"
                name="RemoveItem"
                elem="Delete"
                mods={ { isMobileLayout } }
                aria-label="Remove item from cart"
                onClick={ handleRemoveItem }
            >
                <UniversalIcon
                    src={media('close.svg', WYSIWYG_MEDIA)}
                    alt="closeIcon"
                    className="CloseIcon"
                />
                { isSampleProducts ?
                    ''
                    :
                    <span block="CartItem" elem="DeleteButtonText" mods={ { isMobileLayout } }>
                        { __('Delete') }
                    </span>
                }
            </button>
        );
    }

    renderPriceWithoutDiscount(){
        const {
            currency_code,
            item: {
                product : {
                    price_range = {},
                    type_id,
                    variants = [],
                    is_reduced_price
                } = {},
                sku: itemSku = {},
                qty
            }
        } = this.props;

        if(is_reduced_price)
            return null;

        if (type_id === 'configurable'){
            // Find the variant
            const productVariant = variants.filter((v)=> {
                return v.sku === itemSku;
            }, {});

            // Get specific variant's price and discount
            const { price_range = {} } = productVariant[0] || {};
            if (!(price_range && price_range.minimum_price && price_range.minimum_price.discount && price_range.minimum_price.discount.percent_off !== 0)){
                return null
            }
            const oldPrice = roundPrice(price_range.minimum_price.regular_price.value * qty);

            return (
                <span block="CartItem" elem="OldPrice">{ `${oldPrice}${formatCurrency(currency_code)}` }</span>
            )
        }

        if (!(price_range && price_range.minimum_price && price_range.minimum_price.discount && price_range.minimum_price.discount.percent_off !== 0)){
            return null
        }

        const oldPrice = roundPrice(price_range.minimum_price.regular_price.value * qty);

        return (
            <span block="CartItem" elem="OldPrice">{ `${formatCurrency(currency_code)}${oldPrice}` }</span>
        )
    }

    renderProductPrice() {
        const {
            currency_code,
            item: {
                product: {
                    categories,
                    attributes: {
                        brand: {
                            attribute_options = {},
                            attribute_value = ''
                        } = {}
                    } = {},
                    variants = [],
                    type_id
                },
                sku: itemSku = {},
                discount_amount,
                row_total,
                row_total_incl_tax,
                product
            },
            isCartOverlay,
            isMobileLayout,
            isCheckout,
        } = this.props;
        let productVariant = [];

        const isSampleProduct = categories?.filter(({ id, name } )=> id == SAMPLE_CATEGORY || name == 'ΔΕΙΓΜΑΤΑ').length > 0;

        const isGift = categories?.filter(({ url } )=> url.indexOf('free-gift') != -1 ).length > 0;
        
        if(!isSampleProduct && !(discount_amount - row_total_incl_tax) ){
            return <span class='free-gift' >{__('FREE')}</span>;
        }

        if(isSampleProduct && (!row_total < 1 || !row_total_incl_tax !== 0)){
            return <span class='free-gift' >{__('SAMPLE PRODUCT')}</span>;
        }

        if(!isSampleProduct && !(discount_amount - row_total_incl_tax) || isGift ){
              return <span class='free-gift' > 
                    {__( attribute_options[attribute_value]?.label != BRAND_DIOR && attribute_options[attribute_value]?.label != BRAND_CHRISTIAN_DIOR ? 'FREE GIFT' : 'YOUR GIFT')}
              </span>;
        }

        if (type_id === 'configurable') {
            // Find the variant
            productVariant = variants.filter((v) => {
                return v.sku === itemSku;
            });
        }

        const {
            attributes = {},
            price_range: {
                minimum_price: {
                    final_price: { value: actualProductPrice }
                } = {}
            } = {},
        } = productVariant[0] || product

        const unitLabel = attributes?.unit_label?.attribute_value ? attributes.unit_label.attribute_value : null;
        const unitCalcModifier = attributes?.unit_product_price_per_unit_calc?.attribute_value ? attributes.unit_product_price_per_unit_calc.attribute_value : null;
        const isLuxuryItem = attributes?.att_108?.attribute_value ? attributes?.att_108?.attribute_value === LUXURY_ITEM ? attributes?.att_108?.attribute_value : null : null;
        const isUnitCalcModifierActive = unitCalcModifier > 0 && !isLuxuryItem;

        return (
            <div block={"CartItem"} elem={"Price-Wrapper"}>
                { this.renderPriceWithoutDiscount() }
                <CartItemPrice
                    actualProductPrice={actualProductPrice}
                    row_total={ row_total }
                    row_total_incl_tax={ row_total_incl_tax }
                    currency_code={ currency_code }
                    mix={ {
                        block: 'CartItem',
                        elem: 'Price',
                        mods: { isCartOverlay, isMobileLayout, isUnitCalcModifierActive, isCheckout }
                    }}
                    unitLabel={(unitCalcModifier && !isLuxuryItem) && unitLabel}
                    unitCalcModifier={(unitCalcModifier && !isLuxuryItem) && unitCalcModifier}
                />
            </div>
        );
    }

    renderProductOption = (option) => {
        const { label, values = [], id } = option;

        const labelText = values
            ? __('%s: %s', label, values.map(({ label, value }) => label || value).join(', '))
            : label;

        return (
            <div
              block="CartItem"
              elem="Option"
              key={ id }
            >
                { labelText }
            </div>
        );
    };

    renderProductConfigurationOption (optionsLabel) {

        if(optionsLabel.length < 1) {
            return null;
        }

        return (
            <div
                key={ optionsLabel.label }
                aria-label={ optionsLabel.label }
                block="CartItem"
                elem="Option"
                className="CartItem__item"
            >
                <strong>{ optionsLabel.label }:</strong>
                <span>{ optionsLabel.value }</span>
            </div>
        );
    }

    renderProductConfigurations() {
        const { optionsLabels } = this.props;

        return (
            <div
                block="CartItem"
                elem="Options"
            >
                { Object.values(optionsLabels).map(this.renderProductConfigurationOption) }
            </div>
        );
    }

    renderQuantity() {
        const { item: { qty } } = this.props;

        return (
            <div
                block="CartItem"
                elem="Quantity"
            >
                <strong>{ __('Quantity:') }</strong>
                <span>{ qty }</span>
            </div>
        );
    }

    renderProductWishlistButton() {
        const {
            cartPage
        } = this.props;

        if (!cartPage) {
            return null
        }

        return null
    }

    renderProductEditButton() {
        const {
            cartPage
        } = this.props;

        if (!cartPage) {
            return null
        }

        return null
    }

    renderQuantityChangeField() {
        const {
            item: {
                qty,
                product: {
                    categories,
                    stock_item: {
                        qty_increments: qtyIncrement = 1
                    } = {},
                } = {},
                discount_amount,
                row_total,
                row_total_incl_tax
            } = {},
            linkTo = {},
            minSaleQuantity,
            maxSaleQuantity,
            handleChangeQuantity,
            isProductInStock,
            isCartOverlay,
            cartPage
        } = this.props;
        
        const isSampleProduct = categories?.filter(({ id, name } )=> id == SAMPLE_CATEGORY || name == 'ΔΕΙΓΜΑΤΑ').length > 0;
        const isGift = categories?.filter(({ url } )=> url.indexOf('free-gift') != -1 ).length > 0;
        const isDisable = isSampleProduct || isGift ||
            // !(discount_amount - row_total_incl_tax) && !categories.length > 0 ||
            !(discount_amount - row_total_incl_tax);

        if (!isProductInStock && cartPage) {
            return <Link to={ linkTo } block="CartItem" elem="QuantityWrapper no-stock">{ __('SEE ALTERNATIVES') }</Link>;
        }

        if (!isProductInStock) {
            return <div block="CartItem" elem="QuantityWrapper" mods={ { isPlaceholder: true } } />;
        }

        return (
            <div
                block="CartItem"
                elem="QuantityWrapper"
                mods={ { isCartOverlay } }
                onClick={ !isDisable ? this.quantityClickHandler : {} }
                onKeyDown={ !isDisable ? this.quantityClickHandler : {} }
                role="button"
                tabIndex="-1"
                data-label={ __('Qty:') }
            >
                <Field
                    disabled= { isDisable }
                    id="item_qty"
                    name="item_qty"
                    type="number"
                    isControlled
                    min={ minSaleQuantity }
                    max={ maxSaleQuantity }
                    mix={ { block: 'CartItem', elem: 'Qty' } }
                    value={ qty }
                    onChange={ handleChangeQuantity }
                    step={ qtyIncrement }
                />
            </div>
        );
    }

    renderDesktopContent() {
        return (
            <div block="CartItem" elem="Wrapper" mods={ { isCart: true } }>
                <div className="CartItem-ProductInfo_Left">
                    <div block="CartItem" elem="ProductInfo">
                        { this.renderImage() }
                        { this.renderTitle() }
                    </div>
                </div>
                <div className="CartItem-ProductInfo_Right">
                    { this.renderDeleteButton() }
                    <div
                        block="CartItem"
                        elem="ProductActions"
                    >
                        { this.renderQuantityChangeField() }
                        { this.renderProductPrice() }
                    </div>
                    <div
                        block="CartItem"
                        elem="AdditionalActions"
                    >
                        { this.renderProductEditButton() }
                        { this.renderProductWishlistButton() }
                    </div>
                </div>
            </div>
        );
    }

    renderImage() {
        const { linkTo, isMobile, item: { product: { categories = [] } } } = this.props;
        const isDisable = categories.filter(({ id })=> id == SAMPLE_CATEGORY || id == CARDS_CATEGORY)?.length !== 0;

        if(isDisable) {
            return this.renderImageElement();
        }

        if (isMobile) {
            return (
                <Link to={ linkTo } block="CartItem" elem="Link">
                    { this.renderImageElement() }
                </Link>
            );
        }

        return (
            <Link to={ linkTo } block="CartItem" elem="Link">
                { this.renderImageElement() }
            </Link>
        );
    }

    renderWrapperContent() {
        const { isEditing, isCartOverlay } = this.props;

        if (isCartOverlay) {
            return this.renderMobileContent();
        }

        return isEditing ? this.renderDesktopContent() : this.renderDesktopSummary();
    }

    renderDesktopSummary() {
        const { isSampleProducts, isMobileLayout } = this.props;

        if(isSampleProducts){
            return (
                <div block="CartItem" elem="Wrapper" mods={ { isSummary: true } }>
                    { this.renderImage() }
                    <div block="CartItem" elem="CartItemRows">
                        <div block="CartItem" elem="ProductInfo">
                            { this.renderDesktopSummaryTitle() }
                            { this.renderDeleteButton() }
                        </div>
                        <div block="CartItem" elem="ProductActions" mods={ { isMobileLayout } }>
                            { this.renderProductPrice() }
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div block="CartItem" elem="Wrapper" mods={ { isSummary: true } }>
                { this.renderImage() }
                <div block="CartItem" elem="CartItemRows">
                    <div block="CartItem" elem="ProductInfo">
                        { this.renderDesktopSummaryTitle() }
                        { this.renderProductPrice() }
                    </div>
                </div>
            </div>
        );
    }

    renderBrandName() {
        const {
            item: {
                product: {
                    attributes
                }
            }
        } = this.props;

        let brand = attributes !== undefined
        && attributes.brand !== undefined
        && attributes.brand.attribute_options[attributes.brand.attribute_value] !== undefined ?
            attributes.brand.attribute_options[attributes.brand.attribute_value].label : null;

        if(!brand)
            return null;

        return (
            <p
                block="CartItem"
                elem="Brand"
            >
                <TextPlaceholder content={ brand } length="medium" />
            </p>
        );
    }

    renderOutOfStockMessage() {
        const { isProductInStock, isMaxSaleQtyExceed } = this.props;
        const message = isMaxSaleQtyExceed ? 'Max allowed qty exceed' : 'Out of Stock';

        if (isProductInStock && !isMaxSaleQtyExceed) {
            return null;
        }

        return (
            <p block="CartItem" elem="OutOfStock">
                {  __( message ) }
            </p>
        );
    }

    renderProductSku() {
        const {
            item: {
                sku
            },
            cartPage
        } = this.props;

        if(!cartPage) {
            return null
        }

        return (
            <p
                block="CartItem"
                elem="Sku"
            >
                <TextPlaceholder content={ sku } length="medium" />
            </p>
        );
    }

    renderDesktopSummaryTitle() {
        const { isMobileLayout } = this.props;

        const {
            item: {
                customizable_options,
                bundle_options,
                downloadable_links
            } = {}
        } = this.props;

        return (
            <div block="CartItem" elem="Title" mods={ { isMobileLayout } }>
                { this.renderBrandName() }
                { this.renderProductName() }
                { this.renderProductSku() }
                { this.renderOutOfStockMessage() }
                { this.renderProductConfigurations() }
                { this.renderProductOptions(customizable_options) }
                { this.renderProductOptions(bundle_options) }
                { this.renderProductLinks(downloadable_links) }
                { this.renderQuantity() }
            </div>
        );
    }

    renderProductName() {
        const {
            linkTo,
            item: {
                product: {
                    name
                }
            }
        } = this.props;

        return (
            <Link to={ linkTo } block="CartItem" elem="Heading">{ name }</Link>
        );
    }

    renderLimitationMessage() {
        const { hasLimitation } = this.props;

        if (hasLimitation) {
            return (
                <div className="CartItem_limitation">
                    <div className="label">{__('Eligible for shipping only in Greece')}</div>
                </div>
            );
        }

        return null;
    }


    renderTitle() {
        const { isMobileLayout } = this.props;

        const {
            item: {
                customizable_options,
                bundle_options,
                downloadable_links
            } = {}
        } = this.props;

        return (
            <div block="CartItem" elem="Title" mods={{ isMobileLayout }}>
                {this.renderBrandName()}
                {this.renderProductName()}
                {this.renderProductSku()}
                {this.renderOutOfStockMessage()}
                {this.renderProductConfigurations()}
                {this.renderProductOptions(customizable_options)}
                {this.renderProductOptions(bundle_options)}
                {this.renderProductLinks(downloadable_links)}
                {this.renderLimitationMessage()}
            </div>
        );
    }

    renderContent() {
        const { linkTo = {}, isProductInStock, isMaxSaleQtyExceed, isMobile } = this.props;

        if (!isProductInStock || isMaxSaleQtyExceed || Object.keys(linkTo).length === 0 || isMobile) {
            // If product is out of stock, or link is not set
            return (
                <span block="CartItem" elem="Link">
                    { this.renderWrapperContent() }
                </span>
            );
        }

        return (
            <div block="CartItem" elem="Link">
                { this.renderWrapperContent() }
            </div>
        );
    }
}

export default CartItem;
