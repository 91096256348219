import FieldInput from "Component/FieldInput";
import { convertDateFormat } from '../util/Date';
import media, { WYSIWYG_MEDIA } from "Util/Media/Media";
import {lazy} from "react";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));


const renderTypeText = (args, callback, instance) => {
    let { type, id } = instance.props;
    const { noLabel } = instance.props;
    let showValue = instance.props?.value;

    if(noLabel) {
        return (
            <FieldInput
                { ...instance.props }
                type={type === 'date' ? "date" : "text"}
            />
        );
    }

    return (
        <div className={`has-float-label ${instance.props.isDisabled ? 'isDisabled' : ''}`}>
            { type === 'date' && id === 'date_of_birth' ?
                <UniversalIcon
                    src={media('calendar.jpg', WYSIWYG_MEDIA)}
                    alt="calendar"
                    className="Image-Image"
                />
                : ''
            }
            <FieldInput
                { ...instance.props }
                type={type === 'date' ? "date" : "text"}
                placeholder={ instance.renderPlaceholder() }
            />
            { instance.renderOnlyLabel() }
            { type === 'date' && id === 'date_of_birth' ?
                showValue ?
                    <div className="Field-Placeholder">{ convertDateFormat(showValue) }</div> :
                    <div className="Field-Placeholder">{ __('day/month/year') }</div> :
                ''
            }
        </div>
    );
}
export const config = {
    'Component/Field/Component': {
        'member-function': {
            renderTypeText
        }
    }
};

export default config;