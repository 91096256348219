/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CategoryPaginationLink from 'Component/CategoryPaginationLink';
import ChevronIcon from 'Component/ChevronIcon';
import ChevronsIcon from 'Component/ChevronsIcon';
import { LEFT, RIGHT } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { range } from 'Util/Manipulations';

import { CategoryPagination as SourceCategoryPagination } from 'SourceComponent/CategoryPagination/CategoryPagination.component';

/** @namespace Component/CategoryPagination/Component */
export class CategoryPagination extends SourceCategoryPagination {
    renderPageDoubleIcon(isNext = false) {
        return (
            <ChevronsIcon direction={ isNext ? RIGHT : LEFT } />
        );
    }

    renderFirstPageLink() {
        const { 
            anchorTextPrevious, 
            currentPage
        } = this.props;

        if (currentPage === 1) {
            return null;
        }

        return this.renderPageLink(
            1,
            __('First page'),
            this.renderPageDoubleIcon(false)
        );
    }

    renderPageLink(
        pageNumber,
        label,
        children,
        isCurrent = false
    ) {
        const {
            pathname,
            onPageSelect,
            getSearchQuery
        } = this.props;

        return (
            <li
                key={ Math.random() }
                block="CategoryPagination"
                elem="ListItem"
            >
                <CategoryPaginationLink
                    label={ label }
                    url_path={ pathname }
                    getPage={ onPageSelect }
                    isCurrent={ isCurrent }
                    pageNumber={ pageNumber }
                    getSearchQueryForPage={ getSearchQuery }
                >
                    { children }
                </CategoryPaginationLink>
            </li>
        );
    }

    renderLastPageLink() {
        const { 
            totalPages, 
            currentPage
        } = this.props;

        if (currentPage === totalPages) {
            return null;
        }

        return this.renderPageLink(
            totalPages,
            __('Last page'),
            this.renderPageDoubleIcon(true)
        );
    }

    render() {
        const { isLoading, totalPages, id } = this.props;

        if (totalPages === 1) { // do not show pagination, if there are less then one page
            return <ul block="CategoryPagination" />;
        }

        if (isLoading) {
            return this.renderPlaceholder();
        }

        return (
            <nav aria-label={ __('Product list navigation') }>
                <ul block="CategoryPagination" id={ id }>
                    { this.renderFirstPageLink() }
                    { this.renderPreviousPageLink() }
                    { this.renderPreviousJump() }
                    { this.renderPageLinks() }
                    { this.renderNextJump() }
                    { this.renderNextPageLink() }
                    { this.renderLastPageLink() }
                </ul>
            </nav>
        );
    }
}

export default CategoryPagination;
