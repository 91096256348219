import { getWindowId, appendTokenToHeaders, handleConnectionError } from 'Util/Request';
import getStore from 'Util/Store';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { updateLoadStatus, updateSearchBar } from 'Store/SearchBar/SearchBar.action';
import { makeCancelable } from 'Util/Promise';

export const getApiSearchStatus = () => {
    const { ConfigReducer: {
        search_is_enabled,
        search_project_key,
        search_endpoint_url,
        use_in_autocomplete,
        use_in_fulltext,
        storeList = [],
        code: storeLabel
     } = {} } = getStore().getState() || {};
     
     const store = storeList.find(
        (data) => data?.code == storeLabel
    ) || [];

     return {
        use_in_fulltext: use_in_fulltext != 0 ? true : false,
        use_in_autocomplete: use_in_autocomplete != 0 ? true : false,
        search_is_enabled: search_is_enabled != 0 ? true : false,
        search_project_key: search_project_key || 'hondos',
        search_endpoint_url: search_endpoint_url || 'https://searchiful.chris.magedeploy.com/',
        storeCode: store?.id || 1
     };
}

export const executeGet = (args, name, cacheTTL) => {
    const { 
        search: searchCriteria, 
        currentPage, 
        pageSize, 
        filter: {
            customFilters = {},
            priceRange = {}
        } = {},
        sort: {
            sortDirection,
            sortKey
        } = {}
    } = args;
    
    const { search_project_key, search_endpoint_url, storeCode } = getApiSearchStatus();
    const searchType = name !== 'search' ? 'client/fullsearch/' : 'client/search/'
    var uri = `${search_endpoint_url}${searchType}${search_project_key}/product/1?`;
    var { search } = new URL(uri);
    let params = new URLSearchParams(search);

    params.append('s', storeCode);
    if (name !== 'search') {
        params.append('q', decodeURIComponent(searchCriteria.replace('.', '')));
    }
    else {
        params.append('q', searchCriteria.replace('.', ''));
    }
    if (pageSize && currentPage) {
        params.append('l', pageSize);
        params.append('p', currentPage);
    }

    if(priceRange && Object.values(priceRange)?.length > 0 && priceRange.max !== 0) {
        params.append('f[price][0]',priceRange.min)
        params.append('f[price][1]',priceRange.max)
    }

    if(sortDirection && sortKey !== 'relevant') {
        params.append(`o[${sortKey}]`, sortDirection);
    }

    if(customFilters && Object.values(customFilters)?.length > 0) {
        Object.entries(customFilters).map( (filter, key) => {
            const [ name, value ] = filter
            value.forEach( (element, i) => {
                params.append(`f[${filter[0]}][${i}]`, element)
            });
        });
    }

    uri = uri + params.toString();

    return parseResponse(new Promise((resolve) => {
        getFetch(uri, name).then(
            /** @namespace ExternalSearch/Util/Request/getFetchThen */
            (res) => {
                resolve(res);
            }
        );
    })); 
};

export const parseResponse = (promise) => new Promise((resolve, reject) => {
    promise.then(
        (res) => res.text().then(
            (res) => resolve(checkForErrors(res)),
            () => handleConnectionError('Can not transform Text!') && reject()
        ),
        /** @namespace ExternalSearch/Util/Request/promiseError */
        (err) => handleConnectionError('Can not establish connection!') && reject(err)
    );
});

export const getFetch = (uri, name) => fetch(uri,
    {
        method: 'GET',
        headers: appendTokenToHeaders({
            'Content-Type': 'text/plain',
            Accept: 'application/json,text/html,application/xhtml+xml',
        })
});

export const checkForErrors = (res) => new Promise((resolve, reject) => {
    const { errors } = res;
    
    return !res ? reject(errors) : resolve(res);
});


export const emptySearchRequest = () => {
    const { dispatch } = getStore();

    dispatch(updateLoadStatus(true));

    makeCancelable(executeGet({ search: '' }, 'search', ONE_MONTH_IN_SECONDS).then((resp) => {
        const data = resp;

        dispatch(updateSearchBar(data));
        dispatch(updateLoadStatus(false));
    }));
}
