import { BUNDLE } from 'SourceUtil/Product';

const renderAdditionalSections = (_args, _callback, _instance) => {
    const { dataSource } = _instance.props;

    const { type_id } = dataSource;

    if(type_id === BUNDLE) {
        return null;
    }

    return _callback.apply(_instance, _args);
}


export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderAdditionalSections
        }
    }
}; 