import CartDispatcher from "Store/Cart/Cart.dispatcher";
export class CartPagePlugin {
    componentDidMount(args, callback, instance){
        const { getFreshCart } = instance.props;
        callback.apply(instance, args);
        getFreshCart();
    }

    mapDispatchToProps(args, callback){
        const [dispatch] = args;
        return {
            ...callback(...args),
            getFreshCart: () => CartDispatcher.updateInitialCartData(dispatch)
        };
    };
}

const { componentDidMount, mapDispatchToProps } = new CartPagePlugin();
export const config = {
    'Route/CartPage/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Route/CartPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
};

export default config;
