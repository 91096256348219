/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */

const mapStateToProps =  (args, callback, instance) => {
    const [state] = args;
    return {
        ...callback.apply(instance, args),
        base_url: state.ConfigReducer.base_url
    };
};


const config = {
    'Component/CategorySubcategories/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    }
}

export default config;
