import { AdminCustomerReducer } from '../store/AdminCustomer/AdminCustomer.reducer';

export class StoreIndexPlugin {
    aroundGetStaticReducers = (args, callback) => ({
        ...callback(...args),
        AdminCustomerReducer
    });
}

const { aroundGetStaticReducers } = new StoreIndexPlugin();

export const config = {
    'Store/Index/getReducers': {
        function: aroundGetStaticReducers
    }
};

export default config;
