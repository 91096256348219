import media, { WYSIWYG_MEDIA } from "SourceUtil/Media/Media";
import ProductWishlistButton from 'SourceComponent/ProductWishlistButton';
import {
    CONFIGURABLE
} from 'SourceUtil/Product';
import { objectToUri } from 'SourceUtil/Url';
import {lazy} from "react";

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

class CartItemPlugin {

    containerFunctions(args, callback, instance){
        return {
            ...args,
            CartItemEdit: CartItemEdit.bind(args, callback, instance)
        }
    };

    containerProps(args, callback, instance) {
        const { isWishlistEnabled } = instance.props;
        
        return {
            ...callback.apply(instance, args),
            isWishlistEnabled
        };
    }

    _getProductLinkTo(args, callback, instance){
            const {
                item: {
                    product,
                    product: {
                        type_id,
                        configurable_options,
                        parent,
                        url
                    } = {}
                } = {}
            } = instance.props;
    
            if (type_id !== CONFIGURABLE) {
                return {
                    pathname: url,
                    state: { product }
                };
            }
    
            const variant = instance.getProductVariant();
            
            if (!variant) {
                return {};
            }
            const { attributes = {} } = variant;
    
            const parameters = Object.entries(attributes).reduce(
                (parameters, [code, { attribute_value }]) => {
                    if (Object.keys(configurable_options).includes(code)) {
                        return { ...parameters, [code]: attribute_value };
                    }
    
                    return parameters;
                }, {}
            );
    
            const stateProduct = parent || product;
    
            return {
                pathname: url,
                configurableVariantIndex: instance._getVariantIndex(),
                parameters,
                state: { product: stateProduct },
                search: objectToUri(parameters)
            };
        }

    CartItemEdit(args, callback, instance){
        const { productEditPopup, item } = args.props;
        const getLink = args._getProductLinkTo();
        productEditPopup({ getLink, item })
    }

    renderEditCartItem(instance){
        const {
            item: {
                product: {
                    type_id
                } = {}
            } = {},
            CartItemEdit
        } = instance.props;

        if (type_id !== CONFIGURABLE) {
            return null
        }

        return(
            <div className="ProductEditButton">
                <button
                    block="ProductEditButton"
                    elem="Button"
                    onClick={ CartItemEdit }
                >
                    <UniversalIcon src={media('edit.svg', WYSIWYG_MEDIA)} alt="HeartIcon" className="HeartIcon"/>
                    <span>{ __('Edit') }</span>
                </button>
            </div>
        );
    }

    renderContent(args, callback, instance) {

        return [
            callback.apply(instance, args),
            renderEditCartItem(instance)
        ];

    }

    renderWishlistButtonCartItem = (instance) => {
        const {
            item: {
                product,
                quantity,
                product: {
                    type_id,
                    options = [],
                    configurable_options: confOptions = {}
                }   
            },
            isWishlistEnabled,
        } = instance.props;
    
        if (!isWishlistEnabled) {
            return null;
        }
    
        const groupedProductQuantity = {};
    
        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }
    
            return acc;
        }, []);
        
        const configurableVariantIndex = 0;
        const productOptionsData = {
            requiredOptions
        };


        return (
            <ProductWishlistButton
                isCartItem
                product={product}
                quantity={quantity}
                isCart
                configurableVariantIndex= { configurableVariantIndex }
                // onProductValidationError={ onProductValidationError }
                productOptionsData={productOptionsData}
                groupedProductQuantity={groupedProductQuantity}
            />
        );
    }

    renderProductEditButton(args, callback, instance) {
        const {
            cartPage
        } = instance.props;

        if (!cartPage) {
            return [
                callback.apply(instance, args)
            ]
        }

        return [
            callback.apply(instance, args),
            renderEditCartItem(instance),
            renderWishlistButtonCartItem(instance),
        ]
    }

    render(args, callback, instance){
        const { cartPage } = instance.props;

        if(cartPage){
            return [
                callback.apply(instance, args)
            ]
        }

        return [
            callback.apply(instance, args)
        ]
    }
}


const { 
    containerFunctions, 
    containerProps,
    renderEditCartItem, 
    renderWishlistButtonCartItem,
    // renderContent,
    renderProductEditButton,
    // render,
    _getProductLinkTo,
    CartItemEdit 
} = new CartItemPlugin();

export default {
    'Component/CartItem/Component': {
        'member-function': {
            renderEditCartItem,
            renderWishlistButtonCartItem,
            renderProductEditButton,
            // renderContent
            // render
        }
    },
    'Component/CartItem/Container': { 
        'member-function': { 
            CartItemEdit,
            _getProductLinkTo,
            containerFunctions,
            containerProps
        }
    }
}