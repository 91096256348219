import { createRef } from 'react';
import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';
import ExpandableContent from 'Component/ExpandableContent';
import ExpandableHorizontal from 'Component/ExpandableHorizontal';
import SlickSliderHtml from 'Component/SlickSliderHtml';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { DeviceType } from 'Type/Device';
import PropTypes from "prop-types";
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import Loader from 'Component/Loader';
import ContentShowMore from 'Component/ContentShowMore';
import getStore from 'Util/Store';
import CmsTabs from "Component/CmsTabs";
import Image from 'Component/Image';

export class Html extends SourceHtml {
    static propTypes = {
        device: DeviceType.isRequired,
        content: PropTypes.string.isRequired
    };

    static defaultProps = {
        expandlableHorizontalSettings : {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
        }
    };

    parserOptions = {
        replace: (domNode) => {
            const { data, name: domName, attribs: domAttrs } = domNode;

            // Let's remove empty text nodes
            if (data && !data.replace(/\u21b5/g, '').replace(/\s/g, '').length) {
                return null;
            }

            const rule = this.rules.find((rule) => {
                const { query: { name, attribs } } = rule;

                if (name && domName && name.indexOf(domName) !== -1) {
                    return true;
                } if (attribs && domAttrs) {
                    // eslint-disable-next-line fp/no-loops, fp/no-let
                    for (let i = 0; i < attribs.length; i++) {
                        const attrib = attribs[i];

                        if (typeof attrib === 'object') {
                            const queryAttrib = Object.keys(attrib)[0];

                            if (Object.prototype.hasOwnProperty.call(domAttrs, queryAttrib)) {
                                return domAttrs[queryAttrib].match(Object.values(attrib)[0]);
                            }
                        } else if (Object.prototype.hasOwnProperty.call(domAttrs, attrib)) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (rule) {
                const { replace } = rule;

                return replace.call(this, domNode);
            }
        }
    };

    rules = [
        {
            query: { name: ['widget'] },
            replace: this.replaceWidget
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['div'] },
            replace: this.replaceDiv
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { name: ['video'] },
            replace: this.replaceVideo
        }
    ];

    /**
     * Replace img to React Images
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs.src) {
            const imageRef = createRef(null);
            
            return <Image { ...attributes } isPlain imageRef= { imageRef } />;
        }
    }


        /**
     * Replace vide to React Video
     * @param  {{ attribs: Object }}
     * @return {void|JSX} Return JSX with image
     * @memberof Html
     */
    replaceVideo({ attribs, children }) {
        const attributes = attributesToProps(attribs);
        const { device } = this.props;

        const objectSrc = Object.values(children).reduce((acc, { attribs }) => {
            if (attribs) {
                return { ...acc, [attribs.class]: attribs.src }
            }
            return acc;
        }, {});

        const src = !device.isMobile ? objectSrc.desktop || attributes.src : objectSrc.mobile || attributes.src;

        if (src) {
            return (
                <RenderWhenVisible fallback={() => <Loader isLoading />}>
                    <video
                        width={attributes.width || '100%'}
                        height={attributes.height || '100%'}
                        autoplay={attributes.autoplay || 'false'}
                        muted={attributes.muted || 'false'}
                        loop={attributes.loop || 'false'}
                        playsInline={attributes.playsInline || 'true'}
                        preload={attributes.preload || 'none'}
                    >
                        <source src={src} type="video/mp4" />
                    </video>
                </RenderWhenVisible>
            );
        }
    }

    /**
     * Replace links to native React Router links
     * @param  {{ attribs: Object, children: Array }}
     * @return {void|JSX} Return JSX if link is allowed to be replaced
     * @memberof Html
     */
    replaceDiv({ attribs, children }) {
        const { class : className, ...attrs } = attribs;
        // const { device } = this.props;
        let device = getStore().getState().ConfigReducer.device;

        if (className && className.includes('scandi_horizontal_accordian') && !device.isMobile) {
            return (
                <ExpandableHorizontal
                    mix={ { block: 'scandi_horizontal_accordian', elem: 'Content' } }
                    additioanlClass={ attrs['data-class'] }
                >
                    { domToReact(children,this.parserOptions) }
                </ExpandableHorizontal>
            );
        }

        if (className && className.includes('scandi_mobile_showmore') && device.isMobile) {
            return (
                <ContentShowMore>
                    { domToReact(children,this.parserOptions) }
                </ContentShowMore>
            );
        }

        if (className && className.includes('scandi_showmore')) {
            return (
                <ContentShowMore>
                    { domToReact(children,this.parserOptions) }
                </ContentShowMore>
            );
        }

        if (className && className.includes('scandi_tabs') && attrs['data-id'] && attrs['data-values'] && JSON.parse(attrs['data-values']).length > 0) {
            let tabsTitle = [];
            let tabsContent = [];

            Object.entries(JSON.parse(attrs['data-values'])).reduce((init,[key,option])=>{
                if (option.title && option.value){
                    tabsTitle.push(option.title);
                    tabsContent.push(option.value);
                }
                return init;
            },{});

            if(tabsTitle.length === 0)
                return null

            return (
                <CmsTabs tabsTitle={tabsTitle} tabsContent={tabsContent} />
            );
        }

        if (className && className.includes('scandi_desktop_accordian')) {
            return (
                <ExpandableContent
                    mix={{ block: className, elem: 'Content' }}
                    heading={attrs['data-heading']}
                    id={attrs["id"]}
                >
                    { domToReact(children,this.parserOptions) }
                </ExpandableContent>
            );
        }

        if (className && className.includes('scandi_accordian') && device.isMobile) {
            return (
                <ExpandableContent
                    mix={{ block: 'scandi_accordian', elem: 'Content' }}
                    heading={attrs['data-heading']}
                    id={attrs["id"]}
                >
                    { domToReact(children,this.parserOptions) }
                </ExpandableContent>
            );
        }

        if (className && className.includes('Header-TopMenu')) {
            setTimeout(function(){
                let headerTopMenu = document.getElementById('Header-TopMenu');

                if(headerTopMenu) {
                    setTimeout(function(){
                        headerTopMenu.style.maxHeight = '100%';
                        headerTopMenu.style.opacity = '1';
                    }, 500);
                }
            }, 500);
        }

        if (className && className.includes('slick-slider')) {

            if(attrs['data-mobile'] && !device.isMobile) {
                return (
                    domToReact(children)
                );
            }

            if(attrs['data-showslickon'] && attrs['data-showslickon'] === '2' && device.isMobile){
                return (
                    domToReact(children)
                );
            }
            if(attrs['data-showslickon'] && attrs['data-showslickon'] === '3' && !device.isMobile){
                return (
                    domToReact(children)
                );
            }

            return (
                <SlickSliderHtml
                    settings={ attrs['data-settings'] ? JSON.parse(attrs['data-settings'].replaceAll("\'","\"")) : attrs['data-settings'] }
                    prevClass={attrs['data-prev']}
                    nextClass={attrs['data-next']}
                    className={className}
                >
                    { domToReact(children,this.parserOptions) }
                </SlickSliderHtml>
            );
        }

        if (className && className.includes('ExpandableHorizontal_wrapper') && device.isMobile) {

            return (
                <SlickSliderHtml
                    settings={ this.props.expandlableHorizontalSettings }
                    className={className}
                >
                    { domToReact(children,this.parserOptions) }
                </SlickSliderHtml>
            );
        }
    }
}

export default Html;
