import { 
    PRODUCTLIST_POPUP,
    WISHLIST_POPUP,
    MULTITAB_POPUP
 } from '../utils/wishlist.config';
import Popup from "SourceComponent/Popup";
import { createRef } from 'react';

import { PureComponent, lazy, Suspense } from 'react';
import Loader from 'SourceComponent/Loader';

export const WishlistPopup = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "wishlist-popups" */
    '../component/WishlistPopup'
));

export const ProductPopup = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "wishlist-popups" */
    '../component/ProductPopup'
));


class ProductListPlugin {
    popupRef = () => createRef();

    mapStateToProps(args, callback, instance) {
        const [state] = args;
        return {
            ...callback.apply(instance, args),
            productsInWishlist: state.WishlistReducer.productsInWishlist,
            isWishlistEnabled: state.ConfigReducer.wishlist_general_active
            
        }
    };

    containerProps(args, callback, instance) {
        const { productsInWishlist, isWishlistEnabled, isSampleProduct } = instance.props;
        
        return {
            ...callback.apply(instance, args),
            productsInWishlist,
            isWishlistEnabled,
            isSampleProduct
        };
    }

    renderWishlistPopup(instance){
        
        if(location.pathname.includes('cart')) { 
            return null;
        }

        return (
            <Popup
            ref={ popupRef }
            id={ WISHLIST_POPUP }
            clickOutside={ false }
            mix={ { block: 'ProductCartWishlistPopup' } }
          >
            <Suspense fallback={ <Loader isLoading/> }>
              <WishlistPopup 
                isProductList 
                { ...instance.props }/>
            </Suspense>
          </Popup>
        )
    }   
    
    aroundRenderMultiTabProductWidget(args, callback, instance) {
        const { tabId = '' } = instance.props;
        return (
            <>
            { callback.apply(instance, args) }
            { renderWishlistPopup(instance) }
            <Popup
                ref={ popupRef }
                id={ `${MULTITAB_POPUP}-${tabId}` }
                clickOutside={false}
                mix={{ block: 'MultiTabProduct-Edit' }}
            >
            <Suspense fallback={ <Loader isLoading/> }>
                <ProductPopup
                    isProductList 
                    { ...instance.props }
                />
                </Suspense>
            </Popup>
            </>
        );
    }

    aroundRenderProductPopup(args, callback, instance) {
        const { additionalClass ='', title, widgetId } = instance.props;
        const isGiftPL = additionalClass.indexOf('GiftBox_') !== -1 ? 'GiftBox_' : title ? title : widgetId;

        const popupId =  !title && !additionalClass ? PRODUCTLIST_POPUP : `${PRODUCTLIST_POPUP}-${ isGiftPL }`;

        return (
            <>
            { callback.apply(instance, args) }
            { renderWishlistPopup(instance) }
            { additionalClass !== 'CartPage-GiftBox_choose_bag' ?
            <Popup
                ref={ popupRef }
                id={ popupId }
                clickOutside={false}
                mix={{ block: 'WishlistItem-Edit' }}
            >
            <Suspense fallback={ <Loader isLoading/> }>
                <ProductPopup
                    isProductList 
                    { ...instance.props }
                />
                </Suspense>
            </Popup>
            : null }
            </>
        );
    }
}

const { 
    aroundRenderMultiTabProductWidget,
    aroundRenderProductPopup, 
    renderWishlistPopup, 
    mapStateToProps,
    containerProps,
    popupRef 
} = new ProductListPlugin();

export default {
    'Component/ProductList/Component': {
        'member-function': {
            popupRef,
            renderWishlistPopup,
            render: aroundRenderProductPopup
        }
    },
    'Component/ProductList/Container': { 
        'member-function': { 
            containerProps
        }
    },
    'Component/ProductList/Container/mapStateToProps': {
        function: {
            position: 101,
            implementation: mapStateToProps
        }
    },

    'Component/MultiTabProductWidget/Component': {
        'member-function': {
            popupRef,
            renderWishlistPopup,
            render: aroundRenderMultiTabProductWidget
        }
    },
    // 'Component/ProductLinks/Component': {
    //     'member-function': { 
    //         render:aroundRenderProductLink
    //     }
    // },
    // 'Component/RelatedProducts/Component': { 
    //     'member-function': { 
    //         renderProducts:aroundRenderProductPopup
    //     }
    // },
    'Component/MultiTabProductWidget/Container/mapStateToProps': {
        function: {
            position: 101,
            implementation: mapStateToProps
        }
    },
}