import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';

/** @namespace Query/Cart */
export class CartQuery  extends SourceCartQuery {

    _getCartTotalsFields() {
        return [
            ...super._getCartTotalsFields(),
            'is_active'
        ];
    }
}

export default new CartQuery();
