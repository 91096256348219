/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import 'react-input-range/lib/css/index.css';
import { RangeSelector as SourceRangeSelector } from 'SourceComponent/RangeSelector/RangeSelector.component'

import InputRange from 'react-input-range';
import { formatCurrency } from 'Util/Price';

/**
 * Product Sort
 * @class ProductSort
 * @namespace Component/RangeSelector/Component/rangeSelector
 */
export class RangeSelector extends SourceRangeSelector {
    onInputChange = (index,e,) => {
        let value = this.getValue();
        const { max } = value;
        if(index === 'min' && e.target.value > max) value[index] = max;
        else value[index] = e.target.value;
        this.setState({ value });
    };

    render() {
        const { minValue, maxValue, current_currency } = this.props;
        const { min, max } = this.getValue();
        const isChanged = min !== minValue || max !== maxValue;

        return (
            <>
                <span block="RangeSelector" elem="Selected">{`${min}${formatCurrency(current_currency)}-${max}${formatCurrency(current_currency)}`}</span>
                <div block="RangeSelector" mods={ { isChanged } }>
                    <div block="RangeSelector" elem="Fields">
                        <div block="RangeSelector" elem="Field">
                            <div block="RangeSelector" elem="Label"><span>{ __('Min') }</span></div>
                            <input value={min}
                                   onChange={this.onInputChange.bind(this,'min')}
                                   onBlur={()=>this.onChangeComplete(this.state.value)}
                            />
                            <div block="RangeSelector" elem="Currency"><span>{min}</span>{formatCurrency(current_currency)}</div>
                        </div>
                        <div block="RangeSelector" elem="Field">
                            <div block="RangeSelector" elem="Label"><span>{ __('Max') }</span></div>
                            <input value={max}
                                   onChange={this.onInputChange.bind(this,'max')}
                                   onBlur={()=>this.onChangeComplete(this.state.value)}
                            />
                            <div block="RangeSelector" elem="Currency"><span>{max}</span>{formatCurrency(current_currency)}</div>
                        </div>
                    </div>
                    <InputRange
                      minValue={ minValue }
                      maxValue={ maxValue }
                      value={ this.getValue() }
                      onChangeComplete={ this.onChangeComplete }
                      onChange={ this.onChange }
                    />
                </div>
            </>
        );
    }
}

export default RangeSelector;
