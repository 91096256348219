import BoxNowIframe from '../component/BoxNowIframe';

import Cookies from "universal-cookie";
import media, {WYSIWYG_MEDIA} from 'Util/Media/Media';
import Html from 'Component/Html';
export const BOXNOW_COOKIE = 'boxnow_id';
export const BOX_NOW_CODE = 'boxnow'

const renderDiscountCode = (args, callback, instance) => {
    let {
        is_box_now_module_active,
        box_now_cart_title_before_condition,
        box_now_cart_title_after_condition,
        boxnow_before_hours,
        boxnow_conditional_days
    } = instance.props;

    let condition = false;
    if(boxnow_conditional_days && boxnow_before_hours){
        let date = new Date();
        if(
            boxnow_conditional_days?.split(',').includes(date.getDay().toString())
            &&
            date.getHours() <= boxnow_before_hours
        ){
            condition = true;
        }
    }

    if(parseInt(is_box_now_module_active)) {
        return (
            <>
                <div block="CartPage" elem="Boxnow">
                    <img src={media('admin_icons/boxnow_checkout.png',WYSIWYG_MEDIA)} alt="box icon"/>
                    <div block="CartPage" elem="BoxnowText"><Html content={ (condition ? box_now_cart_title_before_condition :  box_now_cart_title_after_condition) || '' } /></div>
                </div>
                { callback.apply(instance,args) }
            </>
        )
    }

    return callback.apply(instance,args);
}
export const config = {
    'Route/CartPage/Component': {
        'member-function': {
            renderDiscountCode: renderDiscountCode
        }
    }
};

export default config;
