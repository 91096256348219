/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */
import BankRedirectComponent from "../component/BankRedirect";
import {BANK_PAYMENT_CODE} from "../component/BankRedirect/BankRedirect.config";

export class CheckoutPaymentsPlugin {

    renderBankRedirect() {
        const {
            selectedPaymentCode,
            setLoading,
            setDetailsStep,
            setBankRedirectAdditionalData
        } = this.props;
        return (
            <BankRedirectComponent
                setBankRedirectAdditionalData={setBankRedirectAdditionalData}
                setLoading={setLoading}
                setDetailsStep={setDetailsStep}
                selectedPaymentCode={selectedPaymentCode}
            />
        );
    }

    aroundPaymentRenderMap = (originalMember, instance) => {
        const plugin = {}
        instance.props.paymentMethods
            .map(method => method.code)
            .filter((method) => (method.indexOf(BANK_PAYMENT_CODE) >= 0))
            .map((method) => (plugin[method] = this.renderBankRedirect.bind(instance)));
        return ({
            ...originalMember,
                ...plugin
        });
    }
}

const {
    aroundPaymentRenderMap
} = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: aroundPaymentRenderMap
        }
    }
};

export default config;
