const getPaypalConfiguration = () => {
    return [
        'paypal_sandbox_flag',
        'paypal_client_id',
        'paypal_payment_action'
    ];
};

const _getStoreConfigFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        ...getPaypalConfiguration()
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': _getStoreConfigFields
        }
    }
};
