import { STATE_CONFIRM_EMAIL } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
class MyAccountCreateAccountPlugin {

    mapStateToProps = (args, callback, instance) => {
        const [state] = args;
        return {
            ...callback.apply(instance, args),
            customer_address_dob_show: state.ConfigReducer.customer_address_dob_show,
            customer_address_gender_show: state.ConfigReducer.customer_address_gender_show,
            customer_address_postcode_show: state.ConfigReducer.customer_address_postcode_show

        }
    };
    containerProps = (args, callback, instance) => {
        const { props } = instance;
        return {
            ...callback.apply(instance, args),
            customer_address_dob_show: props?.customer_address_dob_show,
            customer_address_gender_show: props?.customer_address_gender_show,
            customer_address_postcode_show: props?.customer_address_postcode_show
        };

    }
    onCreateAccountSuccess = async (args, callback, instance) => {
        const fields = args[0];
        const {
            createAccount,
            onSignIn,
            setSignInState,
            setLoadingState,
            isLoading,
            isLandingPage,
            showNotification,
            isMobile
        } = instance.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat,
            gender,
            postcode,
            date_of_birth
        } = fields;

        let customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat,
                gender,
                postcode,
                date_of_birth
            },
            password
        };

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData);

            // if user needs confirmation
            if (code === 2) {
                setSignInState(STATE_CONFIRM_EMAIL);

                if (isLandingPage || isMobile) {
                    showNotification(
                        'success',
                        // eslint-disable-next-line max-len
                        __('The email confirmation link has been sent to your email. Please confirm your account to proceed.')
                    );
                }
            } else {
                onSignIn();
            }
        } finally {
            setLoadingState(false);
        }

    }
}

const {
    containerProps,
    mapStateToProps,
    onCreateAccountSuccess
} = new MyAccountCreateAccountPlugin();



export const config = {
    'Component/MyAccountCreateAccount/Container': {
        'member-function': {
            containerProps,
            onCreateAccountSuccess
        }
    },
    'Component/MyAccountCreateAccount/Container/mapStateToProps': {
        function: {
            position: 101,
            implementation: mapStateToProps
        }
    },
};

export default config;
