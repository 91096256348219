import { Field } from 'SourceUtil/Query';

const _getUrlResolverFields = (args, callback, instance) => {

    return [
        ...callback(instance, args),
        'image_url',
        'name',
         getCategories()
    ];
}
const getCategories = () => {
    return new Field('categories')
        .addFieldList(category());
}

const category = () => {
    return [
        'id',
        'name',
        'url'
    ]
}
 
export default {
    'Query/UrlRewrites': {
        'member-function': {
            _getUrlResolverFields
        }
    },
};
