import PropTypes from "prop-types";
import Field from 'Component/Field';
import Form from 'Component/Form';
import { signInStateType } from 'Type/Account';
import CmsBlock from 'Component/CmsBlock';

import { MyAccountSignIn as SourceMyAccountSignIn } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import React, {lazy} from "react";

export class MyAccountSignIn extends SourceMyAccountSignIn {
    static propTypes = {
        onSignInSuccess: PropTypes.func.isRequired,
        onSignInAttempt: PropTypes.func.isRequired,
        onFormError: PropTypes.func.isRequired,
        handleForgotPassword: PropTypes.func.isRequired,
        handleCreateAccount: PropTypes.func.isRequired,
        isCheckout: PropTypes.bool.isRequired,
        state: signInStateType.isRequired,
        emailValue: PropTypes.string.isRequired,
        handleEmailInput: PropTypes.func
    };

    renderSignInForm() {
        const {
            onSignInAttempt,
            onSignInSuccess,
            onFormError,
            handleForgotPassword,
            emailValue,
            handleEmailInput,
            isCheckout,
            cmsBlock
        } = this.props;
        
        return (
            <Form
                key="sign-in"
                onSubmit={ onSignInAttempt }
                onSubmitSuccess={ onSignInSuccess }
                onSubmitError={ onFormError }
            >
                <Field
                    type="email"
                    label={ __('Email') }
                    id="email"
                    name="email"
                    placeholder={ __('Your email address') }
                    value={ emailValue }
                    autocomplete={ isCheckout ? 'off' : 'email' }
                    validation={ ['notEmpty', 'email'] }
                    onChange={ handleEmailInput }
                />
                <Field
                    type="password"
                    label={ __('Password') }
                    id="password"
                    name="password"
                    placeholder={ __('Enter your password') }
                    autocomplete="current-password"
                    validation={ ['notEmpty', 'password'] }
                />
                <button
                    type="button"
                    block="Button"
                    mods={ { likeLink: true } }
                    mix={ { block: 'MyAccountOverlay', elem: 'ForgotPassword' } }
                    onClick={ handleForgotPassword }
                >
                    { __('Forgot password?') }
                </button>
                <CmsBlock isVisible= { true } noWrapper={ true } cmsBlock={ cmsBlock } />
                <div block="MyAccountOverlay" elem="SignInButton">
                    <button rel='nofollow' block="Button">{ __('Sign in') }</button>
                </div>
            </Form>
        );
    }

    renderAdditionalField() {
        const {
            isCheckout,
            handleCreateAccount,
            state
        } = this.props;

        if (isCheckout) {
            return null;
        }

        return (
            <article block="MyAccountOverlay" elem="Additional" mods={ { state } }>
                <section>
                    <h4 id="forgot-password-label">{ __("Are you a new user?") }</h4>
                    <button
                      block="Button"
                      mods={ { likeLink: true } }
                      onClick={ handleCreateAccount }
                    >
                        { __('Create an account') }
                    </button>
                </section>
            </article>
        );
    }
}

export default MyAccountSignIn;
