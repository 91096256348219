import { isCrawler, isSSR } from 'Util/Browser';
import ProductWishlistButton from 'Component/ProductWishlistButton';

const containerProps = (args, callback, instance) => {
    const {
        handleChangeProduct,
        quantity,
        getQuantity,
        isCart,
        isDisable,
        isEditWishlistItem,
        toggleProductInWishlist,
        isProductList,
        isAddtoCart
    } = instance.props;

    return {
        ...callback.apply(instance, args),
        isAddtoCart,
        qty: quantity,
        handleChangeProduct,
        getQuantity,
        isCart,
        isDisable,
        isEditWishlistItem,
        toggleProductInWishlist,
        isProductList
    }
}

// const containerFunctions = (args, callback, instance) => {
//     const { quantity } = instance.props
//     return {
//         ...callback.apply(instance, args),
//         qty: quantity
//     }
// }

const componentDidMount = (args, callback, instance) => {
    const { setQuantity, qty } = instance.props;
    if (qty > 1) {
        setQuantity(qty);
    }
    return callback.apply(instance, args);
}

const renderProductWishlistButton = (args, callback, instance) => {
    const {
        product,
        quantity,
        configurableVariantIndex,
        onProductValidationError,
        productOptionsData,
        groupedProductQuantity,
        isWishlistEnabled,
        isProductList
    } = instance.props;

    if (!isWishlistEnabled) {
        return null;
    }

    return (
        <ProductWishlistButton
            isProductList={isProductList}
            isProductPopup={isProductList}
            product={product}
            quantity={quantity}
            configurableVariantIndex={configurableVariantIndex}
            onProductValidationError={onProductValidationError}
            productOptionsData={productOptionsData}
            groupedProductQuantity={groupedProductQuantity}
        />
    );
}

const renderAddToCartActionBlock = (args, callback, instance) => {
    const {
        handleChangeProduct,
        isCart,
        isDisable,
        isEditWishlistItem,
        toggleProductInWishlist,
        isProductList,
        isAddtoCart,
        areDetailsLoaded,
        inStock
    } = instance.props;

    if (isProductList && !isAddtoCart) {
        return instance.renderProductWishlistButton();
    }

    if (!areDetailsLoaded || !inStock) {
        return callback.apply(instance, args); //ProductSizeGuidePopup plugin not working, previously it was return null.
    }

    if (isEditWishlistItem) {
        return (
            <div
                block="ProductActions"
                elem="AddToCartWrapper"
                mods={{ isPrerendered: isSSR() || isCrawler() }}
            >
                {instance.renderQuantityInput()}
                <button
                    disabled={isDisable}
                    block="Button"
                    mix={{ block: 'ProductAction', elem: 'UpdateCart' }}
                    onClick={toggleProductInWishlist}
                >
                    <span>{__("Update Product")}</span>
                </button>
            </div>
        );

    }

    return callback.apply(instance, args);
}
const renderAddToCart = (args, callback, instance) => {
    const {
        handleChangeProduct,
        isCart,
        isDisable,
        configurableVariantIndex
    } = instance.props;
    
    if (isCart) {
        return (
            <button
                disabled={isDisable}
                block="Button"
                mix={{ block: 'ProductAction', elem: 'UpdateCart' }}
                onClick={handleChangeProduct}
            >
                <span>{__("Update Product")}</span>
            </button>
        );
    }
    return callback(...args);

}

const componentDidUpdate = (args, callback, instance) => {
    const { product: { id: prevId }, qty: prevQty, quantity: PrevNewQty } = args[0];

    const { product: { id }, minQuantity, setQuantity, qty, getQuantity, quantity: newQty } = instance.props;
    const quantity = qty > 1 ? qty : minQuantity;

    if (getQuantity && PrevNewQty !== newQty) {
        getQuantity(newQty);
    }

    if (prevQty !== qty) {
        setQuantity(quantity);
    }
    /**
     * Call back should always be called in order to other plugins work
     * id !== prevId condition is checked in main function so its removed from above
     */
    return callback.apply(instance,args);
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            componentDidUpdate,
            componentDidMount,
            renderProductWishlistButton,
            renderAddToCartActionBlock,
            renderAddToCart
        }
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps,
            // containerFunctions
        }
    },
}
