export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Wishlist/Wishlist.dispatcher'
);
import ChevronIcon from 'SourceComponent/ChevronIcon';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import { 
    WISHLIST_POPUP_NEW, 
    ALART_POPUP, 
    EDIT_PRODUCT_POPUP, 
    WISHLIST_POPUP,
    ALART_POPUP_REMOVE
 } from '../utils/wishlist.config';
import { showPopup } from 'SourceStore/Popup/Popup.action';
import WishlistPopup from '../component/WishlistPopup';
import Popup from "SourceComponent/Popup";
import Field from "SourceComponent/Field";
import EditIcon from 'SourceComponent/EditIcon';
import SubmitIcon from '../component/SubmitIcon';
import DeleteIcon from '../component/DeleteIcon';
import CloseIcon from 'SourceComponent/CloseIcon';
import ShareIcon from 'SourceComponent/ShareIcon';
import Loader from 'SourceComponent/Loader';
import WishlistItem from 'SourceComponent/WishlistItem';
import MyAccountWishlistPopupNewCategroy from '../component/MyAccountWishlistPopupNewCategroy';
import BetterWishlist from '../query/BetterWishlist.query';
import { SHARE_WISHLIST_POPUP_ID } from 'SourceComponent/ShareWishlistPopup/ShareWishlistPopup.config';
import AlartPoup from '../component/AlartPoup';
import {
    CONFIGURABLE,
    getVariantsIndexes
} from 'SourceUtil/Product';
import ProductPopup from '../component/ProductPopup';
import { isSignedIn } from 'SourceUtil/Auth';
import { getErrorMessage, fetchQuery } from 'SourceUtil/Request';
import { renderProduct as sharedWishlist } from './WishlistSharedPage.plugin';

class MyAccountMyWishlistPlugin {

    state(args, instance) {

        if(!isSignedIn() || isSignedIn() && instance.getCode && instance.getCode()) {
            return {...args};
        }

        return {
            ...args,
            activeCategory: null,
            selectedCategoryItems: {},
            editCategoryName: null,
            isEdit: false,
            product: {}
        }
    }

    defaultProps(args) {

        return {
            ...args

        }
    };

    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            clearWishlist: (options) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.clearWishlist(dispatch, options)
            ),
            // getWishlistCategories: () => WishlistDispatcher.then(
            //     ({ default: dispatcher }) => dispatcher.getWishlistCategories(dispatch),
            // ),
            addNewCategory: (newCategory) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.addNewWishlistCategory(dispatch, newCategory),
            ),
            editCategoryName: (options) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.editWishlistCategoryName(dispatch, options),
            ),
            deleteCategory: (options) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.deleteCategory(dispatch, options),
            ),
            addItemToCategory: (options) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.addItemToCategory(dispatch, options),
            ),
            removeItemFromCategory: (options) => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.removeItemFromCategory(dispatch, options),
            ),
            showPopup: (type, payload) => dispatch(showPopup(type, payload)),
            updateInitialWishlistData: () => WishlistDispatcher.then(
                ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch),
            ),
        }
    }

    mapStateToProps(args, callback, instance) {
        const [state] = args;
        return {
            ...callback.apply(instance, args),
            customer: state.MyAccountReducer.customer,
            wishlistCategories: state.WishlistReducer.wishlistCategories,
            productsInWishlist: state.WishlistReducer.productsInWishlist
        }
    }

    componentDidUpdate(args, callback, instance) {

        if(!isSignedIn()) { 
            return callback.apply(instance, args);
        }
        
        const { 
            wishlistItems: prevWishlistItems, 
            wishlistCategories : prevWishlistCategories,
            productsInWishlist: prevProductsInWishlist = {},
            customer: prevCustomer = {}
        } = args[0];

        const { activeCategory: prevActiveCategory } = args[1];

        const {
            wishlistCategories = [], 
            wishlistItems,
            updateInitialWishlistData,
            productsInWishlist = {},
            customer = {}
        } = instance.props;

        const { activeCategory } = instance.state;
        
        if(wishlistCategories !== prevWishlistCategories) {
            setActiveCategory(instance, activeCategory )
        }

        if(prevActiveCategory !== activeCategory || Object.keys(productsInWishlist).length !== Object.keys(prevProductsInWishlist).length) {
            // updateInitialWishlistData().then(() => setActiveCategory(instance, activeCategory));
            
        }

        if (customer !== prevCustomer || 
            wishlistCategories.length == 0 && 
            prevWishlistItems !== wishlistItems
        ) {
            setActiveCategory(instance, activeCategory );
        }
    }



    removeAll(args, callback, instance) {
        const { clearWishlist } = instance.props;
        const { activeCategory = 0 } = instance.state;

        // if (!isSignedIn()) {
            instance.setState({ isLoading: true });
            return clearWishlist({
                category_id: activeCategory
            }).then(
                /** @namespace Component/MyAccountMyWishlist/Container/clearWishlistThen */
                () => instance.showNotificationAndRemoveLoading('Wishlist cleared')
            );
        // }

        return callback.apply(instance, args);

    }


    addAllToCart(args, callback, instance) {
        const { moveWishlistToCart } = instance.props;

        if (!isSignedIn()) {
            instance.setState({ isLoading: true });

            return moveWishlistToCart().then(
                /** @namespace Component/MyAccountMyWishlist/Container/moveWishlistToCartThen */
                () => instance.showNotificationAndRemoveLoading('Available items moved to cart'),
                /** @namespace Component/MyAccountMyWishlist/Container/moveWishlistToCartCatch */
                (error) => instance.showErrorAndRemoveLoading(getErrorMessage(error))
            );
        }

        return callback.apply(instance, args);
    }

    componentDidMount(args, callback, instance) {

        if(!isSignedIn()){ 
            return callback.apply(instance, args);
        }

        const { isWishlistLoading, wishlistCategories = [] } = instance.props;

        instance.setState({
            activeCategory: null,
            wishlistItems: {}
        })
    }

    showAlartBox(instance, id) {
        const { showPopup } = instance.props;

        showPopup(
            ALART_POPUP,
            { title: __('Are You Sure') }
        );
    }

    getDerivedStateFromProps(args, callback, instance) {
        if(isSignedIn() && instance.name !== 'WishlistSharedPageContainer_') {
        const {
            wishlistItems,
            wishlistCategories,
            productsInWishlist,
            customer
        } = args[0];

        const { selectedCategoryItems } = args[1];

        const update = { 
            customer, 
            wishlistItems, 
            wishlistCategories, 
            selectedCategoryItems, 
            productsInWishlist 
        };

            return update;
        }
        
    }

    setActiveCategory(instance, activeCategory) {
        const { wishlistItems = {}, wishlistCategories, showError } = instance.props;
        var selectedCategoryItems = [];

        if (activeCategory === 0) {
            selectedCategoryItems.push(wishlistItems);
            selectedCategoryItems = selectedCategoryItems[0];
        } else {
            selectedCategoryItems = Object.values(wishlistItems).filter(({ wishlist }) => wishlist.categories.includes(activeCategory) ) ||  [];
        }

        instance.setState({ activeCategory, selectedCategoryItems: selectedCategoryItems });
    }

    goBackCategoryList(instance, _) {
        instance.setState({
            activeCategory: null,
            isEdit: false, 
            wishlistItems: {}
        });
    }

    shareWishlist(args, callback, instance) {
        if(!isSignedIn()) {
            const { showError, showNotification } = instance.props;
               
             return fetchQuery(BetterWishlist.getGuestWishListShareCodeQuery()).then(
                   ( { s_GetWishlistGuest } ) => {
                       const { sharing_code } = s_GetWishlistGuest;
                       if(sharing_code) {
                           const url = window.location.origin + '/' + 'wishlist/shared/' + sharing_code;
                           navigator.clipboard.writeText(url)
                           showNotification(__("Wishlist shared code clipboard"));
                       } else {
                           showError(__("Wishlist can't be shared"));
                       }
                   },
                   (error) => showError(getErrorMessage(error))
               );
           }

        const { showPopup } = instance.props;
        
        showPopup( 
              SHARE_WISHLIST_POPUP_ID,
             { title: __('Share Wishlist') }
             );
    }

    showPopupNewCategory(args, instance) {
        const { showPopup } = args.props;
        var title = __('ADD NEW CATEGPRY');

        return showPopup(
            WISHLIST_POPUP_NEW,
            {
            action: WISHLIST_POPUP_NEW,
            title
        });
    }

    onCateogryNameUpdate(instance, newCategory) {
        const { activeCategory } = instance.state;
        const { showError, editCategoryName } = instance.props;

        if (!activeCategory && activeCategory === 0 && newCategory) {
            return showError("Please enter the category name")
        }

        editCategoryName({
            category_name: newCategory,
            category_id: activeCategory
        }).then(
            () =>
                setTimeout(instance.setState({ editCategoryName, isEdit: false }), 3000 )
        );

    }


    onCategoryFieldEdit(instance, editCategoryName){
        instance.setState({ editCategoryName })
    }

    isEditCategory(instance, categoryName){
        const { isEdit } = instance.state;

        instance.setState({ 
            isEdit: !isEdit, 
            editCategoryName: categoryName
        })
    }

    renderRemoveProductAlartBox(instance, args){
        const { product_id, category_id, product } = args;

        removeItemFromCategory({ product_id: id, category_id: isFoundInCategory, product })
    }

    productEditPopup(instance, product){
        const { id, name } = product;
        const { showPopup } = instance.props;
        instance.setState({ product });

        return showPopup(
            EDIT_PRODUCT_POPUP,
            {
            product,
            title: name
        });
    }

    renderShareWishlistButton(args, callback, instance) {

        if(!isSignedIn()) {
            const {
                isWishlistLoading,
                shareWishlist,
                isWishlistEmpty
            } = instance.props;
    
            const disabled = isWishlistLoading || isWishlistEmpty;
            return (
                <button
                  block="Button"
                  mods={ { isHollow: true } }
                  mix={ { block: 'MyAccountMyWishlist', elem: 'ShareWishlistButton' } }
                  onClick={ shareWishlist }
                  disabled={ disabled }
                >
                    <ShareIcon isPrimary />
                    { __('Share') }
                </button>
            );
        }

        return callback.apply(instance, args);
    }

    onClickDeleteCategory(instance, options){
    const { deleteCategory } = instance.props;
        
        deleteCategory(options).then(
            () =>
            setTimeout(
            instance.setState({
                activeCategory: null,
                isEdit: false,
                wishlistItems: {}
            }), 3000)
        );

    }

    containerFunctions(args, instance) {
        return {
            ...args,
            showPopupNewCategory: showPopupNewCategory.bind(args, instance),
            setActiveCategory: setActiveCategory.bind(args, instance),
            goBackCategoryList: goBackCategoryList.bind(args, instance),
            onCateogryNameUpdate : onCateogryNameUpdate.bind(args, instance),
            onCategoryFieldEdit: onCategoryFieldEdit.bind(args, instance),
            isEditCategory: isEditCategory.bind(args, instance),
            onClickDeleteCategory: onClickDeleteCategory.bind(args,instance),
            showAlartBox: showAlartBox.bind(args, instance),
            productEditPopup: productEditPopup.bind(args, instance)
        }
    }

    containerProps(args, callback, instance) {
        const { wishlistItems } = instance.props;
        const { wishlistItems: stateWishlistItems } = instance.state;
        
        if(instance.getCode && instance.getCode() && isSignedIn()) {
            return {
                ...callback.apply(instance, args),
                isSharedWishlist: true,
                wishlistItems: wishlistItems,
                creatorsName: instance.state.creatorsName
            }
        }

        if(!isSignedIn() && stateWishlistItems) {
            return {
                ...callback.apply(instance, args),
                wishlistItems: instance.state.wishlistItems,
                creatorsName: instance.state.creatorsName
            }
        }

        if(!stateWishlistItems && wishlistItems && !isSignedIn()) {
            return {
                ...callback.apply(instance, args)
            }
        }

        const { 
            wishlistCategories, 
            wishlistItems: allWishListItems, 
            isWishlistLoading 
        } = instance.props;
        
        const { 
            activeCategory, 
            selectedCategoryItems, 
            editCategoryName, 
            isEdit, 
            product 
        } = instance.state;

        return {
            ...callback.apply(instance, args),
            wishlistItems: selectedCategoryItems,
            isLoading: isWishlistLoading,
            allWishListItems,
            editCategoryName,
            wishlistCategories,
            activeCategory,
            product,
            isEdit
        };
    }


    renderCategoryField = ({ category_id, category_name, items = {}, is_default = false }, i, instance) => {
        const { setActiveCategory, isLoading } = instance.props;
        const itemCounts = category_id === 0 ? Object.keys(items).length : Object.values(items).filter(({ wishlist }) => wishlist.categories.includes(category_id)).length;

        return (
            <li
                block="WishlistPopup"
                elem="Items"
                mix={{ block: 'Item', mods: {
                    isPlacehoder: isLoading
                } }}
                key={i}
            >
                <button
                    block="Button"
                    mix={{ block: 'MyAccountMyWishlist', elem: 'Button' }}
                    onClick={ () => isLoading ? {} : setActiveCategory(category_id)}
                >
                    <label id={category_id} >
                        <TextPlaceholder content={ category_name } />
                    </label>
                    <span>
                        <TextPlaceholder 
                          content= { 
                            isLoading ? 
                                '' : 
                                __("%s Products", itemCounts ) 
                            } 
                          />
                    </span>
                </button>
                <div>
                </div>
            </li>
        )
    }

    renderCategories(instance) {
        const { wishlistCategories = [], wishlistItems, allWishListItems, isLoading } = instance.props;
        const itemCounts = Object.entries(wishlistCategories).length;
        const categories = wishlistCategories.filter(({ is_default }) => !is_default) || [];

        if (isLoading) {
            return (
                <ul className="WishlistPopup-tabs">
                    {renderCategoryPlaceholders(instance)}
                </ul>
            );
        }

        if (!itemCounts > 0) {
            return;
        }

        return <ul className="WishlistPopup-tabs">
            {renderCategoryField({
                category_id: 0,
                category_name: __('All Products'),
                items: allWishListItems
            },
                0,
                instance)
            }
            {categories.map(
                ({ category_id, category_name, is_default }, i) =>
                    renderCategoryField({ 
                        category_id, 
                        category_name, 
                        items: allWishListItems, 
                        is_default 
                    },
                        i,
                        instance
                ))
            }
        </ul>
    }

    renderWishlistPopupNewCategory(instance) {
        return (
            <Popup
                id={WISHLIST_POPUP_NEW}
                clickOutside={false}
                mix={{ block: 'MyAccountMyWishlist' }}
            >
                <MyAccountWishlistPopupNewCategroy />
            </Popup>
        )
    }

    renderActionBarNewCategory(instance) {
        const {
            showPopupNewCategory,
            wishlistCategories,
            isMobile
        } = instance.props;

        const itemCounts = Object.entries(wishlistCategories).length;


        return (
            <div block="MyAccountMyWishlist" elem="top" className="main">
                <div block="MyAccountMyWishlist" elem="ActionBar">
                    <div block="MyAccountMyWishlist" elem="ActionBarActionWrapper">
                        <button
                            block="Button"
                            mix={{ block: 'MyAccountMyWishlist', elem: 'Button' }}
                            onClick={showPopupNewCategory}
                        >
                            {__('Add New WishList Category')}
                        </button>
                        {renderWishlistPopupNewCategory(instance)}
                    </div>
                </div>
                <h2 className="MyAccountMyWishlist-Heading">
                    { isMobile ? __('SAVED LISTS') : __('Saved Lists') }
                    <span>{ __('(%s)', itemCounts) }</span>
                </h2>
            </div>
        );
    }

    renderUpdateCategooryField(categoryName, instance) {
        const { onCategoryFieldEdit, onCateogryNameUpdate, editCategoryName, isEditCategory } = instance.props;
        const isDisable = editCategoryName !== categoryName ? false : true;
        
        return (
            <>
                <Field
                    name="MyAccountMyWishlist"
                    type="input"
                    value={ editCategoryName }
                    placeholder="Edit Category Name"
                    onChange={ onCategoryFieldEdit }
                    maxLength='12'
                />
                <button
                    block="MyAccountMyWishlist"
                    // elem="Button"
                    disabled={isDisable}
                    onClick={ () => onCateogryNameUpdate(editCategoryName) }
                >
                    <SubmitIcon />
                </button>
                <button
                    block="MyAccountMyWishlist"
                    onClick={ isEditCategory }
                >
                    <CloseIcon />
                </button>
            </>
        )
    }

    renderAlartBox({category_name, category_id }, instance){
        const { onClickDeleteCategory } = instance.props;
        
        return (
            <Popup
            id={ ALART_POPUP }
            clickOutside={ false }
            mix={ { block: 'CategoryWishlistDelete' } }
          >
                <div className="AlartPoup-Info">{ __('Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή τη λίστα;') }</div>
              <AlartPoup 
               Action = { () => onClickDeleteCategory({category_name, category_id }) }
              />
          </Popup>
        )
    }

    renderWishlistPopup(instance){
        const { product: { id }, allWishListItems, product } = instance.props;
        
        return (
            <Popup
            id={ WISHLIST_POPUP }
            clickOutside={ false }
            mix={ { block: 'WishlistItem-Categories' } }
          >
              <WishlistPopup
               product={ product }
               productsInWishlist= { allWishListItems }
               { ...instance.props } 
               isAccountTab/>
          </Popup>
        )
    }

    getCategoryHeading(instance) {
        const { 
            wishlistCategories, 
            activeCategory, 
            goBackCategoryList, 
            allWishListItems = {}, 
            isEdit, 
            isEditCategory,
            showAlartBox
        } = instance.props;
        
        let heading = {
            category_name: __('All Products'), 
            items: allWishListItems
        };

        let itemCounts = Object.keys(allWishListItems).length;

        if(activeCategory !== 0) {
            heading = Object.values(wishlistCategories).find((category) => category.category_id === activeCategory);
            itemCounts = Object.values(allWishListItems).filter(({ wishlist }) => wishlist?.categories?.includes(heading?.category_id)).length;
        }

        const { category_name, category_id } = heading;

        return (
            <>
                <button
                    block="MyAccountMyWishlist"
                    elem="goBack"
                    onClick={goBackCategoryList}
                >
                    <ChevronIcon direction="left"/>
                    { __('BACK TO SAVED LISTS') }
                </button>
                <h2
                    key={activeCategory}
                    block="MyAccountMyWishlist"
                    elem="Heading"
                >
                    {
                    !isEdit ? 
                        <>
                        { category_name }
                                <button
                                    block="MyAccountMyWishlist"
                                    onClick={ () => isEditCategory( category_name ) }
                                >
                                { activeCategory !== 0 ? <EditIcon /> : null }
                                </button>
                        </>
                        : renderUpdateCategooryField(category_name, instance)
                    }
                    <span
                        block="MyAccountMyWishlist"
                        elem="Heading"
                    >
                        {`(${itemCounts})`}
                    </span>
                    {
                    !isEdit && activeCategory !== 0  ?
                    <button
                    block="MyAccountMyWishlist"
                    onClick={ () => showAlartBox(category_id) }
                >
                    <DeleteIcon/>
                </button>
                : null }
                </h2>
            </>
        );
    }

    renderProduct = (args, callback, instance) => {
        const { isSharedWishlist = false } = instance.props;

        if(!isSignedIn() || isSharedWishlist && isSignedIn()){
            return sharedWishlist(args[0], callback, instance);
        }
        
        const [id, product] = args[0];
        const { 
            isEditingActive, 
            loadingItemsMap, 
            activeCategory, 
            wishlistCategories, 
            allWishListItems, 
            productEditPopup 
        } = instance.props;

        let heading =  {category_name: "", items: {} };

        if(activeCategory !== 0) {
            heading = Object.values(wishlistCategories).find((category) => category.category_id === activeCategory);
        }

        const { category_name } = heading;

        return (
            <WishlistItem
              isSharedWishlist= { isSharedWishlist }
              key={ id }
              productEditPopup= { productEditPopup }
              productsInWishlist= { allWishListItems }
              product={ product }
              isRemoving={ loadingItemsMap[id] }
              isEditingActive={ isEditingActive }
              handleSelectIdChange={ instance.handleSelectIdChange }
              activeCategory= { activeCategory }
              wishlistCategories= { wishlistCategories }
              categoryName= { category_name }
            />
        );
    };

    renderContent = (args, callback, instance) => {
        const {
            isSharedWishlist = false
        } = instance.props;

        if(!isSignedIn() || isSharedWishlist && isSignedIn()){
            return callback.apply(instance, args);
        }
        const {
            isWishlistLoading,
            isWishlistEmpty,
            wishlistItems
        } = instance.props;
        
        if (isWishlistEmpty && !isWishlistLoading || !wishlistItems || !Object.keys(wishlistItems).length > 0) {
            return instance.renderNoProductsFound();
        }

        return callback.apply(instance, args);
    }

    getProductVariant(instance) {
        const {
                product: {
                    variants = []
                }
        } = instance.props;

        return variants[_getVariantIndex(instance)];
    }

    _getVariantIndex(instance) {
        const {
            product: {
                variants = [], 
                wishlist: { sku: itemSku }
            } = {}
        } = instance.props;
        
        return variants.findIndex(({ sku }) => sku === itemSku || itemSku.includes(sku));
    }

    _getProductLinkTo(instance) {
        const {
                product,
                product: {
                    type_id,
                    configurable_options,
                    parent,
                    url
                } = {}
        } = instance.props;

        if (type_id !== CONFIGURABLE) {
            return {
                pathname: url,
                state: { product }
            };
        }

        const variant = getProductVariant(instance);
        if (!variant) {
            return {};
        }
        const { attributes = {} } = variant;

        const parameters = Object.entries(attributes).reduce(
            (parameters, [code, { attribute_value }]) => {
                if (Object.keys(configurable_options).includes(code)) {
                    return { ...parameters, [code]: attribute_value };
                }

                return parameters;
            }, {}
        );


        return parameters
    }
    
    getEditProductPopup(instance) {
        const { product, location, history, activeCategory } = instance.props;
        const { sku: productSKU, id: productID, wishlist } = product;
        var parameters = [];
        var qty = 1;
        var prevSku = '';
        var configurableVariantIndex = -1;

        if(Object.keys(product).length > 0 && wishlist) {
            const { quantity, sku } = wishlist;
            qty = quantity;
            prevSku = sku
            parameters = _getProductLinkTo(instance);
            
            configurableVariantIndex = _getVariantIndex(instance);
        }
        
        return (
            <Popup
                id={ EDIT_PRODUCT_POPUP }
                clickOutside={false}
                mix={{ block: 'WishlistItem-Edit' }}
            >
                <ProductPopup
                    configurableVariantIndex = { configurableVariantIndex }
                    activeCategory= { activeCategory }
                    isEditWishlistItem
                    history={history}
                    qty = { qty }
                    prevSku = { prevSku }
                    location={ location }
                    parameters= { parameters }
                    // match={match}
                    productSKU={ productSKU }
                    productID={ productID }
                    key={ productID }
                />
            </Popup>
        );
    }

    renderProducts = (args, callback, instance) => {
        const {
            isWishlistLoading,
            isWishlistEmpty,
            wishlistItems = {},
            isSharedWishlist
        } = instance.props;

        if (isWishlistLoading && isWishlistEmpty && typeof(wishlistItems) !== 'object') {
            return instance.renderPlaceholders();
        }

        return callback(...args);
    }


    renderRemoveProductAlartBox(instance){
        const { product: { id }, removeItem } = instance.props;
        
        return (
            <Popup
            id={ ALART_POPUP_REMOVE }
            clickOutside={ false }
            mix={ { block: 'WishlistItem-Remove' } }
          >
                <div className="AlartPoup-Info">{ __('Είστε βέβαιοι ότι θέλετε να διαγράψετε το προϊόν από τη λίστα;') }</div>
                <AlartPoup
                Action = { () => removeItem() }
                />
          </Popup>
        )
    }

    renderActionBar = (args, callback, instance) => {
        const { wishlistItems } = instance.props;
        
        const allCategoryProducts = Object.entries(wishlistItems).length;

        if(!isSignedIn() && !allCategoryProducts > 0) { 
            return null;
        }

        return callback.apply(instance, args);

    }

    renderCategoryPlaceholders = (_instance) => {
        const {
            numberOfPlaceholders = 2,
            isLoading
        } = _instance.props;
    
        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                renderCategoryField(
                    {},
                    i,
                    _instance
                ))
        );
    }

    renderPlaceholder(instance) {
        const {
            showPopupNewCategory
        } = instance.props;

        return (
            <div block="MyAccountMyWishlist" elem="top" className="empty">
                <h2>{ __("Η λίστα αγαπημένων σας είναι άδεια") }</h2>
                <p>{ __("Μπορείτε να προσθέσετε προϊόντα στη λίστα αγαπημένων σας πατώντας στο εικονίδιο που είναι πάνω στη φωτογραφία του προϊόντος.")}</p>
                <div block="MyAccountMyWishlist" elem="ActionBarActionWrapper">
                    <button
                        block="Button"
                        mix={{ block: 'MyAccountMyWishlist', elem: 'Button' }}
                        onClick={showPopupNewCategory}
                    >
                        {__('Add New WishList Category')}
                    </button>
                    {renderWishlistPopupNewCategory(instance)}
                </div>
            </div>
        )
    }


    render(args, callback, instance) {
        const {
            activeCategory,
            wishlistItems= {},
            allWishListItems= {},
            isLoading,
            wishlistCategories= {},
            isEditingActive,
            isSharedWishlist = false
        } = instance.props;

        if(!isSignedIn() || isSharedWishlist){
            return callback.apply(instance, args);
        }
        
        const selectCategoryProducts = Object.entries(wishlistItems)?.length;
        const allCategoryProducts = Object.entries(allWishListItems)?.length;
        const wishListCategories = Object.entries(wishlistCategories)?.length;

        if(!allCategoryProducts > 0 && wishListCategories === 1 || !wishListCategories > 0 && !isLoading) {
            return (
                <div block="MyAccountMyWishlist">
                    {/* <Loader isLoading={ isLoading } /> */}
                    { renderPlaceholder(instance) }
                </div>
            );
        }
        
        if (activeCategory || activeCategory == 0) {

            const heading = activeCategory !== 0 ? 
            Object.values(wishlistCategories).filter((category) => category.category_id === activeCategory) : 
                [ {category_name: __('All Products'), items: allWishListItems} ];
                const { category_name, category_id , items } = heading[0];

            return [
                <>
                    {renderAlartBox({ category_name, category_id }, instance)}
                    {getEditProductPopup(instance)}
                    {renderWishlistPopup(instance)}
                    {renderRemoveProductAlartBox(instance)}
                    <div block="MyAccountMyWishlist">
                        {instance.renderShareWishlist()}
                        <div block="MyAccountMyWishlist" elem="top">
                            {getCategoryHeading(instance)}
                            {selectCategoryProducts > 0 ?
                                instance.renderActionBar() :
                                null
                            }
                        </div>
                        {instance.renderContent()}
                    </div>
                </>
            ]
        }

        return (
            <div block="MyAccountMyWishlist">
                {/* <Loader isLoading={ isLoading } /> */}
                {renderActionBarNewCategory(instance)}
                { renderCategories(instance) }
            </div>
        )
    }

}

const {
    state,
    render,
    mapStateToProps,
    mapDispatchToProps,
    componentDidMount,
    componentDidUpdate,
    containerProps,
    containerFunctions,
    renderCategories,
    renderCategoryField,
    renderActionBar,
    renderActionBarNewCategory,
    renderWishlistPopupNewCategory,
    renderWishlistPopup,
    showPopupNewCategory,
    setActiveCategory,
    getCategoryHeading,
    goBackCategoryList,
    onCateogryNameUpdate,
    onCategoryFieldEdit,
    isEditCategory,
    onClickDeleteCategory,
    renderProduct,
    renderUpdateCategooryField,
    getDerivedStateFromProps,
    renderAlartBox,
    renderRemoveProductAlartBox,
    _getVariantIndex,
    _getProductLinkTo,
    getProductVariant,
    showAlartBox,
    renderContent,
    productEditPopup,
    getEditProductPopup,
    renderPlaceholder,
    shareWishlist,
    removeAll,
    addAllToCart,
    renderShareWishlistButton,
    defaultProps,
    renderProducts,
    renderCategoryPlaceholders
} = new MyAccountMyWishlistPlugin();

export default {
    'Component/MyAccountMyWishlist/Component': {
        'member-function': {
            renderCategories,
            renderCategoryField,
            renderActionBarNewCategory,
            renderWishlistPopupNewCategory,
            renderWishlistPopup,
            getCategoryHeading,
            renderAlartBox,
            renderUpdateCategooryField,
            renderRemoveProductAlartBox,
            _getVariantIndex,
            _getProductLinkTo,
            getProductVariant,
            renderProduct,
            renderProducts,
            renderContent,
            getEditProductPopup,
            renderPlaceholder,
            renderActionBar,
            renderShareWishlistButton,
            render
        }
    },
    'Component/MyAccountMyWishlist/Container': {
        'member-property': {
            state
        },
        'static-member': {
            getDerivedStateFromProps,
            defaultProps
        },
        'member-function': {
            renderCategoryPlaceholders,
            componentDidMount,
            componentDidUpdate,
            containerProps,
            containerFunctions,
            showPopupNewCategory,
            setActiveCategory,
            onCateogryNameUpdate,
            goBackCategoryList,
            isEditCategory,
            onClickDeleteCategory,
            shareWishlist,
            showAlartBox,
            productEditPopup,
            onCategoryFieldEdit,
            removeAll,
            addAllToCart
        }
    },
    'Route/WishlistSharedPage/Container/wishlistSharedContainer': {
        'member-function': { 
            containerProps,
        },
        'static-member': {
            defaultProps
        },
    },
    'Route/WishlistSharedPage/Container/mapDispatchToProps': {
        function: [
            {
                position: 120,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/MyAccountMyWishlist/Container/mapDispatchToProps': {
        function: [
            {
                position: 120,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/MyAccountMyWishlist/Container/mapStateToProps': {
        function: [
            {
                position: 120,
                implementation: mapStateToProps
            }
        ]
    }
};