import Html from 'SourceComponent/Html';
import history from 'SourceUtil/History';
import { appendWithStoreCode } from 'SourceUtil/Url';
import { renderIsGift } from "../route/FreeGiftCategoryPage/FreeGiftCategoryPage.config";

const renderPriceWithSchema = (args, callback, instance) => {
    const { product } = instance.props;

    if(renderIsGift(product)) {
        return instance.renderShortDescription()
    }

    return callback.apply(instance, args);
}

const renderBrand = (args, callback, instance) => {
    const { product } = instance.props;

    if (renderIsGift(product)) {
        return (
            <>
                <div className="ProductActions-Tags">
                    <div className="ProductActions-Tag">{ __('FREE GIFT') }</div>
                </div>
                {callback.apply(instance, args)}
            </>
        );
    }

    return callback.apply(instance, args);
}

const renderReviews = (args, callback, instance) => {
    const { product } = instance.props;

    if (renderIsGift(product)) {
        return null;
    }

    return callback.apply(instance, args);
}

const renderShortDescription = (args, callback, instance) => {
    const {
        product: {
            attributes = {}
        } = {},
        brand : _brand,
        showOnlyIfLoaded,
        product
    } = instance.props;

    let brand = attributes !== undefined
    && attributes.brand !== undefined
    && attributes.brand.attribute_options[attributes.brand.attribute_value] !== undefined ?
        attributes.brand.attribute_options[attributes.brand.attribute_value].label : null;

    if (renderIsGift(product)) {
        return (
            <>
                <div className={'ProductActions-ShortDescription_Gift-title'}>{ __('GIFT WITH YOUR PURCHASE') }</div>
                { instance.renderGiftDescription() }
                { callback.apply(instance, args) }
            </>
        );
    }

    return callback.apply(instance, args);
}

const renderShortDescriptionContent = (args, callback, instance) => {
    const { product: { short_description }, product } = instance.props;
    const { html } = short_description || {};

    if (renderIsGift(product)) {
        return (
            <div block="ProductActions" elem="ShortDescription" className={'isGift'}>
                <div itemProp="Short Description">
                    { html ? <Html content={ html } /> : <p><TextPlaceholder length="long" /></p> }
                </div>
            </div>
        );
    }

    return callback.apply(instance, args);
}

const renderReviewSection = (args, callback, instance) => {
    const { product } = instance.props;

    if (renderIsGift(product)) {
        return (
            <div
                block="ProductActions"
                elem="Reviews"
                className={'isGift'}
            >
                { instance.renderReviews() }
                <div block="ProductActions" elem="ActionButtons">
                    { instance.renderProductShareButton() }
                    { instance.renderProductWishlistButton() }
                </div>
            </div>
        );
    }

    return callback.apply(instance, args);
}

const renderGiftDescription = (args, callback, instance) => {
    const { product: { description, id } } = instance.props;
    const { html } = description || {};

    if (!html && id) {
        return null;
    }

    return (
        <section
            block="ProductActions"
            elem="Section"
            mods={ { type: 'short' } }
            aria-label="Product short description"
        >
            { instance.renderGiftDescriptionContent() }
        </section>
    );
}

const renderGiftDescriptionContent = (args, callback, instance) => {
    const { product: { description } } = instance.props;
    const { html } = description || {};

    return (
        <div block="ProductActions" elem="ShortDescription">
            <div itemProp="description">
                { html ? <Html content={ html } /> : <p><TextPlaceholder length="long" /></p> }
            </div>
        </div>
    );
}

const renderProductWishlistButton = (args, callback, instance) => {
    const { product } = instance.props;

    if (renderIsGift(product)) {
        return null;
    }

    return callback.apply(instance, args);
}

const renderFindStoreHtml = (args, callback, instance) => {
    const { product } = instance.props;

    if (renderIsGift(product)) {
        return null;
    }

    return callback.apply(instance, args);
}

const renderConfigurableAttributes = (args, callback, instance) => {
    const { product } = instance.props;

    if (renderIsGift(product)) {
        return null;
    }

    return callback.apply(instance, args);
}


const renderAddToCartActionBlock = (args, callback, instance) => {
    const { product : { call_to_action }, product } = instance.props;

    if(call_to_action && call_to_action.url) {
        const {
            isLoading,
            disabled,
        } = instance.props;
        return(
            <button
                onClick={ () => history.push({ pathname: appendWithStoreCode(call_to_action.url.replace(window.location.origin,'')) }) }
                block="Button"
                mods={ { isLoading } }
                disabled={ isLoading || disabled }
                className={'FreeGift'}
            >
                <span>{ __('Get it') }</span>
            </button>
        );
    }

    if(renderIsGift(product)) {
       return null;
    }

    return callback.apply( instance, args );

}


const renderProductAlerts = (args, callback, instance) => {
    const { product } = instance.props;

    if(renderIsGift(product)) {
       return null;
    }

    return callback.apply( instance, args );

}


export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderAddToCartActionBlock,
            renderProductAlerts,
            renderBrand,
            renderReviews,
            renderReviewSection,
            renderShortDescription,
            renderShortDescriptionContent,
            renderGiftDescriptionContent,
            renderGiftDescription,
            renderProductWishlistButton,
            renderConfigurableAttributes,
            renderFindStoreHtml,
            renderPriceWithSchema
        }
    },
    'Component/ProductActions/Container': {
        'member-function': {
            // containerFunctions
        }
    },
}
