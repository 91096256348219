import { Field } from "SourceUtil/Query";
import { isSignedIn } from "SourceUtil/Auth";
import ProductListQuery from "Query/ProductList.query";
import BetterWishlist from "../../query/BetterWishlist.query";

const getWishlistQuery = (args, callback, instance) => {
  if (!isSignedIn() && args?.length > 0) {
    return getWishlistGuestQuery(args, callback, instance);
  }

  return callback.apply(instance, args);
};

const getWishlistGuestQuery = (args, callback, instance) => {
  const sharingCode = args[0];

  const field = new Field("s_wishlistGuest")
    .setAlias("wishlist")
    .addFieldList(instance._getWishlistFields());

  if (sharingCode) {
    field.addArgument("sharing_code", "ID", sharingCode);
  }

  return field;
};
const getWishListCategories = () => {
  return new Field("wishlist_categories").addFieldList(
    BetterWishlist._getWishlistCategory()
  );
};

const _getWishlistItemsFields = (args, callback, instance) => {
  if (isSignedIn()) {
    return [...callback.apply(instance, args), "categories"];
  }

  return callback.apply(instance, args);
};

const _getWishlistFields = (args, callback, instance) => {
  let wishlistFields = [
    "updated_at",
    "items_count",
    "creators_name",
    instance._getItemsField(),
  ];

  if (isSignedIn()) {
    wishlistFields = wishlistFields.concat(instance.getWishListCategories());
  }

  return wishlistFields;
};

const getRemoveProductFromGuestWishlistMutation = (
  args,
  callback,
  instance
) => {
  const item_id = args[0];

  return new Field("s_removeProductFromGuestWishlist")
    .setAlias("removeProductFromWishlist")
    .addArgument("itemId", "ID!", item_id);
};

const getSaveWishlistItemGuestMutation = (args, callback, instance) => {
  const wishlistItem = args[0];

  return new Field("s_saveWishlistItemGuest")
    .setAlias("saveWishlistItemGuest")
    .addArgument("wishlistItem", "WishlistItemInput!", wishlistItem)
    .addFieldList(instance._getWishlistItemsFields());
};

const getClearGuestWishlist = () => {
  return new Field("s_clearGuestWishlist").setAlias("clearWishlist");
};

const getClearWishlist = (args, callback, instance) => {
  const [options] = args;
  return new Field("s_clearWishlist")
    .addArgument("input", "ClearWishlistInput", options)
    .setAlias("clearWishlist");
};

const moveAllItemsToWishlist = (args, callback, instance) => {
  const [options] = args;
  return new Field("moveAllItemsToWishlist")
    .addArgument("input", "ClearWishlistInput", options)
    .setAlias("moveAllItemsToWishlist");
};

const getMoveGuestWishlistToCart = (args) => {
  const [guestId] = args;

  const field = new Field("s_moveGuestWishlistToCart")
    .setAlias("moveWishlistToCart")
    .addArgument("guestCartId", "ID", guestId);

  // if (sharingCode) {
  //     field.addArgument('sharingCode', 'ID', sharingCode);

  //     if (!isSignedIn()) {
  //         const guestQuoteId = getGuestQuoteId();
  //         field.addArgument('guestCartId', 'ID', guestQuoteId);
  //     }
  // }

  return field;
};

const _getProductField = () => {
  ProductListQuery.options.isCart = true;
  ProductListQuery.options.isForLinkedProducts = true;

  const productQuery = new Field("product").addFieldList(
    ProductListQuery._getProductInterfaceFieldsOptz()
  );

  ProductListQuery.options.isForLinkedProducts = false;
  ProductListQuery.options.isCart = false;

  return productQuery;
};

export default {
  "Query/Wishlist": {
    "member-function": {
      getWishListCategories,
      _getProductField,
      getWishlistQuery,
      getClearGuestWishlist,
      _getWishlistItemsFields,
      _getWishlistFields,
      getWishlistGuestQuery,
      getSaveWishlistItemGuestMutation,
      getRemoveProductFromGuestWishlistMutation,
      getMoveGuestWishlistToCart,
      getClearWishlist,
      moveAllItemsToWishlist,
    },
  },
};
