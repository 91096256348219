import { lazy } from 'react';

import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';

export const GiftCardCheckComponent = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-video" */
    '../component/GiftCardCheck'
));

export const GiftCardStoresMap = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-video" */
    '../component/GiftCardStoresMap'
));

const GiftCardCheck = 'GiftCardCheck';
const GiftCardStores = 'GiftCardStores';

const renderContent = (args, callback, instance) => {
    const {
        type
    } = instance.props;

    const {
        component: Widget, fallback
    } = instance.renderMap[type] || {};

    if (Widget !== undefined && type === GiftCardCheck) {
        return (
            <RenderWhenVisible fallback={ fallback }>
                <Widget
                  { ...instance.props }
                />
            </RenderWhenVisible>
        );
    }

    return callback.apply(instance, args);
};

export const renderMap = (props, instance) => ({
    ...props,
    [GiftCardCheck]: {
        component: GiftCardCheckComponent
    },
    [GiftCardStores]: {
        component: GiftCardStoresMap
    }
});

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            renderMap
        },
        'member-function': {
            renderContent
        }
    }
};
