import { KLARNA } from 'Component/CheckoutPayments/CheckoutPayments.config';
import Klarna from '../component/Klarna';
import {
    KlarnaContainer
} from "../component/Klarna/Klarna.container";
export class CheckoutPaymentsPlugin {
    renderKlarnaPayment(args, callback, instance) {
        const { setOrderButtonEnableStatus, billingAddress, selectedPaymentCode } = instance.props;
        return <Klarna setOrderButtonEnableStatus={ setOrderButtonEnableStatus }  billingAddress={billingAddress} selectedPaymentCode={selectedPaymentCode} />;
    }
    dataMap = (originalMember, instance) => ({
        ...originalMember,
        [KLARNA]: this.getKlarnaData.bind(this),
        ['klarna_pay_now']: this.getKlarnaData.bind(this),
        ['klarna_pay_later']: this.getKlarnaData.bind(this),
        ['klarna_pay_over_time']: this.getKlarnaData.bind(this)
    });

    paymentRenderMap = (originalMember, instance) => ({
        ...originalMember,
        ['klarna_pay_now']: instance.renderKlarnaPayment.bind(instance),
        ['klarna_pay_later']: instance.renderKlarnaPayment.bind(instance),
        ['klarna_pay_over_time']: instance.renderKlarnaPayment.bind(instance)
    });

    getKlarnaData() {
        return { asyncData: KlarnaContainer.authorize() };
    }
}

const {
    renderKlarnaPayment,
    dataMap,
    paymentRenderMap
} = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderKlarnaPayment
        },
        'member-property': {
            paymentRenderMap
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap
        }
    }
};

export default config;
