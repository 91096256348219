import {Field} from 'SourceUtil/Query';
/** @namespace Query/CustomAttributeQuery */
export class CustomAttributeQuery {

    getCustomerAttribute(option) {
        return new Field('customAttributeMetadata')
            .addArgument('attributes', '[AttributeInput!]!', option)
            .addFieldList([this.getItemsFields()]);
    }

    getStringifyJsonObject(option) {
        return JSON.stringify(option);
    }

    getItemsFields() {
        return new Field('items')
            .addFieldList(this.getAttributeFields());
    }

    getAttributeFields() {
        return [
            'attribute_label',
            'attribute_code',
            'attribute_type',
            'entity_type',
            'input_type',
            this.getAttributeOptionsFields()
        ]

    }

    getAttributeOptionsFields() {
        return new Field('attribute_options')
            .addFieldList([
                'label',
                'value',
                this.getSwatchDataFields()
            ]);
    }

    getSwatchDataFields() {
        return new Field('swatch_data')
            .addFieldList([
                'type',
                'value'
            ]);
    }


}

export default new CustomAttributeQuery();
