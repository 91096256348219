import { Field } from 'Util/Query';

/** @namespace Mageguidepwahondosc/Storelocator/Query/StoreLocator/Query/StoreLocatorQuery */
export class StoreLocatorQuery {
    getStoreLocatorDataQuery(options) {
        const field = new Field('getLocations')
            .addFieldList(this._getLocationField());

        if (options && options.storeIds) {
            field.addArgument('storeIds', '[Int]', options.storeIds.split(','));
        }

        return field;
    }

    _getLocationField() {
        return [
            'total_count',
            this._getRegionField(),
            this._getStoreLocatorSEOFields(),
            this._getCategoryItemsField()
        ];
    }

    _getRegionField() {
        return new Field('regions')
            .addFieldList(this._getRegionFieldItems());
    }

    _getRegionFieldItems() {
        return [
            'id',
            'name'
        ];
    }

    _getCategoryItemsField() {
        return new Field('items')
            .addFieldList(this._getCategoryItemsFieldItems());
    }

    _getCategoryItemsFieldItems() {
        return [
            'category_id',
            'name',
            'website',
            'icon',
            this._getStoresField()
        ];
    }

    _getStoresField() {
        return new Field('stores')
            .addFieldList(this._getStoresFieldItems());
    }

    _getStoresFieldItems() {
        return [
            'store_id',
            'category_id',
            'name',
            'address',
            'store_image',
            'postcode',
            'city',
            'region',
            'country',
            'phone',
            'email',
            'fax',
            'website',
            'open_hours',
            'static_block_1',
            'static_block_2',
            'url_alias',
            'description',
            'meta_description',
            'meta_keywords',
            'embeded_map',
            'lat',
            'lng',
            'zoom'
        ];
    }

    _getStoreLocatorSEOFields() {
        return new Field('store_locator_seo')
            .addFieldList([
                'meta_title',
                'meta_description',
                'meta_keywords'
            ]);
    }
}

export default new StoreLocatorQuery();
