import {Field} from 'Util/Query';
import {isSignedIn} from "Util/Auth";


export class GiftCardDashBoardQuery {
    getCodesFromAccount(){
        if (!isSignedIn){
            return ;
        }
        return new Field('giftCardsFromAccount')
            .addFieldList(this._getListFields());
    }

    addCardQuery (code) {
        const  mutation = new Field('addCardInAccount')
            .addArgument('code','String!',code)
            .addField('added');
        if (!isSignedIn){
            return ;
        }
        return mutation;
    }

    removeCardQuery(code) {
        const  mutation = new Field('removeCardFromAccount')
            .addArgument('code','String!',code)
            .addField('removed');
        if (!isSignedIn){
            return ;
        }
        return mutation;
    }
    _getListFields(){
        return [
            'id',
            'code',
            'status',
            'balance',
            'expiredDate'
        ];
    }
}

export default new GiftCardDashBoardQuery();
