import {
    RADIO
} from 'SourceComponent/ProductBundleItem/ProductBundleItem.config';
import Image from 'SourceComponent/Image';
import Html from 'SourceComponent/Html';
import {lazy} from "react";
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import ProductPrice from '@scandipwa/scandipwa/src/component/ProductPrice';

export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
    ));

// const renderMap = (_args, _instance) => ({
//         ..._args,
//         [RADIO]: _instance.renderDropdownValues.bind(_instance)
//     });

const getDropdownOptions = (_args, _callback, _instance) => {
    const values = _args[0];
    const {
        currencyCode
    } = _instance.props;

    return values.reduce((acc, {
        id,
        label,
        price_type,
        quantity,
        can_change_quantity,
        finalOptionPrice,
        price,
        position,
        product = {}
        // product: {
        //     name
        // } = {}
    }) => {
        const image_uri = product && product.thumbnail ? product.thumbnail.url : '';
        const optionLabel = product && product.name ? label || product.name : label || '';
        const optionDescription = product?.description?.html;
        const optionStock = product && product.stock_status ? product.stock_status : '';
        const subLabel = product && product.price_range ? _instance.renderProductPrice(product) : '';
        const dropdownLabel = !can_change_quantity ? `${ quantity } x ${ optionLabel } ` : `${ optionLabel } `;
        const small_image = product && product.small_image ? product?.small_image?.url : '';
        
        acc.push({
            id,
            name: optionLabel,
            description: optionDescription,
            stock: optionStock,
            value: id,
            label: dropdownLabel,
            subLabel,
            sort_order: position,
            image_uri,
            small_image
        });

        return acc;
    }, []);
}



const renderImageAttribute = (option, _callback, _instance) => {

    const { image_uri, name, value, stock } = option;
    const {
        selectedDropdownValue,
        setDropdownValue
    } = _instance.props;

    const isSelected = value === selectedDropdownValue;

    const style = {
        '--option-is-selected': isSelected ? 1 : 0
    };

    if (stock === 'OUT_OF_STOCK') {
        return (
            <button
                style = { style }
                onClick={ () => setDropdownValue(value) }
                block="ProductAttributeValue"
                disabled
            >
                <UniversalIcon
                    className="ProductAttributeValue-MediaImage"
                    src={ image_uri }
                    alt={ name }
                />
            </button>
        );
    }

    return (
        <button
          style = { style }
          onClick={ () => setDropdownValue(value) }
          block="ProductAttributeValue"
        //   mods={ { isNotAvailable } }
        //   onClick={ this.clickHandler }
        //   aria-hidden={ isNotAvailable }
        //   mix={ mix }
        >
        <UniversalIcon
          className="ProductAttributeValue-MediaImage"
          src={ image_uri }
          alt={ name }
        />
        </button>
    );
}

const renderProductPrice = (args, _callback, _instance) => {
    const [ product ] = args;
    const {
        price_range,
        price_tiers = []
    } = product;

    if (!price_range) {
        return <TextPlaceholder />;
    }

    return (
        <div block="ProductCard" elem="PriceWrapper">
            <ProductPrice
                price={ price_range }
                price_tiers={ price_tiers }
                mix={ { block: 'ProductCard', elem: 'Price' } }
            />
        </div>
    );
}

const renderPriceItem = (args, _callback, _instance) => {
    const [id, subLabel] = args;
    const {
        selectedDropdownValue
    } = _instance.props;

    if (id !== selectedDropdownValue) {
        return null;
    }

    return (
        <div block="ProductBundleItem" elem="SelectedSubLabel">
            { subLabel }
        </div>
    );
}


const renderOptionDropdownValues = (_args, _callback, _instance) => {
    const values = _args[0];

    const {
        optionType,
        option: { options }, 
        getDropdownOptions,
        selectedDropdownValue
    } = _instance.props;

    if(optionType !== RADIO) {
        return _callback.apply(_instance, _args);
    }

    const dropdownOptions = getDropdownOptions(values);
    const activeProduct = selectedDropdownValue != 0 ? 
            Object.values(dropdownOptions).filter( ({ id }) => id == selectedDropdownValue)[0] : dropdownOptions[selectedDropdownValue];

    return (
        <div block="ProductBundleItem" elem="Swatches">
            <div block="ProductBundleItem" elem="SelectedName">
                <h3 itemprop="name" block="ProductBundleItem" elem="ProductTitle">{ activeProduct.name }</h3>
            </div>
            { activeProduct.description ? <div block="ProductBundleItem" elem="SelectedDesc"><Html content={ activeProduct.description } /></div> : '' }
            <div block="ProductBundleItem" elem="SelectedImage">
                <Image
                    mix={{ block: 'ProductBundleItem', elem: 'FigureImage' }}
                    alt="alt"
                    src={ activeProduct.small_image }
                    isPlaceholder={!activeProduct ? true : false}
                />
            </div>
            <div block="ProductBundleItem" elem="AvailableOptions">
                { dropdownOptions.map( (option) => renderImageAttribute(option, _callback, _instance)) }
            </div>
            { _instance.renderPriceItem(activeProduct.id, activeProduct.subLabel) }
            { options.map(_instance.renderQtyInput) }
        </div>
    );
}


export default {
    'Component/ProductBundleItem/Component': {
        'member-property': {
            // renderMap: renderMap
        },
        'member-function': {
            renderOptionDropdownValues,
            renderImageAttribute,
            renderPriceItem
        }
    },
    'Component/ProductBundleItem/Container': { 
        'member-function': {
            getDropdownOptions,
            renderProductPrice
        }
    }
}; 