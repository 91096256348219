import { lazy } from 'react';
import { FREEGIFT_SLIDER  } from '../component/FreeGiftProductWidget/FreeGiftProductWidget.config';
export const FreeGiftProductWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-product-list" */
    '../component/FreeGiftProductWidget'
));

export const renderMap = (props, instance) => {
    return {
        ...props,
        [FREEGIFT_SLIDER]: {
            component: FreeGiftProductWidget
        }
    }
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': renderMap,
        }
    }
};