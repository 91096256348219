const _getStoreConfigFields = (args, callback, instance) => {
    return [
        ...callback(...args),
        'brand_attributes',
        'brand_small_placeholder'
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': _getStoreConfigFields
        }
    }
};
