import { CheckoutDeliveryOptions as SourceCheckoutDeliveryOptions } from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.component';

/** @namespace Component/CheckoutDeliveryOptions/Component */
export class CheckoutDeliveryOptions extends SourceCheckoutDeliveryOptions {
    renderHeading() {
        return (
            <h2 block="Checkout" elem="Heading" id="CheckoutDeliveryOptions-heading">
                { __('SHIPPING METHOD') }
            </h2>
        );
    }
}

export default CheckoutDeliveryOptions;
