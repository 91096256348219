import { lazy } from 'react';
import TextPlaceholder from 'Component/TextPlaceholder';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import Html from 'Component/Html';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

export const ChevronIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/ChevronIcon'
));


export class ExpandableContent extends SourceExpandableContent {
    toggleExpand = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => this.scrollToExpandedContent()
        );
    };

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, subHeading, mix, id } = this.props;

        if(subHeading) {
            return (
                <div
                    role="button"
                    tabIndex={ 0 }
                    block="ExpandableContent"
                    elem="Button"
                    mods={{ isContentExpanded }}
                    mix={{ ...mix, elem: 'ExpandableContentButton' }}
                    onClick={this.toggleExpand}
                    onKeyDown={this.toggleExpand}
                    id={id}
                >
                    <div
                        block="ExpandableContent"
                        elem="Heading"
                        mix={{ ...mix, elem: 'ExpandableContentHeading' }}
                        id={id}
                    >
                        { typeof heading === 'string' ? (
                            <Html content={ heading } />
                        ) : (
                            heading
                        ) }
                        <span block="ExpandableContent"
                              elem="SubHeading">
                            { typeof subHeading === 'string' ? (
                                <Html content={ subHeading } />
                            ) : (
                                subHeading
                            ) }
                        </span>
                    </div>
                    { this.renderButtonIcon() }
                </div>
            );
        }

        if(heading.includes("<span>")) {
            return (
                <div
                    role="button"
                    tabIndex={ 0 }
                    block="ExpandableContent"
                    elem="Button"
                    mods={ { isContentExpanded } }
                    mix={ { ...mix, elem: 'ExpandableContentButton' } }
                    onClick={ this.toggleExpand }
                    onKeyDown={ this.toggleExpand }
                >
                    <div
                        block="ExpandableContent"
                        elem="Heading"
                        mix={{ ...mix, elem: 'ExpandableContentHeading' }}
                        id={id}
                    >
                        { typeof heading === 'string' ? (
                            <Html content={ heading } />
                        ) : (
                            heading
                        ) }
                    </div>
                    { this.renderButtonIcon() }
                </div>
            );
        }

        return (
            <div
                role="button"
                tabIndex={ 0 }
                block="ExpandableContent"
                elem="Button"
                mods={ { isContentExpanded } }
                mix={ { ...mix, elem: 'ExpandableContentButton' } }
                onClick={ this.toggleExpand }
                onKeyDown={ this.toggleExpand }
            >
                <div
                    block="ExpandableContent"
                    elem="Heading"
                    mix={{ ...mix, elem: 'ExpandableContentHeading' }}
                    id={id}
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length="medium" />
                    ) : (
                        heading
                    ) }
                </div>
                { this.renderButtonIcon() }
            </div>
        );
    }

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <div className="MinusIcon" />;
        }

        return <div className="AddIcon" />;
    }

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { isArrow } = this.props;

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? TOP : BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }
}
export default ExpandableContent;
