import { CheckoutQuery as SourceCheckoutQuery } from 'SourceQuery/Checkout.query';
import { isSignedIn } from 'Util/Auth';

export class CheckoutQuery extends SourceCheckoutQuery{

    getPlaceOrderMutation(guestCartId,saveShippingAddress,saveBillingAddress) {
        let mutation = super.getPlaceOrderMutation(guestCartId,saveShippingAddress,saveBillingAddress);
        if(isSignedIn()){
            mutation.addArgument('saveShippingAddress', 'Boolean', saveShippingAddress);
            mutation.addArgument('saveBillingAddress', 'Boolean', saveBillingAddress);
        }
        return mutation;
    }
}

export default new CheckoutQuery();
