import {
    GET_ENHANCED_FORM_DATA,
    UPDATE_CUSTOM_DATA_IS_LOADING,
    GET_ENHANCED_FORM_FIELDS
} from './EnhacnedRegistration.action';
import BrowserDatabase from "SourceUtil/BrowserDatabase/BrowserDatabase";

export const getInitialState = () => ({
    customData: {},
    customAttributeMetadata: [],
    isLoading: false
});

export const getCustomData = (state = getInitialState(), action) => {
    switch (action.type) {
        case GET_ENHANCED_FORM_DATA:
            return {
                ...state,
                customData: action.customData
            };
        case GET_ENHANCED_FORM_FIELDS:
            return {
                ...state,
                customAttributeMetadata: action.customAttributeMetadata
            };
        case UPDATE_CUSTOM_DATA_IS_LOADING:
            const { isLoading } = action;

            return {
                ...state,
                isLoading
            };
        default:
            return state;
    }
};

export default getCustomData;
