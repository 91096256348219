import { showNotification } from 'SourceStore/Notification/Notification.action';
import { fetchMutation, fetchQuery } from 'SourceUtil/Request';

import ProductReturnQuery from '../../query/ProductReturn.query';
import { getReturnList, setLoading, getRmaConfig } from './Return.action';

/** @namespace Rma/Store/Return/Dispatcher/ReturnDispatcher */
export class ReturnDispatcher {
    /**
     * Request returns
     * @param dispatch
     * @returns {*}
     */

     getRmaConfiguration (dispatch) {
        const query = ProductReturnQuery.getRmaConfiguration();
        dispatch(setLoading(true));

        return fetchQuery(query).then(
            /** @namespace Rma/Store/Return/Dispatcher/fetchQuery/then */
            ({ getAmRmaSettings } ) => {
                dispatch(setLoading(false));
                dispatch(getRmaConfig(getAmRmaSettings, false));
            },
            /** @namespace Rma/Store/Return/Dispatcher/fetchQuery/then */
            (error) => {
                dispatch(setLoading(false));
                dispatch(showNotification('error', error[0].message));
            }
        );
    }

    requestReturns(dispatch) {
        const query = ProductReturnQuery.getReturnList();
        dispatch(setLoading(true));

        return fetchQuery(query).then(
            /** @namespace Rma/Store/Return/Dispatcher/fetchQuery/then */
            ({ customer: amrma_returns }) => {
                dispatch(setLoading(false));
                dispatch(getReturnList(amrma_returns, false));
            },
            /** @namespace Rma/Store/Return/Dispatcher/fetchQuery/then */
            (error) => {
                dispatch(setLoading(false));
                dispatch(showNotification('error', error[0].message));
            }
        );
    }

    /**
     * Send comment
     * @param request_id
     * @param comment
     * @returns {*}
     */
    sendComment(request_id, comment = '') {
        const mutation = ProductReturnQuery.sendRmaComment({ request_id, comment });

        return fetchMutation(mutation);
    }
}

export default new ReturnDispatcher();
