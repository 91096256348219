/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Popup from "Component/Popup";
import Field from 'Component/Field';
import Form from 'Component/Form';
import { ALART_POPUP_MY_ACCOUNT_OVERLAY } from './CheckoutSuccessCreateAccount.config';
import AlartPoup from '@mageguide/better-wishlist/src/component/AlartPoup';
import './CheckoutSuccessCreateAccount.style.scss';

/** @namespace Component/CheckoutSuccessCreateAccount/Component */
export class CheckoutSuccessCreateAccount extends PureComponent {
    static propTypes = {
        onCreateAccountAttempt: PropTypes.func.isRequired,
        onCreateAccountSuccess: PropTypes.func.isRequired,
        showTaxVatNumber: PropTypes.bool.isRequired,
        vatNumberValidation: PropTypes.array.isRequired,
        newsletterActive: PropTypes.bool.isRequired,
        isSubscriptionSelected: PropTypes.bool.isRequired,
        onSubscriptionChange: PropTypes.func.isRequired
    };

    renderVatNumberField() {
        const { showTaxVatNumber, vatNumberValidation, isSubmitted } = this.props;

        if (!showTaxVatNumber) {
            return null;
        }

        return (
            <fieldset block="MyAccountOverlay" elem="Legend" style={{display: 'none'}}>
                <Field
                  type="text"
                  label={ __('Tax/VAT Number') }
                  placeholder={ __('Your Tax/VAT Number') }
                  id="taxvat"
                  name="taxvat"
                  validation={ vatNumberValidation }
                  validateSeparately
                  isSubmitted={ isSubmitted }
                />
            </fieldset>
        );
    }

    renderSubscribeToNewsletter() {
        const { onSubscriptionChange, isSubscriptionSelected, isSubmitted } = this.props;

        return (
            <Field
              type="checkbox"
              value="is_subscribed"
              label={ __('Subscribe to newsletter') }
              id="is_subscribed"
              mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
              name="is_subscribed"
              onChange={ onSubscriptionChange }
              checked={ isSubscriptionSelected }
              validateSeparately
              isSubmitted={ isSubmitted }
            />
        );
    }
    renderAcceptTermsAndConditions(){
        const { onTermsChange, isTermsSelected } = this.props;

        return (
            <Field
                type="checkbox"
                value="is_terms_accepted"
                label={ __('I accept the Terms & Conditions') }
                id="is_terms_accepted"
                mix={ { block: 'MyAccountOverlay', elem: 'Checkbox' } }
                name="is_terms_accepted"
                onChange={ onTermsChange }
                checked={ isTermsSelected }

            />
        );
    }

    renderCreateAccountPersonalInfoFields() {
        const {
            newsletterActive, isSubmitted, firstName, lastName, email } = this.props;

        return (
            <>
                <fieldset style={{display: 'none'}}>
                    <legend>{ __('Personal Information') }</legend>
                    <Field
                        type="email"
                        label={ __('Email') }
                        id="email"
                        name="email"
                        placeholder={ __('Your email address') }
                        value={ email }
                        autocomplete="email"
                        validation={ ['notEmpty', 'email'] }
                        validateSeparately
                        isSubmitted={ isSubmitted }
                        hidden
                    />
                    <Field
                      label={ __('First Name') }
                      id="firstname"
                      name="firstname"
                      value={ firstName }
                      autocomplete="given-name"
                      validation={ ['notEmpty'] }
                      validateSeparately
                      isSubmitted={ isSubmitted }
                      hidden={true}
                    />
                    <Field
                      label={ __('Last Name') }
                      id="lastname"
                      name="lastname"
                      value={ lastName }
                      autocomplete="family-name"
                      validation={ ['notEmpty'] }
                      validateSeparately
                      isSubmitted={ isSubmitted }
                      hidden={true}
                    />
                    { this.renderVatNumberField() }
                </fieldset>
                <fieldset>
                    { newsletterActive ? this.renderSubscribeToNewsletter() : null }
                    { this.renderAcceptTermsAndConditions()}
                </fieldset>
            </>
        );
    }

    renderCreateAccountSignUpInfoFields() {
        const { isSubmitted } = this.props;

        return (
            <fieldset block="MyAccountOverlay" elem="Legend">
                <legend>{ __('Sign-Up Information') }</legend>
                <div block="MyAccountOverlay" elem="PasswordBlock">
                    <Field
                      type="password"
                      label={ __('Password') }
                      id="password"
                      name="password"
                      placeholder={ __('Enter your password') }
                      autocomplete="new-password"
                      validation={ ['notEmpty', 'password'] }
                      validateSeparately
                      isSubmitted={ isSubmitted }
                    />
                    <div className={'Field_type_password_hint'}>{ __('(8-13 characters)') }</div>
                    <Field
                      type="password"
                      label={ __('Confirm password') }
                      id="confirm_password"
                      name="confirm_password"
                      placeholder={ __('Retype your password') }
                      autocomplete="new-password"
                      validation={ ['notEmpty', 'password', 'password_match'] }
                      validateSeparately
                      isSubmitted={ isSubmitted }
                    />
                </div>
            </fieldset>
        );
    }

    renderSubmitButton() {
        const { isTermsSelected } = this.props;
        return (
            <div block="MyAccountOverlay" elem="Buttons">
                <button
                  rel='nofollow'
                  block="Button"
                  type="submit"
                  disabled={!isTermsSelected}
                  mix={ { block: 'MyAccountOverlay', elem: 'SignUpButton' } }
                >
                    { __('COMPLETION OF REGISTRATION') }
                </button>
            </div>
        );
    }

    renderCreateAccountForm() {
        const { onCreateAccountAttempt, onCreateAccountSuccess } = this.props;

        return (
            <Form
              key="create-account"
              onSubmit={ onCreateAccountAttempt }
              onSubmitSuccess={ onCreateAccountSuccess }
              onSubmitError={ onCreateAccountAttempt }
            >
                { this.renderCreateAccountSignUpInfoFields() }
                { this.renderCreateAccountPersonalInfoFields() }
                { this.renderSubmitButton() }
            </Form>
        );
    }

    renderAlartBox(){
        const { hidePopup } = this.props;
        
        return (
            <Popup
                id={ALART_POPUP_MY_ACCOUNT_OVERLAY}
                mix={{ block: 'MyAccountOverlay', elem: "ConfirmationBox" }}
                clickOutside={false}
            >
                <div
                    block='MyAccountOverlay'
                    elem="Info"
                >
                    {__('The email confirmation link has been sent to your email. Please confirm your account to proceed.')}
                </div>
                <AlartPoup
                    isConfirm
                    Action={hidePopup}
                />
            </Popup>
        )
    }

    render() {
        return (
            <>
                { this.renderCreateAccountForm() }
                { this.renderAlartBox() }
            </>
        );
    }
}

export default CheckoutSuccessCreateAccount;
