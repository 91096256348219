export class FooterContainerPlugin {

  mapStateToProps = (args, callback, instance) => {
    const [state] = args;

    return {
      ...callback.apply(instance, args),
      footer_bg: state.ConfigReducer.footer_bg,
      footer_fc: state.ConfigReducer.footer_fc,
      footer_inverse_icons: state.ConfigReducer.footer_inverse_icons,
      footer_image: state.ConfigReducer.footer_image
    }
  };

  containerProps = (args, callback, instance) => {
    const { footer_bg, footer_fc, footer_inverse_icons, footer_image } = instance.props;

    return {
      ...callback.apply(instance, args),
      footer_bg,
      footer_fc,
      footer_inverse_icons,
      footer_image
    };
  }

}

export const { mapStateToProps, containerProps } = new FooterContainerPlugin();

export default {
  'Component/Footer/Container/mapStateToProps': {
    function: [
      {
        position: 101,
        implementation: mapStateToProps
      }
    ]
  },
  'Component/Footer/Container': {
    'member-function': {
      containerProps: containerProps
    }
  }
};