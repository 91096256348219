import { lazy } from 'react';
export const CategoryPaginationLink = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "admin-customer-paginationLin" */ 'SourceComponent/CategoryPaginationLink'));

const getPage = (_args, _callback, _instance) => {
    return _callback(..._args);
}

const containerProps = (_args, _callback, _instance) => { 
    const { isAdminWidget } = _instance.props;
    
    return {
        ..._callback(..._args),
        isAdminWidget
    }
}

const renderPageLink = (
    _args, 
    _callback, 
    _instance
) => {
    const {
        isAdminWidget
    } = _instance.props;

    if(!isAdminWidget) {
        return _callback.apply(_instance, _args);
    }

    const pageNumber = _args[0];
    const label = _args[1];
    const children = _args[2];
    const isCurrent = _args[3];

    const {
        pathname,
        onPageSelect,
        getSearchQuery
    } = _instance.props;

    return (
        <li
          key={ pageNumber }
          block="CategoryPagination"
          elem="ListItem"
        >
            <CategoryPaginationLink
              isAdminWidget= { isAdminWidget }
              label={ label }
              url_path={ pathname }
              getPage={ onPageSelect }
              isCurrent={ isCurrent }
              pageNumber={ pageNumber }
              getSearchQueryForPage={ getSearchQuery }
            >
                { children }
            </CategoryPaginationLink>
        </li>
    );
}

export const config = {
    'Component/CategoryPaginationLink/Component': {
        // 'member-function': {
        //     getPage: [
        //         {
        //             position:115,
        //             implementation: getPage
        //         }
        //     ]
        // }
    },
    'Component/CategoryPagination/Component': {
        'member-function': {
            renderPageLink: [
                {
                    position:115,
                    implementation: renderPageLink
                }
            ]
        }
    },
    'Component/CategoryPagination/Container': {
        'member-function': { 
            containerProps
        }
    }
};

export default config;