/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'Store/Notification/Notification.action';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import { showPopup } from 'Store/Popup/Popup.action';
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css';
import { ALART_POPUP_MY_ACCOUNT_OVERLAY } from './CheckoutSuccessCreateAccount.config';
import CheckoutSuccessCreateAccount from './CheckoutSuccessCreateAccount.component';
import { SHOW_VAT_NUMBER_REQUIRED } from './CheckoutSuccessCreateAccount.config';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Component/CheckoutSuccessCreateAccount/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isLoading: state.MyAccountReducer.isLoading,
    showTaxVatNumber: !!state.ConfigReducer.show_tax_vat_number,
    newsletterActive: state.ConfigReducer.newsletter_general_active,
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Component/CheckoutSuccessCreateAccount/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    createAccount: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.createAccount(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showPopup: (type, payload) => dispatch(showPopup(type, payload))
});

/** @namespace Component/CheckoutSuccessCreateAccount/Container */
export class CheckoutSuccessCreateAccountContainer extends PureComponent {
    static propTypes = {
        createAccount: PropTypes.func.isRequired,
        setLoadingState: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        showTaxVatNumber: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        newsletterActive: PropTypes.bool.isRequired,
        showPopup: PropTypes.bool.isRequired,
        hidePopup: PropTypes.bool.isRequired
    };


    containerFunctions = {
        onCreateAccountSuccess: this.onCreateAccountSuccess.bind(this),
        onCreateAccountAttempt: this.onCreateAccountAttempt.bind(this),
        onTermsChange: this.onTermsChange.bind(this),
        onSubscriptionChange: this.onSubscriptionChange.bind(this)
    };

    state = {
        isSubscriptionSelected: false,
        isTermsSelected: false,
        isSubmitted: false
    };


    onTermsChange(){
        this.setState((state) => ({ isTermsSelected: !state.isTermsSelected }));
    }

    containerProps() {
        const {
            state,
            showTaxVatNumber,
            newsletterActive,
            firstName,
            lastName,
            email
        } = this.props;

        const { isSubscriptionSelected, isSubmitted, isTermsSelected } = this.state;

        return {
            state,
            showTaxVatNumber,
            newsletterActive,
            firstName,
            lastName,
            email,
            isTermsSelected,
            vatNumberValidation: this.getVatNumberValidation(),
            isSubscriptionSelected,
            isSubmitted,
            hidePopup: () => history.push({ pathname: '/'})
        };
    }

    showAlartBox() {
        const { showPopup } = this.props;

        showPopup(
            ALART_POPUP_MY_ACCOUNT_OVERLAY,
            { title: __('Confirm to submit') }
        );
    }

    onSubscriptionChange() {
        this.setState((state) => ({ isSubscriptionSelected: !state.isSubscriptionSelected }));
    }

    getVatNumberValidation() {
        const { showTaxVatNumber } = this.props;

        if (showTaxVatNumber === SHOW_VAT_NUMBER_REQUIRED) {
            return ['notEmpty'];
        }

        return [];
    }

    onCreateAccountAttempt(_, invalidFields) {
        const { showNotification, setLoadingState } = this.props;

        if (invalidFields) {
            showNotification('info', __('Incorrect data! Please resolve all field validation errors.'));
        }

        setLoadingState(!invalidFields);
        this.setState({ isSubmitted: true });
    }

    async onCreateAccountSuccess(fields) {
        const {
            createAccount,
            setLoadingState,
            isLoading,
            showNotification
        } = this.props;

        const {
            password,
            email,
            firstname,
            lastname,
            is_subscribed,
            taxvat
        } = fields;

        const customerData = {
            customer: {
                firstname,
                lastname,
                email,
                is_subscribed,
                taxvat
            },
            password
        };

        if (isLoading) {
            return;
        }

        try {
            const code = await createAccount(customerData);

            // if user needs confirmation
            if (code === 2) {
                this.showAlartBox();
            } else {
                if(code !== false) {
                    history.push({ pathname: appendWithStoreCode('enhanced-registration')})
                }
            }
        } finally {
            setLoadingState(false);
        }
    }

    render() {
        return (
            <CheckoutSuccessCreateAccount
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessCreateAccountContainer);
