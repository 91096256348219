// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Loader from "Component/Loader";

import './RequestAccountDeleteButton.style';

export class RequestAccountDeleteButton extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const {onButtonClick,showOverlay,isOverlayVisible, isLoading } = this.props;
        return (
            <div block="RequestAccountDeleteButton">
                {
                    isOverlayVisible
                    &&
                    <>
                        <div className="RequestAccountDeleteButtonOverlay_bkg"></div>
                        <div className="Overlay Overlay_isVisible RequestAccountDeleteButtonOverlay_isVisible">
                            <Loader isLoading={isLoading}/>
                            <p>{__("Are you sure you want to proceed? An email will be sent to the admin and your account will be deleted forever.")}</p>
                            <button
                                className='Button Button_isHollow'
                                onClick={onButtonClick}
                            >
                                <span>{__('Yes')}</span>
                            </button>
                            <button
                                className='Button Button_isHollow'
                                onClick={()=>{showOverlay(false)}}
                            >
                                <span>{__('No')}</span>
                            </button>
                        </div>
                    </>
                }
                {
                    !isOverlayVisible
                    &&
                    <button
                        className='Button Button_isHollow'
                        onClick={()=>{showOverlay(true)}}
                    >
                        <span>{__('Request for account deactivate')}</span>
                    </button>
                }
            </div>
        );
    }
}

export default RequestAccountDeleteButton;
