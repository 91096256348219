import Image from 'Component/Image';
import { DISPLAY_CART_TAX_IN_SHIPPING_EXL_TAX } from 'Util/Cart';
import { formatPrice } from 'Util/Price';

import { MyAccountOrderPopup as SourceMyAccountOrderPopup } from 'SourceComponent/MyAccountOrderPopup/MyAccountOrderPopup.component';

import './MyAccountOrderPopup.style';

/** @namespace Component/MyAccountOrderPopup/Component */
export class MyAccountOrderPopup extends SourceMyAccountOrderPopup {

    renderOrderNumber() {
        const { order: { base_order_info }, order } = this.props;
        const { increment_id } = base_order_info || {};

        return (
            <div className="MyAccountOrderPopup-orderNumber">
                <span>{ __('Αριθμός παραγγελίας') }</span>
                <span>#{ increment_id }</span>
            </div>
        );
    }

    renderBaseInfo() {
        const { order: { base_order_info } } = this.props;
        const { status_label, created_at } = base_order_info || {};

        return (
            <>
                <dl>
                    <dt>{ __('Created at: ') }</dt>
                    <dd>{ created_at }</dd>
                </dl>
                <dl>
                    <dt>{ __('Status: ') }</dt>
                    <dd className={status_label}>{ status_label }</dd>
                </dl>
            </>
        );
    }

    renderPayment() {
        const { order: { payment_info } } = this.props;
        const { additional_information: { method_title } = {} } = payment_info || {};

        return (
            <dl>
                <dt>{ __('Payment Method: ') }</dt>
                <dd>{ method_title }</dd>
            </dl>
        );
    }

    renderShipping() {
        const { order: { shipping_info }, currency_code, display_tax_in_shipping_amount } = this.props;

        const {
            shipping_description,
            shipping_address,
            shipping_amount,
            shipping_incl_tax
        } = shipping_info || {};

        if (!shipping_address) {
            return null;
        }

        const amount = display_tax_in_shipping_amount === DISPLAY_CART_TAX_IN_SHIPPING_EXL_TAX
            ? shipping_amount
            : shipping_incl_tax;

        return (
            <>
                <dl>
                    <dt>{ __('Shipping Method: ') }</dt>
                    <dd>
                        { shipping_description }
                    </dd>
                </dl>
                <dl>
                    <dt>{ __('Shipping Amount: ') }</dt>
                    <dd>
                        { formatPrice(amount, currency_code) }
                    </dd>
                </dl>
            </>
        );
    }

    renderItems() {
        const { order: { base_order_info: { order_items = [] } = {}, order_products = [] }, currency_code } = this.props;
        
        return order_products.map((product, i) => {
            const {
                name,
                sku,
                ordered_sku,
                attributes = {},
                thumbnail,
                id,
                qty,
                row_total,
                type_id,
                configurable_options = {},
                attributes: {
                  beauty_color: {
                    attribute_options: beautyColorOptions = {},
                    attribute_value: beautyColorValue
                  } = {},
                  beauty_size: {
                    attribute_options: beautySizeOptions = {},
                    attribute_value: beautySizeValue
                  } = {}
                } = {},
                variants = []
              } = product;
            

              let optionLabel;
              let key;
            
              if (type_id === 'configurable') {
                const [configurableKey] = Object.keys(configurable_options);
                const variant = variants.find(({ sku }) => ordered_sku === sku) || {};
                const {
                  attribute_options = {},
                  attribute_value,
                  attribute_label
                } = variant?.attributes?.[configurableKey] || {};
                key = attribute_label || configurableKey;
                optionLabel = attribute_options[attribute_value]?.label;
              } else if (beautyColorValue != 0) {
                key = 'Color';
                optionLabel = beautyColorOptions[beautyColorValue]?.label;
              } else if (beautySizeValue != 0) {
                key = 'Size';
                optionLabel = beautySizeOptions[beautySizeValue]?.label;
              }
            
            const productSku = ordered_sku ? ordered_sku : sku;

            let brand = attributes !== undefined
            && attributes.brand !== undefined
            && attributes.brand.attribute_options[attributes.brand.attribute_value] !== undefined ?
                attributes.brand.attribute_options[attributes.brand.attribute_value].label : null;

            const { url } = thumbnail || {};

            return (
                <div
                    key={id || i.toString()}
                    block="MyAccountOrderPopup"
                    elem="Row"
                >
                    {url && (
                        <Image
                            src={url}
                            alt={name}
                            mix={{ block: 'MyAccountOrderPopup', elem: 'Thumbnail' }}
                        />
                    )}
                    <div block="MyAccountOrderPopup" elem="Content">
                        {brand ?
                            <div className="MyAccountOrderPopup-Product_brand">{brand}</div>
                            : ''}
                        <div className="MyAccountOrderPopup-Product_name">{name}</div>
                        <div className="MyAccountOrderPopup-Product_sku">{productSku}</div>
                        {optionLabel ? <div className="MyAccountOrderPopup-Product_option">{
                            type_id === 'configurable' ?
                                __(`${key}: %s`, optionLabel)
                                : optionLabel
                        }</div> : null}
                        <div className="MyAccountOrderPopup-Product_qty">{__('Ποσότητα: %s', qty)}</div>
                        <div className="MyAccountOrderPopup-Product_rowTotal">{formatPrice(row_total, currency_code)}</div>
                    </div>
                </div>
            );
        });
    }

    renderProducts() {
        const { order: { order_products = [] } } = this.props;

        return (
            <div block="MyAccountOrderPopup" elem="ProductsWrapper">
                <h4>
                    { __('Προϊόντα παραγγελίας:') }
                    <span>{ order_products.length }</span>
                </h4>
                <div
                  block="MyAccountOrderPopup"
                  elem="Products"
                >
                    { this.renderItems() }
                </div>
            </div>
        );
    }

    renderTotals() {
        const { order: { base_order_info }, currency_code } = this.props;
        const { grand_total, sub_total } = base_order_info || {};

        return (
            <>
                <dl>
                    <dt>{ __('Subtotal: ') }</dt>
                    <dd>
                        { formatPrice(sub_total, currency_code) }
                    </dd>
                </dl>
                <dl>
                    <dt>{ __('Grand total: ') }</dt>
                    <dd>
                        { formatPrice(grand_total, currency_code) }
                    </dd>
                </dl>
            </>
        );
    }

    renderActions() {
        return null;
    }

    renderContent() {
        const { order: { order_products } } = this.props;

        if (!order_products) {
            return null;
        }

        return (
            <>
                { this.renderOrderNumber() }
                <div className="MyAccountOrderPopup-middleContent">
                    { this.renderBaseInfo() }
                    { this.renderPayment() }
                    { this.renderShipping() }
                    { this.renderTotals() }
                </div>
                { this.renderProducts() }
                <div className="MyAccountOrderPopup-Actions">
                    { this.renderActions() }
                </div>
            </>
        );
    }
}

export default MyAccountOrderPopup;
