/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { PureComponent } from 'react';
import CheckoutGroupPayment from "./CheckoutGroupPayment.component";
import { connect } from "react-redux";

export const mapStateToProps = (state) => ({
    mg_bank_redirect_winbank: state.ConfigReducer.mg_bank_redirect_winbank,
    mg_bank_redirect_alphabank: state.ConfigReducer.mg_bank_redirect_alphabank,
    mg_bank_redirect_nbg_enterprise: state.ConfigReducer.mg_bank_redirect_nbg_enterprise,
    mg_bank_redirect_eurobank: state.ConfigReducer.mg_bank_redirect_eurobank
});

export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/CheckoutGroupPayment/Container */
export class CheckoutGroupPaymentContainer extends PureComponent {

    render() {
        return(
            <CheckoutGroupPayment
                { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutGroupPaymentContainer);
