import { Route } from 'react-router-dom';
import { lazy } from 'react';
export const MyAccountEnhancedRegistration = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "enhanced-registration" */ '../component/MyAccountEnhancedRegistration'));

export const withStoreRegex = (path) => window.storeRegexText.concat(path);
import { ENCHANCE_REGISTRATION } from  '../utils/config';

export class RouterPlugin {
    /**
     * Adds Registration Enchanced handles to the router SWITCH_ITEMS_TYPE list
     * @param originalMember
     * @returns {*[]}
     */
     switchItemsRoute = (originalMember) => [  
        ...originalMember,
        {
            component: <Route path={withStoreRegex(`/${ENCHANCE_REGISTRATION}/:step?`)} render={ (props) => <MyAccountEnhancedRegistration { ...props } /> }  />,
            position: 110
        }
    ];
}


const { switchItemsRoute, requestUrlRewrite } = new RouterPlugin();

export const config = {
    // 'Route/UrlRewrites/Component': {
    //     'member-property': {
    //         SWITCH_ITEMS_CUSTOM: switchItemsRoute
    //     }
    // }
    'Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: switchItemsRoute
        }
    }
    
};

export default config;
