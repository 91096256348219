import { lazy } from "react";
import ModiFaceIcon from '../../util/assets/images/modiface-tag-gr.png';
export const UniversalIcon = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "icons" */
    'Component/UniversalIcon'
));

import './ProductCard.style.plugin.scss';

const renderModifaceIcon = (_args, _callback, _instance) => {
    const {
        product: {
            modifaceData
        } = {}
    } = _instance.props;
    

    if (!modifaceData) {
        return _callback.apply(_instance, _args);
    }



    return (
        <div block="ProductCard" elem="Modiface">
            <UniversalIcon
                src={ModiFaceIcon}
                alt="Modiface-Type"
                className="ProductActions-ModifaceIcon"
            />
        </div>
    );
}

export const config = {
    'Component/ProductCard/Component': {
        'member-function': {
            renderModifaceIcon
        }
    }
};

export default config;