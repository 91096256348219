class MetaDispatcherPlugin {
    _getProductMeta(args, callback, instance) {
        const {
            redirect_code_previous,
            redirect_from,
            name = '',
            attributes: {
                brand: {
                attribute_options = {},
                attribute_value = ''
               } = {}
            } = {},
            meta_title = ''
        } = args[0];

        const brandLabel = attribute_options[attribute_value]?.label +` ${meta_title || name}`;
        const title = attribute_options[attribute_value] ? brandLabel :  meta_title || name;
        
        return {
            ...callback.apply(instance, args),
            title: title,
            redirect_code_previous,
            redirect_from
        }
    }
    _getCategoryMeta(args, callback, instance) {
        const {
            redirect_code_previous,
            redirect_from
        } = args[0];

        return {
            ...callback.apply(instance, args),
            redirect_code_previous,
            redirect_from
        }

    }
}

const { _getProductMeta, _getCategoryMeta } = new MetaDispatcherPlugin();

export default {
    'Util/Meta/Dispatcher': {
        'member-function': {
            _getProductMeta: [
                {
                    position: 102,
                    implementation: _getProductMeta
                }
            ],
            _getCategoryMeta: [
                {
                    position: 102,
                    implementation: _getCategoryMeta
                }
            ]
        }
    },
};
