const renderCurrentPrice = (args, callback, instance) => {
    const {
        forBundler
    } = instance.props;

    if(forBundler){
        const {
            discountPercentage,
            formattedFinalPrice,
            label
        } = instance.props;
        const { itemProp = null, content = null } = instance.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 && !forBundler ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (formattedFinalPrice == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                { instance.renderPriceBadge(label) }
                <span
                    itemProp={ itemProp }
                    content={ content }
                    block="ProductPrice"
                    elem="PriceValue"
                >
                    { formattedFinalPrice }
                </span>
            </PriceSemanticElementName>
        );
    }

    return callback.apply(instance,args);
}

const renderOldPrice = (args, callback, instance) => {
    const {
        forBundler
    } = instance.props;
    // if(!forBundler)
        return callback.apply(instance,args);
    return null;

}

export default {
    'Component/ProductPrice/Component': {
        'member-function': {
            renderCurrentPrice : renderCurrentPrice,
            renderOldPrice : renderOldPrice
        }
    }
};
