import { STORE, STORE_LIST } from './UrlRewrites.component.plugin'
const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite
    } = instance.props;
    if(instance.getType() === STORE_LIST){
        return {
            title: urlRewrite.title ,
            meta_title: urlRewrite.meta_title,
            meta_description: urlRewrite.meta_description,
            meta_keywords: urlRewrite.meta_keywords
        };
    }
    if(instance.getType() === STORE){
        return {
            store_id : urlRewrite.id,
            title: urlRewrite.title,
            meta_title: urlRewrite.meta_title,
            meta_description: urlRewrite.meta_description,
            meta_keywords: urlRewrite.meta_keywords
        };
    }
    return callback.apply(args,instance);
}

export const config = {
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps: [
                {
                    position:100,
                    implementation: getTypeSpecificProps
                }
            ]
        }
    }
};

export default config;
