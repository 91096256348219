import { Field } from 'Util/Query';

const getTimeZoneFields = () => {
    return new Field('am_time_zones')
        .addFieldList([
            'value',
            'label'
        ]);

}
const _getStoreConfigFields = (args, callback, instance) => {
    return [
        ...callback(...args),
        'am_display_option_fields',
        'base_currency_code',
        getTimeZoneFields()
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': _getStoreConfigFields
        }
    }
};
