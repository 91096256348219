import { addRichDataReducer } from '../store/RichData';

export class RichDataReducerPlugin {
    getReducers = (args, callback, instance) => ({
        ...callback.apply(instance, args),
        addRichDataReducer
    });
}

const { getReducers } = new RichDataReducerPlugin();

export const config = {
    'Store/Index/getReducers': {
        function: [
            {
                position: 100,
                implementation: getReducers
            }
        ]
    }
};

export default config;
