import { GET_RETURN_LIST, SET_LOADING, GET_RMA_CONFIG } from './Return.action';

export const initialState = {
    isLoading: false,
    returnList: [],
    getAmRmaConfig: {}
};

/** @namespace Rma/Store/Return/Reducer/ReturnReducer */
export const ReturnReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
    case GET_RMA_CONFIG:
        const { getAmRmaSettings } = action;
        return {
            ...state,
            getAmRmaConfig: getAmRmaSettings
        };    
    case GET_RETURN_LIST:
        const { data } = action;

        return {
            ...state,
            returnList: data
        };
    case SET_LOADING:
        const { is } = action;

        return {
            ...state,
            isLoading: is
        };
    default:
        return state;
    }
};

export default ReturnReducer;
