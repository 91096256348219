import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

/**
 * Image component
 * Images are loaded only when they appear in a viewport
 * @class Image
 * @namespace Component/Image/Component
 */
export class Image extends SourceImage {

    static defaultProps = {
        alt: '',
        wrapperSize: {},
        style: {},
        title: null,
        isPlain: false,
        dataPromoid: null
    };

    render() {
        const {
            ratio,
            mix,
            isPlaceholder,
            wrapperSize,
            src,
            imageRef,
            className,
            isPlain,
            dataPromoid
        } = this.props;
        
        const { imageStatus } = this.state;

        // render image as is: without additional container and additional styles
        if (isPlain) {
            return this.renderImage();
        }

        return (
            <div
              block="Image"
              ref={ imageRef }
              mods={ {
                  ratio,
                  imageStatus: imageStatus.toLowerCase(),
                  isPlaceholder,
                  hasSrc: !!src
              } }
              mix={ mix }
              style={ wrapperSize }
              // eslint-disable-next-line react/forbid-dom-props
              className={ className }
              data-promoid= { dataPromoid }
            >
                { this.renderImage() }
            </div>
        );
    }
}

export default Image;
