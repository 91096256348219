/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { MixType } from 'Type/Common';
import { FilterType, ProductType } from 'Type/ProductList';
import SlickSlider from 'Component/SlickSlider';
import { DEFAULT_PLACEHOLDER_COUNT } from './ProductListPage.config';
import CmsBlock from 'Component/CmsBlock';

import './ProductListPage.style';

/**
 * Placeholder for List of category product
 * @namespace Component/ProductListPage/Component
 * @class ProductListPage
 */
export class ProductListPage extends PureComponent {
    static propTypes = {
        isInfiniteLoaderEnabled: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isVisible: PropTypes.bool.isRequired,
        updatePages: PropTypes.func.isRequired,
        numberOfPlaceholders: PropTypes.number,
        selectedFilters: FilterType,
        wrapperRef: PropTypes.func,
        pageNumber: PropTypes.number,
        items: PropTypes.arrayOf(ProductType),
        mix: MixType
    };

    static defaultProps = {
        numberOfPlaceholders: DEFAULT_PLACEHOLDER_COUNT,
        wrapperRef: () => {},
        selectedFilters: {},
        sliderOptions : {
            isSlider: null
        },
        pageNumber: null,
        items: [],
        mix: {}
    };

    sliderConfig() {
        const {
            sliderOptions :{
                productDisplayDesktop,
                productDisplayTable,
                productDisplayMobile,
                productScrollDesktop,
                productScrollTable,
                productScrollMobile,
                sliderDots,
                sliderNavigation,
                sliderSpeed,
                sliderAutoplay,
                sliderInfinityScroll
            }
        } = this.props

        return {
            slides_to_display: productDisplayDesktop,
            slides_to_scroll: productScrollDesktop,
            slides_to_display_tablet: productDisplayTable ? productDisplayTable : 4,
            slides_to_scroll_tablet: productScrollTable ? productScrollTable : 1,
            slides_to_display_mobile: productDisplayMobile,
            slides_to_scroll_mobile: productScrollMobile,
            slide_speed: sliderSpeed,
            slider_dots: sliderDots != 0 ? true : false,
            show_navigation: sliderNavigation != 0 ? true: false,
            auto_play: sliderAutoplay != 0 ? true: false,
            infinite_loop: sliderInfinityScroll != 0 ? true: false
        };
    }

    state = {
        siblingsHaveBrands: false,
        siblingsHavePriceBadge: false,
        siblingsHaveTierPrice: false,
        siblingsHaveConfigurableOptions: false
    };

    componentDidMount() {
        this.startObserving();
    }

    componentDidUpdate() {
        this.startObserving();
    }

    componentWillUnmount() {
        this.stopObserving();
    }

    containerProps() {
        const {
            sliderOptions : {
                additionalClass
            } = {},
            isSampleProducts,
            maxSampleAdded,
            isSearchPage,
            title
        } = this.props;

        const {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions
        } = this.state;

        return {
            productCardFunctions: {
                setSiblingsHaveBrands: () => this.setState({ siblingsHaveBrands: true }),
                setSiblingsHavePriceBadge: () => this.setState({ siblingsHavePriceBadge: true }),
                setSiblingsHaveTierPrice: () => this.setState({ siblingsHaveTierPrice: true }),
                setSiblingsHaveConfigurableOptions: () => this.setState({ siblingsHaveConfigurableOptions: true })
            },
            productCardProps: {
                siblingsHaveBrands,
                siblingsHavePriceBadge,
                siblingsHaveTierPrice,
                siblingsHaveConfigurableOptions
            },
            title,
            additionalClass,
            isSampleProducts,
            maxSampleAdded,
            isSearchPage
        };
    }

    startObserving() {
        const {
            items,
            updatePages,
            isInfiniteLoaderEnabled
        } = this.props;

        if (!isInfiniteLoaderEnabled || items.length) {
            return;
        }

        if (this.node && !this.observer && 'IntersectionObserver' in window) {
            const options = {
                rootMargin: '0px',
                threshold: [ 0.1, 0.5, 0.9 ]
            };

            this.observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                const { items, isLoading } = this.props;

                // must not be a product items list, and must not be loading
                if (intersectionRatio > 0 && !items.length && !isLoading) {
                    this.stopObserving();
                    updatePages();
                }
            }, options);

            this.observer.observe(this.node);
        }
    }

    stopObserving() {
        if (this.observer) {
            if (this.observer.unobserve && this.node) {
                this.observer.unobserve(this.node);
            }

            if (this.observer.disconnect) {
                this.observer.disconnect();
            }

            this.observer = null;
        }
    }

    renderPlaceholders() {
        const {
            numberOfPlaceholders, mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            },
            isLoading
        } = this.props;
        
        return Array.from(
            { length: numberOfPlaceholders },
            (_, i) => (
                <ProductCard
                  isLoading = { isLoading }
                  key={ i }
                  itemPosition = { i + 1}
                  product={ {} }
                  layout={ layout }
                />
            )
        );
    }

    getPlaceholderRef() {
        const { isVisible } = this.props;

        if (!isVisible) {
            return undefined;
        }

        return (node) => {
            this.node = node;
        };
    }

    renderCmsBlockPromoListing() {
        const { category_page_cms_block_listing, categoryPageCmsBlockListing, selectedFilters } = this.props;

        if(selectedFilters?.['brand']) {
            return null;
        }

        if(categoryPageCmsBlockListing) {
            return <li className="ProductCard ProductCard-Promo"><CmsBlock noWrapper={ true } identifier={ categoryPageCmsBlockListing } key={ categoryPageCmsBlockListing } /></li>
        }

        return null
    }


    renderPageItems() {
        const {
            items,
            device,
            isFreeGifts,
            selectedFilters,
            isSampleProduct,
            title,
            widgetId,
            sliderOptions : {
                isSlider
            } = {},
            mix: {
                mods: {
                    layout = GRID_LAYOUT
                } = {}
            } 
        } = this.props;
        
        if(isSlider) {
            return items.map((product, i) => {
                return (
                    <ProductCard
                        widgetId= { widgetId }
                        title= { title }
                        product={product}
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        itemPosition = { i + 1}
                        selectedFilters={selectedFilters}
                        layout={layout}
                        {...this.containerProps()}
                    />
                );
            });
        }

        return items.map((product, i) => {

            if(i == 8 && !isFreeGifts) {
                return (
                    <>
                        { this.renderCmsBlockPromoListing() }
                        <ProductCard
                            widgetId= { widgetId }
                            title= { title }
                            product={product}
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            itemPosition = { i + 1}
                            selectedFilters={selectedFilters}
                            layout={layout}
                            {...this.containerProps()}
                        />
                    </>
                );
            }

            return (
                <ProductCard
                    widgetId= { widgetId }
                    title= { title }
                    product={product}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    itemPosition = { i + 1}
                    selectedFilters={selectedFilters}
                    layout={layout}
                    {...this.containerProps()}
                />
            );
        });
    }

    renderPlaceholderItems() {
        return (
            <>
                <li
                  block="ProductListPage"
                  elem="Offset"
                  ref={ this.getPlaceholderRef() }
                />
                { this.renderPlaceholders() }
            </>
        );
    }

    renderItems() {
        const { items, isLoading } = this.props;

        if (!items.length || isLoading) {
            return this.renderPlaceholderItems();
        }

        return this.renderPageItems();
    }

    render() {
        const {
            pageNumber,
            wrapperRef,
            sliderOptions : {
                isSlider,
                additionalClass
            } = {} ,
            mix,
            isLoading,
            device
        } = this.props;

        if (isSlider && !isLoading) {
            return (
                <SlickSlider
                 block="ProductListPage"
                 mix={{ ...mix, elem: 'Page' }}
                 {...this.sliderConfig()}
                 device={ device }
                 isLoading={ isLoading }
                 slick_slider_class={ additionalClass }
                    >
                    {this.renderItems()}
                </SlickSlider>
            )
        }

        return (
            <ul
              block="ProductListPage"
              mix={ { ...mix, elem: 'Page' } }
              key={ pageNumber }
              ref={ wrapperRef }
              className={ additionalClass }
            >
                { this.renderItems() }
            </ul>
        );
    }
}

export default withRouter(ProductListPage);
