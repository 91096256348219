import { lazy } from 'react';
import { REWARDPOINTSHISTORY, REWARDPOINTS } from "../component/RewardPointsForm/RewardPoints.config";
import getStore from 'SourceUtil/Store';
export const MyAccountRewardPointsHistory = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-reward-histroy" */
    '../component/MyAccountRewardPointsHistory'
));

export const MyAccountRewardPoints = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account-reward" */
    '../component/MyAccountRewardPoints'
));

export const aroundTabMap = (args) => {
    delete args['my-downloadable'];

    const { 
        getRewardConfig: {
            is_enable = false
        } = {},
        ConfigReducer: { code = '' } = {} 
    } = getStore().getState() || {};


    if(!is_enable || code !== 'en') {
        return {
            ...args
        }
    }
    
    return {
        ...args,
        [REWARDPOINTS]: {
            url: `/${REWARDPOINTS}`,
            name: __('Rewards Points')
        },
        [REWARDPOINTSHISTORY]: {
            url: `/${REWARDPOINTSHISTORY}`,
            name: __('Rewards Points History')
        }

    }
};

export const renderMap = (props, instance) => {
    const { 
        getRewardConfig: {
            is_enable = false
        } = {},
        ConfigReducer: { code = '' } = {} 
    } = getStore().getState() || {};

    if(!is_enable || code !== 'en') {
        return {
            ...props
        }
    }

     return {
         ...props,
         [REWARDPOINTS]: MyAccountRewardPoints,
         [REWARDPOINTSHISTORY]: MyAccountRewardPointsHistory
     }
 };

export default {
    'Route/MyAccount/Component': {
        'member-property': {
            'renderMap': renderMap,
        }
    },
    'Route/MyAccount/Container': {
        'static-member': {
            tabMap: [
                {
                    position: 102,
                    implementation: aroundTabMap
                }
            ]
        }
    }
};

