import { extendCartDataProducts } from 'Util/Product/PerformaceProduct.js'

const _updateCartData = (args, callback, context) => {
    let [ cartTotals, e ] = args;

    if(cartTotals.items?.length > 0){
        cartTotals.items = extendCartDataProducts(cartTotals.items, true) || {};
    }
    
    return callback(...[ cartTotals, e ]);
}
export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData
        }
    }
};
