import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { ChildrenType, MixType } from 'Type/Common';
import { DeviceType } from 'Type/Device';
import { isCrawler, isSSR } from 'Util/Browser';

import './ExpandableHorizontal.style';

/** @namespace Component/ExpandableHorizontal/Component */
export class ExpandableHorizontal extends PureComponent {
    static propTypes = {
        isContentExpanded: PropTypes.bool,
        isArrow: PropTypes.bool,
        heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        children: ChildrenType,
        mix: MixType.isRequired,
        mods: PropTypes.object,
        device: DeviceType.isRequired,
        onClick: (props, propName, componentName) => {
            const propValue = props[propName];
            if (propValue === null) {
                return;
            }
            if (typeof propValue === 'function') {
                return;
            }
            throw new Error(`${componentName} only accepts null or string`);
        }
    };

    static defaultProps = {
        heading: '',
        isContentExpanded: false,
        onClick: null,
        children: [],
        isArrow: false,
        mods: {}
    };

    expandableHorizontalRef = createRef();

    __construct(props) {
        super.__construct(props);
        const { isContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler();

        this.state = {
            isContentExpanded: isForceExpanded || isContentExpanded,
            // eslint-disable-next-line react/no-unused-state
            prevIsContentExpanded: isContentExpanded
        };
    }

    static getDerivedStateFromProps({ isContentExpanded }, { prevIsContentExpanded }) {
        if (isContentExpanded !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isContentExpanded,
                isContentExpanded
            };
        }

        return null;
    }

    scrollToExpandedContent(additioanlClass) {
        let expandableHorizontalAdditioanlClass = 'ExpandableHorizontal scandi_horizontal_accordian-Content '+additioanlClass;
        let expandableHorizontalAdditioanlActiveClass = 'ExpandableHorizontal scandi_horizontal_accordian-Content '+additioanlClass+' ExpandableHorizontal_isContentExpanded';

        setTimeout(function() {
            const el = document.querySelectorAll('.'+additioanlClass);

            for (let i = 0; i < el.length; i++) {
                el[0].classList = expandableHorizontalAdditioanlActiveClass;
                el[i].onmouseover = function() {
                    var c = 0;
                    while (c < el.length) {
                        el[c++].className = expandableHorizontalAdditioanlClass;
                    }
                    el[i].className = expandableHorizontalAdditioanlActiveClass;
                };
            }

        }, 1000);
    }

    renderContent() {
        const { children, mix } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };

        return (
            <div
              block="ExpandableHorizontal"
              elem="Content"
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableHorizontalContent', mods } }
            >
                { children }
            </div>
        );
    }

    render() {
        const { mix, mods, additioanlClass } = this.props;

        return (
            <article
              block="ExpandableHorizontal"
              mix={ mix }
              mods={ mods }
              ref={ this.expandableHorizontalRef }
              className={ additioanlClass }
              onMouseEnter={ this.scrollToExpandedContent(additioanlClass) }
            >
                { this.renderContent() }
            </article>
        );
    }
}
export default ExpandableHorizontal;
