import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import CustomPromoPopup from 'Component/CustomPromoPopup';

export const renderPromo = (args, callback, instance) => {
    const {
        blocks: { items },
        urlRewrite,
        banners
    } = instance.props;
    if (urlRewrite && urlRewrite.banners && urlRewrite.banners.popup && urlRewrite.banners.popup.length > 0) {
        const { popup = {} } = urlRewrite.banners;
        // const popup = ["beauty-cms_block-20", "custom_brands_promo_popup"]
        let index = 0
        while (popup && BrowserDatabase.getItem(`${popup[index]}_popup_id`) && index <= popup.length) {
            index = index + 1;
        }
        // All active popups have been rendered 
        if (popup && index <= popup.length)
            return <CustomPromoPopup identifier={popup[index]} />
        return null;
    }
    if (banners && Object.keys(banners) && Object.keys(banners).length > 0) {
        const { banners: {
            popup = {}
        } } = banners;

        let index = 0
        while (popup && BrowserDatabase.getItem(`${popup[index]}_popup_id`) && index <= popup.length) {
            index = index + 1;
        }
        // All active popups have been rendered 
        if (popup && index <= popup.length)
            return <CustomPromoPopup identifier={popup[index]} />
        return null;
    }
}

export default {
    'Component/Footer/Component': {
        'member-function': {
            renderPromo
        },
    }
}