import { connect } from 'react-redux';

import { AddToCartContainer as SourceAddToCartContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/AddToCart/AddToCart.container';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

/* @namespace Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    containerProps() {
        const {
            product,
            mix,
            disabled,
            layout,
            titleUpperCase,
            isSampleProducts = false,
            isAddingSample = false,
            isModiface = false,
            src = ''
        } = this.props;

        const { isLoading } = this.state;

        return {
            isLoading,
            product,
            mix,
            disabled,
            layout,
            titleUpperCase,
            isSampleProducts,
            isAddingSample,
            isModiface,
            src
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
