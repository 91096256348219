import { ProductConfigurableAttributeDropdownContainer as SourceProductConfigurableAttributeDropdownContainer } from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';

export class ProductConfigurableAttributeDropdownContainer extends SourceProductConfigurableAttributeDropdownContainer {

        _getSelectOptions = () => {
        const {
            option: {
                attribute_options,
                attribute_code
            },
            getIsConfigurableAttributeAvailable,
            getIsConfigurableStockStatus,
            getIsConfigurableLowStock,
            lowStockQty
        } = this.props;

        if (!attribute_options) {
            // eslint-disable-next-line no-console
            console.warn(`Please make sure "${ attribute_code }" is visible on Storefront.`);

            return [];
        }

        return Object.values(attribute_options)
            .reduce((acc, option) => {
                const { value } = option;

                const isAvailable = getIsConfigurableAttributeAvailable({
                    attribute_code,
                    attribute_value: value
                });

                const isInStock = getIsConfigurableStockStatus({
                    attribute_code,
                    attribute_value: value
                })

                const isLowStock = getIsConfigurableLowStock({
                    attribute_code,
                    attribute_value: value
                })
                
                if (!isAvailable) {
                    return acc;
                }

                return [...acc, {
                    ...option,
                    id: value,
                    isLowStock,
                    outOfStock: !isInStock,
                    lowStockQty
                }];
            }, []);
    };

    containerProps() {
        const { option: { attribute_code, attribute_label }, isProductPage, attributeSetId, option } = this.props;

        return {
            selectValue: this._getSelectValue(),
            selectOptions: this._getSelectOptions(),
            selectName: attribute_code,
            selectLabel: attribute_label,
            isProductPage,
            attributeSetId,
            option
        };
    }
}

export default ProductConfigurableAttributeDropdownContainer;
