import { connect } from 'react-redux';
import { CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer, mapDispatchToProps, mapStateToProps } from  'SourceComponent/CheckoutPayments/CheckoutPayments.container';

/** @namespace Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {

    __construct(props) {
        super.__construct(props);
        this.state = {
            selectedPaymentCode: null
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
