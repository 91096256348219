import { EVENT_GTM_IMPRESSIONS_PLP } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const state = (original) => ({
    ...original,
    fired: false
});


const componentDidMount = (args, callback, instance) => {
    const { event, store, recentProducts, areDetailsLoaded } = instance.props;
    const { fired } = instance.state

    callback(...args);

    if(recentProducts[store] && 
       recentProducts[store].length > 0 && 
       !fired && areDetailsLoaded) {
        instance.setState({ fired: true });
        event(EVENT_GTM_IMPRESSIONS_PLP, {
            products: recentProducts[store], 
            list: 'ΕΙΔΑΤΕ ΠΡΟΣΦΑΤΑ', 
            pathname: location?.pathname, 
            search: location?.search
        })
    }
}

const componentDidUpdate = (args, callback, instance) => { 
    const [ prevProps ] = args;
    const { isfired = false } = instance.state;
    const { recentProducts: prevRecentProducts, areDetailsLoaded: prevAreDetailsLoaded } = prevProps;
    const { event, store, recentProducts, areDetailsLoaded } = instance.props;
    
    if(recentProducts[store] && recentProducts[store].length > 0 && 
       JSON.stringify(recentProducts[store]) !== JSON.stringify(prevRecentProducts[store]) && 
       areDetailsLoaded && !isfired) {
        event(EVENT_GTM_IMPRESSIONS_PLP, {
            products: recentProducts[store], 
            list: 'ΕΙΔΑΤΕ ΠΡΟΣΦΑΤΑ', 
            pathname: location?.pathname, 
            search: location?.search
        });
        instance.setState({ fired: true });
    }

    callback(...args);
}

const addComponentDidMount = (args, callback, instance) => {
    const { event, position, block_title } = instance.props;
    const items = instance.getProducts();
    
    if (Object.values(items).length > 0 && position) {
        event(EVENT_GTM_IMPRESSIONS_PLP, {
            products: instance.getProducts() || [],
            list: block_title,
            pathname: location?.pathname,
            search: location?.search
        })
    }

    callback.apply(instance, args);
};

const addComponentDidMountToFreeGift = (args, callback, instance) => {
    const { title = '', event } = instance.props;
    const [ prevProps, prevState ] = args
    const { products = {}, fired = false } = instance.state;
    const { products: prevProducts } = prevState;

    if (Object.values(products).length > 0 && products !== prevProducts && !fired) {
        event(EVENT_GTM_IMPRESSIONS_PLP, {
            products: products || [],
            list: title.toString(),
            pathname: location?.pathname,
            search: location?.search
        });
        instance.setState({ fired: true });
    }

    callback.apply(instance, args);
};


const addComponentDidUpdateToWidget = (args, callback, instance) => {
    const { event } = instance.props;
    const [ prevProps, prevState ] = args
    const { pages = {}, fired = false } = instance.state;
    const { pages: prevPages } = prevState;

    if (Object.values(pages).length > 0 && prevPages !== pages && !fired) {
        const [key] = Object.keys(pages);

        event(EVENT_GTM_IMPRESSIONS_PLP, {
            products: pages[key] || [],
            list: 'Product Widget',
            pathname: location?.pathname,
            search: location?.search
        });
        instance.setState({ fired: true });
    }

    callback.apply(instance, args);
};

const addComponentDidUpdateLinkProducts = (args, callback, instance) => {
    const [ prevProps, prevState ] = args
    const { fired = false } = instance.state;

    const {
        linkType: preLinkType,
        linkedProducts: {
            [preLinkType]: {
                items: preItems = []
            } = {}
        },
    } = prevProps;

    const {
        linkType,
        linkedProducts: {
            [linkType]: {
                items = []
            } = {}
        },
        linkedProducts,
        event
    } = instance.props;

    if (items.length !== 0 && items !== preItems && !fired) {

        event(EVENT_GTM_IMPRESSIONS_PLP, {
            products: items|| [],
            list: preLinkType,
            pathname: location?.pathname,
            search: location?.search
        });

        instance.setState({ fired: true });
    }

    callback.apply(instance, args);
};


const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData))
    };
};


export const config = {
    'Component/ProductLinks/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/ProductListWidget/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/RelatedProductsContainer/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/RecentlyViewedWidget/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/FreeGiftProductWidget/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/RelatedProducts/Container': {
        'member-function': {
            componentDidMount: addComponentDidMount
        }
    },
    'Component/RecentlyViewedWidget/Container': {
        'member-function': {
            // componentDidMount,
            componentDidUpdate
        },
        'member-property': {
            state
        }
    },
    'Component/ProductListWidget/Container': {
        'member-function': {
            componentDidUpdate: addComponentDidUpdateToWidget
        },
        'member-property': {
            state
        }
    },
    'Component/ProductLinks/Container': {
        'member-function': {
            componentDidUpdate: addComponentDidUpdateLinkProducts
        },
        'member-property': {
            state
        }
    },
    'Component/FreeGiftProductWidget/Container': {
        'member-function': {
            componentDidUpdate: addComponentDidMountToFreeGift
        }
    },
};

export default config;
