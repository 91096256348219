export const STORE_LIST = 'STORE_LIST';
export const STORE = 'STORE';
import { lazy } from 'react';
export const StoreLocator = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ '../route/StoreLocator'));
export const StorePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "brand" */ '../route/StorePage'));

const renderContent = (args, callback, instance) => {
    const { props, type } = instance.props;
    switch (type) {
        case STORE_LIST:
            return <StoreLocator { ...props } />;
        case STORE:
            return <StorePage { ...props } />;
        default:
            return callback.apply(instance, args);
    }
}

export const config = {
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent: [
                {
                    position:100,
                    implementation: renderContent
                }
            ]
        }
    }
};

export default config;
