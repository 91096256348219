import  { CmsBlockContainer as SourceCmsBlockContainer }  from 'SourceComponent/CmsBlock/CmsBlock.container';
import Loader from 'SourceComponent/Loader';
import RenderWhenVisible from 'SourceComponent/RenderWhenVisible';
import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import CmsBlockQuery from 'Query/CmsBlock.query'
import { connect } from 'react-redux';

const CmsBlocksAndSliderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CmsBlocksAndSlider/CmsBlocksAndSlider.dispatcher'
);

/** @namespace Route/CmsPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cmsBlocks: state.CmsBlocksAndSliderReducer.blocks
});

/** @namespace Component/CmsBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestCmsBlock: (options) => CmsBlocksAndSliderDispatcher.then(
        ({default: dispatcher}) => dispatcher.handleData(dispatch, options))
});

/** @namespace Component/CmsBlock/Container/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static defaultProps = {
        isVisible: false,
        cmsBlock: {},
        isHeader: false,
        noWrapper: false,
        isCmsBlockLoaded: false
    };

    containerProps = () => {
        const { blockType, cmsBlocks, cmsBlock: cmsBlockProp, isHeader, noWrapper } = this.props;
        const { cmsBlock } = this.state;
        
        return { 
                noWrapper,
                cmsBlock: isHeader && Object.values(cmsBlockProp).length > 0 ? cmsBlockProp: cmsBlock, 
                blockType 
            };
    };

    componentDidMount() {
        const { isHeader, identifier, cmsBlock } = this.props;

        if (!isHeader && identifier && !Object.values(cmsBlock).length > 0 ) {
            this._getCmsBlock();
        }
    }

    componentDidUpdate(prevProps) {
        const { identifier, isHeader, cmsBlocks } = this.props;
        const { identifier: prevIdentifier, cmsBlocks: prevCmsBlocks } = prevProps;
        const { isCmsBlockLoaded = false } = this.state;
        
        if((prevCmsBlocks !== cmsBlocks || !isCmsBlockLoaded) && 
            cmsBlocks.items[identifier]) {
            this.setCmsBlock(cmsBlocks.items[identifier])
        }

        if (identifier !== prevIdentifier && !isHeader) {
            this._getCmsBlock();
        }
    }


    setCmsBlock(cmsBlock){
        this.setState({
            cmsBlock,
            isCmsBlockLoaded: true
        })
    }

    _getCmsBlock() {
        const { identifier, requestCmsBlock, requestDefault } = this.props;
        
        // if(requestDefault) {
        // this.fetchData(
        //     [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
        //     ({ cmsBlocks: { items } }) => {
        //         if (!items.length) {
        //             return;
        //         } 

        //         this.setState({ cmsBlock: items[0] });
        //     }
        // );   
        // } else {
            requestCmsBlock({ identifiers: [identifier] });
        // }
    }

    renderSliderFallback() {
        return (
            <div block="WidgetFactory" elem="CmsBlockPlaceholder" />
        );
    }

    render() {
        const { isVisible, noWrapper } = this.props

        if(noWrapper) {
            return (
                <CmsBlock
                    { ...this.containerProps() }
                />
            );
        }

        return (
            <RenderWhenVisible 
                IsVisible={ isVisible } 
                fallback= { this.renderSliderFallback }
            >
                <CmsBlock
                  { ...this.containerProps() }
                />
            </RenderWhenVisible>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsBlockContainer);
