const beforeGetQuery = (args, callback, instance) => {
    return callback.apply(instance, [{ urlParam: encodeURIComponent(args[0].urlParam) }]);
};

export const config = {
    'Query/UrlRewrites': {
        'member-function': {
            getQuery: [
                {
                    position: 115,
                    implementation: beforeGetQuery
                }
            ]
        }
    }
};

export default config;
