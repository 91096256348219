import { connect } from 'react-redux';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { CheckoutGuestFormContainer as SourceCheckoutGuestFormContainer, mapStateToProps, mapDispatchToProps } from "SourceComponent/CheckoutGuestForm/CheckoutGuestForm.container";
import {
    STATE_SIGN_IN
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';


/** @namespace Component/CheckoutGuestForm/Container */
export class CheckoutGuestFormContainer extends SourceCheckoutGuestFormContainer {

    containerFunctions = {
        handleEmailInput: this.handleEmailInput.bind(this),
        handleCreateUser: this.handleCreateUser.bind(this),
        handlePasswordInput: this.handlePasswordInput.bind(this),
        handleForgotPassword: this.handleForgotPassword.bind(this),
        handleSignIn: this.handleSignIn.bind(this),
        handleCreateAccount: this.handleCreateAccount.bind(this),
        onFormError: this.onFormError.bind(this),
        setSignInState: this.setSignInState.bind(this),
        setLoadingState: this.setLoadingState.bind(this),
        onSignIn: this.onSignIn.bind(this),
        switchLoginOption: this.switchLoginOption.bind(this)
    };

    static defaultProps = {
        emailValue: '',
        isGuestEmailSaved: false
    };

    onSignIn(data){
        const { onSignIn } = this.props;
        onSignIn();
    }

    containerProps = () => {
        const { emailValue, isEmailAvailable, setCheckoutStep, setLoadingState } = this.props;
        const { isLoading, signInState, login_option } = this.state;

        return ({
            formId: SHIPPING_STEP,
            emailValue,
            isEmailAvailable,
            isLoading,
            signInState,
            setCheckoutStep,
            setLoadingState,
            login_option,
            email : this.state.email
        });
    };

    state = {
        isLoading: false,
        signInState: STATE_SIGN_IN,
        email : '',
        login_option : 0,
    };

    handleEmailInput(email) {
        const { onEmailChange } = this.props;
        this.setState({email : email});
        onEmailChange(email);
    }

    switchLoginOption(index){
        this.setState({
            login_option : index
        })
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutGuestFormContainer);
