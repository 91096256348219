import Loader from 'Component/Loader';
import Field from 'Component/Field';
import {VALIDATION_STATUS} from 'Component/Field/Field.config';
import '../style/CartCouponComponentPlugin.style.scss';
import getStore from 'Util/Store';
import { showNotification } from 'Store/Notification/Notification.action';

class CartCouponComponentPlugin {
    handleRemoveCoupons(args, callback, instance) {
        const {handleRemoveCouponFromCart} = instance.props;
        handleRemoveCouponFromCart();
        instance.setState({
            enteredCouponCode: ''
        });
    };

    handleRemoveCoupon(args, callback, instance) {
        const { dispatch } = getStore();
        let removedCode = args[0];
        let {couponCode: couponCodes, handleApplyCouponToCart, handleRemoveCouponFromCart} = instance.props;
        couponCodes = couponCodes ? couponCodes.split(',') : [];
        couponCodes = couponCodes.filter(
            code => code !== removedCode
        );
        if (couponCodes.length){
            handleApplyCouponToCart(couponCodes.join(','));
            setTimeout(()=>{
                dispatch(showNotification('success', __('Coupon was removed!')));
            },1500);
        }
        else
            handleRemoveCouponFromCart();

        instance.setState({
            enteredCouponCode: ''
        });
    }

    handleApplyCoupon(args, callback, instance) {
        let {couponCode: couponCodes, handleApplyCouponToCart} = instance.props;
        const { dispatch } = getStore();
        const { enteredCouponCode } = instance.state;
        couponCodes = couponCodes ? couponCodes.split(',') : [];
        if(couponCodes.includes(enteredCouponCode)){
            dispatch(showNotification('info', __('Coupon already applied!')));
            instance.setState({
                enteredCouponCode: ''
            });
            return;
        }
        couponCodes.push(enteredCouponCode)
        handleApplyCouponToCart(couponCodes.join(','));
        instance.setState({
            enteredCouponCode: ''
        });
    }

    renderApplyCoupon(args, callback, instance) {
        const {enteredCouponCode} = instance.state;
        const {couponCode} = instance.props;
        const {skip, success} = VALIDATION_STATUS;

        return (
            <>
                <Field
                    type="text"
                    id="couponCode"
                    name="couponCode"
                    value={enteredCouponCode}
                    placeholder={__('Your discount code')}
                    onChange={instance.handleCouponCodeChange}
                    customValidationStatus={!enteredCouponCode ? skip : success}
                    mix={{block: 'CartCoupon', elem: 'Input'}}
                    aria-label={__('Your discount code')}
                />
                <div className="button-container">
                    {
                        instance.state.enteredCouponCode
                        &&
                        <button
                            block="CartCoupon"
                            elem="Button"
                            type="button"
                            mods={{isHollow: true}}
                            disabled={!enteredCouponCode}
                            onClick={instance.handleApplyCoupon}
                        >
                            {__('Apply Coupon')}
                        </button>
                    }
                    {
                        couponCode
                        &&
                        <button
                            block="CartCoupon"
                            elem="Button"
                            type="button"
                            mix={{block: 'Button'}}
                            onClick={instance.handleRemoveCoupons}
                        >
                            {__('Remove All')}
                        </button>
                    }
                </div>
            </>
        );
    }
    componentDidUpdate(args, callback, instance) {
        let [prevProps,prevState] = args
        if(
            instance.props.couponCode
            &&
            (
                !prevProps.couponCode
                ||
                instance.props.couponCode.split(',').length > prevProps.couponCode?.split(',')?.length
            )
        ){
            const { dispatch } = getStore();
            dispatch(showNotification('success', __('Coupon was applied!')));
        }
    }

    handleFormSubmit(args, callback, instance) {
        let e = args[0];
        e.preventDefault();
        if (!instance.state.enteredCouponCode) {
            return;
        }
        instance.handleApplyCoupon();
    };

    render(args, callback, instance) {
        let {couponCode: couponCodes} = instance.props;
        couponCodes = couponCodes?.split(',')
        const {isLoading, couponCode, mix} = instance.props;
        return (
            <form block="CartCoupon" onSubmit={instance.handleFormSubmit} mix={mix}>
                <Loader isLoading={isLoading}/>
                {instance.renderTitle()}
                <div block="CartCoupon" elem="Message">
                    { couponCodes && __('Applied coupon codes: ')}
                    <div className='coupons'>
                        {
                            couponCodes?.map(
                                couponCode =>
                                    <div className='coupon'>
                                        <p><strong>{couponCode.toUpperCase()}</strong></p>
                                        <p className='close' onClick={
                                            () => instance.handleRemoveCoupon(couponCode)
                                        }>X</p>
                                    </div>
                            )
                        }
                    </div>
                    {instance.renderApplyCoupon()}
                </div>
            </form>
        );

    }
}

const {
    render,
    renderApplyCoupon,
    handleApplyCoupon,
    handleRemoveCoupon,
    handleRemoveCoupons,
    handleFormSubmit,
    componentDidUpdate
} = new CartCouponComponentPlugin();
export const config = {
    'Component/CartCoupon/Component': {
        'member-function': {
            render: render,
            renderApplyCoupon: renderApplyCoupon,
            handleApplyCoupon: handleApplyCoupon,
            handleRemoveCoupon: handleRemoveCoupon,
            handleRemoveCoupons: handleRemoveCoupons,
            componentDidUpdate: componentDidUpdate,
            handleFormSubmit: handleFormSubmit
        }
    }
};

export default config;
