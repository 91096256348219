/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';

import CustomPromoPopup from './CustomPromoPopup.component';
import {
    CUSTOM_BRANDS_POPUP_IDENTIFIER,
    CUSTOM_BRANDS_PROMO_POPUP_ID,
    CUSTOM_BRANDS_PROMO_POPUP_BROWSER_ITEM
} from "./CustomPromoPopup.config";
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

/** @namespace Component/CustomPromoPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (message) => dispatch(showNotification('success', message)),
    showError: (message) => dispatch(showNotification('error', message)),
    showPopup: (id, payload) => dispatch(showPopup(id, payload)),
    hidePopup: () => dispatch(showPopup('', {})),
});

/** @namespace Component/CustomPromoPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    blocks: state.CmsBlocksAndSliderReducer.blocks,
    isFashion: state.switchWebsiteReducer.isFashion,
    activeOverlay: state.OverlayReducer.activeOverlay,
});

/** @namespace Component/CustomPromoPopup/Container */
export class CustomPromoPopupContainer extends PureComponent {
    static propTypes = {
        showError: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        hidePopup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired,
        identifier: PropTypes.string.isRequired
    };

    static defaultProps = {
        showCallButton: false
    };
    state = {
        id: '',
        item: '',
    }

    containerFunctions = {
        openCustomPromoPopup: this.openCustomPromoPopup.bind(this)
    };

    containerProps() {
        const { showCallButton, blocks, identifier } = this.props;

        return { showCallButton, blocks, identifier, ...this.state };
    }

    openCustomPromoPopup(identifier) {
        const { showPopup } = this.props;

        let browserID = '';
        let popupID = ''
        switch (identifier) {
            case CUSTOM_BRANDS_POPUP_IDENTIFIER:
                browserID = CUSTOM_BRANDS_PROMO_POPUP_BROWSER_ITEM
                popupID = CUSTOM_BRANDS_PROMO_POPUP_ID
                this.setState({ id: popupID, item: browserID });
                break;
            default:
                console.log("wrong identifier");
                break;
        }

        if (!BrowserDatabase.getItem(browserID)) {
            setTimeout(function () {
                showPopup(popupID);
            }, 2000);
        }
    }

    render() {
        return (
            <CustomPromoPopup
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPromoPopupContainer);
