const _getCustomerFields = (args, callback, instance) => {
    return [
        ...callback.apply(instance, args),
        'is_admin'
    ];
}

export default {
    'Query/MyAccount': {
        'member-function': {
            _getCustomerFields: _getCustomerFields
        }
    }
    
};