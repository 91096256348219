import { PureComponent } from 'react';

import './CopyIcon.style';

/** @namespace Component/CopyIcon/Component */
export class CopyIcon extends PureComponent {
    render() {
        return (
            <svg 
            block="CopyIcon" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            >
            <rect x="9.5" y="9.5" width="12" height="12" stroke="black"/>
            <path d="M7 16H3V3H16V7" stroke="black"/>
            </svg>
        );
    }
}

export default CopyIcon;
