import { fetchQuery } from 'Util/Request';

import GiftCardEmailPreviewProcessorQuery from '../query/GiftCardEmailPreviewProcessor.query';
import { GIFTPRODUCT } from './ProductAction.component.plugin';

export class ProductActionContainer {
    state = (prop, instance) => ({
        ...prop,
        minMaxCustomPriceString: null,
        giftOptions: {},
        showGiftCardPreviewOverlay: false,
        htmlContent: ''
    });

    containerFunctions = (prop, instance) => ({
        ...prop,
        setGiftOptions: instance.setGiftOptions.bind(instance),
        hideGiftCardPreviewProcessor: instance.hideGiftCardPreviewProcessor.bind(instance),
        buttonClick: instance.buttonClick.bind(instance)
    });

    componentDidUpdate = (args, callback, instance) => {
        const { product: { type_id, am_giftcard_images } } = instance.props;
        const { giftOptions: { am_giftcard_image }, minMaxCustomPriceString } = instance.state;
        if (type_id === GIFTPRODUCT) {
            if (minMaxCustomPriceString === null) {
                instance.setState({
                    minMaxCustomPriceString: this._getMinMaxCustomPriceString(instance)
                });
            }
            if (instance.state.showGiftCardPreviewOverlay === true) {
                instance.requestForGiftCardOverlay();
            }
            if (am_giftcard_images === undefined) {
                return;
            }

            if (am_giftcard_image === undefined) {
                const enabledCardImage = am_giftcard_images.find((item) => item.status === 1);
                if (enabledCardImage) {
                    instance.setGiftOptions({ am_giftcard_image: enabledCardImage.image_id });
                }
            }
        }

        return callback.apply(instance, args);
    };

    buttonClick = (args, callback, instance) => {
        if (instance.validateGiftCardPreview()) {
            instance.setState(
                {
                    showGiftCardPreviewOverlay: true
                }
            );
        }
    };

    validateGiftCardPreview(args, callback, instance) {
        const {
            giftOptions: {
                am_giftcard_image,
                am_giftcard_sender_name,
                am_giftcard_recipient_name,
                am_giftcard_recipient_email,
                am_giftcard_amount_custom,
                is_date_delivery,
                am_giftcard_date_delivery,
                am_giftcard_date_delivery_timezone
            }
        } = instance.state;
        const { am_display_option_fields, showNotification } = instance.props;
        if (am_giftcard_recipient_email === undefined) {
            showNotification('info', __('Please specify recipient email!'));
            return false;
        }
        if (!am_giftcard_recipient_name && am_display_option_fields.includes('am_giftcard_recipient_name')) {
            showNotification('info', __('Please specify recipient name!'));
            return false;
        }
        if (am_giftcard_sender_name === undefined && am_display_option_fields.includes('am_giftcard_sender_name')) {
            showNotification('info', __('Please specify your name!'));
            return false;
        }
        if(am_display_option_fields.includes('am_giftcard_sender_name') && /[!@#$%^&*(),.?":{}|<>\d]/.test(am_giftcard_sender_name)){
           showNotification('info', __('Digits and special characters are not accepted in name fields!'));
           return false
        }
        if(am_display_option_fields.includes('am_giftcard_recipient_name') && /[!@#$%^&*(),.?":{}|<>\d]/.test(am_giftcard_recipient_name)){
           showNotification('info', __('Digits and special characters are not accepted in name fields!'));
           return false
        }
        if (am_giftcard_image === undefined) {
            showNotification('info', __('Please specify gift card image!'));
            return false;
        }
        if (am_giftcard_amount_custom === undefined) {
            showNotification('info', __('Please specify gift card amount!'));
            return false;
        }
        if (is_date_delivery && is_date_delivery === '1' && am_display_option_fields.includes('am_giftcard_date_delivery')) {
            if (am_giftcard_date_delivery === undefined) {
                showNotification('info', __('Please delivery date'));
                return false;
            }
            if (am_giftcard_date_delivery_timezone === undefined) {
                showNotification('info', __('Please specify timezone'));
                return false;
            }
        }

        return true;
    }

    requestForGiftCardOverlay = (args, callback, instance) => {
        const {
            giftOptions: {
                am_giftcard_sender_name,
                am_giftcard_recipient_name,
                am_giftcard_recipient_email,
                am_giftcard_message,
                am_giftcard_image,
                am_giftcard_amount_custom
            }
        } = instance.state;
        const am_fields = {
            am_giftcard_fields: {
                am_giftcard_sender_name,
                am_giftcard_recipient_name,
                am_giftcard_recipient_email,
                am_giftcard_message,
                am_giftcard_amount: am_giftcard_amount_custom,
                am_giftcard_image
            }
        };

        fetchQuery(GiftCardEmailPreviewProcessorQuery.getGiftCardPreviewQuery(am_fields)).then(
            ({ emailPreviewProcessor: Content }) => {
                instance.setState({ ...Content });
            },
            (message) => {
                showNotification('error', message);
            }
        );
    };

    hideGiftCardPreviewProcessor = (args, callback, instance) => {
        instance.setState({ showGiftCardPreviewOverlay: false });
    };

    setGiftOptions = (args, callback, instance) => {
        instance.setState((state) => ({
            giftOptions: { ...state.giftOptions, ...args[0] }
        }));
    };

    getProductPrice = (args, callback, instance) => {
        const {
            product,
            base_currency_code,
            product: { variants = [], type_id }
        } = instance.props;

        if (type_id === GIFTPRODUCT) {
            const { am_giftcard_prices } = product;
            let { giftOptions: { am_giftcard_amount,am_giftcard_amount_custom } } = instance.state;
            if (am_giftcard_prices) {
                const am_giftcard_prices_value = parseInt(am_giftcard_prices[0].value);
                am_giftcard_amount = parseInt(am_giftcard_amount);
                const priceValue = {
                    value: am_giftcard_amount || am_giftcard_prices_value,
                    currency: base_currency_code
                };

                if (!am_giftcard_amount && !am_giftcard_amount_custom) {
                    instance.setGiftOptions({ am_giftcard_amount: am_giftcard_prices_value });
                }

                return {
                    minimum_price: {
                        final_price: priceValue,
                        regular_price: priceValue,
                        final_price_excl_tax: priceValue
                    }
                };
            }
        }

        return callback.apply(instance, args);
    };

    _getMinMaxCustomPriceString(instance) {
        const { product: { am_open_amount_min, am_open_amount_max }, base_currency_code } = instance.props;
        let placeHolder = '';
        if (am_open_amount_min && am_open_amount_max) {
            placeHolder = `${base_currency_code}${parseInt(am_open_amount_min).toFixed(2)} - ${base_currency_code}${parseInt(am_open_amount_max).toFixed(2)}`;
        } else if (am_open_amount_min) {
            placeHolder = `Min: ${base_currency_code}${parseInt(am_open_amount_min).toFixed(2)}`;
        } else if (am_open_amount_max) {
            placeHolder = `Max: ${base_currency_code}${parseInt(am_open_amount_max).toFixed(2)}`;
        }

        return placeHolder;
    }

    containerProps(args, callback, instance) {
        return {
            ...callback.apply(instance, args),
            giftOptions: instance.state.giftOptions,
            am_display_option_fields: instance.props.am_display_option_fields,
            showGiftCardPreviewOverlay: instance.state.showGiftCardPreviewOverlay,
            htmlContent: instance.state.htmlContent,
            base_currency_code: instance.props.base_currency_code
        };
    }
}

const {
    state,
    componentDidUpdate,
    getProductPrice,
    containerFunctions,
    setGiftOptions,
    hideGiftCardPreviewProcessor,
    requestForGiftCardOverlay,
    validateGiftCardPreview,
    buttonClick,
    containerProps
} = new ProductActionContainer();

export default {
    'Component/ProductActions/Container': {
        'member-function': {
            componentDidUpdate,
            getProductPrice,
            setGiftOptions,
            hideGiftCardPreviewProcessor,
            requestForGiftCardOverlay,
            validateGiftCardPreview,
            buttonClick,
            containerProps
        },
        'member-property': {
            state,
            containerFunctions
        }
    }
};
