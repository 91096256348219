import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { fetchMutation, fetchQuery } from 'Util/Request';

import GiftCardDashBoardQuery from '../../query/GiftCardDashBoard.query';
import { fetchCodes } from './GiftCardDashBoard.action';

export const LOAD_CODES = 'load_codes';

/** @namespace Mageguidepwahondosc/Giftcard/Plugin/Store/GiftCardDashBoard/Dispatcher/GiftCardDashBoardDispatcher */
export class GiftCardDashBoardDispatcher {
    getCodes(dispatch) {
        fetchQuery(
            GiftCardDashBoardQuery.getCodesFromAccount()
        ).then(
            /** @namespace Mageguidepwahondosc/Giftcard/Plugin/Store/GiftCardDashBoard/Dispatcher/fetchQuery/then */
            ({ giftCardsFromAccount }) => {
                BrowserDatabase.setItem(giftCardsFromAccount, LOAD_CODES);
                dispatch(fetchCodes(giftCardsFromAccount));
            }
        );
    }

    removeGiftCardFromAccountDispatch(dispatch, code) {
        fetchMutation(
            GiftCardDashBoardQuery.removeCardQuery(code)
        ).then(
            /** @namespace Mageguidepwahondosc/Giftcard/Plugin/Store/GiftCardDashBoard/Dispatcher/fetchMutation/then */
            () => {
                this.getCodes(dispatch);
                dispatch(showNotification('success', __('GiftCard code is removed!')));
            },
            /** @namespace Mageguidepwahondosc/Giftcard/Plugin/Store/GiftCardDashBoard/Dispatcher/fetchMutation/then */
            (errors) => {
                dispatch(showNotification('error', errors[0].message));
            }
        );
    }

    addCardInAccountDispatch(dispatch, code) {
        fetchMutation(
            GiftCardDashBoardQuery.addCardQuery(code)
        ).then(
            /** @namespace Mageguidepwahondosc/Giftcard/Plugin/Store/GiftCardDashBoard/Dispatcher/fetchMutation/then */
            () => {
                this.getCodes(dispatch);
                dispatch(showNotification('success', __('GiftCard code is added!')));
            },
            /** @namespace Mageguidepwahondosc/Giftcard/Plugin/Store/GiftCardDashBoard/Dispatcher/fetchMutation/then */
            (errors) => {
                dispatch(showNotification('error', errors[0].message));
            }
        );
    }
}

export default new GiftCardDashBoardDispatcher();
