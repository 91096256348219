import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';
import BrowserDatabase from 'SourceUtil/BrowserDatabase';
import GoogleTagManagerQuery from '../../query/GoogleTagManager.query';

const addGTMConfigToConfigQuery = (args, callback, instance) => {
    const original = callback(...args);
    return [
        ...(Array.isArray(original) ? original : [original]),
        GoogleTagManagerQuery.getGTMConfiguration()

    ];
};

const addGTMConfigToConfigReducerInitialState = (args, callback, instance) => {
    const gtm = BrowserDatabase.getItem('config')?.gtm || { 
        enabled: false,
        events: {}
    };

    return {
        ...callback(...args),
        gtm
    };
};

const addGTMConfigToConfigUpdate = (args, callback, context) => {
    const [, action] = args;
    const originalUpdatedState = callback.apply(context, args);

    if (!action) {
        return originalUpdatedState;
    }

    const { config: { gtm } = {}, type } = action;

    if (type !== UPDATE_CONFIG) {
        return originalUpdatedState;
    }

    return {
        ...originalUpdatedState,
        gtm
    };
};

export default {
    'Store/Config/Dispatcher': {
        'member-function': {
            'prepareRequest': addGTMConfigToConfigQuery
        }
    },
    'Store/Config/Reducer/getInitialState': {
        'function': addGTMConfigToConfigReducerInitialState
    },
    'Store/Config/Reducer': {
        'function': addGTMConfigToConfigUpdate
    },
};
