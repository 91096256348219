import ContentWrapper from 'Component/ContentWrapper';
import CmsBlock from 'SourceComponent/CmsBlock';
import { NoMatch as SourceNoMatch } from 'SourceRoute/NoMatch/NoMatch.component';

export class NoMatch extends SourceNoMatch {

    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <CmsBlock identifier={ 'noroute_content_cms_block' } />
                </ContentWrapper>
            </main>
        );
    }
}

export default NoMatch;
