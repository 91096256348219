import { TYPE_BRAND_ATTRIBUTE, TYPE_BRAND } from './UrlRewrites.component.plugin'
const getTypeSpecificProps = (args, callback, instance) => {
    const {
        urlRewrite
    } = instance.props;
    const isLoading = instance.getIsLoading();
    if(instance.getType() === TYPE_BRAND_ATTRIBUTE){
        if (isLoading) {
            const category = history?.state?.state?.category;

            if (category && category !== true) {
                return { categoryIds: category };
            }

            return {};
        }

        return { categoryIds: urlRewrite.id , urlRewrite};
    }
    if(instance.getType() === TYPE_BRAND){
        return {
            attribute_code: urlRewrite.attribute_code ,
            canonical_url : urlRewrite.canonical_url,
            meta_title : urlRewrite.meta_title,
            meta_description : urlRewrite.meta_description,
            description : urlRewrite.description
        };
    }
    return callback.apply(args,instance);
}

export const config = {
    'Route/UrlRewrites/Container': {
        'member-function': {
            getTypeSpecificProps: [
                {
                    position:100,
                    implementation: getTypeSpecificProps
                }
            ]
        }
    }
};

export default config;
