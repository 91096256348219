import { Field } from 'SourceUtil/Query';

const _getUrlResolverFields = (args, callback, instance) => {

    return [
        ...callback(instance, args),
        'is_fashion'
    ];
}
 
export default {
    'Query/UrlRewrites': {
        'member-function': {
            _getUrlResolverFields
        }
    },
};
