import CheckoutSuccess from 'SourceComponent/CheckoutSuccess';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

export const GoogleTagManagerDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/GoogleTagManager/GoogleTagManager.dispatcher'
);

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
        purchase:(options) => GoogleTagManagerDispatcher.then(({ default: dispatcher }) =>
             dispatcher.handleData(dispatch, options))
    };
};

const renderDetailsStep = (args, callback, instance) => {
    const {
        orderID,
        isEmailAvailable,
        email,
        event,
        paymentMethod,
        totals,
        shippingAddress,
        purchase,
        isMobile,
        setLoadingState,
        billingAddress: {
            firstname,
            lastname
        },
        customer,
        order
    } = instance.props;

    const fName = firstname ?? shippingAddress.firstName;
    const LName = lastname ?? shippingAddress.lastname;

    return ( 
        <CheckoutSuccess
          totals = { totals }
          shippingAddress= { shippingAddress }
          email={ email || customer?.email } 
          event={ purchase }
          firstName={ fName }
          lastName={ LName }
          isEmailAvailable={ isEmailAvailable }
          orderID={ orderID }
          order= { order }
          paymentMethod={ paymentMethod }
          isMobile ={ isMobile }
          setLoadingState={ setLoadingState }
        />
    );
};

const containerProps = (args, callback, instance) => {
    const {
        event,
        purchase
    } = instance.props;

    const { 
        order
    } = instance.state

    return {
        ...callback(...args),
        event,
        purchase,
        order
    };
}

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Route/Checkout/Container': {
        'member-function': { 
            containerProps
        }
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep
        }
    }
};
