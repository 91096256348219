const render = (args, callback, instance) => {
    window.client_token = null;
    return callback.apply(instance,args);
}

export const config = {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            render
        }
    }
};

export default config;
