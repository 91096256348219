import { lazy } from 'react';
import { BRAND_SLIDER  } from '../component/BrandWidget/BrandWidget.config';
export const BrandWidget = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-brand" */
    '../component/BrandWidget'
));

export const renderMap = (props, instance) => {
    return {
        ...props,
        [BRAND_SLIDER]: {
            component: BrandWidget
        }
    }
};

export default {
    'Component/WidgetFactory/Component': {
        'member-property': {
            'renderMap': renderMap,
        }
    }
};