import CategoryPaginationLink from 'Component/CategoryPaginationLink';

const renderPageLink = (args, callback, instance) => {
    const { visiblePage } = instance.props;

    if (visiblePage) {
        const [pageNumber, label, children, isCurrent] = args;
        const {
            pathname,
            onPageSelect,
            getSearchQuery,
            visiblePage
        } = instance.props;

        return (
            <li
                key={pageNumber}
                block="CategoryPagination"
                elem="ListItem"
            >
                <CategoryPaginationLink
                    label={label}
                    url_path={pathname}
                    getPage={onPageSelect}
                    isCurrent={isCurrent}
                    pageNumber={pageNumber}
                    getSearchQueryForPage={getSearchQuery}
                    visiblePage={visiblePage}
                >
                    {children}
                </CategoryPaginationLink>
            </li>
        );
    }
    return callback.apply(instance, args);
}
export default {
    'Component/CategoryPagination/Component': {
        'member-function': {
            renderPageLink: [
                {
                    position: 300,
                    implementation: renderPageLink
                }
            ]
        }
    }
};