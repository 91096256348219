import ConfigQuery from 'Query/Config.query';
import { UPDATE_CONFIG } from 'Store/Config/Config.action';
import BrowserDatabase from 'Util/BrowserDatabase';
const prepareRequest = (args, callback, instance) => (
    [
        ...callback.apply(instance, args),
        ConfigQuery.getStoreListField(),
    ]
);
const addStoreListToReducer = (args, callback, instance) => {
    const [data,action] = args;
    if(action?.type === UPDATE_CONFIG){
        let storeList = [];
        if(BrowserDatabase){
            storeList = BrowserDatabase.getItem('config')?.storeList;
        }
        return {
            ...callback.apply(instance, args),
            storeList : storeList
        };
    }
    return callback.apply(instance, args);
};
const getInitialState = (args, callback, instance) => {
    return {
        ...callback.apply(instance, args),
        storeList : []
    };
};

export const config = {
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: [
                {
                    position: 100,
                    implementation: prepareRequest
                }
            ]
        }
    },
    'Store/Config/Reducer': {
        'function': addStoreListToReducer
    },
    'Store/Config/Reducer/getInitialState': {
        'function': getInitialState
    }
};

export default config;
