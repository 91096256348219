/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';

import ProductSizeGuidePopup from './ProductSizeGuidePopup.component';
import {PRODUCT_SIZE_GUIDE_POPUP_ID} from "./ProductSizeGuidePopup.config";

/** @namespace Component/ProductSizeGuidePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (message) => dispatch(showNotification('success', message)),
    showError: (message) => dispatch(showNotification('error', message)),
    showPopup: (payload) => dispatch(showPopup(PRODUCT_SIZE_GUIDE_POPUP_ID, payload)),
    hidePopup: () => dispatch(showPopup('', {}))
});

/** @namespace Component/ProductSizeGuidePopup/Container/mapStateToProps */
export const mapStateToProps = state => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Component/ProductSizeGuidePopup/Container/ProductSizeGuidePopupContainer */
export class ProductSizeGuidePopupContainer extends PureComponent {
    static propTypes = {
        showError: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        hidePopup: PropTypes.func.isRequired,
        showNotification: PropTypes.func.isRequired
    };

    static defaultProps = {
        showSizeGuideButton: false
    };

    containerFunctions = {
        openProductSizeGuidePopup: this.openProductSizeGuidePopup.bind(this)
    };

    containerProps() {
        const { showSizeGuideButton, product, isMobile } = this.props;
        return { showSizeGuideButton, product, isMobile };
    }

    openProductSizeGuidePopup(e) {
        e.preventDefault();

        const { showPopup } = this.props;
        showPopup({ title: __('ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ') });
    }

    render() {
        return (
            <ProductSizeGuidePopup
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSizeGuidePopupContainer);
