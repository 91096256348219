import { Field } from 'SourceUtil/Query';
import { isSignedIn } from 'SourceUtil/Auth';
import OrderQuery from 'SourceQuery/Order.query';

/** @namespace Query/AdminCustomerQuery */
export class AdminCustomerQuery {

    getLoginAsCustomerMutation(customerId) {
        const mutation = new Field('customer_admin_login')
            .addArgument('customer_id', 'String', customerId)
            .addField('success');

        return mutation;
    }

    getOrderByIdQuery(orderId) {
        const query = new Field('get_order_data');
            query.addArgument('id', 'String', orderId)
            .addFieldList(OrderQuery._getOrderItemsFields());

        return query;
    }
    
    getCustomerSearchQuery({ currentPage, fieldsToSearch }) {
        const query = new Field('search_customers');

        if(fieldsToSearch && Object.keys(fieldsToSearch).length > 0) {
            Object.entries(fieldsToSearch).forEach((element) => {
                query.addArgument(`${element[0]}`,'String', `${element[1]}`)
            });
        }

        query.addArgument('page','String', currentPage)
        query.addFieldList(this._getSearchCustomersResponse(false));

        return query
}

    getOrderListSearchQuery({ currentPage, fieldsToSearch }) {
        const query = new Field('search_orders');

        if(fieldsToSearch && Object.keys(fieldsToSearch).length > 0) {
            Object.entries(fieldsToSearch).forEach((element) => {
                query.addArgument(`${element[0]}`,'String', `${element[1]}`)
            });
        }

        query.addArgument('page','String', currentPage)
        query.addFieldList(this._getSearchCustomersResponse(true));

        return query
   }

    _getSearchCustomersResponse(isOrder){
        return [
            'page',
            'limit',
            'total',
             !isOrder ? 
                this._getCustomerList() : 
                this._getOrderList()
            ]
    }

    _getCustomerData(isOrder) {
        if(isOrder) {
        return [
            'email',
            'firstname',
            'lastname',
            'telephone',
            'mobile_phone',
            'customer_id',
            'order_id',
            'vat_id',
            'country_id',
            'created_at',
            'grand_total',
            'status'
            ];
        }

        return [
            'email',
            'firstname',
            'lastname',
            'telephone',
            'mobile_phone',
            'id',
        ]
    }

    _getOrderList(){
        return new Field('orders')
        .addFieldList(this._getCustomerData(true))
    }

    _getCustomerList(){
        return new Field('customers')
        .addFieldList(this._getCustomerData())
    }
}

export default new AdminCustomerQuery();