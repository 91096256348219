import { showNotification } from 'SourceStore/Notification/Notification.action';
import { isSignedIn } from 'SourceUtil/Auth';
import { getGuestQuoteId } from 'SourceUtil/Cart';
import { getErrorMessage, fetchMutation } from 'SourceUtil/Request';
import SampleProductsQuery from '../../../query/SampleProductsQuery.query';

const removeSampleProductFromCart = (args, callback, instance) => {
    const [ dispatch, itemids ] = args;

    if(!itemids?.length > 0) {
        return null;
    }
    
    try {
        const isCustomerSignedIn = isSignedIn();
        const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

        if (!isCustomerSignedIn && !guestQuoteId) {
            return null;
        }

         fetchMutation(SampleProductsQuery.getRemoveSampleItemsMutation(JSON.stringify(itemids), guestQuoteId)).then(
            ({removeSampleItems: { success } }) => success ?
                 instance.updateInitialCartData(dispatch) : 
                 dispatch(showNotification('error', 'Error refeteching cart items'))
            );
    } catch (error) {
        dispatch(showNotification('error', getErrorMessage(error)));
        return null;
    }
}

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            removeSampleProductFromCart: removeSampleProductFromCart
        }
    }
}