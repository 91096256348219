export const RelatedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/RelatedProducts/RelatedProducts.dispatcher'
);

import { getGuestQuoteId } from 'SourceUtil/Cart';

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        relatedProduct:(options) => RelatedProductsDispatcher.then(({ default: dispatcher }) =>
             dispatcher.handleData(dispatch, options))
    };
};

const mapStateToProps = (args, callback, instance) => {
    const { RelatedProductsReducer } = args[0];
    return {
            ...callback.apply(instance, args),
            relatedProducts: RelatedProductsReducer.relatedProducts
        }
};


const componentDidMount = (args, callback, instance) => {
    const { relatedProduct, relatedProducts, totals: { items = [] } } = instance.props;
    const { isRequested = false} = instance.state
    if (!isRequested && items.length > 0) {
        const key = Object.keys(items)?.length
        const { product: { id = '1' } = {} } = items[key - 1];
            relatedProduct({
                productId: getGuestQuoteId(),
                position: 'cart'
             });

             instance.setState({
                isRequested: true
             });
    }

    return callback.apply(instance, args);
}

const containerProps = (args, callback, instance) =>  {
    const { relatedProducts } = instance.props;

    return {
        ...callback.apply(instance, args),
        relatedProducts: relatedProducts
    };
};

const productLinks = (items) => {
 items.reduce((links, product) => {
    const { item_id } = product;
    return item_id;
    }, [])
};

const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;   
    const { totals, relatedProduct } = instance.props;
    const { totals: prevTotals } = prevProps;

    if(JSON.stringify(totals) !== JSON.stringify(prevTotals)) {
            relatedProduct({
                productId: getGuestQuoteId(),
                position: 'cart'
             });
    }

    return callback.apply(instance, args);
}

 
export default {
    'Route/CartPage/Container/mapDispatchToProps': {
        function: [
            {
                position: 102,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Route/CartPage/Container' : { 
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            containerProps
        }
    }
} 
