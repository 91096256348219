
/**
 *
 * @param originalMember
 * @param instance
 * @returns {*&{onBlur: *}}
 * scandi fix for no blure event on input
 */
const containerFunctions =  (originalMember,instance) => {
    return {
        ...originalMember,
        onBlur: instance.onBlur.bind(instance)
    };
}

export const config = {
    'Component/Field/Container': {
        'member-property': {
            containerFunctions: [
                {
                    position:100,
                    implementation: containerFunctions
                }
            ]
        }
    }
}

export default config;
