const getSubCategories = (args, callback, instance) => {
    const { childrenCategories } = instance.props;
    if(!childrenCategories){
        return {
            optionWithSubcategories : {
                attribute_values : []
            }
        }
    }
    return callback.apply(instance,args);
}

export const config = {
    'Component/CategoryConfigurableAttributes/Container': {
        'member-function': {
            getSubCategories: [
                {
                    position:100,
                    implementation: getSubCategories
                }
            ]
        }
    }
};

export default config;
