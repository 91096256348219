export class ConfigQueryPlugin {
    /**
     * Get config free shipping info fields
     * @returns [Field]
     * @private
     */
_aroundGetConfigQueryInfoFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'search_is_enabled',
        'search_project_key',
        'search_endpoint_url',
        'use_in_autocomplete',
        'use_in_fulltext'
    ];
}

export const { _aroundGetConfigQueryInfoFields } = new ConfigQueryPlugin();

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields: [
                {
                    position: 120,
                    implementation: _aroundGetConfigQueryInfoFields
                }
            ]
        }
    }
};
