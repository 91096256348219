import { connect } from 'react-redux';

import ExpandableHorizontal from './ExpandableHorizontal.component';

/** @namespace Component/ExpandableHorizontal/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});

/** @namespace Component/ExpandableHorizontal/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableHorizontal);
