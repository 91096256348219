
import { PDP } from "SourceComponent/Header/Header.config";
import { removeRichData } from '../store/RichData/RichData.action';

export const RichDataDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
    "../store/RichData/RichData.dispatcher"
);

export class ProductPageContainerPlugin {
    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            addRichData: (options) =>
                RichDataDispatcher.then(({ default: dispatcher }) =>
                          dispatcher.addRichdata(options, dispatch)
                      ),
            removeRichdata: (type, isExist) =>
                dispatch(removeRichData(type, isExist)),
        };
    };

    isExist = () => {
        if (document.getElementById(PDP)) {
            return true;
        }
        return false;
    };

    mountRichData = (instance, product) => {
        const { addRichData } = instance.props;
        const { configurableVariantIndex } = instance.state;

        const options = {
            name: PDP,
            isExist: this.isExist(),
            product: {...product, configurableVariantIndex }
        };
        addRichData(options);
    };

    unMountRichData = (instance) => {
        const { removeRichdata } = instance.props;
        removeRichdata(
            PDP, 
            this.isExist(),
        );
    }

    componentWillUnmount = (args, callback, instance) => {
        this.unMountRichData(instance);
        return callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [prevProps, prevState] = args;
        const { product: prevProduct, productSKU: prevProductSKU } = prevProps;
        const { product, productSKU, } = instance.props;
        const { configurableVariantIndex } = instance.state;
        const { configurableVariantIndex: prevConfigurableVariantIndex } = prevState;

        const areLoaded = Object.keys(product).length != 0 ? true : false;
        if (areLoaded &&
            (product != prevProduct ||
                prevProductSKU != productSKU ||
                configurableVariantIndex !== prevConfigurableVariantIndex)
        ) {
            this.mountRichData(instance, product)
        }

        return callback(...args);
    }
}

export const { componentWillUnmount, mapDispatchToProps, componentDidUpdate } = new ProductPageContainerPlugin();

export default {
    "Route/ProductPage/Container/mapDispatchToProps": {
        function: [
            {
                position: 100,
                implementation: mapDispatchToProps,
            },
        ],
    },
    "Route/ProductPage/Container": {
        "member-function": {
            componentWillUnmount: [
                {
                    position: 100,
                    implementation: componentWillUnmount,
                },
            ],
            componentDidUpdate: [
                {
                    position: 100,
                    implementation: componentDidUpdate,
                },
            ]
        },
    },
};