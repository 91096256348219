const _getStoreConfigFields = (args, callback, instance) => {
    return [
        ...callback(...args),
        'category_default_sort_by',
        'seo_separator',
        'seo_option_separator',
        'seo_available_sort_direction',
        'seo_default_sort_direction',
        'seo_canonicalized_pagination',
        'products_per_page'
    ];
};

export default {
    'Query/Config': {
        'member-function': {
            '_getStoreConfigFields': _getStoreConfigFields
        }
    }
};
