export class FooterContainerPlugin {

    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            banners: state.BannersReducer?.banners

        }
    };

    containerProps = (args, callback, instance) => {
        const { banners } = instance.props;

        return {
            ...callback.apply(instance, args),
            banners
        };
    }

}

export const { mapStateToProps, containerProps } = new FooterContainerPlugin();

export default {
    'Component/Footer/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/Footer/Container': {
        'member-function': {
            containerProps: containerProps
        }
    }
};
