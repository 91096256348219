import { CheckoutAddressBook as SourceCheckoutAddressBook } from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.component';
import Field from 'Component/Field';
import { isSignedIn } from 'Util/Auth';

export class CheckoutAddressBook extends SourceCheckoutAddressBook {

    renderHeading() {
        const { isBilling } = this.props;

        if (isBilling) {
            return null;
        }

        return (
            <h2 block="Checkout" elem="Heading">
                { __('SHIPPING ADDRESS') }
            </h2>
        );
    }

    renderAddressList() {
        const { customer: { addresses } } = this.props;
        if (!addresses) {
            return this.renderLoading();
        }
        if (!addresses.length) {
            return this.renderNoAddresses();
        }

        return addresses.map(this.renderAddress);
    }

    renderSaveAddressCheckBox(){
        const {
            isBilling,
            onSaveShippingAddressCheckboxSelect,
            onSaveBillingAddressCheckboxSelect,
            saveBillingAddress,
            saveShippingAddress
        } = this.props;

        if (!isSignedIn()) {
            return null;
        }
        const addressName = isBilling ?
            __('Save Billing address in address book') :
            __('Save Shipping address in address book')
        ;
        let onCheckboxSelect = isBilling ? onSaveBillingAddressCheckboxSelect : onSaveShippingAddressCheckboxSelect;
        let isChecked = isBilling ? saveBillingAddress : saveShippingAddress;
        return(
            <>
                <Field
                    id={isBilling ? "save_address_billing" : "save_address_shipping"}
                    name={isBilling ? "save_address_billing" : "save_address_shipping"}
                    label={ addressName }
                    type="checkbox"
                    onChange={onCheckboxSelect}
                    checked={isChecked}
                    mix={ { block: 'CheckoutBilling', elem: 'SaveAddressInformation-Checkbox' } }
                />
            </>
        );
    }

    render() {
        const { isCustomAddressExpanded } = this.state;
        return (
            <div block="CheckoutAddressBook">
                { this.renderHeading() }
                { this.renderContent() }
                { isCustomAddressExpanded && this.renderSaveAddressCheckBox() }
            </div>
        );
    }
}

export default CheckoutAddressBook;
