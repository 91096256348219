import { appendWithStoreCode } from 'Util/Url';

const BannersDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/Banners/Banners.dispatcher'
);

export class ProductActionsPlugin {
    mapStateToProps(args, callback, instance) {
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            urlRewrite: state.UrlRewritesReducer?.urlRewrite,
            banners: state.BannersReducer?.banners
        }
    };
    mapDispatchToProps(args, callback, instance) {
        const [dispatch] = args;

        return (dispatch) => ({
            ...callback.apply(args, instance),
            getBanners: (options) => BannersDispatcher.then(({ default: dispatcher }) => dispatcher.handleData(dispatch, options))
        })
    };

    containerProps(args, callback, instance) {
        const { urlRewrite, banners } = instance.props
        return {
            ...callback.apply(instance, args),
            urlRewrite: urlRewrite,
            banners: banners
        };
    };

    componentDidMount(args, callback, instance) {
        const { getBanners } = instance.props;
        const { pathname } = location;
        if (pathname === appendWithStoreCode('/') || pathname === '/') {
            getBanners()
        }
        return callback(...args);
    }

    // componentDidUpdate(args, callback, instance) {
    //     const { getBanners, banners } = instance.props;
    //     const { pathname } = location;
    //     if ((pathname === appendWithStoreCode('/') || pathname === '/') && banners) {
    //         getBanners()
    //     }
    //     return callback(...args);
    // }

    getMenuAndCmsBlock(args, callback, instance) {
        const { getMenuAndBlocks, cmsBlockidentifier, isFashion, urlRewrite, banners } = instance.props;
        const {
            footer_content: { footer_cms } = {},
            header_content: { header_cms } = {}
        } = window.contentConfiguration;

        let additionalIdentifiers = [];
        if (urlRewrite && urlRewrite.banners && urlRewrite.banners.popup && urlRewrite.banners.popup.length > 0) {
            additionalIdentifiers = urlRewrite.banners.popup;
        }

        if (banners && banners.popup && banners.popup.length > 0) {
            additionalIdentifiers = banners.popup;
        }

        const options = {
            identifiers: [
                header_cms || !isFashion ? cmsBlockidentifier : 'header_top_promo_fashion',
                'account_create_overlay',
                'account_forgot_password_overlay',
                'account_login_overlay',
                'myAccount_migration_message',
                'checkout_footer_links',
                'footer_middle_left',
                'footer_bottom',
                footer_cms || 'footer_links_block',
                'footer_top',
                'footer_middle_left',
                'fashion_popup',
                'footer_bottom',
                ...additionalIdentifiers
                // 'new_customers_promo_popup',
                // 'custom_brands_promo_popup'
            ],
            ...instance._getMenuOptions()
        }

        getMenuAndBlocks(options);
    }
}

export const { mapStateToProps, mapDispatchToProps, containerProps, getMenuAndCmsBlock, componentDidMount } = new ProductActionsPlugin();

export default {
    'Component/Header/Container/mapStateToProps': {
        function: [
            {
                position: 103,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/Header/Container/mapDispatchToProps': {
        function: [
            {
                position: 103,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/Header/Container': {
        'member-function': {
            containerProps,
            getMenuAndCmsBlock,
            componentDidMount,
            // componentDidUpdate
        },
    }
}
