import { CONFIGURABLE, SIMPLE } from 'SourceUtil/Product/Types';

import { NOT_APPLICABLE } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    getBrand,
    getCategories, 
    getCustomeAttribuite, 
    getPrice, 
    getProductName, 
    getStockStatus, 
    getVariant, 
    getDiscountPercentage
} from './baseProduct.data';

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionOne */
export const getDimensionOne = (type_id, product, configurableVariantIndex, mainSku) => {
    if (type_id === SIMPLE) {
        return NOT_APPLICABLE;
    }

    if (!configurableVariantIndex && mainSku && type_id === CONFIGURABLE) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku)?.name;
    }

    if (!isNaN(configurableVariantIndex) && product.variants[configurableVariantIndex]?.name) {
        const {
            name = ''
        } = product.variants[configurableVariantIndex];

        return name;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/getDimensionTwo */
export const getDimensionTwo = (type_id, product, configurableVariantIndex, inQtyChange = false, mainSku) => {
    if (type_id === SIMPLE) {
        return NOT_APPLICABLE;
    }

    if (!isNaN(configurableVariantIndex)) {
        if (inQtyChange) {
            return mainSku;
        }
        const {
            sku
        } = product.variants[configurableVariantIndex];

        return sku;
    }
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productAddToCart */
export const productAddToCart = ({ product, quantity = 1, configurableVariantIndex = false }) => {
    const {
        type_id = SIMPLE, categories, sku, price = false, variants
    } = product;

    // const mainSku = variants[configurableVariantIndex] ? variants[configurableVariantIndex].sku : sku;

    const data = {
        name: getProductName(type_id, product),
        title: getProductName(type_id, product),
        id: sku,
        item_price: getPrice(product, type_id, price),
        price: getPrice(product, type_id, price),
        category: getCategories(categories),
        brand: getBrand(product),
        quantity,
        // business_unit: getCustomeAttribuite(product, 'att_108'),
        google_business_vertical: 'retail',
        dimension3: getCustomeAttribuite(product, 'att_108'),
        dimension4: getCustomeAttribuite(product, 'badge_newin') || '',
        dimension2: getStockStatus(product, type_id, variants),
        dimension6: getCustomeAttribuite(product, 'mg_custom_lists'),
        dimension5: (type_id === CONFIGURABLE)
        ? getDiscountPercentage(variants[0], type_id, price, false)
        : getDiscountPercentage(product, type_id, price, false),
        // dimension1: (type_id === CONFIGURABLE && !click) ? mainSku : NOT_APPLICABLE,
        // dimension1: getDimensionOne(type_id, product, configurableVariantIndex, mainSku),
        // dimension2: getStockStatus(product, type_id, variants)
        // dimension2: getDimensionTwo(type_id, product, configurableVariantIndex, false, mainSku)
    };

    if(type_id === CONFIGURABLE) {
        data.variant = getVariant(product, type_id, configurableVariantIndex);
    }

    return data;
};

/** @namespace GtmNew/EventData/ActionProduct/Data/productQtyChangeData */
export const productQtyChangeData = ({ product: item, configurableVariantIndex = false }) => {
    const {
        qty = false, 
        price, 
        sku: mainSku = '', 
        product: {
            type_id = SIMPLE, 
            categories, 
            sku: parentSku, 
            name: parentName, 
            variants = {}
        } = {}, 
        product
    } = item;

    const quantity = Math.abs(qty) || 1;

    const data = {
        name: parentName,
        id: parentSku,
        price: (type_id === CONFIGURABLE)
            ? getPrice(variants[0], type_id, price, false)
            : getPrice(product, type_id, price, false),
        item_price: ((type_id === CONFIGURABLE)
            ? getPrice(variants[0], type_id, price, false)
            : getPrice(product, type_id, price, false)),
        category: getCategories(categories),
        brand: getBrand(product),
        variant: getVariant(product, type_id, configurableVariantIndex),
        quantity,
        dimension3: getCustomeAttribuite(product, 'att_108'),
        dimension4:getCustomeAttribuite(product, 'badge_newin') || '',
        dimension2: getStockStatus(product, type_id, variants),
        dimension5: (type_id === CONFIGURABLE)
        ? getDiscountPercentage(variants[0], type_id, price, false)
        : getDiscountPercentage(product, type_id, price, false),
        dimension6: getCustomeAttribuite(product, 'mg_custom_lists')
        // dimension1: getDimensionOne(type_id, product, configurableVariantIndex, mainSku),
        // dimension2: getStockStatus(product, type_id, variants)
        // dimension2: getDimensionTwo(type_id, product, configurableVariantIndex, true, mainSku)
    };

    if(type_id === CONFIGURABLE) {
        data.variant = getVariant(product, type_id, configurableVariantIndex);
    }

    return data;
};
