import { Field, Fragment } from 'Util/Query';

export class ProductListQueryPlugin {
    _getAppliedProducts() {
        return new Field('applied_products')
            .addFieldList([
                'id',
                'sku',
                'name',
            ]);
    }

    _getParentProducts() {
        return new Field('parent_products')
            .addFieldList([
                'id',
                'sku',
                'name',
            ]);
    }

    _getProductLinkFields = (args, callback, instance) => ([
        ...callback.apply(instance, args),
        'discount_type',
        'discount',
        _getAppliedProducts(),
        _getParentProducts()
    ])

}


const { _getProductLinkFields, _getAppliedProducts, _getParentProducts } = new ProductListQueryPlugin();

export default {
    'Query/ProductList': {
        'member-function': {
            '_getProductLinkFields': _getProductLinkFields,
            _getParentProducts,
            _getAppliedProducts
        }
    }
};
