import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { GUEST_WISHLIST } from '../route/GuestWishlist/GuestWishlist.config'
export const withStoreRegex = (path) => window.storeRegexText.concat(path);
export const GuestWishlist = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "guest-wishlist" */ '../route/GuestWishlist'));

export class RouterPlugin {
    SWITCH_ITEMS_TYPE = (originalMember) => [
        ...originalMember,
        {
            component: <Route path={ withStoreRegex(`/${GUEST_WISHLIST}`) } exact component={ GuestWishlist } />,
            position: 91
        }
    ];
}

const {
    SWITCH_ITEMS_TYPE
} = new RouterPlugin();

export const config = {
    'Route/UrlRewrites/Component': {
        'member-property': {
            SWITCH_ITEMS_CUSTOM: SWITCH_ITEMS_TYPE
        }
    }
};

export default config;
