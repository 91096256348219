export const convertQueryStringToKeyValuePairs = (_args, _callback) => {
    const [queryString] = _args;
    const keyValuePairs = {};
    const params = queryString.substring(1).split('&');

    params.forEach((param) => {
        const pair = param.split('=');
        const [keyPair, valuePair = []] = pair;

        if (keyPair.length > 0 && valuePair.length > 0) {
            try {
                const decodedValue = decodeURIComponent(valuePair);
                keyValuePairs[keyPair] = decodedValue;
            } catch (error) {
                console.error(`Error decoding '${valuePair}': ${error.message}`);
            }
        }
    });

    return keyValuePairs;
};

export default {
    'Util/Url/convertQueryStringToKeyValuePairs': {
        function: convertQueryStringToKeyValuePairs
    }
  };