const componentDidMount = (args, callback, instance) => {
    document.addEventListener(
        "updateSelectedShippingMethod",
        (data) => {
            const { detail : method } = data;
            instance.onShippingMethodSelect(method);
        },
        false,
    );
    return callback.apply(instance,args);
}

export const config = {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            componentDidMount
        }
    }
};

export default config;
