export const getVariantIndexByValue = (configurable_options, key, value) => {
    const arrayValue = configurable_options[key]?.attribute_values || [];
    var valueKey = -1;

    if(arrayValue?.length > 0) {
        arrayValue.forEach((arraValue, key) => { 
            if(arraValue == value){
                valueKey = key;
            }
        })
    }

    return valueKey;
}

export const initCurrentLookObject = () => {
    const upcInfo = getMakeupUpcData('test');

    const lipcolorUpcList = Object.keys(upcInfo.lipcolor);
    const eyeshadowUpcList = Object.keys(upcInfo.eyeshadow);

    const swatchList = ['swatch1', 'swatch2', 'swatch3', 'swatch4', 'swatch5'];

    for (let i = 0; i < 5; i += 1) {
        lookObjInput.lipcolor[swatchList[i]] = upcInfo.lipcolor[lipcolorUpcList[i]].shadeArray[0];
        lookObjInput.eyeshadow[swatchList[i]] = upcInfo.eyeshadow[eyeshadowUpcList[i]].shadeArray[0];
    }

    return lookObjInput;
}

var liveResourceLoaded = true;

export const hideAdjustCtas = () => {
    document.getElementById('adjust-cta').style.display = 'none';
    document.getElementById('adjust-collapse-cta').style.display = 'none';
    document.getElementById('adjust-left-eye-cta').style.display = 'none';
    document.getElementById('adjust-right-eye-cta').style.display = 'none';
    document.getElementById('adjust-lips-cta').style.display = 'none';
}

export const startLive =  (data) => {
    var currentMode = 'LIVE_MODE';
    // loading();

    try {
         window.MFE_VTO.startLiveMode();
    } catch (e) {
        document.getElementById('gif-loader').style.display = 'none';
        document.getElementById('error-screen').style.display = 'block';
        return;
    }

    if(Object.values(data) > 0) {
    const canvas =  window.MFE_VTO.setLiveLook({ lookId: 'canvas', lookObject: Object.values(data) });
    const originalCanvas =  window.MFE_VTO.setLiveLook({ lookId: 'canvas-original', lookObject: [] });
    
    canvas.renderedCanvas.style.height = '100%';
    originalCanvas.renderedCanvas.style.height = '100%';

    // Css settings
    document.getElementById('rendered-canvas-container').appendChild(canvas.renderedCanvas);
    document.getElementById('orig-canvas-container').appendChild(originalCanvas.renderedCanvas);
    adjustMargin('orig-canvas-container', 'rendered-canvas-container');
    // resetAll();
    // hideAdjustCtas();
        }
    // }
    // loaded();
}

// Positions before/after images
export const adjustMargin = (id, idRendered) => {
    const canvasDiv = document.getElementById(id);
    const canvas = canvasDiv.firstChild;
    const idRender = document.getElementById(idRendered)
    const dashboardId = idRendered.indexOf('compare') !== -1 ? idRendered : 'app-container';
    const dashboard = document.getElementById(dashboardId);
    const width = canvas.width;
    const height = canvas.height;

    let leftDist = dashboard.clientHeight * width / height;

    leftDist = (dashboard.clientWidth - leftDist) / 2;

    if(canvasDiv && idRendered.indexOf('compare') == -1 ) {
        canvasDiv.querySelector('canvas').style.left = `${Math.round(leftDist)}px`;
    }
    if(idRender) {
        idRender.querySelector('canvas').style.left = `${Math.round(leftDist)}px`;
    }
}

export const removeMakeup = () => {
    currentLookObj.lipcolor = DEFAULT_LIPCOLOR;
    currentLookObj.eyeshadow = DEFAULT_EYESHADOW;
    window.MFE_VTO.lookChangeFromProduct({ mode: currentMode, lookId: 'canvas', lookObject: Object.values(currentLookObj) });
}

export const resetAll = () => {
    removeMakeup();
    deselectCategorySwatches('lipcolor');
    deselectCategorySwatches('eyeshadow');
    document.getElementById('rendered-canvas-container').style.display = 'block';
    document.getElementById('ui-interface').style.display = 'block';
}


export const triggerImageUpload = () => {
    document.getElementById('image-upload').click();
}


export const touchSlide = (event) => {
    if (event.currentTarget === event.target) return;
    const target = event.currentTarget;

    // prevent default scroll behaviour
    event.preventDefault();

    const max = document.getElementById('app-container').clientWidth;
    const touchX = event.touches[0].clientX;
    let startX = window.getComputedStyle(target).left;
    startX = parseFloat(startX); // already in px

    const onTouchMove = (e) => {
        // prevent default scroll behaviour
        e.preventDefault();
        e.stopPropagation();

        const leftDistance = `${clamp(startX + (e.touches[0].clientX - touchX), 0, max) / max * 100}%`;
        document.getElementById('rendered-canvas-container').style.width = leftDistance;
        document.getElementById('compare-slider').style.left = leftDistance;
    };

    document.addEventListener('touchmove', onTouchMove, { passive: false });

    target.ontouchend = () => {
        document.removeEventListener('touchmove', onTouchMove);
        document.removeEventListener('touchend', target.ontouchend);
        target.ontouchend = null;
    };
    document.addEventListener('touchend', target.ontouchend);
}

export const mouseSlide = (event) => {

    if (event.currentTarget === event.target) return;

    const target = event.currentTarget;

    const max = document.getElementById('app-container').clientWidth;
    const mouseX = event.clientX;
    let startX = window.getComputedStyle(target).left;
    startX = parseFloat(startX); // already in px
    
    const onMouseMove = (e) => {
        const leftDistance = `${clamp(startX + (e.clientX - mouseX), 0, max) / max * 100}%`;
        document.getElementById('rendered-canvas-container').style.width = leftDistance;
        document.getElementById('compare-slider').style.left = leftDistance;
    };

    document.addEventListener('mousemove', onMouseMove, false);

    target.onmouseup = () => {
        document.removeEventListener('mousemove', onMouseMove, false);
        document.removeEventListener('mouseup', target.onmouseup, false);
        target.onmouseup = null;
    };
    document.addEventListener('mouseup', target.onmouseup, false);
}

export const clamp = (n, min, max) => {
    return Math.max(Math.min(n, max), min);
}

export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
  
export const getIndexedOptions = (products, color, category) =>
    Object.keys(products).reduce((index, i) => {
      const { 
        modifaceData, 
        id, 
        attributes: { 
            beauty_color: { 
                attribute_value 
            } = {} } = {},
            stock_item: {
                in_stock
            },
            stock_status
        } = products[i];

        var object = {
            ...index,
        };
        const inStock = stock_status == 'IN_STOCK' && in_stock;
        
      if(modifaceData && color[attribute_value]) {
        
        const { label, value } = color[attribute_value];

        const modiface = JSON.parse(modifaceData);
        object[`swatch-${value}`] = { 
            ...modiface['shadeArray'][0],
            category: category,
            value,
            label,
            configurableVariantIndex: i,
            inStock
            }
        }


        return object;
 
}, {});

export const isModifaceType = (varients) => Object.values(varients).filter(({ modifaceData }) => modifaceData !== null);

export const DEFAULT_LIPCOLOR = {
    category: 'lipcolor', colorA: 0, colorR: 255, colorG: 255, colorB: 255, intensity: 0, placement: 'default',
};
export const DEFAULT_EYESHADOW = {
    category: 'eyeshadow', colorA: 0, colorR: 255, colorG: 255, colorB: 255, intensity: 0, placement: 'default',
};

