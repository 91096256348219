import { PureComponent, lazy, Suspense } from 'react';
// import ModiFacePopup from '../ModiFacePopup';
import { MODIFACE } from '../ModiFacePopup/ModifacePopup.config';
import Popup from 'SourceComponent/Popup';
import Loader from 'SourceComponent/Loader';

export const ModiFacePopup = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "widget-modiface" */
    '../ModiFacePopup'
));

class ModiFace extends PureComponent {

    renderModiFacePopup() {
        const { 
            color: { attribute_options = {} } = {}, 
            category,
            product = {}
        } = this.props;
        
        return (
            <Popup
                id={MODIFACE}
                clickOutside={false}
                mix={{ block: 'ModiFacePopup' }}
            >
                <Suspense fallback={<Loader />}>
                    <ModiFacePopup 
                        color= { attribute_options }
                        category= { category }
                        product= { product }
                    />
                </Suspense>
            </Popup>
        )
    }

    render() {
        const { onInitModiface } = this.props;

        return (
            <>
                <div block="Modiface" elem="Action">
                    <button
                        block="Button Button"
                        elem="Action"
                        onClick={() => onInitModiface()}
                    >
                        {__("Try It")}
                    </button>
                </div>
                {this.renderModiFacePopup()}
            </>
        )
    }
}

export default ModiFace;