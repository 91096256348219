import { beautifyLabel, filterLabel } from '@mageguide/enhacned-registration/src/utils/config/index';

const getFieldsData = (custom_attributes) => {
    var customData = {}
    if (custom_attributes && Object.values(custom_attributes).length > 0) {
        custom_attributes.map(({ attribute_code, value }) => {
            if (attribute_code === 'skin_concerns'
                || attribute_code === 'favorite_stores'
                || attribute_code === 'favorite_brands') {
                const isArray = value.indexOf(',');
                if (isArray !== -1) {
                    const toArray = value.split(',').map((val) => val);
                    customData[attribute_code] = {
                        value: toArray
                    };
                } else {
                    customData[attribute_code] = {
                        value: [value]
                    };
                }
            } else {
                customData[attribute_code] = {
                    value
                };
            }
        });
    }
    
    return customData;
}

const renderAuthor = (args, callback, instance) => {
    const { custom_attributes } = args[0];
    const { customAttributeMetadata } = instance.props;

    const fields = getFieldsData(custom_attributes)
    return (
        <>
        { callback.apply(instance, args) }
        {(customAttributeMetadata && customAttributeMetadata.items) ?
                                customAttributeMetadata.items.map(({ attribute_code, attribute_options }, _) => {
                                    const attri = fields[attribute_code];
                                    if (attri) {
                                        const { value } = attri;
                                        return (
                                            <li block="MyAccount-EnhanchedRegistration_dashboard" elem="List">
                                                <label> {beautifyLabel(attribute_code)} </label>
                                                <span> {filterLabel(value, attribute_options)} </span>
                                            </li>
                                        )
                                    }
                                })
            : null}
        </>
    );
}

export const config = {
    'Component/ProductReviewItem/Component': {
        'member-function': { 
            renderAuthor 
        }
    }
};

export default config;