const mapStateToProps =  (args, callback, instance) => {
    const [state] = args;
    return {
        ...callback.apply(instance, args),
        pageSize: state.ConfigReducer.products_per_page
    };
};


const config = {
    'Component/ProductList/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    }
}

export default config;