import Link from 'SourceComponent/Link';
import ProductAttributeValue from 'SourceComponent/ProductAttributeValue';
import { renderIsGift } from "../route/FreeGiftCategoryPage/FreeGiftCategoryPage.config";

const containerProps = (_args, _callback, _instance) =>  {
    const { isFreeGifts } = _instance.props;

    return {
        ..._callback.apply(_instance, _args),
        isFreeGifts
    };
};

const renderAddToCart = (_args, _callback, _instance) =>  { 
    const { isFreeGifts, linkTo, product, product: { url } } = _instance.props;
    
    if(isFreeGifts || renderIsGift(product)) {
        if (!url) {
            return (
                <div>
                    { _instance.renderShortDescription() }
                    <div
                        block="FreeGiftCategoryPage"
                        elem="Link"
                    >
                        { __('See more') }
                    </div>
                </div>
            );
        }

        return (
            <div>
                { _instance.renderShortDescription() }
                <Link
                    block="FreeGiftCategoryPage"
                    elem="Link"
                    to={ linkTo }
                    onClick={ _instance.registerSharedElement }
                >
                    { __('See more') }
                </Link>
            </div>
        );
    }

    return _callback.apply(_instance, _args);
}

const renderConfigurableOptions = (_args, _callback, _instance) =>  {
    const { isFreeGifts, linkTo, product, product: { url } } = _instance.props;

    if(isFreeGifts || renderIsGift(product)) {
        return null;
    }

    return _callback.apply(_instance, _args);
}

const renderIsFreeGiftCondition = (_args, _callback, _instance) =>  { 
    const { isFreeGifts, product } = _instance.props;

    if(isFreeGifts || renderIsGift(product)) {
        return;
    }

    return _callback.apply(_instance, _args);

}

const renderShortDescriptionValue = (_args, _callback, _instance) =>  {
    const {
        getAttribute
    } = _instance.props;

    const shortDescription = getAttribute('short_description');

    if (!shortDescription) {
        return (
            <div
                block="FreeGiftCategoryPage"
                elem="ShortDescriptionContainer"
            />
        );
    }

    return (
        <div
            block="FreeGiftCategoryPage"
            elem="ShortDescriptionContainer"
        >
            <ProductAttributeValue
                attribute={ shortDescription }
                isFormattedAsText
                mix={ {
                    block: 'FreeGiftCategoryPage',
                    elem: 'ShortDescription'
                } }
            />
        </div>
    );

}

export default {
    'Component/ProductCard/Component': {
        'member-function': {
            renderProductActions: renderIsFreeGiftCondition,
            renderAddToCart,
            renderConfigurableOptions,
            renderProductPrice: renderIsFreeGiftCondition,
            renderReviews: renderIsFreeGiftCondition,
            renderShortDescription: renderShortDescriptionValue
        }
    },
    'Component/ProductCard/Container': {
        'member-function': {
            containerProps
        }
    }
}