import CartDispatcher from "Store/Cart/Cart.dispatcher";
import history from 'Util/History';
export class CheckoutPlugin {
    componentDidMount(args, callback, instance){
        const { getFreshCart } = instance.props;
        callback.apply(instance, args);
        getFreshCart();
    }
    _updateCartData(args, callback, instance){
        callback.apply(instance, args);
        const cartTotals = args[0];
        if(!cartTotals?.items?.length && window.location.pathname.includes("checkout") && !window.location.pathname.includes("success"))
            history.push({ pathname: '/cart' });
    }

    mapDispatchToProps(args, callback){
        const [dispatch] = args;
        return {
            ...callback(...args),
            getFreshCart: () => CartDispatcher.updateInitialCartData(dispatch)
        };
    };
}

const { componentDidMount, mapDispatchToProps, _updateCartData } = new CheckoutPlugin();
export const config = {
    'Route/Checkout/Container': {
        'member-function': {
            componentDidMount
        }
    },
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData: [
                {
                    position: 100,
                    implementation: _updateCartData
                }
            ]
        }
    }
};

export default config;
