import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ProductListContainer as SourceProductListContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/ProductList/ProductList.container';
import { setQueryParams } from 'Util/Url';
export { mapStateToProps };

export { mapDispatchToProps };

/** @namespace Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {


    /**
     * Disable infinite loading in mobile
     * Add load more option in mobile (only mobile)
     */
    static defaultProps = {
        mix: {},
        pageSize: 24,
        filter: {},
        search: '',
        selectedFilters: {},
        sort: undefined,
        isPreventRequest: false,
        isPaginationEnabled: true,
        isInfiniteLoaderEnabled: false,
        isMobileLoadMore: true,
        isPageLoading: false,
        noAttributes: false,
        noVariants: false,
        isWidget: false,
        title: '',
        totalPages: 1
    };

    containerFunctions = {
        loadPrevPage: this.loadPage.bind(this, false, true),
        loadPage: this.loadPage.bind(this),
        handleLoadMore: this.handleLoadMore.bind(this),
        updatePage: this.updatePage.bind(this)
    };

    containerProps() {
        const {
            device,
            isLoading,
            isPaginationEnabled,
            isWidget,
            mix,
            pages,
            selectedFilters,
            title,
            totalPages,
            sliderOptions,
            categoryPageCmsBlockListing,
            isSampleProducts,
            isFreeGifts,
            additionalClass,
            isSearchPage,
            isBrandPage,
            isBrandSteps,
            stepsSkus,
            widgetId,
            isMobileLoadMore,
            isPageLoading
        } = this.props;

        return {
            isBrandPage,
            device,
            // Disable loading on mobile to have smooth effect
            isLoading: device.isMobile ? false : isLoading,
            // isLoadingMobile shows loader on load more and previous
            isLoadingMobile: isPageLoading,
            isPaginationEnabled,
            isWidget,
            mix,
            pages,
            selectedFilters,
            title,
            totalPages,
            sliderOptions,
            categoryPageCmsBlockListing,
            isSampleProducts,
            isFreeGifts,
            additionalClass,
            isSearchPage,
            isBrandSteps,
            stepsSkus,
            widgetId,
            currentPage: this._getPageFromUrl(),
            isShowLoading: this._isShowLoading(),
            isVisible: this._isVisible(),
            requestPage: this.requestPage,
            isMobileLoadMore,
            // disable this property to enable infinite scroll on desktop
            isInfiniteLoaderEnabled: this._getIsInfiniteLoaderEnabled()
        };
    }

    handleLoadMore() {
        this.loadPage(true, true);
    }

    updatePage(pageNumber) {
        const { location, history } = this.props;

        setQueryParams({
            page: pageNumber === 1 ? '' : pageNumber
        }, location, history);
    }

    loadPage(next = true, isLoadMore = false) {
        const { pagesCount } = this.state;
        const { isPageLoading } = this.props;

        const {
            minPage,
            maxPage,
            totalPages,
            loadedPagesCount,
            isWidget
        } = this._getPagesBounds();
        const isUpdatable = totalPages > 0 && pagesCount === loadedPagesCount;
        const shouldUpdateList = next ? maxPage < totalPages : minPage > 1;

        if ((isUpdatable && shouldUpdateList && !isPageLoading) || isLoadMore === true) {
            this.setState({ pagesCount: pagesCount + 1 });
            this.requestPage(next ? maxPage + 1 : minPage - 1, true);
            if (isLoadMore === true && !isWidget) {
                this.updatePage(next ? maxPage + 1 : minPage - 1, isLoadMore);
            }
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductListContainer));
