import { PureComponent } from 'react';
import SlickSlider from './SlickSlider.component';

/** @namespace Component/SlickSlider/Container */
export class SlickSliderContainer extends PureComponent {

    render() {
        return <SlickSlider {...this.props} />

    }
}

export default SlickSliderContainer;