import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { IN_STOCK, OUT_OF_STOCK } from 'SourceComponent/ProductCard/ProductCard.config';
import { LOADING_TIME } from 'SourceRoute/CategoryPage/CategoryPage.config';
import { changeNavigationState, goToPreviousNavigationState } from 'SourceStore/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'SourceStore/Navigation/Navigation.reducer';
import { setBigOfflineNotice } from 'SourceStore/Offline/Offline.action';
import ProductReducer from 'SourceStore/Product/Product.reducer';
import { HistoryType, LocationType, MatchType } from 'SourceType/Common';
import { ProductType } from 'SourceType/ProductList';
import { withReducers } from 'SourceUtil/DynamicReducer';
import {  getNewParameters, getVariantIndex } from 'SourceUtil/Product';
import { debounce } from 'SourceUtil/Request';
import { hideActivePopup } from 'SourceStore/Overlay/Overlay.action';
import { hideActiveOverlay } from 'SourceStore/Overlay/Overlay.action';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import {
    convertQueryStringToKeyValuePairs,
    objectToUri
} from 'SourceUtil/Url';
import { MULTITAB_POPUP, PRODUCTLIST_POPUP } from '../../utils/wishlist.config';

import ProductPopup from './ProductPopup.component';

import { isSignedIn } from 'SourceUtil/Auth';
import {
    BUNDLE,
    CONFIGURABLE,
    DOWNLOADABLE,
    getExtensionAttributes,
    GROUPED
} from 'SourceUtil/Product';
import { ERROR_CONFIGURABLE_NOT_PROVIDED } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.config';

export const BreadcrumbsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Breadcrumbs/Breadcrumbs.dispatcher'
);

export const MetaDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Meta/Meta.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Cart/Cart.dispatcher'
);

export const ProductDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Product/Product.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'SourceStore/Wishlist/Wishlist.dispatcher'
);

/** @namespace Route/ProductPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOffline: state.OfflineReducer.isOffline,
    product: state.ProductReducer.product,
    navigation: state.NavigationReducer[TOP_NAVIGATION_TYPE],
    metaTitle: state.MetaReducer.title,
    isMobile: state.ConfigReducer.device.isMobile,
    store: state.ConfigReducer.code,
    payload: state.PopupReducer.popupPayload,
    productsInWishlist: state.WishlistReducer.productsInWishlist,
    isAddingWishlistItem: state.WishlistReducer.isLoading,
    brand: state.ProductReducer.product?.brandData ? state.ProductReducer.product.brandData : {},
    
});

/** @namespace Route/ProductPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    changeHeaderState: (state) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, state)),
    changeNavigationState: (state) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, state)),
    requestProduct: (options) => {
        // TODO: check linked products, there might be issues :'(
        ProductDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
        );
    },
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    updateBreadcrumbs: (breadcrumbs, prevCategoryId) => BreadcrumbsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithProduct(breadcrumbs, prevCategoryId, dispatch)
    ),
    updateMetaFromProduct: (product) => MetaDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateWithProduct(product, dispatch)
    ),
    goToPreviousNavigationState: (state) => dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE, state)),
    updateProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateProduct(dispatch, options)
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    resetHideActivePopup: () => dispatch(hideActivePopup(false)),
    changeItemQty: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.changeItemQty(dispatch, options)
    ),
    addProductToWishlist: (wishlistItem) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addItemToWishlist(dispatch, wishlistItem)
    ),
    removeProductFromWishlist: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeItemFromWishlist(dispatch, options),
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    addItemToCategory: (options) => WishlistDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addItemToCategory(dispatch, options),
    ),
});

/** @namespace Route/ProductPopup/Container */
export class ProductPopupContainer extends PureComponent {
    __construct(props) {
        super.__construct(props);
        this.requestProduct();
        this.state = {
            parameters: this.getParameters(),
            quantity: this.getQuantity(),
            isLoading: false,
            isAddtoCart: this.isAddtoCart()
        };
    }

    isAddtoCart() {
        const { payload } = this.props;
        const [identifier] = Object.keys(payload);
        const isAddtoCart = payload[identifier]?.isAddtoCart || false;

        return (identifier.indexOf(PRODUCTLIST_POPUP) !== -1 || identifier.indexOf(MULTITAB_POPUP) !== -1) && isAddtoCart;

    }

    getQuantity(quantity = 1){
        const { qty } = this.props;

        if(quantity > 1) {
            this.setState({ quantity })
        }

        return qty || quantity
    }

    _getParametersFromSavePDP() {
        const {
                product,
                product: {
                    type_id,
                    configurable_options,
                    url,
                    variants
                } = {},
                configurableVariantIndex
        } = this.props;
        
        if (type_id !== CONFIGURABLE) {
            return {
                pathname: url,
                state: { product }
            };
        }
        const param = url?.split('?');
        
        const variant = variants[configurableVariantIndex];

        if(param[1]) {
          const parameters = convertQueryStringToKeyValuePairs(`?${param[1]}`)
            return !parameters ? {} : parameters;
        }

        if (!variant) {
            return {};
        }

        const { attributes = {} } = variant;

        const parameters = Object.entries(attributes).reduce(
            (parameters, [code, { attribute_value }]) => {
                if (Object.keys(configurable_options).includes(code)) {
                    return { ...parameters, [code]: attribute_value };
                }

                return parameters;
            }, {}
        );


        return parameters
    }

    getParameters(){
        const { parameters = {}, configurableVariantIndex } = this.props;

        return parameters || {};
    }

    state = {
        productOptionsData: {},
        selectedInitialBundlePrice: 0,
        selectedBundlePrice: 0,
        selectedBundlePriceExclTax: 0,
        selectedLinkPrice: 0,
        currentProductSKU: '',
        configurableVariantIndex: -1
    };

    containerFunctions = {
        getQuantity: this.getQuantity.bind(this),
        updateConfigurableVariant: this.updateConfigurableVariant.bind(this),
        handleChangeProduct: this.handleChangeProduct.bind(this),
        getLink: this.getLink.bind(this),
        getSelectedCustomizableOptions: this.getSelectedCustomizableOptions.bind(this),
        setBundlePrice: this.setBundlePrice.bind(this),
        setLinkedDownloadables: this.setLinkedDownloadables.bind(this),
        setLinkedDownloadablesPrice: this.setLinkedDownloadablesPrice.bind(this),
        isProductInformationTabEmpty: this.isProductInformationTabEmpty.bind(this),
        isProductAttributesTabEmpty: this.isProductAttributesTabEmpty.bind(this),
        toggleProductInWishlist: this.toggleProductInWishlist.bind(this)
    };

    static propTypes = {
        location: LocationType,
        changeHeaderState: PropTypes.func.isRequired,
        setBigOfflineNotice: PropTypes.func.isRequired,
        changeNavigationState: PropTypes.func.isRequired,
        updateMetaFromProduct: PropTypes.func.isRequired,
        updateBreadcrumbs: PropTypes.func.isRequired,
        requestProduct: PropTypes.func.isRequired,
        isOffline: PropTypes.bool.isRequired,
        productSKU: PropTypes.string,
        productID: PropTypes.number,
        product: ProductType.isRequired,
        history: HistoryType.isRequired,
        match: MatchType.isRequired,
        goToPreviousNavigationState: PropTypes.func.isRequired,
        navigation: PropTypes.shape(PropTypes.shape).isRequired,
        metaTitle: PropTypes.string,
        addRecentlyViewedProduct: PropTypes.func.isRequired,
        store: PropTypes.string.isRequired,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        location: { state: {} },
        productSKU: '',
        productID: 0,
        metaTitle: undefined,
        isCart: false,
        isEditWishlistItem: false,
        prevSku: '',
        categoryId: 0,
        isProductList: false
    };

    static getDerivedStateFromProps(props, state) {
        const {
            product: {
                sku,
                variants,
                configurable_options,
                options,
                productOptionsData
            }
            // parameters = {}
        } = props;

        const { parameters = {} } = state;

        const {
            currentProductSKU: prevSKU,
            productOptionsData: prevOptionData
        } = state;
        
        const currentProductSKU = prevSKU === sku ? '' : prevSKU;

        /**
         * If the product we expect to load is loaded -
         * reset expected SKU
         */
        if (!configurable_options && !variants) {
            return {
                currentProductSKU
            };
        }

        if (Object.keys(parameters).length !== Object.keys(configurable_options).length) {
            return {
                parameters,
                currentProductSKU
            };
        }

        const configurableVariantIndex = getVariantIndex(variants, parameters, true);

        const newOptionsData = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        const prevRequiredOptions = productOptionsData?.requiredOptions || [];
        const requiredOptions = [...prevRequiredOptions, ...newOptionsData];

        return {
            parameters,
            currentProductSKU,
            configurableVariantIndex,
            productOptionsData: {
                ...prevOptionData, ...productOptionsData, requiredOptions
            }
        };
    }

    isDisable() {
        const { configurableVariantIndex: prevConfigurableVariantIndex, qty } = this.props;
        const { configurableVariantIndex, quantity } = this.state;
        
        if(configurableVariantIndex == -1) {
            return true;
        }

        if(
            prevConfigurableVariantIndex !== configurableVariantIndex
        || quantity !== qty
        ) {
            return false;
        }

        return true;
    }

    componentDidMount() {
        /**
         * Scroll page top in order to display it from the start
         */
        // this.scrollTop();
    }

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            productSKU: PDPSKU,
            product: {
                sku,
                options,
                items
            },
            payload,
            product
        } = this.props;

        const [identifier] = Object.keys(payload);

        const productSKU = PDPSKU ? PDPSKU : payload[identifier].sku;
            
        const {
            productSKU: prevProductSKU,
            product: {
                sku: prevSku,
                options: prevOptions,
                items: prevItems
            },
            product: prevProduct
        } = prevProps;

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }
        /**
         * If the currently loaded category ID does not match the ID of
         * category ID from URL rewrite, request category.
         */
        if (sku && productSKU !== sku) {
            this.requestProduct();
        }

        if(product !== prevProduct) {
            const parameters = this._getParametersFromSavePDP();
            this.setState({ parameters })
        }
        /**
         * LEGACY: needed to make sure required items are
         * selected in the bundle product.
         */
        if (JSON.stringify(options) !== JSON.stringify(prevOptions)) {
            this.getRequiredProductOptions(options);
        }

        /**
         * LEGACY needed to make sure required options are
         * selected in the customizable options product.
         */
        if (JSON.stringify(items) !== JSON.stringify(prevItems)) {
            this.getRequiredProductOptions(items);
        }
    }

    isProductInformationTabEmpty() {
        const dataSource = this.getDataSource();

        return dataSource?.description?.html?.length === 0;
    }

    isProductAttributesTabEmpty() {
        const dataSource = this.getDataSource();

        return Object.keys(dataSource?.attributes || {}).length === 0;
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    setOfflineNoticeSize = () => {
        const { setBigOfflineNotice, productSKU: PDPSKU, payload } = this.props;

        const [identifier] = Object.keys(payload);

        const productSKU = PDPSKU ? PDPSKU : payload[identifier].sku;

        const { sku } = this.getDataSource();

        /**
         * If there is any information about the product, in example,
         * we know it's URL-rewrite SKU is matching the product SKU -
         * show the small offline notice, else - show larger one.
         */
        if (sku !== productSKU) {
            setBigOfflineNotice(true);
        } else {
            setBigOfflineNotice(false);
        }
    };

    getLink(key, value) {
        const { location: { search, pathname } } = this.props;
        const obj = {
            ...convertQueryStringToKeyValuePairs(search)
        };

        if (key) {
            obj[key] = value;
        }

        const query = objectToUri(obj);

        return `${pathname}${query}`;
    }

    getRequiredProductOptions(options) {
        const { productOptionsData } = this.state;

        if (!options) {
            return [];
        }
        const requiredOptions = options.reduce((acc, { option_id, required }) => {
            if (required) {
                acc.push(option_id);
            }

            return acc;
        }, []);

        return this.setState({
            productOptionsData:
                { ...productOptionsData, requiredOptions }
        });
    }

    setLinkedDownloadablesPrice(price) {
        this.setState({
            selectedLinkPrice: price
        });
    }

    setBundlePrice(prices) {
        const { price = 0, priceExclTax = 0, finalPrice = 0 } = prices;
        this.setState({
            selectedInitialBundlePrice: price,
            selectedBundlePrice: finalPrice,
            selectedBundlePriceExclTax: priceExclTax
        });
    }

    setLinkedDownloadables(links) {
        const { productOptionsData } = this.state;
        this.setState({
            productOptionsData: {
                ...productOptionsData, downloadableLinks: links
            }
        });
    }

    getSelectedCustomizableOptions(values, updateArray = false) {
        const { productOptionsData } = this.state;

        if (updateArray) {
            this.setState({
                productOptionsData:
                    { ...productOptionsData, productOptionsMulti: values }
            });
        } else {
            this.setState({
                productOptionsData:
                    { ...productOptionsData, productOptions: values }
            });
        }
    }

    containerProps = () => {
        const { 
            isMobile, 
            isCart, 
            isEditWishlistItem, 
            isProductList,
            brand
        } = this.props;

        const {
            configurableVariantIndex,
            parameters,
            productOptionsData,
            selectedBundlePrice,
            selectedBundlePriceExclTax,
            selectedInitialBundlePrice,
            selectedLinkPrice,
            quantity,
            isLoading,
            isAddtoCart
        } = this.state;
        
        return {
            areDetailsLoaded: this.getAreDetailsLoaded(),
            configurableVariantIndex,
            dataSource: this.getDataSource(),
            isAttributesTabEmpty: this.isProductAttributesTabEmpty(),
            isInformationTabEmpty: this.isProductInformationTabEmpty(),
            isMobile,
            isDisable: this.isDisable(),
            parameters,
            productOptionsData,
            productOrVariant: this.getProductOrVariant(),
            selectedBundlePrice,
            selectedBundlePriceExclTax,
            selectedInitialBundlePrice,
            selectedLinkPrice,
            quantity,
            isLoading,
            isCart,
            isEditWishlistItem,
            isProductList,
            isAddtoCart,
            brand
        };
    };

    toggleProductInWishlist(add = true) {
        const {
            product: { sku, type_id },
            isAddingWishlistItem,
            showNotification,
            productsInWishlist,
            addProductToWishlist,
            prevSku,
            onProductValidationError,
            removeProductFromWishlist,
            hideActiveOverlay,
            goToPreviousNavigationState,
            resetHideActivePopup,
            categoryId
        } = this.props;

        const { quantity } = this.state;
        
        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        if (isAddingWishlistItem) {
            return null;
        }

        const product = this._getProductVariant();
        if (product === ERROR_CONFIGURABLE_NOT_PROVIDED) {
            return showNotification('info', __('Please, select desirable option first!'));
        }

        this.setState({ isLoading: true});
        const { sku: variantSku, product_option, productId } = product;
        const { wishlist: { id: item_id } } = Object.values(productsInWishlist).find(
            ({ wishlist: { sku } }) => sku === prevSku
        );
        
        return removeProductFromWishlist({ item_id, sku: variantSku }).then( 
            () => {
                addProductToWishlist({ sku, product_option, quantity });
                if(categoryId !== 0) {
                    addItemToCategory({ product_id: productId, category_id: categoryId })
                }
                hideActiveOverlay();
                goToPreviousNavigationState();
                resetHideActivePopup();
        });
        

    }

    handleChangeProduct() {
        const { productOptionsData, parameters, quantity, configurableVariantIndex } = this.state;

        const {
            product,
            product: { sku },
            item_id,
            updateProduct,
            hideActiveOverlay,
            goToPreviousNavigationState,
            resetHideActivePopup,
            changeItemQty,
            parameters: orgParameters,
            qty
        } = this.props

        this.setState({ isLoading: true })
        if (
            JSON.stringify(orgParameters) === JSON.stringify(parameters)
            && quantity !== qty
        ) {
           return changeItemQty({ item_id, quantity, sku }).then(
                () => {
                    this.setState({ isLoading: false })
                    hideActiveOverlay();
                    goToPreviousNavigationState();
                    resetHideActivePopup();
                }
            );

        }
        
        updateProduct({
            item_id, 
            quantity,
            sku,
            product: {
                ...product,
                configurableVariantIndex
            },
            productOptionsData
        }
        ).then(
            () => {
                this.setState({ isLoading: false })
                hideActiveOverlay();
                goToPreviousNavigationState();
                resetHideActivePopup();
            }
        );

    }

    updateConfigurableVariant(key, value) {
        const { parameters: prevParameters } = this.state;

        const parameters = getNewParameters(prevParameters, key, value);
        
        this.setState({ parameters });

        this.updateConfigurableVariantIndex(parameters);
    }

    updateConfigurableVariantIndex(parameters) {
        const { product: { variants, configurable_options } } = this.props;
        const { configurableVariantIndex } = this.state;

        const newIndex = Object.keys(parameters).length === Object.keys(configurable_options).length
            ? getVariantIndex(variants, parameters, true)
            // Not all parameters are selected yet, therefore variantIndex must be invalid
            : -1;

        if (configurableVariantIndex !== newIndex) {
            this.setState({ configurableVariantIndex: newIndex });
        }
    }

    getAreDetailsLoaded() {
        const { product } = this.props;
        const dataSource = this.getDataSource();

        return dataSource === product;
    }

    getProductOrVariant() {
        const dataSource = this.getDataSource();
        const { variants } = dataSource;
        const currentVariantIndex = this.getConfigurableVariantIndex(variants);
        const variant = variants && variants[currentVariantIndex];

        if (variants?.length > 0) {
            dataSource.stock_status = variants.some((v) => v.stock_status === IN_STOCK) ? IN_STOCK : OUT_OF_STOCK;
        }

        return variant || dataSource;
    }

    getConfigurableVariantIndex(variants) {
        const { configurableVariantIndex, parameters } = this.state;
        const hasParameters = !!Object.keys(parameters).length;

        if (configurableVariantIndex >= 0) {
            return configurableVariantIndex;
        }

        if (variants && hasParameters) {
            return getVariantIndex(variants, parameters, true);
        }

        return -1;
    }

    _getProductVariant() {
        const {
            product,
            product: { type_id }
        } = this.props;

        const { configurableVariantIndex } = this.state;

        if (type_id === CONFIGURABLE) {
            if (configurableVariantIndex < 0) {
                return ERROR_CONFIGURABLE_NOT_PROVIDED;
            }

            const extension_attributes = getExtensionAttributes({ ...product, configurableVariantIndex });
            const variant = product.variants[configurableVariantIndex];

            return { ...variant, product_option: { extension_attributes } };
        }

        if (type_id === GROUPED) {
            const {
                groupedProductQuantity = {}
            } = this.props;

            const grouped_product_options = Object.entries(groupedProductQuantity).map((option) => ({
                option_id: option[0],
                option_value: option[1]
            }));

            return { ...product, product_option: { extension_attributes: { grouped_product_options } } };
        }

        if (type_id === BUNDLE) {
            const {
                productOptionsData: {
                    productOptions
                }
            } = this.props;

            const extension_attributes = getExtensionAttributes({ ...product, productOptions });

            return { ...product, product_option: { extension_attributes } };
        }

        if (type_id === DOWNLOADABLE) {
            const {
                productOptionsData: {
                    downloadableLinks
                }
            } = this.props;

            const extension_attributes = getExtensionAttributes({ ...product, downloadableLinks });

            return { ...product, product_option: { extension_attributes } };
        }

        return product;
    }

    getDataSource() {
        const {
            productSKU: PDPSKU,
            product,
            payload
        } = this.props;

        const [identifier] = Object.keys(payload);

        const productSKU = PDPSKU ? PDPSKU : payload[identifier].sku;
        const { sku } = product;
        const { product: stateProduct } = history?.state?.state || {};
        const { sku: stateSKU } = stateProduct || {};

        /**
         * If URL rewrite requested matches loaded product SKU
         * assume it is a data-source.
         */
        if (productSKU === sku) {
            return product;
        }

        /**
         * If URL rewrite requested matches product SKU from
         * history state - it is a data-source.
         */
        if (productSKU === stateSKU) {
            return stateProduct;
        }

        /**
         * Else there is no place to get a up-to-date
         * information about the product from.
         */
        return {};
    }

    getProductRequestFilter() {
        const { productID: PDPID, payload  } = this.props;

        const [identifier] = Object.keys(payload);

        const productID = PDPID ? PDPID : payload[identifier].id;

        return { productID };
    }

    requestProduct() {
        const { requestProduct, productSKU: PDPSKU, payload } = this.props;
        const { currentProductSKU } = this.state;
        const [identifier] = Object.keys(payload);
        const productSKU = PDPSKU ? PDPSKU : payload[identifier]?.sku;

        /**
         * If URL rewrite was not passed - do not request the product.
         */
        if (!productSKU) {
            return;
        }

        /**
         * Skip loading the same product SKU the second time
         */
        if (currentProductSKU === productSKU) {
            return;
        }

        this.setState({ currentProductSKU: productSKU });

        const options = {
            isSingleProduct: true,
            args: { filter: this.getProductRequestFilter() }
        };

        requestProduct(options);
    }

    render() {
        return (
            <ProductPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({ProductReducer})(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductPopupContainer)));
