import getStore from 'Util/Store';
import { updateShippingFields } from 'Store/Checkout/Checkout.action';
const  onAddressSelect = (args, callback, instance) => {
    const [ id ] = args;
    const { customer : { addresses } } = instance.props;
    let selectedAddress = addresses.filter((address)=> address.id == id)?.[0];
    if(selectedAddress){
        const store = getStore();
        const { dispatch } = store;
        dispatch(updateShippingFields(selectedAddress))
    }
    return callback.apply(instance,args);
}
export const config = {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            onAddressSelect
        }
    }
};

export default config;
