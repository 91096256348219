import { Field, Fragment } from 'Util/Query';

export class ProductListQueryPlugin {
    getItemsField = (args, callback, instance) => {
        const { isSingleProduct } = instance.options;
        let items = callback.apply(instance, args);
        if (isSingleProduct) {
            items.addField(this._getGiftProductItems());
        }
        return items;
    }
    _getGiftProductItems(){
        return new Fragment('GiftProduct')
            .addFieldList(this._getGiftProductFragmentFields());
    }

    _getGiftProductFragmentFields(){
        return [
            'am_giftcard_type',
            'am_allow_open_amount',
            'am_open_amount_min',
            'am_open_amount_max',
            'am_giftcard_lifetime',
            'am_giftcard_code_image',
            'am_giftcard_code_set',
            'am_giftcard_fee_enable',
            'am_giftcard_fee_type',
            'am_giftcard_fee_value',
            this._getGiftCardPrices(),
            this._getGiftImages()
        ];
    }

    _getGiftCardPrices(){
        return new Field('am_giftcard_prices')
            .addFieldList([
                'price_id',
                'website_id',
                'attribute_id',
                'value'
            ]);
    }

    _getGiftImages(){
        return new Field('am_giftcard_images')
            .addFieldList([
                'image_id',
                'status',
                'code_pos_x',
                'code_pos_y',
                'attribute_id',
                'title',
                'code_text_color',
                'image_path',
                'user_upload'
            ]);
    }
}


const { getItemsField } = new ProductListQueryPlugin();

export default {
    'Query/ProductList': {
        'member-function': {
            '_getItemsField': getItemsField
        }
    }
};
