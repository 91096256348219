import BoxNowIframe from '../component/BoxNowIframe';

import Cookies from "universal-cookie";
import media, {WYSIWYG_MEDIA} from 'Util/Media/Media';
export const BOXNOW_COOKIE = 'boxnow_id';
export const BOX_NOW_CODE = 'boxnow'
const componentDidUpdate = (args, callback, instance) => {
    const {
        isSelected,
        option: {
            carrier_code
        }
    } = instance.props;

    const cookies = new Cookies();
    if(BOX_NOW_CODE === carrier_code && isSelected){
        window.addEventListener(
            "message",
            function (e) {
                const {
                    boxnowLockerId,
                    boxnowLockerName,
                    boxnowLockerPostalCode,
                    boxnowLockerAddressLine1,
                    boxnowLockerAddressLine2,
                } = e.data;
                if (
                    boxnowLockerId &&
                    boxnowLockerName &&
                    boxnowLockerAddressLine1 &&
                    boxnowLockerPostalCode
                ) {
                    cookies.set(BOXNOW_COOKIE, e.data, { path: '/', maxAge : 345600 });
                    window.boxnowLockerId = boxnowLockerId + Math. floor((Math. random()*10));
                    const event = new CustomEvent("paypalComponentSetShippingMethod");
                    document.dispatchEvent(event)
                }
            },
            false
        );
    }
    if(BOX_NOW_CODE !== carrier_code && isSelected && cookies.get(BOXNOW_COOKIE)) {
        window.removeEventListener("message", function (e) {
        }, false);
        cookies.set(BOXNOW_COOKIE, null, { path: '/', maxAge : 0 });
    }
    return callback.apply(instance,args);

}
const renderRow = (args, callback, instance) => {
    let {
        isSelected,
        option: {
            carrier_code,
            carrier_title,
            method_title
        }
    } = instance.props;

    if(BOX_NOW_CODE === carrier_code){
        return (
            <>
                <div block="CheckoutDeliveryOption" elem="Row" className="Boxnow">
                    <img src={media('boxnow_checkout.png',WYSIWYG_MEDIA)} alt="box icon"/>
                    <span className="CheckoutDeliveryOption-title">
                        { carrier_title }
                    </span>
                    <span className="CheckoutDeliveryOption-method">
                        { method_title }
                    </span>
                    { instance.renderPrice() }
                </div>
                <BoxNowIframe isSelected={isSelected} />
            </>
        )
    }
    return callback.apply(instance,args);

}
export const config = {
    'Component/CheckoutDeliveryOption/Component': {
        'member-function': {
            renderRow: renderRow,
            componentDidUpdate : componentDidUpdate,
        }
    }
};

export default config;
