import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { RECAPTCHA_TYPE_RECAPTCHA } from './Recaptcha.config';

import './Recaptcha.style';

/** @namespace Recaptcha/Component/Recaptcha/Component/RecaptchaComponent */
export class RecaptchaComponent extends PureComponent {
    static propTypes = {
        recaptcha_public_key: PropTypes.string.isRequired,
        recaptchaType: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired
    };

    render() {
        const {
            recaptcha_public_key, onChange, recaptchaRef, recaptchaType
        } = this.props;

        if(!recaptcha_public_key)
            return <p>Please add recaptcha keys!</p>

        return (
            <div block="Recaptcha">
                <div className="inputbox-row-right">
                    <ReCAPTCHA
                      ref={ recaptchaRef }
                      size={ recaptchaType === RECAPTCHA_TYPE_RECAPTCHA ? 'normal' : 'invisible' }
                      className="g-recaptcha"
                      sitekey={ recaptcha_public_key }
                      onChange={ onChange }
                    />
                </div>
            </div>
        );
    }
}

export default RecaptchaComponent;
