/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isSignedIn } from 'Util/Auth';

import { showNotification } from 'Store/Notification/Notification.action';

import KlarnaComponent from './Klarna.component';
export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
    );

/** @namespace Component/Klarna/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showError: (message) => dispatch(showNotification('error', message)),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch)
    )
});

/** @namespace Component/Klarna/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({
    shippingAddress: state.CheckoutReducer.shippingFields,
    email: state.CheckoutReducer.email,
    customer: state.MyAccountReducer.customer,
    totals: state.CartReducer.cartTotals
});

/** @namespace Component/Klarna/Container */
export class KlarnaContainer extends PureComponent {
    static propTypes = {
        showError: PropTypes.func.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired
    };

    containerProps() {
        let { showError, setOrderButtonEnableStatus, email, customer, selectedPaymentCode, resetCart, totals } = this.props;
        if(isSignedIn())
            email = customer.email;
        localStorage.setItem('kl_pm', selectedPaymentCode.replace('klarna_',''));
        return { showError, setOrderButtonEnableStatus, data : this.getUpdateData(), email, selectedPaymentCode, resetCart, totals };
    }

    containerFunctions = {
        buildAddress: this.buildAddress.bind(this)
    };

    static authorize() {
        if(!window.Klarna.Payments)
            return { authorization_token : null };
        return new Promise((resolve, reject) => {
            window.Klarna.Payments.authorize(
                { payment_method_category: localStorage.getItem('kl_pm') },
                {},
                (res) => {
                    const { error, approved, authorization_token } = res;
                    if (!approved) {
                        reject(error);
                        const event = new CustomEvent("updatePlacingOrderStatus",{'detail': false});
                        document.dispatchEvent(event)
                    }
                    resolve({ authorization_token });
                }
            );
        });
    }

    buildAddress(address, email, isShipping = false) {
        let addr = {
            'organization_name': '',
            'given_name': '',
            'family_name': '',
            'street_address': '',
            'city': '',
            'postal_code': '',
            'country': '',
            'phone': '',
            'email': email
        };

        if (!address) { // Somehow we got a null passed in
            return addr;
        }
        if (address.prefix) {
            addr['title'] = address.prefix;
        }
        if (address.firstname) {
            addr['given_name'] = address.firstname;
        }
        if (address.lastname) {
            addr['family_name'] = address.lastname;
        }
        if (address.street) {
            if(typeof address.street !== 'string'){
                if (address.street.length > 0) {
                    addr['street_address'] = address.street[0];
                }
                if (address.street.length > 1) {
                    addr['street_address2'] = address.street[1];
                }
            }else {
                addr['street_address'] = address.street;
            }
        }
        if(address.street0){
            if (address.street0.length > 0) {
                addr['street_address'] = address.street0;
            }
            if (address?.street1?.length > 1) {
                addr['street_address2'] = address.street1;
            }
        }
        if (address.city) {
            addr['city'] = address.city;
        }
        if (address.region?.region_code) {
            addr['region'] = address.region.region_code;
        }
        if (address.postcode) {
            addr['postal_code'] = address.postcode;
        }
        if (address.country_id) {
            addr['country'] = address.country_id;
        }
        if (address.telephone) {
            addr['phone'] = address.telephone;
        }
        // Having organization_name in the billing address causes KP/PLSI to return B2B methods
        // no matter the customer type. So we only want to set this if the merchant has enabled B2B.
        if (address.company) {
            addr['organization_name'] = address.company;
        }
        return addr;
    }
    buildCustomer(billingAddress) {
        let type = 'person';

        if (billingAddress.company) {
            type = 'organization';
        }

        return {
            'type': type
        };
    }
    getUpdateData() {
        let { email , shippingAddress, billingAddress,  totals: { is_virtual }, customer } = this.props;
        let data = {
            'billing_address': {},
            'shipping_address': {},
            'customer': {}
        };
        if(isSignedIn())
            email = customer.email;

        if (is_virtual) {
            // shippingAddress is redux state of billing form here
            // Also both needs to be same
            billingAddress = shippingAddress;
        }
        data.billing_address = this.buildAddress(billingAddress, email);
        data.shipping_address = this.buildAddress(shippingAddress, email, true);
        data.customer = this.buildCustomer(billingAddress);
        console.log(data)
        return data;
    }

    render() {
        return <KlarnaComponent { ...this.containerProps() }  { ...this.containerFunctions } />;
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(KlarnaContainer);
