import { Field, Fragment } from 'Util/Query';
import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
// import CustomAttributeQuery from '@mageguide/enhacned-registration/src/query/CustomAttribute.query';
/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList
 */
export class ProductListQuery extends SourceProductListQuery {

    attributeOptions = [
        {
            attribute_code: "beauty_color",
            entity_type: "1"
        },
        {
            attribute_code: "mg_brand",
            entity_type: "1"
        }
    ];

    _getItemsField() {
        const { isSingleProduct, isMediaOnly = false, optimized = false } = this.options;

        if (isMediaOnly) {
            return new Field('items')
                .addField(this._getMediaGalleryField());
        }

        const items = new Field('items')
            .addFieldList(
                optimized ? this._getProductInterfaceFieldsOptz() :
                    this._getProductInterfaceFields()
            );

        if (isSingleProduct) {
            items.addField(this._getGroupedProductItems());
            items.addField(this._getDownloadableProductFields());
        } else {
            items.addField(this._getDownloadableProductLinksRequired());
        }

        return items;
    }

    _getMgListProductInfoFields() {
        return new Field('product_info')
            .addFieldList([
                'sku',
                'name',
                'description',
                'thumbnail',
                'url'
            ]);
    }

    _getMgListAttribute() {
        return new Field('mg_custom_lists')
            .addFieldList([
                'name',
                'label',
                'color',
                'list_id',
                'url',
                'type_id',
                'type_name',
                'description',
                this._getMgListProductInfoFields()
            ]);
    }

    _getCTAActionFields() {
        return new Field('call_to_action')
            .addFieldList([
                'label',
                'url'
            ]);
    }



    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        // Basic fields returned always
        const fields = [
            'id',
            'sku',
            'name',
            'attribute_set_id',
            'type_id',
            'stock_status',
            'modifaceData',
            'brand',
            'is_reduced_price',
            'is_foucsia_price',
            'is_value_label',
            this._getMgListAttribute(),
            this._getCTAActionFields(),
            this._getStockItemField(),
            this._getProductBrandDataField()
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant)) {
            fields.push(
                this._getPriceRangeField(),
                this._getProductImageField(),
                // this._getProductExtraField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getMediaGalleryField(),
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getCategoriesField(),
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                'lowStock',
                'barcode',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getProductLinksField(),
                this._getCustomizableProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField(),
                );
            }
        }

        return fields;
    }

    _getProductInterfaceFieldsOptz(isVariant, isForLinkedProducts = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
            isCart = false,
            isSlider = false,
            isSample = false
        } = this.options;

        // Basic fields returned always
        const fields = [
            'id',
            'sku',
            'name',
            'attribute_set_id',
            'type_id',
            'stock_status',
            'brand',
            'is_reduced_price',
            'is_foucsia_price',
            'is_value_label',
            this._getStockItemField(),
            this._getProductBrandDataField()
        ];



        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant)) {
            fields.push(
                this._getProductThumbnailField(),
                this._getProductSmallField()
            );
            if (!isSample) {
                fields.push(
                    this._getPriceRangeField(),
                    'special_from_date',
                    'special_to_date'
                )
            }

            if (!isCart) {
                fields.push(
                    this._getShortDescriptionField(),
                    this._getProductImageField(),
                    this._getMediaGalleryField(),
                    // this._getProductExtraField(),
                    this._getTierPricesField()
                );
            }
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            if (!isSample) {
                fields.push(
                    'url'
                );
            }

            // if variants are not needed
            if (!noVariants) {
                // The next part contains an extra line as mention in the comment below.
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGiftProductImagesCart()
                );
            }
            if (!isSlider || !isSample) {
                fields.push(
                    this._getCategoriesField()
                )
            }
        }

        if (isSlider && !isSample) {
            fields.push(
                'modifaceData'
            );
        }

        if (isCart) {
            fields.push(
                this._getMgListAttribute()
            );
        }

        if (!isCart) {
            fields.push(
                'lowStock',
                'modifaceData',
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment(),
                this._getCTAActionFields(),
                this._getCustomizableProductFragment(),
                this._getMgListAttribute()
            )
        }

        // prevent linked products from looping
        if (isForLinkedProducts && !isCart) {
            fields.push(this._getProductLinksField());
        }

        // if(isCart && !isSlider) {
        //     fields.push(
        //         CustomAttributeQuery.getCustomerAttribute(this.attributeOptions, true)
        //     )
        // }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                'meta_title',
                'meta_keyword',
                'canonical_url',
                'meta_description',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getProductLinksField(),
                this._getCustomizableProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );

                if (!isSlider) {
                    fields.push(
                        this._getCategoriesField()
                    );
                }

                if (!isCart) {
                    fields.push(
                        this._getReviewsField(),
                        this._getVirtualProductFragment(),
                        this._getCustomizableProductFragment()
                    );
                }
            }
        }

        return fields;
    }

    // Next part of code should be part of giftcard plugin but is unable to run due to other plugins overriding. 
    // Thus not allowing the proper functions to be updated. The CartQueryPlugin of sample-products overides the 
    // initial function without the proper callback which in result causes that function to be unable to get extended.
    // If the mentioned plugin gets updated this next part of code and the call to this code which has a comment above
    // should be rellocated to the proper file and get tested again.
    // <----From here---> //
    _getGiftProductImagesCart() {
        return new Fragment('GiftProduct')
            .addFieldList(this._getGiftProductFragmentFieldsCart());
    }
    _getGiftProductFragmentFieldsCart() {
        return [this._getGiftImages()];
    }

    _getGiftImages() {
        return new Field('am_giftcard_images')
            .addFieldList([
                'image_id',
                'status',
                'code_pos_x',
                'code_pos_y',
                'attribute_id',
                'title',
                'code_text_color',
                'image_path',
                'user_upload'
            ]);
    }
    // <----To Here----> //

    _getProductField() {
        const { isForLinkedProducts, isCart } = this.options;

        return new Field('product')
            .addFieldList(
                !isCart ?
                    this._getProductInterfaceFields(true, isForLinkedProducts) :
                    this._getProductInterfaceFieldsOptz(true, isForLinkedProducts
                    ));
    }

    _getAttributeFields(isVariant) {
        const { isCart = false } = this.options;

        const fields = [
            'attribute_type',
            'attribute_value',
            'attribute_label',
            'attribute_code',
            'attribute_id',
            this._getAttributeOptionsField()
        ];

        if (isCart) {
            return fields;
        }
        // if(isCart && isVariant) {
        //     fields.push(
        //         ...(!isVariant
        //             ? [
        //         this._getAttributeOptionsField()
        //         ]
        //             : []
        //         )
        //     );
        //     return fields
        // }

        fields.push(
            'attribute_id',
            'attribute_type',
            'attribute_group_id',
            'attribute_group_code',
            'attribute_group_name',
            ...(!isCart && !isVariant
                ? [
                    this._getAttributeOptionsField()
                ]
                : []
            )
        )

        return fields;
    }


    _getProductExtraField() {
        return new Field('extra_image')
            .addFieldList(this._getProductSmallFields());
    }

    _getConfigurableProductFragmentFields() {
        const { isPlp = false } = this.options;

        if (isPlp) {
            return [
                this._getConfigurableOptionsField()
            ];
        } else {
            return [
                this._getConfigurableOptionsField(),
                this._getVariantsField()
            ];
        }
    }

    _getProductBrandDataField() {
        const { isSingleProduct } = this.options;

        const field = new Field('brandData');

        if (isSingleProduct) {
            field.addField('value').addField('slider_image').addField('url_alias');
        } else {
            field.addField('url_alias');
        }

        return field;
    }

    _getCustomizableProductFragment() {
        // HONDOSC-1117 Removed to reduce query complexity issue (until we are having customizable options)
        return null;
    }
}

export default new ProductListQuery();
