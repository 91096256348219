import { IN_STOCK } from 'SourceComponent/ProductCard/ProductCard.config';
import { ProductAlerts as SourceProductAlertsComponent } from 'SourceComponent/ProductAlerts/ProductAlerts.component';

import './ProductAlerts.style';

/** @namespace Component/ProductAlerts/Component */
export class ProductAlerts extends SourceProductAlertsComponent {
    renderInStockSubscribeButton() {
        const {
            handlePriceDropSubscribeAlertInStock,
            isInStockAlertEnabled,
            stockStatus
        } = this.props;

        if (!isInStockAlertEnabled) {
            return null;
        }

        if (stockStatus === IN_STOCK || !stockStatus) {
            return null;
        }

        return (
            <button
              block="ProductAlerts"
              elem="InStock Button"
              onClick={ handlePriceDropSubscribeAlertInStock }
            >
                { __('NOTIFY ME WHEN IN STOCK') }
            </button>
        );
    }
}

export default ProductAlerts;
