import { lazy } from 'react';
import { isSignedIn } from 'SourceUtil/Auth';
import getStore from 'SourceUtil/Store';

export const RewardPointsHeader = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "header-reward" */
    '../component/RewardPointsHeader'
));


const renderTopMenu = (args, callback, instance) => {
    const { 
        getRewardConfig: {
            is_enable = false
        } = {}
    } = getStore().getState() || {};

    if(!is_enable) {
        return callback.apply(instance, args)
    }

    return (
        <>
            { callback.apply(instance, args) }
            { isSignedIn() && <RewardPointsHeader/>}
        </>
    );
}
export default {
    'Component/Header/Component': {
        'member-function': {
            renderTopMenu : renderTopMenu
        }
    }
};
