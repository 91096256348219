import { extendCartDataProducts } from 'Util/Product/PerformaceProduct.js'

const _processResponse = (args, callback, instance) => {
    let [ data, product_links ] = args

    if(data?.products?.items?.length > 0) {
        data.products.items = extendCartDataProducts(data.products.items, true) || {};
    }
    return callback(...[data, product_links])
}
export default {
    'Store/LinkedProducts/Dispatcher': {
        'member-function': {
            '_processResponse': _processResponse
        }
    }
};
