import PayPal from '../component/PayPal';
import { PAYPAL_EXPRESS, PAYPAL_EXPRESS_CREDIT } from '../component/PayPal/PayPal.config';
import paypalcardsimage from '../public/assets/images/paypal_payment.png'

export class CheckoutPaymentsPlugin {
    renderPayPal() {
        return (
            <>
                <div className="BankRedirect">
                    <p>{ __('After you place your order, you will be redirected to the PayPal website to complete the transaction with safety.') }</p>
                </div>
            </>
        );
    }

    aroundPaymentRenderMap = (originalMember, instance) => ({
        ...originalMember,
        [PAYPAL_EXPRESS]: instance.renderPayPal.bind(instance)
    });

    aroundRenderContent = (args, callback, instance) => {
        const { selectedPaymentCode } = instance.props;
        return (
            <div>
                { callback.apply(instance, args) }
                {/*{ this.renderPayPal.apply(instance) }*/}
            </div>
        );
    };

    aroundComponentDidUpdate = (args, callback = () => {}, instance) => {
        const [prevProps] = args;
        const { selectedPaymentCode, setOrderButtonVisibility } = instance.props;
        const { selectedPaymentCode: prevSelectedPaymentCode } = prevProps;

        if (selectedPaymentCode !== prevSelectedPaymentCode) {
            if (selectedPaymentCode === PAYPAL_EXPRESS) {
                setOrderButtonVisibility(false);
            }

            if (prevSelectedPaymentCode === PAYPAL_EXPRESS) {
                setOrderButtonVisibility(true);
            }
        }

        callback.apply(instance, args);
    };
    dataMap = (originalMember, instance) => ({
        ...originalMember,
        ['paypal_express']: this.getPayPalData.bind(this)
    });

    getPayPalData() {
        return { asyncData: window.klarnaAdditionalData };
    }
}

const {
    aroundPaymentRenderMap,
    // aroundRenderContent,
    renderPayPal,
    dataMap,
    aroundComponentDidUpdate
} = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            // renderContent: aroundRenderContent,
            renderPayPal: renderPayPal,
            // componentDidUpdate: aroundComponentDidUpdate
        },
        'member-property': {
            paymentRenderMap: aroundPaymentRenderMap
        }
    },
    'Component/CheckoutPayments/Container': {
        'member-property': {
            dataMap
        }
    }
};

export default config;
