const mapStateToProps =  (args, callback, instance) => {
    const [state] = args;
    return {
        ...callback.apply(instance, args),
        seo_separator: state.ConfigReducer.seo_separator
    };
};

export const config = {
    'Component/SearchField/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
