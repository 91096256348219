import { PureComponent } from 'react';

import './SubmitIcon.style';

/** @namespace Component/SubmitIcon/Component */
export class SubmitIcon extends PureComponent {
    render() {
        return (
            <svg 
            block="SubmitIcon" 
            xmlns="http://www.w3.org/2000/svg" 
            version="1.1" 
            id="Capa_1" 
            x="0px" 
            y="0px"
            width="24"
            height="24" 
            viewBox="0 0 533.973 533.973"  
            >
              <path xmlns="http://www.w3.org/2000/svg" d="M477.931,52.261c-12.821-12.821-33.605-12.821-46.427,0l-266.96,266.954l-62.075-62.069    c-12.821-12.821-33.604-12.821-46.426,0L9.616,303.572c-12.821,12.821-12.821,33.604,0,46.426l85.289,85.289l46.426,46.426    c12.821,12.821,33.611,12.821,46.426,0l46.426-46.426l290.173-290.174c12.821-12.821,12.821-33.605,0-46.426L477.931,52.261z"/>
            </svg>
        );
    }
}

export default SubmitIcon;
