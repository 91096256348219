import { PureComponent } from 'react';
import Field from 'Component/Field';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import Html from "Component/Html";

/** @namespace Component/GiftCardDashBoardComponent/Component */
export class GiftCardDashBoardComponent extends PureComponent{

    renderCodesTable(){
        return (
            <table block="MyAccountRewardPointsHistory" elem="Table">
                <thead>
                {this.renderTableHeads()}
                </thead>
                <tbody>
                {this.renderTableRows()}
                </tbody>
            </table>
        )
    }

    renderTableHeads(){
        return (
                <tr>
                    <th>{__('Id')}</th>
                    <th>{__('Code')}</th>
                    <th>{__('status')}</th>
                    <th>{__('Balance')}</th>
                    <th>{__('Validity')}</th>
                    <th>{__('Remove')}</th>
                </tr>
        )
    }

    renderRowItems(item){
        const { removeGiftCard } =this.props;
        return  (
            <>
                <tr>
                    <td>{item.id}</td>
                    <td>{item.code}</td>
                    <td>{item.status}</td>
                    <td><Html content={item.balance} /></td>
                    <td>{item.expiredDate}</td>
                    <td>
                        <div className="applied-gift-coupons">
                            <div className="remove" onClick={() => removeGiftCard(item.code)}>remove</div>
                        </div>
                    </td>
                    {/*<button onClick={() => removeGiftCard(item.code) } type="button" >{__('X')}</button>*/}
                </tr>
            </>

        );
    }

    renderTableRows(){
        const { codes } = this.props;

        if (codes.length === 0 ){
            return __('No Code Found');
        }

        return codes.map((item) => (
                      this.renderRowItems(item)
                    ));

    }

    renderApplyGiftCard(){
        const { giftCode, handleGiftCodeChange } = this.props;
        return (
            <>
                <Field
                    type="text"
                    id="giftCardCode-2"
                    name="couponCode"
                    placeholder={ __('Gift Card Code') }
                    value={giftCode}
                    onChange={handleGiftCodeChange}
                    validation={ ['notEmpty'] }
                    mix={ { block: 'GiftCartCoupon', elem: 'Input' } }
                />
                <button
                    block="GiftCartCoupon"
                    elem="Button"
                    type="submit"
                    className="Button"
                >
                    { __('Add Code') }
                </button>
            </>
        );
    }

    render() {
        const {  handleFormSuccess, isLoading } = this.props;
        return (
            <>
                {this.renderCodesTable()}
                <div className="GiftCartCoupon">
                    <Form block="GiftCartCoupon"
                          onSubmitSuccess={ handleFormSuccess }
                    >
                        <Loader isLoading={ isLoading } />
                        {this.renderApplyGiftCard()}
                    </Form>
                </div>
            </>
        );
    }
}
export default GiftCardDashBoardComponent;
