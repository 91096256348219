export const SAMPLE_PRODUCTS = 'SAMPLE_PRODUCTS';
export const ADDING_SAMPLE_PRODUCT = 'ADDING_SAMPLE_PRODUCT';

/**
 * Add SampleProducts product into list.
 * @param  {Object} product Product returned from fetch
 * @param  {String} store code
 * @return {void}
 * @namespace Store/SampleProducts/Action/addRelatedProducts
 */
export const sampleProductList = (ProductListing) => ({
    type: SAMPLE_PRODUCTS,
    ProductListing,
});

/**
 * Update loading status
 * @param {Boolean} status Loading indication boolean
 * @return {void}
 * @namespace Store/SampleProducts/Action/updateLoadStatus
 */
export const updateIsLoading = (status) => ({
    type: ADDING_SAMPLE_PRODUCT,
    isLoading: status
});
