import getStore from 'Util/Store';

import {
    RECAPTCHA_TYPE_INVISIBLE,
    RECAPTCHA_TYPE_RECAPTCHA,
    RECAPTCHA_TYPE_RECAPTCHA_V3
} from '../component/Recaptcha/Recaptcha.config';

/** @namespace Recaptcha/Utils/RecaptchaConfig/getRecaptchaConfigs */
export const getRecaptchaConfigs = () => {
    const store = getStore();
    const {
        ConfigReducer: {
            recaptcha_for_customer_login,
            recaptcha_for_customer_forgot_password,
            recaptcha_for_customer_create,
            recaptcha_for_customer_edit,
            recaptcha_for_contact,
            recaptcha_for_product_review,
            recaptcha_for_newsletter,
            recaptcha_for_sendfriend,
            recaptcha_recaptcha_public_key,
            recaptcha_invisible_public_key,
            recaptcha_recaptcha_v3_public_key
        } = {}
    } = store.getState();

    return {
        recaptcha_for_customer_login,
        recaptcha_for_customer_forgot_password,
        recaptcha_for_customer_create,
        recaptcha_for_customer_edit,
        recaptcha_for_contact,
        recaptcha_for_product_review,
        recaptcha_for_newsletter,
        recaptcha_for_sendfriend,
        recaptcha_recaptcha_public_key,
        recaptcha_invisible_public_key,
        recaptcha_recaptcha_v3_public_key
    };
};

/** @namespace Recaptcha/Utils/RecaptchaConfig/getCaptchaKey */
export const getCaptchaKey = (captchaType) => {
    const config = getRecaptchaConfigs();
    switch (captchaType) {
    case RECAPTCHA_TYPE_RECAPTCHA:
        return config.recaptcha_recaptcha_public_key;
    case RECAPTCHA_TYPE_INVISIBLE:
        return config.recaptcha_invisible_public_key;
    case RECAPTCHA_TYPE_RECAPTCHA_V3:
        return config.recaptcha_recaptcha_v3_public_key;
    default:
        return null;
    }
};
