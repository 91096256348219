/**
 * MageGuide - Progressive Web App for Magento
 *
 * Copyright © MageGuide, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 */
 import { UPSELL } from 'SourceStore/LinkedProducts/LinkedProducts.reducer';
import { PRODUCT_INTO_UPSELL } from '../component/RelatedProducts/RelatedProducts.config';

export const RelatedProductsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../store/RelatedProducts/RelatedProducts.dispatcher'
);

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;
    return {
        ...callback.apply(instance, args),
        relatedProduct:(options) => RelatedProductsDispatcher.then(({ default: dispatcher }) =>
             dispatcher.handleData(dispatch, options))
    };
};

const mapStateToProps = (args, callback, instance) => {
    const { RelatedProductsReducer } = args[0];
    return {
            ...callback(...args),
            relatedProducts: RelatedProductsReducer.relatedProducts
        }
};

const containerProps  = (args, callback, instance) =>  {
    const { relatedProducts } = instance.props;

    return {
        ...callback.apply(instance, args),
        relatedProducts: relatedProducts
    };
};

const componentDidUpdate = (args, callback, instance) => {
    const { 
        relatedProducts, 
        relatedProduct, 
        id: productID, 
        product:{ 
            sku, 
            type_id,
            product_links = []
        }
     } = instance.props;
     const { isRequested = false} = instance.state
     const { 
        isUpSellActive
     } = instance.state
    const [prevProps] = args;

    const { 
        relatedProducts: prevRelatedProducts,
        productID: prevProductID, 
        product : { sku : prevSku } 
    } = prevProps;

    const upsell = product_links.filter( ({ link_type })=> link_type == UPSELL);

    if(
        isUpSellActive && instance.getAreDetailsLoaded() && 
        prevRelatedProducts !== relatedProducts && 
        Object.keys(relatedProducts).length > 0 
        // && (upsell[0]?.linked_product_sku == 0 || !upsell[0]?.linked_product_sku)
    ) {
        const allIntoUpSell = Object.entries(relatedProducts)
                .filter(( [,data], _) => data?.position === PRODUCT_INTO_UPSELL ) || [];

        if(allIntoUpSell.length > 0 && allIntoUpSell[0]) {
            const [i, ] = allIntoUpSell[0];
            instance.selectPdpProductTab(Number(i))
        }
    }

    if(!isRequested && sku && (sku !== prevSku) && type_id !== 'bundle' && productID) {
        relatedProduct({
           productId:productID,
           position: 'product'
        });
        
        instance.setState({
            isRequested: true
         })
    }

    return callback.apply(instance, args);
}


 
export default {
    'Route/ProductPage/Container/mapDispatchToProps': {
        function: [
            {
                position: 101,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Route/ProductPage/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    },
    'Route/ProductPage/Container' : { 
        'member-function': {
            containerProps,
            componentDidUpdate
        }
    }
} 
