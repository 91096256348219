import NoMatchHandler from 'SourceRoute/NoMatchHandler';
import ContentWrapper from 'SourceComponent/ContentWrapper';
import { renderIsGift } from "../route/FreeGiftCategoryPage/FreeGiftCategoryPage.config";

const render = (_args, _callback, _instance) => {
    const { dataSource } = _instance.props;

    if (renderIsGift(dataSource)) {
        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                // itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                        wrapperMix={{ block: 'ProductPage', elem: 'Wrapper' }}
                        label={__('Main free gift product details')}
                    >
                        {_instance.renderProductPageContent()}
                    </ContentWrapper>
                </main>
            </NoMatchHandler>
        );
    }

    return _callback.apply(_instance, _args);
}

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            render
        }
    },
    // 'Component/ProductCard/Container': {
    //     'member-function': {
    //         containerProps
    //     }
    // }
}