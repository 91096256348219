
import { updateLoadStatus, updateSearchBar } from 'Store/SearchBar/SearchBar.action';
import { executeGet, getApiSearchStatus } from '../../utlis/Request';
import { UPDATE_SEARCH_BAR } from 'Store/SearchBar/SearchBar.action';
import { makeCancelable } from 'Util/Promise';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import { getIndexedProducts } from 'Util/Product';

const onSuccess = (_args, _callback, _instance) => {
    const { use_in_autocomplete = false, search_is_enabled = false } = getApiSearchStatus();
    
    if(search_is_enabled && use_in_autocomplete) { 
        return
    }

    return _callback(..._args);
}

const onError = (_args, _callback, _instance) => {
    const { use_in_autocomplete = false, search_is_enabled = false } = getApiSearchStatus();

    if(search_is_enabled && use_in_autocomplete) { 
        return
    }

        return _callback(..._args);
}

const prepareRequest = (_args, _callback, _instance) => {
    const { use_in_autocomplete = false, search_is_enabled = false } = getApiSearchStatus();

    if(search_is_enabled && use_in_autocomplete) { 
    const [ options, dispatch ] = _args;
    const { args: { search} } = options;
    
    if(search !== ''){
    dispatch(updateLoadStatus(true));

       return makeCancelable(executeGet({ search: search }, 'search', ONE_MONTH_IN_SECONDS).then((resp) => {
            const data = resp;
            dispatch(updateSearchBar(data));
            dispatch(updateLoadStatus(false));
            }));
        }
    }

    return _callback(..._args);
}

export const SearchBarReducer = (_args, _callback, _instance) => {
    const [state , action] = _args;
    const { type } = action;

    if (!action) {
        return _callback.apply(_instance, _args);;
    }

    if (type !== UPDATE_SEARCH_BAR) {
        return _callback.apply(_instance, _args);;
    }

    const { result, result: { products: { items: initialItems } = {} } = {} } = action;
    
    return {
        ...state,
         productsInSearch: result.products ? getIndexedProducts(initialItems) : [result]
    };
};


export default {
    'Store/SearchBar/Dispatcher': {
        'member-function': {
            prepareRequest,
            onSuccess,
            onError
        }
    },
    'Store/SearchBar/Reducer': {
        'function': SearchBarReducer
    }
};
