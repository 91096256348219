import CheckoutPayment from "SourceComponent/CheckoutPayment";

export class CheckoutPaymentsPlugin {
  mapStateToProps = (args, callback, instance) => {
    const [state] = args;
    return {
      ...callback.apply(instance, args),
      totals: state.CartReducer.cartTotals,
    };
  };

  containerProps(args, callback, instance) {
    const { totals: { items = {} } = {} } = instance.props;

    return {
      ...callback.apply(instance, args),
      items,
    };
  }

  renderPayment(args, callback, instance) {
    const method = args[0];
    const { selectPaymentMethod, selectedPaymentCode, items } = instance.props;

    const { code } = method;
    const isSelected = selectedPaymentCode === code;

    return (
      <CheckoutPayment
        items={items}
        key={code}
        isSelected={isSelected}
        method={method}
        onClick={selectPaymentMethod}
      />
    );
  }

  render(args, callback, instance) {
    const {
      method: { code },
      items = {},
    } = instance.props;

    const isGiftCart = Object.values(items).filter(
      ({ product: { type_id } }) => type_id === "amgiftcard"
    );

    if ( 
      (code === "cashondelivery" || code === "msp_cashondelivery") &&
      (isGiftCart && isGiftCart.length > 0 )
       ){
         return null; 
       }

    return callback.apply(instance, args);
  }
}

const { render, mapStateToProps, containerProps, renderPayment } =
  new CheckoutPaymentsPlugin();

export const config = {
  "Component/CheckoutPayment/Component": {
    "member-function": {
      render,
    },
  },
  "Component/CheckoutPayments/Component": {
    "member-function": {
      renderPayment,
    },
  },
  "Component/CheckoutPayments/Container": {
    "member-function": {
      containerProps,
    },
  },
  "Component/CheckoutPayments/Container/mapStateToProps": {
    function: [
      {
        position: 101,
        implementation: mapStateToProps,
      },
    ],
  },
};

export default config;
