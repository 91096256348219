export const NOT_FOUND_PDP = 'NOT_FOUND_PDP';
import { lazy } from 'react';
export const NotFound = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "not-found" */ '../route/NotFound'));

const renderContent = (args, callback, instance) => {
    const { props, type } = instance.props;
    switch (type) {
        case NOT_FOUND_PDP:
            return <NotFound { ...props } />
        default:
            return callback.apply(instance, args);
    }
}

export const config = {
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent: [
                {
                    position:115,
                    implementation: renderContent
                }
            ]
        }
    }
};

export default config;
