import { CategorySortContainer as SourceCategorySortContainer } from 'SourceComponent/CategorySort/CategorySort.container';

/** @namespace Component/CategorySort/Container */
export class CategorySortContainer extends SourceCategorySortContainer {

    containerProps() {
        const {
            isMatchingInfoFilter,
            onSortChange,
            sortDirection,
            sortKey,
            isMobile
        } = this.props;

        return {
            isMatchingInfoFilter,
            onSortChange,
            sortDirection,
            sortKey,
            isMobile,
            selectOptions: this._prepareOptions()
        };
    }
}

export default CategorySortContainer;
