/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */
import CheckoutPayments from 'Component/CheckoutPayments';

const renderPayments = (args, callback, instance) => {
    const {
        paymentMethods,
        onPaymentMethodSelect,
        setLoading,
        setDetailsStep,
        setBankRedirectAdditionalData,
        shippingAddress
    } = instance.props;

    if (!paymentMethods.length) {
        return null;
    }

    return (
        <CheckoutPayments
            setBankRedirectAdditionalData={setBankRedirectAdditionalData}
            setLoading={ setLoading }
            setDetailsStep={ setDetailsStep }
            paymentMethods={ paymentMethods }
            onPaymentMethodSelect={ onPaymentMethodSelect }
            setOrderButtonVisibility={ instance.setOrderButtonVisibility }
            billingAddress={ shippingAddress }
            setOrderButtonEnableStatus={ instance.setOrderButtonEnableStatus }
        />
    );
}

const renderPaymentsCheckout = (args, callback, instance) => {
    const {
        setBankRedirectAdditionalData,
        paymentMethods,
        onPaymentMethodSelect,
        setLoading,
        setDetailsStep,
        shippingAddress,
        setOrderButtonVisibility,
        setOrderButtonEnableStatus,
        paymentMethod,
        selectedShippingMethod
    } = instance.props;

    if (!paymentMethods.length) {
        return null;
    }

    return (
        <CheckoutPayments
            setBankRedirectAdditionalData={setBankRedirectAdditionalData}
            paymentMethod= { paymentMethod }
            setLoading={ setLoading }
            setDetailsStep={ setDetailsStep }
            paymentMethods={ paymentMethods }
            onPaymentMethodSelect={ onPaymentMethodSelect }
            setOrderButtonVisibility={ setOrderButtonVisibility }
            billingAddress={ shippingAddress }
            setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
            selectedShippingMethod={ selectedShippingMethod }
        />
    );
}

export const config = {
    'Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments: renderPayments
        }
    },
    /// One Step Checkout
    'Route/Checkout/Component': {
        'member-function': {
            renderPayments: renderPaymentsCheckout
        }
    }
};

export default config;
