import { ChevronIcon as SourceChevronIcon } from 'SourceComponent/ChevronIcon/ChevronIcon.component';

export class ChevronIcon extends SourceChevronIcon {

    render() {
        const { direction } = this.props;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M6 12L10 8L6 4" />
            </svg>
        );
    }
}

export default ChevronIcon;
