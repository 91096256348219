import {
    updateInfoLoadStatus
} from 'Store/ProductListInfo/ProductListInfo.action';
const onSuccess = (args, callback, instance) => {
    const dispatch = args[1];
    dispatch(updateInfoLoadStatus(false));
    return callback.apply(instance, args);
}

export const config = {
    'Store/ProductListInfo/Dispatcher': {
        'member-function': {
            // onSuccess: [
            //     {
            //         position:1,
            //         implementation: onSuccess
            //     }
            // ]
        }
    }
};

export default config;
