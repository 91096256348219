import getStore from 'Util/Store';

export class CheckoutPaymentPlugin {
    render(args, callback = () => {}, instance) {
        const {
            isSelected,
            method: { title, code }
        } = instance.props;
        const { box_now_instruction } = getStore().getState().ConfigReducer;
        if(code === 'boxnow' && box_now_instruction){
            return (
                <li block="CheckoutPayment">
                    <button
                        block="CheckoutPayment"
                        mods={ { isSelected } }
                        elem="Button"
                        onClick={ instance.onClick }
                        type="button"
                        className="boxnow"
                    >
                        <div className="Field Field_type_checkbox">
                            <div className="Field-CheckboxLabel">
                                <div type="checkbox"></div>
                                <div className="input-control"></div>
                            </div>
                        </div>
                        { title }
                    </button>
                    { box_now_instruction }
                </li>
            );
        }
        return callback.apply(instance,args);
    }
}

const {
    render
} = new CheckoutPaymentPlugin();

export const config = {
    // We dont want to show instructions here
    // 'Component/CheckoutPayment/Component': {
    //     'member-function': {
    //         render
    //     }
    // }
};

export default config;
