const mapStateToProps = (args, callback, instance) => {
    const { ConfigReducer, CheckoutReducer } = args[0];
    return {
        ...callback.apply(instance, args),
        is_box_now_active: ConfigReducer.is_box_now_active
    }
};

export const config = {
    'Component/CheckoutDeliveryOption/Container/mapStateToProps': {
        function: [
            {
                position: 101,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
