const setDetailsStep = async (args, callback, instance) => {
    window.client_token = undefined;
    return callback.apply(instance,args);
}
const componentDidUpdate = (args, callback, instance) => {
    const [ prevPros ] = args;
    const { totals : prevTotals } = prevPros;
    const { totals, updateEmail } = instance.props;
    const { email } = instance.state;
    if(
        JSON.stringify(prevTotals)
        !== JSON.stringify(totals)
    ){
        window.client_token = undefined;
    }
    // Always have email in reducer for klarna
    updateEmail(email);
    return callback(...args);
};
const componentDidMount = (args, callback, instance) => {
    const { placingOrder } = instance.props;
    document.addEventListener(
        "updatePlacingOrderStatus",
        (event) => {
            placingOrder(event.detail);
        },
        false,
    );
    return callback(...args);
};

export default {
    'Route/Checkout/Container': {
        'member-function': {
            setDetailsStep: setDetailsStep,
            componentDidUpdate,
            componentDidMount
        }
    }
};
