export class ProductActionsPlugin {
    mapStateToProps(args, callback, instance) {
        const [state] = args;

        return {
            ...callback.apply(instance, args),
            urlRewrite: state.UrlRewritesReducer?.urlRewrite
        }
    };

    containerProps(args, callback, instance) {
        const { urlRewrite } = instance.props
        return {
            ...callback.apply(instance, args),
            urlRewrite: urlRewrite
        };
    };
}

export const { mapStateToProps, containerProps } = new ProductActionsPlugin();

export default {
    'Component/ProductActions/Container/mapStateToProps': {
        function: [
            {
                position: 103,
                implementation: mapStateToProps
            }
        ]
    },
    'Component/ProductActions/Container': {
        'member-function': {
            containerProps
        },
    }
}