import ProductCard from 'SourceComponent/ProductCard';
import ProductListPage from 'SourceComponent/ProductListPage';

const renderPage = (_args, _callback, _instance) => {
    const [ props = {} ] = _args;
    const { isFreeGifts } = _instance.props;
    
    const {
        isInfiniteLoaderEnabled,
        loadPage,
        isLoading,
        isVisible,
        mix,
        sliderOptions,
        categoryPageCmsBlockListing,
        isSampleProducts,
        device,
        currentPage
    } = _instance.props;

    const newProps = _instance._processProps(props);

    return (
        <ProductListPage
          key={ currentPage }
          device= { device }
          isFreeGifts= { isFreeGifts }
          isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
          updatePages={ loadPage }
          isLoading={ isLoading }
          isVisible={ isVisible }
          mix={ mix }
          sliderOptions = { sliderOptions }
          categoryPageCmsBlockListing={ categoryPageCmsBlockListing }
          isSampleProducts={ isSampleProducts }
          { ...newProps }
        />
    );
}


const renderPlaceholders = (_args, _callback, _instance) => {
    const {
        numberOfPlaceholders,
        isFreeGifts,
        isLoading
    } = _instance.props;

    return Array.from(
        { length: numberOfPlaceholders },
        (_, i) => (
            <ProductCard
              { ..._callback.apply(_instance, _args) }
              isFreeGifts = { isFreeGifts }
              isLoading = { isLoading }
              itemPosition= { i + 1}
            />
        )
    );
}


const containerProps  = (_args, _callback, _instance) =>  {
    const { isFreeGifts } = _instance.props;

    return {
        ..._callback.apply(_instance, _args),
        isFreeGifts
    };
};


export default {
    'Component/CategoryProductList/Container': {
        'member-function': { 
            containerProps
        }
    },
    'Component/ProductList/Container': {
        'member-function': { 
            containerProps
        }
    },
    'Component/ProductList/Component': {
        'member-function': { 
            // renderPage
        }
    },
    'Component/ProductListPage/Component': {
        'member-function': { 
            containerProps,
            renderPlaceholders
        }
    }
};
