/*
 * Copyright © MageGuide, Inc. All rights reserved.
 * See COPYING.txt for license details.
 *
 */

import CheckoutGroupPayment from '../component/CheckoutGroupPayment';

const groupPaymentsPrefix = [
    'mg_bank_redirect'
];

export class CheckoutPaymentsPlugin {

    renderGroupPayment = (groupedPaymentsObject,instance) => {
        const {
            selectPaymentMethod,
            selectedPaymentCode
        } = instance.props;

        return (
            <>
            {
                Object.entries(groupedPaymentsObject).map(([key,groupedPayments]) => {
                    let isSelected = false;

                    if(selectedPaymentCode){
                        groupPaymentsPrefix.some(element => {
                            if (selectedPaymentCode.includes(element)) {
                                isSelected = true;
                            }else {
                                const fakeButton = document.getElementById('place_order_fake');
                                if(fakeButton)
                                    fakeButton.disabled = false;
                                isSelected = false;
                            }
                        });
                    }

                    return (
                        <CheckoutGroupPayment
                            groupedPayments={ groupedPayments }
                            selectPaymentMethod={ selectPaymentMethod }
                            selectedPaymentCode={selectedPaymentCode}
                            paymentKey={key}
                            isSelected={isSelected}
                        />
                        )
                    }
                )
            }
            </>
        );
    };

    renderPayments = (args, callback, instance) => {
        const { paymentMethods } = instance.props;
        let groupedPayments = {};
        let nonGroupPayments = [];
        paymentMethods.map(item => {
            groupPaymentsPrefix.some(element => {
                if (item.code.includes(element)) {
                    if(!groupedPayments[element])
                        groupedPayments[element] = [];
                    groupedPayments[element].push(item)
                }else {
                    nonGroupPayments.push(item)
                }
            });
        })
        return (
            <>
                { nonGroupPayments.map(instance.renderPayment) }
                { this.renderGroupPayment(groupedPayments,instance)  }
            </>
        );
    }
}

const {
    renderPayments
} = new CheckoutPaymentsPlugin();

export const config = {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderPayments: renderPayments
        }
    }
};

export default config;
