import { fetchQuery } from 'SourceUtil/Request';
import AdminCustomerQuery from '../query/AdminCustomerQuery';;
import { getIndexedProducts } from 'SourceUtil/Product';
import { ORDER_POPUP_ID } from 'SourceComponent/MyAccountOrderPopup//MyAccountOrderPopup.config';


const mapStateToProps = (args, callback, instance) => {
    const [state] = args;
    return {
        order: state.OrderReducer?.order || {},
        payload: state.PopupReducer.popupPayload[ORDER_POPUP_ID] || {},
        display_tax_in_shipping_amount: state.ConfigReducer.cartDisplayConfig.display_tax_in_shipping_amount
    }
};

const requestOrderDetails = (_args, _callback, _instance) => {
    const { payload, payload: { increment_id: orderId }, showNotification } = _instance.props;
    if (payload.isAdminOrder) {
        const query = AdminCustomerQuery.getOrderByIdQuery(orderId)
        return fetchQuery(query).then(
            ({ get_order_data: rawOrder }) => {
            const { order_products = [] } = rawOrder;
            const indexedProducts = getIndexedProducts(order_products);
            const order = { ...rawOrder, order_products: indexedProducts };
            const { base_order_info: { currency_code } } = order;
            _instance.setState({ currency_code, order, isLoading: false });
             },
            (error) => showNotification('error', __(error ? error[0].message : 'unable to get order data!'))
        );
    }

    return _callback(..._args);
}

const containerProps = (_args, _callback, _instance) => {
    const { payload } = _instance.props;
    
    return {
      ..._callback.apply(_instance, _args),
      isAdminOrder: payload?.isAdminOrder,
      email: payload?.email || '',
      incrementId: payload?.increment_id || null
    };
  };

export const config = {
    'Component/MyAccountOrderPopup/Container/mapStateToProps': {
        function: {
            position: 101,
            implementation: mapStateToProps
        }
    },
    'Component/MyAccountOrderPopup/Container': {
        'member-function': {
            requestOrderDetails,
            containerProps
        }
    }
};

export default config;