/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component';

/**
 * Page Meta data
 * @class Meta
 * @namespace Component/Meta/Component
 */
export class Meta extends SourceMeta {
    renderTitle() {
        const {
            default_title,
            title_prefix,
            title_suffix,
            title
        } = this.props;

        const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
        const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';
        const { value = title } = title;

        return (
            <title>
                { value || default_title }
            </title>
        );
    }
}

export default Meta;
