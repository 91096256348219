export const updateEveryTime = [
    'title',
    'description',
    'keywords',
    'canonical_url',
    'robots',
    'status_code',
    'type',
    'image',
    'og_image'
];

export const filterData = (args) => {
    const [data] = args;

    const updated = updateEveryTime.reduce((acc, key) => {
        acc[key] = data[key];

        return acc;
    }, {});

    return { ...data, ...updated };
};

export const getInitialState = (args) => ({
    ...args,
    type: '',
    image: '',
    default_store_description: ''
});

class MetaStorePlugin {

    _getProductMeta(args, callback, instance) {
        const {
            thumbnail,
            small_image
        } = args[0];

        return {
            ...callback.apply(instance, args),
            image: small_image?.url || thumbnail?.url,
            type: "product",
        }
    }
    _getCategoryMeta(args, callback, instance) {
        const {
            image
        } = args[0];

        return {
            ...callback.apply(instance, args),
            image,
            type: image ? "website" : '',
        }

    }
}

const { _getProductMeta, _getCategoryMeta } = new MetaStorePlugin();

export default {
    'Util/Meta/Dispatcher': {
        'member-function': {
            _getProductMeta: [
                {
                    position: 112,
                    implementation: _getProductMeta
                }
            ],
            _getCategoryMeta: [
                {
                    position: 112,
                    implementation: _getCategoryMeta
                }
            ]
        }
    },
    'Store/Meta/Reducer/getInitialState': {
        function: getInitialState
    },
    'Store/Meta/Reducer/filterData': {
        function: filterData
    },
};