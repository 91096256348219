 
const _getStoreConfigFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'locale'
];

export const config = {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};

export default config;
