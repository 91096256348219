import { Suspense } from "react";
import CheckoutShipping  from "Component/CheckoutShipping";
import CheckoutBilling from "Component/CheckoutBilling";
import Loader from 'Component/Loader';
import Field from 'Component/Field';
import Form from 'Component/Form';
const ALLOWED_COUNTRY_IDS = ['GR'];
class CheckoutComponentPlugin {
    // renderShippingStep(args, callback, instance) {
    //     const {
    //         shippingMethods,
    //         onShippingEstimationFieldsChange,
    //         saveAddressInformation,
    //         isDeliveryOptionsLoading,
    //         onPasswordChange,
    //         onCreateUserChange,
    //         onEmailChange,
    //         isCreateUser,
    //         estimateAddress,
    //         isPickInStoreMethodSelected,
    //         handleSelectDeliveryMethod,
    //         cartTotalSubPrice,
    //         onShippingMethodSelect,
    //         onStoreSelect,
    //         selectedStoreAddress,
    //         extensionAttributes
    //     } = instance.props;
    //
    //     console.log(extensionAttributes);
    //
    //     return (
    //         <Suspense fallback={ <Loader /> }>
    //             <CheckoutShipping
    //                 isLoading={ isDeliveryOptionsLoading }
    //                 shippingMethods={ shippingMethods }
    //                 cartTotalSubPrice={ cartTotalSubPrice }
    //                 saveAddressInformation={ saveAddressInformation }
    //                 onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
    //                 onShippingMethodSelect={ onShippingMethodSelect }
    //                 onPasswordChange={ onPasswordChange }
    //                 onCreateUserChange={ onCreateUserChange }
    //                 onEmailChange={ onEmailChange }
    //                 isCreateUser={ isCreateUser }
    //                 estimateAddress={ estimateAddress }
    //                 handleSelectDeliveryMethod={ handleSelectDeliveryMethod }
    //                 isPickInStoreMethodSelected={ isPickInStoreMethodSelected }
    //                 onStoreSelect={ onStoreSelect }
    //                 selectedStoreAddress={ selectedStoreAddress }
    //                 extensionAttributes={extensionAttributes}
    //             />
    //         </Suspense>
    //     );
    // }

    renderDocumentType(args, callback, instance){
        const {
            invoice_type,
            getActivities,
            extensionAttributes,
            estimateAddress : { country_id = 'GR' } = {},
            onOrderAttributeChange
        } = instance.props;

        if(!extensionAttributes || !ALLOWED_COUNTRY_IDS.includes(country_id)) return null;

        let initialValue = {};

        if (extensionAttributes.length){
            extensionAttributes.map(el => initialValue[el.attribute_code] = el.value);
        }


        return(
            <div className="Field type_document">
                <h2 className="Checkout-Heading">{__('TYPE OF DOCUMENT')}</h2>
                <div style={{ display : 'none'}} >
                    <Field type={'text'} id='invoice_type' name='invoice_type' value={invoice_type}></Field>
                </div>
                <div className="Checkout_type_document">
                    <div
                        onClick={ () => instance.props.onInvoiceTypeClick('Receipt') }
                        className="Checkout_type_document_option"
                    >
                        <span className='label'>{  __('Receipt') }</span>
                        <div
                            className={`option active ${invoice_type ===  'Receipt' ? 'checked': ''}`}
                            data-invoice_type={invoice_type}
                        >
                        </div>
                    </div>
                    <div
                        onClick={ () => instance.props.onInvoiceTypeClick('Invoice') }
                        className="Checkout_type_document_option"
                    >
                        <span className='label'>{  __('Invoice') }</span>
                        <div
                            className={`option active ${invoice_type ===  'Invoice' ? 'checked': ''}`}
                            data-invoice_type={invoice_type}
                        >
                        </div>
                    </div>
                </div>
                {
                    invoice_type === "Invoice" ?
                        <Form
                            id="invoice_form"
                            key="invoice"
                            mix={ { block: 'Invoice' } }
                        >
                            <div className="FieldForm">
                                <div className="FieldForm-Fields">
                                    <Field
                                        id='invoice_vat_id'
                                        key='vat_id'
                                        name='vat_id'
                                        label={__('VAT ID')}
                                        validation={['notEmpty','vat']}
                                        value={initialValue.vat_id}
                                        placeholder={__('Enter your vat id')}
                                        onChange={ (value) => onOrderAttributeChange('vat_id',value) }
                                        onBlur={ instance.props.onVatIdBlur }
                                    />
                                    <Field
                                        key='company'
                                        id='invoice_company'
                                        name='company'
                                        label={__('Company')}
                                        validation={['notEmpty']}
                                        value={initialValue.company}
                                        placeholder={ __('Enter your company name')}
                                        onChange={ (value) => onOrderAttributeChange('company',value) }
                                        isDisabled={true}
                                    />
                                    <Field
                                        id='invoice_tax_office'
                                        key='tax_office'
                                        name='tax_office'
                                        label={__('Tax Office')}
                                        validation={['notEmpty']}
                                        value={initialValue.tax_office}
                                        placeholder={ __('Enter your tax office')}
                                        isDisabled={true}
                                        onChange={ (value) => onOrderAttributeChange('tax_office',value) }
                                    />
                                    <div style={{display:"none"}}>
                                        <Field
                                            id='invoice_vat_office_code'
                                            key='vat_office_code'
                                            name='vat_office_code'
                                            label={__('Tax Office Code')}
                                            hidden={true}
                                            validation={['notEmpty']}
                                            value={initialValue.vat_office_code}
                                            isDisabled={true}
                                            onChange={ (value) => onOrderAttributeChange('vat_office_code',value) }
                                        />
                                    </div>
                                    <Field
                                        id='invoice_business_activity'
                                        key='business_activity'
                                        name='business_activity'
                                        type='select'
                                        label={__('Occupation')}
                                        validation={['notEmpty']}
                                        value={initialValue.business_activity}
                                        placeholder={ __('Please Select the Occupation')}
                                        selectOptions={getActivities}
                                        onChange={ (value) => onOrderAttributeChange('business_activity',value) }
                                    />
                                </div>
                            </div>
                        </Form>
                        :null
                }
            </div>
        );
    }

    HondoscOverridenRenderBillingStep(args, callback, instance){
        const {
            setLoading,
            setDetailsStep,
            shippingAddress,
            paymentMethods = [],
            savePaymentInformation,
            selectedShippingMethod,
            shippingMethods,
            onShippingEstimationFieldsChange,
            saveAddressInformation,
            isDeliveryOptionsLoading,
            onPasswordChange,
            onCreateUserChange,
            onEmailChange,
            isCreateUser,
            estimateAddress,
            isPickInStoreMethodSelected,
            handleSelectDeliveryMethod,
            cartTotalSubPrice,
            onShippingMethodSelect,
            onStoreSelect,
            isOrderButtonVisible,
            isOrderButtonEnabled,
            isTermsAndConditionsAccepted,
            selectedStoreAddress,
            onSaveShippingAddressCheckboxSelect,
            saveShippingAddress,
            onSaveBillingAddressCheckboxSelect,
            saveBillingAddress,
            paymentMethod,
            setAlphaBankAdditionalData,
            totals: {
                is_virtual
            },
            setShippingFormSubmitted,
            extensionAttributes,
            onOrderAttributeChange,
            onVatIdBlur,
            invoice_type,
            getActivities,
            onInvoiceTypeClick,
            placingOrder
        } = instance.props;

        return (
            <>
                {
                    is_virtual ?
                        null:
                        <Suspense fallback={ <Loader /> }>
                            <CheckoutShipping
                                isLoading={ isDeliveryOptionsLoading }
                                shippingMethods={ shippingMethods }
                                cartTotalSubPrice={ cartTotalSubPrice }
                                setShippingFormSubmitted={setShippingFormSubmitted}
                                saveAddressInformation={ saveAddressInformation }
                                onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                                onShippingMethodSelect={ onShippingMethodSelect }
                                onPasswordChange={ onPasswordChange }
                                onCreateUserChange={ onCreateUserChange }
                                onEmailChange={ onEmailChange }
                                isCreateUser={ isCreateUser }
                                onSaveShippingAddressCheckboxSelect={onSaveShippingAddressCheckboxSelect}
                                saveShippingAddress={saveShippingAddress}
                                estimateAddress={ estimateAddress }
                                handleSelectDeliveryMethod={ handleSelectDeliveryMethod }
                                isPickInStoreMethodSelected={ isPickInStoreMethodSelected }
                                onStoreSelect={ onStoreSelect }
                                selectedStoreAddress={ selectedStoreAddress }
                                extensionAttributes={extensionAttributes}
                                onOrderAttributeChange={onOrderAttributeChange}
                                onVatIdBlur={onVatIdBlur}
                                invoice_type={invoice_type}
                                getActivities={getActivities}
                                onInvoiceTypeClick={onInvoiceTypeClick}
                            />
                        </Suspense>
                }
                <div className="Form CheckoutDocumentType">
                {instance.renderDocumentType(args, callback, instance)}
                </div>
                <Suspense fallback={ <Loader /> }>
                    <CheckoutBilling
                        setLoading={ setLoading }
                        paymentMethods={ paymentMethods }
                        paymentMethod={ paymentMethod }
                        setDetailsStep={ setDetailsStep }
                        setAlphaBankAdditionalData={setAlphaBankAdditionalData}
                        onShippingEstimationFieldsChange={ onShippingEstimationFieldsChange }
                        isOrderButtonVisible={isOrderButtonVisible}
                        isOrderButtonEnabled={isOrderButtonEnabled}
                        isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                        shippingAddress={ shippingAddress }
                        saveBillingAddress={saveBillingAddress}
                        onSaveBillingAddressCheckboxSelect={onSaveBillingAddressCheckboxSelect}
                        savePaymentInformation={ savePaymentInformation }
                        selectedShippingMethod={ selectedShippingMethod }
                        placingOrder={placingOrder}
                    />
                </Suspense>
                { is_virtual ? null :instance.renderDelivery() }
                { instance.renderPayments() }
                { instance.renderTermsAndConditions() }
                { instance.renderActions() }
            </>
        );
    }
    validatePlaceOrderSubmission(args, callback, instance){
        if(
            document.querySelector(".Checkout_type_document .checked")?.dataset?.invoice_type === 'Invoice'
        ){
            let invoice_form = document.getElementById('invoice_form');
            let invoice_data_inputs = invoice_form?.querySelectorAll('input');
            let invoice_data_selects =  invoice_form?.querySelectorAll('select')
            let anyMissingItem  = false;
            if(invoice_data_selects[0] && !invoice_data_selects[0].value){
                anyMissingItem = true;
            }else {
                for(let i = 0; i < invoice_data_inputs.length; i++){
                    // invoice_vat_office_code is not used
                    if(!invoice_data_inputs[i].value && invoice_data_inputs[3].id !== 'invoice_vat_office_code'){
                        anyMissingItem = true;
                        break;
                    }
                }
            }
            if(anyMissingItem){
                const [,showInfo,] = args;
                showInfo(__('Please fill invoice information'));
                invoice_form.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                return false;
            }
        }
        return callback.apply(instance,args);
    }
}
const { /*renderShippingStep,*/ HondoscOverridenRenderBillingStep, validatePlaceOrderSubmission, renderDocumentType } = new CheckoutComponentPlugin();

export const config = {
    'Route/Checkout/Component': {
        'member-function': {
            // renderShippingStep
            renderBillingStep : HondoscOverridenRenderBillingStep,
            validatePlaceOrderSubmission,
            renderDocumentType,
        }
    }
};

export default config;
