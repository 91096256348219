/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';
import { updateLoadStatus } from 'Store/ProductList/ProductList.action';
import { CategoryProductListContainer as SourceCategoryProductListContainer } from 'SourceComponent/CategoryProductList/CategoryProductList.container';

export const ProductListDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductList/ProductList.dispatcher'
);

/** @namespace Component/CategoryProductList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    pages: state.ProductListReducer.pages,
    isOffline: state.OfflineReducer.isOffline,
    isLoading: state.ProductListReducer.isLoading,
    isPageLoading: state.ProductListReducer.isPageLoading,
    totalItems: state.ProductListReducer.totalItems,
    totalPages: state.ProductListReducer.totalPages
});

/** @namespace Component/CategoryProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestProductList: (options) => ProductListDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    updateLoadStatus: (isLoading) => dispatch(updateLoadStatus(isLoading))
});

/** @namespace Component/CategoryProductList/Container */
export class CategoryProductListContainer extends SourceCategoryProductListContainer {

    containerProps() {
        const {
            filter,
            isPageLoading,
            pages,
            search,
            selectedFilters,
            sort,
            totalItems,
            totalPages,
            categoryPageCmsBlockListing,
            isSampleProducts,
            isFreeGifts,
            isSearchPage,
            isBrandPage
        } = this.props;
        
        return {
            isBrandPage,
            filter,
            isPageLoading,
            pages,
            search,
            selectedFilters,
            sort,
            totalItems,
            totalPages,
            categoryPageCmsBlockListing,
            isSampleProducts,
            isFreeGifts,
            isSearchPage,
            isLoading: this.getIsLoading(),
            isPreventRequest: this.getIsPreventRequest(),
            mix: { block: 'CategoryProductList', mods: { layout: this.getLayout() } }
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductListContainer);
