
import {
    updateInfoLoadStatus,
    updateProductListInfo
} from 'Store/ProductListInfo/ProductListInfo.action';
import {
    appendPage,
    updateLoadStatus,
    updatePageLoadingStatus,
    updateProductListItems
} from 'Store/ProductList/ProductList.action';
import { reduceSelectedFilters } from '../../utlis/Product';
import { executeGet, getApiSearchStatus } from '../../utlis/Request';
import { makeCancelable } from 'Util/Promise';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';


export const reduceFilters = (args, _callback) => {
    const [ filters ] = args;
    
    if(!filters) {
        return {}
    }

    return filters.reduce((co, item) => {
        const {
            request_var: attribute_code,
            name: attribute_label,
            position: attribute_position,
            filter_items,
            is_boolean,
            has_swatch
        } = item;
        
        const { attribute_values, attribute_options } = filter_items.reduce((attribute, option) => {
            const { value_string, value } = option;
            const { attribute_values, attribute_options } = attribute;
            
            attribute_values.push(value_string + '');

            return {
                ...attribute,
                attribute_options: {
                    ...attribute_options,
                    [value_string]: {
                        ...option,
                        value_string: value_string + ''
                    }
                }
            };
        }, { attribute_values: [], attribute_options: {} });
        
        return {
            ...co,
            [attribute_code]: {
                attribute_code,
                attribute_label,
                attribute_position,
                attribute_values,
                attribute_type: 'select',
                attribute_options,
                is_boolean,
                has_swatch
            }
        };
    }, {})

};


const onSuccess = (_args, _callback, _instance) => {
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();

    if(search_is_enabled && 
        use_in_fulltext && 
        location.pathname.includes('search')) {
        return;
    }
    
    
    return _callback(..._args);
}

const onError = (_args, _callback, _instance) => {
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();

 
    if(search_is_enabled && 
        use_in_fulltext && 
        location.pathname.includes('search')) {
        return;
    }

    return _callback(..._args);
}

const prepareRequest = (_args, _callback, _instance) => {
    const { use_in_fulltext = false, search_is_enabled = false } = getApiSearchStatus();
    const [ options, dispatch ] = _args;
    const { args, args: { search, filter} } = options;

    if(search_is_enabled && use_in_fulltext && location.pathname.includes('search')) {

    if(!search && !search?.length > 0) {
        return;
    }

    dispatch(updateInfoLoadStatus(true));

       return makeCancelable(executeGet({ ...args, search: decodeURI(args.search) }, 'fullsearch', ONE_MONTH_IN_SECONDS).then((resp) => {
            const data = resp;
            const stringToJsonObject =  JSON.parse(data) || {};

            const { 
                products = {}, 
                products: {
                    items,
                    total_count,
                    page_info: {
                        current_page: currentPage,
                        total_pages
                    } = {}
                },
                currentUrl,
                selectedFilters = [],
                suggested_terms = {}
            }  = stringToJsonObject;

            const newFilters = {
                ...filter,
                customFilters: reduceSelectedFilters(selectedFilters) || {}
            }
            
            const newArgs = {
                currentUrl,
                suggestedTerms: suggested_terms,
                search_is_enabled: search_is_enabled && use_in_fulltext,
                ...args
            };
            
            dispatch(
                updateProductListItems(
                    items,
                    currentPage,
                    total_count,
                    total_pages,
                    newArgs
            ));

            dispatch(updateProductListInfo(products, newFilters));
            dispatch(updateInfoLoadStatus(false));
        }));
    }
    
    return _callback(..._args);
}



export default {
    'Store/ProductListInfo/Reducer/reduceFilters': {
        'function': reduceFilters
    },
    'Store/ProductListInfo/Dispatcher': {
        'member-function': {
            prepareRequest,
            onSuccess,
            onError
        }
    },
    // 'Store/ProductListInfo/Reducer': {
    //     'function': ProductListInfoReducer
    // }
};
