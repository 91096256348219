/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { STORE_LOCATOR_DATA } from './StoreLocator.action';

export const getInitialState = () => ({
    storeLocatorData : {}
});

export const StoreLocatorReducer = (state= getInitialState(), action) => {
    switch (action.type) {
    case STORE_LOCATOR_DATA:
        const { storeLocatorData } = action;

        return {
            ...state,
            storeLocatorData: storeLocatorData
        };

    default:
        return state;
    }
};

export default StoreLocatorReducer;
